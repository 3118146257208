async function getViolationTypes() {
  const res = await jet.http.post({
    type: 'core-read',
    query: 'sin2:/v:5c936a2f-d088-438a-bbd3-f2260a55f853/',
  });

  const data = res?.result?.data || [];
  const ci = res?.result?.columnIndexes || {};
  return data.map(it => ({
    id: it[ci['vcviolationreasons.id']],
    text: it[ci['vcviolationreasons.name']],
    code: it[ci['vcviolationreasons.code']],
  }));

}

export {
  getViolationTypes,
}
