<script>
    export default {
        name: 'JetSvg',
        props: {
            height: {
                type: [String, Number],
                default: 18
            },
            width: {
                type: [String, Number],
                default: 18
            },
            /**
             * for xlink icon-id (see index.html, "#ico-info" - for exmpl)
             * required when no default slot
             */
            xref: {
                type: String,
                required: false
            },
            svg: {
                type: String,
                required: false
            }
        },
        data(){
            return {
                vbx: null   //viewBox for svg-node
            };
        },
        created(){
            this.set_vbx();
        },
        methods: {
            set_vbx(ref){
                ref = (typeof ref === 'undefined') || /^$/.test(ref) ? this.xref : ref;
                const node = $('svg ' + this.xref);
                this.vbx = node.parent().attr('viewBox') || '0 0 512 512';
            }
        },
        watch: {
            xref(val){
                this.set_vbx(val);
            },
            svg(val){
                if (!!val){
                    var vbx = false;
                    $(val).map(function(){
                        if (!!$(this.attr('viewBox'))){
                            vbx = $(this.attr('viewBox'));
                        }
                    });
                    if (!!vbx){
                        this.vbx = vbx;
                    }
                }
            }
        },
        render(h){
            const hasXref = !!this.xref;
            var props = {
                innerHTML: hasXref ? '<use xlink:href="' + this.xref + '" />' : this.svg
            };
            return h('svg', {
                                attrs: {
                                            viewBox: this.vbx, 
                                            width: this.width, 
                                            height: this.height,
                                            xmlns: "http://www.w3.org/2000/svg"
                                        }, 
                                domProps: props
                   });
        }
    };
</script>
