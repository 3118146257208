<template>
  <!-- example v-file-input -->
  <v-file-input
    v-model="vFile"
    label="Прикрепить файл"
    color="primary"
    hide-details
    :loading="loadingFile"
    @change="convertFile"
  ></v-file-input>
</template>

<script>
export default {
  name: "SendFileMixin",
  data: () => ({
    // for send
    file: null,
    // for v-model
    vFile: null,
    loadingFile: false,
  }),
  methods: {

    // Преобразование файла в формат удобный для пересылки
    convertFile(file) {
      if (file) {
        this.file = {};
        this.file.name = file.name;
        this.loadingFile = true;
        const reader = new FileReader();
        reader.onload = () => {
          const res = reader.result;
          this.file.base64 = res.substring(res.search(',') + 1);
          this.loadingFile = false;
        }
        reader.readAsDataURL(new Blob([file]));
      }
    },

    // Открытие файла
    openFile(ref) {
      location.href = '/rpc/?d=file&uri=' + ref;
    },

    /**
     * Возвращает файл в виде массива параметров, необходимо добавить в params 'params.push(...this.toParams()'
     */
    toParams() {
      const file = this.file;
      if (file) {
        return [{
            id: 'file',
            type: 'blob',
            value: file.base64,
          },
          {
            id: 'filename',
            value: file.name,
            type: 'string',
          }];
      } else {
        return null;
      }
    }
  },
}
</script>
