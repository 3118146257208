<template>
  <span>

    <!--Диалог для выбора данных-->
    <JetDialog
      v-model="showDialog"
      title="Импорт постановок"
      width="800"
      apply-button-text="Импорт"
      show-dividers
      @on-cancel-click="close"
      @on-apply-click="importWithDate"
    >
      <v-row>
        <v-col cols="auto">
          <JetDatePicker
            v-model="startDate"
            label="С даты:"
          ></JetDatePicker>
        </v-col>
        <v-col cols="auto">
          <JetDatePicker
            v-model="endDate"
            label="На дату:"
          ></JetDatePicker>
        </v-col>
      </v-row>
      <v-data-table
        v-model="carriers.selected"
        :items="carriers.items"
        :loading="carriers.loading"
        :headers="carriers.header"
        show-select
        item-key="carrierId"
      ></v-data-table>
    </JetDialog>

    <!-- Диалог статуса импорта -->
    <v-dialog v-model="dialogResponseImport" persistent max-width="600px">
      <v-card :loading="loadingImport" elevation="5">
        <v-card-title>
          <span class="body-1">Импорт постановок</span>
        </v-card-title>
        <v-card-text class="text">
          {{ textDialogImport }}
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn
            v-if="!loadingImport"
            class="white--text"
            color="primary"
            @click="refresh"
          >
            Ок
          </v-btn>
          <v-spacer/>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Кнопка "С другого дня" -->
    <span class="px-1">
      <v-btn
        class="white--text"
        color="primary"
        @click="showDialog = true"
      >
        С другого дня
      </v-btn>
    </span>
  </span>
</template>

<script>
  // Компоненты
  import JetDialog from "@/components/JetDialog";
  import JetDatePicker from "@/components/JetDatePicker";

  // Сервисы
  import OtherService from "@/services/OtherService";
  import PlansService from "@/services/PlansService";
  import {FORMAT_FOR_SERVER, FORMAT_FOR_USER, formatDate} from "@/services/JetDate";

  export default {
    name: 'ImportDialog',
    components: {
      JetDatePicker,
      JetDialog,
    },
    props: {
      selectedDate: {
        type: Object,
      },
    },
    data: () => ({
      showDialog: false,
      startDate: null,
      endDate: null,
      carriers: {
        items: [],
        loading: false,
        selected: [],
        header: [
          {text: 'Перевозчик', value: 'shortName'},
        ],
      },
      // Импорт постановок
      loadingImport: true,
      textDialogImport: '',
      dialogResponseImport: false,
    }),
    watch: {
      selectedDate() {
        this.initDate();
      },
      async showDialog(val) {
        if (val) {
          this.initDate();
          this.carriers.loading = true;
          this.carriers.items = await OtherService.getCarriers()
          this.carriers.loading = false;
        }
      },
    },
    methods: {
      initDate() {
        if (this.selectedDate) {
          this.startDate = this.selectedDate.iso;
          const date = new Date(this.selectedDate.iso);
          date.setDate(date.getDate() + 1);
          this.endDate = date.toISOString().substr(0, 10);
        }
      },
      close() {
        this.showDialog = false;
        this.carriers.items = [];
        this.carriers.selected = [];
      },
      async importWithDate() {
        this.showDialog = false;
        this.loadingImport = true;
        this.textDialogImport = `Импортирование постановок с ${formatDate(this.startDate, FORMAT_FOR_USER.ONLY_DATE)} на ${formatDate(this.endDate, FORMAT_FOR_USER.ONLY_DATE)}`;
        this.dialogResponseImport = true;
        const answer = await PlansService.importTrips(
          formatDate(this.startDate, FORMAT_FOR_SERVER.ONLY_DATE),
          formatDate(this.endDate, FORMAT_FOR_SERVER.ONLY_DATE),
        );
        this.textDialogImport = `Запланировано ${answer.planned} рейс(-ов) из ${answer.all}`;
        this.loadingImport = false;
      },
      refresh() {
        if (this.selectedDate.iso === this.endDate) {
          this.$emit('refresh');
        } else {
          this.dialogResponseImport = false;
        }
      },
    },
  };
</script>
