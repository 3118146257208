<template>
    <v-row class="trip-item">
      <!-- Данные по выезду -->
      <v-col>
        <v-row class="align-center flex-nowrap">
          <div>
            <v-checkbox
              v-if="structure.checkbox"
              v-model="item.selected"
              :disabled="disabled"
              class="pt-o mt-0"
              hide-details
              color="primary"
              @change="changeSelected"
              @click.native="check($event)"
            ></v-checkbox>
          </div>
            <div class="text-no-wrap trip-item__stop">
              <b>{{ item.tripCode }}</b>
              <span class="pl-2">{{ stopNames }}</span>
              <v-tooltip bottom v-if="item.violations">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    v-on="on"
                    color="red"
                    class="ml-2"
                  >mdi-alert-circle</v-icon>
                </template>
                {{item.violations.text}}
              </v-tooltip>
            </div>
            <span class="grey--text trip-item__times">{{ startTime }} - {{ endTime }}</span>
            <span class="trip-item__vehicles">{{ textVehicles }}</span>
        </v-row>
      </v-col>

      <!-- Кнопки управления -->
      <v-col v-if="item.planned" cols="auto">
        <!-- Добавить ТС -->
        <v-btn v-if="structure.addVehicle" outlined small color="primary" @click="addVehicle" :disabled="disabled">
          Добавить ТС
        </v-btn>
      </v-col>
    </v-row>
</template>

<script>
import PlansService from "@/services/PlansService";

export default {
  name: 'TripItem',
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    item: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    fullDate: {
      type: Object,
      required: true,
    },
    structure: {
      type: Object,
      default: () => ({
        checkbox: true,
        addVehicle: true,
        delete: true,
        plan: true,
      }),
    },
  },
  data: () => ({
    startTime: '',
    endTime: '',
    vehicles: [],
    stopNames: '',
  }),
  computed: {
    textVehicles() {
      const vehicles = this.vehicles;
      let result = '';
      for (let i = 0; i < vehicles.length; i++) {
        result += vehicles[i].govnum;
        if (i > 0 && !!vehicles[i].stop) {
          result += ` (${vehicles[i].stop})`;
        }
        if (i + 1 < vehicles.length) {
          result += ', ';
        }
      }
      return result;
    },
  },
  created() {
    this.startTime = $utils.formatDate(new Date(this.item.startTime), 'HH:mm');
    this.endTime = $utils.formatDate(new Date(this.item.endTime), 'HH:mm');
    if (this.item.planned) {
      this.initVehicles();
    }
    this.getStops();
  },
  methods: {
    check(e) {
      this.$emit('click-checkbox', {
        isShiftPress: e.shiftKey,
        item: this.item,
      });
    },
    addVehicle() {
      this.$emit('add-vehicle', [this.item]);
    },
    changeSelected() {
      const index = this.value.findIndex(item => {
        return item.scheduleId === this.item.scheduleId;
      });
      if (index !== -1) {
        if (!this.item.selected) {
          this.value.splice(index, 1);
        }
      } else {
        if (this.item.selected) {
          this.value.push(this.item);
        }
      }
    },
    initVehicles() {
      for (const trip of this.item.trips) {
        if (trip.govnum) {
          const index = this.vehicles.findIndex(item => {
            return item.govnum === trip.govnum;
          });
          if (index === -1) {
            this.vehicles.push({
              govnum: trip.govnum,
              stop: trip.locationName || '',
            });
          }
        }
      }
    },
    getStops() {
      const dateWork = $utils.formatDate(
        new Date(this.fullDate.iso), 'yyyy-MM-DD 03:00:00',
      );
      PlansService.getStops(this.item.scheduleId, dateWork)
        .then(stopPoints => {
          const firstPointName = stopPoints?.[0]?.name || '';
          const lastPointName = stopPoints[stopPoints.length - 1]?.name || '';
          this.stopNames = firstPointName + ' - ' + lastPointName;
        })
        .catch(err => console.log('TripItem::getStops', err))
      ;
    },
  },
};
</script>
<style lang="scss" scoped>
    .trip-item{
        font-size: 0.9rem;
        margin: 1rem 0;
        padding-left: 1rem;
        flex-wrap: nowrap;
        align-content: center;
        align-items: center;
        justify-content: space-between;
        border: 1px solid #777;
        border-radius: 3px;
        &__stop{
            display: inline-block;
            max-width: 50%;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        &__times{
            margin: 0 1rem;
            white-space: nowrap;
        }
        &__vehicles{
            
        }
    }
</style>