<template>
  <JetDialog
    title="Тариф"
    v-model="show"
  >

    <template v-slot:default>

      <!-- Перевозчик -->
      <v-select
        v-if="!isModerator"
        label="Перевозчик"
        v-model="newCarrier"
        :items="carriers"
        :loading="loadingCarriers"
        item-text="shortName"
        item-value="carrierId"
        color="grey"
      ></v-select>
      <div v-else>
        Перевозчик:
        <span class="black--text">{{ tariff.shortName }}</span>
      </div>

      <!-- Дата -->
      <div>
        Дата:
        <span v-if="tariff.uploadDt" class="black--text">{{ tariff.uploadDt }}</span>
        <span v-else class="grey--text">Отсутствует</span>
      </div>

      <!-- Файл -->
      <div>
        Файл:
        <v-btn
          v-if="tariff.file"
          class="pa-0"
          text
          style="text-transform: initial"
          @click="openFile(tariff.file.ref)"
        >
          <u>{{ file && file.name || tariff.file.name || 'Файл' }}</u>
        </v-btn>
        <span v-else>
        отсутствует
      </span>
        <v-btn v-if="!isModerator" small class="ml-2" color="primary" outlined @click="onButtonClick">
          Изменить
        </v-btn>
        <input
          ref="uploader"
          class="d-none"
          type="file"
          accept="image/*"
          @change="onFileChanged"
        >
      </div>

    </template>

    <template v-slot:actions>
      <v-spacer v-if="isModerator" />
      <v-btn @click="show = false" outlined color="red">Закрыть</v-btn>
      <v-spacer/>
      <template v-if="!isModerator">
        <v-btn @click="edit" color="primary" :loading="loadingBtn">Сохранить</v-btn>
      </template>
    </template>


  </JetDialog>
</template>

<script>
// Components
import JetDialog from "@/components/JetDialog";

// Service
import OtherService from "@/services/OtherService";

// Mixins
import SendFileMixin from "@/components/dev/mixin/SendFileMixin";

export default {
  name: "ShowTariff",
  components: {
    JetDialog,
  },
  mixins: [
    SendFileMixin,
  ],
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    tariff: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    isModerator: false,
    isSelecting: false,
    carriers: [],
    loadingCarriers: true,
    newCarrier: null,
    loadingBtn: false,
  }),
  created() {
    this.isModerator = !!this.$store.state.profile.subject.roles[OtherService.MODERATOR_ROLE_GUID];
    this.init();
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set() {
        this.$emit('input', false);
      },
    },
  },
  methods: {
    // Инициализация
    async init() {
      this.loadingCarriers = true;
      const reportCarrier = {
        carrierId: this.tariff.carrierId,
        shortName: this.tariff.shortName,
      };
      if (!this.isModerator) {
        this.carriers = await OtherService.getCarriers();
        const findCarrier = this.carriers.find(carr => carr.carrierId === this.tariff.carrierId);
        if (findCarrier) {
          this.newCarrier = findCarrier;
        } else {
          this.carriers.push(reportCarrier);
          this.newCarrier = reportCarrier;
        }
      } else {
        if (!this.tariff.isRead) {
          await OtherService.editTariffStatus(this.tariff.id);
          this.tariff.isRead = true;
        }
      }
      this.loadingCarriers = false;
    },

    // Нажатие на кнопку Изменить (Файл)
    onButtonClick() {
      this.isSelecting = true
      window.addEventListener('focus', () => {
        this.isSelecting = false
      }, {once: true})

      this.$refs.uploader.click()
    },

    // Событие при выборе файла (Получает файл)
    onFileChanged(e) {
      const file = e.target.files[0];
      this.convertFile(file);
    },

    // Изменение отчёта
    async edit() {
      this.loadingBtn = true;
      const carrierId = this.newCarrier.carrierId || this.newCarrier;
      const params = {
        id: this.tariff.id,
        carrierId: carrierId,
        file: this.toParams(),
      };
      await OtherService.editTariff(params);
      setTimeout(() => {
        this.$emit('refresh');
        this.loadingBtn = false;
      }, 1000);
    },
  },
}
</script>
