<template>
  <div>
    <v-row class="align-center">
      <v-col>
        <v-row class="align-center">
          <v-col>
            Всего записей: {{ data.length }}

            <v-tooltip
              right
              color="primary"
              v-if="hasData"
            >
              <template v-slot:activator="{ on }">
                <v-btn icon class="ml-2" @click="showAllData" v-on="on">
                  <v-icon>mdi-refresh</v-icon>
                </v-btn>
              </template>

              <span>Обновить список</span>
            </v-tooltip>
          </v-col>

          <v-col>
            <v-switch
              :disabled="!hasData"
              v-model="active"
              label="Активные"
            ></v-switch>
          </v-col>
        </v-row>

      </v-col>

      <v-col cols="6">
        <v-layout align-center>
          <v-text-field
            v-model="searchText"
            label="Поиск"
            :placeholder="placeholder"
            hide-details
            class="mr-4"
          ></v-text-field>
        </v-layout>
      </v-col>
    </v-row>

    <div :style="{height: innerHeight + 'px'}">
      <template v-if="!loadingData">
        <template v-if="hasData && filteredList.length">
          <v-data-table
            show-select
            fixed-header
            dense
            height="260"
            v-model="selected"
            :headers="headers"
            :items="filteredList"
          ></v-data-table>
        </template>

        <template v-else-if="hasData && filteredList.length === 0">
          <v-sheet
            class="fill-height py-8 text-center"
            style="position: relative; border: 1px dashed #cecece"
          >
            Ничего не найдено
          </v-sheet>
        </template>

        <template v-else>
          <v-sheet
            class="fill-height py-8"
            style="position:relative; border: 1px dashed #cecece"
          >
            <v-btn
              outlined
              absolute
              color="grey"
              @click="showAllData"
              style="left: 50%; top: 50%; transform: translate(-50%, -50%)"
            >
              Показать все записи
            </v-btn>
          </v-sheet>
        </template>
      </template>

      <div v-else class="pa-4 text-center">
        <v-progress-circular
          indeterminate
          color="primary"
        ></v-progress-circular>
      </div>
    </div>
  </div>
</template>

<script>
import MapLayersService from '@/services/MapLayersService';

export default {
  name: 'StopsMapLayer',
  props: {
    placeholder: {
      type: String,
      required: false,
      default: 'Введите название',
    },
    // свойство, передаваемое через v-model
    value: {
      type: Array,
      required: true,
    },
    needCancel: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  watch: {
    searchText: function(val) {
      if (val) {
        // Если данные еще не загружены с сервера - получаем их
        if (!this.hasData && !this.loadingData) {
          this.showAllData();
        }
      }
    },
    selected: function(val) {
      this.$emit('input', _copy(val));
    },
    needCancel: function() {
      this.selected = _copy(this.value);
    },
  },
  data: () => ({
    innerHeight: 300,
    active: false,
    hasData: false,
    loadingData: false,
    data: [],
    searchText: '',
    selected: [],
    headers: [
      { text: 'Наименование', value: 'locName' },
      { text: 'Тип остановки', value: 'objectTypeName' },
      { text: 'Населенный пункт', value: 'twnTownName' },
    ],
  }),
  computed: {
    // Список, фильтированный по статусу активности и введенной строке поиска
    filteredList: function() {
      return this.data.filter(item => {
        // Проверка на активность
        if (this.active) {
          if (this.itemIsChecked(item) === -1) {
            return false;
          }
        }

        // Проверка на введенную строку
        if (this.searchText) {
          return this.checkByStr(item);
        }

        // На всякий случай - установка текущего статуса активности
        // item.checked = this.itemIsChecked(item) !== -1;

        return true;
      });
    }
  },
  methods: {
    // Проверка элемента на соответствие введенной строке
    checkByStr: function(item) {
      let strSearch = new RegExp(this.searchText, 'i');

      return strSearch.test(item.locName);
    },
    // Проверка зачекан элемент или нет
    itemIsChecked: function(item) {
      return this.value.findIndex(checked => {
        return checked.id === item.id;
      });
    },
    // Получение данных с сервера
    showAllData: async function() {
      this.loadingData = true;

      const data = await MapLayersService.getMapStops();

      this.data = _copy(data);
      this.hasData = true;

      this.loadingData = false;
    },
  },
}
</script>

<style scoped>

</style>
