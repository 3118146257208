import store from '@/store';
import { prepareSinRequest, prepareSinResponse } from '../utils/http';
import md5 from 'blueimp-md5';

/**
 * Сервис предназначенный для уменьшения кол-ва сервисов содержащих
 * один-два метода
 */
export default class OtherService {
  // Статусы уведомлений
  static STATUS_NOTIFICATION = {
    // подтверждение получения
    CONFIRM_DELIVERY: 1,
    // подтверждение прочтения
    CONFIRM_READ: 2,
    // начало диалога
    BEGIN_DIALOG: 3,
    // конец диалога
    END_DIALOG: 4,
  };

  // Имя роли: "25. Диспетчерская"
  static MODERATOR_ROLE_GUID = '282f9418-7c77-45c5-8b63-10eda1c9cc47';

  static async getDisruption(eventId, regid) {
    try {
      const queryDisr = `sin2:/v:90e242ce-edc1-45b3-92f3-b267c97b9bf4/?filter=eq(field(".id"),param("${eventId}", "id"))`;
      await store.dispatch('cache/removeItem', md5('core-read' + queryDisr));
      let disruption = await prepareSinResponse(
        await prepareSinRequest(
          'core-read',
          queryDisr,
        ),
        {
          hideUnderscores: true,
          keyReplace: {
            'vehicleid.govnum': 'govnum',
            'typename': 'typeName',
            'vehicleid': 'vehicleId',
            'tripid.schedule.tripcode': 'tripCode',
            'tripid.route.routecode': 'routeCode',
            'tripid.route.routename': 'routeName',
          },
        }
      );
      disruption = disruption.length >= 1 ? disruption[0] : {};
      const queryNotification = `sin2:/v:eb0360b1-14f0-4f0b-9ea4-f7c30a370eb9/?filter=eq(field(".regid"),param("${regid}","id"))`;
      await store.dispatch('cache/removeItem', md5('core-read' + queryNotification));
      let notification = await prepareSinResponse(
        await prepareSinRequest(
          'core-read',
          queryNotification,
        ),
        {
          hideUnderscores: true,
          keyReplace: {
            'regid.status': 'status',
            'regid.eventid': 'eventId',
          },
        }
      );
      notification = notification.length >= 1 ? notification[0] : {};
      if (disruption) {
        disruption.routeName = disruption.routeCode + ' ' + disruption.routeName;
        disruption.time = disruption.eventdt ? $utils.formatDate2(new Date(disruption.eventdt), 'dd/MM/yyyy HH:mm:ss',) : '';
        disruption.comment = disruption.comment || '';
        disruption.isDisruption = true;
        disruption.regid = regid;
        if (notification) {
          disruption.status = notification ? notification.status : -1;
          disruption.readdt = notification.readdt;
        }
      }
      return disruption;
    } catch (e) {
      console.log('OtherService::getDisruption', e);
      return {};
    }
  };

  /**
   * Получение всех нарушений
   * @param {{
   *   onlyEntity: {Boolean} определяет вернуть сущность(id, title, text) или полную запись
   *   addOld: {Boolean} определяет доблять ли решенные нарушения
   *   regid: {String} венёт нарушение с таким id
   * }} params
   * @return {Array}
   */
  static async getDisruptions() {
    try {
      let queryDisruptions = '26c2cbb2-fd6d-4919-9249-4143241fe817.getTripsControlEvents';
      const isModerator = !!store.state.profile.subject.roles[OtherService.MODERATOR_ROLE_GUID];
      queryDisruptions += isModerator ? 'T' : '2';
      await store.dispatch('cache/removeItem', md5('query' + queryDisruptions));
      const disruptions = await prepareSinResponse(
        await prepareSinRequest(
          'query',
          queryDisruptions,
          '',
          [],
          {
            in_userID: store.state.auth.subject.id,
            in_tenantId: store.state.auth.subject.tenantId,
          },
        ),
        {
          hideUnderscores: true,
          keyReplace: {
            typename: 'typeName',
            tripcode: 'tripCode',
            vehicleid: 'vehicleId',
            stopname: 'stopName',
            routecode: 'routeCode',
            routename: 'routeName',
            eventdt: 'eventdt',
          },
        }
      );
      let queryNotifications = `sin2:/v:eb0360b1-14f0-4f0b-9ea4-f7c30a370eb9/?filter=and(`;
      queryNotifications += isModerator ? '' : `eq(field(".userID"),param("${store.state.auth.subject.id}","id")),`;
      queryNotifications += 'isnull(field(".closedt")),eq(field(".regid.eventtypeid"),param("90e242ce-edc1-45b3-92f3-b267c97b9bf4","id")))';
      await store.dispatch('cache/removeItem', md5('core-read' + queryNotifications));
      const notifications = await prepareSinResponse(
        await prepareSinRequest(
          'core-read',
          queryNotifications,
        ),
        {
          hideUnderscores: true,
          keyReplace: {
            'regid.status': 'status',
            'regid.eventid': 'eventId',
          },
        }
      );
      disruptions.forEach(dis => {
        dis.routeName = dis.routeCode + ' ' + dis.routeName;
        dis.time = dis.eventdt ? $utils.formatDate2(new Date(dis.eventdt), 'dd/MM/yyyy HH:mm:ss',) : '';
        dis.comment = dis.comment || '';
        dis.isDisruption = true;
        const ntf = notifications.find(ntf => ntf.eventId === dis.id);
        if (ntf) {
          dis.status = ntf ? ntf.status : -1;
          dis.regid = ntf.regid;
          dis.readdt = ntf.readdt;
        }
      });
      return disruptions;
    } catch (e) {
      console.log('OtherService::getDisruptions', e);
      return [];
    }
  };

  /**
   * Получение всех уведомлений
   * @returns {Array}
   */
  static async getAllNotifications() {
    try {
      const userId = store.state.auth.subject.id;
      const tenantId = store.state.auth.subject.tenantId;
      const dateBefore = $utils.formatDate2(new Date() - 345600000, 'yyyy-MM-dd'); //Выводим уведомления по событиям, произошедшим не более 4 дней назад
      const query = `sin2:/v:eb0360b1-14f0-4f0b-9ea4-f7c30a370eb9/?filter=and(eq(field(".userID"),param("${userId}","id")),eq(field(".tenantId"),param("${tenantId}","id")),gte(field(".regID.filterEventDt"),param("${dateBefore}","date")))&sort=-vcEventRegisterState.regID.filterEventDt`;
      await store.dispatch('cache/removeItem', md5('core-read' + query));
      const allNotifications = await prepareSinResponse(
        await prepareSinRequest(
          'core-read',
          query,
          '',
          [],
          {in_userID: userId},
        ),
        {
          hideUnderscores: true,
          keyReplace: {
            'regid.description': 'text',
            'regid.eventid': 'eventId',
            'regid.eventtypeid': 'eventType',
            'closedt': 'closedt',
          },
        }
      );
      allNotifications.forEach(ntf => {
        ntf.isDisruption = ntf.eventType === '90e242ce-edc1-45b3-92f3-b267c97b9bf4';
        ntf.isMessage = ntf.eventType === '1ca71970-ae32-4a13-bbfb-9deecf092ab3';
        ntf.title = ntf.isDisruption ? 'Срыв' : 'Сообщение';
        ntf.isOld = !!ntf.closedt || (ntf.isMessage && !!ntf.readdt);
      });
      return allNotifications;
    } catch (e) {
      console.log('OtherService::getAllNotifications', e);
      return [];
    }
  };

  /**
   * Отправление сообщения в "Чат"
   * @param {String} regId - id записи в таблице уведомлений
   * @param {String} msg - сообщение
   * @param {{
   *   name: {String} имя файла
   *   base64: {String} файл преобразованный к base64 без всяких заголовков
   * }} file
   * @param {String} justificationType - тип обоснования
   * @returns {Promise<*>}
   */
  static async sendMessage(regId, msg, file, justificationType) {
    const date = $utils.formatDate2(new Date(), 'yyyy-MM-dd HH:mm:ss');
    const userId = store.state.auth.subject.id;
    const tenantId = store.state.auth.subject.tenantId;

    const params = [
      {id: 'id', type: 'id', value: jet.utils.uuidv4()},
      {id: 'commDt', type: 'date', value: date},
      {id: 'userID', type: 'id', value: userId},
      {id: 'tenantID', type: 'id', value: tenantId},
      {id: 'regID', type: 'id', value: regId},
      {id: 'message', type: 'string', value: msg},
    ];

    if (file) {
      params.push({
          id: 'file',
          type: 'blob',
          value: file.base64,
        },
        {
          id: 'filename',
          value: file.name,
          type: 'string',
        });
    }
    if (justificationType) {
      params.push({
        id: 'reason',
        type: 'id',
        value: justificationType,
      });
    }

    return await jet.http.post({
      type: 'core-create',
      query: 'sin2:/v:ee0b2307-1d58-49b8-9ab2-5078f13c434c',
      params: params,
    });
  };

  /**
   * Получение чата
   * @param {String} regId - id записи в таблице уведомлений
   * @returns {Promise<*[][]|{commdt: *, file: *, isMy: boolean, id: *, message: *}[]>}
   */
  static async getDisruptionChat(regId) {
    const data = await jet.http.post({
      type: 'core-read',
      query: `sin2:/v:ee0b2307-1d58-49b8-9ab2-5078f13c434c/?filter=eq(field(".regID"),param("${regId}","id"))`,
    });
    if (!!data && data.result) {
      const result = data.result || {};
      const items = result.data || [];
      const ci = result.columnIndexes || {};
      const userId = store.state.auth.subject.id;

      const requests = items.map(it => ({
        id: it[ci['vceventcomm.id']],
        commdt: it[ci['vceventcomm.commdt']],
        message: it[ci['vceventcomm.message']],
        file: it[ci['vceventcomm.file']],
        reason: it[ci['vceventcomm.reason']],
        isMy: userId === it[ci['vceventcomm.userid']],
      }));
      requests.sort((a, b) => {
        return new Date(a.commdt).getTime() - new Date(b.commdt).getTime();
      });
      requests.forEach((msg) => {
        if (msg.file) {
          msg.file.name = msg.file?.attrs?.['Key{name=fileName, type=java.lang.String}'] || '';
        }
      });
      return requests;
    }
    console.log('OtherService::getMessages', data.error);
    return [[], []];
  };

  static async getIncident(event) {
    const data = await jet.http.post({
      type: 'core-read',
      query: `sin2:/v:1ca71970-ae32-4a13-bbfb-9deecf092ab3/?filter=eq(field(".id"),param("${event.eventid}","id"))`,
    });

    if (!!data && data.result) {
      if (!event.recievedt) {
        OtherService.editStatus(event.regid, OtherService.STATUS_NOTIFICATION.CONFIRM_DELIVERY);
      }

      const result = data.result || {};
      const items = result.data || [];
      const item = items[0] || {};
      const ci = result.columnIndexes || {};

      return {
        id: item[ci['incidents.id']],
        regid: event.regid,
        title: item[ci['incidents.title']],
        text: item[ci['incidents.text']] || '',
        file: item[ci['incidents.file']],
        date: (item[ci['incidents.eventdate']] ? $utils.formatDate2(new Date(item[ci['incidents.eventdate']]), 'dd.MM.yyyy HH:mm:ss') : ''),
        type: item[ci['incidents.type']],
        typeCode: item[ci['incidents.type.code']],
        readdt: event.readdt,
        isMessage: true,
      };
    }
    console.log('OtherService::getIncident', data.error);
    return {};
  }

  static async getIncidents({onlyEntity = false, addOld = false} = {}) {
    const userId = store.state.auth.subject.id;
    let query = 'sin2:/v:eb0360b1-14f0-4f0b-9ea4-f7c30a370eb9/?filter=';
    query += addOld ? `and(eq(field(".userID"),param("${userId}","id")),eq(field(".regid.eventtypeid"),param("1ca71970-ae32-4a13-bbfb-9deecf092ab3","id")))`
      : `and(and(eq(field(".userID"),param("${userId}","id")),eq(field(".regid.eventtypeid"),param("1ca71970-ae32-4a13-bbfb-9deecf092ab3","id"))), isnull(field(".readdt")))`;
    let data;
    try {
      data = await jet.http.post({
        type: 'core-read',
        query: query,
      });
    } catch (e) {
      console.log('OtherService::getIncidents', data.error);
      return [];
    }
    if (!!data && data.result) {
      const result = data.result || {};
      const items = result.data || [];
      const ci = result.columnIndexes || {};

      const promisesOrEntities = [];
      items.forEach(it => {
        const event = {
          eventid: it[ci['vceventregisterstate.regid.eventid']],
          regid: it[ci['vceventregisterstate.regid']],
          recievedt: !!it[ci['vceventregisterstate.recievedt']],
          readdt: !!it[ci['vceventregisterstate.readdt']],
          title: 'Сообщение',
          text: it[ci['vceventregisterstate.regid.description']] || '',
          isMessage: true,
        };
        promisesOrEntities.push(
          onlyEntity ? event : OtherService.getIncident(event)
        );
      });
      if (onlyEntity) {
        return promisesOrEntities;
      } else {
        return await Promise.all(promisesOrEntities);
      }
    }
    console.log('OtherService::getIncidents', data.error);
    return [];
  };

  static async getAllIncidents() {
    let incidents = [];
    try {
      const query = 'sin2:/v:1ca71970-ae32-4a13-bbfb-9deecf092ab3';
      await store.dispatch('cache/removeItem', md5('core-read' + query));
      incidents = await prepareSinResponse(
        await prepareSinRequest(
          'core-read',
          query,
        )
      );
      for (const inc of incidents) {
        inc.date = new Date(inc.eventdate);
        inc.eventdate = inc.eventdate ? $utils.formatDate(inc.date, 'DD/MM/yyyy HH:mm:ss') : '';
        if (inc.file) {
          inc.file.name = inc.file?.attrs?.['Key{name=fileName, type=java.lang.String}'] || '';
        }
      }
      incidents.sort((a, b) => {
        return b.date.getTime() - a.date.getTime();
      });
    } catch (e) {
      incidents = [];
      console.error('OtherService::getAllIncidents', e);
    } finally {
      return incidents;
    }
  };

  /**
   * Получение кол-ва нерешенных уведомлений
   * @return {Integer}
   */
  static async getUnreadCount() {
    const userId = store.state.auth.subject.id;
    const tenantId = store.state.auth.subject.tenantId;
    var res = 0;
    try {
        const data = await jet.http.post({
          type: 'query',
          query: '26c2cbb2-fd6d-4919-9249-4143241fe817.getUnreadCount2',
          params: {
            in_userid: userId,
            in_tenantID: tenantId,
          },
        });
        res = data?.result?.data?.[0]?.[0] || 0;
    } catch(e){
        console.log('ERR (getUnreadCount2)', e);
        res = -1; 
    }
    return res;
  };

  /**
   * Изменение статуса уведомлений (инциденты, нарушения)
   * @param {String} regId - id  записи
   * @param {Number} mode:
   * 1 - подтверждение получения
   * 2 - подтверждение прочтения
   * 3 - начало диалога
   * 4 - конец диалога
   * @return {Boolean} isSuccess
   */
  static async editStatus(regId, mode) {
    const isModerator = store.state.profile.subject.roles[OtherService.MODERATOR_ROLE_GUID];
    if (isModerator && (mode === 1 || mode === 2)) {
      return true;
    }

    const data = await jet.http.post({
      type: 'query',
      query: '26c2cbb2-fd6d-4919-9249-4143241fe817.updateEventstate',
      params: {
        in_regid: regId,
        in_userid: store.state.auth.subject.id,
        in_mode: mode,
      },
    });
    let number = data?.result?.data?.[0]?.[0];
    if (data.error) {
      number = 4;
      _err('Ошибка: ', data.error);
    }
    if (number >= 1) {
      const state = ['Удача', 'Не найдена запись в реестре', 'Состояние уже ранее было установлено', 'Не найден или заблокирован пользователь', 'Неизвестная ошибка'];
      _log('Ошибка:', state[number] || 'незарегистрированный ответ от сервера', {regId, mode,});
      jet.msg({
        text: state[number] || 'незарегистрированный ответ от сервера',
        color: 'error'
      });
      return false;
    }
    return number === 0;
  };

  static setReadIncident(incidentId) {
    const tenantId = store.state.auth.subject.tenantId;
    jet.http.post({
      type: 'query',
      query: 'e9d62d7e-cdf8-4250-a60a-04130c2ad10e.recieveCarrierIncident',
      params: {
        in_incidentID: incidentId,
        in_tenantID: tenantId,
      },
    });
  };

  static async getStatusIncident(incidentId) {
    try {
      const data = await prepareSinResponse(
        await prepareSinRequest(
          'query',
          '26c2cbb2-fd6d-4919-9249-4143241fe817.getIncidentRecievers',
          '',
          [],
          {
            in_incidentID: incidentId,
          },
          {
            cache: false,
          },
        ),
        {
          hideUnderscores: true,
          keyReplace: {
            id: 'incidentId',
            shortname: 'shortName',
            tenantid: 'tenantId',
          },
        }
      );
      data.forEach(el => {
        el.date = el.readdt ? $utils.formatDate2(el.readdt, 'dd.MM.yyyy HH:mm:ss') : '-';
        el.status = el.readdt ? 'Прочитано' : 'Не прочитано';
      });
      return data;
    } catch (e) {
      console.log("OtherService::getStatusIncident", e);
      return [];
    }
  };

  static async getCarrierReports() {
    try {
      const query = 'sin2:/v:a276ca0f-eee2-4581-b791-4cac9adb18cc/';
      await store.dispatch('cache/removeItem', md5('core-read' + query));
      let reports = await prepareSinResponse(
        await prepareSinRequest(
          'core-read',
          query,
        ),
        {
          hideUnderscores: true,
          keyReplace: {
            'carrierid': 'carrierId',
            'carrierid.orgid.shortname': 'shortName',
          },
        }
      );
      reports.forEach(rep => {
        rep.date = rep.date ? $utils.formatDate2(new Date(rep.date), 'dd.MM.yyyy HH:mm:ss') : '';
        if (rep.file) {
          rep.file.name = rep.file?.attrs?.['Key{name=fileName, type=java.lang.String}'] || '';
        }
      });
      return reports;
    } catch (e) {
      console.log("OtherService::getCarrierReports", e);
      return [];
    }
  };

  static async createCarrierReport(param) {
    try {
      const query = 'sin2:/v:4e5f6ce4-c33e-4b81-ba81-ee42354ac40d';
      const params = [
        {id: 'id', type: 'id', value: jet.utils.uuidv4()},
        {id: 'title', type: 'string', value: param.title},
        {id: 'carrierId', type: 'id', value: param.carrierId},
        {id: 'status', type: 'integer', value: '0'},
        {id: 'date', type: 'date', value: new Date().getTime()},
      ];
      if (param.file) {
        params.push(...param.file);
      }
      await store.dispatch('cache/removeItem', md5('core-create' + query));
      let reports = await prepareSinRequest(
        'core-create',
        query,
        '',
        [],
        params,
      );
    } catch (e) {
      console.log("OtherService::getCarrierReports", e);
    }
  };

  static async editCarrierReport(param) {
    try {
      const query = 'sin2:/v:4e5f6ce4-c33e-4b81-ba81-ee42354ac40d';
      const params = [
        {id: 'id', type: 'id', value: param.id},
      ];
      if (param.title) {
        params.push({id: 'title', type: 'string', value: param.title});
      }
      if (param.carrierId) {
        params.push({id: 'carrierId', type: 'id', value: param.carrierId});
      }
      if (param.comment) {
        params.push({id: 'comment', type: 'string', value: param.comment});
      }
      if (param.status || param.status === 0) {
        params.push({id: 'status', type: 'integer', value: param.status});
      }
      if (param.file) {
        params.push(...param.file);
      }
      await store.dispatch('cache/removeItem', md5('core-update' + query));
      let reports = await prepareSinRequest(
        'core-update',
        query,
        '',
        [],
        params,
      );
    } catch (e) {
      console.log("OtherService::editCarrierReport", e);
    }
  };

  static async getCarriers() {
    try {
      const query = '3cf7e495-36a4-45c9-8a01-bad252fdaa7f.getCarriersHierarchy';
      await store.dispatch('cache/removeItem', md5('query' + query));
      let carriers = await prepareSinResponse(
        await prepareSinRequest(
          'query',
          query,
          '',
          [],
          {
            in_tenantId: store.state.auth.subject.tenantId,
          },
        ),
        {
          hideUnderscores: true,
          keyReplace: {
            'id': 'carrierId',
            'tenantid': 'tenantId',
            'name': 'shortName',
          },
        }
      );
      return carriers;
    } catch (e) {
      console.log("OtherService::getCarriers", e);
      return [];
    }
  };

  /**
   * Получение перевозчиков с ролью "Пользователь перевозчика" иерархически
   * по текущей/нижестоящим организациям для создания Инцидентов.
   */
  static async getCarriersHierarchyByRole() {
    try {
      const query = 'e9d62d7e-cdf8-4250-a60a-04130c2ad10e.getCarriersHierarchyByRole';
      await store.dispatch('cache/removeItem', md5('query' + query));
      return await prepareSinResponse(
        await prepareSinRequest(
          'query',
          query,
          '',
          [],
          {
            in_tenantId: store.state.auth.subject.tenantId,
          },
        ),
        {
          hideUnderscores: true,
          keyReplace: {
            'id': 'carrierId',
            'tenantid': 'tenantId',
            'name': 'shortName',
          },
        }
      );
    } catch (e) {
      console.log("OtherService::getCarriersHierarchyByRole", e);
      return [];
    }
  };

  /**
   *
   * @param id|null - если есть id то вернёт только одну запись
   * @returns {Promise<*[]|*>}
   */
  static async getTariffs(id = null) {
    try {
      let query = 'sin2:/v:8786d1f8-4a79-4e63-8496-5e180c20362e/';
      query += id ? `?filter=eq(field(".id"), param("${id}", "id")` : '';
      await store.dispatch('cache/removeItem', md5('core-read' + query));
      let tariffs = await prepareSinResponse(
        await prepareSinRequest(
          'core-read',
          query,
        ),
        {
          hideUnderscores: true,
          keyReplace: {
            'carrierid': 'carrierId',
            'carrier.orgid': 'orgId',
            'carrierid.orgid.shortname': 'shortName',
            'isread': 'isRead',
            'readdt': 'readDt',
            'uploaddt': 'uploadDt',
          },
        }
      );
      tariffs.forEach(tr => {
        tr.uploadDt = tr.uploadDt ? $utils.formatDate2(new Date(tr.uploadDt), 'dd.MM.yyyy HH:mm:ss') : '';
        if (tr.file) {
          tr.file.name = tr.file?.attrs?.['Key{name=fileName, type=java.lang.String}'] || '';
        }
      });
      if (id) {
        if (tariffs.length !== 1) {
          console.log("OtherService::getTariffs 'Ожидалась одна запись, а получено несколько'");
        }
        return tariffs[0];
      }
      return tariffs;
    } catch (e) {
      console.log("OtherService::getTariffs", e);
      return [];
    }
  };

  static async createTariff(param) {
    try {
      const query = 'sin2:/v:7f8ba48b-aa4b-4668-a635-41566bd2ec68';
      const params = [
        {id: 'id', type: 'id', value: jet.utils.uuidv4()},
        {id: 'carrierId', type: 'id', value: param.carrierId},
        {id: 'uploadDt', type: 'date', value: new Date().getTime()},
      ];
      if (param.file) {
        params.push(...param.file);
      }
      await store.dispatch('cache/removeItem', md5('core-create' + query));
      await prepareSinRequest(
        'core-create',
        query,
        '',
        [],
        params,
      );
    } catch (e) {
      console.log("OtherService::createTariff", e);
    }
  };

  static async editTariff(param) {
    try {
      const query = 'sin2:/v:7f8ba48b-aa4b-4668-a635-41566bd2ec68';
      const params = [
        {id: 'id', type: 'id', value: param.id},
      ];
      if (param.carrierId) {
        params.push({id: 'carrierId', type: 'id', value: param.carrierId});
      }
      if (param.file) {
        params.push(...param.file);
      }
      await store.dispatch('cache/removeItem', md5('core-update' + query));
      let reports = await prepareSinRequest(
        'core-update',
        query,
        '',
        [],
        params,
      );
    } catch (e) {
      console.log("OtherService::editTariff", e);
    }
  };

  // Set Read
  static async editTariffStatus(id) {
    try {
      const query = 'f056a1c4-386b-465d-bac1-0229939acad6.readTariffNotification';
      await store.dispatch('cache/removeItem', md5('query' + query));
      let reports = await prepareSinRequest(
        'query',
        query,
        '',
        [],
        {
          in_ID: id,
        }
      );
    } catch (e) {
      console.log("OtherService::editTariffStatus", e);
    }
  };

  static async addNotificationTariffs(id, notificationId) {
    try {
      const query = 'sin2:/v:dce13d96-8ff4-4fdb-a2a8-3882c865e685';
      await store.dispatch('cache/removeItem', md5('core-update' + query));
      await prepareSinRequest(
        'core-update',
        query,
        '',
        [],
        [
          {id: 'id', type: 'id', value: id},
          {id: 'notificationId', type: 'id', value: notificationId},
        ],
      );
    } catch (e) {
      console.log("OtherService::addNotificationTariffs", e);
    }
  };

  static async getAllLocations() {
    let locations = [];
    try {
      const query = 'sin2:/v:fb5dc698-f3b3-48f3-950c-38817427a59e';
      await store.dispatch('cache/removeItem', md5('core-read' + query));
      locations = await prepareSinResponse(
        await prepareSinRequest(
          'core-read',
          query,
        )
      );
    } catch (e) {
      locations = [];
      console.error('OtherService::getAllLocations', e);
    } finally {
      return locations;
    }
  };

  static async getLocationsSchedules(stop1, stop2) {
    let schedules = [];
    try {
      let query = 'sin2:/v:94d55a6d-d914-4d9a-969e-e8be2ff339ce/?filter=';
      let filter = "";
      if (stop1 != null) {
        filter = "eq(field(\".routePoint.location\"),param(\"" + stop1 + "\",\"id\"))";
        if (stop2 != null) {
            filter = "and(" + filter + ",exists(\"94d55a6d-d914-4d9a-969e-e8be2ff339ce\",\"and(eq(field(\\\".routePoint.location\\\"), param(\\\"" + stop2 + "\\\",\\\"id\\\")),eq(field(\\\".routePoint.route\\\"), super.field(\\\".routePoint.route\\\")), eq(field(\\\".scheduleID\\\"), super.field(\\\".scheduleID\\\")), gt(field(\\\".routePoint.pointnumber\\\"), super.field(\\\".routePoint.pointnumber\\\")))\"))";
        }
        query += filter;
        await store.dispatch('cache/removeItem', md5('core-read' + query));
        schedules = await prepareSinResponse(
          await prepareSinRequest(
            'core-read',
            query,
          )
        );
      }
      let n = 1;
      schedules.forEach(sch => {
        sch.n = n;
        n++;
      });
    } catch (e) {
      schedules = [];
      console.error('OtherService::getLocationsSchedules', e);
    } finally {
      return schedules;
    }
  };

  static async getScheduleStops(scheduleID) {
    let schedule = [];
    try {
      const query = 'sin2:/v:84705776-b9d2-4e73-bd8c-dfa7a81a56d5/?filter=eq(field(".scheduleID.ID"),param("' + scheduleID + '","id"))&sort=scheduleByTrip.routePoint.pointNumber';
      await store.dispatch('cache/removeItem', md5('core-read' + query));
      schedule = await prepareSinResponse(
        await prepareSinRequest(
          'core-read',
          query,
        )
      );
      schedule.forEach(sch => {
        sch.routepointDistance = Math.floor(sch.routepointDistance);
      });
    } catch (e) {
      schedule = [];
      console.error('OtherService::getScheduleStops', e);
    } finally {
      return schedule;
    }
  };
}
