<template>
  <div>
    <!-- Верхний ToolBar с фильтрами -->
    <v-toolbar>
      <v-row class="pt-4 align-center">

        <!--Группа-->
        <v-col>
          <v-select
            label="Группа"
            placeholder="Выберите группу маршрутов"
            :items="groups"
            item-text="title"
            item-value="id"
            v-model="group"
            :disabled="disableGroups"
            hide-details
          ></v-select>
        </v-col>

        <!--Дата-->
        <v-col>
          <v-menu
            ref="menu1"
            v-model="menu1"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="290px"
          >
            <template #activator="{ on, attrs }">
              <v-text-field
                readonly persistent-hint hide-details
                label="Дата"
                v-model="formattedDate"
                v-on="on"
                v-bind="attrs"
              ></v-text-field>
            </template>

            <v-date-picker
              no-title
              v-model="date"
              :first-day-of-week="1"
              :weekday-format="dayOfWeekToFormat"
              @input="menu1 = false"
            ></v-date-picker>
          </v-menu>
        </v-col>

        <!--Маршрут-->
        <v-col>
          <v-text-field
            hide-details
            label="Маршрут"
            placeholder="Номер или название"
            v-model="route_name"
          ></v-text-field>
        </v-col>

        <!--Перевозчик-->
        <v-col>
          <v-text-field
            hide-details
            label="Перевозчик/ТС"
            placeholder="Рег.номер ТС или название"
            v-model="vehicle_num"
          ></v-text-field>
        </v-col>

        <!--Тип нарушения-->
        <v-col>
          <v-select
            hide-details multiple
            label="Тип нарушения"
            item-text="title"
            item-value="id"
            placeholder="Выберите тип"
            v-model="type"
            :items="types"
          >
            <template #selection="{ item, index }">
              <v-chip v-if="index === 0" small>
                <span>{{ item }}</span>
              </v-chip>
              <span
                v-if="index === 1"
                class="grey--text caption"
              >
                (+{{ type.length - 1 }} тип{{ type.length >= 6 ? 'ов' : type.length >= 3 ? 'a' : '' }})
              </span>
            </template>

            <template #item="{item}">
              <v-badge
                dot inline
                :color="getColorByViolation(item)"
              ></v-badge>
              {{ item }}
            </template>
          </v-select>
        </v-col>
      </v-row>
    </v-toolbar>

    <!-- Нижний ToolBar c кнопками -->
    <v-toolbar>
      <v-row class="align-center">

        <!-- Отображено маршрутов -->
        <v-col class="col-auto">
          Отображено маршрутов: {{ countViolations }}
        </v-col>

        <!--Обновить-->
        <v-col class="col-auto">
          <v-col cols="auto" class="px-0">
            <v-btn @click="init" color="primary" icon title="Обновить список">
              <jet-svg xref="#ico-refresh"/>
            </v-btn>
          </v-col>
        </v-col>

        <v-spacer />

        <!--Добавить нарушение-->
        <v-col class="col-auto" jus>
          <v-col cols="auto">
            <NewViolation @refresh="refresh"/>
          </v-col>
        </v-col>

      </v-row>
    </v-toolbar>

    <v-data-table
      show-expand
      item-key="id"
      :headers="headers"
      :items="filtered_list"
      :single-expand="singleExpand"
      :expanded.sync="expanded"
      :loading="loading"
      disable-pagination
      hide-default-footer
    >
      <template #item.count="{ item }">
        <template
          v-for="(type, index) in getViolationTypes(item)"
        >
          <v-badge
            dot inline
            :key="`${item.id}_${index}`"
            :color="getColorByViolation(type)"
          ></v-badge>
          {{getViolationTypeShort(type)}}
        </template>
      </template>

      <template #item.countViolationOnExpand="{ item }">
        <td>
          {{ Array.isArray(item.items) ? item.items.length : '' }}
        </td>
      </template>

      <template #expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <div class="py-0">
            <VehicleViolations :headersInner="headersInner"
                               :items="item.items"
                               @onApply="apply"
                               @onMap="goMap">
            </VehicleViolations>
          </div>
        </td>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import VehicleViolations from './components/VehicleViolations';
import HideGlobalToolbarMixin from '@/components/dev/mixin/HideGlobalToolbarMixin';
import { dayOfWeekToFormat, FORMAT_FOR_SERVER, FORMAT_FOR_USER, formatDate } from '@/services/JetDate';
import { prepareSinRequest, prepareSinResponse } from '@/utils/http';
import NewViolation from '@/components/dev/modules/violations/components/NewViolation';
import JetSvg from '@/components/JetSvg';
const $moment = require('moment');

export default {
  name: 'Violations',
  components: {
    JetSvg,
    NewViolation,
    VehicleViolations,
  },
  mixins: [
    HideGlobalToolbarMixin,
  ],
  data: () => ({
    // Диалог добавления нарушения
    dialog_adding: false,
    // Шапка верхнеуровневой таблицы
    headers: [],
    // Шапка внутренней таблицы
    headersInner: [],
    // Дата
    date: new Date().toISOString().substr(0, 10),
    // Блок отображения календаря
    menu1: false,
    // Поле поиска по маршруту
    route_name: '',
    // Поле поиска по перевозчику или ТС
    vehicle_num: '',
    // Настройка открытия строки таблицы
    singleExpand: true,
    // Список открытых строк таблицы
    expanded: [],
    // Выбранные типы нарушений
    type: [],
    // Типы нарушений
    types: [],
    // Список групп маршрутов
    groups: [
      {
        title: 'Мои маршруты',
        id: '1',
      },
      {
        title: 'Все маршруты',
        id: '2',
      },
    ],
    // Текущая группа маршрутов
    group: null,
    // Отключение выбора группы
    disableGroups: false,
    // Список соответствия маршрутов с "моей" группой
    my_routes: [],
    // Объект результата с бэкенда
    result: null,
    // Загрузка
    loading: true,
    // Правильный формат дня недели
    dayOfWeekToFormat: () => '',
    //id-нарушения для вызова карты
    mapViolation: null,
    // Кол-во отображенных маршрутов
    countViolations: 0,
  }),
  computed: {
    filtered_list() {
      return this.prepareData(this.result);
    },
    formattedDate() {
      return formatDate(this.date, FORMAT_FOR_USER.ONLY_DATE, true);
    },
  },
  watch: {
    date() {
      this.init();
    },
  },
  created() {
    this.group = this.groups[1];
    this.dayOfWeekToFormat = dayOfWeekToFormat;
    this.init();
    this.getMyRoutes();
  },
  methods: {
    async init() {
      this.loading = true;
      const res = await jet.http.post({
        type: 'core-read',
        query: 'sin2:/v:b718738d-d427-4a96-bac4-e23233fb346c/?sort=-vcTripsControl.eventDt',
        dateWork: new Date(this.date)
      });
      const indexes = res.result.columnIndexes;
      let types = [];
      const columns = res.result.model.columns;

      let propsList = [
        'tempTripsControlJoin.routecode',
        'tempTripsControlJoin.routename',
        'tempTripsControlJoin.govnum',
        'tempTripsControlJoin.orgcode'
      ];

      let headers = propsList.map(item => {
        let index = columns.findIndex(column => column.id === item);

        if (index !== -1) {
          return {
            text: columns[index].title,
            value: item.toLowerCase(),
          };
        }
      });

      headers.push({ text: 'Типы нарушений', value: 'count', sortable: false });
      headers.push({ text: '', value: 'countViolationOnExpand', sortable: false, width: 5 });
      headers.push({ text: '', value: 'data-table-expand' });

      this.headers = headers;

      let propsList2 = [
        'vcTripsControl.typeName',
        'tempTripsControlJoin.tripcode',
        // "tempTripsControlJoin.shortname",
        'vcTripsControl.eventDt',
        // "vcTripsControl.writetime",
      ];

      let headers2 = propsList2.map(item => {
        let index = columns.findIndex(column => column.id === item);

        if (index !== -1) {
          return {
            text: columns[index].title,
            value: item.toLowerCase(),
          };
        }
      });

      headers2.push(
        {
          text: 'Примечание',
          value: 'comment',
          sortable: false,
        },
        {
          text: '',
          value: 'btnComment',
          sortable: false,
        },
        {
          text: '',
          value: 'action',
          sortable: false,
        },
      );

      this.headersInner = headers2;

      this.result = res;

      res.result.data.forEach((item) => {
        let typeName = ('vcTripsControl.typeName').toLowerCase();

        if (types.indexOf(item[indexes[typeName]]) === -1) {
          types.push(item[indexes[typeName]]);
        }
      });

      this.types = types;
      this.loading = false;
    },

    // Метод для получения "списока маршрутов группы"
    async getMyRoutes() {
      const res = await prepareSinResponse(
        await prepareSinRequest(
          'query',
          '948e1fa3-414a-4568-b4ed-3a5535b239e9.dsp_getDspInfoFn',
          '',
          [],
          {
            attr: 0,
            uid: this.$store.state.profile.subject.id,
            id: null,
          },
          {
            cache: false,
          },
        ),
        {
          hideUnderscores: true,
          keyReplace: {},
        },
      );
      const routes = new Set();
      res.forEach(el => routes.add(el.code));
      this.my_routes = Array.from(routes);
      this.disableGroups = this.my_routes.length === 0;
    },

    // Обработка сырых данных
    prepareData(res) {
      if (!res) {
        return [];
      }

      res = JSON.parse(JSON.stringify(res));

      let dt = {};
      const indexes = res.result.columnIndexes;
      let data = [];
      let index = 0;
      const id = ('vcTripsControl.ID').toLowerCase();
      const typeName = ('vcTripsControl.typeName').toLowerCase();
      const tripcode = ('tempTripsControlJoin.tripcode').toLowerCase();
      const routeId = ('temptripscontroljoin.routeid').toLowerCase();
      const routeCode = ('tempTripsControlJoin.routecode').toLowerCase();
      const routeName = ('tempTripsControlJoin.routename').toLowerCase();
      const carrierName = ('tempTripsControlJoin.shortname').toLowerCase();
      const govName = ('tempTripsControlJoin.govnum').toLowerCase();
      const orgCode = ('tempTripsControlJoin.orgcode').toLowerCase();
      const eventDt = ('vcTripsControl.eventDt').toLowerCase();
      const comment = ('vcTripsControl.comment').toLowerCase();
      const reasonId = ('vcTripsControl.violationReasonId').toLowerCase();

      let re = new RegExp(this.route_name, 'i');
      let reTrip = new RegExp(this.vehicle_num, 'i');

      res.result.data = res.result.data.filter(item => {
        // Фильтрация по группе
        if (this.group === 1) {
          return (this.my_routes.indexOf(item[indexes[routeId]]) !== -1);
        }

        // Фильтрация по номеру или имени маршрута
        if (this.route_name) {
          return (re.test(item[indexes[routeCode]]) || re.test(item[indexes[routeName]]));
        }

        // Фильтрация по гос.номеру ТС или по наименованию перевозчика
        if (this.vehicle_num) {
          return (reTrip.test(item[indexes[govName]])) || (reTrip.test(item[indexes[carrierName]]));
        }

        // Фильтрация по типам нарушений
        if (this.type.length) {
          return (this.type.indexOf(item[indexes[typeName]]) !== -1);
        }

        // Фильтрация по дате
        if (this.date) {
          return formatDate(item[indexes[eventDt]], FORMAT_FOR_SERVER.ONLY_DATE, true) === this.date;
        }

        return true;
      });

      res.result.data.forEach((item, ind) => {
        let itemKey = item[indexes[routeName]] + item[indexes[govName]];

        // Формируем группу Маршрут/ТС
        if (!dt[itemKey]) {
          dt[itemKey] = {
            context: false,
            id: (ind + 1),
            items: [],
          };
          dt[itemKey][routeCode] = item[indexes[routeCode]];
          dt[itemKey][routeName] = item[indexes[routeName]];
          dt[itemKey][govName] = item[indexes[govName]];
          dt[itemKey][orgCode] = item[indexes[carrierName]];

          data.push(dt[itemKey]);
        }

        // Добавляем рейс в группу Маршрут/ТС
        let newDt = {};
        newDt.index = index;
        newDt[id] = item[indexes[id]];
        newDt[tripcode] = item[indexes[tripcode]];
        newDt[typeName] = item[indexes[typeName]];
        newDt[eventDt] = item[indexes[eventDt]];
        newDt.comment = item[indexes[comment]];
        newDt.reasonId = item[indexes[reasonId]];

        dt[itemKey].items.push(newDt);

        index++;
      });
      this.countViolations = index;

      return data;
    },

    // Получение списка типов нарушений в группе Маршрут/ТС
    getViolationTypes(item) {
      let types = [];

      item.items.forEach(violation => {
        if (types.indexOf(violation[('vcTripsControl.typeName').toLowerCase()]) === -1) {
          types.push(violation[('vcTripsControl.typeName').toLowerCase()]);
        }
      });

      return types;
    },
    
    getViolationTypeShort(type) {
      let short = '';

      switch (type) {
        case 'Без движения':
          short = 'ДВЖ';
          break;
        case 'Время':
          short = 'ВРМ';
          break;
        case 'Нет данных':
          short = 'ДАН';
          break;
        case 'Нет датчика':
          short = 'ДТЧ';
          break;
        case 'Расстояние':
          short = 'РСТ';
          break;
        case 'Ручной ввод':
          short = 'РВ';
          break;
        case 'ТС нет в договоре':
          short = 'ДГВ';
          break;
      }

      return short;
    },

    // Определение цвета нарушения
    getColorByViolation(type) {
      let color = 'red';

      switch (type) {
        case 'Без движения':
          color = 'purple';
          break;
        case 'Время':
          color = 'red';
          break;
        case 'Нет данных':
          color = 'grey';
          break;
        case 'Нет датчика':
          color = 'grey darken-2';
          break;
        case 'Расстояние':
          color = 'yellow';
          break;
        case 'Ручной ввод':
          color = 'teal accent-3';
          break;
        case 'ТС нет в договоре':
          color = 'orange darken-2';
          break;
      }

      return color;
    },

    // Событие при комментировании нарушений
    apply(closed) {
      this.init();
    },

    refresh({ date = null }) {
      if (this.date === date) {
        this.init();
      }
      this.date = date;
    },
    async goMap(item) {
      if (!item) {
        return false;
      }
      console.log('gomap', item);
      const MAP_ROUTES_GUID = '802b6602-a729-4b6b-b01a-772e29cb6647';  //sin2 view
      jet.msg();  //hide
      try {
          var resp = await jet.http.post({
                        type: 'core-read',
                        query: `sin2:/v:b718738d-d427-4a96-bac4-e23233fb346c/?id=${ item["vctripscontrol.id"] }`,
                        dateWork: new Date(this.date)                        
          });
          if (!!resp.error){
            throw resp.error;
          }
          if (resp.result.data.length < 1){
            throw {message: 'No data found'};
          }
          const ci = resp.result.columnIndexes,
                data= resp.result.data[0];
          const vi = {
                        id:   data[ci["vctripscontrol.id"]],
                        dt:   $moment(data[ci["vctripscontrol.eventdt"]]).toDate(),
                        lat:  data[ci["vctripscontrol.lat"]],
                        lon:  data[ci["vctripscontrol.lon"]],
                        vc: {
                            id: data[ci["vctripscontrol.vehicleid"]],
                            gov: data[ci["temptripscontroljoin.govnum"]]
                        }
          };
          vi.time = vi.dt.getTime();
          vi.dt1 = $moment(vi.dt).add(-5, 'm').toDate();
          vi.dt2 = $moment(vi.dt).add(5, 'm').toDate();
          vi.violation = {
            name: data[ci["vctripscontrol.typename"]],
            lat:  vi.lat,
            lon:  vi.lon
          }
          console.log('control =>', vi);
          jet.collections.open({id: MAP_ROUTES_GUID, name: "Карта"});
          setTimeout(async ()=>{
            try {
                const map = jet.collections.active.owner;
                const vehicle = await map.search(vi.vc.id);
                console.log('control (vehicle)=>', vehicle);
                map.set("track", {
                                    vehicle: vehicle, 
                                    start: vi.dt1, 
                                    end: vi.dt2
                });
                map.set("fly", vi);
                map.set("popup", {
                    vehicle: vehicle,
                    point: vi
                });
            } catch(e){
                console.log('ERR (on viola)', e);
                jet.msg({text: "Не удается получить сведения о ТС"});
            }
          }, 500);
      } catch(e){
          console.log('ERR (on viola)', e);
          jet.msg({text: "Не удается получить детали нарушения"});
      }
      
      
      
      
/*      
      this.mapViolation = {
        id: item['vctripscontrol.id'],
        dt: this.date,
      };
* 
*/
    },   //goMap
  },
};
</script>

