<template>
  <v-container fluid class="track-j">
    <!-- Заголовок если это не плеер -->
    <template v-if="!player.show">
            <v-row class="track-j__head">
              <v-col>
                <v-btn small icon dark
                       v-on:click="toxls">
                    <v-icon small>mdi-microsoft-excel</v-icon>
                </v-btn>
                Журнал событий телеметрии
                <span v-if="data && data.length > 0">
                    | {{ data.length }} записей
                </span>
              </v-col>
              <v-spacer />
              <v-col cols="auto">
                <!-- Запустить анимацию -->
                <v-tooltip top>
                  <template #activator="{ on, attrs }">
                    <v-btn
                      icon
                      small
                      dark
                      color="white"
                      v-on="on"
                      v-bind="attrs"
                      :disabled="data.length === 0"
                      @click="showPlayer"
                    >
                      <v-icon small>mdi-play</v-icon>
                    </v-btn>
                  </template>
                  Запустить анимацию
                </v-tooltip>

                <!-- Открытие/Скрытие журнала -->
                <v-tooltip top>
                  <template #activator="{ on, attrs }">
                    <v-btn
                      icon
                      color="white"
                      v-on="on"
                      v-bind="attrs"
                      @click="minimizeJournal">
                      <v-icon>
                        mdi-chevron-{{ minimized ? 'up' : 'down' }}
                      </v-icon>
                    </v-btn>
                  </template>

                  {{ minimized ? 'Развернуть' : 'Свернуть' }} журнал
                </v-tooltip>
              </v-col>
            </v-row>
    </template>
    <!-- Заголовок если это плеер -->
    <template v-if="player.show">
      <v-layout align-center class="primary" style="height:44px;">
        <v-flex shrink class="text-no-wrap">
          <!-- Остановить проигрывание -->
          <v-tooltip top>
            <template #activator="{ on, attrs }">
              <v-btn
                dark icon
                v-on="on"
                v-bind="attrs"
                @click="stopAnimation"
              >
                <v-icon>mdi-stop</v-icon>
              </v-btn>
            </template>
            Остановить проигрывание
          </v-tooltip>

          <!-- Пауза анимаци -->
          <v-tooltip top>
            <template #activator="{ on, attrs }">
              <v-btn
                dark icon
                v-on="on"
                v-bind="attrs"
                @click="playOrPauseAnimation"
              >
                <v-icon>
                  mdi-{{ player.isPlay ? 'pause' : 'play' }}
                </v-icon>
              </v-btn>
            </template>

            {{ player.isPlay ? 'Приостановить' : 'Запустить' }} анимацию
          </v-tooltip>

          <!-- Увеличение скорости -->
          <v-tooltip top open-delay="300">
            <template #activator="{ on, attrs }">
              <v-btn
                dark outlined small
                v-on="on"
                v-bind="attrs"
                @click="incOrDecAnimSpeed"
              >
                {{ player.isIncreased ? '/' : 'x' }} 10
              </v-btn>
            </template>

            {{ player.isIncreased ? 'Уменьшить' : 'Увеличить' }} скорость
          </v-tooltip>
        </v-flex>

        <!-- Временная шкала -->
        <v-flex shrink class="ml-4">
          <span style="color: white;">
            {{ player.posTime || '' }}
          </span>
        </v-flex>

        <v-tooltip top>
          <template #activator="{ on, attrs }">
            <v-flex
              class="ml-4 py-1 fill-height"
              style="position:relative;"
              v-on="on"
              v-bind="attrs"
              @mousemove="onTimelineMouseMove"
            >
              <div class="fill-height" style="position:relative;">
                <div
                  style="position: absolute;left: 0;top: 0;width: 100%;height: 100%;"
                  @click="onTimelinePosChange"
                >
                  <div
                    ref="timeline_percent"
                    class="timeline_percent"
                    :style="timelinePercentStyle"
                  ></div>
                </div>

                <div ref="timeline" class="timeline_graph fill-height"/>
              </div>
            </v-flex>
          </template>

          {{ player.timelineTimeTooltip }}
        </v-tooltip>

        <v-flex shrink>
          <v-tooltip top>
            <template #activator="{ on, attrs }">
              <v-btn
                dark icon
                v-on="on"
                v-bind="attrs"
                @click="hidePlayer"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </template>
            Закрыть плеер
          </v-tooltip>
        </v-flex>
      </v-layout>
    </template>

    <!-- Основное содержимое -->
    <v-row class="track-j__main" v-show="!minimized">
        <v-col v-if="!has('data')"
               cols="12" 
               class="text-center pt-6">
          Необходимо выбрать трек для ТС
        </v-col>
        <v-col v-else cols="12" class="track-j__data">
            <v-data-table ref="table"
                          dense 
                          fixed-header
                          disable-sort
                          :headers="headers"
                          :items="data"
                          item-key="time"
                          :items-per-page="-1"
                          :item-class="getColour"
                          single-select
                          disable-pagination
                          hide-default-footer
                          class="track-j__table"
                          v-on:item-selected="selRow"
                          v-on:click:row="clickRow">
                <template v-slot:item.status="{item}">
                    {{ _vehicleStatus(item) }}
                </template>
                <template v-slot:item.time="{item}">
                    {{ formatDate(item.time, 'DD.MM.yyyy HH:mm:ss') }}
                </template>
                <template v-slot:item.speed="{item}">
                    {{ parseInt(item.speed) }}&nbsp;км/ч
                </template>
            </v-data-table>
            <div class="track-j__stats">
                <div class="journal__statistics_title">Гос. номер</div>
                <div class="journal__statistics_value">{{ statistics.govNum }} </div>
                <div class="journal__statistics_title"> |   расст.:</div>
                <div class="journal__statistics_value">{{ statistics.distance }} </div>
                <div class="journal__statistics_title"> |   время в пути:</div>
                <div class="journal__statistics_value">{{ statistics.motorTime }} </div>
                <div class="journal__statistics_title"> |   время стоянки:</div>
                <div class="journal__statistics_value">{{ statistics.stopTime }} </div>
                <div class="journal__statistics_title"> |   период:</div>
                <div class="journal__statistics_value">{{ statistics.period }} </div>
                <div class="journal__statistics_title"> |   сред.:</div>
                <div class="journal__statistics_value">{{ statistics.averageSpeed }} </div>
                <div class="journal__statistics_title"> |   макс:</div>
                <div class="journal__statistics_value">{{ statistics.maxSpeed }} </div>
            </div>
        </v-col>
    </v-row><!--.track-j-main-->
  </v-container>
</template>

<script>
import PtMonMapService from '@/components/dev/service/PtMonMapService';
import { setPadding } from '@/utils/utils';
import Map from '@/utils/map';
import {SPEED_LIMIT} from '@/const/vehicle_consts';

// Задержка в ожидании клавиш Вверх и Вниз для журнала
const JOURNAL_KEYDOWN_TIMEOUT = 50;

const $moment = require('moment');
const tz_shift= $moment(0).hours() * 1000 * 3600;

let _journalKeyTimeout = null;
let _flyTimeout = null;

// Магическое число
// Высота линии таблицы
const _lineHeight = 24; //see css

const _stop_e = (e)=>{
    if (!!e){
        e.preventDefault();
        e.stopPropagation();
    }
};

export const navStatus = function(p){
    let result;
    if (p?.speed < 0.55) {
        var duru = (!!p.duration) ? Number( p.duration) : -1;
        result = 'Остановка';
        if (duru > 0){
            if (duru > 3 * 60000){
                result = 'Стоянка';
            }
            const fmt = (duru > 3599999) ? "HH ч. mm мин." : "mm:ss";
            result += ` (${ $moment(duru - tz_shift).format(fmt) })`;
        }
    } else if (p?.status === 'OVERSPEEDING') {
        result = 'Превышение скорости';
    } else {
        result = 'Движется';
    }

    return result;
};

export default {
  name: 'TeleJournal',
  data() {
    return {
      // Журнал скрыт
      minimized: true,
      headers: [
          {text: 'Тип события', title: 'Тип события', value: '_status', id: '_status', type: 'string'},
          {text: 'Время события', title: 'Время события', value: 'time', id: 'time', type: 'datetime'},
          {text: 'Скорость', title: 'Скорость', value: 'speed', id: 'speed', type: 'integer'},
          {text: 'Местоположение', title: 'Местоположение', value: 'placeName', id: 'placeName', type: 'string'}
      ],
      // Track points
      data: [],
      vehicle: {},
      // Selected point of track
      selected: null,
      // Текущая улица
      statistics: {
         govNum: '',
         motorTime: '',
         stopTime: '',
         period: '',
         averageSpeed: '',
         maxSpeed: '',
         distance:'',
      },
      // Настройка плеера
      player: {
        // Показ/Скрытие
        show: false,
        // Временная линия
        timeline: null,
        // Флаг что анимация проигрывается
        isPlay: false,
        // Конец временного отрезка
        endTime: null,
        // Текущая временная метка
        curTime: null,
        // Скорость уже увеличена на 10
        isIncreased: false,
        // Текущее время движения ТС
        posTime: '',
        // Время под курсором мыши над таймлинией
        timelineTimeTooltip: '',
      },
    };
  },
  inject: ['flyTo', 'popupShow', 'diMapProvider'],
  mounted() {
    // Подписка на нажатия клавиш
    document.addEventListener('keydown', this.onKeyDownListener);
    document.addEventListener('keyup', this.onKeyUpListener);
  },
  destroyed() {
    // Отписка от нажатия клавиш
    document.removeEventListener('keydown', this.onKeyDownListener);
    document.removeEventListener('keyup', this.onKeyUpListener);
  },
  methods: {
    getColour(item) {
        if (item.status === 'OVERSPEEDING') {
          return "overspeed";
        } else if (item.status === 'STOP' || item.status === 'PARKING') {
            return "stop";
        }
    },
    formatDate(date, format){
      return $utils.formatDate(date, format);
    },
    _scrollSelected(){
            const table = this.$refs["table"];
            const w = $(table.$el).find('.v-data-table__wrapper');
            const r = w.find('.v-data-table__selected');
            if (r.length > 0){
                this.$vuetify.goTo(r[0], {container: w[0]});
            }
    },
    has(q){
        switch(q){
            case "data":
                return (!!this.data)&&(this.data.length>0);
            default:
                return false;
        }
    },
    // Сворачивание журнала
    minimizeJournal() {
      this.minimized = !this.minimized;
      this.$emit('minimized', this.minimized);
    },
    // Установка данных в журнал
    setData(vehicle, points) {
      //console.log('setData-points', points);
      if ((points || []).length === 0) {
        if (this.vehicle === vehicle || vehicle === null) {
          this.data = [...points];
          this.vehicle = {};
          this.selected = null;
          this.statistics = {};
        }
      } else {
        points.map( (p, n) => {
            if (p.place) {
              p.placeName = p.place;
            } else {
              p.placeName = String(parseFloat(p.lat).toFixed(5)) + "; " + String(parseFloat(p.lon).toFixed(5));
            }
            p.isSelectable = true;
            p._status = navStatus(p);
            p.index = n;
        });
        this.data = [...points];
        this.vehicle = vehicle;
        this._updateStatistics(points);
      }
    },
    // Установка новых строк данных в журнал при слежении
    addData(vehicle, point) {
      //console.log('addData-points', points);
      this.vehicle = vehicle;
      if (point.place) {
        point.placeName = point.place;
      } else {
        point.placeName = String(parseFloat(point.lat).toFixed(5)) + "; " + String(parseFloat(point.lon).toFixed(5));
      }
      if (parseInt(point.speed) > SPEED_LIMIT) {
        point.status = 'OVERSPEEDING';
      }
      point.isSelectable = true;
      point.index = this.data.length;
      point._status = navStatus(point);
      
      // Выводим последние 10 строк в журнале
      if(this.data.length == 10) {
        this.data.splice(0,1);
      }

      this.data.push({...point});
      this._updateStatistics(this.data);
    },
    clickRow(item, row){
      
        item.clicked = true;    //don`t scroll after selected
        console.log(item.clicked);
        //row.select(true);
        row.select({clicked: true});
    },
    selRow(row){
      
        if (row.value){
            this.selected = row.item;
            if (row.item.clicked){
                row.item.clicked = false;
            } else {
                this._scrollSelected();
            }

            console.log('row.item1', row.item);

            if(row.value?.clicked) {
              row.item.clicked = true;
            }
            
            console.log('row.item2', row.item);
            this.flyTo(this.vehicle, row.item);
            
            if (!!_flyTimeout){
                clearTimeout(_flyTimeout);
            }
            _flyTimeout = setTimeout(()=>{
                this.popupShow(this.vehicle, row.item);
                _flyTimeout = null;
            }, 2000);
        }
    },
    /**
     * @param {KeyboardEvent} e
     */
    onKeyDownListener(e) {
        var point = null,
            index;
        if (!!_journalKeyTimeout) {
            return;  
        }
        switch(e.key){
          case 'ArrowDown':
              index = (!!this.selected)
                        ? this.data.findIndex(it => (it.time === this.selected.time))
                        : -1;
              if (!(index < (this.data.length - 1))){
                  index = this.data.length - 2; //to last
              }
              index++;
              point = this.data[index];
              break;
          case 'ArrowUp':
            index = (!!this.selected) ?
                        this.data.findIndex(it => (it.time === this.selected.time))
                        : 1;
            if (index < 1){
                index = 1;  //to first
            }
            index--;
            point = this.data[index];
            break;
      }
      if (!!point){
            _journalKeyTimeout = setTimeout(() => {
                _journalKeyTimeout = null;
            }, JOURNAL_KEYDOWN_TIMEOUT);

            const table = this.$refs["table"];
            const data = table.createItemProps(point, index);
            data.select(true);
      }
    },
    
    //Скролл tb до необходимой точки трека с карты
    scrollTo(vehicle, point) {
      if ( 
              (this.minimized)
            ||(this.vehicle.id !== vehicle.id)
         ){
        return;
      }

      const index = this.data.findIndex(it => (it.time === point.time));
      
      if (index > -1) {
        point = this.data[index];
        const table = this.$refs["table"];
        const data = table.createItemProps(point, index);
        data.select(true);
      }
    },

    // ---- Плеер ----

    // Запуск анимации на проигрывание
    showPlayer() {
      this.player.show = true;

      this.diMapProvider().getRawMap().createOrDataSource('player_track', {
        type: 'Feature',
        properties: {
          color: '#000',
          strokeColor: '#000',
          time: '',
        },
        geometry: {
          type: 'Point',
          coordinates: [],
        },
      });

      this.diMapProvider().getRawMap().createOrDataLayer('player_track', {
        id: 'player_track',
        type: 'circle',
        source: 'player_track',
        paint: {
          'circle-color': ['get', 'color'],
          'circle-radius': 8,
          'circle-stroke-width': 2,
          'circle-stroke-color': ['get', 'strokeColor'],
        },
      });

      const pPoints = this.data;
      const startTime = pPoints[0].time;

      PtMonMapService.init(pPoints, (lon, lat, curTime) => {
        const sData = this.diMapProvider().getRawMap().getRawMap().getSource('player_track')._data;

        const prevCoords = sData.geometry.coordinates;

        if (prevCoords[0] === lon && prevCoords[1] === lat) {
          // Стоит
          sData.properties.color = 'rgba(101, 73, 133, 1)';
          sData.properties.strokeColor = 'rgba(101, 73, 133, .4)';
        } else {
          // Движется
          sData.properties.color = 'rgba(22, 179, 22, 1)';
          sData.properties.strokeColor = 'rgba(22, 179, 22, .4)';
        }

        sData.geometry.coordinates = [lon, lat];

        const time = new Date(startTime + curTime);

        const hours = setPadding(time.getHours());
        const minutes = setPadding(time.getMinutes());
        const seconds = setPadding(time.getSeconds());

        this.$set(this.player, 'posTime', `${hours}:${minutes}:${seconds}`);

        this.diMapProvider().getRawMap().createOrDataSource('player_track', _copy(sData));
      }, (svg) => {
        if (svg) {
          this.player.timeline = svg;

          this.$nextTick(() => {
            if (this.$refs.timeline) {
              this.$refs.timeline.innerHTML = svg.innerHTML;
            }
          });
        } else {
          if (this.$refs.timeline) {
            this.$refs.timeline.innerHTML = '';
          }

          this.player.timeline = null;
        }
      }, (time, endTime) => {
        this.player.curTime = time;
        this.player.endTime = endTime;
      });
    },
    // Скрытие плеера
    hidePlayer() {
      PtMonMapService.stopAnimation();

      this.player = {
        show: false,
        isPlay: false,
        curTime: null,
        endTime: null,
        timeline: null,
        isIncreased: false,
      };

      this.diMapProvider().getRawMap().createOrDataSource('player_track', {
        type: 'Feature',
        properties: {},
        geometry: {
          type: 'Point',
          coordinates: [],
        },
      });
    },
    // Остановка анимации
    stopAnimation() {
      PtMonMapService.stopAnimation();

      this.player.isPlay = false;
      this.player.curTime = null;
    },
    // Продолжить/Пауза воспроизведения
    playOrPauseAnimation() {
      if (this.player.isPlay) {
        PtMonMapService.pauseAnimation();
      } else {
        PtMonMapService.playAnimation();
      }

      this.player.isPlay = !this.player.isPlay;
    },
    // Увеличить/Уменьшить скорость анимации
    incOrDecAnimSpeed() {
      this.player.isIncreased = !this.player.isIncreased;

      PtMonMapService.setSpeedCoeff(
        this.player.isIncreased ? 100 : 10,
      );
    },
    onTimelineMouseMove(event) {
      const p = event.offsetX / this.$refs.timeline.offsetWidth;
      const startTime = this.data[0].time;
      const percent = p > 1 ? p / 100 : p;
      const fullTime = PtMonMapService.getAnimate().fullTime;

      const time = new Date(startTime + parseInt(`${fullTime * percent}`, 10));

      const hours = setPadding(time.getHours());
      const minutes = setPadding(time.getMinutes());
      const seconds = setPadding(time.getSeconds());

      this.player.timelineTimeTooltip = `${hours}:${minutes}:${seconds}`;
    },
    // При клике на тайм линии
    onTimelinePosChange(e) {
      PtMonMapService.setCurrentTime(
        e.offsetX / this.$refs.timeline.offsetWidth,
      );

      this.player.isPlay = true;
    },

    // ---------

    // Обновление статистики по загруженному треку
    _updateStatistics(trackPoints) {
      this.statistics = {};
      let motorTime = 0;
      let stopTime = 0;
      let averageSpeed = 0;
      let maxSpeed = 0;
      let distance = 0;
      let speedPoints = 0;
      let prev = null;
      trackPoints.map( it => {
                                const speed = Number(it.speed);
                                if (speed > maxSpeed) {
                                    maxSpeed = speed;
                                }
                                
                                if ( speed < .55 ) {
                                    stopTime += Number(it.duration); //Number(it.time) - Number(prev.time);
                                } else {
                                    averageSpeed += speed;
                                    speedPoints++;
                                    motorTime += Number(it.duration);
                                    if (!!prev){
                                        distance += Map.distance(prev, it);
                                    }
                                }
                                
                                prev = it;
                            }
      );
      this.statistics.govNum = this.vehicle.govnum;
      this.statistics.maxSpeed = maxSpeed ? Math.floor(maxSpeed) + ' км/ч' : '';
      this.statistics.averageSpeed = averageSpeed ? Math.floor(averageSpeed/speedPoints) + ' км/ч' : '';
      let first = trackPoints[0];
      let last = trackPoints[trackPoints.length-1];
      this.statistics.period = first && last ? $utils.formatDate2(new Date(first.time), 'dd.MM.yyyy HH:mm:ss') + $utils.formatDate2(new Date(last.time), ' - dd.MM.yyyy HH:mm:ss') : '';
      this.statistics.distance = distance ? Math.floor(distance/1000) + ' км' : '';

      const _ft = $moment(first.time);
      let _motorTime = $moment(motorTime - tz_shift);
      let _stopTime  = $moment(stopTime - tz_shift);
      
      this.statistics.motorTime = _motorTime.format('HH час. mm мин. ss сек.'); // : motorDay + $moment(motorTime).format(' дн. HH час. mm мин. ss сек.');
      this.statistics.stopTime = _stopTime.format('HH час. mm мин. ss сек.');   // stopDay <= 0 ? $moment(stopTime).format('HH час. mm мин. ss сек.') : stopDay + $moment(stopTime).format(' дн. HH час. mm мин. ss сек.');
    },
    // Обработка клика по элементу журнала
    _onJournalItemClick(point) {
      this.selected = point.time;
      this.flyTo(this.vehicle, point);
      this.popupShow(this.vehicle, point);
    },
    // Статус машинки
    _vehicleStatus(point) {
        return navStatus(point);
    },
    toxls(){
        const tb  = this.$refs["table"];
        if (typeof tb !== 'undefined') {
            const data = {
                model: {
                    name: `${this.vehicle.govnum.toUpperCase()}: журнал телеметрии за период ${this.statistics.period}`,
                    columns: this.headers
                },
                $refs: {
                    table: tb
                }
            }
            $xls.exportdata(data);
        } else {
            jet.msg({text: 'Необходимо загрузить трек ТС'});
        }
    }   //toxls
  },
  computed: {
      selVal(){
          return this.selected;
      },
    // Стили полоски временной линии
    timelinePercentStyle() {
      const width = this.player.endTime === null || this.player.curTime === null
        ? '0'
        : ((this.player.curTime * 100) / this.player.endTime) + '%';

      return {
        width: width,
      };
    },
  }
};
</script>

<style lang="scss">
    .track-j{
        height: 100% !important;
        padding: 0;
        & > *{
            width: 100%;
            margin-left: 0;
            margin-right: 0;
        }
        &__head{
            margin-top: 0;
            align-items: center;
            justify-content: space-between;
            background-color: var(--v-primary-base);
            color: #fff;
            font-size: 0.75rem;
            height: fit-content;
            & .col{
                padding-top: 0;
                padding-bottom: 0;
            }
        }
        &__main{
            height: calc(100% - 38px);
            padding: 0;
        }
        &__data{
            height: 100%;
            padding: 0 !important;
        }
        &__table{
            height: calc(100% - 71px);
            & .v-data-table__wrapper{
                overflow-y: auto;
                height: 100% !important;
                padding-bottom: 1rem;
                & .overspeed {
                    color: red;
                }
                & .stop {
                    color: blue;
                }
                & > table > tbody > tr > td{
                    height: 24px;
                    font-size: 0.75rem;
                }
            }
        }
        &__stats{
            border-top: 1px solid var(--v-secondary-lighten5);
            height: 48px;
            display: flex;
            flex-wrap: wrap;
            background: #fff;
            line-height: 1.115;
            justify-content: flex-start;
            align-content: center;
            padding: 0 0.5rem;
            font-size: 0.8rem;
            .journal__statistics_title {
              font-weight: bold;
              padding-left: 0.4rem;
            }
            .journal__statistics_value {
              padding-left: 0.4rem;
            }
        }
    }
    
/* Выбранный элемент в журнале телеметрии */
.journal__selected {
  background-color: var(--v-primary-base, #1E88E5);
  color: white;
}

.journal__selected:hover {
  color: black;
}


.timeline_graph {
  background: white;

  border-radius: 4px;
}

.timeline_percent {
  position: absolute;

  left: 0;
  top: 0;

  height: 100%;

  background-color: var(--v-primary-base);
  opacity: 0.4;
}

.timeline_graph svg {
  height: 100%;
  display: block;
}
</style>