<template>
  <div>

    <v-row>
      <v-col>
        <!-- Табы -->
        <v-tabs v-model="tab" grow color="primary">
          <v-tab>Cрывы</v-tab>
          <v-tab>Сообщения</v-tab>
        </v-tabs>
      </v-col>
      <v-col v-if="isModerator && tab === 0" align="right" align-self="center" cols="auto">
        <v-checkbox
          v-model="newOnly"
          label="Ожидают ответа"
          class="pt-0 mt-0"
          hide-details
        ></v-checkbox>
      </v-col>
      <v-col v-if="tab === 0" align="right" cols="auto">
        <JetSearchField
          v-model="search"
          style="width: 500px"
        ></JetSearchField>
      </v-col>
    </v-row>


    <v-tabs-items v-model="tab">

      <!-- Вкладка Срывы -->
      <v-tab-item>
        <DisruptionTab
          v-if="tab === 0"
          :search="search"
          :isModerator="isModerator"
          :newOnly="newOnly"
        ></DisruptionTab>
      </v-tab-item>

      <!-- Вкладка Сообщения -->
      <v-tab-item>
        <MessageTab
          v-if="tab === 1"
          :isModerator="isModerator"
        ></MessageTab>
      </v-tab-item>

    </v-tabs-items>
  </div>
</template>

<script>
// Имя роли: "25. Диспетчерская"
const MODERATOR_ROLE_GUID = '282f9418-7c77-45c5-8b63-10eda1c9cc47';

// Mixins
import HideGlobalToolbarMixin from '@/components/dev/mixin/HideGlobalToolbarMixin';

// Tabs
import MessageTab from './components/MessageTab';
import DisruptionTab from './components/DisruptionTab';
import JetSearchField from "@/components/JetSearchField";

export default {
  mixins: [
    HideGlobalToolbarMixin,
  ],
  name: 'Notifications',
  components: {
    JetSearchField,
    DisruptionTab,
    MessageTab,
  },
  data: () => ({
    tab: 0,
    search: '',
    isModerator: false,
    newOnly: false,
  }),
  computed: {
    user(){
      return this.$store.state.profile.subject;
    },
  },
  created() {
    const role = this.user.roles[MODERATOR_ROLE_GUID];
    this.isModerator = !!role;
  },
};
</script>

<!-- TODO: Убрать если не понадобится -->
<style lang="sass">
.border
  border: 1px solid black
  border-collapse: collapse
</style>
