/**
 * Вспомогательный класс для работы с картой на странице
 */

/**
 * Needed for "websocket-nats", otherwise errors in console
 */
import 'setimmediate';

import MapBoxGL from 'mapbox-gl';
import MapBoxCircle from 'mapbox-gl-circle';
import MapBoxGLDraw from '@mapbox/mapbox-gl-draw';

import MapSettingsService from '@/components/dev/service/MapSettingsService';
import MapIcons from '@/components/dev/service/MapIcons';
import MapLayersService from '@/services/MapLayersService';

// Константы
import { SPEED_LIMIT } from '@/const/vehicle_consts';


// Константные имена для Источников
const _SOURCES = {
  VEHICLES: 'source_vehicles',
  TRACK: 'source_track',
  BUS_STOP: 'source_bus_stop',
  WATCH: 'source_vehicle_tracking',
  POINTS: 'source_points',
  SPEED_UP: 'source_speed_up_points',
  // Все маркеры которые были высчитаны
  MARKERS: 'source_markers',
};

// Константные имена для Слоев
const _LAYERS = {
  VEHICLES: 'layer_vehicle_clusters',
  VEHICLE_CLUSTERS: 'vehicle-clusters',
  VEHICLE_CLUSTER_NUM: 'vehicle-clusters-num',
  TRACK: 'layer_track',
  TRACK_DIRECTION: 'layer_track_direction',
  BUS_STOP: 'layer_bus_stop',
  BUS_START: 'layer_bus_start',
  BUS_END: 'layer_bus_end',
  WATCH: 'layer_watch_vehicle',
  POINTS: 'layer_track_points',
  SPEED_UP: 'layer_speed_up_points',
  PARKING: 'layer_packing_points',
  NO_DATA: 'layer_no_data_points',
};

// События ТС
const _VEHICLE_EVENTS = {
  // Движется
  moving: 'moving',
  // Остановка
  stop: 'stop',
  // Парковка
  parking: 'parking',
  // Заблокирован
  blocked: 'blocked',
  // Тревожная кнопка
  alarm: 'alarm',
  // Превышение скорости
  speedUp: 'speed_up',
  // Нет данных | Потеря связи
  noData: 'no_data',
};

// Слои и Источники трека
let _trackIds = {};

// Экземпляр карты
let _map = null;

// Экземляр рисовалки
let _draw = null;

let _geoZoneControl = null;

// Динамический попап
let _dynamicPopup = null;

// Старое ТС за которым следили
let _oldVehicle = null;

// Выбранные точки
let _selectedPoints = [];

// Идентификаторы гео зон
let _geoZonesId = [];
let _geoZoneCircles = [];
let _geoZoneCirclesHash = {};

let _currentDrawIds = [];
let _drawCircle = null;

// Массив подписчиков
const _subscribes = {};

const voidCallback = () => {
};

let _onVehicleLayerCallback = null;

let _layerRoutes = [];

let _mapInitialized = false;

// TODO: Необходимо заменить
// const _mapToken = 'pk.eyJ1IjoibXQta2loLXJ1IiwiYSI6ImNrb2w5bG9tYjBkMjcycnFwbHVzcjVleG0ifQ.tGmI9Q6_V_qKJvOg86yIlg';
/*
 * https://account.mapbox.com/
 * user: mt-kih-ru
 * email: mt@kih.ru
 * pwd: typhoon1
 */

const _mapToken = require('@/components/dev/components/map-ext/map-tokens').MBX_STYLE;


// ------------------- PUBLIC ------------------

/**
 * Инициализация карты
 *
 * @param {Object} options Параметры карты
 * @param {Boolean} reload Необходимо пересоздание карты
 * @return {Promise<unknown>}
 */
function init(options, reload = false) {
  return new Promise((resolve) => {
    if (reload) {
      if (_map !== null) {
        _map.remove();
      }

      _map = null;
      _draw = null;
    }

    // Если карту уже создали
    if (_map !== null) {
      return;
    }
    console.log('MAP (init)', _mapToken);

    MapBoxGL.accessToken = _mapToken.t;

    // Создаем экземпляр карты
    _map = new MapBoxGL.Map({
      container: options.container,
//      style: 'mapbox://styles/mt-kih-ru/ckolbnwcranxa17no3n4hyhwp',
      style: _mapToken.s,
      center: [
        38.97603,
        45.04484,
      ],
      zoom: 8,
      maxZoom: 24,
      pitchWithRotate: false,
      preserveDrawingBuffer: true,
    });

    _draw = new MapBoxGLDraw({
      displayControlsDefault: false,
      modes: Object.assign({
        static: {
          onSetup(opts) {
            return {};
          },
          toDisplayFeatures(state, geojson, display) {
            display(geojson);
          },
        },
      }, MapBoxGLDraw.modes),
    });

    _geoZoneControl = new _GeoZoneControl();

    window._map = _map; // TODO: DELETE IT
    window._draw = _draw; // TODO: DELETE IT

    // Не уверен что это законно
    _map.addControl(_draw);

    _map.on('draw.create', () => {
      _trigger('draw.updated', _draw.getAll());
      _trigger('draw.created', null);
    });

    _map.on('draw.update', () => {
      _trigger('draw.updated', _draw.getAll());
    });

    _map.on('draw.delete', () => {
      _trigger('draw.updated', _draw.getAll());
    });

    const mapLoaded = new Promise(mResolve => {
      _map.on('load', async () => {
        await _loadImages();

        // Добавление источника для Машин
        createOrDataSource(_SOURCES.VEHICLES, {
          type: 'FeatureCollection',
          features: [],
        });

        // Добавление слоя для отрисовки Машин
        createOrDataLayer(_LAYERS.VEHICLES, {
          id: _LAYERS.VEHICLES,
          type: 'symbol',
          source: _SOURCES.VEHICLES,
          layout: {
            'text-field': ['get', 'vehicleNumber'],
            'text-justify': 'auto',
            'text-size': 11,
            'text-offset': [0, 0.5],
            'text-allow-overlap': true,
            'icon-image': 'bus-parking',
            'icon-allow-overlap': true,
            'icon-size': 0.5,
            'icon-offset': [
              0,
              -60 / 2,
            ],
          },
          filter: ['==', 'visible', true],
          'paint': {
                'text-color': '#202',
                'text-halo-color': '#fff',
                'text-halo-width': 2
          }
        });

        // Добавление источника для отрисовки машины для слежения
        createOrDataSource(_SOURCES.WATCH, {
          type: 'FeatureCollection',
          features: [],
        });

        // Добавление слоя для отрисовки слежки за ТС
        createOrDataLayer(_LAYERS.WATCH, {
          id: _LAYERS.WATCH,
          type: 'symbol',
          source: _SOURCES.WATCH,
          layout: {
            'text-field': ['get', 'vehicleNumber'],
            'icon-image': 'bus',
            'icon-size': 0.3,
            'icon-offset': [0, 15],
            'icon-rotate': ['get', 'heading'],
            'icon-ignore-placement': true,
            'icon-rotation-alignment': 'map',
          },
        });

        _mapInitialized = true;

        mResolve({
          map: _map,
          comp: this,
        });
      });
    });

    resolve(mapLoaded);
  });
}

/**
 * Получение состояния инициализация карты
 *
 * @return {boolean}
 */
function initialized() {
  return _map !== null && _mapInitialized;
}


function _isIn(src, id){
    var src = _map.getSource(src);
    if (!(!!src)){
        return false;
    }
    const data = src._data || {features:[]};
    var res = data.features.findIndex((f)=>{ return ((f.id===id)||(f.properties.deviceId===id)); }) > -1;
    return res;
}

/**
 * Отрисовка машинок
 *
 * @param {Array<Object>} vehicles Массив машинок. Если пусто - то все очистится
 * @param {CallableFunction} vehicleInfo Запрос информации о машинке
 * @param {CallableFunction} callback Дополнительный callback
 * @return {void}
 */
async function drawStaticVehicles(vehicles, vehicleInfo, callback) {
  createOrDataSource(_SOURCES.VEHICLES, {
    type: 'FeatureCollection',
    features: [],
  });

  // Если не передали никаких данных
  if (!vehicles || vehicles.length === 0) {
    // То очищаем все точки
    // TODO: может быть косяк
    this.createOrDataSource(_SOURCES.VEHICLES, {
      type: 'FeatureCollection',
      features: [],
    });

    return;
  }
  const sData = _map.getSource(_SOURCES.VEHICLES)._data;

  vehicles.forEach(it => {
      if (_isIn(_SOURCES.WATCH,  it.id)){
          return;
      }
    const pos = sData.features.findIndex(f => f.id === it.id);
    const visi= (typeof it.visible === 'undefined') ? true : it.visible;

    const feature = {
      id: it.id,
      type: 'Feature',
      properties: {
        vcId: it.id,
        deviceId: it.deviceId,
        heading: it.heading + 100,
        status: it.status,
        visible: visi,  //for hide static vc
        vehicleNumber: it.gov || 'x000XX', // TODO: it.gov не возвращает номер - проверить что там
      },
      geometry: {
        type: 'Point',
        coordinates: [
          it.lon,
          it.lat,
        ],
      },
    };

    if (pos > -1) {
        sData.features.splice(pos, 1, feature);
    }
    sData.features.push(feature);
  });

  createOrDataSource(_SOURCES.VEHICLES, sData);

  if (_onVehicleLayerCallback !== null) {
    _map.off('click', _LAYERS.VEHICLES, _onVehicleLayerCallback);
  }

  // Обработка клика по точке (машинке)
  _onVehicleLayerCallback = async (e) => {
    callback(e.features[0].properties);
    await _drawPopup(e, vehicleInfo);
  };

  _map.on('click', _LAYERS.VEHICLES, _onVehicleLayerCallback);



    // Центруем карту к последнему элементу
    if (sData.features.length < 3){
        const lastVehicle = vehicles[vehicles.length - 1];
        _map.panTo([lastVehicle.lon, lastVehicle.lat]);
    } else {
        const llb = new MapBoxGL.LngLatBounds();
        sData.features.map((f)=>{
            llb.extend(f.geometry.coordinates);
        });
        _map.fitBounds(llb, {padding: 40});
    }
}

function undrawVehicle(vehicle, dyn){
    const src = _map.getSource(dyn ? _SOURCES.WATCH : _SOURCES.VEHICLES);
    if (!!src){
        const data = src._data || {features:[]};
        const i = data.features.findIndex(f => (f.id === vehicle.id) || (f.properties?.deviceId === vehicle.id));   //TODO: ???
        if (i > -1){
            data.features.splice(i, 1);
            src.setData(data);
        }
    }
}   //undrawVehicle

/**
 * Отрисовка ТС с отслеживанием
 *
 * @param {Object} payload Отслеживаемое ТС
 * @param {Object} vehicle Отслеживаемое ТС
 * @param {CallableFunction} vehicleInfo Запрос информации о машинке
 */
function drawDynamicVehicle(payload, vehicle, vehicleInfo) {
    console.log('tracking', vehicle, vehicleInfo);
    const coords = [
      payload.lon,
      payload.lat,
    ];

    const lngLat = {
      lon: payload.lon,
      lat: payload.lat,
    };

  // Если ранее еще не создали источник
  if (!_map.getSource(_SOURCES.WATCH)) {
    this.createOrDataSource(_SOURCES.WATCH, {
      type: 'FeatureCollection',
      features: [],
    });
  }

  const sData = _map.getSource(_SOURCES.WATCH)._data;

  const pos = sData.features.findIndex(it => it.id === vehicle.id);

  const feature = {
    id: vehicle.id,
    type: 'Feature',
    properties: {
      vcId: vehicle.id,
      deviceId: payload.deviceId,
      heading: payload.heading + 180,
      status: payload.status,
    },
    geometry: {
      type: 'Point',
      coordinates: coords,
    },
  };

  if (pos === -1) {
    sData.features.push(feature);
  } else {
    sData.features.splice(pos, 1, feature);
  }

  this.createOrDataSource(_SOURCES.WATCH, sData);

  if (_oldVehicle && _oldVehicle.id !== vehicle.id) {
    if (!!_dynamicPopup) {
      _dynamicPopup.remove();
      _dynamicPopup = null;
    }
  } else {
      if (!!_dynamicPopup){
        _dynamicPopup.setLngLat(lngLat);
      }
  }

  _oldVehicle = vehicle;
  undrawVehicle(vehicle, false);

  _map.on('click', _LAYERS.WATCH, async (e)=>{
    e.preventDefault();

    if (!(!!_dynamicPopup)) {
      _dynamicPopup = new MapBoxGL.Popup({
          maxWidth: '470px;',
      });
      _dynamicPopup.addTo(_map);
    }
    // Показываем попал с прелоадером
    _dynamicPopup.setLngLat(lngLat);
    _dynamicPopup.setHTML($('#cardPopupPreloader').html());

    // Получаем дополнительную информацию о ТС
    const vehicleData = await vehicleInfo(e.features[0].properties);

    try {
        // Отобразим попап
        _dynamicPopup.setHTML(vehicleData);
        $(_dynamicPopup._container).find('.vehicle_close').on('click', function(e){
          e.preventDefault();
          _dynamicPopup.remove();
        });
        _dynamicPopup.on('close', function(e){
            //_dynamicPopup.remove();  //TODO:
            _dynamicPopup = null;
        });
    } catch (e) {
      if (_dynamicPopup !== null) {
        _dynamicPopup.remove();
      }
      _dynamicPopup = null;
    }
  });
}   //drawDynamicVehicle

/**
 * Отображение трека
 *
 * @param {Object} trackData Информация о треке и о маркерах
 * @param {CallableFunction} onTrackPointClick Callback
 * @returns {void}
 */
function drawTrack(trackData, onTrackPointClick = voidCallback) {
  const track = trackData.track || {};
  const points = track.points || [];
  const markers = trackData.markers || [];
  const trackId = track.id || '';

  if (points.length === 0 || trackId === '') {
    _trigger('on_track_hide', trackData);

    return;
  }

  const defSettings = MapSettingsService.getDefaultData();
  const settings = trackData.settings || {};
  const trackColor = settings.color || defSettings.color;
  const lineWidth = settings.width || defSettings.width;

  // Генерируем имена слоев
  const layers = _genTrackLayerNames(track);

  // Генерируем имена источников
  const sources = _genTrackSourceNames(track);

  // Сохраняем имена для дальнейшей очистки
  _trackIds[trackId] = {
    layers: [
      layers.trackId, layers.trackId + '_speed-limit', layers.busStartId,
      layers.busEndId, layers.busStopId,
      layers.trackDirectionId, layers.trackPointsId,
      layers.trackSpeedUpId, layers.trackParkingId,
      layers.trackNoDataId,
    ],
    sources: [
      sources.busTrackId, sources.trackPointsId, sources.markers,
    ],
  };

  // Если ранее были созданы такие слои - то удаляем их
  _regenerateLayerAndSources(layers, sources);

  const bounds = new MapBoxGL.LngLatBounds(
    [points[0].lon, points[0].lat],
    [points[0].lon, points[0].lat],
  );

  // Убираем первую и последнюю точку трека - они есть его начало и конец
  const startPoint = points.shift();
  const endPoint = points.pop();

  // Собираем координаты точек и границы
  points.forEach((it, i) => {
    const data = [it.lon, it.lat];

    if (i) {
      bounds.extend(data);
    }
  });

  // Добавление источника для Трека
  createOrDataSource(sources.busTrackId, {
    type: 'FeatureCollection',
    features: points.map((it, index) => {
      const prev = points[index - 1] || null;

      if (prev === null) {
        return null;
      }

      return {
        type: 'Feature',
        properties: {
          isSpeedLimit: parseInt(it.speed) >= SPEED_LIMIT,
        },
        geometry: {
          type: 'LineString',
          coordinates: [
            [prev.lon, prev.lat],
            [it.lon, it.lat],
          ],
        },
      };
    }).filter(it => it != null),
  });

  // Превышение скорости
  createOrDataLayer(layers.trackId + '_speed-limit', {
    id: layers.trackId + '_speed-limit',
    type: 'line',
    source: sources.busTrackId,
    paint: {
      'line-color': '#c20a0a',
      'line-width': lineWidth,
    },
    filter: [
      '==',
      'isSpeedLimit',
      true,
    ],
    layout: {
      'line-cap': 'round',
      'line-join': 'round',
    },
  });

  // Линия трека
  createOrDataLayer(layers.trackId, {
    id: layers.trackId,
    type: 'line',
    source: sources.busTrackId,
    paint: {
      'line-color': _rgbaFromHexa(trackColor),
      'line-width': lineWidth,
    },
    filter: [
      '==',
      'isSpeedLimit',
      false,
    ],
    layout: {
      'line-cap': 'round',
      'line-join': 'round',
    },
  });

  // Отображение точек маршрута
  _drawTrackPoints(sources.trackPointsId, layers.trackPointsId, points, onTrackPointClick, trackData);

  // ------------------- МАРКЕРЫ -------------------

  // Создаем общий источник для всех видов маркеров
  createOrDataSource(sources.markers, {
    type: 'FeatureCollection',
    features: markers.map(it => {
      return {
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: it.coordinates,
        },
        properties: Object.assign({
          type: it.type,
          coords: it.coordinates,
        }, it.params || {}),
      };
    }),
  });

  // Остановки
  _drawTrackStops(sources, layers, settings);

  // Превышение скорости
  _drawTrackSpeedUpPoints(sources, layers, settings);

  // Парковки
  _drawTrackParking(sources, layers, settings);

  // Потеря связи
  _drawTrackNoData(sources, layers, settings);

  // -----------------------------------------------

  // Начало и Конец маршрута
  _drawTrackBounds(points, layers, sources, startPoint, endPoint, settings);

  _map.fitBounds(bounds, {
    padding: 20,
  });
}

/**
 * Динамическое изменение настроек трека
 *
 * @param {Object} track Информация о треке
 * @param {{
 *    color: {r: Number, g: Number, b: Number, a: Number},
 *    width: Number,
 *    showStops: String,
 *    stopTypes: {parking: Boolean, stop: Boolean, speedUp: Boolean, noData: Boolean, moving: Boolean},
 * }} settings Настройки
 */
function applyTrackSettings(track, settings) {
  const visibilityField = 'visibility';
  const stopTypes = settings.stopTypes;

  const trackColor = settings.color || '#0000FFFF';
  const lineWidth = settings.width;

  const showBounds = [
    MapSettingsService.stopsEnum.all,
    MapSettingsService.stopsEnum.stops,
  ].includes(settings.showStops) ? 'visible' : 'none';

  const showStops = stopTypes.stop || false ? 'visible' : 'none';
  const showSpeedUps = stopTypes.speedUp || false ? 'visible' : 'none';
  const showNoData = stopTypes.noData || false ? 'visible' : 'none';
  const showMoving = stopTypes.moving || false ? 'visible' : 'none';
  const showParking = stopTypes.parking || false ? 'visible' : 'none';

  const layers = _genTrackLayerNames(track);

  // Настройка трека
  _map.setPaintProperty(layers.trackId, 'line-color', _rgbaFromHexa(trackColor));
  _map.setPaintProperty(layers.trackId, 'line-width', lineWidth);
  _map.setPaintProperty(layers.trackId + '_speed-limit', 'line-width', lineWidth);

  // Настройка показа начала и конца трека
  _map.setLayoutProperty(layers.busStartId, visibilityField, showBounds);
  _map.setLayoutProperty(layers.busEndId, visibilityField, showBounds);

  // Настройка показа маркеров
  _map.setLayoutProperty(layers.busStopId, visibilityField, showStops);
  _map.setLayoutProperty(layers.trackSpeedUpId, visibilityField, showSpeedUps);
  _map.setLayoutProperty(layers.trackNoDataId, visibilityField, showNoData);
  _map.setLayoutProperty(layers.trackPointsId, visibilityField, showMoving);
  _map.setLayoutProperty(layers.trackParkingId, visibilityField, showParking);
}

/**
 * Очистка трека
 *
 * @param {Object} trackData Информация о треке
 * @returns {void}
 */
function clearTrack(trackData) {
  const trackId = trackData.track.id;
  const data = _trackIds[trackId] || null;

  _trigger('on_track_hide', trackData);

  if (data) {
    _clearLayers(data);

    delete _trackIds[trackId];
  }
}

/**
 * Очистка всех треков
 *
 * @returns {void}
 */
function clearTracks() {
  _trigger('on_track_hide', null);

  Object.keys(_trackIds).forEach(it => {
    const data = _trackIds[it];

    _clearLayers(data);
  });

  _trackIds = {};
}

/**
 * Отрисовка маршрутов
 *
 * @param {Array<Object>} routes Набор точек маршрута
 */
function drawLayerRoutes(routes) {
  _layerRoutes.forEach(it => {
    this.createOrDataSource(it, {
      type: 'FeatureCollection',
      features: [],
    });
  });

  _layerRoutes = [];

  let bounds = null;

  routes.forEach(it => {
    const coords = (it.points || []).map(it => it.coordinates);

    if (bounds === null) {
      bounds = new MapBoxGL.LngLatBounds(coords[0], coords[0]);
    }

    for (let i = 1, len = coords.length; i < len; i++) {
      bounds.extend(coords[i]);
    }

    // const rpId = `route_points_${it.id}`;

    // _layerRoutes.push(rpId);

    // TODO: коммент т.к. может пригодиться позднее
    // this.createOrDataSource(rpId, {
    //   type: 'FeatureCollection',
    //   features: it.points.map(p => {
    //     return {
    //       type: 'Feature',
    //       properties: {
    //         name: p.name || p.locationName || p.locname,
    //       },
    //       geometry: {
    //         type: 'Point',
    //         coordinates: p.coordinates,
    //       },
    //     };
    //   }),
    // });
    //
    // this.createOrDataLayer(rpId, {
    //   id: rpId,
    //   type: 'circle',
    //   source: rpId,
    //   paint: {
    //     'circle-radius': 8,
    //     'circle-stroke-width': 2,
    //     'circle-color': '#1760dc',
    //     'circle-stroke-color': '#3b5d9c',
    //   },
    // });
    //
    // this.createOrDataLayer(`route_points_text_${it.id}`, {
    //   id: `route_points_text_${it.id}`,
    //   type: 'symbol',
    //   source: rpId,
    //   layout: {
    //     'text-field': ['get', 'name'],
    //     'text-variable-anchor': ['bottom'],
    //     'text-radial-offset': 0.5,
    //     'text-justify': 'auto',
    //     'text-size': 14,
    //   },
    // });

    const rId = `route_${it.id}`;

    _layerRoutes.push(rId);

    this.createOrDataSource(rId, {
      type: 'Feature',
      geometry: {
        type: 'LineString',
        coordinates: coords,
      },
    });

    this.createOrDataLayer(rId, {
      id: `route_${it.id}`,
      type: 'line',
      source: rId,
      layout: {
        'line-join': 'round',
        'line-cap': 'round',
      },
      paint: {
        'line-color': '#ffd200',
        'line-width': 4,
      },
    });
  });

  if (bounds !== null) {
    _map.fitBounds(bounds, {
      padding: 20,
    });
  }
}

/**
 * Отрисовка остановок
 *
 * @param {Array<Object>} stops
 */
function drawStops(stops) {
  this.createOrDataSource('route_stops', {
    type: 'FeatureCollection',
    features: stops.map(it => ({
      type: 'Feature',
      properties: {
        title: it.locName || 'Нет имени остановки',
      },
      geometry: {
        type: 'Point',
        coordinates: it.coordinates,
      },
    })),
  });

  if (!_map.getLayer('route_stops')) {
    _map.addLayer({
      id: 'route_stops',
      type: 'circle',
      source: 'route_stops',
      paint: {
        'circle-color': '#ff00de',
        'circle-radius': 5,
        'circle-stroke-width': 2,
        'circle-stroke-color': '#0032e7',
      },
    });
  }

  if (!_map.getLayer('route_stops_text')) {
    _map.addLayer({
      id: 'route_stops_text',
      type: 'symbol',
      source: 'route_stops',
      layout: {
        'text-field': ['get', 'title'],
        'text-variable-anchor': ['top', 'bottom', 'left', 'right'],
        'text-radial-offset': 0.5,
        'text-justify': 'auto',
        'text-size': 14,
      },
    });
  }
}

/**
 * Отрисовка гео зон
 *
 * @param {Array<Object>} geoZones
 */
function drawGeoZones(geoZones) {
  _geoZonesId.forEach(it => {
    if (_map.getLayer(it)) {
      _map.removeLayer(it);
    }

    if (_map.getSource(it)) {
      _map.removeSource(it);
    }
  });

  _geoZoneCircles.forEach(it => {
    it.remove();
  });

  _geoZonesId = [];
  _geoZoneCircles = [];
  _geoZoneCirclesHash = {};
  
  const bounds = new MapBoxGL.LngLatBounds();
  
  geoZones.forEach(it => {
    const item = _copy(it);
    const ref = item.ref;
    const geometry = item.refGeometry;

    delete item.ref;
    delete item.refType;
    delete item.refGeometry;

    const id = `geo_zone_${ref.id}`;

    _geoZonesId.push(id);

    _map.addSource(id, {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: [item],
      },
    });

    switch (it.refType) {
      case 'LINE':
        const radians = (
          78271.517 *
          Math.cos(
            item.geometry.coordinates[0][1] * Math.PI / 180,
          )
        );

        _map.addLayer({
          id: id,
          type: 'line',
          source: id,
          layout: {
            'line-join': 'round',
            'line-cap': 'round',
          },
          paint: {
            'line-color': _colorFromInt(ref.color || -65536, 1),
            'line-width': [
              'interpolate',
              ['exponential', 2],
              ['zoom'],
              1, ['*', ref.width / radians, ['^', 2, 1]],
              24, ['*', ref.width / radians, ['^', 2, 24]],
            ],
          },
        });
        
        it.geometry.coordinates.forEach(it => {
          bounds.extend(it);
        });

        break;

      case 'POLYGON':
        // TODO: пока не очень красиво, но это проще и работает
        _map.removeSource(id);

        _map.addSource(id, {
          type: 'geojson',
          data: item,
        });

        _map.addLayer({
          id: id,
          type: 'fill',
          source: id,
          paint: {
            'fill-color': _colorFromInt(ref.color || -65536, 0.7),
          },
        });
        
        it.geometry.coordinates[0].forEach(it => {
          bounds.extend(it);
        });

        break;

      case 'CIRCLE':
        const circle = new MapBoxCircle(
          { lat: geometry.y, lng: geometry.x },
          (ref.width || 1000),
          {
            fillColor: _colorFromInt(ref.color || -65536, 0.8),
            strokeColor: _colorFromInt(ref.color || -65536, 1),
          },
        );

        _geoZoneCircles.push(circle);
        _geoZoneCirclesHash[it.ref.id] = {
          geoZone: it,
          circle: circle,
        };

        circle.addTo(_map);
        
        circle._circle.geometry.coordinates[0].forEach(it => {
          bounds.extend(it);
        });

        break;
    }
  });
  
  if (!_emptyObject(bounds)) {
    _map.fitBounds(bounds, {
      padding: 40,
      maxZoom: 14,
    });
  }
}

/**
 * Скрытие/Показ слоя с геозоной
 *
 * @param {Object} geoZone
 * @param {Boolean} visibilityState
 */
function visibleEditedGeoZone(geoZone, visibilityState) {
  const id = _getGeoZoneId(geoZone);

  if (_map.getLayer(id)) {
    _map.setLayoutProperty(id, 'visibility', visibilityState ? 'visible' : 'none');
  }

  if (_hasOwnProperty(_geoZoneCirclesHash, geoZone.id)) {
    const data = _geoZoneCirclesHash[geoZone.id];
    const id = data.circle.__instanceId;

    _map.setLayoutProperty(`circle-stroke-${id}`, 'visibility', visibilityState ? 'visible' : 'none');
    _map.setLayoutProperty(`circle-fill-${id}`, 'visibility', visibilityState ? 'visible' : 'none');
  }

  // Если показать гео зону - значит удалить редактируемую
  if (!visibilityState) {
    if (!!(geoZone.polygonText || geoZone.polygontext)) {
      const feature = MapLayersService.parseWkx(geoZone);
  
      const type = feature.refType;
      const geometry = feature.refGeometry;
  
      delete feature.ref;
      delete feature.refGeometry;
      delete feature.refType;
  
      // TODO: вообще такое себе решение
      _map.removeControl(_draw);
  
      _map.addControl(_draw);
      _map.addControl(_geoZoneControl);
  
      const bounds = new MapBoxGL.LngLatBounds();
  
      switch (type) {
        case 'LINE':
        case 'POLYGON':
          _currentDrawIds = _draw.add({
            type: 'FeatureCollection',
            features: [
              feature,
            ],
          });
  
          _trigger('draw.updated', _draw.getAll());
  
          const coords = type === 'LINE'
            ? feature.geometry.coordinates
            : feature.geometry.coordinates[0];
  
          coords.forEach(it => {
            bounds.extend(it);
          });
  
          _map.fitBounds(bounds, {
            padding: 40,
          });
  
          break;
  
        case 'CIRCLE':
          const circle = new MapBoxCircle(
            { lat: geometry.y, lng: geometry.x },
            geoZone.radius,
            {
              editable: true,
              fillColor: _rgbaFromObject(geoZone.color),
              strokeColor: _rgbaFromObject(geoZone.color),
            },
          );
  
          circle.on('radiuschanged', (_circle) => {
            const source = _map.getStyle().sources[`circle-source-${_circle.__instanceId}`] ?? {};
  
            _trigger('draw.updated', source.data ?? { features: [] });
            _trigger('circle_radius_changed', _circle.getRadius());
          });
  
          circle.on('centerchanged', (_circle) => {
            const source = _map.getStyle().sources[`circle-source-${_circle.__instanceId}`] ?? {};
  
            _trigger('draw.updated', source.data ?? { features: [] });
            _trigger('circle_center_changed', _circle.getCenter());
          });
  
          _drawCircle = circle;
  
          circle.addTo(_map);
  
          const source = _map.getStyle().sources[`circle-source-${circle.__instanceId}`] ?? {};
  
          _trigger('draw.updated', source.data ?? { features: [] });
          _trigger('circle_center_changed', circle.getCenter());
  
          // Данная конструкция будет всегда и в любом случае (все что до forEach)
          source.data.features[0].geometry.coordinates[0].forEach(it => {
            bounds.extend(it);
          });
  
          break;
      }
  
      _map.fitBounds(bounds, {
        padding: 40,
        maxZoom: 14,
      });
    }
  } else {
    _draw.deleteAll();

    _currentDrawIds = [];

    if (_drawCircle !== null) {
      _drawCircle.remove();

      _drawCircle = null;
    }

    _map.removeControl(_geoZoneControl);
  }
}

/**
 * Применение параметров к геозоне
 *
 * @param {Object} geoZone
 */
function applyGeoZoneParams(geoZone) {
  //console.log('applyGeoZoneParams', _copy(geoZone));

  if (geoZone.type !== null) {
    _map.addControl(_geoZoneControl);
  } else {
    _map.removeControl(_geoZoneControl);
  }

  // Сейчас ничего не рисуем и только выбираем тип
  if (_draw.getAll().features.length === 0 && _drawCircle === null) {
    let drawMode = null;

    if (geoZone.type === 'LINE') {
      drawMode = _draw.modes.DRAW_LINE_STRING;
    }

    if (geoZone.type === 'POLYGON') {
      drawMode = _draw.modes.DRAW_POLYGON;
    }

    if (geoZone.type === 'CIRCLE') {
      const center = _map.getCenter();

      const circle = new MapBoxCircle(
        { lat: center.lat, lng: center.lng },
        1000,
        {
          editable: true,
          fillColor: _rgbaFromObject(geoZone.color),
          strokeColor: _rgbaFromObject(geoZone.color),
        },
      );

      circle.on('radiuschanged', (_circle) => {
        const source = _map.getStyle().sources[`circle-source-${_circle.__instanceId}`] ?? {};

        _trigger('draw.updated', source.data ?? { features: [] });
        _trigger('circle_radius_changed', _circle.getRadius());
      });
      circle.on('centerchanged', (_circle) => {
        const source = _map.getStyle().sources[`circle-source-${_circle.__instanceId}`] ?? {};

        _trigger('draw.updated', source.data ?? { features: [] });
        _trigger('circle_center_changed', _circle.getCenter());
      });

      _drawCircle = circle;

      _trigger('circle_radius_changed', 1000);
      _trigger('circle_center_changed', circle.getCenter());

      circle.addTo(_map);

      const source = _map.getStyle().sources[`circle-source-${circle.__instanceId}`] ?? {};
      _trigger('draw.updated', source.data ?? { features: [] });
    }

    if (drawMode !== null) {
      _draw.changeMode(drawMode);

      _trigger('draw.updated', _draw.getAll());
    }
  } else {
    setTimeout(() => {
      switch (geoZone.type) {
        case 'LINE':
          // const feature = MapLayersService.parseWkx(geoZone);
          const lineId = 'gl-draw-line-inactive.cold';

          const layer = _map.getLayer(lineId);

          if (layer) {
            const source = _map.getStyle().sources[layer.source || layer.sourceLayer];
            const sourceData = source.data;

            if (sourceData.features.length && sourceData.features[0].geometry.coordinates.length) {
              const coords = sourceData.features[0].geometry.coordinates;

              const radians = (
                78271.517 * Math.cos(coords[0][1] * Math.PI / 180)
              );

              // Тощина линии
              _map.setPaintProperty(lineId, 'line-width', [
                'interpolate',
                ['exponential', 2],
                ['zoom'],
                1, ['*', geoZone.width / radians, ['^', 2, 1]],
                24, ['*', geoZone.width / radians, ['^', 2, 24]],
              ]);
            }

            // Цвет
            _map.setPaintProperty(lineId, 'line-color', _rgbaFromObject(geoZone.color));
          }

          break;

        case 'POLYGON':
          const id = 'gl-draw-polygon-fill-inactive.cold';
          const strokeId = 'gl-draw-polygon-stroke-inactive.cold';

          if (_map.getLayer(id)) {
            // Заливка
            _map.setPaintProperty(id, 'fill-color', _rgbaFromObject(geoZone.color));
            _map.setPaintProperty(id, 'fill-opacity', 0.7);
          }

          if (_map.getLayer(strokeId)) {
            // Цвет границ
            _map.setPaintProperty(strokeId, 'line-color', _rgbaFromObject(geoZone.color));
          }

          break;

        case 'CIRCLE':
          const circleId = 'circle-fill-' + _drawCircle.__instanceId;

          if (_map.getLayer(circleId)) {
            // Цвет круга
            _map.setPaintProperty(circleId, 'fill-color', _rgbaFromObject(geoZone.color));

            // Цвет обводки
            // _map.setPaintProperty(circleId, 'stroke-color', _rgbaFromObject(geoZone.color));

            // Радиус
            _drawCircle.setRadius(+geoZone.radius);
          }

          break;
      }
    }, 100);
  }
}

/**
 * Подписка на события
 *
 * @param {String} method Имя события
 * @param {CallableFunction} callback Функция подписчик
 */
function subscribe(method, callback) {
  _subscribes[method] = callback;
}

/**
 * Получение экземпляра карты
 *
 * @return {MapBoxGL}
 */
function getMap() {
  return _map;
}

/**
 * Получение экземпляра рисования
 *
 * @return {MapBoxGLDraw}
 */
function getDraw() {
  return _draw;
}

/**
 * Очистка всех рисующихся элементов
 */
function removeAll() {
  _draw.deleteAll();

  _currentDrawIds = [];

  if (_drawCircle !== null) {
    _drawCircle.remove();

    _drawCircle = null;
  }
}

/**
 * Удаление кнопки для очистки карты после рисования
 */
function removeControl() {
  _map.removeControl(_geoZoneControl);
}

/**
 * Создание либо установка новых данных в источник
 *
 * @param {String} sourceId Имя истоничка
 * @param {{
 *   type: String,
 *   features: Array<{
 *      type: String,
 *      geometry: {
 *        type: String,
 *        coordinates: Array<number>,
 *      },
 *      properties: Object|*,
 *   }>,
 * }|*} sourceData Данные источника
 * @param {Object} options Дополнительные параметры слоя
 */
function createOrDataSource(sourceId, sourceData, options = {}) {
    var src = _map.getSource(sourceId);
    if (!(!!src)) {
        _map.addSource(
                sourceId,
                Object.assign(options, {
                  type: 'geojson',
                  data: sourceData,
                }),
        );
        src = _map.getSource(sourceId);
    }
    src.setData(sourceData);
}   //createOrDataSource

/**
 * Создает слой, если такового не было
 *
 * @param {String} layerId Идентификатор слоя
 * @param {{
 *   id: String,
 *   type: String,
 *   source: String,
 *   paint: *|Object,
 *   layout: *|Object,
 * }} layerData Данные слоя
 */
function createOrDataLayer(layerId, layerData) {
  if (!_map.getLayer(layerId)) {
    // Если нет источника - создадим по-умолчанию
    if (!_map.getSource(layerData.source)) {
      _map.addSource(layerData.source, {
        type: 'FeatureCollection',
        features: [],
      });
    }

    _map.addLayer(layerData);
  }
}

// ------------------ PRIVATE ------------------

/**
 * Контрол для работы с геозонами
 */
class _GeoZoneControl {
  onAdd(map) {
    this._map = map;
    this._container = document.getElementById('geoZoneMapControlDelete');
    this._container.style.display = 'block';
    this._container.onclick = () => {
      if (_drawCircle !== null) {
        _drawCircle.remove();
        _drawCircle = null;
      }

      _draw.delete(_currentDrawIds);
      _currentDrawIds = [];

      // Удаляем новые нарисованные геозоны
      if (_draw.getAll().features.length) {
        _draw.deleteAll();
      }

      _trigger('draw.updated', _draw.getAll());
    };

    return this._container;
  }

  onRemove() {
    if (this._container) {
      this._container.style.display = 'none';
    }

    if (this._map) {
      this._map = undefined;
    }
  }
}

/**
 * RGBA цвет из объекта
 *
 * @param {Object} color
 * @private
 */
function _rgbaFromObject(color) {
  // TODO: это просто ужасно... Но при создании приходит #RGB
  if (typeof color === 'string' && color.substring(0, 1) === '#') {
    return color;
  }

  return `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a || 1})`;
}

/**
 * RGBA цвет из HEXA
 *
 * @param {String} color
 * @return String
 * @private
 */
function _rgbaFromHexa(color) {
  let r = 0, g = 0, b = 0, a = 1;

  if (color.length === 5) {
    r = '0x' + color[1] + color[1];
    g = '0x' + color[2] + color[2];
    b = '0x' + color[3] + color[3];
    a = '0x' + color[4] + color[4];
  } else if (color.length === 9) {
    r = '0x' + color[1] + color[2];
    g = '0x' + color[3] + color[4];
    b = '0x' + color[5] + color[6];
    a = '0x' + color[7] + color[8];
  }

  a = +(a / 255).toFixed(3);

  return `rgba(${+r}, ${+g}, ${+b}, ${+a})`;
}

/**
 * Загрузка иконок
 *
 * @returns {void}
 * @private
 */
async function _loadImages() {
    const mi = new MapIcons(_map);
    await mi.init();
}

/**
 * Очистка слоев трека
 *
 * @param {Object} trackInfo
 * @private
 */
function _clearLayers(trackInfo) {
  const layers = trackInfo.layers || [];
  const sources = trackInfo.sources || [];

  layers.forEach(it => {
    if (_map.getLayer(it)) {
      _map.removeLayer(it);
    }

    // Удаление "безымянных" источников
    if (_map.getSource(it)) {
      _map.removeSource(it);
    }
  });

  sources.forEach(it => {
    if (_map.getSource(it)) {
      _map.removeSource(it);
    }
  });
}

/**
 * Отрисовка попапа
 *
 * @param {Object} e
 * @param {CallableFunction} vehicleInfo
 * @return {Promise<void>}
 * @private
 */
async function _drawPopup(e, vehicleInfo) {
  // Создание попапа
  const popup = new MapBoxGL.Popup({
    closeButton: false, // Скрываем стандартную кнопку закрытия
    maxWidth: '470px',
  });

  const className = 'popup_' + (new Date()).getTime();

  const popupDiv = $('<div>').appendTo(document.body);
  popupDiv.addClass(className).html( $('#cardPopupPreloader').html() );

  // Показываем попал с прелоадером
  popup.setLngLat(e.lngLat)
    .setHTML(popupDiv.html())
    .addTo(_map);

  // Получаем дополнительную информацию о ТС
  popupDiv.html( await vehicleInfo(e.features[0].properties) );

  // Устанавливаем полученные данные в попап
  popup.setHTML(popupDiv.html());

  $(popup._container).find('.vehicle_close').on('click', function(e){
    e.preventDefault();
    popup.remove();
  });
}

/**
 * Отрисовка точек движения
 *
 * @param {String} sourceId Идентификатор источника данных
 * @param {String} layerId Идентификатор слоя
 * @param {Array<Object>} points Массив точек трека
 * @param {CallableFunction} callable
 * @param {*} trackData
 * @private
 */
function _drawTrackPoints(sourceId, layerId, points, callable, trackData) {
    var prev = {lat: 0, lon: 0};
/*
filter((pt)=>{
        const inc = distance(prev, pt) > 200;
        if (inc){
            prev = pt;
        }
        return inc;
    }).
*/
  // Добавление источника для точек трека
  createOrDataSource(sourceId, {
    type: 'FeatureCollection',
    features: points.map((it, index) => {
        const d = distance(prev, it);
        if (d > 50){
            prev = it;
        }
        return {
            type: 'Feature',
            geometry: {
              type: 'Point',
              coordinates: [
                it.lon,
                it.lat,
              ],
            },
            properties: {
              id: it.id,
              distance: d,
              deviceId: it.deviceId,
              heading: it.heading - 90,
              status: it.status,
              icon: 'arrow-direction',
              ref: Object.assign(it, {
                index: index,
              }),
            },
          };
        }),
    });

    // Добавление слоя для точек трека
    createOrDataLayer(layerId, {
        id: layerId,
        type: 'symbol',
        source: sourceId,
        layout: {
          'icon-image': ['get', 'icon'],
          'icon-size': 0.4,
          'icon-rotate': ['get', 'heading'],
          'icon-rotation-alignment': 'map',
          'icon-allow-overlap': true,
        },
        filter: ['>', ['get', 'distance'], [
                    'step', ['zoom'],
                        800, 10,
                        700, 11,
                        500, 12,
                        300, 13,
                        200, 14,
                        50
                    ]
                ]
    });

  _map.on('click', layerId, (e) => {
    const isFn = e.originalEvent.altKey || false;

    const features = _map.queryRenderedFeatures(e.point, {
      layers: [layerId],
    });

    const feature = features && features.length && features[0] || {};
    const props = JSON.parse(feature.properties && feature.properties.ref || '{}');

    if (!isFn) {
      _selectedPoints = [];
    }

    _trigger('on_track_point_click', {
      props: props,
      trackData: trackData,
    });

    if (_selectedPoints.length === 0) {
      _selectedPoints.push(props);

      // Сообщим обратно что тыкнули по точке
      callable([props]);

      return;
    }

    if (isFn && _selectedPoints.length) {
      const firstIndex = _selectedPoints[0].index;
      const lastIndex = props.index;

      const isFirstBigger = firstIndex > lastIndex;

      // Сообщим обратно массив точек
      callable(points.slice(
        isFirstBigger ? lastIndex : firstIndex,
        isFirstBigger ? firstIndex : lastIndex,
      ));
    }
  });
}

/**
 * Добавление слоя для остановок
 *
 * TODO: цвета, отображение
 *
 * @param {Object} sources Список источников
 * @param {Object} layers Список слоев
 * @param {{
 *    color: String,
 *    width: Number,
 *    showStops: String,
 *    stopTypes: {parking: Boolean, stop: Boolean, speedUp: Boolean, noData: Boolean, moving: Boolean},
 * }} settings Настройки
 * @private
 */
function _drawTrackStops(sources, layers, settings) {
  const showStop = settings.showStops || 'all';

  createOrDataLayer(layers.busStopId, {
    id: layers.busStopId,
    type: 'symbol',
    source: sources.markers,
    layout: {
      'icon-image': 'map-track-pause',
      'icon-size': 0.3,
      'icon-allow-overlap': true,
      'icon-offset': [
        25,
        -51,
      ],
      'visibility': showStop === MapSettingsService.stopsEnum.all ? 'visible' : 'none',
    },
    filter: [
      '==',
      'type',
      _VEHICLE_EVENTS.stop,
    ],
  });
}

/**
 * Добавление слоя отображающего превышений скорости
 *
 * TODO: цвета, отображение
 *
 * @param {Object} sources Список источников
 * @param {Object} layers Список слоев
 * @param {{
 *    color: String,
 *    width: Number,
 *    showStops: String,
 *    stopTypes: {parking: Boolean, stop: Boolean, speedUp: Boolean, noData: Boolean, moving: Boolean},
 * }} settings Настройки
 * @private
 */
function _drawTrackSpeedUpPoints(sources, layers, settings) {
  const isVisible = settings.stopTypes.speedUp || false;

  // Слой для остановок
  _map.addLayer({
    id: layers.trackSpeedUpId,
    type: 'circle',
    source: sources.markers,
    layout: {
      visibility: isVisible ? 'visible' : 'none',
    },
    paint: {
      'circle-radius': {
        base: 1.75,
        stops: [
          [12, 2],
          [22, 180],
        ],
      },
      'circle-color': '#ee0808',
      'circle-stroke-width': 4,
      'circle-stroke-color': '#e508ec',
    },
    filter: [
      '==',
      'type',
      _VEHICLE_EVENTS.speedUp,
    ],
  });
}

/**
 * Добавление слоя отображающего парковки
 *
 * TODO: цвета, отображение
 *
 * @param {Object} sources Список источников
 * @param {Object} layers Список слоев
 * @param {{
 *    color: String,
 *    width: Number,
 *    showStops: String,
 *    stopTypes: {parking: Boolean, stop: Boolean, speedUp: Boolean, noData: Boolean, moving: Boolean},
 * }} settings Настройки
 * @private
 */
function _drawTrackParking(sources, layers, settings) {
  const isVisible = settings.stopTypes.parking || false;

  // Слой для остановок
  _map.addLayer({
    id: layers.trackParkingId,
    type: 'symbol',
    source: sources.markers,
    layout: {
      'icon-image': 'map-track-stop',
      'icon-size': 0.3,
      'icon-allow-overlap': true,
      'icon-offset': [
        25,
        -51,
      ],
      visibility: isVisible ? 'visible' : 'none',
    },
    filter: [
      '==',
      'type',
      _VEHICLE_EVENTS.parking,
    ],
  });
}

/**
 * Добавление слоя отображающего Потерю связи
 *
 * TODO: цвета, отображение
 *
 * @param {Object} sources Список источников
 * @param {Object} layers Список слоев
 * @param {{
 *    color: String,
 *    width: Number,
 *    showStops: String,
 *    stopTypes: {parking: Boolean, stop: Boolean, speedUp: Boolean, noData: Boolean, moving: Boolean},
 * }} settings Настройки
 * @private
 */
function _drawTrackNoData(sources, layers, settings) {
  const isVisible = settings.stopTypes.noData || false;

  if (!_map.getLayer(layers.trackNoDataId)) {
    _map.addLayer({
      id: layers.trackNoDataId,
      type: 'circle',
      source: sources.markers,
      layout: {
        visibility: isVisible ? 'visible' : 'none',
      },
      paint: {
        'circle-radius': 15,
        'circle-color': '#ff0000',
        'circle-stroke-width': 1,
        'circle-stroke-color': '#a8ec08',
      },
      filter: [
        '==',
        'type',
        _VEHICLE_EVENTS.noData,
      ],
    });
  }
}

/**
 * Отрисовка границ трека
 * Его начало и текущее положение ТС
 *
 * @param {Array<Object>} points
 * @param {Object} layers
 * @param {Object} sources
 * @param {Object} startPoint
 * @param {Object} endPoint
 * @param {{
 *    color: {r: Number, g: Number, b: Number, a: Number},
 *    width: Number,
 *    showStops: String,
 *    stopTypes: {parking: Boolean, stop: Boolean, speedUp: Boolean, noData: Boolean, moving: Boolean},
 * }} settings
 * @private
 */
function _drawTrackBounds(
  points,
  layers,
  sources,
  startPoint,
  endPoint,
  settings,
) {
  const showStop = settings.showStops || 'all';
  const visible = [MapSettingsService.stopsEnum.all, MapSettingsService.stopsEnum.stops].includes(showStop);

  const startTrackPoints = points.length
    ? [points[0].lon, points[0].lat]
    : [startPoint.lon, startPoint.lat];

  const endTrackPoints = points.length
    ? [points[points.length - 1].lon, points[points.length - 1].lat]
    : [endPoint.lon, endPoint.lat];

  const startTrack = {
    type: 'Feature',
    properties: {
      name: 'start',
    },
    geometry: {
      type: 'Point',
      coordinates: startTrackPoints,
    },
  };

  const endTrack = {
    type: 'Feature',
    properties: {
      name: 'end',
    },
    geometry: {
      type: 'Point',
      coordinates: endTrackPoints,
    },
  };

  // Слой для отображения Начала трека
  _map.addLayer({
    id: layers.busStartId,
    type: 'symbol',
    source: {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: [
          startTrack,
        ],
      },
    },
    layout: {
      'icon-image': 'map-track-a',
      'icon-size': 0.3,
      'icon-offset': [25, -50],
      visibility: visible ? 'visible' : 'none',
    },
  });

  // Слой для отображения Конца трека
  _map.addLayer({
    id: layers.busEndId,
    type: 'symbol',
    source: {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: [
          endTrack,
        ],
      },
    },
    layout: {
      'text-field': ['get', 'vehicleNumber'],
      'icon-image': 'map-track-b',
      'icon-size': 0.3,
      'icon-offset': [25, -50],
    },
  });
}

/**
 * Удаление и создание слоев и источников
 *
 * @param {Object} layers
 * @param {Object} sources
 * @private
 */
function _regenerateLayerAndSources(layers, sources) {
  if (_map.getLayer(layers.trackId)) {
    _map.removeLayer(layers.trackId);
  }

  if (_map.getLayer(layers.trackId + '_speed-limit')) {
    _map.removeLayer(layers.trackId + '_speed-limit');
  }

  if (_map.getLayer(layers.busStopId)) {
    _map.removeLayer(layers.busStopId);
  }

  if (_map.getLayer(layers.busStartId)) {
    _map
      .removeLayer(layers.busStartId)
      .removeSource(layers.busStartId);
  }

  if (_map.getLayer(layers.busEndId)) {
    _map
      .removeLayer(layers.busEndId)
      .removeSource(layers.busEndId);
  }

  if (_map.getLayer(layers.trackDirectionId)) {
    _map.removeLayer(layers.trackDirectionId);
  }

  if (_map.getLayer(layers.trackPointsId)) {
    _map.removeLayer(layers.trackPointsId);
  }

  if (_map.getLayer(layers.trackSpeedUpId)) {
    _map.removeLayer(layers.trackSpeedUpId);
  }

  if (_map.getLayer(layers.trackParkingId)) {
    _map.removeLayer(layers.trackParkingId);
  }

  if (_map.getLayer(layers.trackNoDataId)) {
    _map.removeLayer(layers.trackNoDataId);
  }

  // Так же удаляем источники
  if (_map.getSource(sources.markers)) {
    _map.removeSource(sources.markers);
  }

  if (_map.getSource(sources.busTrackId)) {
    _map.removeSource(sources.busTrackId);
  }

  if (_map.getSource(sources.trackPointsId)) {
    _map.removeSource(sources.trackPointsId);
  }
}

/**
 * Генерация имен слоев
 *
 * @param {Object} track Информация о текущем треке
 * @return {{
 *    busStartId: String,
 *    trackId: String,
 *    trackDirectionId: String,
 *    busStopId: String,
 *    busEndId: String,
 *    trackPointsId: String,
 *    trackSpeedUpId: String,
 *    trackParkingId: String,
 *    trackNoDataId: String,
 *  }}
 * @private
 */
function _genTrackLayerNames(track) {
  const trackId = track.id || '';

  return {
    trackId: `${_LAYERS.BUS_STOP}-${trackId}`,
    busStopId: `${_LAYERS.TRACK}-${trackId}`,
    busStartId: `${_LAYERS.BUS_START}-${trackId}`,
    busEndId: `${_LAYERS.BUS_END}-${trackId}`,
    trackDirectionId: `${_LAYERS.TRACK_DIRECTION}-${trackId}`,
    trackPointsId: `${_LAYERS.POINTS}-${trackId}`,
    trackSpeedUpId: `${_LAYERS.SPEED_UP}-${trackId}`,
    trackParkingId: `${_LAYERS.PARKING}-${trackId}`,
    trackNoDataId: `${_LAYERS.NO_DATA}-${trackId}`,
  };
}

/**
 * Генерация имен источников данных
 *
 * @param {Object} track Информация о треке
 * @return {{
 *    busTrackId: String,
 *    trackPointsId: String,
 *    markers: String,
 * }}
 * @private
 */
function _genTrackSourceNames(track) {
  const trackId = track.id;

  return {
    busTrackId: `${_SOURCES.TRACK}-${trackId}`,
    trackPointsId: `${_SOURCES.POINTS}-${trackId}`,
    markers: `${_SOURCES.MARKERS}-${trackId}`,
  };
}

/**
 * Преобразование числа в rgb цвет
 *
 * TODO: надо найти все эти функции и привести к одной toRgbaColor(Object|Number|String)
 *
 * @param {Number|Object} color Цвет формата -60456
 * @param {Number} alpha Альфа канал
 * @return {String}
 * @private
 */
function _colorFromInt(color, alpha = 1) {
  if (typeof color === 'object') {
    return `rgba(${color.r},${color.g},${color.b},${alpha})`;
  }

  const blue = color & 255;
  const green = (color >> 8) & 255;
  const red = (color >> 16) & 255;

  return `rgba(${red},${green},${blue},${alpha})`;
}

/**
 * Генерация id для геозоны
 *
 * @param {Object} geoZone
 * @returns {string}
 * @private
 */
function _getGeoZoneId(geoZone) {
  return `geo_zone_${geoZone.id}`;
}

/**
 * Посылка события
 *
 * @param {String} method
 * @param {*} data
 * @private
 */
function _trigger(method, data = null) {
  if (_hasOwnProperty(_subscribes, method)) {
    _subscribes[method](data);
  }
}

function distance(ll1, ll2){
    //радиус Земли
    const R = 6372795;
    if (
            (!ll1)
         || (!ll2)
         || !(!!ll1.lat)
         || !(!!ll2.lat)
        ){
        return R;
    }
    //перевод коордитат в радианы
    var lat1 = ll1.lat * Math.PI / 180,
        lat2 = ll2.lat * Math.PI / 180,
        long1 = ll1.lon * Math.PI / 180,
        long2 = ll2.lon * Math.PI / 180;
        //вычисление косинусов и синусов широт и разницы долгот
    var cl1 = Math.cos(lat1);
    var cl2 = Math.cos(lat2);
    var sl1 = Math.sin(lat1);
    var sl2 = Math.sin(lat2);
    var delta = long2 - long1;
    var cdelta = Math.cos(delta);
    var sdelta = Math.sin(delta);
    //вычисления длины большого круга
    var y = Math.sqrt(Math.pow(cl2 * sdelta, 2) + Math.pow(cl1 * sl2 - sl1 * cl2 * cdelta, 2));
    var x = sl1 * sl2 + cl1 * cl2 * cdelta;
    var ad = Math.atan2(y, x);
    var dist = ad * R; //расстояние между двумя координатами в метрах
    return Math.round(dist);
}   //distance
// ------------------ EXPORT ------------------




export default {
  init,
  initialized,
  drawStaticVehicles,
  drawDynamicVehicle,
  undrawVehicle,
  drawTrack,
  applyTrackSettings,
  clearTrack,
  clearTracks,
  drawLayerRoutes,
  drawStops,
  drawGeoZones,
  visibleEditedGeoZone,
  applyGeoZoneParams,
  subscribe,
  getMap,
  getDraw,
  distance,
  removeControl,
  removeAll,
  createOrDataSource,
  createOrDataLayer,
};
