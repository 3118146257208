// Сторонние зависимости
import md5 from 'blueimp-md5';

// Vuex
import store from '@/store/index';

// Утилиты
import {prepareSinRequest, prepareSinResponse} from '@/utils/http';
import {formatDate} from '@/utils/utils';

/**
 * Сервис для работы с маршрутами
 */
export default class RouteService {
  /**
   * Получение списка локаций
   *
   * @param {Boolean} clearCache
   * @return {Promise<Array<Object>|*[]>}
   */
  static async getLocations(clearCache = false) {
    try {
      const query = 'sin2:/v:f775655f-9081-4980-99f2-b0b8a69b6344';

      if (clearCache) {
        await store.dispatch('cache/removeItem', md5('core-read' + query));
      }

      return prepareSinResponse(
        await prepareSinRequest(
          'core-read',
          query,
        )
      );
    } catch (e) {
      console.error('RouteService::getLocations', e);

      return [];
    }
  }

  /**
   * Получение информации об остановке
   *
   * @param {String} id Идентификатор остановки
   * @param {Boolean} clearCache Очистка кеша
   * @returns {Promise<Array<Object>|{}>}
   */
  static async getLocation(id, clearCache = false) {
    try {
      const query = `sin2:/v:f775655f-9081-4980-99f2-b0b8a69b6344?filter=eq(field(".id"),param("${id}", "id"))`;

      if (clearCache) {
        await store.dispatch('cache/removeItem', md5('core-read' + query));
      }

      return prepareSinResponse(
        await prepareSinRequest(
          'core-read',
          query
        )
      );
    } catch (e) {
      console.error('RouteService::getLocation', e);

      return {};
    }
  }

  /**
   * Получение списка трасс
   *
   * @param {String} baseRouteId Идентификатор роута (либо id если null)
   * @returns {Promise<*[]>}
   */
  static async getAlternates(baseRouteId) {
    try {
      let query = `sin2:/v:ac979cd0-86c8-4f52-8dc1-f5fa8ff149e0`;

      query += '?filter=';
      query += `or(eq(field(".BASEROUTEID"),param("${baseRouteId}","id")), eq(field(".ID"),param("${baseRouteId}","id")))`;

      return prepareSinResponse(
        await prepareSinRequest(
          'core-read',
          query,
        ),
        {
          hideUnderscores: true,
        }
      );
    } catch (e) {
      console.error('RouteService::getAlternates', e);

      return [];
    }
  }

  /**
   * Загрузка нулевых трасс
   *
   * @param {String} routeId Идентификатор маршрута
   * @return {Promise<Array<Object>|*[]>}
   */
  static async getZeroTracks(routeId) {
    try {
      const dateWork = formatDate(new Date(Date.now()), 'YYYY-MM-DD HH:mm:ss');

      let query = `sin2:/v:11ac7e7e-1e05-41d2-853b-4a17a2b7256b`;

      query += '?filter=';
      query += `and(`;
      query += `eq(field(".routeid"),param("${routeId}","id")),`;
      query += `lte(field(".startdt"),param("${dateWork}","date")),`;
      query += `or(`;
      query += `isnull(field(".enddt")),`;
      query += `gt(field(".enddt"),param("${dateWork}","date"))`;
      query += `)`; // or
      query += `)`; // and

      return prepareSinResponse(
        await prepareSinRequest(
          'core-read',
          query
        ),
        {
          hideUnderscores: true,
        }
      );
    } catch (e) {
      console.error('RouteService::getZeroTrack', e);

      return [];
    }
  }

  /**
   * Копирование альтернативного маршрута
   *
   * @param {String} baseId
   * @param {String} routeId
   * @param {String} name
   * @return {Promise<null|*>}
   */
  static async copyAlterRoute(baseId, routeId, name) {
    try {
      return await jet.http.post('/rpc?d=jsonRpc', {
        type: 'query',
        query: 'feb1b1ec-48b9-46d4-b59e-93d9ff3f1f09.copyAlterRouteWithTimestamp',
        params: {
          in_tenantID: store.state.auth.subject.tenantId,
          in_targetRouteId: routeId,
          in_baseRouteId: baseId,
          in_userID: store.state.auth.subject.id,
          in_alterDesc: name,
          in_date: new Date().toISOString(),
          in_type: 1,
        },
      });
    } catch (e) {
      console.error('RouteService::copyAlterRoute', e);

      return null;
    }
  }

  /**
   * Удаление альтернативной трассы
   *
   * @param {String} routeId Идентификатор трассы
   * @returns {Promise<null|*>}
   */
  static async removeAlterRoute(routeId) {
    try {
      return await jet.http.post('/rpc?d=jsonRpc', {
        type: 'query',
        query: 'feb1b1ec-48b9-46d4-b59e-93d9ff3f1f09.removeAlterRoute',
        params: {
          in_tenantID: store.state.auth.subject.tenantId,
          in_targetRouteId: routeId,
          in_userID: store.state.auth.subject.id,
        },
      });
    } catch (e) {
      console.error('RouteService::removeAlterRoute', e);

      return null;
    }
  }
  /**
   * Получение текущей версии расписания по свидетельству
   *
   * @param {String} certificateID Идентификатор свидетельства
   * @param {date} dateWork Рабочая дата
   * @param {Boolean} clearCache
   * @return {Promise<Array<Object>|*[]>}
   */
  static async getCurrentScheduleVersion(certificateID, dateWork) {
    var result = null;
    try {
      var query = `sin2:/v:54e55495-92e8-453a-ae02-567494757d32/?filter=
                        and(
                          exists(\"e83a5aa3-fd84-4fa3-82ce-a89529a7acb3\",
                            \"and(
                              eq(field(\\\".id\\\"),param(\\\"${certificateID}\\\", \\\"id\\\")),
                              eq(
                                super.field(\\\".route\\\"), 
                                select(\\\"e8fd22eb-f901-42af-874f-2bf5fe6a78f4\\\",
                                  \\\".route\\\",
                                  \\\"eq(field(\\\\\\\".id\\\\\\\"),super.field(\\\\\\\".servicedRouteID\\\\\\\"))\\\"
                                )
                              )
                            )\"
                          ),
                          not(eq(field(\".verStatus.Name\"), param(\"Проект\",\"string\"))),
                          lte(field(".verStart"),var("util.date.dateFromMillis(${dateWork}l)","date")),
                          or(
                            isnull(field(".verEnd")),
                            gte(field(".verEnd"),var("util.date.dateFromMillis(${dateWork}l)","date"))
                          )
                        )`;
      const response = await jet.http.post('/rpc/?d=jsonRpc', {
        type: 'core-read',
        query: query,
      });
      
      if (!response.error) {
        const data = response.result.data;
        const ci = response.result.columnIndexes;
        if (data.length > 0) {
          var d = data[0];
          result = d[ci['vcreleaseschedules.id']];
        }
      }
    } catch (e) {
      console.error('RouteService::getCurrentScheduleVersion', e);
    }
    return result;
  }

  /**
   * Загрузка версий маршрута
   *
   * @param {String} verID Идентификатор базовой версии маршрута
   * @return {Promise<Array<Object>|*[]>}
   */
  static async getRouteVersions(verID) {
    try {
      let query = `sin2:/v:acd2f542-598c-4540-b46c-03375098d467?filter=eq(field(".verID"),param("${verID}","id"))&sort=-vcRoutes.version`;

      return prepareSinResponse(
        await prepareSinRequest(
          'core-read',
          query
        ),
        {
          keyReplace: {
            verstart: 'vcroutesVerstart',
            routename: 'vcroutesRoutename',
            routecode: 'vcroutesRoutecode',
            verstatus: 'vcroutesVerstatus',
            verid: 'vcroutesVerid',
            id: 'vcroutesId',
            baserouteid: 'vcroutesBaserouteid',
            version: 'vcroutesVersion',
            verstatusName: 'vcroutesVerstatusName',
            alterdesc: 'vcroutesAlterdesc',
            alternumb: 'vcroutesAlternumb',
            enddt: 'vcroutesEnddt',
            isactiveroute: 'vcroutesIsactiveroute',
            startdt: 'vcroutesStartdt',
            verend: 'vcroutesVerend',
          },
          hideUnderscores: true,
        }
      );
    } catch (e) {
      console.error('RouteService::getRouteVersions', e);

      return [];
    }
  }

  /**
   * Загрузка версий маршрута с переданным фильтром
   *
   * @param {String} filter Фильтр для коллекции
   * @return {Promise<Array<Object>|*[]>}
   */
  static async getRouteVersionsFiltered(filter) {
    try {
      let query = `sin2:/v:acd2f542-598c-4540-b46c-03375098d467?filter=${filter}&sort=-vcRoutes.version`;

      return prepareSinResponse(
        await prepareSinRequest(
          'core-read',
          query
        ),
        {
          keyReplace: {
            "_sec_attrs.userid": '_sec_attrsUserid',
            "_sec_attrs.tenantid": '_sec_attrsTenantid',
            routecode: 'vcroutesRoutecode',
            routename: 'vcroutesRoutename',
            id: 'vcroutesId',
            verid: 'vcroutesVerid',
            version: 'vcroutesVersion',
          }
        }
      );
    } catch (e) {
      console.error('RouteService::getRouteVersionsFiltered', e);

      return [];
    }
  }
}
