<template>
  <div class="px-4">
    <v-row>
      <v-col class="pt-0" cols="auto">
        <v-btn @click="back" color="primary">Назад</v-btn>
      </v-col>

      <v-spacer />

      <v-col class="pt-0" cols="auto">
        <AddIncidentReceiver
          :incidentId="message.id"
          @refreshCarriers="init"
        ></AddIncidentReceiver>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="6" class="ml-1">
        Тип: {{ message.typeName }}
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="6" class="ml-1">
        Дата события: {{ message.eventdate }}
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="6" class="ml-1">
        Заголовок: {{ message.title }}
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="6" class="ml-1">
        Текст: {{ message.text }}
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="6" class="ml-1">
        <div class="d-flex">
          Архив:
          <v-checkbox v-model="message.isread" disabled class="pt-0 mt-0 ml-2" hide-details/>
        </div>
      </v-col>
    </v-row>

    <v-row v-if="message.file">
      <v-col cols="6" class="ml-1">
        Файл:
        <v-btn
          class="px-0"
          text
          style="text-transform: initial"
          @click="openFile"
        >
          <u>{{ message.file.name || 'Имя отсутствует'}}</u>
        </v-btn>
      </v-col>
    </v-row>
    <v-data-table
      :headers="headers"
      :items="carriers"
      :loading="loading"
    ></v-data-table>
  </div>
</template>

<script>

  import OtherService from "@/services/OtherService";
  import AddIncidentReceiver from "@/components/dev/modules/incidents/components/AddIncidentReceiver";

  export default {
    name: "IncidentPage",
    components: {AddIncidentReceiver},
    props: {
      message: {
        type: Object,
        required: true,
      },
      isModerator: {
        type: Boolean,
        default: false,
      },
    },
    data: () => ({
      loading: true,
      headers: [
        {text: 'Перевозчик', value: 'shortName'},
        {text: 'Статус', value: 'status'},
        {text: 'Дата', value: 'date'},
      ],
      carriers: [],
    }),
    created() {
      this.init();
    },
    methods: {
      async init() {
        this.loading = true;
        this.carriers = await OtherService.getStatusIncident(this.message.id);
        this.loading = false;
      },
      back() {
        this.$emit('back');
      },
      openFile() {
        location.href = '/rpc/?d=file&uri=' + this.message.file.ref;
      },
    },
  };
</script>
