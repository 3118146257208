<template>
  <v-dialog
    persistent
    max-width="600px"
    v-model="show"
  >
    <v-card>
      <v-card-title class="primary white--text">
        Настройка рабочего периода
      </v-card-title>

      <v-card-text>
        <v-container
          fluid
          grid-list-lg
          class="pa-0"
        >
          <v-layout wrap align-center>
            <v-flex sm6>
              <v-select
                hide-details
                label="Период"
                v-model="period"
                :items="periods"
              ></v-select>
            </v-flex>

            <v-flex sm6>
              <v-select
                hide-details
                label="Год"
                v-model="year"
                :items="years"
              ></v-select>
            </v-flex>

            <v-flex sm6>
              <v-menu
                v-model="menuPeriodBegin"
                :return-value="begin"
                :close-on-content-click="false"
                lazy
                offset-y
                full-width
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    readonly hide-details
                    append-icon="far fa-calendar-alt"
                    label="Начало периода"
                    v-model="beginFormat"
                    v-on="on"
                  ></v-text-field>
                </template>

                <v-date-picker
                  no-title scrollable
                  locale="ru-RU"
                  v-model="begin"
                  :first-day-of-week="1"
                  :weekday-format="dayOfWeekToFormat"
                  :max="maxDate"
                  :min="minDate"
                  @input="menuPeriodBegin = false"
                ></v-date-picker>
              </v-menu>
            </v-flex>

            <v-flex sm6>
              <v-menu
                v-model="menuPeriodEnd"
                :return-value="end"
                :close-on-content-click="false"
                lazy
                offset-y
                full-width
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="endFormat"
                    label="Конец периода"
                    append-icon="far fa-calendar-alt"
                    readonly
                    hide-details
                    v-on="on"
                  ></v-text-field>
                </template>

                <v-date-picker
                  no-title scrollable
                  locale="ru-RU"
                  v-model="end"
                  :first-day-of-week="1"
                  :weekday-format="dayOfWeekToFormat"
                  :max="maxDate"
                  :min="minDate"
                  @input="menuPeriodEnd = false"
                ></v-date-picker>
              </v-menu>
            </v-flex>

            <v-flex sm12>
              <v-menu
                v-model="menuPeriodCurrent"
                :return-value="current"
                :close-on-content-click="false"
                lazy
                offset-y
                full-width
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="currentFormat"
                    label="Рабочая дата"
                    append-icon="far fa-calendar-alt"
                    readonly
                    hide-details
                    v-on="on"
                  ></v-text-field>
                </template>

                <v-date-picker
                  no-title scrollable
                  locale="ru-RU"
                  v-model="current"
                  :first-day-of-week="1"
                  :weekday-format="dayOfWeekToFormat"
                  :max="maxDate"
                  :min="minDate"
                  @input="menuPeriodCurrent = false"
                ></v-date-picker>
              </v-menu>
            </v-flex>

            <v-flex sm12>
              <v-select
                v-model="mode"
                :items="modes"
                label="Режим работы"
                hide-details
              ></v-select>
            </v-flex>
          </v-layout>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer/>

        <v-btn
          text
          color="primary"
          @click="onVBtnSaveClick"
        >
          Сохранить
        </v-btn>

        <v-btn
          text
          @click="onVBtnCancelClick"
        >
          Отмена
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {formatDate} from '@/utils/utils';
import {dayOfWeekToFormat} from "@/services/JetDate";

const _formatDateUser = 'DD.MM.yyyy';
const _formatDate = 'yyyy-MM-DD';

export default {
  name: 'TheDialogPeriod',
  props: {
    visible: {
      type: [Boolean, Number],
    },
  },
  data() {
    return {
      periods: [
        {
          offset: 0,
          count: 0,
          value: 0,
          text: 'Произвольный',
        },
        {
          offset: 0,
          count: 1,
          value: 1,
          text: 'Январь',
        },
        {
          offset: 1,
          count: 1,
          value: 2,
          text: 'Февраль',
        },
        {
          offset: 2,
          count: 1,
          value: 3,
          text: 'Март',
        },
        {
          offset: 3,
          count: 1,
          value: 4,
          text: 'Апрель',
        },
        {
          offset: 4,
          count: 1,
          value: 5,
          text: 'Май',
        },
        {
          offset: 5,
          count: 1,
          value: 6,
          text: 'Июнь',
        },
        {
          offset: 6,
          count: 1,
          value: 7,
          text: 'Июль',
        },
        {
          offset: 7,
          count: 1,
          value: 8,
          text: 'Август',
        },
        {
          offset: 8,
          count: 1,
          value: 9,
          text: 'Сентябрь',
        },
        {
          offset: 9,
          count: 1,
          value: 10,
          text: 'Октябрь',
        },
        {
          offset: 10,
          count: 1,
          value: 11,
          text: 'Ноябрь',
        },
        {
          offset: 11,
          count: 1,
          value: 12,
          text: 'Декабрь',
        },
        {
          offset: 0,
          count: 3,
          value: 13,
          text: '1-й квартал',
        },
        {
          offset: 3,
          count: 3,
          value: 14,
          text: '2-й квартал',
        },
        {
          offset: 6,
          count: 3,
          value: 15,
          text: '3-й квартал',
        },
        {
          offset: 9,
          count: 3,
          value: 16,
          text: '4-й квартал',
        },
        {
          offset: 0,
          count: 6,
          value: 17,
          text: '1-е полугодие',
        },
        {
          offset: 0,
          count: 9,
          value: 18,
          text: '9 месяцев',
        },
        {
          offset: 0,
          count: 12,
          value: 19,
          text: 'Год',
        },
      ],
      maxDate: '2030', // максимально допустимый год
      minDate: '2000', // минимально допустимый год
      menuPeriodBegin: false,
      menuPeriodEnd: false,
      menuPeriodCurrent: false,
      beginMs: this.$store.state.period.period.begin,
      endMs: this.$store.state.period.period.end,
      currentMs: this.$store.state.period.period.current,
      mode: this.$store.state.period.period.mode,
      modes: [
        { value: 1, text: 'Автоматическая настройка рабочей даты' },
        { value: 2, text: 'Скользящий период и рабочая дата' },
        { value: 3, text: 'Неизменный период и рабочая дата' },
      ],
      dayOfWeekToFormat: () => '',
    };
  },
  computed: {
    show() {
      return (!!this.visible);
    },
    year: {
      get() {
        const { beginMs, endMs } = this;
        const yearBegin = new Date(beginMs).getFullYear();
        const yearEnd = new Date(endMs).getFullYear();

        if (yearBegin === yearEnd) return yearBegin;

        return null;
      },
      set(value) {
        const day = 24 * 60 * 60 * 1000; // миллесекунд в сутках

        // При изменении года проверяем не изменился ли месяц (автокорректировка
        // JavaScript), если изменился отнимаем сутки
        const dateBegin = new Date(this.beginMs);
        let newDateBegin = new Date(this.beginMs);
        newDateBegin = new Date(newDateBegin.setFullYear(value));

        if (dateBegin.getMonth() !== newDateBegin.getMonth()) {
          newDateBegin = newDateBegin - day;
        }

        // При изменении года проверяем не изменился ли месяц (автокорректировка
        // JavaScript), если изменился отнимаем сутки
        const dateEnd = new Date(this.endMs);
        let newDateEnd = new Date(this.endMs);
        newDateEnd = new Date(newDateEnd.setFullYear(value));

        if (dateEnd.getMonth() !== newDateEnd.getMonth()) {
          newDateEnd = newDateEnd - day;
        }

        // При изменении года проверяем не изменился ли месяц (автокорректировка
        // JavaScript), если изменился отнимаем сутки
        const dateCurrent = new Date(this.currentMs);
        let newDateCurrent = new Date(this.currentMs);
        newDateCurrent = new Date(newDateCurrent.setFullYear(value));

        if (dateCurrent.getMonth() !== newDateCurrent.getMonth()) {
          newDateCurrent = newDateCurrent - day;
        }

        this.beginMs = newDateBegin;
        this.endMs = newDateEnd;
        this.currentMs = newDateCurrent;
      },
    },
    period: {
      get() {
        const { periods, beginMs, endMs } = this;

        // Проверяем являются ли даты начала и конца периода датами начала
        // и конца месяца(ев)
        if (
          !$utils.isMonthBegin(beginMs) ||
          !$utils.isMonthEnd(endMs)
        ) return 0;

        const monthBegin = new Date(beginMs).getMonth();
        const monthEnd = new Date(endMs).getMonth();

        /**
         * Ищем период удовлетворяющий заданным условиям
         * @param {Number} offset
         * @param {Number} count
         * @return {Number}
         */
        function getPeriodValue(offset, count) {
          let value;

          for (let i = 0, size = periods.length; i < size; i++) {
            const period = periods[i];

            if (period.offset === offset && period.count === count) {
              value = period.value;
              break;
            }
          }

          return value;
        }

        // Проверяем на равенство месяц начала и конца периода
        if (monthBegin === monthEnd) {
          return getPeriodValue(monthBegin, 1);
        }

        // Квартал начинается с определённых месяцев
        const quarterStarts = [0, 3, 6, 9];

        // Вычисляем кол-во месяцев между датой начала и конца периода
        const diffBeginEnd = monthEnd - monthBegin;

        // Проверяем является ли месяц начала периода месяцом начала квартала и
        // соответствует ли кол-во месяцев между датой начала и конца периода
        // кол-ву месяцев в квартале
        if (quarterStarts.includes(monthBegin) && diffBeginEnd === 2) {
          return getPeriodValue(monthBegin, 3);
        }

        // Квартал начинается с определённых месяцев (учитываем только первое
        // полугодие)
        const halfYearStarts = [0];

        // Проверяем является ли месяц начала периода месяцем начала полугодия и
        // соответствует ли кол-во месяцев между датой начала и конца периода
        // кол-ву месяцев в полугодие
        if (halfYearStarts.includes(monthBegin) && diffBeginEnd === 5) {
          return getPeriodValue(monthBegin, 6);
        }

        // Проверяем является ли месяц начала периода месяцем начала года и
        // соответсвует ли кол-во месяцев между датой начала и конца периода
        // кол-ву месяцев в периоде (9 месяцев)
        if (monthBegin === 0 && diffBeginEnd === 8) {
          return getPeriodValue(monthBegin, 9);
        }

        // Проверяем является ли месяц начала периода месяцем начала года,
        // а месяц конца периода месяцем конца года
        if (monthBegin === 0 && monthEnd === 11) {
          return getPeriodValue(monthBegin, 12);
        }

        return 0;
      },
      set(value) {
        const { periods } = this;
        let { year } = this;
        let period;

        for (let i = 0, size = periods.length; i < size; i++) {
          if (periods[i].value === value) {
            period = periods[i];
            break;
          }
        }

        if (year === null) {
          year = new Date(this.beginMs).getFullYear();
        }

        const beginMs = +new Date(year, period.offset, 1);
        const endMs = +new Date(year, period.offset + period.count, 0);

        this.beginMs = beginMs;
        this.endMs = endMs;

        if (this.currentMs < beginMs || this.currentMs > endMs) {
          this.currentMs = endMs;
        }
      },
    },
    years() {
      let from = Number(this.minDate);
      const to = Number(this.maxDate);
      const result = [{
        value: null,
        text: 'Несколько значений',
      }];

      do {
        result.push({
          value: from,
          text: from,
        });
        from++;
      } while (from <= to);

      return result;
    },
    beginFormat() {
        return formatDate(this.beginMs, _formatDateUser);
    },
    begin: {
      get() {
        return formatDate(this.beginMs, _formatDate);
      },
      set(value) {
        const newBeginMs = +new Date(value);

        if (newBeginMs > this.endMs) {
          this.beginMs = this.endMs;
          this.currentMs = this.endMs;
        } else if (newBeginMs > this.currentMs) {
          this.beginMs = newBeginMs;
          this.currentMs = this.endMs;
        } else {
          this.beginMs = newBeginMs;
        }
      },
    },
    currentFormat() {
        return formatDate(this.currentMs, _formatDateUser);
    },
    current: {
      get() {
        return formatDate(this.currentMs, _formatDate);
      },
      set(value) {
        const newCurrentMs = +new Date(value);

        if (newCurrentMs < this.beginMs || newCurrentMs > this.endMs) {
          this.currentMs = this.endMs;
        } else {
          this.currentMs = newCurrentMs;
        }
      },
    },
    endFormat() {
        return formatDate(this.endMs, _formatDateUser);
    },
    end: {
      get() {
        return formatDate(this.endMs, _formatDate);
      },
      set(value) {
        this.endMs = +new Date(value);

        if (this.currentMs > this.endMs) {
          this.currentMs = this.endMs;
        }
      },
    },
  },
  created() {
    this.dayOfWeekToFormat = dayOfWeekToFormat;
  },
  methods: {
    onVBtnSaveClick() {
      this.$store.commit('period/set', {
        begin: this.beginMs,
        end: this.endMs,
        current: this.currentMs,
        mode: this.mode,
      });
      this.visible = false;
    },
    onVBtnCancelClick() {
      this.visible = false;
    },
  },
};
</script>
