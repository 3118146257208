<template>
  <v-container class="px-8 pt-4">

    <v-btn v-if="!isOnce" color="primary" @click="back(false)">Назад</v-btn>

    <v-row>

      <v-col cols="8">
        <v-text-field
          v-model="nameLeader"
          label="ФИО руководителя"
          :rules="[ruleFio]"
        ></v-text-field>
        <v-text-field
          v-model="phoneLeader"
          label="Номер руководителя"
        ></v-text-field>
        <v-text-field
          v-model="orgPlace"
          label="Юр. адрес"
        ></v-text-field>
        <v-text-field
          v-model="factAddress"
          label="Фактический адрес"
        ></v-text-field>
        <v-text-field
          v-model="orgTlf"
          label="Телефон"
        ></v-text-field>
        <v-text-field
          v-model="email"
          label="Email"
        ></v-text-field>

        <v-row>
          <v-col>
            <v-btn
              @click="resetToOriginal"
              color="red"
              class="white--text"
              :disabled="!changed"
            >
              Сбросить
            </v-btn>
          </v-col>
          <v-col align="right">
            <v-btn
              @click="submit"
              color="primary"
              class="white--text"
              :loading="loadingButton"
              :disabled="!changed"
            >
              Отправить на модерацию
            </v-btn>
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="4">

        <template v-if="modificationProfile">

          <!-- На модерации -->
          <div v-if="!modificationProfile.moderationdt" class="blue-border pa-2 mb-4 mt-2">
            <v-row>
              <v-col align="center" class="pa-0" style="color: blue">
                На модерации
              </v-col>
            </v-row>
          </div>

          <!-- Отклонено -->
          <div v-else-if="!modificationProfile.status" class="red--text red-border pa-2 mb-4 mt-2">
            <v-row>
              <v-col align="center" class="pa-0">
                <v-icon color="red">mdi-exclamation-thick</v-icon>
                Отклонено
              </v-col>
            </v-row>
            <v-row>
              <v-col align="center" class="pa-0">{{ modificationProfile.comment }}</v-col>
            </v-row>
          </div>

          <!-- Изменено -->
          <div
            v-else-if="modificationProfile.status"
            class="green--text green-border pa-2 mb-4 mt-2"
          >
            <v-row>
              <v-col align="center" class="pa-0">
                <v-icon color="green"> mdi-check-bold</v-icon>
                Изменено
              </v-col>
            </v-row>
            <v-row>
              <v-col align="center" class="pa-0">
                {{ formatDate(modificationProfile.moderationdt) }}
              </v-col>
            </v-row>
          </div>
        </template>

        <div v-else-if="loadingButton" class="b-spinner mt-4">
          <v-progress-circular indeterminate color="primary"/>
        </div>
      </v-col>

    </v-row>
  </v-container>
</template>

<script>
import ProfilesService from '@/components/dev/service/ProfilesService';

// Mixins
import HideGlobalToolbarMixin from '@/components/dev/mixin/HideGlobalToolbarMixin';

export default {
  mixins: [
    HideGlobalToolbarMixin,
  ],
  name: 'Profile',
  props: {
    originalProfile: {
      type: Object,
      required: true,
    },
    isOnce: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    loadingButton: false,
    nameLeader: '',
    phoneLeader: '',
    orgPlace: '',
    factAddress: '',
    orgTlf: '',
    email: '',
    carrierId: '',
    orgId: '',
    hdrId: '',
    modificationProfile: null,
    change: false,
    ruleFio: fio => {
      const pattern = /.+ .+/;
      return pattern.test(fio) || 'Введите фамилию и имя';
    },
  }),
  computed: {
    changed() {
      if (this.nameLeader !== this.originalProfile.nameLeader) {
        return true;
      }
      if (this.phoneLeader !== this.originalProfile.phoneLeader) {
        return true;
      }
      if (this.orgPlace !== this.originalProfile.orgPlace) {
        return true;
      }
      if (this.factAddress !== this.originalProfile.factAddress) {
        return true;
      }
      if (this.orgTlf !== this.originalProfile.orgTlf) {
        return true;
      }
      if (this.email !== this.originalProfile.email) {
        return true;
      }
      if (this.carrierId !== this.originalProfile.carrierId) {
        return true;
      }
      if (this.orgId !== this.originalProfile.orgId) {
        return true;
      }
      return false;
    },
  },
  created() {
    this.load();
  },
  methods: {
    formatDate(date) {
      return $utils.formatDate2(new Date(date), 'yyyy-MM-dd HH:mm:ss');
    },
    async load() {
      this.loadingButton = true;
      this.resetToOriginal();
      this.modificationProfile = await ProfilesService.getModificationProfile(this.carrierId);
      this.applyChange();
      this.loadingButton = false;
    },
    // сбрасывает все значения на установленные поумолчанию
    resetToOriginal() {
      this.nameLeader = this.originalProfile.nameLeader;
      this.phoneLeader = this.originalProfile.phoneLeader;
      this.orgPlace = this.originalProfile.orgPlace;
      this.factAddress = this.originalProfile.factAddress;
      this.orgTlf = this.originalProfile.orgTlf;
      this.email = this.originalProfile.email;
      this.carrierId = this.originalProfile.carrierId;
      this.orgId = this.originalProfile.orgId;
      this.hdrId = this.originalProfile.hdrId;
    },
    applyChange() {
      if (this.modificationProfile && !this.modificationProfile.moderationdt) {
        const mp = this.modificationProfile.changes;
        if (mp.nameLeader) {
          this.nameLeader = mp.nameLeader;
        }
        if (mp.orgPlace) {
          this.orgPlace = mp.orgPlace;
        }
        if (mp.factAddress) {
          this.factAddress = mp.factAddress;
        }
        if (mp.orgTlf) {
          this.orgTlf = mp.orgTlf;
        }
        if (mp.email) {
          this.email = mp.email;
        }
        if (mp.phoneLeader) {
          this.phoneLeader = mp.phoneLeader;
        }
      }
    },
    async submit() {
      this.loadingButton = true;
      const params = {};
      if (this.nameLeader !== this.originalProfile.nameLeader) {
        params.nameLeader = this.nameLeader;
      }
      if (this.orgPlace !== this.originalProfile.orgPlace) {
        params.orgPlace = this.orgPlace;
      }
      if (this.factAddress !== this.originalProfile.factAddress) {
        params.factAddress = this.factAddress;
      }
      if (this.orgTlf !== this.originalProfile.orgTlf) {
        params.orgTlf = this.orgTlf;
      }
      if (this.email !== this.originalProfile.email) {
        params.email = this.email;
      }
      if (this.phoneLeader !== this.originalProfile.phoneLeader) {
        params.phoneLeader = this.phoneLeader;
      }
      await ProfilesService.submitModificationProfile(
        this.carrierId,
        this.orgId,
        this.hdrId,
        JSON.stringify(params));
      this.loadingButton = false;
      await this.load();
      this.change = true;
    },
    back(reload = false) {
      this.$emit('closeDialog', reload || this.change);
    },
  },
};
</script>

<style scoped lang="sass">
.red-border
  border: 1px solid red
  border-collapse: collapse

.blue-border
  border: 1px solid blue
  border-collapse: collapse

.green-border
  border: 1px solid green
  border-collapse: collapse

.container
  width: 1200px
  padding: 0
  margin: 0
</style>
