<script>
export default{
    props: {
        'owner': {
            type: Object,
            required: true
        }
    },
    data: () => ({
        /*params*/
        certificateID: null,
        /*params filters*/
        f_scheduleVersionID: null
    }),
    created(){
        console.log('10.2 created by', this.owner);
        
        this._initingFilters();
        
        this.owner.on_set = this._set;
    },
    methods: {
        _completingFilters(param){
            param = param.replaceAll(`param_certificateID`, this.certificateID);
            return param;
        },
        _set(param){
            console.log('10.2.set: ' + param.name + ' ', param.value);
            switch (param.name){
                case "certificateID":
                    this.certificateID = param.value;
                    this._setCertificateID();
                    break;
            }
        },
        _setCertificateID(){
            if (!$utils.isEmpty(this.certificateID)) {
                var comp = this.owner.$refs["scheduleVersionID"];
                var filter = this._completingFilters(this.f_scheduleVersionID);
                comp.setFilter(filter);
            }
        },
        _initingFilters(){
            this.f_scheduleVersionID = 
                    `and(
                        exists(\"e83a5aa3-fd84-4fa3-82ce-a89529a7acb3\",
                            \"and(
                                eq(field(\\\".id\\\"),param(\\\"param_certificateID\\\", \\\"id\\\")),
                                eq(
                                    super.field(\\\".route\\\"), 
                                    select(\\\"e8fd22eb-f901-42af-874f-2bf5fe6a78f4\\\",
          \\\".route\\\",
          \\\"eq(field(\\\\\\\".id\\\\\\\"),super.field(\\\\\\\".servicedRouteID\\\\\\\"))\\\"
                                    )
                                )
                            )\"
                        ),
                        not(eq(field(\".verStatus.Name\"), param(\"Проект\",\"string\")))
                    )`;
        }
    },
    render(h){
        h('i',{class: "rep-stub"});
    }
}
</script>