<script>
import Model from '@/core/Model';
const $moment = require('moment');
$moment.locale('ru');
export default {
    name: 'Vehicles',
    data() {
        return {
            isEdit: false
        }
    },
    methods: {
        async checkGovNum(requestData) {
            try {
                let capt = "Предупреждение";
                let msg = "";
                let year = 2000;
                const delimeter = "\n";
                year = requestData.yr;
                const govNum = requestData.govNum;
                const VIN = requestData.vin;
                const ID = requestData.id || jet.utils.uuidv4();
                const startDt = requestData.startDt;
                const endDt = requestData.endDt;

                if (year == null) year = 2000;
                if (year < 1900 || year > 2200) {                	
                    msg = "Год должен попадать в промежуток от 1900 до 2200.";
                }

                let carrierName = "";
                let vehicleID = "";

                // Проверка VIN кода
                if(!!VIN) {
                    const req1 = {
                        type: 'query',
                        query: (!!endDt) ? "14efced2-8c59-4111-a8e7-79b514eed84b.sqlChkEmptyGovNum" : "14efced2-8c59-4111-a8e7-79b514eed84b.sqlChkEmptyGovNum2",
                        params: (!!endDt) ? {VIN: VIN, startDt: startDt, endDt: endDt, ID: ID} : {VIN: VIN, startDt: startDt, ID: ID}
                    }

                    let rs1 = await $http.post(req1);
                    
                    if(rs1.error) {
                        throw new Error("Ошибка проверки VIN кода");
                    }
                    
                    const data1 = rs1.result.data;
                    if(data1.length > 0){
                        carrierName = data1[0][0];
                        vehicleID  = data1[0][1];
                        msg = msg + delimeter + "ТС с введенным VIN кодом уже заведено в системе – «" + carrierName + "».";
                    }
                }

                // Проверка Гос номера
                const req2 = {
                    type: 'query',
                    query: (!!endDt) ? "14efced2-8c59-4111-a8e7-79b514eed84b.sqlChkEmptyVin" : "14efced2-8c59-4111-a8e7-79b514eed84b.sqlChkEmptyVin2",
                    params: (!!endDt) ? {govNum: govNum, startDt: startDt, endDt: endDt, ID: ID} : {govNum: govNum, startDt: startDt, ID: ID}
                }

                let rs2 = await $http.post(req2);

                if(rs2.error) {
                    throw new Error("Ошибка проверки Гос. номера");
                }

                carrierName = "";

                const data2 = rs2.result.data;
                if(data2.length > 0){
                    carrierName = data2[0][0];
                    vehicleID  = data2[0][1];
                    msg = msg + delimeter + "ТС с введенным гос. номером уже заведено в системе – «" + carrierName + "».";
                }
                
                
                // Проверка маски
                const maskID = requestData.maskId;
                const latin = govNum.match(/[a-z]/i);
                if(latin){
                    msg = msg + delimeter + "В рег. номере присутствуют латинские буквы. Сохранение невозможно.";
                }
                
                const req3 = {
                    type: 'query',
                    query: "14efced2-8c59-4111-a8e7-79b514eed84b.chkMask",
                    params: {govNum, maskID}
                }
                let rs3 = await $http.post('/rpc?d=jsonRpc', req3);
                const data3 = rs3.result.data;
                
                if(rs3 !== null){
                    if(data3.length > 0){
                        msg = msg + delimeter + "Рег. № " + govNum + " не соответствует формату " + data3[0] + ".";
                    }
                }
                if(msg != "") {
                    jet.msg({ text: msg, color:'warning', centered: true, bottom: false });
                    return false;
                }

                return true;
            } catch (error) {
                jet.msg({ text: error.message, color:'error' });
                return false;
            }
        },
        setCarrierTenant(carrierId) {
            setTimeout(() => {
                const currentCarrierId = !!carrierId ? carrierId : this.getMasterId();
                const carrier = this.$refs.vcvehicleCrrid.getItems().filter(it => it.vcautocarrierId == currentCarrierId)[0];
                const tenantId = !!carrierId ? this.$refs.vcvehicleTenant.value || this.$refs.vcvehicleTenanttmp.value : carrier.vcautocarrierTenantid;
                
                this.$refs.vcvehicleCrrid.setValue(currentCarrierId);
                this.$refs.vcvehicleCrrid.text = carrier.vcautocarrierOrgidOrgcode + " " + carrier.vcautocarrierOrgidShortname;
                this.$refs.vcvehicleTenant.setValue(tenantId);
                this.$refs.vcvehicleTenant.text = this.$refs.vcvehicleTenant.getItems().filter(it => it.ssctenantsId == tenantId)[0].ssctenantsTitle;
            }, 2000)
        },
        async updateTenant(params) {
            const req = {
                type: 'query',
                query: '14efced2-8c59-4111-a8e7-79b514eed84b.changeTenant',
                params: params
            };

            try {
                const res = await jet.http.post('/rpc/?d=jsonRpc', req);
                if(!res.error) {
                    const data = res.result.data;
                }
            } catch(error) {
                console.error("ERR: updateTenant()", error);
            }
            
        }
    },
    async mounted(){
        const self = this;
        self.isEdit = !!self.vcvehicleId;
        if(!self.isEdit) {
            // CREATE
            self.setCarrierTenant();
        } else {
            // EDIT
            const carrierId = self.$refs.vcvehicleCrrid.value;
            self.setCarrierTenant(carrierId);
        }

        self.$refs.vcvehicleNumplaces.rules = [];
        self.$refs.vcvehicleNumplaces2.rules = [];
        
        self.$parent.beforeSave = async () => {
            const form = self.$refs["form"];
            self.vcvehicleModelspecid = self.vcvehicleModel;
            self.vcvehicleTenanttmp = self.vcvehicleTenant;
            
            
            if(!form.validate()) {
                return false;
            }

            try {
                const requestData = {
                    yr: self.vcvehicleYr,
                    govNum: self.vcvehicleGovnum,
                    vin: self.vcvehicleVin,
                    id: self.vcvehicleId,
                    startDt: self.vcvehicleStartdt,
                    endDt: self.vcvehicleEnddt,
                    maskId: self.vcvehicleMaskid
                }

                const result = await self.checkGovNum(requestData);

                if(!(!!result)) {
                    return false;
                } 

                return true;
            } catch (error) {
                console.error('ERR: beforeSave()', error);
            }
        };

        self.$parent.afterSave = async ({id}) => {
            try {
                const updateTenantParams = {
                    in_vehicleID: self.vcvehicleId || id,
                    in_tenantID: self.vcvehicleTenant || self.vcvehicleTenanttmp 
                };

                await self.updateTenant(updateTenantParams);

                jet.collections.refresh();
            } catch (error) {
                console.error('ERR: afterSave()', error);
            }
        }

        
    }
}
</script>

<style lang="scss">

</style>