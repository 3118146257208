<script>
import { NULL_ID, isEmpty } from "@/utils";

/**
 * Report ("332cf8e3-01a4-417e-98ce-588ed4aeeb29"): 10.41 Расписание водительское 
 * params handle
 */
export default{
    name: "Rep1041",
    props: {
        owner: {
            type: Object,
            required: true
        }
    },
    data: () => ({
    }),
    created(){
        this.owner.on_set = this._set;
        this._setRoute(null);
    },
    methods: {
        _set(param){
            switch (param.name){
                case "routeID":
                    this._setRoute(param.value);
                    break;
            }
        },
        _setRoute(id){
            this.route = id;
            const comp = this.owner.$refs["releaseScheduleID"];
            const filter = `eq(field(\"vcReleaseSchedules.route\"),param(\"${ isEmpty(id) ? NULL_ID : id }\", \"id\"))`;
            console.log('comp (filter)', comp, filter);
            comp.setFilter(filter);
        }
    },
    render(h){
        h('i',{class: "rep-stub"});
    }
}
</script>