const $moment = require('moment');
window["$moment"] = $moment;

function isEmpty(val) {
  switch (typeof val) {
    case 'string':
      return /^$/.test(val);
    case 'undefined':
      return true;
    case 'number':
      return false;
    default:
      return true;
  }
}   //isEmpty

/**
 * @param {Number|String|Date} date
 * @param {String} format
 * @return {String}
 * TODO: by moment.js
 */
function formatDate(date, format) {
  return date ? $moment(date).format(format) : null;
}

/**
 * Форматирование даты из Data в нужный формат
 * Дата с временем: 'yyyy-MM-dd HH:mm:ss'
 *
 * @param {Number|String|Date} d
 * @param {String} format
 * @return {String}
 */
function formatDate2(d, format = 'dd.MM.yyyy') {
  d = new Date(d) || new Date();

  const date = d.getDate();
  const month = d.getMonth() + 1;
  const year = d.getFullYear();
  const hours = d.getHours();
  const minutes = d.getMinutes();
  const seconds = d.getSeconds();

  let result = format;
  result = result.replace(/dd/, setPadding(date));
  result = result.replace(/MM/, setPadding(month));
  result = result.replace(/yyyy/, year);
  result = result.replace(/HH/, setPadding(hours));
  result = result.replace(/mm/, setPadding(minutes));
  result = result.replace(/ss/, setPadding(seconds));

  return result;
}

/**
 * Читаем данные из localStorage
 * @param {String} itemName
 * @param {String} key
 * @return {*}
 */
function readLocalStorage(itemName, key) {
  let item = window.localStorage.getItem(itemName);

  item = JSON.parse(item);

  if (!!item) {
    if (key) {
      return item[key] || null;
    } else {
      return item;
    }
  } else {
    return null;
  }
}

/**
 * Сохраняем данные в localStorage
 * @param {String} itemName
 * @param {Object} obj
 */
function saveLocalStorage(itemName, obj) {
  let item = window.localStorage.getItem(itemName);

  item = JSON.parse(item);

  if (item) {
    Object.assign(item, obj);
  } else {
    item = obj;
  }

  window.localStorage.setItem(itemName, JSON.stringify(item));
}

/**
 * Получаем начало месяца
 * @return {Number}
 */
function getMonthBegin() {
  let d = new Date();
  d = d.setDate(1);
  d = new Date(d);
  d = d.setHours(0, 0, 0, 0);
  return d;
}

/**
 * Получаем конец месяца
 * @return {Number}
 */
function getMonthEnd() {
  let d = new Date();
  const month = d.getMonth();
  const fullYear = d.getFullYear();
  d = +new Date(fullYear, month + 1, 0);
  return d;
}

/**
 * Проверяем день на начало месяца
 * @param {Number} d - дата в миллисекундах
 * @return {Boolean}
 */
function isMonthBegin(d) {
  d = new Date(d);
  return d.getDate() === 1;
}

/**
 * Проверяем день на конец месяца
 * @param {Number} d - дата в миллисекундах
 * @return {Boolean}
 */
function isMonthEnd(d) {
  d = new Date(d);
  const month = d.getMonth();
  const day = 24 * 60 * 60 * 1000; // миллесекунд в сутках
  const nextMonth = new Date(+d + day).getMonth();
  return month !== nextMonth;
}

/**
 * Получаем координаты элемента на странице
 * @param {HTMLElement} el
 * @return {Object}
 */
function getCoords(el) {
  const box = el.getBoundingClientRect();

  return {
    top: box.top + window.pageYOffset,
    left: box.left + window.pageXOffset
  };
}

/**
 * Получаем id из uri
 * @param {String} uri
 * @return {String|null}
 */
function parseId(uri) {
  const matches = uri.match(
    /sin2:\/[cv]:([a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12})/
  );
  const id = matches[1];

  if (typeof id !== 'string') {
    return null;
  }

  return id;
}

function uuidv4() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    const r = Math.random() * 16 | 0;
    const v = c === 'x' ? r : (r & 0x3 | 0x8);

    return v.toString(16);
  });
}

/**
 * @param {String|Number} value
 * @param {Number} size
 * @return {String}
 */
function setPadding(value, size = 2) {
  let s = `${value}`;

  while (s.length < size) {
    s = `0${s}`;
  }

  return s;
}

/**
 *
 * @param {Array<any>} arr
 * @param {(item: any) => string | number} func
 * @returns {Array<any>}
 */
function uniqBy(arr, func) {
  if (!arr.length) {
    return [];
  }

  return Object.values(arr.reduce((prevent, current) => {
    const key = func(current);

    return {...prevent, [key]: current};
  }, {}));
}

function sin2obj(cols, data) {
  const keys = Object.keys(cols);
  const o = {};

  keys.forEach(key => {
    const n = key.lastIndexOf('.');
    const s = (n < 0) ? key : key.substr(n + 1);

    o[s] = data[cols[key]];
  });

  return o;
}

/**
 * Склонение числительных
 *
 * @param {Number} number Число
 * @param {Array<String>} titles Массив вариантов
 * @return {String}
 */
function declOfNum(number, titles) {
  number = Math.abs(number);

  const cases = [2, 0, 1, 1, 1, 2];

  return titles[
    (number % 100 > 4 && number % 100 < 20)
      ? 2
      : cases[(number % 10 < 5) ? number % 10 : 5]
    ];
}

/**
 * Градусы в радианы
 *
 * @param {Number} angle Угол в градусах
 * @return {number}
 */
function deg2rad(angle) {
  return (angle / 180) * Math.PI;
}

/**
 * Получение RGBA цвета из числа|объекта
 *
 * @param {number|{r:number,g:number,b:number}|*} color Цвет
 * @param {number} alpha Альфа канал (0.0 - 1.0)
 * @return {string}
 */
function colorFromInt(color, alpha = 1) {
  if (typeof color === 'object') {
    return `rgba(${color.r},${color.g},${color.b},${alpha})`;
  }

  const blue = color & 255;
  const green = (color >> 8) & 255;
  const red = (color >> 16) & 255;

  return `rgba(${red},${green},${blue},${alpha})`;
}

/**
 *
 * @param {{lat: Number, lon: Number}} pointLngLat
 * @param {{lat: Number, lon: Number}} featureLngLat
 */
function pixelToMeter(pointLngLat, featureLngLat) {
  // pow(sin(deg2rad( ($lat1-$lat2) / 2)), 2)
  const l1 = Math.pow(Math.sin(deg2rad((pointLngLat.lat - featureLngLat.lat) / 2)), 2);
  // cos(deg2rad($lat1))
  const l2 = Math.cos(deg2rad(pointLngLat.lat));
  // cos(deg2rad($lat2))
  const l3 = Math.cos(deg2rad(featureLngLat.lat));
  // pow(sin(deg2rad(($lng1- $lng2) / 2))
  const l4 = Math.pow(Math.sin(deg2rad((pointLngLat.lon - featureLngLat.lon) / 2)), 2);

  return Math.asin(Math.sqrt(l1 + l2 * l3 * l4)) * 6378245 * 2;
}

/**
 * Проверяет что объект существует в массиве
 *
 * @param {Object} item Искомый элемент
 * @param {Array<Object>} array Массив в котором ищем
 * @return {Number} -1 если нет элемента, иначе - индекс элемента
 * @private
 */
function findObjectIndex(item, array) {
  return array.findIndex(cur => {
    let res = true;

    Object.keys(cur).forEach(key => {
      res = res && cur[key] === item[key];
    });

    return res;
  });
}

/**
 * Метод для поиска определенного компонента в иерархии Vue
 *
 * @param {VueComponent} vueComponent - vue компонент (например: this или jet.collections.active._own)
 * @param {String} findName - имя указанное в компоненте (парамметр 'name')
 * @returns {VueComponent | null}
 */
function getComponent(vueComponent, findName) {
  if (findName === vueComponent.$options.name) {
    return vueComponent;
  }

  for (const child of vueComponent.$children) {
    const res = getComponent(child, findName);

    if (res) {
      return res;
    }
  }
}

/**
 * RGBA цвет из HEXA
 *
 * @param {String} color
 * @return String
 */
function rgbaFromHexa(color) {
  let r = 0, g = 0, b = 0, a = 1;

  if (color.length === 5) {
    r = '0x' + color[1] + color[1];
    g = '0x' + color[2] + color[2];
    b = '0x' + color[3] + color[3];
    a = '0x' + color[4] + color[4];
  } else if (color.length === 9) {
    r = '0x' + color[1] + color[2];
    g = '0x' + color[3] + color[4];
    b = '0x' + color[5] + color[6];
    a = '0x' + color[7] + color[8];
  }

  a = +(a / 255).toFixed(3);

  return `rgba(${+r}, ${+g}, ${+b}, ${+a})`;
}

export {
  isEmpty,
  formatDate,
  formatDate2,
  readLocalStorage,
  saveLocalStorage,
  getMonthBegin,
  getMonthEnd,
  isMonthBegin,
  isMonthEnd,
  getCoords,
  parseId,
  uuidv4,
  setPadding,
  uniqBy,
  sin2obj,
  declOfNum,
  deg2rad,
  colorFromInt,
  pixelToMeter,
  findObjectIndex,
  getComponent,
  rgbaFromHexa,
};
