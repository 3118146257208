import {prepareSinRequest, prepareSinResponse} from '@/utils/http';

export default class DangerRouterService {
  /**
   * Получение опасных типов
   *
   * @returns {Promise<Array<Object>|*[]>}
   */
  static async allDangerTypes() {
    try {
      return prepareSinResponse(
        await prepareSinRequest(
          'core-read',
          'sin2:/v:328bbc64-369a-4562-89ca-f1a7638791b4/?filter=field(".danger")'
        )
      );
    } catch (e) {
      console.error('DangerRouterService::allDangerTypes', e);

      return [];
    }
  }

  /**
   * Получение всех объектов остановок
   *
   * @returns {Promise<Array<Object>|*[]>}
   */
  static async allStopObjects(dangerId) {
    try {
      const view = 'sin2:/v:9822c295-014e-4cfe-a68d-5018790a4c07';
      const notNullObjectTypeId = 'not(isnull(field(".objectTypeID")))';
      const notEqualDanger = `eq(field(".objectTypeID"),param("${dangerId}", "id"))`;

      const query = `${view}?filter=and(${notNullObjectTypeId},${notEqualDanger})`;

      return prepareSinResponse(
        await prepareSinRequest(
          'core-read',
          query
        )
      );
    } catch (e) {
      console.error('DangerRouterService::allStopObjects', e);

      return [];
    }
  }
}
