<script>
    const $moment = require('moment');
    import ReleaseSchedulesService from '@/services/ReleaseSchedulesService';
    import PlansService from '@/services/PlansService';

    export default {

        name: 'CloseVersionDialog',
        props: ['show', 'version'],
        data() {
            return {
                schDt: new Date().setHours(0, 0, 0, 0),
                dateMenu: false,
                canCloseTotal: true,
                showConfirm: false,
                confirmMessage: null,
                showInfo: false,
                trips: null
            };
        },
        methods: {
            async checkBeforeClose() {
                this.canCloseTotal = true;
                this.confirmMessage = null;
                this.showConfirm = false;
                
                if ($moment(this.schDt).isBefore($moment(this.version.vcreleaseschedulesVerstart))) {
                    this.confirmMessage = 'Дата окончания НЕ должна быть меньше даты начала';
                    this.showConfirm = true;
                    this.canCloseTotal = false;
                    return;
                }
                
                let preData = await ReleaseSchedulesService.checkBeforeApprove(this.version.vcreleaseschedulesId, $moment(this.schDt).format('DD.MM.YYYY'), 1);
                let trips = JSON.parse(preData[0].jsonobject.value);
                if (!!trips[0].trips || !!trips[0].futuretrips) {
                    this.confirmMessage = 'По данному расписанию были выполнены постановки в будущем периоде, которые будут УДАЛЕНЫ';
                    this.showConfirm = true;
                    this.trips = trips[0];
                    return;
                }
                this.closeVersion();
            },
            
            closeVersion() {
                if (!!this.trips) {
                    if (!!this.trips.trips) {
                        var trips = this.trips.trips.split(",");
                        trips.forEach(x => {
                            PlansService.deleteTrips(x, new Date(this.schDt).getTime());
                        });
                    }
                    if (!!this.trips.futuretrips) {
                        var futuretrips = this.trips.futuretrips.split(",");
                        futuretrips.forEach(x => {
                            PlansService.deleteFutureTrips(x, new Date(this.schDt).getTime());
                        });
                    }
                }
                
                var params = [
                    {
                        id: 'id',
                        type: 'id',
                        value: this.version.vcreleaseschedulesId,
                    }, {
                        id: 'verEnd',
                        type: 'integer',
                        value: new Date(this.schDt).getTime(),
                    }, {
                        id: 'verstatus',
                        type: 'id',
                        value: 'dfbc6fca-4a65-4a55-9a36-fe69b2703cf3',
                    }
                ];
                
                ReleaseSchedulesService.updateReleaseSchedule(params)
                    .then(result => {
                        this.$emit('closeVersion', this.version.vcreleaseschedulesId);
                    })
                    .finally(() => {
                        this.showConfirm = false;
                        this.showInfo = false;
                        this.$emit('close');
                    });

            }   //closeVersion
        },
        
        render(h) {
            return h('div', [
                h('v-dialog', {props: {
                        width: "640px",
                        value: this.show
                    }}, [
                    h('v-card', {class: {"sch-fix": true}}, [
                        h('v-card-title', 'Прекратить действие версии расписания'),
                        h('v-card-text', [
                            !!this.version
                            ? h('div', [
                                h('h3', this.version.sRoutecode + '. ' + this.version.sRoutename),
                                h('div', {class: {'mt-3': true, 'mb-3': true}},
                                        'версия № ' + this.version.vcreleaseschedulesVersion +
                                        (!!this.version.vcreleaseschedulesVerstart ? ' от ' + $moment(this.version.vcreleaseschedulesVerstart).format('DD.MM.YYYY') : '') +
                                        ': ' + this.version.vcreleaseschedulesVerstatusName),
                                h('v-menu', {
                                    props: {"max-width": "290px", "min-width": "290px", value: this.dateMenu},
                                    scopedSlots: {
                                        activator: ({on, attrs}) => {
                                            on["blur"] = (e) => {
                                                console.log("blur:", e);
                                            };
                                            return h('v-text-field', {
                                                props: {
                                                    label: "Дата окончания действия версии расписания",
                                                    "append-icon": "mdi-calendar",
                                                    value: $utils.formatDate(this.schDt,'DD.MM.yyyy'),
                                                    "full-width": false
                                                },
                                                style: {"max-width": "12rem"},
                                                on: on
                                            });
                                        }
                                    }
                                }, [
                                    h('v-date-picker', {props: {
                                            value: $utils.formatDate(this.schDt,'YYYY-MM-DD'),
                                            "no-title": true, locale: "ru-RU",
                                            "weekday-format": this.dayOfWeekToFormat,
                                            "first-day-of-week": "1",
                                            scrollable: true
                                        }, on: {
                                            input: (schDt) => {
                                                if (!schDt) {
                                                    return;
                                                }
                                                const [y, m, d] = schDt.split('-');
                                                this.schDt = new Date(y, m - 1, d);
                                                this.dateMenu = false;
                                            }
                                        }})
                                ])
                            ])
                            : null
                        ]),
                        h('v-card-actions', [
                            h('v-spacer'),
                            h('v-btn', {props: {outlined: true, tile: true, color: 'gray', small: true},
                                on: {click: () => {
                                        this.$emit('close');
                                    }
                                }
                            }, 'отмена'),
                            h('v-btn', {props: {outlined: true, tile: true, color: 'primary', small: true},
                                    on: {click: () => {
                                        this.showInfo = true;
                                    }
                                }
                            }, 'да')
                        ])
                    ])
                ]),
                h('v-dialog', {props: {
                    width: "500px",
                    value: this.showInfo
                }}, [
                    h('v-card', {class: {"sch-fix": true}}, [
                        h('v-card-title', 'Прекратить действие версии расписания'),
                        h('v-card-text', 'Вы действительно хотите прекратить действие версии расписания?'),
                        h('v-card-actions', [
                            h('v-spacer'),
                            h('v-btn', {props: {outlined: true, tile: true, color: 'gray', small: true},
                                on: {click: () => {
                                        this.showInfo = false;
                                        this.$emit('close');
                                    }
                                }
                            }, 'отмена'),
                            h('v-btn', {props: {outlined: true, tile: true, color: 'primary', small: true},
                                    on: {click: this.checkBeforeClose}
                            }, 'да')
                        ])
                    ])
                ]),
                h('v-dialog', {props: {
                        width: "500px",
                        value: this.showConfirm
                    }}, [
                        h('v-card', {class: {"sch-fix": true}}, [
                            h('v-card-title', 'Прекратить действие версии расписания'),
                            h('v-card-text', this.confirmMessage),
                            h('v-card-actions', [
                                h('v-spacer'),
                                h('v-btn', {props: {outlined: true, tile: true, color: 'gray', small: true},
                                    on: {click: () => {
                                            this.showConfirm = false;
                                            this.showInfo = false;
                                            this.$emit('close');
                                        }
                                    }
                                }, 'отмена'),
                                this.canCloseTotal
                                    ? h('v-btn', {props: {outlined: true, tile: true, color: 'primary', small: true},
                                        on: {click: this.closeVersion}
                                    }, 'да')
                                    : null
                            ])
                        ])
                    ])
            ]);
        }
    }

</script>