<template>
    <v-card class="jet-conte jet-no-details">
        <v-toolbar dense flat width="100%">
        </v-toolbar>
        <v-data-table v-if="loaded" class="fill-height jet-table"
            v-model="selected"
            :headers="headers"
            :items="items"
            fixed-header
            item-key="name"
            single-select
            disable-pagination
            hide-default-footer
            :items-per-page=1000>
        </v-data-table>
    </v-card>
</template>

<script>
export default {
    name: 'JetTableReport',
    props: {
        collection: {
            type: Object,
            default: null
        }
    },
    data() {
        return {
            headers: [],
            items: [],
            selected: null
        }
    },
    computed: {
        loaded() {
            this.refresh();
            return true;
        }
    },
    methods: {
        refresh() {
            var rep  = this.collection.report,
                data = this.collection.data;
            if ( !rep ) {
                return;
            }
            const columns = rep.description.columns;
            columns.map((c) => {
                this.headers.push({text: c.title, value: c.name});
            })
            Object.keys(data.rows).map((i) => {
                var r = data.rows[i];
                var item = {id: $utils.uuidv4()};
                this.headers.map((h) => {
                    item[h.value] = r[this.headers.indexOf(h)];
                })
                this.items.push(item);
            });
        }
    }
}
</script>
