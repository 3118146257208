const _mapBoxSdkClient = require('@mapbox/mapbox-sdk');
const _directionsService = require('@mapbox/mapbox-sdk/services/directions');

const _baseClient = _mapBoxSdkClient({
  // TODO: сменить этот токен
  accessToken: 'pk.eyJ1Ijoid2lyaWdodCIsImEiOiJjazg1dHlpOXEwMGJmM2dxaThtaGxlY3ZwIn0.sysHXju-B1TqiH0LwPRRNg'
});

const _directionsClient = _directionsService(_baseClient);

/**
 * Сервис для работы с направлениями
 */
export default class MapDirectionsService {
  /**
   * Получение маршрута между точками
   *
   * @param {Array<Object>} waypoints
   * @returns {Promise} Массив координат
   */
  static getDirection(waypoints) {
    return _directionsClient
      .getDirections({
        profile: 'driving-traffic',
        geometries: 'geojson',
        steps: false,
        alternatives: false,
        waypoints: waypoints,
      })
      .send()
      .then(data => {
        const routes = data?.body?.routes || [];
        const route = routes?.[0] || {};

        // Сразу вернем массив координат
        return {
          coords: route?.geometry?.coordinates || [],
          start: waypoints[0].id,
          end: waypoints[1].id,
        };
      })
      .catch(err => console.error('MapDirectionsService::getDirection', err));
  }
}
