<template>
  <div>
    <v-btn @click="addCarrier" color="primary">Добавить перевозчика</v-btn>

    <JetDialog
      v-model="dialog"
      title="Отправка инцидента"
      width="800"
      apply-button-text="Добавить перевозчиков"
      @on-apply-click="send"
      @on-cancel-click="dialog = false; selectedCarriers = []"
      :loading-apply-button="loadingCreate"
    >
      <div>
        <v-text-field
          v-model="searchText"
          label="Поиск"
          hide-details
          class="mr-4"
        ></v-text-field>
      </div>

      <v-data-table
        v-model="selectedCarriers"
        :items="carriersList"
        :loading="loadingCarriers"
        :headers="headerSelected"
        show-select
        item-key="carrierId"
      ></v-data-table>
    </JetDialog>
  </div>
</template>

<script>
import {prepareSinRequest, prepareSinResponse} from "@/utils/http";
import OtherService from "@/services/OtherService";
import JetDialog from "@/components/JetDialog";

export default {
  name: "AddIncidentReceiver",
  components: {JetDialog},
  props: {
    incidentId: {
      type: String,
      required: true,
    }
  },
  data: () => ({
    dialog: false,
    searchText: '',
    carriers: [],
    loadingCarriers: true,
    selectedCarriers: [],
    loadingCreate: false,
    headerSelected: [
      {text: 'Перевозчик', value: 'shortName'},
    ],
  }),
  computed: {
    carriersList() {
      const regexp = new RegExp(this.searchText, 'i');
      return this.carriers.filter(it => regexp.test(it.shortName));
    }
  },
  methods: {
    addCarrier() {
      this.dialog = true;
      this.loadCarriers();
    },

    // Загрузка списка перевозчиков
    async loadCarriers() {
      this.loadingCarriers = true;
      Promise.all([
        OtherService.getCarriersHierarchyByRole(),
        OtherService.getStatusIncident(this.incidentId),
      ]).then(res => {
        const allCarrier = res[0];
        const receivers = res[1];
        this.carriers = allCarrier.filter(carrier => {
          let i = -1;
          for (const receiver of receivers) {
            i++;
            if (receiver.tenantId === carrier.tenantId) {
              receivers.splice(i, 1);
              return false;
            }
          }
          return true;
        });
      });
      this.loadingCarriers = false;
    },

    async send() {
      this.loadingCreate = true;
      const status = await this.sendIncidentToCarriers(this.incidentId);
      if (status) {
        await this.scream(this.incidentId);
      }
      this.loadingCreate = false;
      this.dialog = false;
      this.$emit('refreshCarriers');
    },

    // Отправка инцидента перевозчикам
    async sendIncidentToCarriers(incidentId) {
      let tenantsID = '{'
      this.selectedCarriers.forEach(cr => {
        tenantsID += `"${cr.tenantId}",`
      });
      tenantsID = tenantsID.substring(0, tenantsID.length - 1) + '}';
      const res = await prepareSinRequest(
        'query',
        'e9d62d7e-cdf8-4250-a60a-04130c2ad10e.addIncidentReciever',
        '',
        [],
        {
          in_userID: this.$store.state.auth.subject.id,
          in_incidentID: incidentId,
          in_tenantIDs: tenantsID,
        },
        {
          cache: false,
        },
      );
      if (res.error) {
        return this.error('Ошибка при отправке инцидента перевозчикам');
      }
      return true;
    },

    // Команда для рассылки инцидента
    async scream(incidentId) {
      const scream = await jet.http.post(`/publicApi?call=eventincident&arg.incidentid=${incidentId}`, {});
      if (scream.error || !scream.res) {
        return this.error('Ошибка при отправке инцидента перевозчикам');
      }
      return true;
    },

    // Сообщение об ошибке
    error(text) {
      jet.msg({
        color: 'error',
        text,
      });
      return false;
    },
  },
}
</script>
