var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-list-item',{key:_vm.vehicle.id,staticClass:"vehicle",style:(_vm.cardStyle),on:{"click":_vm.onClick}},[_c('v-list-item-action',{staticClass:"mr-0",on:{"click":function($event){$event.stopPropagation();return _vm.onTrackingClick.apply(null, arguments)}}},[_c('v-icon',[_vm._v(" mdi-checkbox-"+_vm._s(_vm.checked ? 'marked' : 'blank')+"-outline ")])],1),_c('v-list-item-content',{staticClass:"ml-2"},[_c('v-list-item-title',[(_vm.status)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-badge',_vm._g(_vm._b({staticClass:"mt-0",attrs:{"dot":"","bottom":"","overlap":"","color":_vm.stateColors[_vm.stateColor]}},'v-badge',attrs,false),on),[_c('fa-icon',_vm._g(_vm._b({staticClass:"mr-1 status_icon",staticStyle:{"display":"inline-block"},attrs:{"icon":_vm.status.icon}},'fa-icon',attrs,false),on))],1)]}}],null,false,3306840374)},[_c('span',[_vm._v(_vm._s(_vm.status.content)+", "+_vm._s(_vm.stateName))])]):_vm._e(),_vm._v(" "+_vm._s(_vm.vehicle.govnum && _vm.vehicle.govnum.toUpperCase() || 'Нет номера')+" ")],1),(_vm.vehicle.rent)?_c('v-list-item-subtitle',{staticClass:"caption"},[_vm._v(" Аренда, "+_vm._s(_vm.vehicle.rent.subcarrieridOrgidShortname)+" ")]):_vm._e()],1),_c('v-list-item-action',{staticClass:"mx-1"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","x-small":"","icon":"","color":_vm.checked ? 'primary' : 'gray lighten-5'},on:{"click":function($event){$event.stopPropagation();return _vm.onTrackingClick.apply(null, arguments)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-eye")])],1)]}}])},[_c('span',[_vm._v("Отслеживать")])])],1),_c('v-list-item-action',{staticClass:"mr-1"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","x-small":"","icon":"","loading":_vm.showTrackLoading,"color":_vm.showTrack ? 'primary' : 'gray lighten-5'},on:{"click":function($event){$event.stopPropagation();return _vm.onShowTrackClick.apply(null, arguments)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-map")])],1)]}}])},[_c('span',[_vm._v("Показать трек")])])],1),_c('v-list-item-action',[_c('v-menu',{attrs:{"offset-x":"","transition":"slide-x-transition","disabled":_vm.vehicle.blockStatus},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"fab":"","x-small":"","icon":"","color":"gray lighten-5"}},on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}]),model:{value:(_vm.showContextMenu),callback:function ($$v) {_vm.showContextMenu=$$v},expression:"showContextMenu"}},[_c('v-list',[_c('v-list-item',{on:{"click":_vm.onTrackYesterday}},[_vm._v(" Загрузить трек за вчера ")]),_c('v-list-item',{on:{"click":_vm.showPeriod}},[_vm._v(" Загрузить трек за период ")]),_c('v-list-item',{on:{"click":_vm.onRunReport}},[_vm._v(" Отчет о пробеге за сегодня ")]),_c('v-list-item',{on:{"click":_vm.onViolationSpeedReport}},[_vm._v(" Отчет о нарушениях скоростного режима ")]),_c('v-list-item',{on:{"click":function($event){_vm.showTrackSettings = true;}}},[_vm._v(" Настройки отображения ")])],1)],1)],1),_c('v-menu',{attrs:{"nudge-right":"410","nudge-bottom":"190","close-on-content-click":false},model:{value:(_vm.showTrackSettings),callback:function ($$v) {_vm.showTrackSettings=$$v},expression:"showTrackSettings"}},[_c('MapSettings',{attrs:{"value":_vm.trackSettings,"dialog":_vm.showTrackSettings},on:{"input":_vm.onTrackSettingsChanged,"close":function($event){_vm.showTrackSettings = false}}})],1),_c('vc-choose-period',{ref:"period",on:{"period":function($event){return _vm.trackByPeriod($event)}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }