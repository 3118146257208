<template>
  <JetDialog
    title="Отчёт"
    v-model="show"
  >

    <template v-slot:default>

      <!-- Наименование -->
      <v-text-field
        v-if="!isCompleted"
        label="Наименование"
        v-model="newTitle"
        color="grey"
        hide-details
      ></v-text-field>
      <div v-else>
        Наименование:
        <span class="black--text">{{ report.title }}</span>
      </div>

      <!-- Перевозчик -->
      <v-select
        v-if="!isCompleted"
        label="Перевозчик"
        v-model="newCarrier"
        :items="carriers"
        :loading="loadingCarriers"
        item-text="shortName"
        item-value="carrierId"
        color="grey"
      ></v-select>
      <div v-else>
        Перевозчик:
        <span class="black--text">{{ report.shortName }}</span>
      </div>

      <!-- Дата -->
      <div>
        Дата:
        <span v-if="report.date" class="black--text">{{ report.date }}</span>
        <span v-else class="grey--text">Отсутствует</span>
      </div>

      <!-- Файл -->
      <div>
        Файл:
        <v-btn
          v-if="report.file"
          class="pa-0"
          text
          style="text-transform: initial"
          @click="openFile(report.file.ref)"
        >
          <u>{{ file && file.name || report.file.name || 'Файл' }}</u>
        </v-btn>
        <span v-else>
        отсутствует
      </span>
        <v-btn v-if="!isCompleted" small class="ml-2" color="primary" outlined @click="onButtonClick">
          Изменить
        </v-btn>
        <input
          ref="uploader"
          class="d-none"
          type="file"
          accept="image/*"
          @change="onFileChanged"
        >
      </div>

      <!-- Комментарий -->
      <v-textarea
        v-if="!isCompleted && isModerator"
        v-model="newComment"
        label="Комментарий"
        auto-grow
        rows="2"
        row-height="42"
        outlined
        hide-details
        color="grey"
      ></v-textarea>
      <div v-else-if="report.comment">
        Комментарий:
        <span class="black--text">{{ report.comment }}</span>
      </div>
    </template>

    <template v-slot:actions>
      <template v-if="!isCompleted">
        <v-btn @click="show = false" :loading="loadingBtn" outlined color="red">Отмена</v-btn>
        <template v-if="isModerator">
          <v-btn @click="edit" color="primary" :loading="loadingBtn" :disabled="disabledSend" outlined>Сохранить</v-btn>
        </template>
        <v-spacer/>
        <template v-if="isModerator">
          <v-btn @click="confirm(false)" color="primary" :disabled="!isComment" :loading="loadingBtn">Отклонить</v-btn>
          <v-btn @click="confirm(true)" color="primary" :loading="loadingBtn">Принять</v-btn>
        </template>
        <template v-else>
          <v-btn @click="edit" color="primary" :loading="loadingBtn" :disabled="disabledSend">Сохранить</v-btn>
        </template>
      </template>
      <template v-else>
        <v-spacer/>
        <v-btn @click="show = false" outlined color="red">Закрыть</v-btn>
        <v-spacer/>
      </template>
    </template>


  </JetDialog>
</template>

<script>
// Components
import JetDialog from "@/components/JetDialog";

// Service
import OtherService from "@/services/OtherService";

// Mixins
import SendFileMixin from "@/components/dev/mixin/SendFileMixin";

export default {
  name: "ShowReport",
  components: {
    JetDialog,
  },
  mixins: [
    SendFileMixin,
  ],
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    report: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    isModerator: false,
    isSelecting: false,
    comment: '',
    carriers: [],
    loadingCarriers: true,
    newTitle: '',
    newCarrier: null,
    newComment: '',
    loadingBtn: false,
  }),
  created() {
    this.isModerator = !!this.$store.state.profile.subject.roles[OtherService.MODERATOR_ROLE_GUID];
    this.init();
    this.newTitle = this.report.title || '';
    this.newComment = this.report.comment || '';
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set() {
        this.$emit('input', false);
      },
    },
    isCompleted() {
      return this.report.status === 2;
    },
    disabledSend() {
      return !this.newTitle.replace(/\s/g, '');
    },
    isComment() {
      return this.newComment && this.newComment.replace(/\s/g, '') || false;
    },
  },
  methods: {
    // Инициализация
    async init() {
      this.loadingCarriers = true;
      const reportCarrier = {
        carrierId: this.report.carrierId,
        shortName: this.report.shortName,
      };
      if (!this.isCompleted) {
        this.carriers = await OtherService.getCarriers();
        const findCarrier = this.carriers.find(carr => carr.carrierId === this.report.carrierId);
        if (findCarrier) {
          this.newCarrier = findCarrier;
        } else {
          this.carriers.push(reportCarrier);
          this.newCarrier = reportCarrier;
        }
      } else {
        this.newCarrier = reportCarrier;
      }
      this.loadingCarriers = false;
    },

    // Нажатие на кнопку Изменить (Файл)
    onButtonClick() {
      this.isSelecting = true
      window.addEventListener('focus', () => {
        this.isSelecting = false
      }, {once: true})

      this.$refs.uploader.click()
    },

    // Событие при выборе файла (Получает файл)
    onFileChanged(e) {
      const file = e.target.files[0];
      this.convertFile(file);
    },

    // Изменение отчёта
    async edit() {
      this.loadingBtn = true;
      const carrierId = this.newCarrier.carrierId || this.newCarrier;
      const params = {
        id: this.report.id,
        title: this.newTitle,
        carrierId: carrierId,
        file: this.toParams(),
      };
      if (this.report.status === 1) {
        params.status = 0;
      }
      await OtherService.editCarrierReport(params);
      setTimeout(() => {
        this.$emit('refresh');
        this.loadingBtn = false;
      }, 1000);
    },

    // Подтверждение или отклонение отчёта
    async confirm(confirm) {
      this.loadingBtn = true;
      const params = {
        id: this.report.id,
        status: (confirm ? 2 : 1),
        comment: confirm ? '' : this.newComment,
      };
      await OtherService.editCarrierReport(params);
      setTimeout(() => {
        this.$emit('refresh');
        this.loadingBtn = false;
      }, 1000);
    },
  },
}
</script>
