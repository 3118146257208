import {prepareSinResponse, prepareSinRequest} from '@/utils/http';
import {uuidv4} from '@/utils/utils';

export default {
  /**
   * Получение списка городов
   *
   * @return {Promise<{
   *    areaName: *,
   *    name: *,
   *    id: *
   * }[]|*[]>}
   */
  async getCities() {
    try {
      return prepareSinResponse(
        await prepareSinRequest(
          'core-read',
          'sin2:/v:5edcd75a-013e-43ca-ad35-9e0cfce9128a',
          'towns',
          ['areaname', 'townname']
        ),
        {
          keyReplace: {
            areaname: 'areaName',
            townname: 'name',
          },
          hideUnderscores: true,
        }
      );
    } catch (e) {
      console.error('CreateBusStopService::getCities', e);

      return [];
    }
  },

  /**
   * Получение списка временных зон
   *
   * @returns {Promise<Array<Object>|*[]>}
   */
  async getTimeZones() {
    try {
      return prepareSinResponse(
        await prepareSinRequest(
          'core-read',
          'sin2:/v:8ad56cef-d7eb-4c80-98a9-8b70072d5880'
        )
      );
    } catch (e) {
      console.error('CreateBusStopService::getTimeZones', e);

      return [];
    }
  },

  /**
   * Получение списка остановок
   *
   * @returns {Promise<{
   *    name: *,
   *    id: *
   * }[]|*[]>}
   */
  async getStopTypes() {
    try {
      return prepareSinResponse(
        await prepareSinRequest(
          'core-read',
          'sin2:/v:328bbc64-369a-4562-89ca-f1a7638791b4/?filter=field(".isstop")',
          'vcobjecttypes',
          ['name',]
        )
      );
    } catch (e) {
      console.error('CreateBusStopService::getStopTypes', e);

      return [];
    }
  },

  /**
   * Создание/редактирование остановки
   *
   * @param {Object} busStop Информация об остановке
   * @returns {Promise<*>}
   */
  async createOrUpdateBusStop(busStop) {
    try {
      const id = busStop.id || uuidv4();

      const params = [
        {id: 'id', type: 'id', value: id},
        {id: 'typeid', type: 'id', value: 'bd78259f-7b64-ad44-e040-007f02002b0e'},
        {id: 'twnid', type: 'id', value: busStop.twnid},
        {id: 'locname', type: 'string', value: busStop.locname},
        {id: 'code', type: 'number', value: busStop.code},
        {id: 'latitude', type: 'number', value: busStop.latitude},
        {id: 'longitude', type: 'number', value: busStop.longitude},
        {id: 'startdt', type: 'date', value: new Date(busStop.startdt).getTime()},
        {id: 'enddt', type: 'date', value: new Date(busStop.enddt).getTime()},
        {id: 'timezoneid', type: 'id', value: busStop.timezoneid},
        {id: 'objecttypeid', type: 'id', value: busStop.objecttypeid},
        {id: 'description', type: 'string', value: busStop.description},
        {id: 'radius', type: 'number', value: 100},
      ];

      await jet.http.post('/rpc?d=jsonRpc', {
        type: busStop.id === null ? 'core-create' : 'core-update',
        query: 'sin2:/v:f775655f-9081-4980-99f2-b0b8a69b6344',
        params: params,
      });

      return {
        id: id,
        typeid: 'bd78259f-7b64-ad44-e040-007f02002b0e',
        twnid: busStop.twnid,
        locname: busStop.locname,
        code: busStop.code,
        latitude: busStop.latitude,
        longitude: busStop.longitude,
        startdt: new Date(busStop.startdt),
        enddt: new Date(busStop.enddt),
        timezoneid: busStop.timezoneid,
        objecttypeid: busStop.objecttypeid,
        description: busStop.description,
        radius: 100,
      };
    } catch (e) {
      console.error('CreateBusStopService::createBusStop', e);

      return false;
    }
  },
  /**
   * Функция для получения следующего кода остановки
   */
  async getNextLocationCode() {
    const routes = [];
    const request = {
      type: 'query',
      query: '4431268d-b082-4beb-8b4a-2d6f6a47464e.calcMaxCode',
    };

    const response = await jet.http.post('/rpc/?d=jsonRpc', request);
    if (!response.error) {
      const data = response.result.data;
      for (const row of data) {
        return row[0];
      }
    }
    return null;
  },
}
