/**
 * Класс Report
 */
class Report {
  /**
   * @constructor
   * @param {Object} report
   */
  constructor(report) {
    this._report = report;
  }
  
  get id(){
      return this._report.id;
  }

  /**
   * описание отчёта
   */
  get description() {
    return this._report.reportDescription;
  }

  /**
   * название отчёта
   */
  get name() {
    return this.description.title;
  }
  
  get mime(){
      return ((!!this.description.formats) && (this.description.formats.length>0))
                ? this.description.formats[0].mime
                : 'application/octet-stream';
  }
  
/**
 * Параметры отчета
 * @returns {Array|Report.get params._report.reportDescription.mainQuery.arguments}
 */
  get params(){
        var _params = [],
            mq = null;
        if ((!!this._report) && (!!this._report.reportDescription)){
            mq = this._report.reportDescription.mainQuery;
            if (!!mq){
                _params = mq.arguments;
                //normalize param`s to using
                _params.map((p)=>{
                    p.type = p.metaType;
                    p.label = p.title;
                    if (('id'===p.type) && p.hasOwnProperty('editor')){
                        var n = p.editor.indexOf('uri=');
                        p.uri = unescape(p.editor.substr(n + 4));
                    }
                });
            } else {
                _params = this._report.reportDescription.arguments;
                _params.map((p)=>{
                  if (!p.type) {
                    p.type = p.metaType;
                  }
                  var type = p.type.toLowerCase();
                  var dfe  = p.defaultExpression;
                  p.type = p.editor || ((type.lastIndexOf('.') > 0) ? type.substr(type.lastIndexOf('.')+1) : type);
                  p.metaType = p.type;
                  p.uri = (p.attributes) ? p.attributes.uri : null;
                  p.label = p.title;
                });
            }
        }
        
        return _params;
  }
}

export default Report;
