<template>
  <div class="grey lighten-3">
    <v-data-table
      :headers="headersInner"
      :items="items"
      show-select
      item-key="index"
      disable-pagination
      hide-default-footer
      v-model="selected"
      class="grey lighten-3 mb-2"
      @current-items="current = $event"
      @item-selected="bulkSelect"
    >

      <template v-slot:[`item.${fieldName}`]="{ item }">
        <v-badge
          dot
          inline
          left
          :color="getColorByViolation(item[fieldName])">
          {{ item[fieldName] }}
        </v-badge>
      </template>

      <template v-slot:[`item.${fieldDate}`]="{ item }">
        {{ formatDate(item[fieldDate]) }}
      </template>
        <template v-slot:item.btnComment="{ item }">
                <v-icon small
                        @click="showAddComment(item)">
                    mdi-pencil
                </v-icon>
            </template>

      <template v-slot:item.action="{ item }">
        <v-icon
          @click="$emit('onMap', item)"
          title="На карте"
        >
            mdi-map-marker-alert-outline
        </v-icon>
      </template>
    </v-data-table>

    <v-layout class="pr-2 pb-2">
      <v-spacer/>
      <AddComment ref="addComm"
        :selected="selected"
        @onApply="apply"
      ></AddComment>
    </v-layout>
  </div>
</template>

<script>
//Components
import AddComment from './AddComment';

//Utils
import {formatDate, FORMAT_FOR_USER} from '@/services/JetDate';

const $moment = require('moment');

export default {
  props: {
    // Список полей шапки таблицы нарушений
    headersInner: {
      type: Array,
    },
    // Список нарушений в группе Маршрут/ТС
    items: {
      type: Array,
    },
  },
  components: {AddComment},
  name: 'VehicleViolations',
  data: () => ({
    // Флаг нажатой кравиши Shift
    shiftKeyOn: false,
    current: [],
    // Выбранные элементы таблицы
    selected: [],
    // Ключ поля, отвечающего за тип нарушения
    fieldName: 'vctripscontrol.typename',
    // Ключ поля, отвечающего за дату нарушения
    fieldDate: 'vctripscontrol.eventdt',
  }),
  created() {
    // Навешиватся собыия клика
    this.keyDownHandler = ({key}) => {
      if (key == 'Shift') this.shiftKeyOn = true;
    };

    this.keyUpHandler = ({key}) => {
      if (key == 'Shift') this.shiftKeyOn = false;
    };

    window.addEventListener('keydown', this.keyDownHandler);
    window.addEventListener('keyup', this.keyUpHandler);
  },
  // Убираются события клика при дестрое компонента
  beforeDestroy() {
    window.removeEventListener('keydown', this.keyDownHandler);
    window.removeEventListener('keyup', this.keyUpHandler);
  },
  methods: {
    // Функция, прокидывающая событие комментирования нарушений
    apply(items) {
      this.selected = [];
      this.$emit('onApply', items);
    },
    // Получение цвета нарушения
    getColorByViolation(type) {
      let color = 'red';

      switch (type) {
        case 'Без движения':
          color = 'purple';
          break;
        case 'Время':
          color = 'red';
          break;
        case 'Нет данных':
          color = 'grey';
          break;
        case 'Нет датчика':
          color = 'grey darken-2';
          break;
        case 'Расстояние':
          color = 'yellow';
          break;
        case 'Ручной ввод':
          color = 'teal accent-3';
          break;
        case 'ТС нет в договоре':
          color = 'orange darken-2';
          break;
      }

      return color;
    },
    // Функция выделения нескольких нарушений через Shift
    bulkSelect({item: b, value}) {
      const {selected, current, shiftKeyOn} = this;

      if (selected.length == 1 && value == true && shiftKeyOn) {
        const [a] = selected;
        let start = current.findIndex((item) => item == a);
        let end = current.findIndex((item) => item == b);

        if (start - end > 0) {
          let temp = start;
          start = end;
          end = temp;
        }

        for (let i = start; i <= end; i++) {
          selected.push(current[i]);
        }
      }
    },
    formatDate(date) {
      return $moment(date).format('DD.MM.YYYY HH:mm');
    },
    showAddComment(item) {
      this.$refs['addComm'].init(item);
    }
  },
};
</script>

