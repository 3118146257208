<template>
  <JetDialog
    title="Загрузка Excel файла"
    width="400"
    v-model="show"
  >
    <template v-slot:default>
      <div>
        Перейдите по
        <a target="_blank" href="https://220fz.rosavtotransport.ru/assets/search">ссылке</a>
        и скачайте файл реестра, после загрузите его
      </div>

      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-file-input
            clearable hide-details
            placeholder="Выбрать файл"
            v-on="on"
            v-bind="attrs"
            :loading="fileUploading"
            :disabled="fileUploading"
            @change="onFileChanged"
          ></v-file-input>
        </template>

        Загрузка Excel файла
      </v-tooltip>
    </template>

    <template v-slot:actions>
      <v-spacer/>

      <v-btn
        outlined
        color="primary"
        @click="closeDialog"
      >
        Закрыть
      </v-btn>
    </template>
  </JetDialog>
</template>

<script>
// Компоненты
import JetDialog from '@/components/JetDialog';

export default {
  components: {
    JetDialog,
  },
  name: 'InterCarrier',
  data: () => ({
    // Ставим флаг чтобы сразу показать диалог
    show: true,
    // Флаг что файл отправляется
    fileUploading: false,
  }),
  methods: {
    // Закрытие диалога
    closeDialog() {
      this.show = false;
      this.fileUploading = false;
    },
    // При измении выбранного файла
    onFileChanged(file) {
      if (file !== undefined) {
        const fd = new FormData();
        fd.append('file', file, 'inter_carrier_routes.xlsx');

        this.fileUploading = true;

        fetch('/fileParser?parser=inter_carrier', {
          method: 'POST',
          credentials: 'include',
          body: fd,
        })
          .then(response => response.json())
          .then(data => {
            if (data.data.inserted !== undefined) {
              jet.msg({
                text: `Успешно добавлено ${data.data.inserted} записей!`,
                color: 'primary',
              });

              this.show = false;
            }
          })
          .catch(err => {
            console.error('InterCarrier::Upload excel file', err);

            jet.msg({
              text: 'Произошла ошибка на сервере',
              color: 'error',
            });
          })
          .finally(() => {
            this.fileUploading = false;
            this.$attrs.collection.refresh();
          });
      }
    }
  },
  computed: {
    // Возвращает фильтрованные данные, либо полный список
    // TODO: удалить после того, как этот функционал будет реализован в другом месте
    list() {
      if (this.filter !== '' && this.filter !== null) {
        const re = new RegExp(this.filter, 'i');

        return this.items.filter(it => {
          return re.test(it.name) // Наименование первозчика
            || re.test(it.routecode) // Номер маршрута
            || re.test(it.routename); // Наименование маршрута
        });
      }

      return this.items;
    },
  },
};
</script>

<style scoped>

</style>
