<script>
/* eslint-disable */

import { formatDate } from '@/utils/utils';
import OtherService from "@/services/OtherService";

var WS_URI = {
    servers: process.env.VUE_APP_BACKEND_NATS_SERVER,
    user: process.env.VUE_APP_BACKEND_NATS_USERNAME,
    pass: process.env.VUE_APP_BACKEND_NATS_PASSWORD
};

const SOURCE_ROUTE_ID = 'source-route';
const SOURCE_STOPS_ID = 'source-stops';
const SOURCE_VEHICLE_ID = 'source-vehicle';
const SOURCE_TRACK_ID = 'source-track';
const SOURCE_FAILS_ID = 'source-fails';

const $moment = require('moment');

var ws_codec = null,
  ws_nats = null,
  ws_ids = [],
  ws_tc = null,
  ws_alarm = null,
  FEATURES = [];

function get_the_time(t, only) {
  const fmt = (!!only) ? 'HH:mm' : 'dd.MM.yyyy HH:mm';

  return !!t ? formatDate(t, fmt) : '-';
}

function _dsp_get_fail_type(rawId) {
  switch (rawId) {
    case 1:
    case 'noNaviDevice':
      return 'Нет датчика';
    case 2:
    case 'noNaviData':
      return 'Нет данных';
    case 4:
    case 'noMovement':
      return 'Нет движения';
    case 8:
    case 'failedArrival':
    case 'failedDeparture':
      return 'Отклонение от расписания';
    case 16:
    case 'routeExit':
      return 'Отклонение от трассы';
    case 32:
      return 'Нарушение скорости';
    case 'noVehicleContract':
      return 'Нет контракта';
    case 'tripState':
      return 'Рейс завершен';
    case 'successArrival':
      return 'Отправление';
    case 'successDeparture':
      return 'Прибытие';
    case 'manual':
      return 'Ручной ввод';
    default:
      return 'unknown';
  }
}

const get_vc_status = function(time) {
  const type = typeof time;
  const msHour = 60 * 60 * 100;
  const msDate = Date.now();
  const msDiff = msDate - time;

  if (type !== 'number') {
    return null;
  }

  if (msDiff <= 2 * msHour) {
    return 0;
  } else if (msDiff > 2 * msHour && msDiff <= 24 * msHour) {
    return 24;
  } else if (msDiff > 24 * msHour && msDiff <= 72 * msHour) {
    return 72;
  } else {
    return 100;
  }
};

const DspOnlineControl = {
  name: 'DspOnlineControl',
  data: () => ({
    show: false,
    lastEvtTime: null,
    events: [],
    sid: false,
    bellNode: null,
  }),
  async created() {
    this.lastEvtTime = new Date();
    this.bellNode = document.querySelector('.dsp-num-evts');

    if (this.bellNode) {
      this.bellNode.style.opacity = 0;
    }

    ws_codec = await jet.http.getJSONCodec();
    ws_nats = await jet.http.getNats(WS_URI);
    
    if (ws_nats) {
        ws_tc = ws_nats.subscribe('PUBLIC.kigat.tripsControl');
        (async ()=>{
          for await (const m of ws_tc) {
            try{
              var msg = ws_codec.decode(m.data);
              this.onNatsMessageControl.bind(msg)
            } catch(e){
              console.log('ERR (on_m_control):', e);
            }
          }
        })();

        ws_alarm = ws_nats.subscribe('PUBLIC.kigat.alarm');
        (async ()=>{
          for await (const m of ws_alarm) {
            try{
              var msg = ws_codec.decode(m.data);
              this.onNatsMessageAlarm.bind(msg);
            } catch(e){
              console.log('ERR (on_alarm):', e);
            }
          }
        })();
    }
  },
  destroyed() {
    if (!(!!ws_tc)) {
      this.ws_tc.unsubscribe();
    }
  },
  computed: {
    reverseEvents() {
      return this.events.slice().reverse();
    },
  },
  methods: {
    reset() {
      this.events = [];
      this.bellNode.innerHTML = '';
      this.bellNode.style.opacity = 0;
      this.show = false;
    },
    showDetails(item) {
      this.$parent.showFails(item);
    },
    onNatsMessageControl(msg) {
      //console.log('control', msg);
      try {
        msg = JSON.parse(msg);
      } catch (e) {
        return;
      }

      const rawType = msg['type'];

      msg.name = _dsp_get_fail_type(rawType);

      if ('unknown' === msg.name) {
        return;
      }

      const route = this.$parent.markRoute(msg.route);

      if (!route) {
        return;
      }

      msg.trip = { id: msg.trip, tripId: msg.trip, vc: msg.govnum, route: route };

      if (msg.deviation) {
        msg.deviation = (1447 == msg.deviation) ? 'не прибыл' : msg.deviation + ' мин.';
      }

      if ((rawType == '8') || (rawType == 'failedArrival') || (rawType == 'failedDeparture')) {
        msg.distance = null; //reset for time's
      }

      msg.time = (!!msg.time) ? new Date(msg.time) : new Date();
      this.events.push(msg);
      this.bellNode.innerHTML = this.events.length;
      this.bellNode.style.opacity = 1;
    },
  },
  template: '<v-dialog v-model="show" transition="dialog-top-transition" activator=".dsp-signal" max-width="720">\
                <v-card>\
                    <v-toolbar dark color="primary">\
                        <v-toolbar-title>Сообщения контроля нарушений по состоянию на {{$parent.theTime(lastEvtTime, true)}}</v-toolbar-title>\
                        <v-spacer></v-spacer>\
                        <v-toolbar-items>\
                            <v-tooltip bottom>\
                                <template v-slot:activator="{ on }">\
                                    <v-btn dark text @click="reset()" v-on="on">сбросить</v-btn>\
                                </template>\
                                <span>Очистить список сообщений</span>\
                            </v-tooltip>\
                            <v-tooltip bottom>\
                                <template v-slot:activator="{ on }">\
                                    <v-btn dark text @click="show=false" v-on="on"><v-icon>mdi-close</v-icon></v-btn>\
                                </template>\
                                <span>Закрыть список</span>\
                            </v-tooltip>\
                        </v-toolbar-items>\
                    </v-toolbar>\
                    <v-card-text>\
                        <v-list v-if="(events.length > 0)" three-line class="dsp-control-online">\
                            <!--v-subheader></v-subheader--->\
                            <template v-for="(item, index) in reverseEvents">\
                                <v-list-item :key="(item.id + \'-rtm\')" @click="$emit(\'onfail\', item)">\
                                    <v-list-item-avatar>{{$parent.theTime(item.time, true)}}</v-list-item-avatar>\
                                    <v-list-item-content>\
                                        <v-list-item-title>{{item.name}}</v-list-item-title>\
                                        <v-list-item-subtitle>\
                                            <div><b>{{item.trip.route.code}}</b>. {{item.trip.route.name}}</div>\
                                            <div class="d-flex justify-space-between">\
                                                <div v-if="(item.govnum)"><b>ТС</b>: {{item.govnum}}</div>\
                                                <div v-if="(item.stopname)"><b>остановка</b>: {{item.stopname}}</div>\
                                                <div v-if="(item.distance)"><b>расстояние</b>: {{item.distance}} м</div>\
                                                <div v-if="(item.deviation)"><b>отклонение</b>: {{item.deviation}}</div>\
                                            </div>\
                                            <div>{{item.trip.route.org.name}}</div>\
                                        </v-list-item-subtitle>\
                                    </v-list-item-content>\
                                </v-list-item>\
                                <v-divider />\
                            </template>\
                        </v-list>\
                    </v-card-text>\
                </v-card>\
            </v-dialog>',
};  //DspOnlineControl

const DspSchedule = {
  name: 'DspSсhedule',
  props: ['trip'],
  data: () => ({
    loading: false,
    schedule: [],
    vcInfo: null,
  }),
  watch: {
    trip: function(newTrip, oldTrip) {
      if (!newTrip) {
        return;
      }
      this.loading = true;
      var self = this;
      jet.http.post('/rpc?d=jsonRpc', {
        type: 'query',
        query: '948e1fa3-414a-4568-b4ed-3a5535b239e9.dsp_getDspInfoFn',
        params: { attr: 2, uid: newTrip.id, id: newTrip.id },
      }).then((data) => {
        self.loading = false;
        if (data.result) {
          var _shs = [],
            now = (new Date()).getTime(),
            magic = 2.5 * 60 * 1000;
          for (var i = 0; i < data.result.data.length; i++) {
            var _s = {
              id: data.result.data[i][0],  //
              stop: data.result.data[i][1],  //
              rqsst: data.result.data[i][6],  //по треб.
              arr: (data.result.data[i][13]) ? new Date(data.result.data[i][13]) : null,
              dep: (data.result.data[i][14]) ? new Date(data.result.data[i][14]) : null,
              stt: 'unknown',
            };
            if ((_s.arr) && (_s.dep)) {
              if ((now + magic > _s.arr.getTime()) && (now - magic < _s.dep.getTime())) {
                _s.stt = 'current';
              } else if (now < _s.arr.getTime()) {
                _s.stt = 'future';
              } else if (now > _s.dep.getTime()) {
                _s.stt = 'finish';
              }
            }
            _shs.push(_s);
          }
          self.schedule = _shs;
        }
      }).catch((err) => {
        self.loading = false;
        console.log(err);
      });     //TODO: err-handel
    },
  },
  computed: {
    show: {
      get: function() {
        return (!!this.trip);
      },
      set: function(oldVal, newVal) {
        if (!newVal) {
          this.$emit('hide');
        }
      },
    },
    title: {
      get: function() {
        if (this.trip) {
          return 'расписание рейса № ' + this.trip.n + ' маршрута № ' + this.trip.route.code + '. ' + this.trip.route.name;
        }
        return '';
      },
    },
  },
  methods: {
    getVcInfo: function() {
      this.vcInfo = null;
      if ((!this.trip) || (!this.trip.vc)) {
        return;
      }
    },
  },
  template: '<v-dialog v-model="show" transition="dialog-top-transition" max-width="95%">\
                <v-card>\
                    <v-toolbar dark color="primary">\
                        <v-toolbar-title>{{ title }}</v-toolbar-title>\
                        <v-spacer></v-spacer>\
                        <v-toolbar-items>\
                            <v-btn dark text @click="show=false">закрыть</v-btn>\
                        </v-toolbar-items>\
                    </v-toolbar>\
                    <v-card-text>\
                        <h3 v-show="(loading)" class="m-5"><v-progress-circular size="32" indeterminate color="primary" class="mr-3"></v-progress-circular>Загрузка расписания...</h3>\
                        <v-list v-if="(!!schedule)" two-line subheader class="schedule">\
                            <v-subheader v-if="(!!trip)&&(trip.vc)">везет {{trip.vc}}</v-subheader>\
                            <v-subheader v-else><v-icon>warning</v-icon>ТС не запланировано</v-subheader>\
                            <template v-for="(item, index) in schedule">\
                                <v-list-item :key="item.id" class="timed-item" v-bind:class="item.stt">\
                                    <v-list-item-avatar>{{ index + 1 }}</v-list-item-avatar>\
                                    <v-list-item-content>\
                                        <v-list-item-title v-text="(index==0) ? $parent.theTime(item.arr, true) : (index==schedule.length-1) ? $parent.theTime(item.dep, true) : $parent.theTime(item.arr, true) + \' - \' + $parent.theTime(item.dep, true)"></v-list-item-title>\
                                        <v-list-item-subtitle>\
                                            <svg v-if="(item.rqsst>0)" title="Остановки по требованию" viewBox="0 0 512 512" style="margin-right:0.5rem;width:18px;height:18px;color:#c4c4c4s;"><use xlink:href="#icon-request" /></svg>\
                                            {{ item.stop }}\
                                        </v-list-item-subtitle>\
                                    </v-list-item-content>\
                                </v-list-item>\
                                <v-divider />\
                            </template>\
                        </v-list>\
                    </v-card-text>\
                </v-card>\
            </v-dialog>',
};


import DspFailComment from './components/dsp-ext/DspFailComment';

const DspFails = {
  name: 'DspFails',
  props: {
    'trip': {
      type: Object, required: true, default: function() {
        return { id: 'stub' };
      },
    },
  },
  components: {
    DspFailComment,
  },
  data: () => ({
      loading: false,
    failures: [],
    editedFail: null,
  }),
  created(){ 
    var newTrip = this._props.trip;
    this.prepareData(newTrip);
  },
  watch: {
    trip: function(newTrip, oldTrip) {
      this.prepareData(newTrip);
    },   //trip;
  },      //watch
  methods: {
    prepareData: function(newTrip) {
      console.log('DspFails: set a trip for list', newTrip);
      this.editedFail = null;
      if (!newTrip) {
        this.failures = [];
        return;
      }
      if ((newTrip.failures) && (newTrip.failures.length > 0)) {
        this.failures = newTrip.failures;
        newTrip.failure = newTrip.failures[newTrip.failures.length - 1];
        this.$emit('onfail', newTrip);
        this.$forceUpdate();
        return;
      } else {
        this.failures = [];
      }  
    },
    itemByNode: function(node) {
      var id = null;
      while (node) {
        if (node.hasAttribute('data-fail-id')) {
          id = node.getAttribute('data-fail-id');
          break;
        }
        node = node.parentNode;
      }
      if (!id) {
        return null;
      }
      for (var i = 0; i < this.failures.length; i++) {
        if (id === this.failures[i].id) {
          return this.failures[i];
        }
      }
      return null;
    },
    onFail: function(e) {
      console.log('sel Fail', e);
      this.trip.failure = this.itemByNode(e.target);
      this.$emit('onfail', this.trip);
    },
    comment: function(e) {
      console.log(e);
      e.preventDefault();
      e.stopPropagation();
      this.editedFail = this.itemByNode(e.target);
    },
    openFile: function(e) {
      location.href = '/rpc/?d=file&uri=fs:id:' + this.itemByNode(e.target).file;
    },
  },
  render: function(h) {
    if (this.loading) {
      return h('div', { class: { 'mb-3': true, 'dsp-fails-conte': true } }, [
        'Получение списка нарушений рейса...',
        h('v-progress-linear', {
          props: {
            active: true,
            bottom: true,
            indeterminate: true,
            rounded: true,
            color: 'primary',
          },
        }),
      ]);
    }
    if (!this.trip) {
      return;
    }
    var items = [
      h('v-list-item', { class: { 'trip-title': true } }, [
        h('v-list-item-avatar', this.trip.n),
        h('v-list-item-content', [
          h('v-list-item-title', get_the_time(this.trip.start, true) + '-' + get_the_time(this.trip.end, true) + ', ' + this.trip.vc),
        ]),
      ]),
    ], s, self = this;
    items.push(h('v-divider'));
    if (this.failures.length > 0) {
      for (var n = this.failures.length - 1; n >= 0; n--) {
        var fail = this.failures[n],
          details = [];
        if (fail.stop) {
          details.push(h('div', {class: {'text-wrap': true }}, [h('b', 'остановка'), ': ' + fail.stop]));
        }
        if (fail.distance) {
          details.push(h('div', [h('b', 'расстояние'), ': ' + fail.distance + ' м']));
        }
        if (fail.dev) {
          if (/^не/.test(fail.dev)) {  //не прибыл
            details.push(h('div', { style: { 'font-weight': 'bold' } }, fail.dev));
          } else if (fail.t == 4) {
            details.push(h('div', [h('b', 'продолжительность: '), fail.dev + ' мин.']));
          } else {
            details.push(h('div', [h('b', 'отклонение: '), fail.dev + ' мин.']));
          }
        }
        if (!!fail.timeIn) {
          details.push(h('div', [h('b', 'время возврата: '), fail.timeIn]));
        } else if ((fail.t == 4) || (fail.t == 'noMovement')) {
          details.push(h('div', { style: { 'font-weight': 'bold' } }, 'продолжает стоять'));
        }
        if (fail.violationName) {
          details.push(h('div', {
            style: {
              width: '100%',
              'font-size': '0.9rem',
              'overflow': 'hidden',
              'text-overflow': 'ellipsis',
              'white-space': 'nowrap',
              'font-weight': 'bold',
            },
            class: {'text-wrap': true },
          }, fail.violationName));
        }
        if (fail.comment) {
          details.push(h('div', {
            style: {
              width: '100%',
              'font-size': '0.7rem',
              'overflow': 'hidden',
              'text-overflow': 'ellipsis',
              'white-space': 'nowrap',
            },
            class: {'text-wrap': true },
          }, fail.comment));
        }
        if (fail.result) {
          details.push(h('div', {
            style: {
              width: '100%',
              'font-size': '0.7rem',
              'overflow': 'hidden',
              'text-overflow': 'ellipsis',
              'white-space': 'nowrap',
            },
            class: {'text-wrap': true },
          }, fail.result));
        }
        
        items.push(
          h('v-list-item', { key: fail.id, attrs: { 'data-fail-id': fail.id }, on: { click: self.onFail } }, [
            h('v-list-item-avatar', fail.timeOut),
            h('v-list-item-content', [
              h('v-list-item-title', fail.t_name),
              h('v-list-item-subtitle', [
                h('div', { class: { 'd-none': (!fail.schTime) } }, [
                  h('b', 'по расписанию: '),
                  (fail.schTime) ? fail.schTime : '',
                ]),
                h('div', { class: { 'd-flex': true, 'flex-column': true, 'dsp-fail-details': true } }, details),
                h('a', {
                  on: { click: this.comment },
                  domProps: { 'value': fail.id, href: 'javascript:void(0)' },
                }, 'комментировать'),
                (fail.file) ? 
                  h('div', [
                    h('a', {
                      on: { click: this.openFile },
                      domProps: { 'value': fail.file, href: 'javascript:void(0)' }, 
                    }, 'открыть вложения')
                  ]) : 
                  null
              ]),
            ]),
          ]),
        );
        items.push(h('v-divider'));
      }   //for (var n...)
    } else {
      items.push(
        h('v-list-item', { key: 'no-data-' + (new Date()).getTime() }, [
          h('v-list-item-avatar', [
            h('v-icon', ['alarm_off']),
          ]),
          h('v-list-item-content', ['По выбранному рейсу данные нарушений не получены']),
        ]),
      );
    }
    
    return h('div', { class: { 'dsp-fails-conte': true } }, [
        h('v-list', {
            class: { 'dsp-fails-list': true },
            props: { 'dense': true },
        }, items), 
        h('DspFailComment', { props: { 'fail': self.editedFail } })
    ]);
  }
};          //DspFails

export default {
  name: 'DspRoutsView',
  data: function() {
    return {
      loadData: false,  //stop loading base data
      queryDate: null,
      queryMode: -1,
      error: null,
      orgs: [],
      activeOrgIndex: -1,
      activeRoute: null,
      activeDep: null,
      activeTrip: null,
      activeVc: null,
      controlTrip: null,
      showMap: false,
      mapRoute: false,
      mapBox: null,
      searchInpVal: '',
      searchVal: '',
      hSearchTimer: null,
      modeRoute: [
        { title: 'Мои', value: 0 },
        { title: 'Все', value: 5 },
      ],
      onlyMyRoute: null,
      isModerator: false,
    };
  }, // methods
  components: {
    DspSchedule,
    DspOnlineControl,
    DspFails
  },
  async created() {
    this.onlyMyRoute = this.modeRoute[0];
    this.isModerator = !!this.$store.state.profile.subject.roles[OtherService.MODERATOR_ROLE_GUID];
    this.styling();
    /**
     * Mapbox GL loading
     */

    if (typeof mapboxgl === 'undefined') {
      var p, s = document.createElement('link');
      s.setAttribute('rel', 'stylesheet');
      s.setAttribute('href', 'https://api.tiles.mapbox.com/mapbox-gl-js/v1.5.0/mapbox-gl.css');
      document.getElementsByTagName('head')[0].appendChild(s);
      s = document.createElement('script');
      s.setAttribute('src', 'https://api.tiles.mapbox.com/mapbox-gl-js/v1.5.0/mapbox-gl.js');
      document.getElementsByTagName('head')[0].appendChild(s);
      await (async () => {
        return new Promise((resolve) => {
          s.addEventListener('load', () => {
            //console.log('MBX LOADED!!!');
            resolve();
          });
        });
      })();
    }

    if (($('#dsp-ctrl-user').length < 1) && (jet.$store.state.profile.subject)) {
      /*  TODO: bell
              var _my = document.createElement('div'),
                  _co = $('.jet-col-title');
              _my.id = "dsp-ctrl-user";
              _my.innerHTML = '<span class="dsp-signal"><svg viewBox="0 0 512 512" style="width:16px;height:16px;"><use xlink:href="#icon-bell" /></svg><div class="dsp-num-evts"></div></span> ' + jet.$store.state.profile.subject.title + ' <span class="num-of"></span>';
              _my.style.position = "absolute";
              _my.style.right = "1rem";
              (_co.length>0) ? _co.parent().add(_my) : void(0);
      */
      var subj = jet.$store.state.profile.subject;
      if ((subj) && (subj.tenantId)) {
        var title = document.querySelector('.jet-col-title');
        if (title.length > 0) {
          title.html(title.html + ' | ' + subj.tenants[subj.tenantId].title);
        }
      }
    }
  },
  beforeMount: function() {
    this.refresh();
  },
  mounted: function() {
    //PRE a map: stub for correct's showing fails list
    //this.openMap({id:'stub'});
    this.$nextTick(() => {
      this.showMap = false;
      this.mapRoute = false;
    });
  },
  destroyed: function() {
    if (ws_alarm) {
      ws_alarm.unsubscribe();
      ws_alarm = null;
    }
  },
  methods: {
    styling: function() {
      var el = document.createElement('style');
      el.setAttribute('type', 'text/css');
      el.id = 'dsp-basic-styles';
      el.innerText = '.w-100 {width:100%;}\
                    .dsp-signal{opacity:0.8;} .dsp-signal:hover{opacity:1;}\
                    .dsp-num-evts{display:inline-block;width:auto;padding:0.125rem 0.25rem; color:#fff;background-color:#F44336;border-radius:3px;font-size:10px;position:relative;top:-1rem;left:-0.25rem;}\
                    #dsp-icons{display:none !important;}\
                    .jet-routes-conte {max-height:calc(100vh - 140px);overflow:auto;background-color:#f9faff;}\
                    .jet-routes-conte h3{font-size:1.25rem;color:dark-gray;font-weight:300;}\
                    .jet-routes-conte .back{border:0 none;width:2.5rem;height:2.5rem;}\
                    .jet-routes-conte .container--fluid{padding:0;}\
                    .jet-routes-conte .v-expansion-panel--active .v-expansion-panel-header{min-height:36px;}\
                    .jet-routes-conte input[type="search"]{transition: width .5s;width:15rem;}\
                    .jet-routes-conte input[type="search"]:focus{width:18rem;}\
                    .jet-routes-conte .dsp-search .v-text-field__details{display:none;}\
                    .jet-routes-conte .v-expansion-panel-header{padding: 0.5rem 1rem;}\
                    .jet-routes-conte .dsp-legend{text-align:right;}\
                    .jet-routes-conte .dsp-legend i{display:inline-block;width:18px;height:12px;margin-right:0.75rem;border-radius:3px;}\
                    .jet-routes-conte .dsp-legend i.dsp-none{background-color:#c4c4c4;}\
                    .jet-routes-conte .dsp-legend i.dsp-part{background-color:#ddaf1e;}\
                    .jet-routes-conte .dsp-legend i.dsp-full{background-color:#a2d028;}\
                    .jet-routes-conte .dsp-org-planing .dsp-val{display:inline-block;min-width:2rem;padding:0.125rem;color:#fff;border-radius:3px;text-align:center;font-size:0.85rem;border:1px solid;margin:0 1rem;}\
                    .jet-routes-conte .route-card{width:400px;margin:0 1rem 1rem 0; border-radius:7px !important;background:linear-gradient(to bottom, #ffffff 0%,#fbfcfe 100%);}\
                    .jet-routes-conte .route-card .route-code{font-size:1rem;padding:0.125rem 0.35rem;background:#9bafb8;color:#fff;border-radius:6px 0;min-width:4rem;}\
                    .jet-routes-conte .route-card .v-card__title{font-size:1rem;padding:0px;justify-content:space-between !important;}\
                    .jet-routes-conte .route-card .route-name{font-size:1rem;max-width:30rem;text-overflow:ellipsis;overflow:hidden;color:#3b3a59;margin:1rem 0 2rem 0;white-space:nowrap;}\
                    .jet-routes-conte .route-card .v-chip{min-width:2rem;border-radius:3px;text-align:center;font-size:0.85rem;padding:0 0.75rem;color:#fff;}\
                    .jet-routes-conte .v-badge__badge{white-space: nowrap}\
                    .jet-routes-conte .icon{display:inline-block;width:24px;height:24px;}\
                    .jet-routes-list .v-list-item{min-height:1.125rem;}\
                    .jet-routes-list .v-list-item > * {padding:0;}\
                    .jet-route .route-title{color:#9bafb8;font-size:1.5rem;margin:0;padding:0;font-weight:400;line-height:1.115;}\
                    .jet-route .route-title .route-code{display:inline-block;padding:0.25rem 0.5rem;color:#fff;background-color:#99b0b8;margin-right:0.5rem;border-radius:4px;font-size:1.35rem;}\
                    .jet-route .route-title .text-muted{font-size:0.75rem;}\
                    .route-deps-conte .dep-card{width:200px; height:142px; margin: 1rem;background:linear-gradient(to bottom, #ffffff 0%,#fbfcfe 100%);border-radius:7px !important;}\
                    .route-deps-conte .dep-card.active{background:#fff !important;border-color:#c4c4c4 !important;}\
                    .route-deps-conte .dep-card .v-card__title{font-size:1rem;padding:0 0.5rem 0 0;justify-content:space-between !important;height:36px;}\
                    .route-deps-conte .dep-card .dep-code{font-size:1rem;padding:0.125rem 0.35rem;background:#7a7a7a;color:#fff;border-radius:6px 0;min-width:3rem;max-width:6rem;overflow:hidden;white-space:nowrap;text-overflow:ellipsis;}\
                    .route-deps-conte .dep-card .dep-status{min-width:2rem;border-radius:3px;text-align:center;font-size:0.75rem;padding:0.25rem 0.5rem;color:#fff;height:auto;line-height:1.115;}\
                    .route-deps-conte .dep-card.dsp-empty .dep-status{background-color:#c4c4c4;}\
                    .route-deps-conte .dep-card.dsp-part  .dep-status{background-color:#ddaf1e;}\
                    .route-deps-conte .dep-card.dsp-full  .dep-status{background-color:#a2d028;}\
                    .route-deps-conte .dep-card .v-card__text{height:100px;padding:12px;}\
                    .route-deps-conte .dep-card .dep-vehicles{color:#3b3b57;line-height:1.115;padding:0;}\
                    .route-deps-conte .dep-card .dep-time{color:#7A7A7A;}\
                    .dsp-trips-head{color:#7A7A7A;font-size:1.35rem;font-weight:400;}\
                    .dsp-trips-head .dep-code{display:inline-block;background:#7A7A7A;color:#fff;font-size:1.125rem;padding:0.125rem 0.5rem;min-width:3rem;text-align:center;margin:0 0.5rem 0 0;border-radius:3px;}\
                    .route-trips-conte .trip-card{width:270px;height:144px; margin: 1rem;}\
                    .route-trips-conte .trip-card .v-card__title{font-size:1rem;padding:0 0.5rem 0 0;justify-content:space-between !important;flex-wrap:nowrap;height:36px;}\
                    .route-trips-conte .trip-card .v-card__text{height:64px;padding:8px 16px;}\
                    .route-trips-conte .trip-card .v-card__actions{padding:8px;}\
                    .route-trips-conte .trip-card .trip-code{font-size:1rem;padding:0.125rem 0.35rem;background:#7a7a7a;color:#fff;border-radius:6px 0;min-width:3rem;text-overflow: ellipsis;overflow: hidden;white-space: nowrap;}\
                    .route-trips-conte .trip-card .trip-state{color:#7a7a7a;text-overflow: ellipsis;overflow: hidden;white-space: nowrap;}\
                    .route-trips-conte .trip-card .trip-state svg{width:16px;height:16px;}\
                    .route-trips-conte .trip-card .vc-gov{color:#1F1F40;font-size:1.125rem;text-transform:uppercase;}\
                    .route-trips-conte .trip-card .trip-times{color:#7a7a7a;margin:4px 0 0 12px;}\
                    .route-trips-conte .trip-card .trip-times .deviation{font-size:0.7rem;position:relative;top:-0.75rem;left:0.125rem;}\
                    .route-trips-conte .vc-link{display:inline-block;width:8px;height:8px;border-radius:50%;background:#4CAF50;position:relative;top:-5px;}\
                    .route-trips-conte .vc-link.no-link{background:#BDBDBD;}\
                    .route-trips-conte .vc-link.after2{background:#ff9800;}\
                    #route-map{min-height:400px;height:calc(100% - 64px);background:#fff;}\
                    .map-vechicle-info .v-dialog__content{z-index:999;}\
                    .vc-status b{display:inline-block;width:6px;height:6px;margin-right:0.5rem;border-radius:50%}\
                    .vc-status.vc-success b{background-color:#4CAF50;}\
                    .vc-status.vc-warning b{background-color:#FF9800;}\
                    .vc-status.vc-danger b{background-color:#d50000;}\
                    .jet-routes-conte .dsp-none .dsp-val{background-color:#c4c4c4 !important;}\
                    .jet-routes-conte .dsp-part .dsp-val{background-color:#ddaf1e !important;}\
                    .jet-routes-conte .dsp-full .dsp-val{background-color:#a2d028 !important;}\
                    .jet-routes-conte .dsp-empty .dsp-val{background-color:#fff !important; color:#c4c4c4 !important; border:1px solid #c4c4c4 !important;}\
                    .jet-routes-conte .v-chip.dsp-fact{border:1px solid #c4c4c4; color:#c4c4c4; background:transparent none;}\
                    .jet-routes-conte .dsp-none-fact .v-chip.dsp-fact{color:#ddaf1e !important;border-color:#ddaf1e !important;}\
                    .jet-routes-conte .dsp-part-fact .v-chip.dsp-fact{color:#ddaf1e !important;border-color:#ddaf1e !important;}\
                    .jet-routes-conte .dsp-full-fact .v-chip.dsp-fact{color:#a2d028 !important;border-color:#a2d028 !important;}\
                    .v-data-table td{overflow:hidden;white-space:nowrap;text-overflow:ellipsis;max-width:15rem;}\
                    .dsp-control-online .v-list-item span{display: inline-block;margin-right:1.5rem;}\
                    .dsp-fails-conte{width:25%;overflow:auto;height:calc(100% - 64px);}\
                    .dsp-fails-list .trip-title {color:rgb(0, 60, 135);}\
                    .dsp-fail-details div{margin-right:1.5rem;}\
    ';
      document.body.appendChild(el);
      if (document.querySelectorAll('#dsp-icons').length < 1) {
        el = document.createElement('div');
        el.id = 'dsp-icons';
        el.innerHTML = '\
                    <svg aria-hidden="true" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">\
                        <g id="icon-bell"><path fill="currentColor" d="M224 480c-17.66 0-32-14.38-32-32.03h-32c0 35.31 28.72 64.03 64 64.03s64-28.72 64-64.03h-32c0 17.65-14.34 32.03-32 32.03zm209.38-145.19c-27.96-26.62-49.34-54.48-49.34-148.91 0-79.59-63.39-144.5-144.04-152.35V16c0-8.84-7.16-16-16-16s-16 7.16-16 16v17.56C127.35 41.41 63.96 106.31 63.96 185.9c0 94.42-21.39 122.29-49.35 148.91-13.97 13.3-18.38 33.41-11.25 51.23C10.64 404.24 28.16 416 48 416h352c19.84 0 37.36-11.77 44.64-29.97 7.13-17.82 2.71-37.92-11.26-51.22zM400 384H48c-14.23 0-21.34-16.47-11.32-26.01 34.86-33.19 59.28-70.34 59.28-172.08C95.96 118.53 153.23 64 224 64c70.76 0 128.04 54.52 128.04 121.9 0 101.35 24.21 138.7 59.28 172.08C421.38 367.57 414.17 384 400 384z"></path></g>\
                    </svg>\
                    <svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" shape-rendering="geometricPrecision">\
                        <g id="icon-warning"><path fill="currentColor" d="M270.2 160h35.5c3.4 0 6.1 2.8 6 6.2l-7.5 196c-.1 3.2-2.8 5.8-6 5.8h-20.5c-3.2 0-5.9-2.5-6-5.8l-7.5-196c-.1-3.4 2.6-6.2 6-6.2zM288 388c-15.5 0-28 12.5-28 28s12.5 28 28 28 28-12.5 28-28-12.5-28-28-28zm281.5 52L329.6 24c-18.4-32-64.7-32-83.2 0L6.5 440c-18.4 31.9 4.6 72 41.6 72H528c36.8 0 60-40 41.5-72zM528 480H48c-12.3 0-20-13.3-13.9-24l240-416c6.1-10.6 21.6-10.7 27.7 0l240 416c6.2 10.6-1.5 24-13.8 24z"></path></g>\
                        <g id="icon-hex-fail"><path fill="currentColor" d="M441.5 39.8C432.9 25.1 417.1 16 400 16H176c-17.1 0-32.9 9.1-41.5 23.8l-112 192c-8.7 14.9-8.7 33.4 0 48.4l112 192c8.6 14.7 24.4 23.8 41.5 23.8h224c17.1 0 32.9-9.1 41.5-23.8l112-192c8.7-14.9 8.7-33.4 0-48.4l-112-192zM400 448H176L64 256 176 64h224l112 192-112 192zm-10.2-112.8l-22.6 22.6c-4.7 4.7-12.3 4.7-17 0L288 295.6l-62.2 62.2c-4.7 4.7-12.3 4.7-17 0l-22.6-22.6c-4.7-4.7-4.7-12.3 0-17l62.2-62.2-62.2-62.2c-4.7-4.7-4.7-12.3 0-17l22.6-22.6c4.7-4.7 12.3-4.7 17 0l62.2 62.2 62.2-62.2c4.7-4.7 12.3-4.7 17 0l22.6 22.6c4.7 4.7 4.7 12.3 0 17L327.6 256l62.2 62.2c4.7 4.7 4.7 12.3 0 17z"</path></g>\
                    </svg>\
                    <svg aria-hidden="true" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">\
                        <g id="icon-map-point"><path fill="currentColor" d="M192 96c-52.935 0-96 43.065-96 96s43.065 96 96 96 96-43.065 96-96-43.065-96-96-96zm0 160c-35.29 0-64-28.71-64-64s28.71-64 64-64 64 28.71 64 64-28.71 64-64 64zm0-256C85.961 0 0 85.961 0 192c0 77.413 26.97 99.031 172.268 309.67 9.534 13.772 29.929 13.774 39.465 0C357.03 291.031 384 269.413 384 192 384 85.961 298.039 0 192 0zm0 473.931C52.705 272.488 32 256.494 32 192c0-42.738 16.643-82.917 46.863-113.137S149.262 32 192 32s82.917 16.643 113.137 46.863S352 149.262 352 192c0 64.49-20.692 80.47-160 281.931z"></path></g>\
                    </svg>\
                    <svg aria-hidden="true" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">\
                        <g id="icon-clock"><path fill="currentColor" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm216 248c0 118.7-96.1 216-216 216-118.7 0-216-96.1-216-216 0-118.7 96.1-216 216-216 118.7 0 216 96.1 216 216zm-148.9 88.3l-81.2-59c-3.1-2.3-4.9-5.9-4.9-9.7V116c0-6.6 5.4-12 12-12h14c6.6 0 12 5.4 12 12v146.3l70.5 51.3c5.4 3.9 6.5 11.4 2.6 16.8l-8.2 11.3c-3.9 5.3-11.4 6.5-16.8 2.6z"></path></g>\
                        <g id="icon-check-ok"><path fill="currentColor" d="M435.848 83.466L172.804 346.51l-96.652-96.652c-4.686-4.686-12.284-4.686-16.971 0l-28.284 28.284c-4.686 4.686-4.686 12.284 0 16.971l133.421 133.421c4.686 4.686 12.284 4.686 16.971 0l299.813-299.813c4.686-4.686 4.686-12.284 0-16.971l-28.284-28.284c-4.686-4.686-12.284-4.686-16.97 0z"></path></g>\
                        <g id="icon-fail"><path fill="currentColor" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm0 464c-118.7 0-216-96.1-216-216 0-118.7 96.1-216 216-216 118.7 0 216 96.1 216 216 0 118.7-96.1 216-216 216zm94.8-285.3L281.5 256l69.3 69.3c4.7 4.7 4.7 12.3 0 17l-8.5 8.5c-4.7 4.7-12.3 4.7-17 0L256 281.5l-69.3 69.3c-4.7 4.7-12.3 4.7-17 0l-8.5-8.5c-4.7-4.7-4.7-12.3 0-17l69.3-69.3-69.3-69.3c-4.7-4.7-4.7-12.3 0-17l8.5-8.5c4.7-4.7 12.3-4.7 17 0l69.3 69.3 69.3-69.3c4.7-4.7 12.3-4.7 17 0l8.5 8.5c4.6 4.7 4.6 12.3 0 17z"></path></g>\
                        <g id="icon-list"><path fill="currentColor" d="M80 48H16A16 16 0 0 0 0 64v64a16 16 0 0 0 16 16h64a16 16 0 0 0 16-16V64a16 16 0 0 0-16-16zm0 160H16a16 16 0 0 0-16 16v64a16 16 0 0 0 16 16h64a16 16 0 0 0 16-16v-64a16 16 0 0 0-16-16zm0 160H16a16 16 0 0 0-16 16v64a16 16 0 0 0 16 16h64a16 16 0 0 0 16-16v-64a16 16 0 0 0-16-16zm416-136H176a16 16 0 0 0-16 16v16a16 16 0 0 0 16 16h320a16 16 0 0 0 16-16v-16a16 16 0 0 0-16-16zm0 160H176a16 16 0 0 0-16 16v16a16 16 0 0 0 16 16h320a16 16 0 0 0 16-16v-16a16 16 0 0 0-16-16zm0-320H176a16 16 0 0 0-16 16v16a16 16 0 0 0 16 16h320a16 16 0 0 0 16-16V88a16 16 0 0 0-16-16z"></path></g>\
                        <g id="icon-comments"><path fill="currentColor" d="M448 0H64C28.7 0 0 28.7 0 64v288c0 35.3 28.7 64 64 64h96v84c0 7.1 5.8 12 12 12 2.4 0 4.9-.7 7.1-2.4L304 416h144c35.3 0 64-28.7 64-64V64c0-35.3-28.7-64-64-64zm32 352c0 17.6-14.4 32-32 32H293.3l-8.5 6.4L192 460v-76H64c-17.6 0-32-14.4-32-32V64c0-17.6 14.4-32 32-32h384c17.6 0 32 14.4 32 32v288zM280 240H136c-4.4 0-8 3.6-8 8v16c0 4.4 3.6 8 8 8h144c4.4 0 8-3.6 8-8v-16c0-4.4-3.6-8-8-8zm96-96H136c-4.4 0-8 3.6-8 8v16c0 4.4 3.6 8 8 8h240c4.4 0 8-3.6 8-8v-16c0-4.4-3.6-8-8-8z"></path></g>\
                        <g id="icon-request"><path fill="currentColor" d="M501.5 66.6l-71.7-26.1 9.5-23.2c1.7-4.1-.2-8.8-4.3-10.5L420.2.6c-4.1-1.7-8.8.2-10.5 4.3l-10 24.7L321.1 1c-8.9-3.2-18 2.7-20.5 9.6L264.1 111c-3 8.3 1.2 17.5 9.6 20.5l73.6 26.8-22.5 55.4c-3.7-1.8-7.3-3.9-11.5-4.8L253.6 197l-10.1-16.1c-11.9-19-28.8-33.5-48.4-42.5 12.8-13 20.7-30.8 20.7-50.4 0-39.7-32.3-72-72-72S72 48.3 72 88c0 20.8 9 39.4 23.1 52.6-22.8 11.3-41.9 29.7-53.8 53.5L5 266.5c-11.8 23.7-2.2 52.6 21.5 64.4.7.3 26.5 14.1 51-5L49.1 453.6c-5.7 25.8 10.6 51.5 36.4 57.3 3.6.8 7 1.1 10.4 1.1 22.3 0 42-15.8 46.8-37.6l17.2-77.5 15.9 19.9V464c0 26.5 21.5 48 48 48s48-21.5 48-48v-52.8c0-18.1-6.2-35.8-17.5-50l-46.5-58.1v-20.3c6.3 3.2 13.1 5.5 20.1 6.9l60.8 12.2-.3.8c-1.7 4.1.2 8.8 4.3 10.5l14.8 6.2c4.1 1.7 8.8-.2 10.5-4.3l6.7-16.6c13-6.4 23.1-18.1 26.2-33.1 1.8-8.8.9-17.8-2.1-26l28.6-70.2 76.6 27.9c8.9 3.3 18-2.8 20.5-9.6L511 87.1c3.1-8.3-1.2-17.4-9.5-20.5zM143.9 48c22.1 0 40 17.9 40 40s-17.9 40-40 40-40-17.9-40-40 17.9-40 40-40zm157.2 223.7h-.4l-66.4-13.3c-12.9-2.6-24.3-10.5-31.3-21.6-14.6-23.3-16.3-28-27.1-35.6v113.2l53.5 66.9c6.8 8.5 10.5 19.1 10.5 30V464c0 8.8-7.2 16-16 16s-16-7.2-16-16v-52.8c0-3.6-1.2-7.2-3.5-10L152.2 336h-11.5l-29.2 131.5c-1.7 7.7-9.6 14.2-19.1 12.2-8.6-1.9-14.1-10.5-12.2-19.1l31.6-142.3V206c-5.3 4.7-10.1 10-13.4 16.7l-36.2 72.5c-3.1 6.2-12.1 11.9-21.5 7.2-7.9-4-11.1-13.6-7.2-21.5l36.2-72.5c14.9-29.8 44.9-48.4 78.2-48.4 28 0 53.5 14.2 68.3 37.9l13.7 21.9c2.3 3.7 6.1 6.3 10.4 7.2l66.4 13.3c2.2.4 4.2 1.3 5.9 2.5l-11.5 28.9zm148.8-110.1l-150.3-54.7 25.6-70.4 150.3 54.7-25.6 70.4z"></path>\
                    </svg>';
        document.body.appendChild(el);
      }
    },  //styling
    theTime: function(t, only) {
      const fmt = (!!only) ? 'HH:mm' : 'DD.MM.yyyy HH:mm';
      return (!!t) ? $utils.formatDate(t, fmt) : '-';
    },
    back: function() {
      this.activeDep = null;
      this.activeRoute = null;
    },
    onNatsMessageAlarm: function(message) {
      //console.log('!!!ALARM!!!', message);
    },  //onNatsMessageAlarm
    refresh: function() {
      this.activeVc = null;
      this.activeTrip = null;
      this.activeDep = null;
      this.activeRoute = null;
      this.orgs = [];
      this.queryMode = 0;
      const uid = this.$store.state.profile.subject.id;
      //load Routes & Carriers
      jet.http.post('/rpc?d=jsonRpc', {
        type: 'query',
        query: '948e1fa3-414a-4568-b4ed-3a5535b239e9.dsp_getDspInfoFn',
        params: {
          attr: this.onlyMyRoute.value,
          uid: uid,
          id: null,
        },
      }).then((data) => {
        //console.log('dsp_getDspInfoFn:', data);
        this.queryDate = new Date();
        if ((data.result) && (data.result.data)) {
          let _id = 'xxx';
          let _org = null;

          const _orgs = [];

          for (let i = 0; i < data.result.data.length; i++) {
            if (_id !== data.result.data[i][0]) {
              _id = data.result.data[i][0];
              _org = {
                id: _id,
                name: data.result.data[i][1],
                code: data.result.data[i][2],
                sche: 0,
                plan: 0,
                fails: 0,
                tenant: data.result.data[i][11],
                routes: [],
              };
              _orgs.push(_org);
            }

            const _r = {
              id: data.result.data[i][3],
              // org: _org,
              name: data.result.data[i][4],
              code: data.result.data[i][5],
              sche: data.result.data[i][6],  //графиков всего
              plan: data.result.data[i][7],  //постановок
              fact1: data.result.data[i][8],
              fact2: data.result.data[i][9],
              fails: data.result.data[i][10],
              rqsst: data.result.data[i][12], //нал.остановок по треб.
            };

            _org.sche += _r.sche;
            _org.plan += _r.plan;

            _org.routes.push(_r);

            if (_r.fails > 0) {
              _org.fails++;
            }
          }

          this.orgs = _orgs;
          this.queryMode = 1;
          jet.msg({
            text: 'Вы контролируете ' + data.result.data.length + ' по ' + _orgs.length + ' перевозчикам',
            color: 'primary',
            timeout: 6000,
          });
          this.$nextTick(() => {
            $('#dsp-ctrl-user .num-of').html('(' + data.result.data.length + ')');
          });

        } else {
          this.orgs = [];
          jet.msg({ text: 'Данные не найдены', color: 'warning', timeout: 6000 });
          this.queryMode = 1;
          this.error = 'Данные маршрутов не найдены';
        }
      }, (err) => {
        console.log('ERR:dsp_getDspInfoFn:', err);
        this.queryMode = 666;
        this.error = 'Ошибка получения списка контролируемых маршрутов. Пожалуйста обновите страницу';
        jet.msg({ text: this.error, color: 'warning', timeout: 6000 });
      });
    }, // refresh
    orgChange: function(pane) {
      this.activeTrip = null;
      this.activeDep = null;
      this.activeRoute = null;
    },
    markRoute: function(routeId) {
      if (!routeId) {
        return null;
      }
      for (var i = 0; i < this.orgs.length; i++) {
        var org = this.orgs[i];
        for (var n = 0; n < org.routes.length; n++) {
          if (org.routes[n].id === routeId) {
            org.fails++;
            org.routes[n].fails++;
            return org.routes[n];
          }
        }
      }
      return null;
    },  //markRoute
    orgByTenant: function(tenantId) {
      if (!tenantId) {
        return null;
      }
      for (var i = 0; i < this.orgs.length; i++) {
        if (this.orgs[i].tenant === tenantId) {
          return this.orgs[i];
        }
      }
    },
    orgClass: function(org) {
      if ((org.fails) && (org.fails > 0)) {
        return { has_fails: true };
      }
    },
    planingClass: function(item) {
      var c = {};
      if (item.sche > 0) {
        if (item.sche === item.plan) {
          c['dsp-full'] = true;
        } else if (item.plan > 0) {
          c['dsp-part'] = true;
        } else {
          c['dsp-none'] = true;
        }
      } else {
        c['dsp-empty'] = true;
      }
      if (typeof item.fact1 !== 'undefined') {
        if (item.fact1 > 0) {
          if (item.fact1 === item.fact2) {
            c['dsp-full-fact'] = true;
          } else if (item.fact2 > 0) {
            c['dsp-part-fact'] = true;
          } else {
            c['dsp-none-fact'] = true;
          }
        }
      } else {
        c['dsp-empty-fact'] = true;
      }
      return c;
    },
    routeClass: function(route) {
      if ((route.fails) && (route.fails > 0)) {
        return { has_fails: true };
      } else if (route.sche > route.plan) {
        return (route.plan === 0) ? { has_fails: true } : { incomplete: true };
      }
    },
    planingDepClass: function(dep) {
      var c = {};
      if ((dep.trips.length == 0) || (dep.bads == dep.trips.length)) {
        c['dsp-empty'] = true;
      } else if (dep.bads > 0) {
        c['dsp-part'] = true;
      } else {
        c['dsp-full'] = true;
      }
      return c;
    },
    openRoute: function(org, e, p) {
      jet.http.post('/rpc?d=jsonRpc', {
        type: 'query',
        query: '948e1fa3-414a-4568-b4ed-3a5535b239e9.dsp_getDspInfoFn',
        params: { attr: 1, uid: org.id, id: e.id },
      }).then((data) => {
        //console.log('openRoute:', data);
        if ((data.result) && (data.result.data)) {
          var _dep = { id: 'stub', n: '-', bads: 0, fails: 0, trips: [] },
            _deps = [],
            now = new Date(),
            magic = 2.5 * 60 * 1000;
          for (var i = 0; i < data.result.data.length; i++) {
            if (!!data.result.data[i][0]) {
              if (_dep.id !== data.result.data[i][0]) {
                _dep = { id: data.result.data[i][0], n: data.result.data[i][1], trips: [], vchs: [] };
                _deps.push(_dep);
              }
            } else { // no dep id found
              if (_deps.length === 0) {
                _deps.push(_dep);
              }
            }
            var _trip = {
              id: data.result.data[i][2], // scheduleId
              tripId: data.result.data[i][3], // tripId (nullable)
              route: e,
              n: data.result.data[i][5],
              vcId: data.result.data[i][11],
              vc: data.result.data[i][12],
              fails: data.result.data[i][6],
              stateVc: data.result.data[i][7],
              deviation: (!!data.result.data[i][8]) ? data.result.data[i][8] : -1,
              rqsst: data.result.data[i][9],
              start: (!!data.result.data[i][13]) ? new Date(data.result.data[i][13]) : null,
              end: (!!data.result.data[i][14]) ? new Date(data.result.data[i][14]) : null,
              factStart: (!!data.result.data[i][15]) ? new Date(data.result.data[i][15]) : null,
              factEnd: (!!data.result.data[i][16]) ? new Date(data.result.data[i][16]) : null,
              statePlan: 0, // 0-none, 1-начат (выполняется), 2-завершен
              stateStart: 0, // 0-none, 1-начат, 2-начат с опозд, 3-не начат
              stateEnd: 0,  // 0-none, 1-, 2-начат с опозд
            };
            if ((_trip.start) && (_trip.end)) {
              if ((now.getTime() + magic > _trip.start.getTime()) && (now.getTime() - magic < _trip.end.getTime())) {
                _trip.statePlan = 1;
              } else if (now.getTime() > _trip.end.getTime()) {
                _trip.statePlan = 2;
              }
              if (_trip.start.getTime() < now.getTime()) { //started
                if (!!_trip.factStart) {
                  _trip.stateStart = (_trip.deviation < 0) ? 0 : (_trip.start.getTime() - _trip.factStart.getTime()) < _trip.deviation * 60 * 1000 ? 1 : 2; //TODO:
                } else {
                  _trip.stateStart = 3;  //не начат
                }
              }
              if (_trip.end.getTime() < now.getTime()) {  //must be finished
                if (!!_trip.factEnd) {
                  _trip.stateEnd = (_trip.deviation < 0) ? 0 : (_trip.factEnd.getTime() - _trip.end.getTime()) < _trip.deviation * 60 * 1000 ? 1 : 2;
                } else {
                  _trip.stateEnd = 3;  //сорван
                }
              }
            }
            _dep.trips.push(_trip);
          }
          for (var i = 0; i < _deps.length; i++) {
            _deps[i].start = new Date('9999-01-01');
            _deps[i].end = new Date('0001-01-01');
            _deps[i].bads = 0;   // no trip on schedule
            _deps[i].fails = 0;   // control evts by trips
            _deps[i].avhs = [];
            _deps[i].vehicles = '';
            _deps[i].rqsst = 0;

            for (var n = 0; n < _deps[i].trips.length; n++) {
              if (_deps[i].start > _deps[i].trips[n].start) {
                _deps[i].start = _deps[i].trips[n].start;
              }
              if (_deps[i].end < _deps[i].trips[n].end) {
                _deps[i].end = _deps[i].trips[n].end;
              }
              if (!_deps[i].trips[n].tripId) {
                _deps[i].bads++;
              }
              if (!!_deps[i].trips[n].fails) {
                _deps[i].fails++;
              }
              if (!!_deps[i].trips[n].vc) {
                if (_deps[i].avhs.indexOf(_deps[i].trips[n].vc) < 0) {
                  _deps[i].avhs.push(_deps[i].trips[n].vc);
                }
              }
              if (_deps[i].trips[n].rqsst > 0) {
                _deps[i].rqsst += _deps[i].trips[n].rqsst;
              }
            }
          }
          e.deps = _deps;
          this.activeRoute = e;
          if (e.deps.length === 1) {
            this.activeDep = e.deps[0];
          }
          if ((!!p) && (p.resolve)) {
            return p.resolve();
          }
          // console.log('Active route:', e);
        }
      }, (err) => {
        console.log('ERR:openRoute:', err);
      });
    }, // openRoute...
    openDep: function(dep) {
      if (!this.activeRoute) {
        return;
      }
      this.activeDep = dep;
    }, // openDep
    sheduleTrip: function(trip) {
      this.activeTrip = trip;
    },  //shedule
    async showTripControl (trip) {
      var controlID = null;
      
      //get a trip fail's
      await jet.http.post('/rpc?d=jsonRpc', {
        type: 'query',
        query: '948e1fa3-414a-4568-b4ed-3a5535b239e9.get_tripcontrol_dsp',
        params: { in_id: trip.tripId },
      }).then((data) => {
        if (data.result && data.result.data.length > 0) {
          controlID = data.result.data[0][2];
        }
      }).catch((err) => {
        console.log(err);
      });     //TODO: err-handler
      
      if (!controlID) {
        return;
      }
      const MAP_ROUTES_GUID = '802b6602-a729-4b6b-b01a-772e29cb6647';  //sin2 view
      jet.msg();  //hide
      try {
        var resp = await jet.http.post({
          type: 'core-read',
          query: `sin2:/v:b718738d-d427-4a96-bac4-e23233fb346c/?id=${ controlID }`,
          dateWork: new Date()                        
        });
        if (!!resp.error){
          throw resp.error;
        }
        if (resp.result.data.length < 1){
          throw {message: 'No data found'};
        }
        const ci = resp.result.columnIndexes,
              data= resp.result.data[0];
        const vi = {
                      id:   data[ci["vctripscontrol.id"]],
                      dt:   $moment(data[ci["vctripscontrol.eventdt"]]).toDate(),
                      lat:  data[ci["vctripscontrol.lat"]],
                      lon:  data[ci["vctripscontrol.lon"]],
                      vc: {
                          id: data[ci["vctripscontrol.vehicleid"]],
                          gov: data[ci["temptripscontroljoin.govnum"]]
                      }
        };
        vi.time = vi.dt.getTime();
        vi.dt1 = $moment(vi.dt).add(-5, 'm').toDate();
        vi.dt2 = $moment(vi.dt).add(5, 'm').toDate();
        vi.violation = {
          name: data[ci["vctripscontrol.typename"]],
          lat:  vi.lat,
          lon:  vi.lon
        }
        console.log('control =>', vi);
        jet.collections.open({id: MAP_ROUTES_GUID, name: "Карта"});
        setTimeout(async ()=>{
          try {
            const map = jet.collections.active.owner;
            const vehicle = await map.search(vi.vc.id);
            console.log('control (vehicle)=>', vehicle);
            map.set("track", {
                                vehicle: vehicle, 
                                start: vi.dt1, 
                                end: vi.dt2
            });
            map.set("fly", vi);
            map.set("popup", {
                vehicle: vehicle,
                point: vi
            });
          } catch(e){
            console.log('ERR (on viola)', e);
            jet.msg({text: "Не удается получить сведения о ТС"});
          }
        }, 500);
      } catch(e){
          console.log('ERR (on viola)', e);
          jet.msg({text: "Не удается получить детали нарушения"});
      }
    },              //showTripControl
    getTripInfo: function(tripId) {
      /**
       id: data.result.data[i][2], // scheduleId
       tripId: data.result.data[i][3], // tripId (nullable)
       route: e,
       n: data.result.data[i][5],
       vcId:data.result.data[i][11],
       vc: data.result.data[i][12],
       stateVc: data.result.data[i][7],
       fails: data.result.data[i][6],
       start: (!!data.result.data[i][13]) ? new Date(data.result.data[i][13]) : null,
       end: (!!data.result.data[i][14]) ? new Date(data.result.data[i][14]) : null,
       factStart: (!!data.result.data[i][15]) ? new Date(data.result.data[i][15]) : null,
       factEnd: (!!data.result.data[i][16]) ? new Date(data.result.data[i][16]) : null,
       statePlan: "unknown",
       stateStart: 0, // 0-none, 1-начат, 2-начат с опозд, 3-не начат
       stateEnd: 0 // 0-none, 1-, 2-начат с опозд
       */
    },
    showTripControl2: function(event) {
      //console.log('showTripControl2', event);
      var self = this,
        trip = event.trip;
      var _do_show = function() {
        self.showTripControl(trip);
      };
      if (!trip.n) {
        const uid = this.$store.state.profile.subject.id;
        jet.http.post('/rpc?d=jsonRpc', {
          type: 'query',
          query: '948e1fa3-414a-4568-b4ed-3a5535b239e9.dsp_getDspInfoFn',
          params: { attr: 4, uid: uid, id: trip.tripId },
        }).then((data) => {
          if ((data.result) && (data.result.data) && (data.result.data.length > 0)) {
            var raw = data.result.data[0];
            trip.id = raw[0][0]; // scheduleId
            trip.n = raw[0][1];
            _do_show();
          }
        });
      } else {
        _do_show();
      }
    },
    showFail: function(event /*trip & failure*/) {
      var r = event.route;
      if (r) {
        r.trip = event;
        this.openMap(r);
      }
    },
    onNatsMessageReceive: function(message) {
      if (!this.mapBox) {
        return;
      }
      var data;
      try {
        data = JSON.parse(message);
      } catch (e) {
        return;
      }
      var is_exists = false;
      for (let i = 0, count = FEATURES.length; i < count; i++) {
        var feature = FEATURES[i];
        if (feature.properties.deviceId === data.deviceId) {
          feature.geometry.coordinates = [data.lon, data.lat];
          feature.properties.heading = data.heading + 180 || 0;
          is_exists = true;
          break;
        }
      }
      if (!is_exists) {
        console.log('onNatsMessageReceive: ' + data.deviceId + ' is\'t a feature');
      }
      this.mapBox.getSource(SOURCE_VEHICLE_ID).setData({
        type: 'FeatureCollection',
        features: FEATURES,
      });
    },  //onNatsMessageReceive
    /**
     * open route only without fails
     */
    openMap2: async function(route) {
      route.trip = null;
      this.controlTrip = null;    //reset;
      this.openMap(route);
    },
    openMap: function(r) {
      var hasRouteReload = true;
      if ((!!this.mapRoute) && (!!r)) {
        hasRouteReload = (r.id !== this.mapRoute.id);
      }

      this.mapRoute = r;
      this.showMap = (!!r);

      if (!this.showMap) {
        if (ws_nats) {
          ws_sids.forEach((sid) => {
            sid.unsubscribe();
          });
          ws_sids = [];
        }
        if (this.mapBox) {
          this.mapBox.remove();
          this.mapBox = null;
        }
        FEATURES = [];
        return;
      }
      

      /*if (!ws_nats) {
        ws_codec = await jet.http.getJSONCodec();
        ws_nats = await jet.http.getNats(WS_URI);
      }*/

      var _mbx,
        self = this,
        trip = r.trip;
      var failure = (trip) ? trip.failure : null;

      //this.controlTrip = trip;

      var onMapVcClick = function(e) {
        var selected, features = self.mapBox.queryRenderedFeatures(e.point, { layers: ['vehicle-active'] });
        if ((features) && (features.length > 0)) {
          selected = features[0]; //.properties.vcId;
          var p = new mapboxgl.Popup({ closeOnClick: true }).setLngLat(e.lngLat).setHTML('Получение данных ТС...').addTo(self.mapBox);
          p.setMaxWidth('460px');
          self.mapBox.setCenter(e.lngLat);
          var nav_data, reg_data, geo_data,
            url = '/api/publicApi?call=lastVehicle&arg.ids=' + selected.properties.deviceId;
          var _set_popup_info = function() {
            var s = '';
            if (reg_data) {
              s = '<h3 style="border-bottom:1px solid #e0e0e0;"';
              if (nav_data) {
                var t = get_vc_status(nav_data.time);
                s += ' class="vc-status ' + ((t === 0) ? 'vc-success' : (t === 24) ? 'vc-warning' : 'vc-danger') + '"><b></b>';
              }
              s += reg_data[1] + '</h3>';
            }
            if (nav_data) {
              s += ((nav_data.speed) && nav_data.speed > 0) ? '<br />В движении, <b>скорость</b> ' + nav_data.speed + ' км/ч' : '<br /><b>стоит</b>';
              s += '<br /><b>время:</b> ' + self.theTime(new Date(nav_data.time));
              s += '<br /><b>ш / д:</b> ' + nav_data.lat + ' / ' + nav_data.lon;
              if (geo_data) {
                s += '<div>' + ((geo_data.road) ? geo_data.road : geo_data.display_name) + '</div>';
              }
            } else {
              s = 'данные движения не получены';
            }
            if (reg_data) {
              s += '<h3 style="margin-top:1rem;border-top:1px solid #e0e0e0;">';
              if (!!reg_data[3]) { //routeId
                s += 'маршрут № ' + reg_data[4];
              }
              if (!!reg_data[11]) { //scheduleId
                s += ', рейс № ' + reg_data[12] + ': ' + get_the_time(new Date(reg_data[13]), true) + '-' + get_the_time(new Date(reg_data[14]), true);
              } else {
                s += ' рейс не выполняется';
              }
            } else {
              s += '<br />получение дополнительной информации...';
            }
            if (!geo_data) {
              s += '<br />получение информации местонахождения...';
            }
            s += '<div style="text-align:right;"><a id="map-close-popup" href="javascript:void(0);">закрыть</a></div>';
            p.setHTML(s);
            document.querySelector('#map-close-popup').onclick = function(e) {
              e.preventDefault();
              p.remove();
            };
          };  //_fmt_msg
          jet.http.post(url, {}).then((res) => {
            nav_data = (res.length > 0) ? res[0] : false;
            _set_popup_info();
            jet.http.post('/rpc?d=jsonRpc', {
              type: 'query',
              query: '948e1fa3-414a-4568-b4ed-3a5535b239e9.dsp_getDspInfoFn',
              params: { attr: 3, uid: selected.properties.deviceId, id: selected.properties.deviceId },
            }).then((data) => {
              if ((data.result) && (data.result.data) && (data.result.data.length > 0)) {
                reg_data = data.result.data[0];
                //console.log(reg_data);
                _set_popup_info();
              }
            });
            $http.get('https://nominatim.openstreetmap.org/reverse?format=json&lat=' + nav_data.lat + '&lon=' + nav_data.lon + '&zoom=18&addressdetails=1').then((addr) => {
              geo_data = addr;
              _set_popup_info();
            }); //TODO: err-handler
          });     //TODO: err-handler
        }
      };      //onMapVcClick
      var onMapTrackClick = function(e) {
        // console.log(e);
        //console.log('FEATURE', e.features[0]);
        e.originalEvent.preventDefault();
        e.originalEvent.stopPropagation();
        var feature = e.features[0];
        if (_mbx.popup) {
          return;
        }
        var s = '<h3>Маршрут № ' + r.code + ', рейс ' + trip.n + '</h3>';
        s += '<div style="border-bottom:1px solid #e0e0e0;margin-bottom:1rem;">' + r.org.name + '</div>';
        s += '<b>Время по расписанию</b>: ' + get_the_time(trip.start, true) + ' - ' + get_the_time(trip.end, true);
        s += '<br /><b>ТС</b>: ' + trip.vc;
        s += '<br /><b>время события</b>: ' + get_the_time(feature.properties.time, true);
        s += '<br /><b>ш / д</b>: ' + Number(feature.properties.lat).toFixed(4) + ' / ' + Number(feature.properties.lon).toFixed(4);
        s += '<br /><b>скорость</b>: ' + Math.round(feature.properties.speed) + 'км/ч';
        s += '<br /><b>число спутников</b>: ' + feature.properties.sats;
        s += '<div id="map-geo-position"></div>';
        s += '<div style="text-align:right;margin-top:1rem;"><a id="map-close-popup" href="javascript:void(0);">закрыть</a></div>';
        _mbx.popup = new mapboxgl.Popup({ closeOnClick: true }).setLngLat(e.lngLat).setHTML(s).addTo(_mbx);
        _mbx.popup.setMaxWidth('460px');
        _mbx.popup.on('close', function(e) {
          _mbx.popup = null;
        });
        _mbx.popup.getElement().querySelector('#map-close-popup').onclick = function(e) {
          _mbx.popup.remove();
        };
        $http.get('https://nominatim.openstreetmap.org/reverse?format=json&lat=' + feature.properties.lat + '&lon=' + feature.properties.lon + '&zoom=18&addressdetails=1').then((addr) => {
          _mbx.popup.getElement().querySelector('#map-geo-position').innerHTML = ((addr.road) ? addr.road : addr.display_name);
        }); //TODO: err-handler
        return false;
      };      //onMapTrackClick

      var routeOnMap = function() {
        //console.log('onMapLoaded.routeOnMap', r);

        _mbx = self.mapBox;
        /** TODO: render on fly to canvas
         var busIcon = {
                width: 48,
                height: 48,
                data: new Uint8Array(48 * 48 * 4),
                onAdd: function(){
                    var canvas = document.createElement('canvas');
                    canvas.width = this.width;
                    canvas.height = this.height;
                    this.context = canvas.getContext('2d');
                },
                render: function(){
                    this.context.drawImage(busImage, 0, 0);
                    _mbx.triggerRepaint();
                    return true;
                }
            };  //busIcon
         */

        if (!_mbx.hasImage('bus')) {
          //_mbx.loadImage('/imgs/marker-blue.png', function(err, _img){ //TODO: from resource
          /*
          _mbx.loadImage('/imgs/bus-pointer.png', function(err, _img){ //TODO: from resource
              _mbx.addImage('bus', _img);
          });
          */
          var busImage = new Image();
          busImage.onload = function() {
            _mbx.addImage('bus', busImage);
          };
          busImage.src = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAwCAMAAABHcohdAAAACXBIWXMAABYlAAAWJQFJUiTwAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAACBUExURQAAAABAgAA4hwA6igA8hwA9hgA+hgA6hQA6hwA7hgA8hQA9hwA8hQA8hgA7hwA7hgA7hwA8hwA8hwA8hgA8hwA7hwA9hwA8hwA8hxBIjiBUlkBspUBtpU95rVB4rFB5rGCEtGCFtJCpypCpy5Cqy5+10p+20qC20r/O4d/n8P///xmBCRIAAAAYdFJOUwAQIDBAUF9gYHCAj5CQn6Cgr7/Pz9/f7+/rzPoAAAFISURBVDjLjZPBYoIwEEQ3aLWVQq2CeUFaa0sR8/8f2AOgIQboXOdls9nMityk1uleg96nKyWPihLNXWnk2SrGUzyoEh14UO4UWXS++arquvosOmLhnT82tlNzHNRQBwDzax3VBiBv+4gBiqsd6FoAxCIiEYDxfGuvBiASkS3AxT6oBngVUQBHG1AJaCXPAE0IaADWsgUKG5QBUsmAUxg4A5lo4DsM/ABaRt5we8c8MHtFBpzDwAeQyRtgwkABpHODWonSQDk2apRIMvVZ6X++uy0RDkziRm5wy8WNXB/a8h7a0gt+H/viVNV1dTZ+7OcXR0Qlvp/4Cxxl48dbPbnAKrD/yln/XEJy2kiDwPIOLIKA7Ho/C/vyMtXioM1oBJD3qRadNpejgOjxIbTaAGwmADXZYjeK3ZQvT+ND6EeRy7SSdAZY+v/0BxaSczEwcQaDAAAAAElFTkSuQmCC';
        }
        if (!_mbx.hasImage('arrow-direction-blue-')) {
          var arrImage = new Image();
          arrImage.onload = function() {
            _mbx.addImage('arrow-direction-blue', arrImage);
          };
          arrImage.src = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAWCAMAAACfbM4MAAAACXBIWXMAABYlAAAWJQFJUiTwAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAB1UExURQAAAP///////////////////////////////////////////////////////////////wA8hxBIjiBUljBhnkBspUBtpVB5rGCEtGCFtG+RvH+dw5Cqy5+20qC20q/C2b/O4c/a6M/a6d/m8N/n8O/z9////x9WmkkAAAARdFJOUwAQIDBAUF9gb4CQn6+/z9/vdefXpQAAAK9JREFUKM+Nk9kWgiAURcVICTKJpAEtaeL/PzG42IPG0Hnea53tuVi0FBfZGGNatkY56GU501RlEtp3SgNHVgnI5nAZ31CME5DL6eaKOatQArI5Dveg4AyCYhDczZZZQjbiRzAAQbEX3PriCGTTKS9IygQke52B5OC0eFOjmPj5CgTFKDbBdKNNdALZP74a4THFpMHq2FmE0rAfjR44qLGAnvCtBKWfL2c483z/+RE+Jf8lNd4uTUIAAAAASUVORK5CYII=';
          /*
                          _mbx.loadImage('/imgs/arrow-direction.png', function(err, _img){ //TODO: from resource
                              _mbx.addImage('arrow-direction', _img);
                          });
          */
        }
        if (!_mbx.hasImage('fail')) {
          var failImage = new Image();
          failImage.onload = function() {
            _mbx.addImage('fail', failImage);
          };
          failImage.src = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAuCAMAAAB+ruu2AAAACXBIWXMAAA3XAAAN1wFCKJt4AAAAB3RJTUUH4wwOCgEceaaxZgAAAB1pVFh0Q29tbWVudAAAAAAAQ3JlYXRlZCB3aXRoIEdJTVBkLmUHAAABhlBMVEUAAAACAgIEAwQ2BwSUERCfFBC5FhPAFhPMGBXMGRXNGBTOGBXkGxb/Hhr/My8AAAAAAAC3FxMSEhIVFRWmFBKrFRGfEw66FhITFBkdHR26FhMTExP/yGLKGBUhISG/FhPIFxWoEhDLGBQVFRX1tF/1tWD2tWB4aUTNGRXNGBXeGhYnJyfZGRZtWTvFGBTQGRU0MS4DBxAtLC3QGRUvLy8uLS4vLi8vLy/SGRUgHyDVGRXDFxTeVDHQGRXTGRa7FhPVGRXEFxXRGRUnJyfOGBQwMDDJFxPIGBS8FhI4NzjVGRY7Ojs7OjvPGRU8Ozw8OzxbTz/WGhbSGRXSGRXWMyHUGRXUGRXvz3vYGhbZGhb31YD21H/QGRXfwHTZGhbbGhbZGRXaGhbdGhbZGhbZGhbaGhbWGhbaGhbZGhbYGhbruWnZGhbaGhb3w23aGhbttGX0v2vaGhbZGRbaGhbaGhbdLB7TGRXYGRbZGhbaGhbbGhbcGhbdGhbdKx7eLB71uWf1uWj///8iJSF2AAAAdnRSTlMAAAAAAAAAAAAAAAAAAAABAgUHCAgJEBARExkaGhscHBwdHh8fHx8lJSgpLC8zNDQ5Ojo8PT4+PkJES05QU1NZXV5eX212enx/i4yRkpKTlJmaoamps729v7+/wcrM0NDV1dbZ3OHi5Ozt7e7w8fb29/n7/P3+bRhIYwAAAAFiS0dEgRK6rv4AAAD8SURBVDjLY2BgYAjPD7HTBNKsDDhAdENdVWGEEwMDMy8uBaVVIOChJQ7kseBWUF2cGiqGzwSQmqo0K3l+PApAajLj3fAqAIKaMi9jEQYGTpwKgKAiOVCPgYEPtwKQXTG2GngVAEFenDVeBdlJ9vhMSLdQwO3IkpQgKdzerCl31xFlYODAoSAn0gUoxo09oKqrwhyUGBh4cIRkZWKwIgMDF47Iqi7y1RdkYGDHGpv1tRkJjgy4QXSBjSyQYsOtIFeNAS+IzlUZVTCqYKgpUIUwmHAoiMqVwaOdX9owNivA28/fx9PV2VJZAFOFkIS6roGpuYmZkbacpDAjujQAMZGtBmEkh8gAAAAASUVORK5CYII=';
        }

        //Map sources
        var _srcs = [SOURCE_ROUTE_ID, SOURCE_STOPS_ID, SOURCE_VEHICLE_ID, SOURCE_TRACK_ID, SOURCE_FAILS_ID];
        for (var i = 0; i < _srcs.length; i++) {
          if (!_mbx.getSource(_srcs[i])) {
            _mbx.addSource(_srcs[i], { type: 'geojson', data: { type: 'FeatureCollection', features: [] } });
          }
        }
        if (!_mbx.getLayer('route')) {
          _mbx.addLayer({
            id: 'route',
            type: 'line',
            source: SOURCE_ROUTE_ID,
            paint: { 'line-color': ['step', ['get', 'color'], '#EA8C00', 1, '#19A3F0'], 'line-width': 3 },
            layout: { 'line-cap': 'round', 'line-join': 'round' },
          });
        }
        if (!_mbx.getLayer('bus-stop')) {
          _mbx.addLayer({
            id: 'bus-stop',
            type: 'circle',
            source: SOURCE_STOPS_ID,
            paint: { 'circle-radius': 8, 'circle-color': '#808C9A' },
          });
          _mbx.addLayer({
            id: 'bus-stop-name',
            type: 'symbol',
            source: SOURCE_STOPS_ID,
            layout: {
              'text-field': '{name}',
              'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
              'text-size': 16,
              'text-offset': [0, 0.5],
              'text-anchor': 'top',
            },
          });
        }
        if (!_mbx.getLayer('track')) {
          _mbx.addLayer({
            id: 'track',
            type: 'line',
            source: SOURCE_TRACK_ID,
            paint: { 'line-color': '#546e7a', 'line-width': 4 },
            layout: { 'line-cap': 'round', 'line-join': 'round' },
            filter: ['in', '$type', 'LineString'],
          });
          _mbx.addLayer({
            id: 'track-direction',
            type: 'symbol',
            source: SOURCE_TRACK_ID,
            layout: {
              'symbol-spacing': 100,
              'icon-ignore-placement': true,
              'icon-rotate': ['get', 'heading'],
              'icon-rotation-alignment': 'map',
              'icon-allow-overlap': true,
              'icon-image': 'arrow-direction-blue',
              'icon-size': 0.5,
              'visibility': 'visible',
            },
            filter: ['in', '$type', 'Point'],
          });
        } else {
          _mbx.getSource(SOURCE_TRACK_ID).setData({ type: 'FeatureCollection', features: [] });
        }
        if (!_mbx.getLayer('vehicle-active')) {
          _mbx.addLayer({
            id: 'vehicle-active',
            type: 'symbol',
            source: SOURCE_VEHICLE_ID,
            minzoom: 0,
            maxzoom: 24,
            layout: {
              'icon-image': 'bus',
              'icon-size': 0.8,
              'icon-ignore-placement': true,
              'icon-rotate': ['get', 'heading'],
              'icon-rotation-alignment': 'map',
              'text-field': ['get', 'title'],
              'text-offset': [1, 2],
              'text-anchor': 'left',
              'text-size': 12,
              'text-max-width': 40,
            },
          });
        }
        if (!_mbx.getLayer('fails')) {
          _mbx.addLayer({
            id: 'fails',
            type: 'symbol',
            source: SOURCE_FAILS_ID,
            minzoom: 0,
            maxzoom: 24,
            layout: {
              'icon-image': 'fail',
              'icon-size': 1,
              'icon-ignore-placement': true,
              'icon-anchor': 'bottom',
              'icon-offset': [12, 0],
              'text-field': ['get', 'title'],
              'text-offset': [1, 2],
              'text-anchor': 'left',
              'text-size': 12,
              'text-max-width': 40,
            },
            paint: {
              'text-color': '#d50000',
              'text-halo-color': '#EF9A9A',
            },
          });
        }

        if (r.id === 'stub') {
          return;
        }

        //route point's
        if (hasRouteReload) {
          jet.http.get(`/api/publicApi?call=route&arg.route=${r.id}`).then((data) => {
            const points = data.points;
            const routeParts = [];
            const lineFeatures = [];
            const busStopFeatures = [];
            const bounds = new mapboxgl.LngLatBounds([points[0].lon, points[0].lat], [points[0].lon, points[0].lat]);
            for (var i = 0, size = points.length; i < size; i++) {
              const { lat, lon, pointType, name } = points[i];
              const counRouteParts = routeParts.length;
              if (pointType === 'END_STOP') {
                const feature = {
                  type: 'Feature',
                  properties: { name },
                  geometry: { type: 'Point', coordinates: [lon, lat] },
                };
                const routePart = [];
                if (counRouteParts > 0) {
                  routeParts[counRouteParts - 1].push([lon, lat]);
                } else {
                  routePart.push([lon, lat]);
                }
                routeParts.push(routePart);
                busStopFeatures.push(feature);
              } else {
                const prevPoint = points[i - 1];
                routeParts[counRouteParts - 1].push([prevPoint.lon, prevPoint.lat]);
                routeParts[counRouteParts - 1].push([lon, lat]);
              }
              bounds.extend([lon, lat]);
            }
            var j = 0;
            for (var i = 0, count = routeParts.length; i < count; i++) {
              const feature = {
                type: 'Feature',
                properties: {},
                geometry: { type: 'LineString', coordinates: routeParts[i] },
              };
              var color;
              if (count > 3) {
                if (i % 2) {
                  color = '#000000';
                } else {
                  color = j % 2 ? 0 : 1;
                  j++;
                }
              } else {
                color = i % 2 ? 0 : 1;
              }
              feature.properties.color = color;
              lineFeatures.push(feature);
            }
            self.mapBox.getSource(SOURCE_ROUTE_ID).setData({ type: 'FeatureCollection', features: lineFeatures });
            self.mapBox.getSource(SOURCE_STOPS_ID).setData({ type: 'FeatureCollection', features: busStopFeatures });
            self.mapBox.fitBounds(bounds, { padding: 80 });
            setTimeout(function(){
                self.mapBox.resize();
            }, 300);
          }); //TODO: error handle
        }
        var _show_vc_onmap = function(ids) {
          var params = new URLSearchParams();
          params.append('call', 'lastVehicle');
          params.append('arg.ids', ids.join(','));
          jet.http.post('/api/publicApi', { data: params }).then((res) => {
            const vehicles = [];
            for (var i = 0, size = res.length; i < size; i++) {
              var vehicle = res[i];
              vehicle.satus = get_vc_status(vehicle.time);
              vehicles.push(vehicle);
            }
            for (var i = 0; i < vehicles.length; i++) {
              var feature = {
                type: 'Feature',
                properties: {
                  vcId: vehicles[i].id,
                  deviceId: vehicles[i].deviceId,
                  heading: vehicles[i].heading + 180,
                  status: vehicles[i].status,
                },
                geometry: { type: 'Point', coordinates: [vehicles[i].lon, vehicles[i].lat] },
              };
              FEATURES.push(feature);
            }
            _mbx.getSource(SOURCE_VEHICLE_ID).setData({ type: 'FeatureCollection', features: FEATURES });
          });
          _mbx.on('click', 'vehicle-active', onMapVcClick);
          jet.msg({ text: 'На маршруте №' + r.code + ' ' + ids.length + ' ТС', color: 'warning' });
          for (var i = 0; i < ids.length; i++) {
            var sid = ws_nats.subscribe(`PUBLIC.kigat.telemetry.${ids[i]}`);
            (async ()=>{
              for await (const m of sid) {
                try{
                  var msg = ws_codec.decode(m.data);
                  self.onNatsMessageReceive.bind(msg);
                } catch(e){
                  console.log('ERR (on_m_recieve):', e);
                }
              }
            })();
            ws_ids.push(sid);
          }
        };  //_show_vc_onmap
        if (!!trip) {
          //One Vc on current trip
          if (trip.vcId) {
            //VC-track loading
            var _ds = new Date(),
              _de = new Date(),
              _t;
            if ((!!failure) && (failure.timeOut)) {
              _t = failure.timeOut.split(':');
              _ds.setHours(_t[0]);
              _ds.setMinutes(_t[1] - 10);
            } else {
              _ds.setMinutes(-10);
            }
            console.log('failure at: ', _t, _ds);
            if ((!!failure) && (failure.timeIn)) {
              _t = failure.timeIn.split(':');
              _de.setHours(_t[0]);
              _de.setMinutes(_t[1] + 10);
            } else {
              _de = new Date(trip.end.getTime());
              _de.setMinutes(_de.getMinutes() + 10);
            }
            console.log('failure to: ', _t, _de);

            jet.http.get('/api/publicApi?call=history&arg.ids=' + trip.vcId +
              '&arg.start=' + _ds.getTime() + '&arg.end=' + _de.getTime(), {}).then(function(track) {
              if (track.length > 0) {
                var points = track[0].points,
                  coords = [];
                var data = {
                  type: 'FeatureCollection',
                  features: [],
                };
                for (var i = 0, count = points.length; i < count; i++) {
                  coords.push([points[i].lon, points[i].lat]);
                  points[i].heading = (points[i].heading || 0) - 90;
                  data.features.push({
                    type: 'Feature',
                    properties: points[i],
                    geometry: { type: 'Point', coordinates: [points[i].lon, points[i].lat] },
                  });
                }
                data.features.push({
                    type: 'Feature',
                    geometry: {
                      type: 'LineString',
                      coordinates: coords,
                    },
                  },
                );
                _mbx.getSource(SOURCE_TRACK_ID).setData(data);
                _mbx.on('click', 'track-direction', onMapTrackClick);

                jet.msg({
                  text: 'Трек "' + trip.vc + '" за время: ' + get_the_time(_ds, true) + '-' + get_the_time(_de, true),
                  color: 'primary',
                  timeout: 30000,
                });
              } else {
                jet.msg({
                  text: 'Данные трека "' + trip.vc + '" за время: ' + get_the_time(_ds, true) + '-' + get_the_time(_de, true) + ' не получены',
                  color: 'warning',
                  timeout: 30000,
                });
                console.log('MAP: show track - no points', track);
              }
            }); //TODO: err-hande or not load
          } else {
            jet.msg({
              text: 'На маршруте №' + r.code + ', рейсе № ' + trip.n + ' нет ТС',
              color: 'warning',
              timeout: 30000,
            });
          }
        } else {
          //Get vehicle's from route ALL-trips
          jet.http.post('/rpc?d=jsonRpc', {
            type: 'query',
            query: '948e1fa3-414a-4568-b4ed-3a5535b239e9.dsp_getDspInfoFn',
            params: { attr: 1, uid: r.org.id, id: r.id },
          }).then((data) => {
            console.log('route vehicles', data);
            var ids = [];
            if (data.result) {
              for (var i = 0; i < data.result.data.length; i++) {
                var _id = data.result.data[i][11];
                if ((_id) && (ids.indexOf(_id) < 0)) {
                  ids.push(_id);
                }
              }
            }
            if (ids.length < 1) {
              jet.msg({ text: 'На маршруте №' + r.code + ' нет ТС', color: 'warning' });
              return;
            }
            _show_vc_onmap(ids);
          });
        }
        if (!!failure) {
          if (failure.coordsOut) {
            var ll = failure.coordsOut.replace(' ', '').split(',');
            var feature = {
              type: 'Feature',
              properties: {
                failure: failure,
                title: ((failure.timeOut) ? failure.timeOut + ': ' : '') + failure.t_name,
              },
              geometry: { type: 'Point', coordinates: ll },
            };
            _mbx.getSource(SOURCE_FAILS_ID).setData({ type: 'FeatureCollection', features: [feature] });
            _mbx.setCenter(ll);
            //_mbx.flyTo({center:ll});
            //self.mapBox.on('click', 'vehicle-active', onMapVcClick);

            jet.http.post('/rpc?d=jsonRpc', {
              type: 'query',
              query: '948e1fa3-414a-4568-b4ed-3a5535b239e9.dsp_getDspInfoFn',
              params: { attr: 5, uid: failure.id, id: failure.id },
            }).then((data) => {
              console.log('Last failure event:', data);
              if ((data.result) && (data.result.data.length > 0)) {
                var raw = data.result.data[0];
                var feature = {
                  type: 'Feature',
                  properties: {
                    vcId: raw[0],
                    deviceId: raw[1],
                    heading: raw[6] + 180,
                    speed: raw[7],
                    tm: new Date(raw[13]),
                    status: '',
                    title: self.theTime(new Date(raw[13])) + ': ' + raw[12],
                  },
                  geometry: { type: 'Point', coordinates: [raw[4], raw[5]] },
                };
                _mbx.getSource(SOURCE_VEHICLE_ID).setData({ type: 'FeatureCollection', features: [feature] });
                var bounds = new mapboxgl.LngLatBounds(ll, feature.geometry.coordinates);
                _mbx.fitBounds(bounds, { padding: 80 });
              }
            });
          }   //if (failure)
        }
      };  //routeOnMap

      var _n = 0,
        map_init = function() {
          if ((typeof mapboxgl === 'undefined') || (!!self.mapBox)) {
            return;
          }
          var place = document.querySelector('#route-map');
          if (!place) {
            return;
          }
          //map_conte.style.height = (map_conte.parentNode.clientHeight - 64)+"px";
          self.mapBox = new mapboxgl.Map({
            container: place,
            style: 'https://api.maptiler.com/maps/66edf92d-a15d-4b0f-b23f-cc81ef9717a0/style.json?key=vsrA3Oos6Qo35cVMWMTT',
            center: [38.97603, 45.04484],
            zoom: 8,
            maxZoom: 18,
            pitchWithRotate: false,
          });
        },
        _map_watch = function() {
          _n++;
          if (_n > 100) {
            jet.msg({ text: 'Карта недоступна - попробуйте вызвать позднее', color: 'warning', timeout: 6000 });
            return;
          }
          if ((self.mapBox) && self.mapBox.loaded()) {
            _mbx = self.mapBox;
            routeOnMap();
          } else {
            map_init();
            setTimeout(_map_watch, 300);
          }
        };
      self.$nextTick(_map_watch);
    },   //openMap
    /**
     * check visible elem on search
     */
    isVisi: function(item) {
      if ((!this.searchVal) || /^$/.test(this.searchVal)) {
        this.activeOrgIndex = -1;
        return true;
      }
      var res = false,
        re1 = new RegExp('^' + this.searchVal + '+', 'gi'),
        re2 = new RegExp(this.searchVal + '+', 'gi');

      if (re2.test(item.name) || re1.test(item.code)) {
        res = true;
      } else if (item.routes) { //a org
        for (var i = 0; i < item.routes.length; i++) {
          if (re2.test(item.routes[i].name) || re1.test(item.routes[i].code)) {
            res = true;
            break;
          }
        }
      }
      if ((res) && (this.activeOrgIndex < 0)) {
        this.$nextTick(() => {
          var org = (!!item.org) ? item.org : item;
          for (var i = 0; i < this.orgs.length; i++) {
            if (org.id === this.orgs[i].id) {
              this.activeOrgIndex = i;
              break;
            }
          }
        });
      }
      return res;
    },   //isVisi
  },
  watch: {
    searchInpVal: function(newVal) {
      if (this.hSearchTimer) {
        clearTimeout(this.hSearchTimer);
        this.hSearchTimer = null;
      }
      var self = this;
      this.hSearchTimer = setTimeout(function() {
        self.hSearchTimer = null;
        self.searchVal = newVal;
      }, 1500);
    },
  },
  computed: {
    /**
     * for Fails-list visible on map
     */
    showFails() {
      return (!!this.mapRoute) && (this.mapRoute.trip) && (this.mapRoute.trip.fails);
    },
    orgItems() {
      if (this.searchInpVal !== null && this.searchInpVal !== '') {
        const regex = new RegExp(`${this.searchInpVal}`, 'i');

        const result = [];

        for (const org of this.orgs) {
          if (regex.test(org.code) || regex.test(org.name)) {
            result.push(org);

            continue;
          }

          const _org = _copy(org);
          _org.routes = org.routes.filter(route => {
            return regex.test(route.code) || regex.test(route.name);
          });

          if (_org.routes.length) {
            const index = result.findIndex(it => it.id === _org.id);

            if (index === -1) {
              result.push(_org);
            } else {
              result.splice(index, 1, _org);
            }
          }
        }

        return result;
      }

      return this.orgs;
    },
  },
  template: '<v-container class="jet-routes-conte" fluid align-start>\
                    <v-row v-if="(queryMode<0)" class="dsp-error">\
                        <v-col cols="12"><h3 class="mb-3">Ошибка</h3>{{error}}</v-col>\
                    </v-row>\
                    <v-row v-else-if="(queryMode===0)">\
                        <v-col cols="12"><h3 class="mb-3">Получение списка контролируемых маршрутов...</h3>\
                            <v-progress-linear active bottom indeterminate rounded color="primary"></v-progress-linear>\
                        </v-col>\
                    </v-row>\
                    <v-row v-else-if="(queryMode===1)" class="justify-space-between align-center" dense>\
                        <v-col><h3 class="m-3">Перевозчики</h3></v-col>\
                        <v-col cols="auto" align="right">\
                         <v-select v-if="isModerator" v-model="onlyMyRoute" :items="modeRoute" item-text="title" return-object style="width: 90px" outlined small hide-details dense @change="refresh" />\
                        </v-col>\
                        <v-col cols="auto"><v-text-field class="dsp-search" type="search" v-model="searchInpVal" @click:clear="searchInpVal = null" small hide-details dense placeholder="Поиск" single-line solo flat outlined clearable append-icon="mdi-magnify" height="1" /></v-col>\
                    </v-row>\
                    <v-row v-if="(queryMode===1)" class="justify-space-between align-center" dense style="color:#7a7a7a;font-size:0.9rem;">\
                        <v-col>\
                            <div class="d-flex justify-start align-center">\
                               <div class="ma-3">Выполнение транспортной работы по состоянию на {{ theTime(queryDate) }}</div>\
                               <div><v-btn v-on:click="refresh" small depressed color="primary" dark><v-icon small>fas fa-sync</v-icon>&nbsp;обновить</v-btn></div>\
                            </div>\
                        </v-col>\
                        <v-col cols="auto" class="dsp-legend">\
                            <b>запланировано</b>: <i class="dsp-none"></i>Нет&nbsp;&nbsp;&nbsp;<i class="dsp-part"></i>Частично&nbsp;&nbsp;&nbsp;<i class="dsp-full"></i>Полностью\
                        </v-col>\
                    </v-row>\
                    <v-expansion-panels accordion focusable class="jet-routes-list" v-model="activeOrgIndex">\
                      <v-expansion-panel v-for="item in orgItems" :key="item.id" v-on:change="orgChange" v-show="isVisi(item)" :data-org-id="item.id">\
                        <v-expansion-panel-header>\
                            <v-container fluid>\
                                <v-row dense class="justify-space-between align-items-center">\
                                    <v-col cols="auto">{{ item.code }}.&nbsp;{{ item.name }}</v-col>\
                                    <v-col cols="auto" class="dsp-org-planing" v-bind:class="planingClass(item)">\
                                        <div class="d-flex align-items-center">\
                                            <svg v-if="((item.fails)&&(item.fails>0))" viewBox="0 0 576 512" style="width:20.25px;height:18px;color:#EF5350;"><use xlink:href="#icon-warning" /></svg>\
                                            <div class="dsp-val">{{ item.routes.length }}</div>\
                                        </div>\
                                    </v-col>\
                                </v-row>\
                            </v-container>\
                        </v-expansion-panel-header>\
                        <v-expansion-panel-content class="pt-3">\
                            <!--======================================== Dep(s) of active route ===================================-->\
                            <v-container fluid class="dsp-route-conte">\
                                <v-row v-if="(activeRoute)" dense class="jet-route" :key="activeRoute.id">\
                                    <v-col cols="auto">\
                                        <v-btn tile outlined title="Назад к списку маршрутов" class="back" \
                                               v-on:click="back" fab><v-icon color="#9bafb8">fa-chevron-left</v-icon></v-btn>\
                                    </v-col>\
                                    <v-col class="align-self-center route-title">\
                                        <div class="d-flex align-center">\
                                            <div class="route-code">{{ activeRoute.code }}</div>\
                                            <div>{{ activeRoute.name }}\
                                                <div class="text-muted" v-if="(activeRoute.deps.length > 1)">выезды по маршруту</div>\
                                                <div class="text-muted" v-else-if="((activeRoute.deps.length==1)&&(activeRoute.deps[0].id!==\'stub\'))">выезд № {{activeRoute.deps[0].n}}</div>\
                                                <div class="text-muted" v-else>рейсы маршрута</div>\
                                            </div>\
                                        </div>\
                                    </v-col>\
                                    <v-col v-if="(activeRoute.deps.length > 0)" cols="12">\
                                        <v-sheet v-if="(activeRoute.deps.length > 1)" class="route-deps-conte" elevation="0">\
                                            <v-slide-group show-arrows center-active>\
                                                <v-slide-item v-for="(dep, index) in activeRoute.deps" :key="dep.id" v-slot:default="{active, toggle}" active-class="active">\
                                                    <v-card class="dep-card" v-bind:class="planingDepClass(dep)" outlined flat v-on:click="openDep(dep)" @click="toggle">\
                                                        <v-card-title>\
                                                            <div class="d-flex justify-space-between align-items-center w-100">\
                                                                <div class="dep-code">{{ dep.n }}</div>\
                                                                <div class="dep-status align-self-center">{{ dep.trips.length + " / " + (dep.trips.length-dep.bads) }}</div>\
                                                            </div>\
                                                        </v-card-title>\
                                                        <v-card-text class="d-flex flex-column justify-space-between align-stretch">\
                                                            <div v-if="dep.avhs.length>0" class="dep-vehicles">\
                                                                <div v-for="(vc) in dep.avhs">{{vc}}</div>\
                                                            </div>\
                                                            <div v-else class="dep-vehicles">не запланирован</div>\
                                                            <div class="d-flex justify-space-between">\
                                                                <div class="dep-time">\
                                                                    <svg viewBox="0 0 516 512" style="width:16px;height:16px;"><use xlink:href="#icon-clock" /></svg>\
                                                                    &nbsp;{{theTime(dep.start,1)}} - {{theTime(dep.end,1)}}\
                                                                </div>\
                                                                <div>\
                                                                    <svg v-if="(dep.rqsst>0)" title="Остановки по требованию" viewBox="0 0 512 512" style="margin-left:0.25rem;width:18px;height:18px;color:#c4c4c4s;"><use xlink:href="#icon-request" /></svg>\
                                                                    <svg v-if="(dep.fails>0)" title="Нарушения" viewBox="0 0 576 512" style="width:20.25px;height:18px;color:#EF5350;margin-left:0.25rem;"><use xlink:href="#icon-warning" /></svg>\
                                                                </div>\
                                                            </div>\
                                                        </v-card-text>\
                                                    </v-card>\
                                                </v-slide-item>\
                                            </v-slide-group>\
                                        </v-sheet>\
                                        <!-- ============================ Trips of active dep =========================================-->\
                                        <v-expand-transition>\
                                            <v-sheet v-if="(!!activeDep)" color="grey lighten-4" class="route-trips-conte">\
                                                <h3 class="dsp-trips-head"><div v-if="(activeDep.id!==\'stub\')" class="dep-code">{{activeDep.n}}</div>Рейсы</h3>\
                                                <v-row class="mt-3 mb-3">\
                                                    <v-col cols="auto" v-for="trip in activeDep.trips" :key="trip.id+\'_trip\'">\
                                                        <v-card class="trip-card d-flex flex-column justify-space-between" :data-trip-id="(trip.tripId||trip.id)">\
                                                            <v-card-title class="d-flex justify-space-between">\
                                                                <div class="trip-code">{{trip.n}}</div>\
                                                                <div class="trip-state" v-if="(trip.stateEnd===1)">завершен <svg viewBox="0 0 512 512" style="color:#a1d028;"><use xlink:href="#icon-check-ok" /></svg></div>\
                                                                <div class="trip-state" v-else-if="(trip.stateEnd===2)">завершен с опозд. <svg viewBox="0 0 512 512" style="color:#ed9511;"><use xlink:href="#icon-check-ok" /></svg></div>\
                                                                <div class="trip-state" v-else-if="(trip.stateEnd===3)">сорван <svg viewBox="0 0 512 512" style="color:#f07C91;"><use xlink:href="#icon-fail" /></svg></div>\
                                                                <div class="trip-state" v-else-if="(trip.stateStart===1)">выполняется <svg viewBox="0 0 512 512"><use xlink:href="#icon-clock" /></svg></div>\
                                                                <div class="trip-state" v-else-if="(trip.stateStart===2)">начат с опозд. <svg viewBox="0 0 512 512" style="color:#ed9511;"><use xlink:href="#icon-clock" /></svg></div>\
                                                                <div class="trip-state" v-else-if="(trip.stateStart===3)">НЕ начат <svg viewBox="0 0 576 512" style="color:#f07C91;"><use xlink:href="#icon-hex-fail" /></svg></div>\
                                                            </v-card-title>\
                                                            <v-card-text>\
                                                                <div v-if="(!!trip.vc)" class="vc-gov" >\
                                                                    <span v-if="(trip.stateVc<0)" class="vc-link no-link"></span>\
                                                                    <span v-else-if="((trip.stateVc>=0) && (trip.stateVc<=2))" class="vc-link"></span>\
                                                                    <span v-else-if="(trip.stateVc>2)" class="vc-link after2"></span>\
                                                                    {{trip.vc}}\
                                                                </div>\
                                                                <div class="vc-gov" v-else>не запланирован</div>\
                                                                <div class="trip-times">{{theTime(trip.start,1)}} - {{theTime(trip.end,1)}}\
                                                                <span v-if="(trip.deviation>0)" class="deviation">&plusmn;{{trip.deviation}} мин.</span></div>\
                                                            </v-card-text>\
                                                            <v-card-actions class="d-flex justify-space-between">\
                                                                <v-btn link text x-small v-on:click.stop.prevent="sheduleTrip(trip)" style="color:#003C87;"><svg viewBox="0 0 512 512" style="width:16px;height:16px;"><use xlink:href="#icon-list" /></svg>&nbsp;расписание</v-btn>\
                                                                <v-btn v-show="(trip.fails>0)" link text x-small v-on:click.stop.prevent="showTripControl(trip)">нарушения&nbsp;<svg viewBox="0 0 576 512" style="color:#f07C91;width:18;height:16px;"><use xlink:href="#icon-warning" /></svg>\</v-btn>\
                                                                <svg v-if="(trip.rqsst>0)" title="Остановки по требованию" viewBox="0 0 512 512" style="margin:0 0.25rem;width:18px;height:18px;color:#c4c4c4s;"><use xlink:href="#icon-request" /></svg>\
                                                            </v-card-actions>\
                                                        </v-card>\
                                                    </v-col>\
                                                </v-row>\
                                            </v-sheet>\
                                        </v-expand-transition>\
                                    </v-col>\
                                </v-row><!--.jet-route-->\
                                <!--============================================= ROUTES ==================================================-->\
                                <v-row v-else dense class="jet-routes">\
                                    <v-col cols="auto" class="w-25" v-for="route in item.routes" :key="route.id" :data-route-id="route.id" v-show="isVisi(item)">\
                                        <v-hover><template v-slot="{ hover }">\
                                            <v-card class="route-card" outlined :elevation="hover ? 8 : 0" v-on:click.stop="openRoute(item, route)">\
                                                <v-card-title>\
                                                    <div class="route-code">{{ route.code }}</div>\
                                                    <div><v-btn text small style="color:#1e5596;" v-on:click.stop.prevent="openMap2(route)">на карте&nbsp;<svg viewBox="0 0 384 512" style="height:1rem;width:0.75rem;"><use xlink:href="#icon-map-point" /></svg></v-btn></div>\
                                                </v-card-title>\
                                                <v-card-text>\
                                                    <div class="route-name">{{ route.name }}</div>\
                                                    <div class="route-states d-flex justify-space-between align-center">\
                                                        <div v-bind:class="planingClass(route)">\
                                                            <div class="v-chip dsp-val">{{route.sche}} / {{route.plan}}</div>\
                                                            <div class="v-chip dsp-fact">{{route.fact1}} / {{route.fact2}}</div>\
                                                        </div>\
                                                        <div>\
                                                            <svg v-if="((route.rqsst)&&(\'0\'!=route.rqsst))" title="Остановки по требованию" viewBox="0 0 512 512" style="margin-left:0.5rem;width:22px;height:22px;color:#c4c4c4s;"><use xlink:href="#icon-request" /></svg>\
                                                            <svg v-if="((route.fails)&&(route.fails>0))" title="Нарушения" viewBox="0 0 576 512" style="margin-left:0.5rem;width:24.75px;height:22px;color:#EF5350;"><use xlink:href="#icon-warning" /></svg>\
                                                        </div>\
                                                    </div>\
                                                </v-card-text>\
                                            </v-card>\
                                        </template></v-hover>\
                                    </v-col>\
                                </v-row><!--.jet-routes-->\
                            </v-container>\
                        </v-expansion-panel-content>\
                      </v-expansion-panel>\
                    </v-expansion-panels>\
                    <v-dialog v-model="showMap" v-show="((!!mapRoute)&&(\'stub\'!==mapRoute.id))" fullscreen hide-overlay transition="dialog-bottom-transition">\
                        <v-toolbar dark color="primary">\
                            <v-toolbar-title>{{ mapRoute.code }}. {{ mapRoute.name }}</v-toolbar-title>\
                            <v-spacer></v-spacer>\
                            <v-toolbar-items>\
                                <v-btn dark text @click="openMap(false)"><v-icon>mdi-close</v-icon> закрыть</v-btn>\
                            </v-toolbar-items>\
                        </v-toolbar>\
                        <div class="d-flex align-stretch fill-height" style="background-color:#fff;">\
                            <div id="route-map" style="width:100%;height:100%;overflow:hidden;"></div>\
                            <DspFails v-show="showFails" :trip="mapRoute.trip" @onfail="showFail($event)" />\
                        </div>\
                    </v-dialog>\
                    <DspSchedule v-bind:trip="activeTrip" @hide="activeTrip=null" />\
                    <!--DspControl v-bind:trip="controlTrip" @hide="controlTrip=null" @onmap="openMap($event)" /-->\
                    <DspOnlineControl @onfail="showTripControl2($event)" />\
            </v-container>',
};
</script>
