// Vue
import Vue from 'vue';

// Утилиты
import { declOfNum } from '@/utils/utils';

// Клонирование объекта
window._copy = (data) => {
  return JSON.parse(JSON.stringify(data));
};

// Проверка что объект пустой
window._emptyObject = (object) => {
  return Object.keys(object).length === 0;
};

// Проверка что свойство есть в объекте
window._hasOwnProperty = (object, propertyName) => {
  return Object.prototype.hasOwnProperty.call(object, propertyName);
};

// Функция логирования
window._log = (...args) => {
  console.log.apply(window, args);
};

// Лог ошибок
window._err = (...args) => {
  console.error.apply(window, args);
};

// Добавляем глобальный миксин
Vue.mixin({
  methods: {
    // Проверка что объект не пустой
    _emptyObject: (object) => window._emptyObject(object),
    // Плюрализация
    _pluralize: (number, titles) => declOfNum(number, titles),
  },
});

/**
 * Ищет последний индекс в массиве
 * Как только найдет первый - сразу его вернет
 * В противном случае вернет -1
 *
 * @param {CallableFunction} callback
 * @return {number}
 */
Array.prototype.lastIndex = function(callback) {
  // Копируем данные, чтобы не менять исходный массив
  const rCopy = _copy(this);

  // Находим последний индекс
  const index = rCopy.reverse().findIndex(callback);

  if (index > -1) {
    return rCopy.length - index;
  }

  return -1;
};
