<template>
  <ol-map ref="map"
          name="ol-provider"
          v-on:onload="_mapLoad"
          v-on:vehicleClick="vehicleClick"
          v-on:trackClick="trackClick"
          >
  </ol-map>
</template>

<script>
import OlMap from "@/components/OlMap";
import MapSettingsService from '@/components/dev/service/MapSettingsService';
import { rgbaFromHexa, colorFromInt } from '@/utils/utils';
import JetMap from '@/components/map';
import MapIcons from '@/components/dev/service/MapIcons';
import MapObjectsService from '@/components/dev/service/MapObjectsService';
import {SPEED_LIMIT} from '@/const/vehicle_consts';
import GeoJSON from 'ol/format/GeoJSON';

let _map;

let _points = [];

// Идентификатор источника и слоя для всех точек машинок
const _STATIC_VEHICLES_ID = 'static-vehicles';


// Идентификатор источника и слоя для трека
const _TRACK_PREFIX = 'track-vehicle.';



// Пустой список коллекции точек
const _EMPTY_FEATURE_COLLECTION = {
  type: 'FeatureCollection',
  features: [],
};

const _stop_e = (e)=>{
    var e = e?.originalEvent || e;
    if (!!e){
        e.preventDefault();
        e.stopPropagation();
    }
};

// --------------------- СЛОИ НА КАРТЕ ---------------------

// Идентификаторы гео зон
let _geoZonesId = [];
let _geoZoneCircles = [];
let _geoZoneCirclesHash = {};

let _layerRoutes = [];
let _layerStops = [];

// ----------------------------------------------------------

function distance(ll1, ll2) { // TODO: ->maputils
  const R = 6372795; // радиус Земли

  if (
    (!ll1)
    || (!ll2)
    || !(!!ll1.lat)
    || !(!!ll2.lat)
  ) {
    return R;
  }
  // перевод коордитат в радианы
  const lat1 = ll1.lat * Math.PI / 180,
    lat2 = ll2.lat * Math.PI / 180,
    long1 = ll1.lon * Math.PI / 180,
    long2 = ll2.lon * Math.PI / 180;

  // вычисление косинусов и синусов широт и разницы долгот
  const cl1 = Math.cos(lat1);
  const cl2 = Math.cos(lat2);
  const sl1 = Math.sin(lat1);
  const sl2 = Math.sin(lat2);
  const delta = long2 - long1;
  const cdelta = Math.cos(delta);
  const sdelta = Math.sin(delta);

  // вычисления длины большого круга
  const y = Math.sqrt(Math.pow(cl2 * sdelta, 2) + Math.pow(cl1 * sl2 - sl1 * cl2 * cdelta, 2));
  const x = sl1 * sl2 + cl1 * cl2 * cdelta;
  const ad = Math.atan2(y, x);
  const dist = ad * R; // расстояние между двумя координатами в метрах

  return Math.round(dist);
}

export default {
    name: 'OlMapProvider',
    inject: [
        'trackPointClick', 'vehiclePointClick', 'mapLoad'
    ],
    components: {
        OlMap
    },  
    data() {
        return {
            loaded: false
        };
    },
    mounted() {
        this.$nextTick(()=>{
            _map = this.$refs['map'];
        });
    },
    methods: {
        getCenter(){
            return this.$refs['map'].getCenter();
        },
        _mapLoad(e) {
            this.loaded = true;
            if (typeof this.mapLoad !== "undefined"){
                this.mapLoad(this.loaded);
            }
        },
        // Рисование машинки
        drawVehicle(vehicle) {
            //console.log('drawVehicle ', vehicle);
            
            if (vehicle.ll) {
                const latLng = vehicle.ll.split(':');
                const lat = latLng[0];
                const lon = latLng[1];

                _points.push({
                    ...vehicle,
                    lat,
                    lon,
                });

                

                _map.createOrDataLayer(_STATIC_VEHICLES_ID, {
                    type: 'FeatureCollection',
                    features: _points.map(it => {
                        return {
                            type: 'Feature',
                            id: it.id,
                            properties: {
                                vehicle: it,
                            },
                            geometry: {
                                type: 'Point',
                                properties: {},
                                coordinates: [it.lon, it.lat],
                            },
                        };
                    }),
                });
                

                _map.getRawMap().getLayers().forEach(l => {
                    if ( _STATIC_VEHICLES_ID === l.get('name') ){
                        let source = l.getSource();
                        let f = source.getFeatureById(vehicle.id);
                        const coords = [lon, lat];

                        if(!!f) {
                            f.getGeometry().setCoordinates(coords);
                            f.getProperties().vehicle.heading = vehicle.heading;
                        }
                    } 
                })

                if (!vehicle.tracking) {
                    //_map.getRawMap()?.panTo([lon, lat]);
                    this.flyToCoordinates(lat, lon);
                }

                // Помечаем что ТС нарисовано
                vehicle.__state = {
                    ...vehicle.__state || {},
                    draw: true,
                    tracking: vehicle.tracking || false,
                };

                return vehicle;
            } else {
                /*jet.msg({
                    text: `У ${vehicle.govnum.toUpperCase()} нет координат!`,
                    color: 'red'
                });*/

                return null;
            }
        },
        // Удаление машинки
        removeVehicle(vehicle) {
            // console.log('removeVehicle ', vehicle.id);
            const index = _points.findIndex(it => it.id === vehicle.id);
            _points.splice(index, 1);
            

            _map.getRawMap().getLayers().forEach(l => {
                if ( "static-vehicles" === l.get('name') ){
                    let source = l.getSource();
                    let f = source.getFeatureById(vehicle.id);
                    //console.log('FEATURE', f);
                    
                    if(!!f) {  
                        source.removeFeature(f);
                    }
                        
                }
            })

            // Помечаем что ТС не нарисовано
            vehicle.__state = {
                ...vehicle.__state || {},
                draw: false,
                tracking: vehicle.tracking,
            };

            return vehicle;
        },
        // Очистка всех машинов
        clearVehicles() {
            _points.forEach(vehicle => {
                // Помечаем что ТС не нарисовано
                vehicle.__state = {
                ...vehicle.__state || {},
                draw: false,
                };
            });

            _points = [];

            /*_map.createOrDataSource(_STATIC_VEHICLES_ID, {
                type: 'FeatureCollection',
                features: [],
            });*/
            /*_map.createOrDataLayer(_STATIC_VEHICLES_ID, {
                type: 'FeatureCollection',
                features: [],
            });*/
            _map.clear(_STATIC_VEHICLES_ID);

            return 0;
        },
        // Рисование трека
        drawTrack(vehicle, track, settings) {
            console.log('drawTrack');
            //console.log('track', track);
            //console.log('markers', markers);
            console.log('vehicle', vehicle);
            console.log('settings', settings);

            let trackSettings = null;
            if(!!settings) {
                trackSettings = this.changeTrackStyle(vehicle, settings);
            }
            

            const id = `${_TRACK_PREFIX}${vehicle.id}`;
            var prev = { lat: 0, lon: 0 };

            const trackLine = {
                        type: 'Feature',
                        id,
                        geometry: {
                            type: 'LineString',
                            coordinates: []
                        },
                        properties: {
                            name: id,
                            vehicle: vehicle,
                            lineSettings: !!trackSettings ? { trackColor: trackSettings.trackColor, lineWidth: trackSettings.lineWidth } : null
                        }
            };
            const trackPoints = {
                        type: 'FeatureCollection',
                        id: id + '.points',
                        features: [],
                        properties: {
                            name: id + '.points',
                            vehicle: vehicle
                        }
            };
            track.map( (it, n) => {
                
                it.firstOverspeed = false;
                if (parseInt(it.speed) > SPEED_LIMIT) {
                    it.status = 'OVERSPEEDING';
                }
                if (it.status === 'OVERSPEEDING') {
                        if (n === 0) {
                            it.firstOverspeed = true;
                            it.overspeed = parseInt(it.speed);
                        } else if (n > 0 && track[n-1].status !== 'OVERSPEEDING') {
                            it.firstOverspeed = true;
                            it.overspeed = parseInt(it.speed);
                        }
                }
                //TODO: type normalize
                if (n === 0) {
                    it.type = 'started';
                } else if (n === (track.length - 1)) {
                    it.type = 'ended';
                } else if (!(!!it.type)) {
                    it.type = 'moving';
                }

                it.distance = distance(prev, it);
                if (it.distance > 20) {
                    prev = it;
                }

                // Настройка отображения стрелок под Zoom
                if(it.status == 'MOVING') {
                    if(n % 20 == 0) {
                        it.zoom = 10;
                    } else if(n % 15 == 0) {
                        it.zoom = 12;
                    } else if(n % 10 == 0) {
                        it.zoom = 14;
                    } else {
                        it.zoom = 16;
                    }
                }
                

                // Если пришли настройки трека
                if(!!trackSettings) {
                    it.pointSettings = { showStops: trackSettings.showStops, showSpeedUps: trackSettings.showSpeedUps, showParking: trackSettings.showParking };
                }
                
                const coords = [it.lon, it.lat];
                trackLine.geometry.coordinates.push(coords);
                trackPoints.features.push({
                    type: "Feature",
                    geometry: {
                        type: "Point",
                        coordinates: coords
                    },
                    properties: { vehicle, point: it }
                });
                
            });

            
            
            _map.drawTrack({track: trackLine, stops: trackPoints});

            
            vehicle.__state = {
                ...vehicle.__state,
                track: true,
            };
        },
        // Рисование трека по слежению
        drawTrackingLine(vehicle, track, settings) {
            //console.log('drawTrackingLine');
            return this.drawTrack(vehicle, track, settings);
        },
        // Удаление трека
        removeTrack(vehicle) {
            //console.log('removeTrack');
            const id = `${_TRACK_PREFIX}${vehicle.id}`;
            const trackPoints = `${id}.points`;
            
            
            _map.clear(id);
            _map.clear(trackPoints);
            
            vehicle.__state = {
                ...vehicle.__state,
                track: false,
            };
        },
        // Удаление всех треков
        clearTracks() {
            const re = new RegExp(`${_TRACK_PREFIX}`);
            
            let trackLayers = [];
            _map.getRawMap().getLayers().forEach(l => {
                let name = l.get('name');

                if((name) && (name.indexOf('track-vehicle') != -1)) {
                    //_map.clear(name);
                    trackLayers.push(name);
                }
            })

            for(let i = 0; i < trackLayers.length; i++) {
                _map.clear(trackLayers[i]);
            }

        },
        // Установка фокуса на координатах
        drawFlag(point) {
            //console.log('point', point);
            return _map.drawFlag(point);
        },        
        flyToCoordinates(lat, lon) {
            //console.log('flyToCoordinates:', lon + " " + lat);
            if (lat !== null && lon !== null) {
                _map.getRawMap().getView().setCenter([lon, lat]);
            }
        },
        flyTo(vehicle, trackPoint) {
            //console.log('flytit', trackPoint);
            _map.drawFlag(trackPoint);

            if(trackPoint.clicked) {
                _map.getRawMap().getView().setCenter([trackPoint.lon, trackPoint.lat]);
            }
/*TODO: refactoring code            
            if (trackPoint.lat != null && trackPoint.lon != null) {
                const id = `${_TRACK_PREFIX}${vehicle.id}`;
                const trackPoints = `${id}.points`;

                const points = _map.getSourceFeatures(trackPoints);

                // Снятие выделения с прошлой точки
                
                const oldSelectedIndex = points.findIndex(it => it.getProperties().isSelected);
                if (oldSelectedIndex > -1) {
                points[oldSelectedIndex].properties.isSelected = false;
                }

                // Установка новой выбранной точки
                const trackPointIndex = points.findIndex(it => it.id === trackPoint.id);
                if (trackPointIndex > -1) {
                points[trackPointIndex].properties.isSelected = true;
                }
                
                let newPoints = [];
                points.forEach(p => {
                    newPoints.push(new GeoJSON().writeFeatureObject(p));
                })
                _map.createOrDataLayer(trackPoints, {
                    type: 'FeatureCollection',
                    //features: points,
                    features: newPoints,
                });

                _map.getRawMap().getView().setCenter([trackPoint.lon, trackPoint.lat]);
            }
*/                    
        },        
        setZoom(zoom){
            //console.log('setZoom');
            //console.log(_map);
            _map.getRawMap().getView().setZoom(zoom);
        },
        // Изменение настроек трека
        changeTrackStyle(vehicle, settings) {
            console.log('changeTrackStyle');
            console.log('vehicle', vehicle);
            console.log('settings', settings);
            const id = `${_TRACK_PREFIX}${vehicle.id}`;
            
            const stopTypes = settings.stopTypes;
            console.log('stopTypes', stopTypes);
            const trackColor = settings.color || '#0000FFFF';
            const lineWidth = settings.width;

            const showStops = stopTypes.stop || false ? 'visible' : 'none';
            const showSpeedUps = stopTypes.speedUp || false ? 'visible' : 'none';
            const showNoData = stopTypes.noData || false ? 'visible' : 'none';
            const showMoving = stopTypes.moving || false ? 'visible' : 'none';
            const showParking = stopTypes.parking || false ? 'visible' : 'none';

            const trackSettings = { trackColor, lineWidth, showStops, showSpeedUps, showNoData, showMoving, showParking };
            
            
            //const track = [...vehicle.track[0].points];

            this.removeTrack(vehicle);
            return trackSettings;
            //this.drawTrack(vehicle, track, trackSettings);
            

        },


        // --------------------- Слои на карте ---------------------



        // Отрисовка гео зон
        drawGeoZones(geoZones) {
            //console.log('drawGeoZone', geoZones);
            _geoZonesId.forEach(it => {
                _map.clear(it);
            });

            _geoZonesId = [];
            
            geoZones.forEach(it => {
                const id = `geo_zone_${it.ref.id}`;

                _geoZonesId.push(id);

                const geoZone = {
                    type: 'Feature',
                    id,
                    geometry: {
                        type: it.geometry.type,
                        coordinates: it.geometry.coordinates
                    },
                    properties: {
                        name: id,
                        geoZone: it.ref
                    }
                };

                geoZone.properties.geoZone.coordinates = it.geometry.coordinates;
                //console.log('geoZone', geoZone);

                _map.drawGeoZone({geoZone: geoZone});

            });

        },
        // Отрисовка маршрутов
        drawLayerRoutes(routes, stops) {
            console.log('drawLayerRoutes');
            //console.log('routes', routes);
            //console.log('stops', stops);
            // Отрисовка маршрутов
            _layerRoutes.forEach(it => {
                _map.clear(it);
            });

            _layerStops.forEach(it => {
                _map.clear(it);
            });


            _layerRoutes = [];
            _layerStops = [];

            routes.map(it => {
                const coords = (it.points || []).map(it => it.coordinates);
                const rId = `route_${it.id}`;
                const rpId = `route_points_stops${it.id}`;
                //const rpSt = `route_points_stops_text_${it.id}`
                //const rpEndId = `route_points_end_stops${it.id}`;
                //const rpId = `route_points_stops${it.id}`;
                console.log('IT', it);

                const routeLine = {
                    type: 'Feature',
                    id: rId,
                    geometry: {
                        type: 'LineString',
                        coordinates: []
                    },
                    properties: {
                        name: rId,
                    }
                };

                const routePoints = {
                    type: 'FeatureCollection',
                    id: rpId,
                    features: [],
                    properties: {
                        name: rpId,
                    }
                };

                routeLine.geometry.coordinates.push(...coords);

                const pointsLen = it.points.length;
                let hasLocationName = it.points.map(point => point.locationName).filter(el => !!el);
                console.log('hasLocationName', hasLocationName);
                
                it.points.forEach(p => {

                    let count = 0;
                    hasLocationName.forEach(item => {
                        if(p.locationName == item.trim()) {
                            count++;
                        } 
                    })

                    if(count > 1) {
                        p.first = (p.pointNumber < (pointsLen / 2)) ? true : false;
                    } else {
                        p.first = true;
                    }

                    
                    
                    routePoints.features.push({
                        type: "Feature",
                        geometry: {
                            type: "Point",
                            coordinates: p.coordinates
                        },
                        properties: { 
                            point: p
                        }
                    });
                })

                if(!!stops && !!stops.length) {
                    stops.forEach(s => {
                        routePoints.features.push({
                            type: "Feature",
                            geometry: {
                                type: "Point",
                                coordinates: s.coordinates
                            },
                            properties: { 
                                point: s,
                                stop: true
                            }
                        });
                    })
                }


                _layerRoutes.push(rId);
                _layerStops.push(rpId);

                _map.drawLayerRoutes({route: routeLine, stops: routePoints});
                

                //console.log('routeLine', routeLine);
                //console.log('routePoints', routePoints);
            });

            if(!(!!routes.length) && (!!stops && !!stops.length)) {
                
                stops.forEach(s => {
                    const rpId = `route_points_stops${s.id}`;
                    const routePoints = {
                        type: 'FeatureCollection',
                        id: rpId,
                        features: [],
                        properties: {
                            name: rpId,
                        }
                    };
                    routePoints.features.push({
                        type: "Feature",
                        geometry: {
                            type: "Point",
                            coordinates: s.coordinates
                        },
                        properties: { 
                            point: s,
                            stop: true
                        }
                    });

                    _map.drawLayerRoutes({route: {}, stops: routePoints});
                })

                
            }



        },
        // Отрисовка остановок
        /*drawStops(stops) {
            console.log('drawStops', stops);
            _map.createOrDataLayer('route_stops', {
                type: 'FeatureCollection',
                features: stops.map(it => ({
                type: 'Feature',
                properties: {
                    title: it.locName || 'Нет имени остановки',
                },
                geometry: {
                    type: 'Point',
                    coordinates: it.coordinates,
                },
                })),
            });

        },*/
        // Отрисовка объектов маршрута
        drawStopObjects(stopObjects) {
            //console.log('drawStopObjects', stopObjects);
            MapObjectsService.init(_map);

            MapObjectsService.drawStopObjects(
                 _copy(stopObjects || []),
            );
        },
        // Удаление флага
        removeFlag() {
            //console.log('removeFlag');
            try {
                /*_map.createOrDataLayer("flag-point", {
                type: 'FeatureCollection',
                features: []
                });*/
                _map.clear('flag-point');
                return true;
            } catch (ex) {
                console.log("Ошибка при удалении флага на выделенной точке трека", ex);
                return false;
            }
        },
        async ready(loaded){
            const self = this;
            var n = 0;
            return new Promise((resolve, reject)=>{
                const _wait = ()=>{
                    n++;
                    if ( n > 100 ){
                        reject({message: "timeout expired"});
                    }
                    if (!!loaded){
                        resolve();
                    } else {
                        setTimeout(_wait, 300);
                    }
                };  //_wait
                _wait();
            });
        }, // ready
        update(){
            console.log('update');
            if (!!_map){
                const r = _map.getRawMap();
                if (!!r){
                    r.resize.apply(r);
                }
            }
        }, // update
        vehicleClick({vehicle, point}) {
            /*const evac = (/^[Ээ]вакуатор/).test(vehicle.vctypename.trim());
            if(!!evac) {
                this.evacPointClick(vehicle, point);
            } else {
                this.vehiclePointClick(vehicle, point);
            }*/
            console.log('КЛИК ПО VEHICLE', {vehicle, point});
            this.vehiclePointClick(vehicle, point);
            
        },
        trackClick({vehicle, point}){
            this.trackPointClick(vehicle, point);
        },
    }
}
</script>
