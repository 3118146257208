<template>
  <div> 
    <v-dialog v-model="showThis" max-width="400">
      <v-card class="sel-sched-card">
        <v-card-title>Добавить сервисную стоянку</v-card-title>
        <v-card-text class="sel-sched">
          <v-row>
            <v-col>
              Сервисные стоянки
              <v-banner
                outlined
                single-line
                rounded>
                <template v-for="item in servTypes">
                  <v-checkbox
                    v-model="item.checked"
                    class="pt-o mt-0"
                    hide-details
                    color="primary"
                    :key="item.id"
                    :label="item.name"
                  ></v-checkbox>
                </template>
              </v-banner>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn @click="prepareSave">ок</v-btn>
          <v-btn @click="close">отмена</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div> 
</template>

<script>

    export default {
        name: 'AddServiceStopDialog',
        props: ['show', 'stop', 'types'],
        data() {
            return {
                servTypes: [],
                showThis: false,
            };
        },
        watch: {
            show: function (val) {
                this.servTypes = [];
                this.types.filter(it => !!it.id)
                .map((type) => {
                    var s = _copy(type);
                    s.onStop = this.stop.service.find((serv) => serv.servicestopid === type.id);
                    s.checked = !!s.onStop;
                    this.servTypes.push(s);
                });
                this.showThis = val;
            },
            showThis: function (val) {
                if (!val) {
                    this.$emit('close');
                }
            },
        },
        methods: {
            close() {
                this.$emit('close');
            },
            prepareSave(){
                var services = [];
                this.servTypes.filter((type) => !!type.checked)
                    .map((type) => {
                        if (!!type.onStop ) {
                            services.push(_copy(type.onStop));
                        } else {
                            services.push({
                                id: $utils.uuidv4(),
                                schedulestopid: this.stop.id,
                                servicestopid: type.id
                            })
                        }
                })
                var res = {
                    service: services
                }
                this.$emit('save', res);
                this.$emit('close');
            }
        },
    };

</script>

<style lang="scss">
    .sel-sched-card{
       height: 20em;
    }
    .sel-sched{
        height: 15em;
    }
    .v-banner{
        overflow-y: auto;
        height: 12em;
    }
</style>
