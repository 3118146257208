
const state = {
    active: null
};

const mutations = {
    active(state, ci) {
        state.active = ci;
        if (!!state.active){
            $utils.saveLocalStorage('last-coll', state.active);
        }
    }
};

const actions = {
    setActive(ctx, ci){
        ctx.commit('active', ci);
    }
};

const getters = {
};

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
};
