<template>
  <v-autocomplete
    label="Версия расписания"
    v-model="versions.selected"
    item-value="id"
    item-text="shortName"
    return-object
    :items="versions.list"
    :loading="versions.loading"
    @change="onSelectVersion($event)"
  >
    <template #item="{item: version}">
      <div id="scheduleVersions">
        <div>
          {{ version.version }}
        </div>
        <div>
          {{ version.verstatusName }}
        </div>
        <div>
          {{ version.verstart }}
        </div>
        <div>
          {{ version.verend }}
        </div>
      </div>
    </template>
  </v-autocomplete>
</template>

<script>
    
import ReleaseSchedulesService from '@/services/ReleaseSchedulesService';
const $moment = require('moment');

export default {
  name: "ScheduleVersionSelection",
  props: {
    filter: {
      type: String,
      required: true,
    },
    selected: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    versions: {
      loading: false,
      list: [],
    },
  }),
  created() {
    this.loadScheduleVersions();
  },
  watch: {
    filter: function (val) {
      if (!!val){
          this.loadScheduleVersions();
      }
    }
  },
  methods: {
    onSelectVersion(item) {
      this.$emit('selectVersion', item);
    },
    loadScheduleVersions() {
      this.versions.loading = true;
      
      ReleaseSchedulesService.getScheduleVersionsFiltered(this.filter)
        .then(result => {
          this.versions.list = [
            {
              version: 'Версия',
              verstatusName: 'Статус',
              verstart: 'Начало действия',
              verend: 'Окончание действия',
              shortName: '',
              disabled: true,
            }
          ];
          result.map(it => {
            it.verstart = !!it.verstart ? $moment(it.verstart).format('DD.MM.YYYY') : '';
            it.verend = !!it.verend ? $moment(it.verend).format('DD.MM.YYYY') : '';
            it.shortName = it.version + '   ' + it.verstatusName + '   ' + it.verstart + '   ' + it.verend;
            it.disabled = false;
            this.versions.list.push({...it});
          });
          this.versions.selected = this.versions.list.find(it => it.id === this.selected) || this.versions.list[this.versions.list.length - 1];
          this.$emit('selectVersion', this.versions.selected);
        })
        .catch(err => {
          _err('ScheduleVersionSelection::loadScheduleVersions', err);
        })
        .finally(() => {
          this.versions.loading = false;
        });
    },
  }
}
</script>



<style lang="sass">
#scheduleVersions
  display: grid
  grid-template-columns: 0.3fr 1fr 0.9fr 0.9fr
  width: 100%
  max-width: 600px

#scheduleVersions > div
  padding: 5px
  max-height: 50px
  overflow: hidden
  text-overflow: ellipsis
  white-space: nowrap
</style>