<script>
import Vue from 'vue';
import vuetify from '@/plugins/vuetify';
import { watch } from '@/utils/dom';
import Model from '@/core/Model';

var all = null;

const DspRoutesList = {
    el: undefined,
    vuetify,
    props: {
        owner: {
            type: Object,
            required: false
        }
    },
    data(){
        return {
            id: null,
            routes: null,
            selected: [],   //new routes id`s
            error: null,
            loading: false
        };
    },
    async created(){
        this.id = this.$parent.dspoperatorsId;
        console.log('List created', this);
        this.loading = true;
        
        //Ctrls route`s first
        try {
            const sel = await $http.post({
                            type: 'core-read',
                            query: `sin2:/v:dbc206e0-e989-494c-9650-bef60ee652ff/?filter=eq(field(".operId"),param("${this.id}", "id"))`
            });
            console.log('ctrls', sel);
            if (!!sel.error){
                throw sel.error;
            }
            const ci = sel.result.columnIndexes["dspopsroutes.routeid"];
            sel.result.data.map( d => {
                this.selected.push( d[ci] );
            });
        } catch(e){
            console.log('ERR (ctrls)', e);
        }
        
        if ( all?.length > 0 ){
            this.routes = all;
            this.loading = false;
            return;
        }
        
        //all routes for list        
        $http.post({
                    type: 'core-read',
                    query: 'sin2:/v:d122d351-3e3e-4983-a73c-199f0fa1bed5/'
        }).then( r => {
            if ( !!r.error ){
                throw r.error;
            }
            const model = new Model(r.result.model);
            const re = /\D/g;
            all = model.sin2obj(r.result.data, true).sort( (r1, r2) => {
                return Number(r1.vcroutesRoutecode.replace(re, '')) < Number(r2.vcroutesRoutecode.replace(re, '')) ? -1 : 1;
            });
            this.routes = all;
            this.loading = false;
        }).catch( e=> {
            this.loading = false;
            console.log('ERR (routes)', e);
            jet.msg({text:'Ошибка получения списка маршрутов'});
        });
        
    },
    methods: {
        has(q, v){
            switch(q){
                case "checked":
                    return this.selected.findIndex( s => s===v.vcroutesId) > -1;
                case "id":
                    return !!this.id;
                case "routes":
                    return this.routes?.length > 0;
            }
            return false;
        },
        async save( operId ){
            var numOf = 0;
            try {
                const uri = 'sin2:/v:dbc206e0-e989-494c-9650-bef60ee652ff/';
                const opts = {
                            type: 'core-read',
                            query: `${ uri }?filter=eq(field(".operId"),param("${ operId }","id"))`,
                      };
                
                var res = await $http.post('/rpc?d=jsonRpc', opts);
                if (!!res.error) {
                    throw res.error;
                }
                
                opts.type = 'core-delete';
                res.result.data.map( async r => {
                    try {
                        const id = r[res.result.columnIndexes["dspopsroutes.id"]];
                        opts.query = `${ uri }?id=${ id }`;
                        opts.params= [{id: 'id', type: 'id', value: id}];
                        const del = await $http.post('/rpc?d=jsonRpc', opts);
                        if (!!del.error) {
                          throw del.error;
                        }
                    } catch(e){ }
                });
                
                //save new
                opts.type = 'core-create';
                opts.query= uri;
                this.selected.map( async id => {
                    try {
                        opts.params = [
                                            {id: 'operId', type: 'id', value: operId},
                                            {id: 'routeId',type: 'id', value: id}
                                      ];
                        const res = await $http.post('/rpc?d=jsonRpc', opts);
                        if (!!res.error) {
                          throw res.error;
                        }
                        numOf++;
                    } catch(e){
                        jet.msg({text:'Ошибка сохранения контролируемого маршрута: ' + e.message, timeout: 20000});
                    }
                });
                
                setTimeout(()=>{
                    const a = jet.collections.active;
                    a._own.$refs["detail_0"]?.refresh();
                    jet.msg({text:`Сохранено ${ numOf } маршрута(ов)`, timeout: 20000});
                    
                }, 500);
            } catch(e){
                jet.msg({text:'Ошибка сохранения списка контролируемых маршрутов'});
                console.log('ERR (save)', e);
            }
        }
    },
    template: `<v-container><v-row justify="center"><v-col cols="12" md="8">
                    <v-list dense class="dsp-routes">
                        <v-subheader><v-badge :content="selected.length">Список закрепленных маршрутов</v-badge><v-icon v-if="loading" class="ml-5 mdi-spin">mdi-refresh</v-icon></v-subheader>
                        <v-list-item-group v-if="has('routes')" 
                               v-model="selected"
                               color="primary"
                               multiple>
                            <v-list-item v-for="r in routes" :key="'rt-' + r.vcroutesId" 
                                         :value="r.vcroutesId"
                                         class="dsp-route">
                                <v-list-item-icon class="dsp-route__code">
                                    {{ r.vcroutesRoutecode }}
                                </v-list-item-icon>
                                <v-list-item-content>
                                    {{ r.vcroutesRoutename }}
                                    <div class="dsp-route__meta">
                                        <div class="status">{{ r.vcroutesVerstatusName }}</div>
                                        <div class="carrs">{{ r.vcroutesCarriers }}</div>
                                    </div>
                                </v-list-item-content>
                                <v-list-item-icon>
                                    <v-icon v-if="has('checked', r)" small>mdi-checkbox-outline</v-icon>
                                </v-list-item-icon>
                            </v-list-item>
                        </v-list-item-group>
                   </v-list>
            </v-col></v-row></v-container>`
};  //DspRoutesList
    
export default {
    name: 'DspOpsForm',
    components: {
        DspRoutesList
    },
    data(){
        return {
            list: null
        };
    },
    mounted(){
        this.$parent.afterSave = ({ id }) => {
            this.list.save(id);
        };
        watch('form .v-tabs .v-tabs-items .v-window-item.v-window-item--active .container').then( node => {
            $(node).append('<div id="ops-list"></div>');
            DspRoutesList.parent = this;
            DspRoutesList.el = $(node).find('#ops-list').get(0);
            this.list = new Vue(DspRoutesList);
        });
    }
};
</script>    
<style lang="scss">
    .dsp-routes{
        & .dsp-route{
            border-bottom: 1px solid #ccc;
            &__code{
                width: 3rem;
                white-space: nowrap;
                overflow: hidden;
            }
            &__meta{
                font-size: 0.75rem;
                color: #999;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                & > * { 
                    &:not(:last-child){
                        margin-right: 0.5rem;
                    }
                }
            }
            & .v-list-item__content{
                padding: 0 0 2px 0;
            }
        }
        
    }
</style>