<template>
    <v-dialog v-model="show"
              scrollable
              eager
              max-width="720px"
              content-class="mt-rt-choose">
        <v-card>
            <v-toolbar flat tile
                       class="pt-4">
                <jet-input-id type="id"
                              label="маршрут"
                              ref="routeInp"
                              :uri="SIN_ROUTES_URI"
                              noactions
                              hide-details
                              name="route"
                              :value="routeId"
                              style="width:100%;"
                              v-on:input="selRoute"
                    />
                <v-spacer />
                <v-btn v-on:click="show=false"
                       text>
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card-text v-if="!has('route')" 
                         class="py-5">
                <v-alert border="left"
                         colored-border
                         color="primary"
                         elevation="2">
                    Выберите маршрут из списка...
                </v-alert>
            </v-card-text>
            <v-card-text v-else-if="mode===MODES.loading" class="py-5">
                <v-skeleton-loader type="ist-item-avatar-two-line@3" />
            </v-card-text>    
            <v-card-text v-else-if="has('vers')">
                <h2>Версии расписаний {{route.vcroutesRoutecode}}.&nbsp;{{route.vcroutesRoutename}}</h2>
                <v-list class="mt-rt-versions">
                    <v-list-item v-for="v in vers"
                                 :key="v.vcreleaseschedulesId"
                                 v-bind:class="{approved: has('approved', v), lost: has('lost', v)}"
                                 v-on:click="selVersion(v)">
                        <v-list-item-avatar>
                            {{ v.vcreleaseschedulesVersion }}
                        </v-list-item-avatar>
                        <v-list-item-content>
                            <v-list-item-title>
                                {{ v.vcreleaseschedulesVerstatusName }}
                            </v-list-item-title>
                            <v-list-item-subtitle>
                                {{ fmt(v.vcreleaseschedulesVerstart) }}
                                {{ !!v.vcreleaseschedulesVerend ? ' - ' + fmt(v.vcreleaseschedulesVerend) : '' }}
                            </v-list-item-subtitle>
                            <v-list-item-subtitle class="mt-rt-meta">
                                маршрут: {{ v.sVerstatusName }}
                                &nbsp;вер.№&nbsp;<b>{{v.sVersion}}</b>&nbsp;{{ fmt(v.sVerstart) }}
                                {{ !!v.sVerend ? ' - ' + fmt(v.sVerend) : '' }}
                            </v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>
<script>
import JetInputId from '@/components/editors/JetInputId';
import { MODES } from '@/utils';
import Model from '@/core/Model';
const $moment = require('moment');

const SIN_ROUTES_URI = `sin2:/v:d122d351-3e3e-4983-a73c-199f0fa1bed5?sort=vcRoutes.routeCode&filter=
                        and(
                                exists(\"24b28908-8b07-4840-b9a9-995593478876\",
                                    \"and(
                                            eq(field(\\\".verId\\\"),super.field(\\\".verId\\\")),
                                            or(
                                              eq(field(\\\".verStatus.Name\\\"),param(\\\"Утвержден\\\", \\\"string\\\")),
                                              eq(field(\\\".verStatus.Name\\\"),param(\\\"Проект\\\", \\\"string\\\"))
                                            )
                                          )\"
                                    ),
                                eq(field(\".isActiveRoute\"), param(true,\"boolean\"))
                        )`;


export default {
    name: 'RouteSelVersion',
    components: {
        JetInputId
    },
    data(){
        return {
            MODES,
            SIN_ROUTES_URI,
            mode: MODES.none,
            show: false,
            route: null,
            vers: null
        };
    },
    provide: {
                owner: {    //stub's
                                reg(comp){},
                                unreg(comp){},
                                val(name, val){}
                }
    },    
    methods: {
        has(q, v){
            switch(q){
                case "approved":
                    return /^(утве)+/i.test(v.vcreleaseschedulesVerstatusName); //утв.
                case "lost":
                    return /^(утратил)+/i.test(v.vcreleaseschedulesVerstatusName);
                case "route":
                    return !!this.route;
                case "vers":
                    return this.vers?.length > 0;
            }
            return false;
        },
        fmt(dt){
            return (!!dt) ? $moment(dt).format('DD.MM.YYYY') : null;
        },
        open(route){
            this.route = route;
            this.show = (new Date()).getTime();
            this.$nextTick(()=>{
                const inp = this.$refs.routeInp;
                if (!!route?.need){
                    this.mode = MODES.loading;
                    //inp.setFilter('eq(field(\".id\"),param(\"' + route.id + '\",\"id\"))');
                    this.selectRoute(inp, route.id);
                } else {
                    inp.setFilter();
                }
            });
        },
        async selectRoute(inp, id){
            await inp.refresh();
            const items = inp.getItems();
            const selectedRoute = items.find(it => it.vcroutesId === id);
            if (!!selectedRoute) {
              inp.rowSelect(selectedRoute);
            }
        },
        selRoute(id){
            console.log(id);
            const r = this.$refs["routeInp"];
            this.route = r.selected;
            this.loadVers();
        },
        async loadVers(){
            this.vers = [];
            
            if (!this.has('route')){
                return;
            }
            this.mode = MODES.loading;
            const verUri = `sin2:/v:17743043-925a-48e4-90b5-8f1e77226a01?filter=
                                    exists(\"17743043-925a-48e4-90b5-8f1e77226a01\",
                                                   \"and(
                                                       eq(field(\\\"s.verid\\\"),super.field(\\\"s.verid\\\")),
                                                       eq(field(\\\"s.id\\\"),param(\\\"${ this.route.vcroutesId }\\\",\\\"id\\\"))
                                                   )\"
                                    )
                                    &sort=vcReleaseSchedules.verStatus.Name,-vcReleaseSchedules.verStart`;
            try{
                const res = await $http.post('/rpc?d=jsonRpc', {
                    type: 'core-read',
                    query: verUri
                });
                if (!!res.error){
                    throw res.error;
                }
                const m = new Model(res.result.model);
                this.vers = m.sin2obj(res.result.data, true);
                console.log('vers', this.vers);
                this.mode = MODES.default;
            } catch(e){
                this.mode = MODES.error;
            }
        },
        selVersion(ver){
            this.$emit("input", {route: this.route, ver, vers: this.vers});
            this.show = false;
        }
    },
    computed: {
        routeId(){
            return this.route?.vcroutesId;
        }
    }
};
</script>
<style lang="scss" scoped>
    .mt-rt-choose{
        & h2{
            font-weight: 400;
            margin: 1rem 0;
            color: var(--v-primary-base);
        }
        & .mt-rt-versions{
            & .v-list-item{
                border-top: 1px solid #efefef;
                &.approved{
                    font-weight: 500;
                    color: var(--v-primary-base) !important;
                }
                &.lost{
                    color: #757575;
                }
                &__avatar{
                    font-size: 1.5rem;
                }
                & .mt-rt-meta{
                    font-size: 0.85rem;
                    font-weight: 400;
                    text-transform: lowercase;
                }
            }
        }
        & .v-card{
            &__text{
                height: 480px;
            }
        }
    }
</style>    