<template>
  <span key="main-conte"></span>
</template>

<script>
export default {
  name: 'JetMainConte',
  data: () => ({
    loadData: false  // stop loading base data
  }),
};
</script>
