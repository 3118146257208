// Сторонние зависимости
import MapBoxGL from 'mapbox-gl';
import MapBoxCircle from 'mapbox-gl-circle';

// Vuex
import store from '@/store/index';

// Vuetify цвета
import colors from 'vuetify/lib/util/colors';

// Сервисы
import MapDirectionsService from './MapDirectionsService';
import MapLayersService from '@/services/MapLayersService';

// Utils
import Map from '@/utils/map';
import { uuidv4, pixelToMeter } from '@/utils/utils';

const idPrefix = 'routes_drawRoute';
const stopsId = `${idPrefix}_stops`;
const stopsTextId = `${stopsId}_text`;
const lineId = `${idPrefix}_line`;

// Текущая выбранная точка
let _currentFeature = null;

// Экзепляр события обратки нажатия клавиш
let _keyDown = null;

// Массив остановок
let _busStops = [];
// Связь между остановками
let _directions = {};
// Массив точек
let _points = [];

// Флаг что уже перетаскиваем точку
let selected = false;
// Экземпляр события перетягивания мышкой. Для отвязывания
let mouseMoveEvent = null;

// Хранилище подписчиков
const _listeners = {};

// Событие - произошло изменение
const EVENT_CHANGED = 'changed';
// Событие - остановка по треку
const EVENT_BY_TRACK_STOPS = 'by_track_stops';

export default {
  allowEdit: true,

  /**
   * Набор цветов
   *
   * @return {{
   *    defStopStroke: string,
   *    finalStop: string,
   *    finalStopStroke: string,
   *    stop: string,
   *    stopStoke: string,
   *    defStop: string,
   *    currentStroke: string,
   * }}
   */
  colors: () => ({
    finalStop: 'rgba(17, 42, 204, 1)',
    finalStopStroke: 'rgba(17, 42, 204, 0.5)',
    defStop: 'rgba(51, 142, 17, 1)',
    defStopStroke: 'rgba(51, 142, 17, 0.5)',
    stop: 'rgba(198, 24, 229, 1)',
    stopStoke: 'transparent', //'rgba(192, 168, 31, 0.1)',
    currentStroke: 'rgba(192, 168, 31, 1)',
  }),

  /**
   * Инициализация
   *
   * @param {Map} map
   * @param {Array<Object>} busStops
   * @param {Array<Object>} points
   * @param {Boolean} allowEdit Разрешить редактирование
   */
  init(map, busStops, points, allowEdit) {
    this._map = map.getMap();
    this._instance = map;
    this.allowEdit = allowEdit;

    _busStops = busStops;

    // Заполнение связей между остановками
    for (let i = 0, len = busStops.length; i < len; i++) {
      const it = busStops[i];

      if (i === len - 1) {
        _directions[it.id] = {
          next: null,
        };
      } else {
        _directions[it.id] = {
          next: busStops[i + 1].id,
        };
      }
    }

    this._map.on('click', (e) => {
      const features = this._map.queryRenderedFeatures(e.point, {
        layers: [stopsId, lineId],
      }) || [];

      if (features.length === 0) {
        this._clearCurrentFeature();
      }
    });
  },

  /**
   * Отрисовка маршрута
   *
   * @param {Object} route
   */
  drawRoute(route) {
    // Если передали пустоту - значит надо чистить
    if (route === null) {
      // TODO:
      // this._draw.deleteAll();
      _points = [];

      this._instance.createOrDataSource(stopsId, {
        type: 'FeatureCollection',
        features: [],
      });

      this._instance.createOrDataSource(lineId, {
        type: 'FeatureCollection',
        features: [],
      });

      return;
    }

    const points = route.points || [];

    _points = points;

    this._drawTrackLines(points);
    this._drawTrackPoints(points);

    this._fitBounds(points);
  },

  /**
   * Обновление названий остановок на карте при редактировании остановки
   *
   * @param {Object} route
   */
  redrawStopName(stop) {
    // Идентификатор измененной остановки
    const id = stop.id;

    const idx = _points.findIndex(it => it.id === id);

    if (idx > -1) {
      const point = _points[idx];
      point.locationName = stop.locationName;
      _points.splice(idx, 1, point);
      
      // Источник с точками
      const sData = _map.getSource(stopsId)._data;
      const currentProperties = sData.features[idx].properties
      
      // Обновим название точки на карте
      sData.features.splice(idx, 1, {
        id: id,
        type: 'Feature',
        properties: {
          id: currentProperties.id,
          name: stop.locationName,
          color: currentProperties.color,
          strokeColor: currentProperties.strokeColor,
          isStop: currentProperties.isStop,
          circleStrokeWidth: currentProperties.circleStrokeWidth,
          ref: point,
        },
        geometry: sData.features[idx].geometry,
    });

    // Сообщим в обратную сторону что были изменения
    this._emit(EVENT_CHANGED, null);

    this._instance.createOrDataSource(stopsId, sData);
    }
  },

  /**
   * Редактирование нулевой трассы
   */
  editZeroTrack() {
  },

  /**
   * Показ/Скрытие нулевых трасс
   *
   * @param {Boolean} state Флаг для показа либо скрытия
   */
  toggleShowZeroTracks(state) {
    const regex = new RegExp(/^zt_\d+$/);
    const lineRegex = new RegExp(/^zt_line_\d+$/);
    const sourceNames = Object.keys(this._map.getStyle().sources).filter(it => regex.test(it) || lineRegex.test(it));

    sourceNames.forEach(sourceName => {
      const source = this._map.getSource(sourceName);

      source._data.features = source._data.features.map(it => {
        it.properties.visible = state;

        return it;
      });

      this._instance.createOrDataSource(sourceName, {
        type: 'FeatureCollection',
        features: source._data.features,
      });
    });
  },

  /**
   * Отрисовка нулевых трассы
   *
   * @param {Array<Object>} zeroPoints
   * @param {Array<Object>} zeroTracks
   */
  drawZeroTracks(zeroPoints, zeroTracks) {
    const _z = [];
    let _zc = 0;

    const _zeroTracksHash = [];

    zeroTracks.forEach(zeroTrack => {
      const index = zeroPoints.findIndex(it => it.id === zeroTrack.exitid);

      if (index > -1) {
        _zeroTracksHash.push(index + 1);
      }
    });

    _zeroTracksHash.sort((a, b) => a - b).forEach(end => {
      _z.push({
        points: zeroPoints.slice(_zc, end > zeroPoints.length ? zeroPoints.length : end),
      });

      _zc = end;
    });

    _z.forEach((it, index) => {
      this._instance.createOrDataSource(`zt_${index}`, {
        type: 'FeatureCollection',
        features: it.points.map((zPoint, zIndex) => {
          const label = zIndex === it.points.length - 1
            ? zeroTracks.find(it => it.exitid === zPoint.id).name
            : '';

          const start = zIndex === 0 ? null : zPoint.id;
          const end = zIndex === it.points.length - 1 ? null : it.points[zIndex + 1].id;

          return {
            id: zPoint.id,
            type: 'Feature',
            geometry: {
              type: 'Point',
              coordinates: zPoint.coordinates,
            },
            properties: {
              label: label,
              visible: false,
              start: start,
              end: end,
            },
          };
        }),
      });

      this._instance.createOrDataLayer(`zt_${index}`, {
        id: `zt_${index}`,
        type: 'circle',
        source: `zt_${index}`,
        paint: {
          'circle-radius': 6,
          'circle-color': 'red',
        },
        filter: [
          '==',
          'visible',
          true,
        ],
      });

      this._instance.createOrDataLayer(`zt_text_${index}`, {
        id: `zt_text_${index}`,
        type: 'symbol',
        source: `zt_${index}`,
        layout: {
          'text-field': ['get', 'label'],
          'text-variable-anchor': ['top', 'bottom', 'left', 'right'],
          'text-radial-offset': 0.5,
          'text-justify': 'auto',
          'text-size': 14,
        },
        filter: [
          'all',
          ['!=', 'label', ''],
          ['!=', 'visible', false],
        ],
      });

      this._instance.createOrDataSource(`zt_line_${index}`, {
        type: 'FeatureCollection',
        features: [
          {
            type: 'Feature',
            properties: {
              coords: it.points.map(it => it.coordinates),
              visible: false,
            },
            geometry: {
              type: 'LineString',
              coordinates: it.points.map(it => it.coordinates),
            },
          },
        ],
      });

      this._instance.createOrDataLayer(`zt_line_${index}`, {
        id: `zt_line_${index}`,
        type: 'line',
        source: `zt_line_${index}`,
        paint: {
          'line-color': '#8312ac',
          'line-width': 6,
        },
        filter: [
          '==',
          'visible',
          true,
        ],
      });
    });
  },

  /**
   * Отрисовка маршрута по треку
   *
   * @param {Object} route Описание маршрута
   * @param {Array<Object>} stops Все остановки на карте
   */
  drawRouteByTrack(route, stops) {
    // На всякий чистим все
    this._instance.createOrDataSource(stopsId, {
      type: 'FeatureCollection',
      features: [],
    });
    this._instance.createOrDataSource(lineId, {
      type: 'FeatureCollection',
      features: [],
    });

    const points = route.points || [];
    const trackStops = route.stops || [];

    _points = points;

    const bounds = this._fitBounds(points, false);

    const wSw = 0.001;
    const wNe = 0.001;

    bounds.extend([bounds._sw.lng - wSw, bounds._sw.lat - wNe]);
    bounds.extend([bounds._sw.lng - wSw, bounds._ne.lat + wNe]);
    bounds.extend([bounds._sw.lng + wSw, bounds._sw.lat + wNe]);
    bounds.extend([bounds._sw.lng + wSw, bounds._sw.lat - wNe]);

    _map.fitBounds(bounds, {
      padding: 20,
    });

    // *************************************

    const inBounds = stops.filter(it => this._inBounds(bounds, it.coordinates));

    const fStops = [];

    // Для каждой существующей остановки
    for (const stop of inBounds) {
      const feature = {
        lon: stop.coordinates[0],
        lat: stop.coordinates[1],
      };

      let find = {
        eStop: null,
        stop: null,
        cost: null,
        pos: -1,
      };

      // Бежим по каждой точке "остановки" на треке
      for (const trackStop of trackStops) {
        const point = {
          lon: trackStop.coordinates[0],
          lat: trackStop.coordinates[1],
        };

        const cost = pixelToMeter(point, feature);

        find.pos++;

        if (cost <= 100) {
          find = {
            eStop: stop,
            stop: find.cost === null ? trackStop : (Math.min(find.cost, cost) === cost ? trackStop : find.stop),
            cost: find.cost === null ? cost : Math.min(find.cost, cost),
            pos: find.pos,
          };
        }
      }

      if (find.stop !== null) {
        const sStop = _copy(find.eStop);
        sStop.coordinates = find.stop.coordinates;
        sStop.isFinal = false;
        sStop.locationName = find.eStop.locname;
        sStop._id = sStop.id;
        sStop.radius = 100;
        sStop.location = sStop.id;

        points.splice(find.stop.pos, 1, sStop);

        fStops.push(sStop);
      }
    }

    const pPoints = points.map(it => {
      it.id = it._id || uuidv4();
      it.radius = it.radius || 100;

      return it;
    });

    this._updateDirections(fStops);

    _points = pPoints;
    _busStops = fStops;

    this._emit(EVENT_BY_TRACK_STOPS, _copy(fStops));
    this._emit(EVENT_CHANGED, _copy(pPoints));

    this._drawTrackLines(pPoints);
    this._drawTrackPoints(pPoints);
  },

  /**
   * Редактирование остановки
   *
   * @param {Object|null} stop Информация об остановке
   */
  editStop(stop) {
    // Если не передали остановку, значит надо чистить
    if (stop === null) {
      if (this._circle !== undefined && this._circle !== null) {
        this._circle.remove();

        this._circle = null;
      }

      return;
    }

    // Если не было создано круга - создаем
    if (this._circle === undefined || this._circle === null) {
      const coords = stop.coordinates;
      const bounds = new MapBoxGL.LngLatBounds(
        stop.coordinates,
        stop.coordinates,
      );

      _map.fitBounds(bounds, {
        padding: 20,
        maxZoom: 15,
      });

      this._circle = new MapBoxCircle(
        { lng: coords[0], lat: coords[1] },
        stop.radius,
        {
          fillColor: '#22aacc',
        },
      );

      this._circle.addTo(_map);
    } else {
      // Круг создан, надо только изменять параметры
      this._circle.setRadius(stop.radius);
    }
  },

  /**
   * Обновление остановки
   *
   * @param {String} routeId Идентификатор маршрута
   * @param {Object} stop Остановка
   */
  async updateStop(routeId, stop) {
    try {
      const busStops = _copy(_busStops);
      busStops.splice(busStops.findIndex(it => it.id === stop.id), 1, stop);
      _busStops = _copy(busStops);

      if (_points && _points.length) {
        const points = _copy(_points);
        points.splice(points.findIndex(it => it.id === stop.id), 1, stop);
        _points = _copy(points);

        this._drawTrackPoints(_points);
        this._drawTrackLines(_points);
      }
    } catch (e) {
      return null;
    }
  },
  /**
   * Сохранение изменений на треке
   *
   * @param {String} routeId Идентификатор маршрута
   * @param {Array<Object>} track Информация о треке
   * @return {Object}
   */
  updateTrack(routeId, track) {
    return jet.http.post('/rpc?d=jsonRpc', {
      type: 'query',
      query: 'fee7df77-4686-4f69-94af-7a22cfa95cca.nqSaveRoutePoints',
      params: {
        'in_userid': store.state.auth.subject.id,
        'in_routeid': routeId,
        'in_points': JSON.stringify(track),
      },
    });
  },

  /**
   * Уничтожение объекта
   */
  destroy() {
    if (_keyDown !== null) {
      document.removeEventListener('keydown', _keyDown);

      _keyDown = null;
    }
  },

  /**
   * Автопостроение маршрута до добавленных точек
   *
   * @param {Array<Object>} stops
   * @param {Number} start
   * @return {Promise}
   */
  async autoBuild(stops, start) {
    _busStops = _copy(stops);

    const busStopsLen = _busStops.length - 1;

    this._updateDirections(_busStops);

    const newPoints = [];

    for (let i = start; i < busStopsLen; i++) {
      const data = await MapDirectionsService.getDirection([
        { coordinates: _busStops[i].coordinates, id: _busStops[i].id },
        { coordinates: _busStops[i + 1].coordinates, id: _busStops[i + 1].id },
      ]);

      let tData = data.coords.map(it => {
        return {
          id: uuidv4(),
          coordinates: it,
          location: null,
        };
      });

      if (_points.findIndex(it => it.id === _busStops[i].id) === -1) {
        tData.unshift(_busStops[i]);
      }

      newPoints.push(...tData);
    }

    // Последняя остановка
    newPoints.push(_busStops[busStopsLen]);

    const points = _copy(_points);

    points.push(...newPoints);

    _points = this._filterPoints(points);

    this._drawTrackLines(_points);
    this._drawTrackPoints(_points);

    this._fitBounds(_points);

    this._emit(EVENT_CHANGED, _points);

    return _points;
  },

  /**
   * Сортировка
   *
   * @param {Array<Object>} stops
   */
  async sortStops(stops) {
    _busStops = _copy(stops);

    this._updateDirections(_busStops);

    const track = [];

    for (const [index, busStop] of _busStops.entries()) {
      if (index < _busStops.length - 1) {
        const data = await MapDirectionsService.getDirection([
          { coordinates: busStop.coordinates, id: busStop.id },
          { coordinates: _busStops[index + 1].coordinates, id: _busStops[index + 1].id },
        ]);

        let trackData = data.coords.map(it => {
          return {
            id: uuidv4(),
            coordinates: it,
            location: null,
          };
        });

        trackData.unshift(_busStops[index]);

        track.push(...trackData);
      }
    }

    track.push(_busStops[_busStops.length - 1]);

    _points = this._filterPoints(track);

    this._updateDirections(_busStops);

    this._emit(EVENT_CHANGED, _points);

    this._drawTrackLines(_points);
    this._drawTrackPoints(_points);

    this._fitBounds(_points);
  },

  /**
   * Получение данных
   *
   * @return {Array<Object>}
   */
  getData() {
    if (_busStops && _busStops.length) {
      const result = [];

      let pointNumber = 0;

      let stop = _busStops[0].id;
      const lastStop = _busStops[_busStops.length - 1].id;

      const lines = _map.getSource(lineId)._data;
      const points = _map.getSource(stopsId)._data;
    
      let done = false;

      try {
        while (!done) {
          const startIndex = lines.features.findIndex(it => it.id === stop);

          if (startIndex > -1) {
            const line = lines.features[startIndex];

            const start = line.properties.start;
            const end = line.properties.end;

            const iStart = points.features.findIndex(it => it.id === start);

            const pStart = points.features[iStart];

            const propRef = pStart.properties.ref;
            const ref = typeof propRef === 'string' ? JSON.parse(propRef || '{}') : propRef || {};

            const isFinal = ref.isFinal || false;

            result.push({
              pointnumber: ++pointNumber,
              lon: pStart.geometry.coordinates[0],
              lat: pStart.geometry.coordinates[1],
              radius: ref.radius || 100,
              id: ref.id || $utils.uuidv4(),
              location: ref.location || null,
              type: ref.location != null
                ? isFinal
                  ? MapLayersService.FINAL_STOP_GUID
                  : MapLayersService.DEF_STOP_GUID
                : null,
            });

            stop = end;
          } else {
            const iEnd = points.features.findIndex(it => it.id === _busStops[_busStops.length - 1].id);

            if (iEnd > -1) {
              const pEnd = points.features[iEnd];
            
              const propRef = pEnd.properties.ref;
              const ref = typeof propRef === 'string' ? JSON.parse(propRef || '{}') : propRef || {};

              result.push({
                pointnumber: ++pointNumber,
                lon: pEnd.geometry.coordinates[0],
                lat: pEnd.geometry.coordinates[1],
                radius: ref.radius || 100,
                id: ref.id || $utils.uuidv4(),
                location: ref.location || null,
                type: ref.location != null
                  ? ref.isFinal
                    ? MapLayersService.FINAL_STOP_GUID
                    : MapLayersService.DEF_STOP_GUID
                  : null,
              });
            }

            done = true;
          }
        }
      } catch (e) {
        jet.msg({
          text: 'К сожалению, на карте где-то "сломалась" точка. Необходимо перезагрузить маршрут',
        });

        throw e;
      }

      return result
        .filter((it, index) => {
          if (index === result.length - 1) {
            return true;
          }

          const next = result[index + 1];

          return !(it.lon === next.lon && it.lat === next.lat);
        })
        .slice(0, result.lastIndex(it => it.id === lastStop));
    }
  },

  /**
   * Добавление слушателя событий
   *
   * @param {String} event тип события
   *    changed -> были внесены какие-либо изменения
   * @param {CallableFunction} callback Обработчик события
   */
  listen(event, callback) {
    if (!_hasOwnProperty(_listeners, event)) {
      _listeners[event] = [];
    }

    _listeners[event].push(callback);
  },

  /**
   * Сообщаем данные в обратную сторону
   *
   * @param {String} event Имя события
   * @param {*} payload Полезная информация
   * @private
   */
  _emit(event, payload = null) {
    if (_hasOwnProperty(_listeners, event)) {
      _listeners[event].forEach(f => {
        f(payload);
      });
    }
  },

  /**
   * Обработка нажатия клавиш
   *
   * @param {KeyboardEvent} e Событие
   * @private
   */
  _onKeyDown(e) {
    if (e.code === 'Delete') {
      if (_currentFeature !== undefined && _currentFeature !== null) {
        if (_currentFeature.properties.isStop) {
          return;
        }
        const stopsData = _map.getSource(stopsId)._data;
        const lineData = _map.getSource(lineId)._data;

        const currentId = _currentFeature.properties.id;
        const currentIndex = stopsData.features.findIndex(it => it.id === currentId);

        let prevId = null;
        let prevCoords = null;

        let nextId = null;
        let nextCoords = null;

        const prevLineIndex = lineData.features.findIndex(it => it.properties.end === currentId);

        // Если есть линия от предыдующей и удаляемой
        if (prevLineIndex > -1) {
          const line = lineData.features[prevLineIndex];

          prevId = line.properties.start;
          prevCoords = line.properties.coords[0];

          const nextLineIndex = lineData.features.findIndex(it => it.properties.start === currentId);

          // Если есть следующая линия
          if (nextLineIndex > -1) {
            const nextLine = lineData.features[nextLineIndex];

            lineData.features.splice(prevLineIndex, 1, this._getLineTrack(
              line.properties.start,
              line.properties.start,
              nextLine.properties.end,
              [line.properties.coords[0], nextLine.properties.coords[1]],
              prevLineIndex,
            ));
          }
        }

        const nextLineIndex = lineData.features.findIndex(it => it.properties.start === currentId);

        // Если есть линия от удаляемой и со следующей точкой
        if (nextLineIndex > -1) {
          const line = lineData.features[nextLineIndex];

          nextId = line.properties.end;
          nextCoords = line.properties.coords[1];

          // Удаляем старую связь
          lineData.features.splice(nextLineIndex, 1);
        }

        this._clearCurrentFeature();

        stopsData.features.splice(currentIndex, 1);

        this._emit(EVENT_CHANGED, null);

        this._instance.createOrDataSource(stopsId, _copy(stopsData));
        this._instance.createOrDataSource(lineId, _copy(lineData));
      }
    }
  },

  /**
   * Помечаем текущю фичу как выбранную
   *
   * @private
   */
  _markCurrentFeature() {
    if (_currentFeature !== undefined && _currentFeature !== null) {
      _currentFeature.properties.strokeColor = this.colors().currentStroke;

      const stopsData = this._map.getSource(stopsId)._data;
      const currentIndex = stopsData.features.findIndex(it => it.properties.id === _currentFeature.properties.id);
      
      if (currentIndex > -1) {
        stopsData.features.splice(currentIndex, 1, {
          id: _currentFeature.properties.id,
          type: 'Feature',
          properties: _currentFeature.properties,
          geometry: _currentFeature.geometry,
        });

        this._instance.createOrDataSource(stopsId, stopsData);
      }
    }
  },

  /**
   * Рисование всех точек маршрута
   *
   * @param {Array<Object>} points
   * @private
   */
  _drawTrackPoints(points) {
    if (points && points.length) {
      const colors = this.colors();

      this._instance.createOrDataSource(stopsId, {
        type: 'FeatureCollection',
        features: points.filter(it => it !== null).map(it => {
          const isStop = it.locationName !== undefined && it.locationName !== null;

          const color = isStop
            ? ((it.isFinal || false) ? colors.finalStop : colors.defStop)
            : colors.stop;
          const stroke = isStop
            ? ((it.isFinal || false) ? colors.finalStopStroke : colors.defStopStroke)
            : colors.stopStoke;

          return {
            id: it.id,
            type: 'Feature',
            properties: {
              id: it.id,
              name: isStop ? it.locationName : '',
              color: color,
              strokeColor: stroke,
              isStop: isStop,
              circleStrokeWidth: isStop ? 4 : 12,
              ref: it,
            },
            geometry: {
              type: 'Point',
              coordinates: it.coordinates,
            },
          };
        }),
      });

      this._instance.createOrDataLayer(stopsId, {
        id: stopsId,
        type: 'circle',
        source: stopsId,
        paint: {
          'circle-radius': {
            base: 1.75,
            stops: [
              [10, 2],
              [13, 12],
              [16, 16],
            ],
          },
          'circle-stroke-width': {
            base: 4,
            stops: [
              [12, 4],
              [20, 8],
            ],
          },
          'circle-color': ['get', 'color'],
          'circle-stroke-color': ['get', 'strokeColor'],
        },
      });

      this._instance.createOrDataLayer(stopsTextId, {
        id: stopsTextId,
        type: 'symbol',
        source: stopsId,
        layout: {
          'text-field': ['get', 'name'],
          'text-variable-anchor': ['top', 'bottom', 'left', 'right'],
          'text-radial-offset': 0.5,
          'text-justify': 'auto',
          'text-size': 14,
        },
        filter: [
          '==',
          ['get', 'isStop'],
          true,
        ],
      });

      if (this.allowEdit) {
        // Обработка события когда отпустили перетаскивание
        const onMouseUp = (e) => {
          e.originalEvent.preventDefault();

          selected = false;

          _map.setLayoutProperty(stopsTextId, 'visibility', 'visible');

          _map.off('mousemove', mouseMoveEvent);
        };

        _map.on('click', stopsId, e => {
          e.preventDefault();
          e.originalEvent.stopPropagation();
          e.originalEvent.preventDefault();

          _currentFeature = e.features?.[0] || null;

          this._markCurrentFeature();
        });

        _map.on('mousedown', stopsId, e => {
          // Запрещаем перетаскивание карты
          e.preventDefault();
          e.originalEvent.stopPropagation();
          e.originalEvent.preventDefault();

          // Проверка нужна, чтобы не нажались две точки, которые друг на друге нарисованы
          if (selected) {
            return;
          }

          selected = true;

          this._clearCurrentFeature();

          if (_keyDown === null) {
            _keyDown = e => this._onKeyDown(e);

            document.addEventListener('keydown', _keyDown);
          }

          const feature = e.features?.[0] || null;

          _currentFeature = feature;
          this._markCurrentFeature();

          // Если клик был по точке
          if (feature !== null) {
            const sourceName = feature.source;
            const props = feature?.properties;
            const id = props?.id || null;

            _map.setLayoutProperty(stopsTextId, 'visibility', 'none');

            // Если id был указан у точки с которой работаем
            if (id) {
              const sourceData = _map.getSource(sourceName)._data;

              let index = 0;
              let sFeature = {};

              for (sFeature of sourceData.features) {
                if (sFeature?.properties?.id === id) {
                  break;
                }

                index++;
              }

              mouseMoveEvent = e => this._onMouseMove(e, index, feature);

              // Навешиваем событие перетаскивания на карту
              _map.on('mousemove', mouseMoveEvent);

              // навешиваем событие отжатия кнопки на мышке, срабатывает однажды и потом снимается автоматом
              _map.once('mouseup', onMouseUp);

              // навешиваем событие выхода за пределы карты, срабатывает однажды и потом снимается автоматом
              _map.once('mouseout', onMouseUp);
            }
          }
        });
      }

      this.selected = false;
    }
  },

  /**
   * Рисование линий трека
   *
   * @param {Array<Object>} points Набор точек
   * @private
   */
  _drawTrackLines(points) {
    if (points && points.length) {
      _points = points;

      // Рисуем линии
      this._instance.createOrDataSource(lineId, {
        type: 'FeatureCollection',
        features: points.map((it, index) => {
          const next = points?.[index + 1] || null;

          if (next === null) {
            return null;
          }

          const coords = [
            it.coordinates,
            next.coordinates,
          ];

          return this._getLineTrack(
            it.id,
            it.id,
            next.id,
            coords,
            index,
          );
        }).filter(it => it !== null),
      });

      // Стиль линии
      if (!_map.getLayer(lineId)) {
        _map.addLayer({
          id: lineId,
          type: 'line',
          source: lineId,
          paint: {
            'line-color': ['get', 'color'],
            'line-width': 6,
          },
        });
      }

      if (this.allowEdit) {
        // Клик по линиии для добавления точки
        _map.on('click', lineId, e => {
            const features = this._map.queryRenderedFeatures(e.point, {
                layers: [stopsId],
              }) || [];
            if (features.length === 0) {
                this._onLineTrackClick(e);
            }
        });
      }
    }
  },

  /**
   * Обработка движения мышки
   *
   * @param {Event|*} e
   * @param {Number} index
   * @param {Object} feature
   * @private
   */
  _onMouseMove(e, index, feature) {
    // Новаые координаты
    const newCoords = [
      e.lngLat.lng,
      e.lngLat.lat,
    ];
    
    // Установим их у точки для обновления на карте
    feature.geometry.coordinates = newCoords;

    // Идентификатор точки с которой работает
    const id = feature.id || feature.properties.id;

    const idx = _points.findIndex(it => it.id === id);

    if (idx > -1) {
      const point = _points[idx];
      point.coordinates = newCoords;
      point.locationLongitude = newCoords[0];
      point.locationLatitude = newCoords[1];

      _points.splice(idx, 1, point);
    }

    // Источник с точками
    const sData = _map.getSource(stopsId)._data;
    // Источник с линиями
    const lineData = this._map.getSource(lineId)._data;

    // Обновим положение точки на карте с положением курсора
    sData.features.splice(index, 1, {
      id: feature.properties.id,
      type: 'Feature',
      properties: feature.properties,
      geometry: {
        type: 'Point',
        coordinates: newCoords,
      },
    });
    // Обновим координаты в связях остановок и трека
    this._updateDirectionTrack(id, {
      coordinates: newCoords,
    });

    const toChangeLines = [];

    // Найдем линию где началом является текущая точка
    const startIndex = lineData.features.findIndex(it => it.properties.end === id);

    if (startIndex > -1) {
      const startLine = lineData.features[startIndex];
      const startLineCoords = [
        startLine.geometry.coordinates[0],
        newCoords,
      ];

      startLine.geometry.coordinates = startLineCoords;
      startLine.properties.coords = startLineCoords;

      toChangeLines.push(startLine);
    }

    // Найдем линию где концом является текущая точка
    const stopIndex = lineData.features.findIndex(it => it.properties.start === id);

    if (stopIndex > -1) {
      const endLine = lineData.features[stopIndex];
      const endLineCoords = [
        newCoords,
        endLine.geometry.coordinates[1],
      ];

      endLine.geometry.coordinates = endLineCoords;
      endLine.properties.coords = endLineCoords;

      toChangeLines.push(endLine);
    }

    lineData.features.splice(startIndex > -1 ? startIndex : stopIndex, toChangeLines.length, ...toChangeLines);

    // Сообщим в обратную сторону что были изменения
    this._emit(EVENT_CHANGED, null);

    this._instance.createOrDataSource(lineId, lineData);
    this._instance.createOrDataSource(stopsId, sData);
  },

  /**
   * Обработка события добавления точки на линии
   *
   * @param e 
   * @private
   */
  _onLineTrackClick(e) {

    /*if(e.originalEvent.ctrlKey == false) {
      return;
    } */
   

    e.preventDefault();
    e.originalEvent.stopPropagation();
    e.originalEvent.preventDefault();

    const feature = e.features?.[0] || null;

    // Проверка что кликнули по линии
    if (feature !== null) {
      const pointCoords = [
        e.lngLat.lng,
        e.lngLat.lat,
      ];

      const start = feature.properties.start;
      const end = feature.properties.end;
      
      // --------------------------------------------------

      const lineData = _map.getSource(lineId)._data;
      const index = lineData.features.findIndex(it => {
        const props = it.properties;

        return props.start === start && props.end === end;
      });

      if (index > -1) {
        const newId = uuidv4();
        const coords = JSON.parse(feature.properties.coords);

        // Разделим линию на 2 часть
        lineData.features.splice(index, 1, ...[
          // Предыдущая точка с новой
          this._getLineTrack(
            start,
            start,
            newId,
            [
              coords[0],
              pointCoords,
            ],
            index,
          ),
          // Новая точка со старой
          this._getLineTrack(
            newId,
            newId,
            end,
            [
              pointCoords,
              coords[1],
            ],
            index + 1,
          ),
        ]);

        const stopsData = _map.getSource(stopsId)._data;

        const startIndex = stopsData.features.findIndex(it => it.properties.id === start);
        const startStop = stopsData.features[startIndex];

        const endIndex = stopsData.features.findIndex(it => it.properties.id === end);
        const endStop = stopsData.features[endIndex];

        // Добавляем точку
        stopsData.features.splice(startIndex, 2, ...[
          // Изначальная точка
          startStop,
          // Новая точка
          {
            id: newId,
            type: 'Feature',
            properties: {
              circleRadius: 6,
              circleStrokeWidth: 8,
              color: this.colors().stop,
              id: newId,
              isStop: false,
              name: null,
              strokeColor: this.colors().stopStoke,
            },
            geometry: {
              type: 'Point',
              coordinates: pointCoords,
            },
          },
          // Конечная точка
          endStop,
        ]);
        
        const copyDir = _copy(_directions);

        for (const key in copyDir) {
          if (copyDir.hasOwnProperty(key)) {
            const index = (copyDir[key].track || []).findIndex(it => {
              return it.id === startStop.id || it.id === startStop.properties.id;
            });

            if (index > -1) {
              copyDir[key].track.splice(index, 0, {
                coordinates: pointCoords,
                id: newId,
                isFinal: false,
                location: null,
                type: MapLayersService.DEF_STOP_GUID,
              });
            }

            break;
          }
        }

        _directions = _copy(copyDir);

        this._emit(EVENT_CHANGED, null);

        this._instance.createOrDataSource(lineId, lineData);
        this._instance.createOrDataSource(stopsId, stopsData);
      }
    }
  },

  /**
   * Очистка текущей точки
   * Делаем не активной
   *
   * @private
   */
  _clearCurrentFeature() {
    if (_currentFeature !== undefined && _currentFeature !== null) {
      const colors = this.colors();

      const stopsData = this._map.getSource(stopsId)._data;
      const currentIndex = stopsData.features.findIndex(it => it.properties.id === _currentFeature.properties.id);

      if (currentIndex > -1) {
        const propRef = _currentFeature.properties.ref;
        
        const ref = typeof propRef === 'string' ? JSON.parse(_currentFeature.properties.ref || '{}') : propRef;
        const isStop = _currentFeature.properties.isStop || false;

        _currentFeature.properties.strokeColor = isStop
          ? ((ref.isFinal || false) ? colors.finalStopStroke : colors.defStopStroke)
          : colors.stopStoke;

        stopsData.features.splice(currentIndex, 1, {
          id: _currentFeature.properties.id,
          type: 'Feature',
          properties: _currentFeature.properties,
          geometry: _currentFeature._geometry,
        });

        _currentFeature = null;

        this._instance.createOrDataSource(stopsId, stopsData);
      }
    }
  },

  /**
   * Вписка маршрута карты в экран
   *
   * @param {Array<Object>} points Набор точек
   * @param {Boolean} fit Применить ли ограничения
   * @return {LngLat.LngLatBounds}
   * @private
   */
  _fitBounds(points, fit = true) {
    if (points && points.length) {
      const coords = points.filter(it => it !== null).map(it => it.coordinates)
        // TODO: вообще такого быть не должно
        .filter(it => it[0] !== null && it[1] !== null);
      if (coords && coords.length) {
        const bounds = new MapBoxGL.LngLatBounds(coords[0], coords[0]);

        for (let i = 1, len = coords.length; i < len; i++) {
          bounds.extend(coords[i]);
        }

        if (fit) {
          _map.fitBounds(bounds, {
            padding: 20,
          });
        }

        return bounds;
      }
    }
  },

  /**
   * Обновление трека в массиве треков
   *
   * @param {String} id
   * @param {Object} toUpdate
   * @private
   */
  _updateDirectionTrack(id, toUpdate) {
    const dirCopy = _copy(_directions);

    for (const key in dirCopy) {
      if (dirCopy.hasOwnProperty(key)) {
        const index = (dirCopy[key].track || []).findIndex(it => {
          return it.id === id;
        });

        if (index > -1) {
          const track = dirCopy[key].track[index];

          Object.keys(toUpdate).forEach(uK => {
            track[uK] = toUpdate[uK];
          });

          dirCopy[key].track.splice(index, 1, _copy(track));

          break;
        }
      }
    }

    _directions = _copy(dirCopy);
  },

  /**
   * Генерация линии трека
   *
   * @param {String} id Идентификатор линии
   * @param {String} start Идентификатор точки начала
   * @param {String} end Идентификатор точки конца
   * @param {Array<Array<Number>>} coords Массив координат
   * @param {Number} index Индекс в массиве точек
   * @return {{
   *    geometry: {
   *      coordinates: *,
   *      type: string
   *    },
   *    id: *,
   *    type: string,
   *    properties: {
   *      color: *,
   *      start: *,
   *      end: *,
   *      coords: *
   *    }
   * }}
   * @private
   */
  _getLineTrack(id, start, end, coords, index) {
    const finals = _points.filter(it => it.isFinal || false);
    const finalLen = finals.length;

    let color = colors.red.base;

    if (finalLen === 2) {
      color = colors.purple.darken1;
    }

    if (finalLen === 3) {
      const centerIndex = _points.findIndex(it => it.id === finals[1].id);

      color = index < centerIndex ? colors.indigo.base : colors.green.darken1;
    }

    if (finalLen === 4) {
      const start = _points.findIndex(it => it.id === finals[1].id);
      const end = _points.findIndex(it => it.id === finals[2].id);

      if (index < start) {
        color = colors.indigo.darken1;
      } else if (start < index && index < end) {
        color = colors.teal.base;
      } else if (index > end) {
        color = colors.orange.darken1;
      }
    }

    return {
      id: id,
      type: 'Feature',
      properties: {
        start: start,
        end: end,
        coords: coords,
        color: color,
      },
      geometry: {
        type: 'LineString',
        coordinates: coords,
      },
    };
  },

  /**
   * Проверка что точка входит в границы
   *
   * @param {LngLat.LngLatBounds} bounds Границы
   * @param {Array<Number>} lngLat Массив координат
   * @private
   */
  _inBounds(bounds, lngLat) {
    const multipleCoords = (lngLat[0] - bounds['_ne']['lng']) * (lngLat[0] - bounds['_sw']['lng']);

    const lng = bounds['_ne']['lng'] > bounds['_sw']['lng']
      ? multipleCoords < 0
      : multipleCoords > 0;

    const lat = (lngLat[1] - bounds['_ne']['lat']) * (lngLat[1] - bounds['_sw']['lat']) < 0;

    return lng && lat;
  },

  /**
   * Обновление связей между остановками
   *
   * @param {Array<Object>} busStops Массив остановок
   * @private
   */
  _updateDirections(busStops) {
    _directions = {};

    // Заполнение связей между остановками
    for (let i = 0, len = busStops.length; i < len; i++) {
      const it = busStops[i];

      if (i === len - 1) {
        _directions[it.id] = {
          next: null,
        };
      } else {
        const next = busStops[i + 1];

        _directions[it.id] = {
          next: next.id,
        };
      }
    }
  },

  /**
   * Удаляет из _points подряд идущие дубли
   *
   * @param {Array<Object>} points Массив точек
   * @return {Array<Object>}
   * @private
   */
  _filterPoints(points) {
    return _copy(points).filter((it, index) => {
      if (index === 0) {
        return true;
      }

      const curr = points[index];
      const next = index + 1 < points.length ? points[index + 1] : null;

      if (next === null) {
        return true;
      }

      return !(next.coordinates[0] === curr.coordinates[0] && next.coordinates[1] === curr.coordinates[1]);
    });
  },
};