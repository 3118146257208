<script>
export default{
    props: {
        'owner': {
            type: Object,
            required: true
        }
    },
    data: () => ({
        /*params*/
        startDate: null,
        endDate: null,
        carrierId: null,
        /*params filters*/
        f_routeId_carrierId_not_null: null,
        f_routeId_carrierId_null: null,
        f_carrierId: null
    }),
    created(){
        console.log('10.5 created by', this.owner);
        
        this._setInitialParams();
        this._initingFilters();
        this._setDates();
        this.owner.on_set = this._set;
    },
    methods: {
        _setInitialParams(){
            var comp = this.owner.$refs["startDate"];
            this.startDate = this._setDateFormat(comp.value);
            comp = this.owner.$refs["endDate"];
            this.endDate = this._setDateFormat(comp.value);
        },
        _completingFilters(param){
            param = param.replaceAll(`param_carrierId`, this.carrierId)
                        .replaceAll(`param_startDate`, this.startDate)
                        .replaceAll(`param_endDate`, this.endDate);
            return param;
        },
        _setDateFormat(param){
            var result = null;
            if (param !== null) {
                result = $utils.formatDate(new Date(param), 'DD-MM-YYYY HH:mm:ss');
            }
            return result;
        },
        _set(param){
            console.log('10.5.set: ' + param.name + ' ', param.value);
            switch (param.name){
                case "carrierId":
                    this.carrierId = param.value;
                    this._setCarrier();
                    break;
                case "startDate":
                    this.startDate = this._setDateFormat(param.value);
                    this._setDates();
                    break;
                case "endDate":
                    this.endDate = this._setDateFormat(param.value);
                    this._setDates();
                    break;
            }
        },
        _setDates(){
            if (!$utils.isEmpty(this.startDate) && !$utils.isEmpty(this.endDate)) {
                var comp = this.owner.$refs["carrierId"];
                var filter = this._completingFilters(this.f_carrierId);
                comp.setFilter(filter);
                this._setCarrier();
            }
        },
        _setCarrier(){
            if (!$utils.isEmpty(this.startDate) && !$utils.isEmpty(this.endDate)) {
                var comp = this.owner.$refs["routeId"];
                var filter = '';
                if (!$utils.isEmpty(this.carrierId)) {
                    filter = this._completingFilters(this.f_routeId_carrierId_not_null);
                } else {
                    filter = this._completingFilters(this.f_routeId_carrierId_null);
                }
                comp.setFilter(filter);
            }
        },
        _initingFilters(){
            this.f_routeId_carrierId_not_null = 
                    `and(
                        not(eq(field(\".verStatus.Name\"),param(\"Проект\", \"string\"))),
                        exists(\"e8fd22eb-f901-42af-874f-2bf5fe6a78f4\", \"
                            and(
                                eq(field(\\\".carrier\\\"),param(\\\"param_carrierId\\\", \\\"id\\\")),
                                eq(super.field(\\\".ID\\\"),field(\\\".route\\\")),
                                or(
                                    isnull(field(\\\".endDt\\\")),
                                    gte(field(\\\".endDt\\\"),param(\\\"param_startDate\\\", \\\"date\\\"))
                                ),
                                lte(field(\\\".startDt\\\"),param(\\\"param_startDate\\\", \\\"date\\\"))
                            )
                        \"),
                        or(
                            isnull(field(\".endDt\")),
                            gte(field(\".endDt\"),param(\"param_startDate\", \"date\"))
                        ),
                        or(
                            isnull(field(\".verEndTime\")),
                            gte(field(\".verEndTime\"),param(\"param_startDate\", \"date\"))
                        ),
                        and(
                            not(isnull(field(\".verStart\"))),
                            lte(field(\".verStart\"),param(\"param_startDate\", \"date\"))
                        ),
                        and(
                            not(isnull(field(\".startDt\"))),
                            lte(field(\".startDt\"),param(\"param_startDate\", \"date\"))
                        )
                    )`;
            this.f_routeId_carrierId_null = 
                    `and(
                        not(eq(field(\".verStatus.Name\"),param(\"Проект\", \"string\"))),
                        or(
                            isnull(field(\".endDt\")),
                            gte(field(\".endDt\"),param(\"param_startDate\", \"date\"))
                        ),
                        or(
                            isnull(field(\".verEndTime\")),
                            gte(field(\".verEndTime\"),param(\"param_startDate\", \"date\"))
                        ),
                        and(
                            not(isnull(field(\".verStart\"))),
                            lte(field(\".verStart\"),param(\"param_startDate\", \"date\"))
                        ),
                        and(
                            not(isnull(field(\".startDt\"))),
                            lte(field(\".startDt\"),param(\"param_startDate\", \"date\"))
                        )
                    )`;
            this.f_carrierId = 
                    `and(
                        or(
                            isnull(field(\".endDt\")),
                            gte(field(\".endDt\"),param(\"param_startDate\", \"date\"))
                        ),
                        lte(field(\".startDt\"),param(\"param_endDate\", \"date\"))
                    )`;
        }
    },
    render(h){
        h('i',{class: "rep-stub"});
    }
}
</script>