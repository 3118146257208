<template>
 <JetDialog
   title="Добавить тариф"
   v-model="show"
   applyButtonText="Добавить"
   :loading-apply-button="loadingBtn"
   :disabledApplyButton="disabledSend"
   @on-apply-click="create"
   @on-cancel-click="show = false"
 >

   <!-- Перевозчик -->
   <v-select
     label="Перевозчик"
     v-model="params.carrierId"
     :items="carriers"
     :loading="loadingCarriers"
     item-text="shortName"
     item-value="carrierId"
   ></v-select>

   <!-- Файл -->
   <v-file-input
     v-model="vFile"
     class="pt-0 mt-0 px-2"
     label="Прикрепить файл"
     color="primary"
     hide-details
     :loading="loadingFile"
     @change="convertFile"
     :clearable="false"
   ></v-file-input>

 </JetDialog>
</template>

<script>
// Components
import JetDialog from "@/components/JetDialog";

// Service
import OtherService from "@/services/OtherService";

// Mixins
import SendFileMixin from "@/components/dev/mixin/SendFileMixin";

export default {
  name: "CreateTariff",
  components: {
    JetDialog,
  },
  mixins: [
    SendFileMixin,
  ],
  props: {
    value: {
      type: Boolean,
      required: true,
    }
  },
  data: () => ({
    params: {
      carrierId: '',
      file: null,
    },
    carriers: [],
    loadingCarriers: true,
    loadingBtn: false,
  }),
  computed: {
    show: {
      get() {
        return this.value;
      },
      set() {
        this.$emit('input', false);
      },
    },
    disabledSend() {
      return !this.params.carrierId || !this.file;
    },
  },
  created() {
    this.init();
  },
  methods: {
    async init() {
      this.loadingCarriers = true;
      this.carriers = await OtherService.getCarriers();
      this.loadingCarriers = false;
    },
    async create() {
      this.loadingBtn = true;
      this.params.file = this.toParams();
      await OtherService.createTariff(this.params);
      setTimeout(() => {
        this.$emit('refresh');
        this.loadingBtn = false;
      }, 1000);
    },
  },
}
</script>
