<template>
  <div class="px-4">
    <v-row>
      <v-col class="pt-0">
        <v-btn @click="back" color="primary">Назад</v-btn>
        <b class="ml-2">{{ incident.title }}</b>
        <span class="ml-2">{{ incident.date }}</span>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6" class="ml-1">
        {{ incident.text }}
      </v-col>
    </v-row>
    <v-row v-if="fileExist">
      <v-col class="pt-0">
        <v-icon>mdi-file-document</v-icon>
        <a :href="fileUrl">{{ fileName }}</a>
      </v-col>
    </v-row>
  </div>
</template>

<script>

export default {
  name: "incidentPage",
  props: {
    incident: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    fileExist: false,
    fileName: '',
    fileUrl: '',
  }),
  created() {
    this.setFile(this.incident.file);
  },
  methods: {
    back() {
      this.$emit('back');
    },
    setFile(file) {
      if (file) {
        this.fileName = file?.attrs?.['Key{name=fileName, type=java.lang.String}'] || 'Файл';
        this.fileUrl = '/rpc/?d=file&uri=' + file.ref;
        this.fileExist = true;
      }
    },
  },
};
</script>
