const MAP_ICONS = [         // see @/assets/images/map/*.png
    'arrow-direction',      // направление движения (стрелка)
    'arrow-direction-selected',
    'map-track-a',          // начало трека
    'map-track-b',          // конец трека
    'map-track-pause',      // стоянка
    'map-track-stop',       // остановка
    'bus-moving',           // автобус
    'bus-parking',          // автобус без движения
    'bus-stop',             // остановка
    'flag-red',             // флаг
    'map-overspeed'         // превышение скорости
];

class MapIcons {
    constructor(map){
        this.map = map;
    }
    async init(){
        const all = [];

        MAP_ICONS.map(i => {
            if (this.map.hasImage(i)){
                return;
            }
            all.push(new Promise((resolve)=>{
                const source = require(`@/assets/images/map/${i}.png`),
                      img = new Image();
                img.src = source;
                img.onload = ()=>{
                    this.map.addImage(i, img);
                };
                resolve({ name: i, image: img });
            }));
        });
        await Promise.all(all);
    }   // init
}

export default MapIcons;
