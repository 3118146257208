<template>
  <div>

    <!-- Назад, Имя маршрута, План/факт -->
    <v-row align="center" fluid>
      <v-col>
        <v-btn @click="back" color="primary">
          Назад
        </v-btn>
        <b class="px-4"> {{ selectedRoute.routeName }} </b>
        <span class="grey--text">Уровень планирования: </span>
        <span
          class="red--text"
          :class="{'green--text': selectedRoute.completed}"
        >
          {{ selectedRoute.routePlan }}
        </span>
      </v-col>
    </v-row>

    <!-- Кол-во рейсов, "Только незапланированные", Поиск ТС -->
    <v-row align="center">

      <!-- Выезды/Рейсы -->
      <v-col cols="3">
        <template v-if="!loadingSchedules">
          <span v-html="departureTripTitle"/>
        </template>
      </v-col>

      <v-col cols="5" class="d-flex justify-end">
        <!-- Выбрать все -->
        <v-checkbox
          class="mt-0 mr-2"
          v-model="selectAll"
          hide-details
          label="Выбрать все"
        ></v-checkbox>

        <!-- Только незапланированные -->
        <v-checkbox
          class="mt-0"
          v-model="onlyUnplanned"
          hide-details
          label="Только незапланированные"
        ></v-checkbox>
      </v-col>

      <!-- Искать по ТC -->
      <v-col cols="4">
        <JetSearchField
          label="Искать ТC"
          v-model="searchVehicle"
        ></JetSearchField>
      </v-col>
    </v-row>

    <template v-if="!loadingSchedules">
      <v-row style="height: 500px">
        <v-col class="fill-height pt-0" cols="8" style="overflow: auto" :aria-disabled="true">

          <!-- Компоненты Выезд или Рейс -->
          <template :class="{'pb-12' : selected.length > 0}">
            <template v-for="departure in filteredSchedules">
              <DepartureItem
                v-if="departure.isDeparture"
                :key="departure.id"
                v-model="selected"
                :item="departure"
                :fullDate="fullDate"
                :disabled="rightDialog"
                @plan="planButton"
                @deleted="deletedTrips"
                @add-vehicle="addVehicle"
                @click-checkbox="clickCheckbox"
                :onlyUnplanned="onlyUnplanned"
              ></DepartureItem>
              <TripItem
                v-else
                :key="departure.id"
                v-model="selected"
                :item="departure.schedules[0]"
                :fullDate="fullDate"
                :disabled="rightDialog"
                @plan="planButton"
                @deleted="deletedTrips"
                @add-vehicle="addVehicle"
                @click-checkbox="clickCheckbox"
              ></TripItem>
            </template>
          </template>

          <v-card v-if="cardShow" style="max-width: 400px; min-height: 160px">
            <v-card-title>
              <v-icon color="warning" class="mr-2">
                mdi-alert
              </v-icon>
              Внимание
            </v-card-title>
            <v-card-text>{{cardText}}</v-card-text>
          </v-card>
        </v-col>

        <!-- Поле для выбора ТС -->
        <v-col class="fill-height pt-0" cols="4" style="overflow: auto">
          <div v-if="!rightDialog" class="d-flex justify-center">
            Выберите рейс(ы) для назначения транспорта
          </div>
          <template v-else>
            <v-radio-group
              v-model="selectedVehicle"
              class="pt-0 mt-0"
            >
              <template v-for="vehicle in filteredVehicles">
                <v-radio
                  class="mt-0 mb-4"
                  :key="vehicle.govnum"
                  :label="vehicle.govnum"
                  :value="vehicle.vehicleId"
                  color="primary"
                ></v-radio>
              </template>
            </v-radio-group>
          </template>
        </v-col>
      </v-row>

      <!-- Кнопки управления -->
      <v-row>
        <v-col cols="8" align="right">

          <!-- Удалить -->
          <v-btn
            color="red"
            class="white--text mr-2"
            :disabled="selected.length === 0 || rightDialog || isTwoVehicle"
            @click="dialogDelete = true"
          >
            Удалить
          </v-btn>

          <!-- Диалог удаления -->
          <v-dialog v-model="dialogDelete" persistent max-width="600px">
            <v-card>
              <v-card-title>
                <span class="body-1">
                  Распланировать
                </span>
              </v-card-title>
              <v-card-text class="text">
                Вы уверены, что хотите распланировать {{ tripsName }}?
              </v-card-text>
              <v-card-actions>
                <v-spacer/>
                <v-btn
                  color="primary"
                  @click="dialogDelete = false"
                  :disabled="loadingDelete"
                >
                  Отмена
                </v-btn>
                <v-btn
                  class="white--text"
                  color="red"
                  @click="deleteTrips()"
                  :loading="loadingDelete"
                >
                  Снять
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <!-- Выбрать рейс(-ов) -->
          <v-btn
            color="primary"
            :disabled="selected.length===0 || rightDialog || isTwoVehicle"
            @click="planButton(null)"
          >
            Выбрать {{ selected.length }} рейс(-ов)
          </v-btn>
        </v-col>

        <!-- Отмена -->
        <v-col cols="1">
          <v-btn
            :disabled="!rightDialog || loadingPlanButton"
            color="primary"
            @click="closeSelectVehicle"
          >
            Отмена
          </v-btn>
        </v-col>

        <v-col cols="3" align="right">

          <!-- Планировать -->
          <template v-if="!isTwoVehicle">
            <v-btn
              v-if="rightDialog"
              :disabled="!selectedVehicle"
              color="primary"
              @click="dialogPlanning = true"
            >
              Планировать {{ selected.length }} рейс(-ов)
            </v-btn>
          </template>

          <!-- Выбрать ОП -->
          <v-btn
            v-else
            color="primary"
            @click="stopPointDialog = true"
          >
            Выбрать ОП
          </v-btn>

          <!-- Диалог для планирования -->
          <JetDialog
            title="Планировать"
            v-model="dialogPlanning"
            width="700"
            :show-dividers="false"
          >
            <template v-slot:default>
              Запланировать на {{ tripsName }} ТС с рег. номером <b>{{ govnum }}</b>.

              <div v-if="loadingCheck">
                Проверка на пересечения:
                <v-progress-circular size="20" indeterminate color="primary"/>
              </div>
              <div v-else-if="interactionTrips.length >= 1">
                <b>Внимание: </b>
                Планируемый автомобиль {{ govnum }} пересекается по времени с рейсом(-aми):
                <v-data-table
                  :headers="interactionHeader"
                  :items="interactionTrips"
                >
                  <template #item.actions="{ item }">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          fab x-small depressed
                          v-on="on"
                          v-bind="attrs"
                          @click="showRemoveDialog(item)"
                        >
                          <v-icon color="red">mdi-close</v-icon>
                        </v-btn>
                      </template>
                      Удалить
                    </v-tooltip>
                  </template>
                </v-data-table>

                <!-- Диалог удаления записи -->
                <JetDialog
                  title="Подтвердите действие"
                  v-if="dialogDeleteTrips"
                  v-model="dialogDeleteTrips"
                  :loading-apply-button="loadingDelete"
                  @on-cancel-click="dialogDeleteTrips = false, candidateDelete = null"
                  @on-apply-click="deleteTripIntersection"
                >
                  Вы уверены что хотите удалить планирование с рейса {{candidateDelete.tripCode}} принадлежащего маршруту {{candidateDelete.routeName}}?
                </JetDialog>
              </div>
            </template>
            <template v-slot:actions>
              <div class="px-4 d-flex" style="width: 100%;">
                <v-btn
                  color="primary"
                  @click="refresh()"
                  :disabled="loadingPlanButton"
                >
                  Отмена
                </v-btn>
                <v-spacer/>
                <v-btn
                  v-if="rightDialog"
                  :disabled="!selectedVehicle || loadingPlanButton"
                  color="primary"
                  @click="showPeriodPicker = true"
                  class="mr-2"
                >
                  Планировать на период
                </v-btn>
                <v-dialog
                  ref="dialog"
                  v-model="showPeriodPicker"
                  persistent
                  width="290px"
                >
                  <v-date-picker
                    v-model="datePeriodEnd"
                    scrollable
                    locale="ru-Latn"
                    :first-day-of-week="1"
                    :weekday-format="dayOfWeekToFormat"
                  >
                    <v-spacer/>
                    <v-btn color="primary" @click="showPeriodPicker = false, datePeriodEnd = fullDate.iso">
                      Отмена
                    </v-btn>
                    <v-btn color="primary" @click="planTripsOnPeriod(), showPeriodPicker = false">
                      Планировать
                    </v-btn>
                  </v-date-picker>
                </v-dialog>
                <v-btn
                  v-if="rightDialog"
                  :disabled="!selectedVehicle"
                  color="primary"
                  @click="planTrips"
                  :loading="loadingPlanButton"
                >
                  Планировать
                </v-btn>
              </div>
            </template>
          </JetDialog>

          <!-- Диалог выбора остановочного пункта -->
          <v-dialog v-model="stopPointDialog" persistent max-width="600px">
            <v-card>
              <v-card-title>
                <span class="body-1">
                  Добавление ТС на рейс {{ tripCode }}
                </span>
              </v-card-title>
              <v-card-text class="text">
                <v-autocomplete
                  v-model="stopPoint"
                  :items="stopPoints"
                  :loading="!loadingStopPoints"
                  item-text="name"
                  item-value="stopId"
                  label="Остановочный пункт"
                  placeholder="Введите название остановочного пункта"
                  return-object
                >
                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-title>
                        Нет данных (На маршруте 2 конечные ОП)
                      </v-list-item-title>
                    </v-list-item>
                  </template>
                </v-autocomplete>
              </v-card-text>
              <v-card-actions>
                <v-spacer/>
                <v-btn
                  color="primary"
                  @click="stopPointDialog = false"
                  :disabled="loadingPlanButton"
                >
                  Отмена
                </v-btn>
                <v-btn
                  v-if="rightDialog"
                  :disabled="!selectedVehicle"
                  color="primary"
                  @click="planTrips"
                  :loading="loadingPlanButton"
                >
                  Планировать
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-col>
      </v-row>
    </template>
    <div v-else class="b-spinner align-center mt-6" align="center">
      <v-progress-circular indeterminate color="primary"/>
    </div>

    <v-dialog v-model="dialogError" width="600px" persistent>
      <v-card>
        <v-card-title>
          Внимание
        </v-card-title>
        <v-card-text>
          {{ errorText }}
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn color="primary" @click="dialogError = false">Закрыть</v-btn>
          <v-spacer/>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// Service
import PlansService from "@/services/PlansService";
import VehicleService from "@/services/VehicleService";

// Component
import DepartureItem from "../../../DepartureItem";
import TripItem from "../../../TripItem";

// Mixin
import TripMixin from "../../../TripMixin";
import JetDialog from "@/components/JetDialog";
import JetSearchField from "@/components/JetSearchField";
import {dayOfWeekToFormat} from "@/services/JetDate";

export default {
  name: "RouteTrips",
  components: {
    JetSearchField,
    JetDialog,
    DepartureItem,
    TripItem,
  },
  props: {
    // Выбранный маршрут
    selectedRoute: {
      type: Object,
      required: true,
    },
    // Выбранная дата
    fullDate: {
      type: Object,
      required: true,
    },
  },
  mixins: [
    TripMixin,
  ],
  data: () => ({
    // Выбранное ТС
    selectedVehicle: null,
    govnum: null,
    dialogPlanning: false,
    loadingCheck: false,
    interactionTrips: [],
    interactionHeader: [
      {text: 'Код', value: 'tripCode'},
      {text: 'Маршрут', value: 'routeName'},
      {text: 'Действие', value: 'actions'},
      {text: '', value: ''},
    ],
    dialogDeleteTrips: false,
    candidateDelete: null,
    // Наличие изменений
    isRefresh: false,
    dayOfWeekToFormat: () => '',
    cardShow: false,
    cardText: '',
  }),
  watch: {
    searchVehicle(newValue) {
      this.filterVehicle(newValue);
    },
    fullDate() {
      this.loadTrips();
    },
    selectedRoute() {
      this.loadTrips();
    },
    selectedVehicle(value) {
      if (value) {
        const vehicle = this.filteredVehicles.find((vehicle) => {
          return vehicle.vehicleId === value;
        });
        this.govnum = vehicle?.govnum || '';
      } else {
        this.govnum = '';
      }
    },
    async dialogPlanning(val) {
      if (val) {
        this.checkIntersections();
      } else {
        this.interactionTrips = [];
      }
    },
  },
  created() {
    this.dayOfWeekToFormat = dayOfWeekToFormat;
    this.loadTrips();
  },
  computed: {
    departureTripTitle() {
      let depCount = 0;
      let tripCount = 0;
      this.schedules.forEach(departure => {
        if (departure.isDeparture) {
          depCount++;
        }
        tripCount += departure.schedules.length;
      });
      let resString = '';
      if (depCount > 0) {
        resString += '<b>Выезды: ' + depCount + '</b> / ';
      }
      resString += 'Рейсы: ' + tripCount;
      return resString;
    },
  },
  methods: {
    back() {
      this.$emit('back', this.changed);
      this.changed = false;
      this.rightDialog = false;
    },
    async loadTrips() {
      this.firstItem = null;
      this.rightDialog = false;
      this.selectedVehicle = null;
      this.selectAll = false;
      this.isTwoVehicle = false;
      this.onlyUnplanned = false;
      this.selected = [];
      this.loadingSchedules = true;
      this.vehicles = await VehicleService.getIdAndGovnum(
        this.selectedRoute.carrierId,
        $utils.formatDate(new Date(this.fullDate.iso), this.DATE_FORMAT),
      );
      this.filteredVehicles = this.vehicles.slice();
      this.schedules = await PlansService.getTrips(
        $utils.formatDate(new Date(this.fullDate.iso), this.DATE_FORMAT),
        this.selectedRoute.routeId,
        this.vehicles,
      );

      let plannedVehicles = [];
      let vehiclesTime = [];

      this.schedules.forEach(schedule => {
        schedule.schedules.forEach(sc => {
          if (sc.trips.length > 0) {
            if (plannedVehicles.indexOf(sc.trips[0].vehicleId) === -1) {
              plannedVehicles.push(sc.trips[0].vehicleId);
            }

            if (sc.trips.length === 1) {
              const ind = vehiclesTime.findIndex(item => item.vId === sc.trips[0].vehicleId);

              if (ind === -1) {
                vehiclesTime.push({
                  vId: sc.trips[0].vehicleId,
                  lastEnd: (new Date(sc.endTime)).setYear(2020),
                  lastStart: (new Date(sc.startTime)).setYear(2020),
                  timeSumm: (((new Date(sc.endTime)).setYear(2020)) - ((new Date(sc.startTime)).setYear(2020))) / 1000 / 60 / 60,
                  flights: [],
                });
              } else {
                let curVehicle = vehiclesTime[ind];

                if ((new Date(sc.startTime)).setYear(2020) - curVehicle.lastEnd >= 45 * 60 * 1000) {
                  if (!vehiclesTime[ind].flights.length) {
                    vehiclesTime[ind].lastStart = (new Date(sc.startTime)).setYear(2020);
                    vehiclesTime[ind].lastEnd = (new Date(sc.endTime)).setYear(2020);
                    vehiclesTime[ind].timeSumm = (((new Date(sc.endTime)).setYear(2020)) - ((new Date(sc.startTime)).setYear(2020))) / 1000 / 60 / 60;
                  }
                } else {
                  vehiclesTime[ind].lastEnd = (new Date(sc.endTime)).setYear(2020);

                  if (vehiclesTime[ind].timeSumm < 4) {
                    if ((curVehicle.lastEnd - curVehicle.lastStart) / 1000 / 60 / 60 >= 4) {
                      sc.violations = {
                        type: '',
                        text: 'Необходима сервисная стоянка не менее 45 минут или смена водителя',
                      };

                      schedule.hasViolations = true;

                      let nSc = _copy(sc);
                      vehiclesTime[ind].flights.push(nSc);
                    }
                  } else {
                    // let nSc = _copy(sc);
                    // vehiclesTime[ind].flights.push(nSc);
                  }

                  vehiclesTime[ind].timeSumm = (curVehicle.lastEnd - curVehicle.lastStart) / 1000 / 60 / 60;
                }
              }
            }
          }
        });
      });

      let plan = 0;
      let total = 0;
      for (const schedule of this.schedules) {
        plan += schedule?.plan || 0;
        total += schedule?.total || 0;
      }

      if (plan === 0 && total === 0) {
        this.cardShow = true;
        this.cardText = 'Для данного маршрута отсутствуют графики на выбранную дату';
      } else {
        this.cardShow = false;
        this.cardText = '';
      }

      this.selectedRoute.routePlan = plan + '/' + total;
      this.selectedRoute.completed = (plan / total) === 1;
      this.loadingSchedules = false;
    },
    planButton(schedules) {
      this.searchVehicle = '';

      if (schedules) {
        this.clearSelected();
        this.selected = schedules;
      }

      this.rightDialog = true;
    },
    filterVehicle(value) {
      if (!this.isTwoVehicle) {
        if (!value) {
          this.filteredVehicles = this.vehicles.slice();
        } else {
          const regexp = new RegExp(`${value}`, 'i');
          this.filteredVehicles = this.vehicles
            .filter(vehicle => regexp.test(vehicle.govnum));
        }
      } else {
        if (!value) {
          this.filteredVehicles = this.vehiclesTrip.slice();
        } else {
          const regexp = new RegExp(`${value}`, 'i');
          this.filteredVehicles = this.vehiclesTrip
            .filter(vehicle => regexp.test(vehicle.govnum));
        }
      }
    },
    closeSelectVehicle() {
      this.rightDialog = false;
      this.selectedVehicle = null;
      this.isTwoVehicle = false;
      this.clearSelected();
    },
    showRemoveDialog(item) {
      this.candidateDelete = item;
      this.dialogDeleteTrips = true;
    },
    async deleteTripIntersection() {
      this.loadingDelete = true;
      await PlansService.deleteTrips(
        this.candidateDelete.tripId,
        this.candidateDelete.startDt,
      );
      this.isRefresh = true;
      this.loadingDelete = false;
      this.dialogDeleteTrips = false;
      this.candidateDelete = null;
      await this.checkIntersections();
    },
    async checkIntersections() {
      this.loadingCheck = true;
      const promises = [];
      this.selected.forEach(trip => {
        const startTime = this.fullDate.iso + ' ' + $utils.formatDate(new Date(trip.startTime), 'HH:mm');
        const endTime = this.fullDate.iso + ' ' + $utils.formatDate(new Date(trip.endTime), 'HH:mm');
        promises.push(PlansService.intersectionTrips(this.selectedVehicle, startTime, endTime));
      });
      await Promise.all(promises).then(res => {
        console.log(res);
        let interactionTrips = [];
        res.forEach(forTrip => {
          forTrip.forEach(intersectionTrip => {
            interactionTrips.push(intersectionTrip);
          });
        });
        // delete duplicates
        const temp = {};
        for (let i = 0, len = interactionTrips.length; i < len; i++)
          temp[interactionTrips[i]['id']] = interactionTrips[i];

        interactionTrips = new Array();
        for (const key in temp)
          interactionTrips.push(temp[key]);
        this.interactionTrips = interactionTrips;
        this.loadingCheck = false;
      });
    },
    refresh() {
      this.dialogPlanning = false;
      if (this.isRefresh) {
        this.deletedTrips();
        this.isRefresh = false;
      }
    },
  },
};
</script>
