<script>
import { NULL_ID, isEmpty } from "@/utils";

/**
 * Report (64ea0b26-3db8-4f93-9f31-22ab4b100200): 10.33 Расписание по маршруту 
 * params handle
 */
export default{
    name: "Rep1033",
    props: {
        owner: {
            type: Object,
            required: true
        }
    },
    data: () => ({
        /*params*/
        date: null,
        route: null,
        scheduleFilter: ''
    }),
    created(){
        console.log('10.33 created by', this.owner);
        this.owner.on_set = this._set;
        this._setRoute(null);
    },
    methods: {
        _set(param){
            console.log('10.33 (set): ', param);
            switch (param.name){
                case "formDt":
                    this._setDate(param.value);
                    break;
                case "routeID":
                    this._setRoute(param.value);
                    break;
            }
        },
        _setDate(dt){
            this.date = dt;
        },
        _setRoute(id){
            this.route = id;
            const comp = this.owner.$refs["releaseScheduleID"];
            const filter = `eq(field(\"vcReleaseSchedules.route\"),param(\"${ isEmpty(id) ? NULL_ID : id }\", \"id\"))`;
            console.log('comp (filter)', comp, filter);
            comp.setFilter(filter);
        }
    },
    render(h){
        h('i',{class: "rep-stub"});
    }
}
</script>