import store from '@/store';

/**
 * Сервис предназначенный для работы с профилем
 */
export default class ProfilesService {
  static async getProfiles(carrierId = null) {
    const tenantId = store.state.auth.subject.tenantId;
    const filter = carrierId
      ? `eq(field(".id"),param("${carrierId}","id"))`
      : `security.checkTenant(param("${tenantId}","id"))`;

    const data = await jet.http.post({
      type: 'core-read',
      query: `sin2:/v:ad8fd1d7-e1b8-4edc-926c-dfd45c2da6ae/?filter=${filter}`,
    });

    if (!!data && data.result) {
      const result = data.result || {};
      const items = result.data || [];
      const ci = result.columnIndexes || {};

      const autoCarriers = items.map(it => ({
        carrierId: it[ci['vcautocarrier.id']],
        orgId: it[ci['vcautocarrier.orgid']],
        hdrId: it[ci['vcautocarrier.orgid.hdrid']],
        nameLeader: it[ci['vcautocarrier.orgid.hdrid.fio']] || '',
        orgPlace: it[ci['vcautocarrier.orgid.orgplace']] || '',
        factAddress: it[ci['vcautocarrier.orgid.factaddress']] || '',
        orgTlf: it[ci['vcautocarrier.orgid.orgtlf']] || '',
        email: it[ci['vcautocarrier.orgid.email']] || '',
        title: it[ci['vcautocarrier.orgid.shortname']] || '',
        phoneLeader: it[ci['vcautocarrier.orgid.hdrid.phone']] || '',
      }));

      return autoCarriers || [];
    }
    if (data.error) {
      jet.msg({ text: 'Возникла проблема. Повторите запрос позже', color: 'error' });
    }

    return [];
  };


  static async confirmProfile(orgId, hdrId, change) {
    const hdrParams = [];
    let newHdrid = null;
    if (!hdrId) {
      newHdrid = jet.utils.uuidv4();
    }
    if (change.nameLeader) {
      const [surname, name, ...patronymic] = change.nameLeader.split(' ');
      hdrParams.push(
        { id: 'surname', type: 'string', value: surname || '' },
      );
      hdrParams.push(
        { id: 'name', type: 'string', value: name || '' },
      );
      hdrParams.push(
        { id: 'patronymic', type: 'string', value: patronymic.join(' ') || '' },
      );
    }
    if (change.phoneLeader) {
      hdrParams.push(
        { id: 'phone', type: 'string', value: change.phoneLeader },
      );
    }
    if (hdrParams.length > 0) {
      hdrParams.push(
        { id: 'id', type: 'id', value: hdrId || newHdrid },
      );
      const data = await jet.http.post({
        type: hdrId ? 'core-update' : 'core-create',
        // View Физическое лицо
        query: 'sin2:/v:155be56f-49d5-44ad-8dca-3e08cef92539',
        params: hdrParams,
      });

      if (data.error) {
        if (newHdrid) {
          jet.msg({ text: 'Возникла проблема. При создании нового физ лица. Повторите запрос позже', color: 'error' });
          newHdrid = null;
        } else {
          jet.msg({ text: 'Возникла проблема. Повторите запрос позже', color: 'error' });
        }
      }
    }

    const orgParams = [];
    if (change.orgPlace) {
      orgParams.push(
        { id: 'orgplace', type: 'string', value: change.orgPlace },
      );
    }
    if (change.factAddress) {
      orgParams.push(
        { id: 'factaddress', type: 'string', value: change.factAddress },
      );
    }
    if (change.orgTlf) {
      orgParams.push(
        { id: 'orgtlf', type: 'string', value: change.orgTlf },
      );
    }
    if (change.email) {
      orgParams.push(
        { id: 'email', type: 'string', value: change.email },
      );
    }
    if (change.shortName) {
      orgParams.push(
        { id: 'shortname', type: 'string', value: change.shortName },
      );
    }
    if (newHdrid) {
      orgParams.push(
        { id: 'hdrid', type: 'id', value: newHdrid },
      );
    }
    if (orgParams.length > 0) {
      orgParams.push(
        { id: 'id', type: 'id', value: orgId },
      );
      jet.http.post({
        type: 'core-update',
        // View Юрлицо
        query: 'sin2:/v:2e4490d8-64f9-468d-b74e-ff4350a2b128',
        params: orgParams,
      });
    }
  };

  static async getModificationProfile(carrierId) {

    const data = await jet.http.post({
      type: 'core-read',
      query: `sin2:/v:77dfb31f-1ffb-4fe5-9718-f75670b1ccda/?filter=eq(field(".carrierid"),param("${carrierId}","id"))`,
    });

    if (!!data && data.result) {
      const result = data.result || {};
      const items = result.data || [];
      const ci = result.columnIndexes || {};

      const requests = items.map(it => ({
        carrierId: it[ci['vcaccountchanges.carrierid']],
        orgId: it[ci['vcaccountchanges.orgid']],
        hdrId: it[ci['vcaccountchanges.hdrid']],
        status: it[ci['vcaccountchanges.status']],
        comment: it[ci['vcaccountchanges.comment']],
        regdt: it[ci['vcaccountchanges.regdt']],
        moderationdt: it[ci['vcaccountchanges.moderationdt']],
        changes: JSON.parse(it[ci['vcaccountchanges.changes']]),
      }));

      let maxDate = requests?.[0] || null;
      for (const request of requests) {
        const curDate = new Date(request.regdt).getTime();
        const oldDate = new Date(maxDate.regdt).getTime();
        if (curDate > oldDate) {
          maxDate = request;
        }
      }
      return maxDate || null;
    }

    if (data.error) {
      jet.msg({ text: 'Возникла проблема. Повторите запрос позже', color: 'error' });
    }

    return null;
  };

  static async submitModificationProfile(carrierId, orgId, hdrId, changes) {
    const date = $utils.formatDate2(new Date(), 'yyyy-MM-dd HH:mm:ss');

    const data = await jet.http.post({
      type: 'core-create',
      query: 'sin2:/v:77dfb31f-1ffb-4fe5-9718-f75670b1ccda',
      params: [
        { id: 'id', type: 'id', value: jet.utils.uuidv4() },
        { id: 'carrierid', type: 'id', value: carrierId },
        { id: 'orgid', type: 'id', value: orgId },
        { id: 'hdrid', type: 'id', value: hdrId },
        { id: 'regdt', type: 'date', value: date },
        { id: 'changes', type: 'string', value: changes },
      ],
    });

    if (data.error) {
      jet.msg({ text: 'Возникла проблема. Повторите запрос позже', color: 'error' });
    }
  };

  static async editStatusModificationProfile(id, status, comment = '') {
    const date = $utils.formatDate2(new Date(), 'yyyy-MM-dd HH:mm:ss');

    await jet.http.post({
      type: 'core-update',
      query: 'sin2:/v:77dfb31f-1ffb-4fe5-9718-f75670b1ccda',
      params: [
        { id: 'id', type: 'id', value: id },
        { id: 'status', type: 'boolean', value: status },
        { id: 'moderationdt', type: 'date', value: date },
        { id: 'comment', type: 'string', value: comment },
      ],
    });
  };

  static async getModificationProfilesTable() {
    const data = await jet.http.post({
      type: 'core-read',
      query: `sin2:/v:28491de2-3c69-4607-a578-04381570ad07/?filter=isnull(field(".moderationdt")`,
    });

    if (!!data && data.result) {
      const result = data.result || {};
      const items = result.data || [];
      const ci = result.columnIndexes || {};

      const requests = items.map(it => ({
        carrierId: it[ci['vcaccountchanges.carrierid']],
        orgId: it[ci['vcaccountchanges.carrierid.orgid']],
        hdrId: it[ci['vcaccountchanges.carrierid.orgid.hdrid']],
        status: it[ci['vcaccountchanges.status']],
        comment: it[ci['vcaccountchanges.comment']],
        regdt: it[ci['vcaccountchanges.regdt']],
        moderationdt: it[ci['vcaccountchanges.moderationdt']],
        changes: JSON.parse(it[ci['vcaccountchanges.changes']]),
        id: it[ci['vcaccountchanges.id']],
        title: it[ci['vcaccountchanges.carrierid.orgid.shortname']],
      }));
      return requests;
    }

    if (data.error) {
      jet.msg({ text: 'Возникла проблема. Повторите запрос позже', color: 'error' });
    }

    return {};
  };
  
  static async reloadSending() {
        const tenantId = store.state.auth.subject.tenantId;
        const response = await jet.http.post(`/rpc?d=jsonRpc`, {
          type: 'query',
          query: '31b8f9f2-86db-4498-bd03-da4b37a00992.update_vcdailyreports',
          params: {
            tenantId: tenantId,
          },
        });
        
        const result = response.result && response.result.data || [];
        
        return result;
  }
}
