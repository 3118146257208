<script>
export default {
    name: 'ServicedRoutes',
    methods: {
        
    },
    mounted(){
        const self = this;
        self.$parent.beforeSave = () => {
            const form = self.$refs["form"];
            
            if(!form.validate()) {
                return false;
            }
            
        };
    }
}
</script>

<style lang="scss">

</style>