// Утилиты
import {prepareSinRequest, prepareSinResponse} from '@/utils/http';
// Vuex
import store from '@/store/index';

/**
 * Сервис для работы с версиями расписания
 */
export default class ReleaseSchedulesService {

    /**
     * Создание новой версии расписания
     *
     * @param Array<Object> params Параметры запроса
     * @return {Promise<Array<Object>|*[]>}
     */
    static async createNewReleaseSchedule(params) {
        return await jet.http.post({
            type: 'core-create',
            query: 'sin2:/v:694756e6-bdcf-4eb4-93ff-4bcbc81bef24',
            params: params,
        });
    }

    /**
     * Изменение версии расписания
     *
     * @param Array<Object> params Параметры запроса
     * @return {Promise<Array<Object>|*[]>}
     */
    static async updateReleaseSchedule(params) {
        return await jet.http.post({
            type: 'core-update',
            query: 'sin2:/v:694756e6-bdcf-4eb4-93ff-4bcbc81bef24',
            params: params,
        });
    }

    /**
     * Удаление версии расписания
     *
     * @param Array<Object> params Параметры запроса
     * @return {Promise<Array<Object>|*[]>}
     */
    static async deleteReleaseSchedule(params) {
        return await jet.http.post({
            type: 'core-delete',
            query: 'sin2:/v:694756e6-bdcf-4eb4-93ff-4bcbc81bef24',
            params: params,
        });
    }

    static async checkBeforeApprove(releasescheduleid, dt, mode) {
        try {
            return prepareSinResponse(
                    await prepareSinRequest(
                            'query',
                            '8a967d00-c3a5-425d-a7db-aab1c8d45f73.call_kih_kigat_pre_approval_schedule_check',
                            '',
                            [],
                            {
                                releasescheduleid: releasescheduleid,
                                dt: dt,
                                mode: mode
                            },
                            ),
                    );
        } catch (e) {
            console.error('ReleaseSchedulesService::checkBeforeApprove', e);
            return [];
        }
    }
    
        /**
     * Сохранение изменений расписания
     *
     * @param {Array<Object>} departures выезды
     * @param {Array<Object>} schedules рейсы
     * @param {Array<Object>} schedulestops остановки рейсов
     * @param {Array<Object>} wkmodesonschedule режимы работы перевозчиков
     * @return {Object}
     */
    static async updateSchedules(departures, releasescheduleid, schedules, schedulestops, wkmodesonschedule, serviceStops) {
        return jet.http.post('/rpc?d=jsonRpc', {
            type: 'query',
            query: '8a967d00-c3a5-425d-a7db-aab1c8d45f73.call_kih_kigat_save_schedules',
            params: {
                'in_userid': store.state.auth.subject.id,
                'in_tenantid': store.state.auth.subject.tenantId,
                'in_releasescheduleid': releasescheduleid,
                'in_departures': JSON.stringify(departures),
                'in_schedules': JSON.stringify(schedules),
                'in_schedulestops': JSON.stringify(schedulestops),
                'in_wkmodesonschedule': JSON.stringify(wkmodesonschedule),
                'in_servicestops': JSON.stringify(serviceStops),
            },
        });
    }
    static async getWkmodes() {
        try {
            let query = `sin2:/v:442c7557-183f-4757-aa37-72a7b9ac5ba8`;

            return prepareSinResponse(
                await prepareSinRequest(
                  'core-read',
                  query
                ),
                {
                  hideUnderscores: true,
                }
              );
          } catch (e) {
            console.error('ReleaseSchedulesService::getWkmodes', e);

            return [];
        }
    }
    
    static async getSeasons() {
        try {
            let query = `sin2:/v:7f02e156-88bc-4cc8-8b99-25cf7e27f740`;

            return prepareSinResponse(
                await prepareSinRequest(
                  'core-read',
                  query
                ),
                {
                  hideUnderscores: true,
                }
              );
          } catch (e) {
            console.error('ReleaseSchedulesService::getSeasons', e);

            return [];
        }
    }
    
    static async getWkmodesOnSchedule(schedule) {
        try {
            const data = await jet.http.post('/rpc?d=jsonRpc', {
              type: 'core-read',
              query: `sin2:/v:297d766a-9418-418a-94f8-e112c51b5f1b?filter=eq(field(".schedule"), param(\"${schedule}\", \"id\"))`,
            });

            if (!!data && data.result) {
              const response = data.result;
              const rData = response.data || [];

              const ci = response.columnIndexes || {};
              return rData.map(it => {
                return {
                  id: it[ci['vcwkmodesonschedule.id']],
                  wkmodeName: it[ci['vcwkmodesonschedule.wkmode.modname']],
                  carrierName: it[ci['vcwkmodesonschedule.carrier.orgid.shortname']],
                  carrier: it[ci['vcwkmodesonschedule.carrier']],
                  tenantid: it[ci['vcwkmodesonschedule.tenantid']],
                  wkmode: it[ci['vcwkmodesonschedule.wkmode']],
                };
              });
            }
        } catch (e) {
            return [];
        }
    }
                                            
    static async getCarriers(routeID, schedulesIDs) {
        try {
            var filter = `?filter=
                            or(
                              exists(\"6fa7167b-5072-4c47-8fb3-65527086b39e\",
                                  \"and(
                                      eq(super.field(\\\".id\\\"), field(\\\".carrierid\\\")),
                                      or(
                                          isnull(field(\\\".endcontractdt\\\")),
                                          gte(field(\\\".endcontractdt\\\"),var(\\\"dateWork\\\"))
                                      ),
                                      or(
                                          exists(\\\"e27d521f-e415-4172-bbf8-3445541bf7bd\\\",
                                              \\\"and(
                                                  eq(super.field(\\\\\\\".id\\\\\\\"), field(\\\\\\\".contractid\\\\\\\")),
                                                  eq(field(\\\\\\\".routeid.route\\\\\\\"), param(\\\\\\\"${routeID}\\\\\\\",\\\\\\\"id\\\\\\\")),
                                                  or(
                                                      isnull(field(\\\\\\\".scheduleid\\\\\\\")),
                                                      instr(str.concat(field(\\\\\\\".scheduleid\\\\\\\")), param(\\\\\\\"${schedulesIDs}\\\\\\\",\\\\\\\"string\\\\\\\"))
                                                  )
                                              )\\\"
                                          ),
                                          and(
                                              not(exists(\\\"e27d521f-e415-4172-bbf8-3445541bf7bd\\\",
                                                  \\\"and(
                                                      eq(super.field(\\\\\\\".id\\\\\\\"), field(\\\\\\\".contractid\\\\\\\")),
                                                      eq(field(\\\\\\\".routeid.route\\\\\\\"), param(\\\\\\\"${routeID}\\\\\\\",\\\\\\\"id\\\\\\\")),
                                                      not(isnull(field(\\\\\\\".scheduleid\\\\\\\")))
                                                  )\\\"
                                              )),
                                              exists(\\\"e27d521f-e415-4172-bbf8-3445541bf7bd\\\",
                                                  \\\"and(
                                                      eq(super.field(\\\\\\\".id\\\\\\\"), field(\\\\\\\".contractid\\\\\\\")),
                                                      eq(field(\\\\\\\".routeid.route\\\\\\\"), param(\\\\\\\"${routeID}\\\\\\\",\\\\\\\"id\\\\\\\"))
                                                  )\\\"
                                              )
                                          )
                                      )
                                  )\"
                              ),
                              exists(\"0e6241ee-2dd5-4860-89ba-0324c0f0e7fb\",
                                  \"and(
                                      eq(super.field(\\\".id\\\"), field(\\\".servicedRouteID.carrier\\\")),
                                      eq(field(\\\".servicedRouteID.route\\\"), param(\\\"${routeID}\\\",\\\"id\\\"))
                                  )\"
                              )
                          )`;
            const data = await jet.http.post('/rpc?d=jsonRpc', {
              type: 'core-read',
              query: 'sin2:/v:ad8fd1d7-e1b8-4edc-926c-dfd45c2da6ae' + filter,
            });

            if (!!data && data.result) {
              const response = data.result;
              const rData = response.data || [];

              const ci = response.columnIndexes || {};

              return rData.map(it => {
                return {
                  id: it[ci['vcautocarrier.id']],
                  orgId: it[ci['vcautocarrier.orgid']],
                  orgCode: it[ci['vcautocarrier.orgid.orgcode']],
                  shortName: it[ci['vcautocarrier.orgid.shortname']],
                };
              });
            }
        } catch (e) {
            return [];
        }
    }

    static async copyVersion(routeid, versionId) {
        return jet.http.post('/rpc?d=jsonRpc', {
            type: 'query',
            query: '8a967d00-c3a5-425d-a7db-aab1c8d45f73.copyVersion',
            params: {
                in_routeID: routeid,
                in_tenantID: store.state.auth.subject.tenantId,
                in_userID: store.state.auth.subject.id,
                in_releasescheduleid: versionId,
                in_date: new Date(),
                in_type: 1,
            },
        });
    }
    
    static async getServiceStops(stopId) {
        try {
            let query = `sin2:/v:0e482332-4689-4aae-ac62-50f2962efa6a?filter=eq(field(".scheduleStopID"), param(\"${stopId}\", \"id\"))`;

            return prepareSinResponse(
                await prepareSinRequest(
                  'core-read',
                  query
                ),
                {
                  hideUnderscores: true,
                }
              );
          } catch (e) {
            console.error('ReleaseSchedulesService::getServiceStops', e);

            return [];
        }
    }
    
    static async getServiceStopsTypes() {
        try {
            let query = `sin2:/v:6e6bd680-b0ad-438c-9b4d-7e56f08ad247`;

            return prepareSinResponse(
                await prepareSinRequest(
                  'core-read',
                  query
                ),
                {
                  hideUnderscores: true,
                }
              );
          } catch (e) {
            console.error('ReleaseSchedulesService::getServiceStopsTypes', e);

            return [];
        }
    }
      /**
   * Загрузка версий расписания с переданным фильтром
   *
   * @param {String} filter Фильтр для коллекции
   * @return {Promise<Array<Object>|*[]>}
   */
  static async getScheduleVersionsFiltered(filter) {
    try {
      let query = `sin2:/v:17743043-925a-48e4-90b5-8f1e77226a01?filter=${filter}`;

      return prepareSinResponse(
        await prepareSinRequest(
          'core-read',
          query
        )
      );
    } catch (e) {
      console.error('ReleaseSchedulesService::getScheduleVersionsFiltered', e);

      return [];
    }
  }
}
