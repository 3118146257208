const MBX_OPTS = [
    {
        t: 'pk.eyJ1IjoidmljdG9yMjh6IiwiYSI6ImNrdjlobGh2ajIxdHMyb2x1M3JxM2I3Z2kifQ.RQ8l-zofCuGhYBCEPF-sKA',
        s: 'mapbox://styles/victor28z/ckv9hm2kv67r415lhy0fk25qn'
    },
    {
        t: 'pk.eyJ1IjoidmljdG9yejI4LTMiLCJhIjoiY2t2OWozYWV2MXB0cTJxb2tkZmEyMDI2bSJ9.fFbVAWjNwIcytaYIZ72mDg',
        s: 'mapbox://styles/victorz28-3/ckv9j3pz93jta14qmlag1trie'
    },
    {
        t: 'pk.eyJ1IjoidmljdG9yejI4LTQiLCJhIjoiY2t2OWo4YjVwMjI5ZDJubHU0YTB5OWtxMyJ9.2UZsfipUoZlQa3OT3gnhNQ',
        s: 'mapbox://styles/victorz28-4/ckv9j8zbd68ya15nzuo02rgir' 
    }
];
const rnd =  Math.floor(Math.random() * MBX_OPTS.length);
export const MBX_STYLE = MBX_OPTS[rnd];