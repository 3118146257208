<template>
  <div>

    <!-- Поиск -->
    <v-row>
      <v-col>
        <JetSearchField
          label="Найти ТС"
          v-model="search"
        ></JetSearchField>
      </v-col>
      <v-col class="grey--text" align="right">
        Выберите ТС для планирования
      </v-col>
    </v-row>

    <!-- Таблица ТС -->
    <v-data-table
      :headers="headers"
      :items="vehicles"
      :loading="loadingVehicle"
      :search="search"
      @click:row="showTrips"
    ></v-data-table>
  </div>
</template>

<script>
import VehicleService from "@/services/VehicleService";
import JetSearchField from "@/components/JetSearchField";

export default {
  name: "TabVehicles",
  components: {
    JetSearchField,
  },
  props: {
    // Выбранная дата
    fullDate: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    // Поиск по таблице
    search: '',
    // Таблица
    headers: [
      {text: 'Рег.номер', value: 'gov'},
      {text: 'Перевозчик', value: 'oname'},
      {text: 'Планирование', value: 'tripsCount'},
    ],
    // ТС
    vehicles: [],
    loadingVehicle: true,
  }),
  created() {
    this.loadingRoutes();
  },
  methods: {
    showTrips(vehicle) {
      this.$emit('selectVehicle', vehicle);
    },
    async loadingRoutes() {
      this.loadingVehicle = true;
      this.vehicles = await VehicleService.getVehiclesDate(this.fullDate.iso);
      this.loadingVehicle = false;
    },
  },
};
</script>
