/**
 * Watch DOM-node by selector
 *
 * @param {String} selector
 * @returns {Promise}
 */
async function watch(selector) {
  const p = (resolve, reject) => {
    let _t, n = 0;

    const _watch = function() {
      n++;

      if (n > 100) {
        clearInterval(_t);
        reject();
      }

      const node = $(selector);

      if (node.length > 0) {
        clearInterval(_t);
        resolve(node);
      }
    };

    _t = setInterval(_watch, 100);
  };
  return new Promise(p);
}

export {
  watch,
};
