<template>
  <v-list-item
    class="vehicle"
    :style="cardStyle"
    :key="vehicle.id"
    @click="onClick"
  >
    <!-- Выбор ТС -->
    <v-list-item-action class="mr-0" @click.stop="onTrackingClick">
      <v-icon>
        mdi-checkbox-{{ checked ? 'marked' : 'blank' }}-outline
      </v-icon>
    </v-list-item-action>

    <!-- Заголовок -->
    <v-list-item-content class="ml-2">
      <v-list-item-title>
        <!-- Статус движения | Состояние датчика -->
        <v-tooltip top v-if="status">
          <template #activator="{ on, attrs }">
            <v-badge
              dot bottom overlap
              class="mt-0"
              v-on="on"
              v-bind="attrs"
              :color="stateColors[stateColor]"
            >
              <fa-icon
                class="mr-1 status_icon"
                style="display: inline-block;"
                v-on="on"
                v-bind="attrs"
                :icon="status.icon"
              ></fa-icon>
            </v-badge>
          </template>

          <span>{{ status.content }}, {{ stateName }}</span>
        </v-tooltip>

        {{ vehicle.govnum && vehicle.govnum.toUpperCase() || 'Нет номера' }}
      </v-list-item-title>

      <v-list-item-subtitle
        class="caption"
        v-if="vehicle.rent"
      >
        Аренда, {{ vehicle.rent.subcarrieridOrgidShortname }}
      </v-list-item-subtitle>
    </v-list-item-content>

    <!-- Отслеживать -->
    <v-list-item-action class="mx-1">
      <v-tooltip top>
        <template #activator="{ on, attrs }">
          <v-btn
            fab x-small icon
            v-on="on"
            v-bind="attrs"
            :color="checked ? 'primary' : 'gray lighten-5'"
            @click.stop="onTrackingClick"
          >
            <v-icon>mdi-eye</v-icon>
          </v-btn>
        </template>
        <span>Отслеживать</span>
      </v-tooltip>
    </v-list-item-action>

    <!-- Трек -->
    <v-list-item-action class="mr-1">
      <v-tooltip top>
        <template #activator="{ on, attrs }">
          <v-btn
            fab x-small icon
            v-on="on"
            v-bind="attrs"
            :loading="showTrackLoading"
            :color="showTrack ? 'primary' : 'gray lighten-5'"
            @click.stop="onShowTrackClick"
          >
            <v-icon>mdi-map</v-icon>
          </v-btn>
        </template>

        <span>Показать трек</span>
      </v-tooltip>
    </v-list-item-action>

    <!-- Дополнительное меню -->
    <v-list-item-action>
      <v-menu
        offset-x
        transition="slide-x-transition"
        v-model="showContextMenu"
        :disabled="vehicle.blockStatus"
      >
        <template #activator="{ on }">
          <v-btn
            fab x-small icon
            color="gray lighten-5"
            v-on="on">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>

        <!-- Содержимое меню -->
        <v-list>
          <!-- Загрузить трек за вчера -->
          <v-list-item @click="onTrackYesterday">
                Загрузить трек за вчера
          </v-list-item>
          <v-list-item @click="showPeriod">
                Загрузить трек за период
          </v-list-item>
          <v-list-item @click="onRunReport">
                Отчет о пробеге за сегодня
          </v-list-item>
          <v-list-item @click="onViolationSpeedReport">
                Отчет о нарушениях скоростного режима
          </v-list-item>
          <v-list-item @click="showTrackSettings = true;">
                Настройки отображения
          </v-list-item>
        </v-list>
      </v-menu>
    </v-list-item-action>

    <!-- Настройки карты -->
    <v-menu
      nudge-right="410"
      nudge-bottom="190"
      v-model="showTrackSettings"
      :close-on-content-click="false"
    >
      <MapSettings
        :value="trackSettings"
        :dialog="showTrackSettings"
        @input="onTrackSettingsChanged"
        @close="showTrackSettings = false"
      ></MapSettings>
    </v-menu>

    <!-- Диалог выбора периода трека -->
    <vc-choose-period ref="period" 
                      v-on:period="trackByPeriod($event)"/>
  </v-list-item>
</template>

<script>
import MapSettings from './MapSettings';
import { dayOfWeekToFormat } from '@/services/JetDate';
import CarrierUtils from '@/components/dev/service/CarrierUtils';
import MapSettingsService from '@/components/dev/service/MapSettingsService';
import VcChoosePeriod from '@/components/dev/components/map-vehi/VcChoosePeriod';

// Идентификатор отчета о пробеге
const RUN_REPORT_GUID = '5253a9c5-b4d9-4ea4-8eec-03b45dad6f01';
// Идентификатор отчета о превышении скорости
const SPEED_VIOLATION_REPORT_GUID = 'dfc8af5b-db89-4022-b9cc-1a4f17145fda';

// События ТС
const _lastEvents = CarrierUtils.lastEvents();

let _lastPeriodData = {
  startDate: $utils.formatDate(new Date(),'YYYY-MM-DD'),
  startTime: '00:00',
  endDate: $utils.formatDate(new Date(),'YYYY-MM-DD'),
  endTime: '00:00',
};

export default {
  props: {
    vehicle: {
      type: Object,
      required: true,
    },
  },
  components: {
    MapSettings,
    VcChoosePeriod
  },
  name: 'VehicleItem',
  inject: [
    'placePoint', 
    'removePoint',
    'drawTrack', 
    'removeTrack',
    'startTracking', 
    'stopTracking',
    'stopTrackingTotal', 
    'removeTrackTotal',
    'changeTrackStyle',
  ],
  data() {
    return {
      // Показ/Скрытие трека
      showTrack: false,
      // Загрузка показа трека
      showTrackLoading: false,
      // Показ настроек трека для карты
      showTrackSettings: false,
      // Отображение контекстного меню
      showContextMenu: false,
      // Начало даты
      startDate: {
        // Показ выбора даты
        showPicker: false,
      },
      // Начало времени
      startTime: {
        // Показ выбора начала времени
        showPicker: false,
      },
      // Конец даты
      endDate: {
        // Показ выбора конца даты
        showPicker: false,
      },
      // Конец времени
      endTime: {
        // Показ выбора конца времени
        showPicker: false,
      },

      // Состояние ТС по-умолчанию
      stateName: 'Нет данных',
      // Цвет состояния ТС по-умолчанию
      stateColor: 'gray',
      // Набор цветов состояний ТС
      stateColors: {
        green: '#A1D028',
        yellow: '#EBE320',
        orange: '#ED9511',
        red: '#F07C91',
        gray: '#BBBBBB',
      },

      // Настройка трека
      trackSettings: MapSettingsService.getSettings(this.vehicle),
      // Стили карточки ТС
      cardStyle: {},

      dayOfWeekToFormat: () => '',
    };
  },
  created() {
    // TODO: WTF? @see VehicleItem_old::created
    this.dayOfWeekToFormat = dayOfWeekToFormat;
    
    switch (this.vehicle.state) {
      case 0:
        this.stateName = 'На связи';
        this.stateColor = 'green';
        break;
      case 24:
        this.stateName = 'Потеря связи с датчиком от 2 до 24 часов';
        this.stateColor = 'yellow';
        break;
      case 72:
        this.stateName = 'Потеря связи с датчиком от 24 до 72 часов';
        this.stateColor = 'orange';
        break;
      case 100:
        this.stateName = 'Потеря связи с датчиком свыше 72 часов';
        this.stateColor = 'red';
        break;
      default:
        this.stateName = 'Нет данных о связи';
        this.stateColor = 'gray';
    }
    if (this.vehicle.blockStatus === true) {
      this.stateName = '-';
      this.stateColor = 'gray';
    }

    // Устанавливаем для текущей карточки стили отображения трека на карте
    this._changeCardStyle(
      MapSettingsService.getSettings(this.vehicle),
    );

    this._setState();
  },
  beforeUpdate() {
    // Реагируем на изменение объекта

    this._setState();
  },
  
  methods: {
    // Клик по карточке
    onClick() {
      this.onTrackingClick();
    },
    // Загрузить трек за сегодня
    onShowTrackClick() {
      if (this.vehicle.blockStatus) {
        return;
      }
      this.showTrack = !this.showTrack;

      if (this.showTrack) {
        //Убираем все ТС, убираем галочку и слежение
        this.stopTrackingTotal();
        this.checked = false;
        
        this.showTrackLoading = true;

        const dateStart = new Date();
        dateStart.setHours(0, 0, 0, 0);

        this.drawTrack(this.vehicle, dateStart).finally(() => {
          this.showTrackLoading = false;
        });

      } else {
        this.removeTrack(this.vehicle);
      }
    },
    // Загрузить трек за вчера
    onTrackYesterday() {
      //Убираем все ТС, убираем галочку и слежение
      this.stopTrackingTotal();
      this.checked = false;
      
      // Очистим трек если уже был
      if (this.showTrack) {
        this.removeTrack(this.vehicle);
      }

      const dateStart = new Date();
      dateStart.setDate(dateStart.getDate() - 1);
      dateStart.setHours(0, 0, 0, 0);
      
      const dateEnd = new Date();
      dateEnd.setDate(dateEnd.getDate() - 1);
      dateEnd.setHours(23, 59, 59, 0);
      
      this.showTrack = true;
      this.showTrackLoading = true;

      this.drawTrack(this.vehicle, dateStart, dateEnd).finally(() => {
        this.showTrackLoading = false;
      });
    },
    // Отслеживание
    onTrackingClick() {
      
      if (this.vehicle.blockStatus) {
        return;
      }
      this.checked = !this.checked;
      this.dispAttrs.draw = this.checked;   //sync
      
      if (this.checked) {
        // Убираем все треки и отжимаем кнопку
        this.removeTrackTotal();
        this.showTrack = false;
        
        this.startTracking(this.vehicle);
      } else {
        this.stopTracking(this.vehicle);
      }
    },
    // Закрытие диалога выбора времени показа трека
    trackByPeriod(period){
      this.showTrack = true;
      this.showTrackLoading = true;

      this.drawTrack(this.vehicle, period.start, period.end).finally(() => {
        this.showTrackLoading = false;
      });
        
    },
    closePeriodDialog() {
      this.showPeriodDialog = false;

      this.$store.commit('ptmap_date_track/startDate', _lastPeriodData.startDate);
      this.$store.commit('ptmap_date_track/startTime', _lastPeriodData.startTime);
      this.$store.commit('ptmap_date_track/endDate', _lastPeriodData.endDate);
      this.$store.commit('ptmap_date_track/endTime', _lastPeriodData.endTime);
    },
    // Разрешенные даты на выбор даты окончания
    allowedDates(date) {
      // Если не выбрана дата начала - нельзя выбрать дату конца
      if (this.$startDate === null) {
        return false;
      }

      const diffToday = this._allowDatesDiff(date);
      const diffStartDate = this._allowDatesDiff(date, this.$startDate);

      return diffStartDate >= 0 && diffToday <= 0;
    },
    // Разрешенные даты на выбор даты начала
    allowedStartDates(date) {
      // Если не выбрана дата начала - нельзя выбрать дату конца
      if (this.$startDate === null) {
        return false;
      }

      const diff = this._allowDatesDiff(date);

      return diff <= 0;
    },
    // Применение выбора диапазона дат
    applyDateRange() {
      //Убираем все ТС, убираем галочку и слежение
      this.stopTrackingTotal();
      this.checked = false;
      
      // Очистим трек если уже был
      if (this.showTrack) {
        this.removeTrack(this.vehicle);
      }

      this.showPeriodDialog = false;
      this.showTrack = true;
      this.showTrackLoading = true;

      this.drawTrack(
        this.vehicle,
        new Date(Date.parse(`${this.$startDate} ${this.$startTime}:00`)),
        new Date(Date.parse(`${this.$endDate} ${this.$endTime}:00`)),
      ).finally(() => {
        this.showTrackLoading = false;
      });
    },
    // Вызов отчета (Пробег за период)
    onRunReport() {
      this.showContextMenu = false;
      const currentDate = new Date();

      const startDate = new Date();
      startDate.setHours(0, 0, 0, 0);

      const args = [
        `"carrier": "${this.vehicle.acid}"`,
        `"vehicle": "${this.vehicle.id}"`,
        `"startDt": java.util.Date{time:${startDate.getTime()}l}`,
        `"endDt": java.util.Date{time:${currentDate.getTime()}l}`,
        `"on_day": true`,
        `"on_month": true`,
      ].join(',');

      this._sendToReport(RUN_REPORT_GUID, args);
    },
    // Вызов отчета 10.11 о нарушениях скоростного режима
    onViolationSpeedReport() {
      this.showContextMenu = false;
      const currentDate = new Date();

      const startDate = new Date();
      startDate.setHours(0, 0, 0, 0);

      const args = [
        `"carrier": "${this.vehicle.acid}"`,
        `"vehicle": "${this.vehicle.id}"`,
        `"startDate": java.util.Date{time:${startDate.getTime()}l}`,
        `"endDate": java.util.Date{time:${currentDate.getTime()}l}`,
        `"speed": 90`,
      ].join(',');

      this._sendToReport(SPEED_VIOLATION_REPORT_GUID, args);
    },
    // При изменении настроек трека
    onTrackSettingsChanged(params) {
      MapSettingsService.saveSettings(this.vehicle, params);

      this._changeCardStyle(params);

      if (this.showTrack) {
        this.changeTrackStyle(this.vehicle, params);
      }
    },
    // Установка значений от состояния
    _setState() {
      //TODO: Если состояние было изменено
      if (!!this.vehicle.__state) {
        // То помечаем было ли оно выбрано
        /*
        this.checked = this.vehicle.__state.draw || false;
        // Пометка что выбран трек
        this.showTrack = this.vehicle.__state.track || false;
        // Пометка что идет отслеживание
        this.isTracking = this.vehicle.__state.tracking || false;
        */
      }
    },
    // Изменение стилей карточки ТС
    _changeCardStyle(settings) {
      this.cardStyle = {
        'border-left': `3px solid ${settings.color}`,
      };
    },
    // Отправка запроса на отчет
    _sendToReport(reportGuid, args) {
      // TODO: не самое изящное решение, но я его просто скопировал и оно работает
      //   fetch почему-то не заработал
      const form = $('<form></form>').attr('action', '/rpc?d=report').attr('method', 'post').css({ display: 'none' });

      form.append($('<input/>').attr('type', 'text').attr('name', 'report').attr('value', reportGuid));
      form.append($('<input/>').attr('type', 'text').attr('name', 'args').attr('value', `@{${args}}`));
      form.append($('<input/>').attr('type', 'text').attr('name', 'ctx').attr('value', this._getReportContext()));

      form.appendTo('body').trigger('submit').remove();
    },
    // Получение контекста для формирования отчета
    _getReportContext() {
      let d = this.$store.getters['period/current'];
      let ctx = 'param{name:"dateWork",type:"date",value:java.util.Date{time:' + d.getTime() + 'l}},';

      d = this.$store.getters['period/begin'];
      ctx += 'param{name:"dateBegin",type:"date",value:java.util.Date{time:' + d.getTime() + 'l}},';

      d = this.$store.getters['period/end'];
      ctx += 'param{name:"dateEnd",type:"date",value:java.util.Date{time:' + d.getTime() + 'l}}';

      return `@[${ctx}]`;
    },
    // Форматирование дат
    _formatDate(date) {
      if (!date) {
        return null;
      }
      return $utils.formatDate(new Date(date),'DD.MM.yyyy');
    },
    // Разница текущей даты в календаре и выбранной
    _allowDatesDiff(endDate, startDate = new Date()) {
      const start = new Date(startDate);
      const current = new Date(endDate);

      return Math.ceil((current.getTime() - start.getTime()) / (1000 * 3600 * 24));
    },
    showPeriod(){
        this.$refs["period"].open();
    }
  },
  computed: {
    dispAttrs(){
        return this.vehicle.dispAttrs;
    },
    checked: {
        get(){
            return this.vehicle.dispAttrs?.draw || false;
        },
        set(val){
            this.vehicle.dispAttrs.draw = val;
            this.$emit('checked', val);
        }
    },
    // Стиль состояния
    stateStyle() {
      return {
        display: 'inline-block',
        width: '12px',
        height: '12px',
        'border-radius': '100%',
        'background-color': this.stateColors[this.stateColor],
      };
    },
    // Статус машинки
    status() {
      
      if (this.vehicle.blockStatus === true) {
          return {
            icon: 'minus-circle',
            content: 'Заблокирован',
          };
      }
      
      
      const status = (this.vehicle.last?.status || this.vehicle.telemetry?.status || '').toLowerCase();
      console.log('status', status);
      switch (status) {
        case _lastEvents.moving:
        case _lastEvents.speedUp:
          return {
            icon: 'play-circle',
            content: 'Движется',
          };
        case _lastEvents.stop:
          return {
            icon: 'pause-circle',
            content: 'Остановка',
          };
        case _lastEvents.parking:
          return {
            icon: ['fab', 'product-hunt'],
            content: 'Стоянка',
          };
        case _lastEvents.blocked:
          return {
            icon: 'stop-circle',
            content: 'Заблокирован',
          };
        default:
          return {
            icon: 'exclamation-circle',
            content: 'Нет данных о движении',
          };
      }
    },
    // Форматированная дата начала
    formatStartDate() {
      return this._formatDate(this.$startDate);
    },
    // Форматированная дата окончания
    formatEndDate() {
      return this._formatDate(this.$endDate);
    },
    $startDate: {
      get() {
        return this.$store.getters['ptmap_date_track/startDate'];
      },
      set(value) {
        this.$store.commit('ptmap_date_track/startDate', value);
        if (this._allowDatesDiff(this.$endDate, value) < 0) {
          this.$endDate = this.$startDate;
        }
      },
    },
    $startTime: {
      get() {
        return this.$store.getters['ptmap_date_track/startTime'];
      },
      set(value) {
        this.$store.commit('ptmap_date_track/startTime', value);
      },
    },
    $endDate: {
      get() {
        return this.$store.getters['ptmap_date_track/endDate'];
      },
      set(value) {
        this.$store.commit('ptmap_date_track/endDate', value);
      },
    },
    $endTime: {
      get() {
        return this.$store.getters['ptmap_date_track/endTime'];
      },
      set(value) {
        this.$store.commit('ptmap_date_track/endTime', value);
      },
    },
  },
  watch: {
      dispAttrs(attrs){
          this.checked = !!attrs.draw;
      }
  }
};
</script>

<style lang="scss" scoped>
.v-list-item.vehicle {
    min-height: 32px !important;
    padding-left: 2rem;
    & .v-list-item__action{
        margin: 0;
    }
    & .v-list-item__content{
        padding: 6px 0;
    }
}

.status_icon path {
  fill: var(--v-primary-base, blue);
}
</style>
