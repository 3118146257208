<template>
  <JetDialogData
    v-model="dialogCreate"
    :current-tab="currentTab"
    title="Создание инцидента"
    width="800"
  >
    <!-- Основная страница -->
    <template #tab-1>
      <v-row>
        <v-col class="pb-0">
          <v-select
            label="Тип инцидента"
            v-model="incidentEntity.type"
            item-text="name"
            item-value="id"
            :items="typesIncidents"
            :loading="loadingTypes"
            hide-details
          ></v-select>
        </v-col>
      </v-row>

      <v-row>
        <v-col class="pb-0">
          <v-text-field
            label="Заголовок"
            v-model="incidentEntity.title"
            hide-details
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col class="pb-0">
          <v-text-field
            label="Текст"
            v-model="incidentEntity.text"
            hide-details
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col class="pb-0">
          <v-file-input
            v-model="incidentEntity.vFile"
            label="Прикрепить файл"
            color="primary"
            hide-details
            :loading="incidentEntity.loadingFile"
            @change="convertFile"
          ></v-file-input>
        </v-col>
      </v-row>

      <v-row>
        <v-col class="pb-0">
          <v-btn color="primary" @click="currentTab = 1">
            Добавить перевозчика
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="pb-0">
          <v-data-table
            :headers="headerCandidate"
            :items="candidateForAddition"
            :items-per-page="7"
            style="width: 100%"
          >
            <template #item.clickDelete={item}>
              <!-- Удаление записи -->
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    fab x-small depressed
                    v-on="on"
                    v-bind="attrs"
                    @click="deleteCandidateCarrier(item)"
                  >
                    <v-icon color="red">
                      mdi-close
                    </v-icon>
                  </v-btn>
                </template>
                Удалить
              </v-tooltip>
            </template>
          </v-data-table>
        </v-col>
      </v-row>

      <v-row>
        <v-col class="pb-0" align="right">
          <v-btn
            class="mt-3 ml-4"
            color="primary"
            @click="newIncident"
            :disabled="candidateForAddition.length === 0"
            :loading="loadingCreate"
          >
            Отправить
          </v-btn>
        </v-col>
      </v-row>
    </template>

    <!--Выбор перевозчика-->
    <template #tab-2>
      <div>
        <v-text-field
          v-model="searchText"
          label="Поиск"
          hide-details
          class="mr-4"
        ></v-text-field>
      </div>

      <v-data-table
        v-model="selectedCarriers"
        :items="carriersList"
        :loading="loadingCarriers"
        :headers="headerSelected"
        item-key="carrierId"
        show-select
      ></v-data-table>

      <v-row>
        <v-col>
          <v-btn color="primary" @click="cancelAddCandidate">Отмена</v-btn>
        </v-col>
        <v-spacer/>
        <v-col align="right">
          <v-btn color="primary" @click="addCarrierInTable" :disabled="selectedCarriers.length === 0">Добавить
            {{ selectedCarriers.length }} перевозчиков
          </v-btn>
        </v-col>
      </v-row>
    </template>

  </JetDialogData>
</template>

<script>
import {prepareSinRequest, prepareSinResponse} from "@/utils/http";
import JetDialogData from "@/components/JetDialogData";
import OtherService from "@/services/OtherService";

export default {
  name: "CreateIncident",
  components: {JetDialogData},
  props: {
    value: {
      type: Boolean,
      required: true,
    }
  },
  data: () => ({
    currentTab: 0,
    // Сущность создаваемого инциндента
    searchText: '',
    incidentEntity: {
      title: '',
      text: '',
      type: '',
      vFile: null,
      file: null,
      loadingFile: false,
    },
    // Типы инцидентов
    typesIncidents: [],
    loadingTypes: false,
    carriers: [],
    loadingCarriers: true,
    headerSelected: [
      {text: 'Перевозчик', value: 'shortName'},
    ],
    selectedCarriers: [],
    headerCandidate: [
      {text: 'Перевозчик', value: 'shortName'},
      {text: '', value: 'clickDelete', align: 'end',},
    ],
    candidateForAddition: [],
    loadingCreate: false,
  }),
  computed: {
    dialogCreate: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    carriersList() {
      const regexp = new RegExp(this.searchText, 'i');
      return this.carriers.filter(it => regexp.test(it.shortName));
    }
  },
  created() {
    this.loadTypeIncidents();
    this.loadCarriers();
  },
  methods: {

    // Загрузка возможных типов инцидента
    async loadTypeIncidents() {
      this.loadingTypes = true;
      try {
        const types = await prepareSinResponse(
          await prepareSinRequest(
            'core-read',
            'sin2:/v:d2d2d684-b958-480b-bc57-9fe03066c824/'
          )
        );
        this.typesIncidents = types;
      } catch (e) {
        this.typesIncidents = [];
        console.error('Incidents::loadTypeIncidents', e);
      } finally {
        this.loadingTypes = false;
      }
    },

    // Загрузка списка перевозчиков
    async loadCarriers() {
      this.loadingCarriers = true;
      this.carriers = await OtherService.getCarriersHierarchyByRole();
      this.loadingCarriers = false;
    },

    // Закрытие диалога
    cancelAddCandidate() {
      this.currentTab = 0;
      this.selectedCarriers = [];
    },

    // Добавление перевозчика для отправки ему инцидента
    addCarrierInTable() {
      this.currentTab = 0;
      this.selectedCarriers.forEach(sC => {
        const duplicate = this.candidateForAddition.find(cFA => cFA.carrierId === sC.carrierId);
        if (!duplicate) {
          this.candidateForAddition.push(sC);
        }
        const carrierIndex = this.carriers.findIndex(cr => cr.carrierId === sC.carrierId);
        if (carrierIndex !== -1) {
          this.carriers.splice(carrierIndex, 1);
        }
      });

      this.selectedCarriers = [];
    },

    // Удаление перевозчика из списка на отправление инцидента
    deleteCandidateCarrier(carrier) {
      this.carriers.push(carrier);
      const carrierIndex = this.candidateForAddition.findIndex(cr => cr.carrierId === carrier.carrierId);
      if (carrierIndex !== -1) {
        this.candidateForAddition.splice(carrierIndex, 1);
      }
    },


    // Полный цикл создания инцидента с отправкой его выбранным перевозчикам
    async newIncident() {
      this.loadingCreate = true;
      const incidentId = jet.utils.uuidv4();
      if (await this.createIncident(incidentId)) {
        if (await this.sendIncidentToCarriers(incidentId)) {
          if (await this.scream(incidentId)) {
            jet.msg({
              color: 'primary',
              text: 'Инцидент успешно создан',
            });
          }
        }
      }
      this.loadingCreate = false;
      this.dialogCreate = false;
      this.$emit('refresh');
    },

    // Добавление инцедента
    async createIncident(incidentId) {
      const incident = this.incidentEntity;
      incident.date = new Date().toISOString();
      const params = [
        {id: 'id', type: 'id', value: incidentId},
        {id: 'type', type: 'id', value: incident.type},
        {id: 'title', type: 'string', value: incident.title},
        {id: 'text', type: 'string', value: incident.text},
        {id: 'eventdate', type: 'date', value: incident.date},
        {id: 'isread', type: 'boolean', value: false},
        {id: 'toall', type: 'boolean', value: false},
      ];
      if (incident.file) {
        params.push(
          {id: 'file', type: 'blob', 'value': incident.file.base64},
          {id: 'filename', type: 'string', value: incident.file.name}
        );
      }
      const data = await jet.http.post({
        type: 'core-create',
        query: 'sin2:/v:50437b1f-f300-439e-baab-5be6e46152fa',
        params,
      });
      if (data.error) {
        return this.error('Ошибка при записи инцидента');
      }
      return true;
    },

    // Отправка инцидента перевозчикам
    async sendIncidentToCarriers(incidentId) {
      let tenantsID = '{'
      this.candidateForAddition.forEach(cr => {
        tenantsID += `"${cr.tenantId}",`
      });
      tenantsID = tenantsID.substring(0, tenantsID.length - 1) + '}';
      const res = await prepareSinRequest(
        'query',
        'e9d62d7e-cdf8-4250-a60a-04130c2ad10e.addIncidentReciever',
        '',
        [],
        {
          in_userID: this.$store.state.auth.subject.id,
          in_incidentID: incidentId,
          in_tenantIDs: tenantsID,
        },
        {
          cache: false,
        },
      );
      if (res.error) {
        return this.error('Ошибка при отправке инцидента перевозчикам');
      }
      return true;
    },

    // Команда для рассылки инцидента
    async scream(incidentId) {
      const scream = await jet.http.post(`/publicApi?call=eventincident&arg.incidentid=${incidentId}`, {});
      if (scream.error || !scream.res) {
        return this.error('Ошибка при рассылке инцидента перевозчикам');
      }
      return true;
    },

    // Преобразование файла для отправки
    convertFile(file) {
      if (file) {
        this.incidentEntity.file = {};
        this.incidentEntity.file.name = file.name;
        this.incidentEntity.loadingFile = true;
        const reader = new FileReader();
        reader.onload = () => {
          const res = reader.result;
          this.incidentEntity.file.base64 = res.substring(res.search(',') + 1);
          this.incidentEntity.loadingFile = false;
        };
        reader.readAsDataURL(new Blob([file]));
      }
    },

    // Сообщение об ошибке
    error(text) {
      jet.msg({
        color: 'error',
        text,
      });
      return false;
    },
  },
}
</script>
