<script>
import Vue from 'vue';
import vuetify from '@/plugins/vuetify';
import { watch } from '@/utils/dom';
import Model from '@/core/Model';

const SPECIAL_DAYS = {
    vcwkmodesIsworking: false,
    vcwkmodesIsholiday: false,
    vcwkmodesIsdayoff: false
};

const SPEC_DAYS_CHECKBOXES = ['vcwkmodesIsworking', 'vcwkmodesIsholiday', 'vcwkmodesIsdayoff'];


const WeekList = {
    name: 'WeekList',
    el: null,
    vuetify,
    data(){
        return {
            show: true,
            selectedDays: [],
            days: [
                {index: 0, label: 'Пн', value: 'Пн'},
                {index: 1, label: 'Вт', value: 'Вт'},
                {index: 2, label: 'Ср', value: 'Ср'},
                {index: 3, label: 'Чт', value: 'Чт'},
                {index: 4, label: 'Пт', value: 'Пт'},
                {index: 5, label: 'Сб', value: 'Сб'},
                {index: 6, label: 'Вс', value: 'Вс'},
            ]
        };
    },
    mounted() {
        
    },
    template: `
        <v-container class="week-container" v-if="show">
            <v-row justify="center">
                <v-col cols="12" md="8">
                    <v-list class="week">
                        <v-list-item
                            v-for="day in days"
                            :key="day.index"
                        >
                            <v-checkbox
                                v-model="selectedDays"
                                :label="day.label"
                                :value="day"
                            ></v-checkbox>
                        </v-list-item>
                    </v-list>
                </v-col>
            </v-row>
        </v-container>
    `
}; // WeekList
export default {
    name: 'RefForm',
    components: {
        WeekList
    },
    mounted(){
        const self = this;
        
        watch('form .v-tabs .v-tabs-items .v-window-item.v-window-item--active .container').then(async node => {
            $(node).append('<div id="week-list"></div>');
            WeekList.parent = self;
            WeekList.el = $(node).find('#week-list').get(0);
            self.$refs["vcwkmodesWeek"] = new Vue(WeekList);

            const week = self.$refs["vcwkmodesWeek"];

            console.log('SELF', self);

            SPEC_DAYS_CHECKBOXES.map(it => {
                if(!!self[it]) {
                    self.$refs[it].setValue("true");
                }
            })

            
            
            if(!!self.vcwkmodesWeek?.selectedDays) {
                const selectedDays = [...self.vcwkmodesWeek.selectedDays];
                selectedDays.map((day,index) => {
                    if(day) {
                        week.selectedDays.push(week.days[index]);
                    }
                })
            }

            self.onDataChange = (val) => {
                // Выбраны 3 чекбокса => скрываем дни недели
                if(SPEC_DAYS_CHECKBOXES.indexOf(val) != -1) {
                    SPECIAL_DAYS[val] = !SPECIAL_DAYS[val];
                    if(SPECIAL_DAYS['vcwkmodesIsworking'] && SPECIAL_DAYS['vcwkmodesIsholiday'] && SPECIAL_DAYS['vcwkmodesIsdayoff']) {
                        week.show = false;
                        return;
                    }
                    week.show = true;
                }
            }

            const compW = new Vue(Object.assign({
                parent: self,
                propsData: {},
                methods: {}
            }, WeekList)).$mount( $(week.$el).parent().find("#week-list").get(0) );
        });

        self.$parent.beforeSave = () => {
            const form = self.$refs["form"];

            const week = self.$refs["vcwkmodesWeek"];
            const selDays = [];
            const indexes = week.selectedDays.map(day => day.index);

            for(let i = 0; i < 7; i++) {
                if(indexes.indexOf(i) != -1) {
                    selDays[i] = true;
                } else {
                    selDays[i] = false;
                }
                
            }

            const param = `timetable{hours:@[8.0d,8.0d,8.0d,8.0d,8.0d,0.0d,0.0d],selectedDays:@[${selDays.join(',')}]}`;
            self.vcwkmodesWeek = param;

            console.log('SELF', self);
            
            if(!form.validate()) {
                return false;
            }

        };

        
        
        
        

    }
}
</script>

<style lang="scss">
.week-container {
    margin-top: 2rem;
}
.week-container .week {
    display: flex;
    justify-content: space-between;
}
</style>