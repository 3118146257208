<template>
  <div class="px-4">
    <template v-if="!loading">
      <template v-if="!dialogProfile">
        <v-row align="center" class="mx-1">
          <v-col class="grey--text">Выберете запись, которую хотите изменить</v-col>
          <v-col>
            <JetSearchField
              v-model="search"
            ></JetSearchField>
          </v-col>
        </v-row>


        <!-- Таблица -->
        <v-data-table
          :headers="headers"
          :items="profiles"
          :search="search"
        >
          <template v-slot:item="row">
            <tr @click="openDialog(row.item)">
              <td>
                <span v-if="row.item.title">
                  {{ row.item.title }}
                </span>
                <span v-else class="grey--text">
                  Не указано
                </span>
              </td>
              <td>
                <span v-if="row.item.nameLeader">
                  {{ row.item.nameLeader }}
                </span>
                <span v-else class="grey--text">
                  Не указано
                </span>
              </td>
              <td>
                <span v-if="row.item.phoneLeader">
                  {{ row.item.phoneLeader }}
                </span>
                <span v-else class="grey--text">
                  Не указано
                </span>
              </td>
              <td>
                <span v-if="row.item.orgPlace">
                  {{ row.item.orgPlace }}
                </span>
                <span v-else class="grey--text">
                  Не указано
                </span>
              </td>
              <td>
                <span v-if="row.item.factAddress">
                  {{ row.item.factAddress }}
                </span>
                <span v-else class="grey--text">
                  Не указано
                </span>
              </td>
              <td>
                <span v-if="row.item.orgTlf">
                  {{ row.item.orgTlf }}
                </span>
                <span v-else class="grey--text">
                  Не указано
                </span>
              </td>
              <td>
                <span v-if="row.item.email">
                  {{ row.item.email }}
                </span>
                <span v-else class="grey--text">
                  Не указано
                </span>
              </td>
            </tr>
          </template>
        </v-data-table>
      </template>
      <Profile
        v-else
        :originalProfile="selectedProfile"
        :isOnce="profiles.length === 1"
        @closeDialog="closeDialog"
      ></Profile>
    </template>
    <div v-else class="b-spinner align-center mt-6" align="center">
      <v-progress-circular indeterminate color="primary"/>
    </div>
  </div>
</template>

<script>
import Profile from '@/components/dev/modules/profile/components/Profile';
import ProfilesService from '@/components/dev/service/ProfilesService';
import JetSearchField from '@/components/JetSearchField';

// Mixins
import HideGlobalToolbarMixin from '@/components/dev/mixin/HideGlobalToolbarMixin';

export default {
  mixins: [
    HideGlobalToolbarMixin,
  ],
  name: 'SelectProfile',
  components: {
    JetSearchField,
    Profile,
  },
  data: () => ({
    loading: true,
    dialogProfile: false,
    selectedProfile: null,
    profiles: [],
    search: '',
    headers: [
      { text: 'Наименование организации', value: 'title' },
      { text: 'ФИО руководителя', value: 'nameLeader' },
      { text: 'Телефон руководителя', value: 'phoneLeader' },
      { text: 'Юр. адрес', value: 'orgPlace' },
      { text: 'Фактический адрес', value: 'factAddress' },
      { text: 'Телефон', value: 'orgTlf' },
      { text: 'Email', value: 'email' },
    ],
  }),
  created() {
    this.load();
  },
  methods: {
    async load() {
      this.loading = true;
      this.profiles = await ProfilesService.getProfiles();
      if (this.profiles.length === 1) {
        this.openDialog(this.profiles[0]);
      }
      this.loading = false;
    },
    openDialog(profile) {
      this.dialogProfile = true;
      this.selectedProfile = profile;
    },
    closeDialog(reload) {
      this.dialogProfile = false;
      this.selectedProfile = null;
      if (reload) {
        this.load();
      }
    },
  },
};
</script>
