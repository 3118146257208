<template>
  <div class="px-4">
    <v-row>
      <v-col>
        <v-btn @click="back" color="primary">Назад</v-btn>
        <span class="pl-4">
          {{ message.routeName }}, рейс {{ message.tripCode }}
          <span
            v-if="message.rejected && !isModerator"
            class="red-border red--text py-2 px-2 ml-2"
          >
            <v-icon color="red">
              mdi-exclamation-thick
            </v-icon>
            <b>Отклонено</b>
          </span>
        </span>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="pb-0">
        <!-- Транспортное средство -->
        <v-row>
          <v-col class="pt-0 text-truncate">Транспортное средство</v-col>
          <v-col class="pt-0" align="right">{{ message.govnum }}</v-col>
        </v-row>

        <!-- Остановка -->
        <v-row v-if="message.stopName">
          <v-col class="pt-0">Остановка</v-col>
          <v-col class="pt-0" align="right">{{ message.stopName }}</v-col>
        </v-row>

        <!-- Время -->
        <v-row>
          <v-col class="pt-0">Время</v-col>
          <v-col align="right" class="pt-0">{{ message.time }}</v-col>
        </v-row>

      </v-col>

      <!-- Чат -->
      <v-col class="pb-0">
        <template v-if="!loadingChat">
          <v-row v-for="msg in chat" :key="msg.id">
            <v-col v-if="msg.isMy" class="pt-0"/>
            <v-col class="pt-0">
              <v-card :color="msg.isMy ? 'green' : 'blue'">
                <v-card-text class="white--text">
                  <div>{{ msg.reason ? getTextJustification(msg.reason) : '' }}</div>
                  <b>{{ msg.message }}</b>
                  <br/>
                  <v-btn
                    v-if="msg.file"
                    class="white--text px-0"
                    small
                    text
                    style="text-transform: initial"
                    @click="openFile(msg)"
                  >
                    <u>{{ msg.file.name || 'Файл' }}</u>
                  </v-btn>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col v-if="!msg.isMy" class="pt-0"/>
          </v-row>
        </template>
        <div v-else class="b-spinner align-center" align="center">
          <v-progress-circular indeterminate color="primary"/>
        </div>

        <!-- Комментарий -->
        <div>
          <b class="mx-2" style="font-size: 17px">Комментарий</b>
          <div class="mx-2 py-4" v-if="message.comment">
            {{ message.comment || "" }}
          </div>
          <div v-else>
            <v-select
              v-model="justificationType"
              class="px-2"
              label="Тип обоснования"
              :items="CASE_DISRUPTION"
              item-text="name"
              item-value="id"
              outlined
              hide-details
              color="grey"
              clearable
            ></v-select>
            <v-textarea
              v-model="dialogComment"
              class="px-2 pt-2 pb-4"
              auto-grow
              rows="2"
              row-height="42"
              outlined
              hide-details
              color="grey"
              label="Введите комментарий"
            ></v-textarea>
          </div>
        </div>
      </v-col>
    </v-row>


    <!-- Прикрепить файл -->
    <v-row v-if="!isModerator && !message.comment">
      <v-col cols="6" class="pt-0"></v-col>
      <v-col cols="6" class="pt-0">
        <v-file-input
          v-model="vFile"
          class="pt-0 mt-0 px-2"
          label="Прикрепить файл"
          color="primary"
          hide-details
          :loading="loadingFile"
          @change="convertFile"
        ></v-file-input>
      </v-col>
    </v-row>

    <!-- Отправить -->
    <v-row v-if="!message.comment">
      <v-col class="py-0"></v-col>
      <v-col v-if="isModerator" class="py-0">
        <v-row>
          <v-col>
            <v-btn
              :disabled="disableSendBtn"
              :loading="loadingSend"
              class="white--text mx-2"
              color="red"
              @click="reject"
            >
              Отклонить
            </v-btn>
          </v-col>
          <v-col align="right">
            <v-btn
              :disabled="disableSendBtn"
              :loading="loadingSend"
              class="white--text mx-2"
              color="primary"
              @click="confirm"
            >
              Подтвердить
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
      <v-col v-else align="right">
        <v-btn
          :disabled="disableSendBtn && !loadingFile"
          :loading="loadingSend"
          class="white--text mx-2"
          color="primary"
          @click="sendMessage"
        >
          Отправить
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import OtherService from "@/services/OtherService";

const CASE_DISRUPTION = [
  {name: 'ДТП', id: 'e33d7f1f-73be-4ee9-b187-032d189c8592'},
  {name: 'Тех. неисправность', id: 'c09e815d-f30d-42dc-9861-af442118634d'},
  {name: 'Нет телеметрии', id: '51a1c1ba-3fc4-45a6-81b2-7704306a6f5b'},
  {name: 'Временное перекрытие трассы', id: '9b8a3bd0-325e-4a42-aaa4-fb9857f3aa67'},
];

export default {
  name: "DisruptionPage",
  props: {
    message: {
      type: Object,
      required: true,
    },
    isModerator: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    dialogLoading: true,
    dialogComment: '',
    file: null,
    vFile: null,
    chat: [],
    loadingChat: true,
    loadingFile: false,
    justificationType: null,
    CASE_DISRUPTION,
    loadingSend: false,
  }),
  created() {
    this.loadChat();
  },
  computed: {
    disableSendBtn() {
      return !this.justificationType && !this.dialogComment.replace(/\s/g, '');
    },
  },
  watch: {
    message(newValue, oldValue) {
      if (newValue.regid !== oldValue.regid) {
        this.loadChat();
      }
    },
  },
  methods: {
    back() {
      this.dialogComment = '';
      this.$emit('refresh');
    },
    convertFile(file) {
      if (file) {
        this.file = {};
        this.file.name = file.name;
        this.loadingFile = true;
        const reader = new FileReader();
        reader.onload = () => {
          const res = reader.result;
          this.file.base64 = res.substring(res.search(',') + 1);
          this.loadingFile = false;
        }
        reader.readAsDataURL(new Blob([file]));
      }
    },
    async loadChat(showLoad = true) {
      if (showLoad) {
        this.loadingChat = true;
      }
      this.chat = await OtherService.getDisruptionChat(this.message.regid);
      this.loadingChat = false;
    },
    openFile(msg) {
      location.href = '/rpc/?d=file&uri=' + msg.file.ref;
    },
    async sendMessage() {
      this.loadingSend = true;
      if (this.chat.length === 0) {
        await OtherService.editStatus(this.message.regid, OtherService.STATUS_NOTIFICATION.BEGIN_DIALOG);
      }
      const message = {
        id: jet.utils.uuidv4(),
        isMy: true,
        message: this.dialogComment,
        file: this.file,
        reason: this.justificationType,
      };
      this.chat.push(message);
      await OtherService.sendMessage(this.message.regid, this.dialogComment, this.file, this.justificationType);
      this.loadChat(false);
      this.dialogComment = '';
      this.file = null;
      this.vFile = null;
      this.loadingSend = false;
    },
    async reject() {
      await this.sendMessage(true)
        .then(res => {
          jet.msg({
            text: 'Обоснование срыва успешно отклонено',
          });
          this.back();
        })
        .catch(err => {
          jet.msg({
            text: 'Ошибка при отклонении обоснования срыва',
            color: 'error'
          });
        });
    },
    async confirm() {
      await this.sendMessage(true)
        .then(async res => {
          jet.msg({
            text: 'Обоснование срыва успешно подтверждено',
          });
          const status = await OtherService.editStatus(this.message.regid, OtherService.STATUS_NOTIFICATION.END_DIALOG);
          if (status === true) {
            this.back();
          }
        })
        .catch(err => {
          jet.msg({
            text: 'Ошибка при подтверждении обоснования срыва',
            color: 'error'
          });
        });

    },
    getTextJustification(id) {
      return CASE_DISRUPTION.find(jus => jus.id === id).name;
    },
  },
};
</script>

<style scoped lang="sass">
.red-border
  border: 1px solid red
  border-collapse: collapse
</style>
