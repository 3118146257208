<script>
import Vue from 'vue';
import vuetify from '@/plugins/vuetify';
import { watch } from '@/utils/dom';
import Model from '@/core/Model';
const $moment = require('moment');
$moment.locale('ru');

export default {
    name: 'AutoCarrier',
    methods: {
        async updateVehicleEndDate(endDt, carrierId, orgId) {
            if(endDt != null) {
                try {
                    let vhs = [];
                    let devs = [];
                    let govNums = [];

                    let cmd = "sin2:/v:6857aebe-2248-4e72-a9ec-918d5e722cf6/?filter=eq(field(\".crrid\"),param(\"" + carrierId + "\", \"id\"))";
                    let _r = await $http.post('/rpc?d=jsonRpc', {
                        type: 'core-read',
                        query: cmd
                    });
                    const m = new Model(_r.result.model);
                    const resp = m.sin2obj(_r.result.data, true);

                    for(let i = 0; i < resp.length; i++) {
                        let vh = {};
                        let rs = resp[i];
                        vh.id = rs["vcvehicleId"];
                        vh.endDt = rs["vcvehicleEnddt"];
                        vh.startDt = rs["vcvehicleStartdt"];
                        //Если на дату закрытия ТС еще не начало действовать
                        if($moment(vh.startDt).isAfter(endDt)){ 
                            vh.less = true;
                            if(govNums.indexOf(rs["vcvehicleGovnum"]) == -1){
                                govNums.push(rs["vcvehicleGovnum"]);
                            }
                            //Просто открытое ТС
                        } else {
                            vh.less = false;
                        }

                        if(vh.endDt === null) {
                            vhs.push(vh);

                            let cmd1 = "sin2:/v:4e4626a1-1915-4223-8fe9-c63ae052fd80/?filter=and(isnull(field(\".endDt\")),eq(field(\".vcID\"),param(\""+vh.id+"\", \"id\")))&fields=.devID,.ID,.startDt,.endDt";
                            let _r1 = await $http.post('/rpc?d=jsonRpc', {
                                type: 'core-read',
                                query: cmd1
                            });
                            const m1 = new Model(_r1.result.model);
                            const resp1 = m1.sin2obj(_r1.result.data, true);

                            if(resp1.length && !!resp1) {
                                for (let j = 0; j < resp1.length; j++) {
                                    var dev = {};
                                    let rs1 = resp1[j]; 
                                    dev.ID = rs1['vcgpsdevsId'];
                                    dev.startDt = rs1['vcgpsdevsStartdt'];
                                    //Если на дату закрытия датчик еще не начал действовать
                                    if($moment(dev.startDt).isAfter(endDt)){
                                        dev.less = true;
                                        if(govNums.indexOf(rs["vcvehicleGovNum"]) == -1){
                                            govNums.push(rs["vcvehicleGovNum"]);
                                        }
                                        //Просто открытый датчик
                                    } else {
                                        dev.less = false;
                                    }
                                    devs.push(dev);
                                };
                            }
                        }
                    }

                    let cmd2 = "sin2:/v:2e4490d8-64f9-468d-b74e-ff4350a2b128/?filter=eq(field(\".id\"),param(\"" + orgId + "\", \"id\"))";
                    let _r2 = await $http.post('/rpc?d=jsonRpc', {
                        type: 'core-read',
                        query: cmd2
                    });
                    const m2 = new Model(_r2.result.model);
                    const resp2 = m2.sin2obj(_r2.result.data, true);

                    let companyName = resp2[0]['organizationsShortname'];
                    if(vhs.length > 0) {
                        let msg = "Для всех транспортных средств (" + vhs.length + " шт) перевозчика \"" + companyName + "\" и их датчиков (" + devs.length + " шт)\nбудет установлена следующая дата окончания учета: " + $moment(endDt).format('DD.MM.YYYY');
                        if (govNums.length > 0) {
                            msg += "\nТС или их датчики " + govNums.join(", ") + " начали действовать после даты закрытия перевозчика, \nим будет установлена дата окончания действия, равная дате начала действия.";
                        }

                        jet.msg({text: msg, type:'warning', centered: true, bottom: false });
                
                        const vUri = "0c117b36-5e32-444e-84ab-5cc4340b0be4";
                        const vOpts = {
                            type: 'api2.update',
                            query: 'sin2:/v:' + vUri,
                            view: vUri,
                            packs: []
                        }
                        for (let i = 0; i < vhs.length; i++) {
                            const date = vhs[i].less ? vhs[i].startDt : endDt;
                            vOpts.packs.push({
                                object: vhs[i].id,
                                values: {
                                    'vcvehicle.endDt': {name: 'vcvehicle.endDt', type: 'date', value: $moment(date).unix()*1000},
                                }
                            });
                            
                        }
                        
                        const devUri = "4e4626a1-1915-4223-8fe9-c63ae052fd80";
                        const devOpts = {
                            type: 'api2.update',
                            query: 'sin2:/v:' + devUri,
                            view: devUri,
                            packs: []
                        };
                        for (let j = 0; j < devs.length; j++) {
                            const date = devs[j].less ? devs[j].startDt : endDt;
                            devOpts.packs.push({
                                object: devs[j].ID,
                                values: {
                                    'endDt': {name: 'endDt', type: 'date', value: $moment(date).unix()*1000},
                                }
                            });
                        }

                        const vRes = await $http.post(vOpts);
                        const devRes = await $http.post(devOpts);
                        if(devRes.error || vRes.error) {
                            msg = 'Ошибка проверки действующих ТС и датчиков';
                            throw new Error(msg);
                        }
                    }
                } catch (error) {
                    jet.msg({text: error.message, color:'error'});
                }
                
            }
        }
    },
    mounted(){
        const self = this;
        self.$parent.beforeSave = () => {
            const form = self.$refs["form"];
            
            if(!form.validate()) {
                return false;
            }
            
        };

        self.$parent.afterSave = async (id) => {
            self.updateVehicleEndDate(self.vcautocarrierEnddt, self.vcautocarrierId, self.vcautocarrierOrgid);
            
            return await new Promise((resolve) => {
                setTimeout( () => {
                    resolve(true);
                }, 3000)
            });
        }
    }
}
</script>

<style lang="scss">

</style>