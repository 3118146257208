<template>
  <div>

    <!-- Поиск -->
    <v-row>
      <v-col>
        <JetSearchField
          label="Найти маршрут по номеру, названию или перевозчику"
          v-model="search"
        ></JetSearchField>
      </v-col>
      <v-col class="grey--text" align="right">
        Выберите маршрут для планирования
      </v-col>
    </v-row>

    <!-- Таблица планирование -->
    <v-data-table
      :headers="headers"
      :items="routes"
      :loading="loadingRoutes"
      :search="search"
      @click:row="showTrips"
    ></v-data-table>
  </div>
</template>

<script>
// Сервисы
import PlansService from '@/services/PlansService';

// Общие компоненты
import JetSearchField from '@/components/JetSearchField';

export default {
  name: 'TabRoute',
  props: {
    // Выбранная дата
    fullDate: {
      type: Object,
      required: true,
    },
  },
  components: {
    JetSearchField,
  },
  data: () => ({
    tenantId: null,
    // Маршруты
    routes: [],
    // Флаг что происходит загрузка маршрутов
    loadingRoutes: true,
    // Поиск по таблице
    search: '',
    // Таблица
    headers: [
      { text: 'Маршрут', value: 'routeName' },
      { text: 'Перевозчик', value: 'carrierName' },
      { text: 'Планирование', value: 'routePlan' },
    ],
  }),
  watch: {
    fullDate() {
      this.loadRoutes();
    },
  },
  created() {
    this.tenantId = this.$store.state.auth.subject.tenantId;
    this.loadRoutes();
  },
  methods: {
    async loadRoutes() {
      if (this.fullDate.iso) {
        this.loadingRoutes = true;

        try {
          this.routes = await PlansService.getPlans(this.tenantId, this.fullDate.iso);
        } catch (e) {
          this.routes = [];

          jet.msg({
            color: 'warning',
            text: 'Не удалось загрузить данные о планировании',
          });
        }

        this.loadingRoutes = false;
      }
    },
    showTrips(route) {
      this.$emit('selectRoute', route);
    },
  },
};
</script>
