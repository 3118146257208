<template>
    <v-menu class="jet-input-helper">
        <template v-slot:activator="{ on }">
            <v-btn 
                x-small
                outlined
                tile
                v-on="on"
                class="jet-input-btn">
                <svg viewBox="0 0 64 512"><use xlink:href="#ico-v-dots" /></svg>
            </v-btn>
        </template>
        <v-list class="jet-input-actions">
            <v-list-item @click="$emit('clear')">
                <v-list-item-title>очистить поле</v-list-item-title>
            </v-list-item>
            <v-list-item @click="$emit('lock')">
                <v-list-item-title>закрепить</v-list-item-title>
            </v-list-item>
            <v-list-item @click="$emit('unlock')">
                <v-list-item-title>сбросить</v-list-item-title>
            </v-list-item>
        </v-list>
    </v-menu>
</template>
<script>
    export default {
        name: 'JetInputBtn',
        data(){
            return {};
        },
        methods:{
            
        }
    }
</script>
<style lang="scss" scoped>
    @import "@/styles/index";
    
    .v-btn.v-size--x-small.jet-input-btn{
       width: 32px;
       height: 32px;
       min-width: 32px;
       border: transparent;
       & svg{
           width: 22px;
           height: 22px;
           color: $gray-color;
       }
    }
    .jet-input-actions{
        & .v-list-item{
            font-size:0.85;
            min-height: 24px;
        }
    }
</style>