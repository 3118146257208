const xl = require('excel4node');
const $moment = require('moment');
const tz_shift= $moment(0).hours() * 1000 * 3600;


function exportdata(data) {
    if ( !data ) {
        return;
    }
    const tb = data.$refs["table"];
    const name = data.model.name,
          rows = tb.internalCurrentItems,
          cols = data.model.columns;
    var book = new xl.Workbook();
    const border = {
        left: {
            style: 'thin',
            color: '#000000'
        },
        right: {
            style: 'thin',
            color: '#000000'
        },
        top: {
            style: 'thin',
            color: '#000000'
        },
        bottom: {
            style: 'thin',
            color: '#000000'
        }
    }
    const caption = book.createStyle({
        font: {
            color: '#000000',
            size: 12,
            bold: true
        }
    });
    const title = book.createStyle({
        font: {
            color: '#000000',
            size: 10,
        },
        alignment: {
            horizontal: 'center',
            vertical  : 'center',
            wrapText  : true
        },
        fill: {
            type: 'gradient',
            bgColor: '#C0C0C0',
            patternType: 'solid'
        },
        border: border,
    });
    const int_cell = book.createStyle({
        font: {
            size: 10,
        },
        border: border,
        numberFormat: '#,##0'
    });
    const date_cell = book.createStyle({
        font: {
            size: 10,
        },
        border: border,
        numberFormat: 'dd.mm.yyyy'
    });
    const def_cell = book.createStyle({
        font: {
            size: 10,
        },
        border: border
    });
    var sheet = book.addWorksheet("Лист 1");
    sheet.cell(1, 1, 1, cols.length, true)
        .string(name)
        .style(caption);
    cols.map((col, c) => {
        sheet.cell(2, c + 1)
            .string(col.title)
            .style(title);
    });
    
    rows.map((row, r) => {
        cols.map((col, c) => {
            if ( row[col.id]) {
                switch ( col.type ) {
                    case 'integer':
                        sheet.cell(2 + r + 1, c + 1)
                            .number(row[col.id])
                            .style(int_cell);
                        break;
                    case 'date':
                    case 'datetime':
                        sheet.cell(2 + r + 1, c + 1)
                            .string($utils.formatDate(row[col.id], 'datetime'===col.type ? 'DD.MM.YYYY HH:mm:ss' : 'DD.MM.YYYY'))
                            .style(date_cell);
                        break;
                    case 'boolean':
                        sheet.cell(2 + r + 1, c + 1)
                            .string((row[col.id]) ? 'Да' : 'Нет')
                            .style(def_cell);
                        break;
                    default:
                        sheet.cell(2 + r + 1, c + 1)
                            .string(row[col.id])
                            .style(def_cell);
                        break;
                }
            } else {
            sheet.cell(2 + r + 1, c + 1)
                .string('')
                .style(def_cell);
            }
        });
    });
    var mimeType = 'application/vnd.ms-excel';
    book.writeToBuffer().then(function(buffer) {
        var blob = new Blob([buffer], {type: mimeType});
        var url = URL.createObjectURL(blob);
        var ref = document.createElement('a');
        ref.href = url;
        document.body.appendChild(ref);
        ref.click();
        document.body.removeChild(ref);
        window.URL.revokeObjectURL(url);
    });
};

// Экспортируем публичные методы
export {
    exportdata
};