<template>
  <div>
    <v-layout class="align-center mb-4">
      <v-flex shrink class="text-no-wrap pr-2">
        Дата планирования:
      </v-flex>
      <v-flex shrink>
        <v-menu
          v-model="showDatePicker"
          min-width="290px"
          :close-on-content-click="false"
          transition="scale-transition"
          ref="menu"
          offset-y
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              class="pt-0 mt-0"
              v-model="computedDateFormatted"
              v-on="on"
              readonly
              hide-details
            ></v-text-field>
          </template>
          <v-dialog
            ref="dialog"
            v-model="showDatePicker"
            persistent
            width="290px"
          >
            <v-date-picker
              v-model="dateInCalendar"
              scrollable
              locale="ru-Latn"
              :first-day-of-week="1"
              :weekday-format="dayOfWeekToFormat"
            >
              <v-spacer/>
              <v-btn color="primary" @click="closeCalendar(false)">
                Отмена
              </v-btn>
              <v-btn color="primary" @click="closeCalendar(true)">
                ОК
              </v-btn>
            </v-date-picker>
          </v-dialog>
        </v-menu>
      </v-flex>
      <v-btn
        icon
        color="primary"
        @click="showDatePicker = true"
      >
        <v-icon>mdi-calendar-month</v-icon>
      </v-btn>
    </v-layout>
  </div>
</template>

<script>
import {dayOfWeekToFormat, formatDate, FORMAT_FOR_USER} from '@/services/JetDate';

export default {
  name: 'CalendarDatePlanning',
  data: () => ({
    selectedDate: null,
    dateInCalendar: null,
    showDatePicker: false,
    dayOfWeekToFormat: () => '',
  }),
  computed: {
    computedDateFormatted() {
      return formatDate(this.selectedDate, FORMAT_FOR_USER.ONLY_DATE, true);
    },
  },
  created() {
    this.dayOfWeekToFormat = dayOfWeekToFormat;
    this.dateInCalendar = (new Date()).toISOString().substr(0, 10);
    this.closeCalendar(true);
  },
  methods: {
    // @param {Boolean} save - если true выбранное значение будет сохранено
    closeCalendar(save) {
      if (save) {
        this.selectedDate = this.dateInCalendar;
        this.$emit('set-date', {
          iso: this.selectedDate,
          format: this.computedDateFormatted,
        });
      } else {
        this.dateInCalendar = this.selectedDate;
      }
      this.showDatePicker = false;
    },
  },
};
</script>

<style scoped>
/deep/ .v-text-field {
  width: 90px;
}
</style>
