<template>
  <v-dialog v-model="show" :width="width" :persistent="persistent">
    <v-card>
      <!-- Заголовок -->
      <v-card-title>
        <slot name="title">
          {{ title }}
        </slot>
      </v-card-title>

      <v-divider v-if="showDividers"/>

      <!-- Основная часть диалога -->
      <v-card-text class="pt-4 body-1">
        <slot name="default">
          Кажется, тело диалога не было указано...
        </slot>
      </v-card-text>

      <v-divider v-if="showDividers"/>

      <!-- Действия -->
      <v-card-actions class="px-4">
        <slot name="actions">
          <v-btn
            outlined
            class="body-2"
            color="red lighten-1"
            :disabled="disabledCancelButton"
            @click="$emit('on-cancel-click')"
          >
            {{ cancelButtonText }}
          </v-btn>

          <v-spacer/>

          <v-btn
            color="primary"
            :disabled="disabledApplyButton"
            @click="$emit('on-apply-click')"
            :loading="loadingApplyButton"
          >
            {{ applyButtonText }}
          </v-btn>
        </slot>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    // Значение для отображения диалога
    value: {
      type: Boolean,
      required: true,
    },
    // Заголовок
    title: {
      type: String,
      default: '',
    },
    // Ширина диалогового окна
    width: {
      type: String,
      default: '600',
    },
    // Текст кнопки "Отмена"
    cancelButtonText: {
      type: String,
      default: 'Отмена',
    },
    // Текст кнопки "Применить"
    applyButtonText: {
      type: String,
      default: 'Применить',
    },
    // Контроль показа кнопки "Отмена"
    showCancelButton: {
      type: Boolean,
      default: false,
    },
    // Контроль показа кнопки "Применить"
    showApplyButton: {
      type: Boolean,
      default: false,
    },
    // Контроль свойства disable для кнопки "Применить"
    disabledApplyButton: {
      type: Boolean,
      default: false,
    },
    // Отображение лоадера на кнопке "Применить"
    loadingApplyButton: {
      type: Boolean,
      default: false,
    },
    // Контроль свойства disable для кнопки "Отмена"
    disabledCancelButton: {
      type: Boolean,
      default: false,
    },
    // Отображение разделительных линий
    showDividers: {
      type: Boolean,
      default: true,
    },
    // Закрытие диалога при нажатии вне его
    persistent: {
      type: Boolean,
      default: false,
    },
  },
  name: 'JetDialog',
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    }
  },
};
</script>
