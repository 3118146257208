<template>
  <v-expansion-panels>
    <v-expansion-panel class="border mb-1 pa-1">
      <v-expansion-panel-header class="py-0 pl-0 pr-4">
        <v-row class="mx-0 pl-4">

          <!-- Данные по выезду -->
          <v-col>
            <v-row class="align-center">
              <div>
                <v-checkbox
                  v-if="structure.checkbox"
                  v-model="item.selected"
                  :disabled="disabled"
                  class="pt-0 mt-0"
                  hide-details
                  color="primary"
                  @change="changeSelected"
                  @click.native="check($event, true)"
                ></v-checkbox>
              </div>
              <div class="flex-nowrap text-no-wrap">

                <div class="pb-1">

                  <b>{{ item.title }} Рейсов запланировано: {{ countTrips }} /
                    {{ item.schedules.length }}</b>

                  <v-tooltip bottom v-if="item.hasViolations">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        v-bind="attrs"
                        v-on="on"
                        color="red"
                        class="ml-2"
                      >mdi-alert-circle</v-icon>
                    </template>

                    На рейсах есть нарушения норм труда и отдыха
                  </v-tooltip>
                </div>
                <span class="grey--text pr-1">{{ startTime }} - {{ endTime }}</span>
                {{ textVehicles }}
              </div>
            </v-row>
          </v-col>
        </v-row>
      </v-expansion-panel-header>
      <v-expansion-panel-content class="px-0 pb-0">
        <TripItem
          v-for="schedule in filteredSchedules"
          :key="schedule.tripCode"
          v-model="value"
          :item="schedule"
          :fullDate="fullDate"
          :disabled="disabled"
          :structure="structure"
          @add-vehicle="addVehicle"
          @click-checkbox="clickCheckbox"
        ></TripItem>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
  import PlansService from "@/services/PlansService";
  import TripItem from "./TripItem";

  export default {
    name: 'DepartureItem',
    props: {
      value: {
        type: Array,
        default: () => ([]),
      },
      item: {
        type: Object,
        required: true,
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      fullDate: {
        type: Object,
        required: true,
      },
      onlyUnplanned: {
        type: Boolean,
        default: false,
      },
      structure: {
        type: Object,
        default: () => ({
          checkbox: true,
          addVehicle: true,
          delete: true,
          plan: true,
        }),
      },
    },
    components: {
      TripItem,
    },
    computed: {
      filteredSchedules() {
        return this.onlyUnplanned ? this.item.schedules.filter(schedule => !schedule.planned) : this.item.schedules;
      },
      textVehicles() {
        const vehicles = this.vehicles;
        let result = '';
        for (let i = 0; i < vehicles.length; i++) {
          result += vehicles[i].govnum;
          if (i > 0 && !!vehicles[i].stop) {
            result += ` (${vehicles[i].stop})`;
          }
          if (i + 1 < vehicles.length) {
            result += ', ';
          }
        }
        return result;
      },
    },
    data: () => ({
      startTime: '00:00',
      endTime: '23:59',
      vehicles: [],
      countTrips: 0,
      firstItem: null,
    }),
    created() {
      this.initTime();
      this.initVehicles();
      this.item.schedules.forEach(schedule => this.countTrips += schedule.trips.length);
    },
    methods: {
      // Необходим что-бы при клике по чекбоксу не открывалась панель
      check(e, isCheckbox = false) {
        e.cancelBubble = true;
        if (isCheckbox) {
          this.$emit('click-checkbox', {
            isShiftPress: e.shiftKey,
            item: this.item,
          });
        }
      },
      addVehicle(schedule) {
        this.$emit('add-vehicle', (schedule || this.item.schedules));
      },
      changeSelected() {
        for (const schedule of this.item.schedules) {
          schedule.selected = this.item.selected;
          const index = this.value.findIndex(item => {
            return item.scheduleId === schedule.scheduleId;
          });
          if (index !== -1) {
            if (!this.item.selected) {
              this.value.splice(index, 1);
            }
          } else {
            if (this.item.selected) {
              this.value.push(schedule);
            }
          }
        }
      },
      initTime() {
        const schedules = this.item.schedules;
        let minTime = schedules[0].startTime;
        let maxTime = schedules[0].endTime;
        for (const schedule of schedules) {
          if (schedule.startTime.getTime() < minTime.getTime()) {
            minTime = schedule.startTime;
          }
          if (schedule.endTime.getTime() > maxTime.getTime()) {
            maxTime = schedule.endTime;
          }
        }
        this.startTime = $utils.formatDate(new Date(minTime), 'HH:mm');
        this.endTime = $utils.formatDate(new Date(maxTime), 'HH:mm');
      },
      initVehicles() {
        const schedules = this.item.schedules;
        const vehicles = [];
        for (const schedule of schedules) {
          if (schedule.planned) {
            for (const trip of schedule.trips) {
              if (trip.govnum) {
                const index = vehicles.findIndex(item => {
                  return item.govnum === trip.govnum;
                });
                if (index === -1) {
                  vehicles.push({
                    govnum: trip.govnum,
                    stop: vehicles.length > 0 ? trip.locationName : '',
                  });
                }
              }
            }
          }
        }
        this.vehicles = vehicles;
      },
      clickCheckbox(event) {
        if (event.isShiftPress) {
          if (this.firstItem) {
            let first = this.item.schedules.findIndex(schedule => {
              return schedule.scheduleId === this.firstItem.scheduleId;
            });
            let last = this.item.schedules.findIndex(schedule => {
              return schedule.scheduleId === event.item.scheduleId;
            });

            if (first !== -1 && last !== -1) {
              // Что бы работало и в обратном порядке
              // Когда мы выбираем сначала элемент внизу, а потом вверху
              if (last < first) {
                const tmp = first;
                first = last;
                last = tmp;
              }
              for (let i = first; i <= last; i++) {
                this.item.schedules[i].selected = true;
                const index = this.value.findIndex(item => {
                  return item.scheduleId === this.item.schedules[i].scheduleId;
                });
                if (index === -1) {
                  this.value.push(this.item.schedules[i]);
                }
              }
            }
          }
        } else {
          this.firstItem = event.item;
        }
      },
    },
  };
</script>

<style lang="sass">
  .border
    border-collapse: collapse
    border: 1px solid
    border-radius: 10px
</style>
