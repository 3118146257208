<script>
import Vue from 'vue';
import vuetify from '@/plugins/vuetify';
import { watch } from '@/utils/dom';
import Model from '@/core/Model';
import JetSpinner from '../../../JetSpinner.vue';
const $moment = require('moment');
$moment.locale('ru');

const CALENDAR_TYPES = {
    main: {id: "95ceb548-b612-4dbd-951e-c7f890c258e7", value: false},
    sliding: {id:"1b6f3c65-5974-49b9-be24-1694c6a64032", value: false}
}

const VIEW_ID = 'bc14ab3f-0470-4ce9-a2ca-ae54a092d55e';


const Calendar = {
    name: 'Calendar',
    components: {JetSpinner},
    el: null,
    vuetify,
    data() {
        return {
            focus: '',
            type: 'month',
            selectedEvent: {},
            selectedElement: null,
            selectedOpen: false,
            colors: ['red accent-4'],
            weekday: [1, 2, 3, 4, 5, 6, 0], 
            menuOpen: false,
            menuMsg: '',
            loader: false,

            allCalendar: [], // данные по календарю за все года 
            calendarid: null,
            typeid: null,
            startdt: null,
            enddt: null,
            dayhours: null,
            weekdays: null,
            breakingdayscount: null,
            workingdayscount: null,
            code: null,
            
            
            dayOffs: [], // дни, которые назначили выходными
            workDays: [],
            activeDays: [],
            changesDays: []
        }
    },
    async mounted() {
        this.$refs.calendar.prev();
        
        setTimeout(() => {
            this.$refs.calendar.next();
        },10);
    },
    watch: {
        dayOffs(days, oldDays) {
            let list = this.getMonthDays();
            
            days.map(day => {
                const index = list.findIndex(it => it.lastChild.id == day);
                const dayIndex = this.allCalendar.findIndex(it => it.scDate == day);
                if(index != -1) {
                    this.allCalendar[dayIndex] = {...this.allCalendar[dayIndex], scHours: 0, isHoliday: true};
                    list[index].classList.remove('work');
                    list[index].classList.add('dayoff');
                }

                if(this.changesDays.indexOf(day) == -1 && days.length > oldDays.length && oldDays.length && oldDays.indexOf(day) == -1) {
                    this.changesDays.push(day);
                } else if(this.changesDays.indexOf(day) == -1 && !oldDays.length && days.length == 1 && oldDays.indexOf(day) == -1) {
                    this.changesDays.push(day);
                }
            })
            
            oldDays.map(day => {
                const index = list.findIndex(it => it.lastChild.id == day);
                const dayIndex = this.allCalendar.findIndex(it => it.scDate == day);
                
                if(days.indexOf(day) == -1) {
                    if(this.workDays.indexOf(day) != -1 || this.allCalendar[dayIndex].isHoliday) {
                        this.allCalendar[dayIndex] = {...this.allCalendar[dayIndex], scHours: this.dayhours, isHoliday: false};
                        list[index].classList.remove('dayoff');
                        list[index].classList.add('work');
                    } else {
                        list[index].classList.remove('dayoff');
                    }
                }

                if(this.changesDays.indexOf(day) == -1 && days.length < oldDays.length && oldDays.length && days.indexOf(day) == -1) {
                    this.changesDays.push(day);
                } else if(this.changesDays.indexOf(day) == -1 && !oldDays.length && days.length == 1 && days.indexOf(day) == -1) {
                    this.changesDays.push(day);
                }
                
            })

        },
        breakingdayscount(daysCount, oldDaysCount) {
            this.isNeedGenerate(daysCount, oldDaysCount, this.workingdayscount);
        },
        workingdayscount(daysCount, oldDaysCount) {
            this.isNeedGenerate(daysCount, oldDaysCount, this.breakingdayscount);
        },
        weekdays(weekdays, oldWeekDays) {
            this.isNeedGenerate(weekdays, oldWeekDays, this.dayhours);
        },
        dayhours(dayhours, oldDayHours) {
            this.isNeedGenerate(dayhours, oldDayHours, this.weekdays);
        },
        enddt(enddt, oldEndDt) {
            if($moment(enddt).isSame(oldEndDt,'date')) {
                return;
            }
            if(!!this.typeid) {
                if(!!enddt && !!oldEndDt && this.allCalendar.length) {
                    this.generateFullClnd($moment(this.startdt), $moment(enddt));
                    this.generateClnd(true);
                    return;
                }

                this.generateClnd();
            }
        },
        startdt(startdt, oldStartDt) {
            if($moment(startdt).isSame(oldStartDt,'date')) {
                return;
            }

            if(!!this.typeid) {
                if(!!startdt && !!oldStartDt && this.allCalendar.length) {
                    this.generateFullClnd($moment(startdt), $moment(this.enddt));
                    this.generateClnd(true);
                    return;
                }

                this.generateClnd();
            }
        }
    },
    methods: {
        // Отображаем данные(генерируем, если их нет) для текущего месяца 
        generateClnd(edit = false) {
            console.log('generateClnd', edit);
            this.workDays = [];

            let lastDate = null;
            if(this.allCalendar.length) {
                lastDate = this.allCalendar[this.allCalendar.length - 1]?.scDate;
            }
             
            if(!this.allCalendar.length && !(!!this.calendarid) || $moment(this.$refs.calendar.value).isAfter(lastDate)) {
                this.menuOpen = true;
                this.menuMsg = `Данных за ${$moment(this.$refs.calendar.value).year()} г. не обнаружено. Вы хотите их создать автоматически?`;
            }

            if(!this.menuOpen) {
            setTimeout(() => {
                let monthNode = this.getMonthDays();
                let dates = monthNode.map(m => {
                    if(!!m.lastChild.id) {
                        return m.lastChild.id;
                    }
                    return "";
                        
                });
                monthNode.map(it => {
                    it.classList.remove('dayoff');
                    it.classList.remove('work');
                })
               
                if(!edit) {
                    dates.map(date => {
                        const dayIndex = this.allCalendar.findIndex(it => it.scDate == date);
                        const nodeIndex = monthNode.findIndex(it => it.lastChild.id == date);
                            
                        if(dayIndex != -1) {
                            if(Number(this.allCalendar[dayIndex].scHours) && !this.allCalendar[dayIndex].isHoliday && !!date) {
                                monthNode[nodeIndex].classList.add('work');
                                this.workDays = this.workDays.indexOf(date) != -1 ? [...this.workDays]: [...this.workDays, date];
                            } else if(this.allCalendar[dayIndex].isHoliday && !!date) {
                                monthNode[nodeIndex].classList.add('dayoff');
                                this.dayOffs = this.dayOffs.indexOf(date) != -1 ? [...this.dayOffs]: [...this.dayOffs, date];
                            }
                        }  
                    })
                } else {
                    if(this.typeid == CALENDAR_TYPES['main'].id) {
                        dates.map(date => {
                            const dayIndex = this.allCalendar.findIndex(it => it.scDate == date);
                            const nodeIndex = monthNode.findIndex(it => it.lastChild.id == date);
                                
                            if(dayIndex != -1) {
                                if(this.isDayOff(date) && this.checkDate(date) && !!date) {
                                    this.allCalendar[dayIndex] = {...this.allCalendar[dayIndex], scHours: 0};
                                    monthNode[nodeIndex].classList.remove('work');
                                } else if(this.checkDate(date) && !!date) {
                                    this.allCalendar[dayIndex] = {...this.allCalendar[dayIndex], scHours: this.dayhours};
                                    monthNode[nodeIndex].classList.add('work');
                                    this.workDays = this.workDays.indexOf(date) != -1 ? [...this.workDays]: [...this.workDays, date];
                                }
                            }   
                        })
                    } else {
                        let slidingDays = this.getSlidingDays();
                        dates.map((date,i) => {
                            const dayIndex = this.allCalendar.findIndex(it => it.scDate == date);
                            const nodeIndex = monthNode.findIndex(it => it.lastChild.id == date);
                            const slidingDayIndex = slidingDays.findIndex(it => it.date == date);
                            
                            if(dayIndex != -1) {
                                if(!slidingDays[slidingDayIndex].value) {
                                    this.allCalendar[dayIndex] = {...this.allCalendar[dayIndex], scHours: 0};
                                    monthNode[nodeIndex].classList.remove('work');
                                } else {
                                    this.allCalendar[dayIndex] = {...this.allCalendar[dayIndex], scHours: this.allCalendar[dayIndex].scHours ? this.allCalendar[dayIndex].scHours : this.dayhours};
                                    monthNode[nodeIndex].classList.add('work');
                                    this.workDays.push(date);
                                }
                            }
                        })
                    }
                }
                    
                    
                this.$forceUpdate();
            },20)

            }
        },
        viewDay ({ date }) {
            this.focus = date;
        },
        setToday () {
            this.focus = ''
        },
        prev () {
            if(!this.menuOpen) {
                this.$refs.calendar.prev();
                const startYearAhead = $moment(this.$refs.calendar.value).add(1,'year').startOf('year');
                const startMonth = $moment(this.$refs.calendar.value).subtract(1, 'month');
                const endMonth = $moment(this.$refs.calendar.value).subtract(1, 'month').endOf('month');

                if(!!this.allCalendar) {
                    this.generateClnd();
                } else {
                    this.generateClnd(true);
                }
            }
        },
        next() {
            if(!this.menuOpen) {
                this.$refs.calendar.next();
                const startYearAhead = $moment(this.$refs.calendar.value).add(1,'year').startOf('year');
                const startMonth = $moment(this.$refs.calendar.value).add(1, 'month');
                const endMonth = $moment(this.$refs.calendar.value).add(1, 'month').endOf('month');
                
                const lastEl = this.allCalendar[this.allCalendar.length - 1];
                
                if($moment(startMonth).isSame(startYearAhead,'date') || $moment(startMonth).isAfter(lastEl.scDate)) {
                    this.menuOpen = true;
                    this.menuMsg = `Данных за ${startMonth.year()} г. не обнаружено. Вы хотите их создать автоматически?`;
                } else if(!!this.allCalendar) {
                    this.generateClnd();
                } else {
                    this.generateClnd(true);
                }
            }
            
        },
        showEvent (event, date) {
            this.focus = date;
            const open = () => {
                this.selectedEvent = event;
                this.selectedElement = event.target;
                requestAnimationFrame(() => requestAnimationFrame(() => this.selectedOpen = true))
            }

            if (this.selectedOpen) {
                this.selectedOpen = false
                requestAnimationFrame(() => requestAnimationFrame(() => open()))
            } else {
                open()
            }

            event.stopPropagation()
        },
        rnd (a, b) {
            return Math.floor((b - a + 1) * Math.random()) + a
        },
        checkDate(date) {
            let res = false;
            if(!!this.enddt) {
                res = ($moment(date).isAfter(this.startdt) || $moment(date).isSame(this.startdt, 'date')) 
                        && ($moment(date).isBefore(this.enddt) || $moment(date).isSame(this.enddt, 'date'));
            } else {
                res = $moment(date).isAfter(this.startdt) || $moment(date).isSame(this.startdt, 'date');
            }
            
            return res;
        },
        isDayOff(date) {
            let res = false;
            
            if(this.weekdays == 7) {
                return res;
            } 

            if(($moment(date).day() <= this.weekdays) && ($moment(date).day() != 0)) {
                res = false;
            } else {
                res = true;
            }
            
            return res;
        },
        getMonthDays() {
            let monthDays = [...document.querySelectorAll('.v-calendar-weekly__day')];
            return monthDays;
        },
        onDayHover(val, date) {
            this.activeDays = [];
            this.activeDays.push({date: date,active: val});
        },
        isActive(date) {
            const index = this.activeDays.findIndex(it => it.date == date); 
            if(index != -1) {
                return this.activeDays[index].active;
            }
        },
        getDateHours(date) {
            const d = this.allCalendar.findIndex(it => it.scDate == date);
            
            if(d != -1) {
                if(this.allCalendar[d].scHours == null) {
                    this.allCalendar[d].scHours = this.dayhours || 8;
                }
                
                return this.allCalendar[d].scHours;
            }
            
        },
        onInputChange(val, date) {
            const d = this.allCalendar.findIndex(it => it.scDate == date);
            let monthNode = this.getMonthDays();
            let nodeIndex = monthNode.findIndex(it => it.lastChild.id == date);

            if(!!val) {
                if(!monthNode[nodeIndex].classList.contains('dayoff')) {
                    if(!!this.allCalendar[d].scHours && val == 0) {
                        monthNode[nodeIndex].classList.remove('work');
                    } else {
                        monthNode[nodeIndex].classList.add('work');
                    }

                    this.allCalendar[d].scHours = val;
                    
                } else {
                    this.allCalendar[d].scHours = 0;
                }

                

                if(this.changesDays.indexOf(date) == -1) {
                    this.changesDays.push(date);
                }
            }

        },
        // Вычисляем график под парметры скользящего календаря
        getSlidingDays(start = $moment(this.startdt), end = $moment(this.enddt)) {
            let slidingDays = [];
            let date = $moment(start).clone();
            
            let nextYearDay = $moment(end).clone().add(1,'day'); 
            
            
            while(date.isBefore(end) || date.isSame(end, 'date')) {
                for(let j = 0; j < this.workingdayscount; j++) {
                    if(!date.isSame(nextYearDay, 'date')) {
                        slidingDays.push({
                            date: date.format('YYYY-MM-DD'),
                            value: true
                        })
                        date.add(1,'day');
                    }
                }

                for(let k = 0; k < this.breakingdayscount; k++) {
                    if(!date.isSame(nextYearDay, 'date')) {
                        slidingDays.push({
                            date: date.format('YYYY-MM-DD'),
                            value: false
                        })
                        date.add(1,'day');
                    }
                }
            }

            return slidingDays;
        },
        isNeedGenerate(param1, oldParam1, param2) {
            if( (!!param1 && !!param2 && !!this.allCalendar) || (!!param1 && !!param2 && !!this.typeid) ) {
                if(!!param1 && !!oldParam1 || oldParam1 == 0) {
                    this.generateFullClnd();
                    this.generateClnd(true);
                } else {
                    this.generateClnd();
                }
            } 
        },
        // Генерация следующего года
        generateNextClnd() {
            this.menuOpen = false;
            this.menuActivator = null;
            
            let start = null;
            if(this.allCalendar.length) {
                // Берем последнюю рабочую дату из базы
                let lastWorkDate = null;
                this.allCalendar.map(it => {
                    if(it.scHours > 0) {
                        lastWorkDate = it.scDate;
                    }
                })
                start = $moment(lastWorkDate);
            } else {
                start = $moment(this.startdt);
            }

            this.enddt = $moment(this.$refs.calendar.value).endOf('year');
            
            this.generateFullClnd(start, this.enddt);
            this.generateClnd();
            
        },
        // Генерация всего периода (если данных не пришло с сервера)
        generateFullClnd(start = $moment(this.startdt), end = $moment(this.enddt)) {
            console.log('generateFullClnd');
            const calendar = [];
            let slidingDays = null;
            let counter = 0;
            let date = $moment(start).clone();

            if(CALENDAR_TYPES['sliding'].id == this.typeid) {
                slidingDays = this.getSlidingDays(start, end);
            }

           
            
            while(date.isBefore(end) || date.isSame(end, 'date')) {
                let scHours = null;
                
                if(CALENDAR_TYPES['main'].id == this.typeid) {
                    scHours = !this.isDayOff(date) ? this.dayhours : 0;
                } else {
                    scHours = slidingDays[counter].value ? this.dayhours || 8 : 0;
                }

                calendar.push({
                    scDate: $moment(date).format('YYYY-MM-DD'),
                    scHours: scHours,
                    isHoliday: false,
                    object: $utils.uuidv4()
                })
                

                date.add(1,'day');
                counter++;
                
            }
            
            if(this.allCalendar.length) {
                calendar.map(day => {
                    const index = this.allCalendar.findIndex(it => it.scDate == day.scDate);
                    if(index != -1) {
                        this.allCalendar[index] = {...day};
                    } else {
                        this.allCalendar.push(day);
                    }
                    
                    if(this.changesDays.indexOf(day.scDate) == -1) {
                        this.changesDays.push(day.scDate);
                    }
                })
                
            } else {
                this.allCalendar = [...calendar];
                this.changesDays = [...calendar.map(it => it.scDate)];
            }

            this.allCalendar = this.allCalendar.sort((a,b) => $moment(a.scDate) - $moment(b.scDate));
            
                         
        }
         
    },
    template: `
        <v-container class="calendar-container" v-show="!!typeid">
            <v-row justify="center">
                <v-col cols="12" md="8" v-show="!loader">
                    <v-sheet height="64">
                        <v-toolbar
                            flat
                        >
                            <v-btn
                                outlined
                                class="mr-4"
                                color="grey darken-2"
                                @click="setToday"
                            >
                                Сегодня
                            </v-btn>
                            <v-btn
                                fab
                                text
                                small
                                color="grey darken-2"
                                @click="prev"
                            >
                                <v-icon small>
                                mdi-chevron-left
                                </v-icon>
                            </v-btn>
                            <v-btn
                                fab
                                text
                                small
                                color="grey darken-2"
                                @click="next"
                            >
                                <v-icon small>
                                    mdi-chevron-right
                                </v-icon>
                            </v-btn>
                            <v-toolbar-title v-if="$refs.calendar">
                                {{ $refs.calendar.title }}
                            </v-toolbar-title>
                            <v-spacer></v-spacer>
                        </v-toolbar>
                    </v-sheet>
                    <v-sheet height="300" width="600">
                        <v-calendar
                            ref="calendar"
                            v-model="focus"
                            color="primary"
                            :type="type"
                            @click:event="showEvent"
                            @click:date="viewDay"
                            :weekdays="weekday"
                            locale="ru"
                        >
                            <template v-slot:day="{ past, date }">
                                <div 
                                    v-if="checkDate(date)"
                                    class='day-container' 
                                    @mouseover="onDayHover(true, date)" 
                                    @mouseleave="onDayHover(false, date)" 
                                    :id='date'
                                >
                                    <v-checkbox
                                        v-show='isActive(date)'
                                        v-model="dayOffs"
                                        label=""
                                        :value="date"
                                    ></v-checkbox>
                                    <div 
                                        class='day-content' 
                                        @click="showEvent($event, date)"
                                    >
                                        <div 
                                            v-if="!selectedOpen || focus != date"
                                        >
                                            {{ getDateHours(date) }}
                                        </div>
                                        <div 
                                            class="day-hour-edit"
                                            v-else-if="selectedOpen && focus == date"
                                        >
                                            <v-text-field
                                                :value="getDateHours(focus)"
                                                @change="(e) => onInputChange(e, focus)"
                                                @blur="selectedOpen = false"
                                                :autofocus="true"
                                            ></v-text-field>
                                        </div>
                                    </div>
                                    
                                </div>

                                <div v-else></div>

                            </template>
                        </v-calendar>
                        <v-menu
                            :position-x="650"
                            :position-y="500"
                            v-model="menuOpen"
                            :close-on-content-click="false"
                            :close-on-click="false"
                            style="left:460px; top: 460px;"
                        >
                            <v-card
                                color="grey lighten-4"
                                min-width="350px"
                                flat
                            >
                                <v-toolbar color="primary" dark height="28">Внимание</v-toolbar>

                                <v-card-text>
                                    <span>
                                        {{ menuMsg }}
                                    </span>
                                </v-card-text>

                                <v-card-actions style="display:flex; justify-content:center;">
                                    <v-btn
                                        text
                                        color="primary"
                                        @click="generateNextClnd"
                                    >
                                        Подтвердить
                                    </v-btn>
                                    <v-btn
                                        text
                                        color="primary"
                                        @click="menuOpen = false"
                                    >
                                        Отмена
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-menu>
                    </v-sheet>
                </v-col>
                <v-col cols="12" md="8" v-show="loader">
                    <JetSpinner />
                </v-col>
            </v-row>
        </v-container>
    `
}

export default {
    name: 'ProductionCalendar',
    components: {
        Calendar
    },
    methods: {
        onTypeidChange(val) {
            if(val) {
                this.$refs['sincalendarBreakingdayscount'].setVisible(false);
                this.$refs['sincalendarWorkingdayscount'].setVisible(false);
                this.$refs['sincalendarWeekdays'].setVisible(true);
                this.$refs['sincalendarDayhours'].setVisible(true);
                this.$refs['sincalendarBreakingdayscount'].rules = [];
                this.$refs['sincalendarWorkingdayscount'].rules = [];                    
            } else {
                this.$refs['sincalendarBreakingdayscount'].setVisible(true);
                this.$refs['sincalendarWorkingdayscount'].setVisible(true);
                this.$refs['sincalendarWeekdays'].setVisible(false);
                this.$refs['sincalendarDayhours'].setVisible(false);
                this.$refs['sincalendarWeekdays'].rules = [];
                this.$refs['sincalendarDayhours'].rules = [];
                this.$refs["sincalendar"].dayhours = this.sincalendarDayhours || 8;
            }
        }
    },
    mounted() {
        const self = this;

        watch('form .v-tabs .v-tabs-items .v-window-item.v-window-item--active .container').then(async (node) => {
            $(node).append('<div id="prod-calendar"></div>');
            Calendar.parent = self;
            Calendar.el = $(node).find('#prod-calendar').get(0);
            self.$refs["sincalendar"] = new Vue(Calendar);
            const calendar = self.$refs["sincalendar"];
            
            self.onDataChange = (val) => {
                let prepareValue = val.replace('sincalendar','');
                let calendarValue = prepareValue.replace(prepareValue[0], prepareValue[0].toLowerCase());
                if(val == 'sincalendarTypeid') {
                    calendar[calendarValue] = self[val];
                    self.onTypeidChange(CALENDAR_TYPES['main'].id == self[val]);
                } else {
                    self.$nextTick(() => {
                        calendar[calendarValue] = self[val];
                    })
                }
            }

            
            if(!!self.sincalendarTypeid) {
                self.onTypeidChange(CALENDAR_TYPES['main'].id == self.sincalendarTypeid);
            }
            

            

            // Получаем данные по дням для календаря
            if(!!self.sincalendarId) {
                try {
                    calendar.loader = true;
                    let uri = `sin2:/v:${VIEW_ID}/?filter=eq(field(".calendarid"),param("${self.sincalendarId}","id"))`;
                    let _r = await $http.post('/rpc?d=jsonRpc', {
                        type: 'core-read',
                        query: uri
                    });

                    const m = new Model(_r.result.model);
                    const resp = m.sin2obj(_r.result.data, true);
                    console.log('resp', resp);
                    if(resp.length) { 
                        resp.map(r => {
                            const scdate = $moment(r.sincalendarscalesScdate);
                            calendar.allCalendar.push({
                                scDate: scdate.format('YYYY-MM-DD'),
                                scHours: r.sincalendarscalesSchours,
                                isHoliday: r.sincalendarscalesIsholiday,
                                object: !!r.sincalendarscalesId ? r.sincalendarscalesId : $utils.uuidv4()
                            })
                        })
                    }

                    calendar.allCalendar = calendar.allCalendar.sort((a,b) => $moment(a.scDate) - $moment(b.scDate));
                    calendar.loader = false;
                    
                } catch (error) {
                    console.error('ERR: (calendarScales)');
                } finally {
                    calendar.loader = false;
                }
            } 
            
            // Получаем значение поля код календаря
            const newClndCode = self.maxRowCode() + 1;

            // Инициализируем значения объекта календаря
            calendar.calendarid = self.sincalendarId;
            calendar.dayhours = self.sincalendarDayhours;
            calendar.weekdays = self.sincalendarWeekdays;
            calendar.breakingdayscount = self.sincalendarBreakingdayscount;
            calendar.workingdayscount = self.sincalendarWorkingdayscount;
            calendar.code = self.sincalendarCode || newClndCode;
            calendar.typeid = self.sincalendarTypeid;
            calendar.startdt = !!self.sincalendarStartdt ? $moment(self.sincalendarStartdt) : $moment();
            calendar.enddt = !!self.sincalendarEnddt ? $moment(self.sincalendarEnddt) : $moment().endOf('year');
            
            // Скрываем поля, если календарь создается
            if(!(!!self.sincalendarStartdt) || !(!!self.sincalendarId)) {
                self.$refs['sincalendarStartdt'].setValue($moment());
                self.$refs['sincalendarStartdt'].$refs['dtinput'].value = $moment().format('DD.MM.YYYY');

                self.$refs['sincalendarBreakingdayscount'].setVisible(false);
                self.$refs['sincalendarWorkingdayscount'].setVisible(false);
                self.$refs['sincalendarWeekdays'].setVisible(false);
                self.$refs['sincalendarDayhours'].setVisible(false);

                self.$refs['sincalendarCode'].value = newClndCode;
            }

            
            // Скрываем лишние поля
            self.$refs['sincalendarBreakingdayscount'].label = "Через количество дней";
            self.$refs['sincalendarShiftamount'].setVisible(false);
            self.$refs['sincalendarStarttime'].setVisible(false);
            self.$refs['sincalendarBreak'].setVisible(false);
            self.$refs['sincalendarShiftamount'].rules = [];
            self.$refs['sincalendarStarttime'].rules = [];
            self.$refs['sincalendarBreak'].rules = [];


            self.$parent.beforeSave = () => {
                const form = self.$refs["form"];
                if(!!form && !form.validate()) {
                    return false;
                }
            }

            self.$parent.afterSave = async (p) => {
                // Записываем дни из календаря
                let opts = {
                    type: (!!self.sincalendarId) ? 'api2.update' : 'api2.create',
                    query: 'sin2:/v:' + VIEW_ID,
                    view: VIEW_ID,
                    packs: []
                }

                const scDates = [];
                const enddt = self.enddt || calendar.enddt;
                
                calendar.allCalendar.map(date => {
                    if(($moment(date.scDate).isBefore(enddt) || $moment(date.scDate).isSame(enddt, 'date')) && scDates.indexOf(date.scDate) == -1) {
                        scDates.push(date.scDate);
                        if(!!self.sincalendarId) {
                            if(calendar.changesDays.indexOf(date.scDate) != -1) {
                                opts.packs.push({
                                    object: date.object,
                                    values: {
                                        "sinCalendarScales.calendarid": {name: 'sinCalendarScales.calendarid', type: 'id', value: p.id},
                                        "sinCalendarScales.scDate": {name: 'sinCalendarScales.scDate', type: 'date', value: $moment(date.scDate).unix()*1000},
                                        "sinCalendarScales.scHours": {name: 'sinCalendarScales.scHours', type: 'float', value: date.scHours},
                                        "sinCalendarScales.isHoliday": {name: 'sinCalendarScales.isHoliday', type: 'boolean', value: date.isHoliday},
                                    }
                                })
                            }
                        } else {
                            opts.packs.push({
                                object: date.object,
                                values: {
                                    "sinCalendarScales.calendarid": {name: 'sinCalendarScales.calendarid', type: 'id', value: p.id},
                                    "sinCalendarScales.scDate": {name: 'sinCalendarScales.scDate', type: 'date', value: $moment(date.scDate).unix()*1000},
                                    "sinCalendarScales.scHours": {name: 'sinCalendarScales.scHours', type: 'float', value: date.scHours},
                                    "sinCalendarScales.isHoliday": {name: 'sinCalendarScales.isHoliday', type: 'boolean', value: date.isHoliday},
                                }
                            })
                        }
                    }
                    
                    
                })


                let res = null;
                if(opts.packs.length) {
                    try {
                        calendar.loader = true;

                        res = await $http.post(opts);
                        if (!!res.error){
                            throw res.error;
                        }
                        
                        console.log('RES', res);
                        calendar.loader = false;
                    } catch (e) {
                        console.log('SAVE ERR:', e);
                        jet.msg({text:'ВНИМАНИЕ! Ошибка сохранения изменений. <br /><small>Информация для технической поддержки: ' + 
                            e.message + '</small>', type:'warning'});
                    } finally {
                        calendar.loader = false;
                    }

                }

                return res;
            };
        });
    }
}
</script>

<style lang="scss">
.calendar-container {
    margin-top: 1rem;
}
.day-container {
    display: flex;
    justify-content: space-around;
    margin-top: -0.7rem;
}

.day-content {
    font-size: 16px;
    text-align: center;
    margin-top: 0.4rem;
    cursor: pointer;
}

.day-hour-edit {
    margin-top: -1.5rem;
}

.day-hour-edit .v-input {
    font-size: 16px;
    width: auto;
}

.v-calendar-weekly__day.dayoff {
    border: 3px solid red!important;
}

.v-calendar-weekly__day.work {
    border: 1px solid #2962FF!important;
}

.v-calendar-weekly__day-label .v-btn {
    width: 20px!important;
    height: 20px!important;
    border-radius: 50%!important;
}

/*.calendar-container .v-menu__content {
    top: 460px!important;
    left: 460px!important;
}*/

</style>