<template>
  <v-dialog
    fullscreen hide-overlay
    transition="dialog-bottom-transition"
    v-model="dialog"
  >
    <!-- Основное содержимое -->
    <v-card class="fill-height">
      <v-layout column fill-height>
        <!-- Заголовок -->
        <v-flex shrink>
          <v-toolbar dark color="primary">
            <v-toolbar-title>
              Маршрут:
              {{ item && item.vcroutesRoutecode || '' }}
              {{ item && item.vcroutesRoutename || '' }}
            </v-toolbar-title>

            <v-spacer/>

            <v-toolbar-items>
              <v-btn dark text @click="closeDialog">
                Закрыть
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>
        </v-flex>

        <!-- Основная часть -->
        <v-flex style="height: 100px">
          <v-layout fill-height>
            <!-- Список -->
            <v-flex shrink class="fill-height" style="overflow: auto;">
              <div style="width: 350px;" class="fill-height">
                <v-tabs-items class="fill-height" v-model="currentTab">    
                  <!-- Список остановок и выбор версии маршрута -->
                  <v-tab-item>
                    <v-layout column class="fill-height">
                      <v-flex class="list_flex">
                        <!-- Выбор версии маршрута -->
                        <v-col cols="1" sm="12" md="12">
                          <v-autocomplete
                            label="Версия маршрута"
                            v-model="versions.selected"
                            item-value="id"
                            item-text="shortName"
                            return-object
                            :items="versions.list"
                            :loading="versions.loading"
                            @change="onVersionSelected($event)"
                          >
                            <template #item="{item: version}">
                              <div id="routeVersions">
                                <div>
                                  {{ version.vcroutesRoutecode }}
                                </div>
                                <div>
                                  {{ version.vcroutesRoutename }}
                                </div>
                                <div>
                                  {{ version.vcroutesVersion }}
                                </div>
                                <div>
                                  {{ version.vcroutesVerstatusName }}
                                </div>
                              </div>
                            </template>
                          </v-autocomplete>
                        </v-col>
                        <!-- Заголовок списка остановок -->
                        <h3 v-if="busStops.length" class="px-4">
                          Остановки маршрута

                          <!-- Оповещение что сортируются остановки -->
                          <v-tooltip bottom v-if="sortChangesInProgress">
                            <template #activator="{ on }">
                              <v-progress-circular
                                indeterminate
                                class="ml-4"
                                size="24"
                                color="primary"
                                v-on="on"
                              ></v-progress-circular>
                            </template>

                            Формирование маршрута...
                          </v-tooltip>
                        </h3>

                        <!-- Список -->
                        <v-list dense v-if="loaded" :disabled="sortChangesInProgress">
                          <template v-if="canEdit">
                            <draggable
                              v-model="busStops"
                              @start="isDrag = true"
                              @end="isDrag = false"
                              @change="onDragChanged"
                            >
                              <div v-for="(stop, index) in busStops" :key="index">
                                <BusStopItem
                                  :item="stop"
                                  :show-menu="canEdit"
                                  :is-first="index === 0"
                                  :is-last="index === busStops.length - 1"
                                  :loading="stopsInProgress.findIndex(it => it.id === stop.id) > -1"
                                  @onOver="highlightMarker"
                                  @onOut="unHighlightMarker"
                                  @remove="onRemoveStopShow"
                                  @change-to="onChangeTo"
                                  @bus-stop-change="onBusStopChange"
                                  @on-bus-click="onBusClick(stop)"
                                ></BusStopItem>

                                <v-divider class="ma-0 mx-4"/>
                              </div>
                            </draggable>
                          </template>

                          <template v-else>
                            <div v-for="(stop, index) in busStops" :key="index">
                              <BusStopItem
                                :item="stop"
                                :show-menu="canEdit"
                                :is-first="index === 0"
                                :is-last="index === busStops.length - 1"
                                :loading="stopsInProgress.findIndex(it => it.id === stop.id) > -1"
                                @remove="onRemoveStopShow"
                                @change-to="onChangeTo"
                                @bus-stop-change="onBusStopChange"
                                @on-bus-click="onBusClick(stop)"
                              ></BusStopItem>

                              <v-divider class="ma-0 mx-4"/>
                            </div>
                          </template>
                        </v-list>

                        <!-- Если нет данных -->
                        <p v-if="loaded && !busStops.length" class="pa-4">
                          На маршруте еще не добавлены остановки
                        </p>

                        <!-- Лоадер -->
                        <div v-if="!loaded" class="pa-4 text-center">
                          <v-progress-circular
                            indeterminate
                            color="primary"
                          ></v-progress-circular>
                        </div>
                      </v-flex>

                      <!-- Блок с действиями -->
                      <v-flex shrink class="pa-4 text-right" v-if="canEdit">
                        <v-btn
                          outlined
                          class="mr-2"
                          color="primary"
                          @click="onChangeTo({name: 'create-bus-stop', item: null})"
                        >
                          Новая
                        </v-btn>

                        <v-btn color="primary" @click="dialogs.isShowAddStopDialog = true">
                          Добавить
                        </v-btn>
                      </v-flex>
                    </v-layout>
                  </v-tab-item>

                  <!-- Динамический компонент редактирования -->
                  <v-tab-item>
                    <!-- Редактирование радиуса -->
                    <EditRadius
                      v-if="editTabCompName === 'edit-radius'"
                      v-model="editedItem"
                      @radiusChanged="onRadiusChanged"
                      @onCancel="onEditCancel"
                      @onSubmit="onEditRadiusSubmit"
                    ></EditRadius>

                    <!-- Создание/Редактирование остановки -->
                    <CreateBusStop
                      :item="editedItem"
                      v-if="editTabCompName === 'create-bus-stop'"
                      @on-cancel="onCreateBusStopCancel"
                      @on-near-add="onNearStopAdd"
                      @on-new-add="onNearStopAdd"
                      @on-update="onBusStopUpdated"
                    ></CreateBusStop>
                  </v-tab-item>

                  <!-- Список нулевых трасс -->
                  <v-tab-item>
                    <v-layout column>
                      <v-flex>
                        <h3 class="pa-4">
                          Список нулевых трасс
                        </h3>

                        <!-- Список конечных остановок -->
                        <v-list>
                          <v-list-item
                            v-for="stop in finalStops"
                            class="mt-4"
                            :key="stop.id"
                          >
                            {{ stop.locationName }}
                          </v-list-item>
                        </v-list>
                      </v-flex>
                    </v-layout>
                  </v-tab-item>
                </v-tabs-items>
              </div>
            </v-flex>

            <!-- Правая часть -->
            <v-flex class="fill-height">
              <v-layout column fill-height>
                <!-- Панель инструментов -->
                <v-flex shrink v-if="canEdit" class="py-2">
                  <v-toolbar dense flat>
                    <v-layout>
                      <!-- Альтернативные трассы -->
                      <v-flex shrink align-self-center>
                        <v-layout align-center>
                          <!-- Выбор альтернативной трассы -->
                          <v-flex class="mr-2 pt-3">
                            <v-select
                              hide-details return-object
                              label="Трассы"
                              style="width: 180px;"
                              placeholder="Выберите трассу маршрута"
                              item-text="alterdesc"
                              v-model="alternatives.selected"
                              :items="alternatives.list"
                              :loading="alternatives.loading || copyRouteLoading"
                            ></v-select>
                          </v-flex>

                          <!-- Добавление альтернативной трассы -->
                          <v-flex class="mr-2">
                            <v-tooltip bottom>
                              <template #activator="{ on, attrs }">
                                <v-btn
                                  fab x-small depressed
                                  v-on="on"
                                  v-bind="attrs"
                                  :loading="copyRouteLoading"
                                  @click="onAlternateAddClick"
                                >
                                  <v-icon>
                                    mdi-plus
                                  </v-icon>
                                </v-btn>
                              </template>

                              Добавить трассу
                            </v-tooltip>
                          </v-flex>

                          <!-- Удаление альтернативной трассы -->
                          <v-flex>
                            <v-tooltip bottom>
                              <template #activator="{ on, attrs }">
                                <v-btn
                                  fab x-small depressed
                                  v-on="on"
                                  v-bind="attrs"
                                  :loading="removeRouteLoading"
                                  :disabled="disableRemoveAlternate"
                                  @click="onAlterRouteRemoveClick"
                                >
                                  <v-icon>
                                    mdi-minus
                                  </v-icon>
                                </v-btn>
                              </template>

                              Удалить трассу
                            </v-tooltip>
                          </v-flex>
                        </v-layout>
                      </v-flex>

                      <!-- Кнопки управления -->
                      <v-flex shrink align-self-center class="ml-2">
                        <!-- Удалить трек -->
                        <v-tooltip bottom>
                          <template #activator="{ on, attrs }">
                            <v-btn
                              text fab small
                              v-on="on"
                              v-bind="attrs"
                              @click="clearTrack.showDialog = true"
                            >
                              <v-icon>
                                mdi-delete
                              </v-icon>
                            </v-btn>
                          </template>

                          Очистить трассу
                        </v-tooltip>

                        <!-- По треку ТС -->
                        <v-tooltip bottom>
                          <template #activator="{ on, attrs }">
                            <v-btn
                              text fab small
                              v-on="on"
                              v-bind="attrs"
                              :disabled="trackBy.loading"
                              :loading="trackBy.loading"
                              @click="onByTrackClick"
                            >
                              <v-icon>
                                mdi-chart-timeline-variant
                              </v-icon>
                            </v-btn>
                          </template>

                          Создать по треку ТС
                        </v-tooltip>
                      </v-flex>

                      <!-- Нулевыые трассы -->
                      <v-flex
                        shrink align-self-center
                        class="ml-2"
                      >
                        <v-layout align-center>
                          <!-- Checkbox -->
                          <v-flex>
                            <v-checkbox
                              hide-details
                              label="Нулевые трассы"
                              v-model="zeroTrackShow"
                            ></v-checkbox>
                          </v-flex>

                          <!-- Показ/Скрытие нулевых трасс -->
                          <v-flex class="ml-1">
                            <v-tooltip bottom>
                              <template #activator="{ on, attrs }">
                                <v-btn
                                  fab x-small depressed
                                  v-on="on"
                                  v-bind="attrs"
                                  @click="showZeroTracksList"
                                >
                                  <v-icon>
                                    mdi-{{ zeroTracks.showList ? 'eye-off' : 'eye' }}
                                  </v-icon>
                                </v-btn>
                              </template>

                              Показать нулевые трассы
                            </v-tooltip>
                          </v-flex>

                          <!-- Дополнительное меню -->
                          <v-flex class="ml-1">
                            <v-menu offset-y transition="slide-x-transition">
                              <template #activator="{ on }">
                                <v-btn
                                  fab x-small depressed
                                  v-on="on"
                                >
                                  <v-icon>
                                    mdi-dots-vertical
                                  </v-icon>
                                </v-btn>
                              </template>

                              <v-list>
                                <!-- Редактировать -->
                                <v-list-item>
                                  <v-list-item-title>
                                    Редактировать
                                  </v-list-item-title>
                                </v-list-item>

                                <!-- Очистить -->
                                <v-list-item>
                                  <v-list-item-title>
                                    Очистить
                                  </v-list-item-title>
                                </v-list-item>
                              </v-list>
                            </v-menu>
                          </v-flex>
                        </v-layout>
                      </v-flex>

                      <!-- Объекты маршрута -->
                      <v-flex
                        shrink align-self-center
                        class="ml-2">
                        <v-layout align-center>
                          <v-flex>
                            <v-checkbox
                              hide-details
                              label="Объекты маршрута"
                              v-model="showTrackObjects"
                            ></v-checkbox>
                          </v-flex>

                          <v-flex class="ml-1">
                            <v-tooltip bottom>
                              <template #activator="{ on, attrs }">
                                <v-btn
                                  fab x-small depressed
                                  v-on="on"
                                  v-bind="attrs"
                                >
                                  <v-icon>
                                    mdi-eye
                                  </v-icon>
                                </v-btn>
                              </template>

                              Показать объекты
                            </v-tooltip>
                          </v-flex>
                        </v-layout>
                      </v-flex>

                      <v-flex></v-flex>

                      <!-- Сохранить -->
                      <v-flex shrink>
                        <v-btn
                          color="primary"
                          elevation="0"
                          :disabled="trackSaving"
                          :loading="trackSaving"
                          @click="onSave"
                        >
                          Сохранить
                        </v-btn>
                      </v-flex>
                    </v-layout>
                  </v-toolbar>
                </v-flex>

                <!-- Карта -->
                <v-flex style="height: 100px;">
                  <div class="fill-height" id="route_map"/>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-card>

    <!-- Диалог для маршрута вида "Устарел/Утвержден" -->
    <template v-if="!canEdit">
      <JetDialog
        width="600"
        title="Внимание"
        v-model="showNotForEditDialog"
      >
        <template #default>
          Данный маршрут
          <b>{{ item && item.vcroutesVerstatusName && item.vcroutesVerstatusName.trim().toLowerCase() }}</b>,
          поэтому его редактирование недоступно
        </template>

        <template #actions>
          <v-btn outlined class="body-2" color="red lighten-1" @click="showNotForEditDialog = false">
            Ок
          </v-btn>

          <v-spacer/>

          <v-btn color="primary" @click="closeDialog">
            Закрыть маршрут
          </v-btn>
        </template>
      </JetDialog>
    </template>

    <!-- Диалог выбора остановочных пунктов -->
    <JetDialog
      title="Выбор остановок"
      width="900"
      apply-button-text="Добавить"
      v-model="dialogs.isShowAddStopDialog"
      @on-cancel-click="onCancelAddStops"
      @on-apply-click="onAddStops"
    >
      <template #default>
        <SelectStops
          v-model="selectedWaypoints"
          :need-clear="needClearWaypoints"
        ></SelectStops>
      </template>
    </JetDialog>

    <!-- Диалог удаления остановки -->
    <JetDialog
      title="Удалить остановку с трассы?"
      v-model="removeStop.showDialog"
    >
      <template #default>
        Подтвердите, что хотите удалить остановочный пункт
        <b>"{{ (removeStop.item && removeStop.item.locationName) || '' }}"</b>
        с трассы маршрута?
      </template>

      <template #actions>
        <v-btn
          outlined
          color="red"
          @click="onRemoveStopClose"
        >
          Отмена
        </v-btn>

        <v-spacer/>

        <v-btn
          dark
          class="body-2"
          color="red"
          @click="onRemoveStop"
        >
          Удалить
        </v-btn>
      </template>
    </JetDialog>

    <!-- Диалог очистки трека -->
    <JetDialog
      title="Очистить трек?"
      apply-button-text="Очистить"
      v-model="clearTrack.showDialog"
      @on-cancel-click="clearTrack.showDialog = false"
      @on-apply-click="onAllTrackClear"
    >
      <template #default>
        Подтвердите, что хотите очистить трек
      </template>
    </JetDialog>

    <!-- Диалог оповещение если ошибка в остановках -->
    <JetDialog
      title="Невозможно сохранить"
      width="600"
      v-model="dialogs.isCantSave"
    >
      <template #default>
        Трасса маршрута должна содержать не меньше 2, и не больше 4 конечных остановочных пунктов
      </template>

      <template #actions>
        <v-spacer/>

        <v-btn
          outlined
          color="primary"
          @click="dialogs.isCantSave = false, dialogs.forceSave = true"
        >
          Сохранить
        </v-btn>

        <v-btn
          outlined
          color="primary"
          @click="dialogs.isCantSave = false"
        >
          Не сохранять
        </v-btn>
      </template>
    </JetDialog>

    <!-- Диалог "По треку ТС" -->
    <JetDialog
      title="Создать по треку ТС"
      width="800"
      apply-button-text="Загрузить"
      v-model="dialogs.isByTrack"
      :disabled-apply-button="trackByDisabledApplyButton"
      @on-apply-click="onLoadTrack"
      @on-cancel-click="dialogs.isByTrack = false"
    >
      <template #default>
        <v-row class="align-center">
          <v-col>
            <v-row class="align-center">
              <!-- Начало -->
              <v-col cols="6">
                <datetime
                  type="datetime"
                  input-id="startDate"
                  v-model="trackBy.start"
                >
                  <label for="startDate" slot="before">
                    Дата начала
                  </label>
                </datetime>
              </v-col>

              <!-- Окончание -->
              <v-col cols="6">
                <datetime
                  type="datetime"
                  input-id="endDate"
                  v-model="trackBy.end"
                >
                  <label for="endDate" slot="before">
                    Дата окончания
                  </label>
                </datetime>
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="6">
            <v-layout align-center>
              <v-text-field
                clearable
                class="mr-4"
                placeholder="Поиск по гос номеру"
                v-model="trackBy.filter"
              ></v-text-field>
            </v-layout>
          </v-col>
        </v-row>

        <div style="height: 300px;">
          <v-data-table
            dense show-select single-select
            height="260"
            v-model="trackBy.selected"
            :loading="trackBy.loadingVehicles"
            :headers="trackBy.headers"
            :items="trackByItems"
          >
            <template #no-data>
              Ничего не загружено
            </template>
          </v-data-table>
        </div>
      </template>
    </JetDialog>

    <!-- Диалог подтверждения загрузки маршрута по треку ТС -->
    <JetDialog
      title="Внимание"
      apply-button-text="Да, уверен"
      v-model="dialogs.sureByTrack"
      @on-apply-click="applyRouteByTrack"
      @on-cancel-click="dialogs.sureByTrack = false"
    >
      <template #default>
        Текущий трек маршрута будет удален.
        Уверены, что хотите загрузить новый трек маршрута?
      </template>
    </JetDialog>

    <!-- Диалог создания альтернативной трассы -->
    <JetDialog
      title="Добавление альтернативной трассы"
      v-model="dialogs.addAlternateTrack"
      @on-apply-click="onApplyAddAlternate"
      @on-cancel-click="onCancelAddAlternate"
    >
      <v-text-field
        title="Название альтернативной трассы"
        placeholder="Введите название альтернативной трассы"
        v-model="alternatives.newName"
        :rules="[
            v => !!v || 'Не должно быть пустым',
          ]"
      ></v-text-field>
    </JetDialog>

    <!-- Диалог оповещение о пустом маршруте -->
    <JetDialog
      title="Невозможно сохранить"
      width="600"
      v-model="dialogs.cantEmptySave"
    >
      <template #default>
        Нельзя сохранять пустой маршрут
      </template>

      <template #actions>
        <v-spacer/>

        <v-btn
          outlined
          color="primary"
          @click="dialogs.cantEmptySave = false"
        >
          Понятно
        </v-btn>
      </template>
    </JetDialog>

    <!-- Оповещения -->
    <v-snackbar
      bottom
      timeout="-1"
      v-model="snackbar.show"
      :color="snackbar.color"
    >
      <div class="mb-2">
        {{ snackbar.text }}
      </div>

      <v-btn
        small dark text
        @click="snackbar.show = false"
      >
        Закрыть
      </v-btn>
    </v-snackbar>
  </v-dialog>
</template>

<script>
// Сторонние зависимости
import draggable from 'vuedraggable';
import { Datetime } from 'vue-datetime';

// Компоненты
import JetDialog from '@/components/JetDialog';
import BusStopItem from './BusStopItem';
import SelectStops from './SelectStops';
import EditRadius from './EditRadius';
import CreateBusStop from './CreateBusStop';

// Сервисы
import MapLayersService from '@/services/MapLayersService';
import ShowRouteMapService from '@/components/services/ShowRouteMapService';

// Utils
import Map from '@/utils/map';
import VehicleService from '@/services/VehicleService';
import RouteService from '@/services/RouteService';

// Параметр значения vcroutesVerstatus при котором можно
// редактировать маршрут
const _allowStatusValues = [
  'ed4762ee-ca91-4b52-89e8-1176fb0a296e', // Проект
];

// Тип "Конечная остановка"
const FINAL_STOP_TYPE_GUID = 'bd78259f-7b66-ad44-e040-007f02002b0e';

// Идентификатор маршрута
let _routeId = null;

let _timeout = null;

const _tabs = {
  main: 0,
  editPoint: 1,
  zeroTrack: 2,
};

export default {
  name: 'ShowRoute',
  components: {
    draggable, datetime: Datetime,

    JetDialog,

    BusStopItem, SelectStops,
    EditRadius, CreateBusStop,
  },
  props: {
    // Коллекция
    collection: {
      type: Object,
      required: false,
      default: () => ({
        master: {
          selected: {},
        },
      }),
    },
    // Данные из-вне
    vcRouteItem: {
      type: Object,
      required: false,
      default: null,
    },
  },
  watch: {
    // При изменении имени компонента для табов
    editTabCompName(val) {
      this.currentTab = val === null ? _tabs.main : _tabs.editPoint;

      if (val && this.editedItem !== null && val === 'edit-radius') {
        ShowRouteMapService.editStop(
          _copy(this.editedItem),
        );
      }

      if (val === null) {
        this.editedItem = null;

        ShowRouteMapService.editStop(null);
      }
    },
    // При изменении типа альтернативной трассы
    alternatives: {
      deep: true,
      handler(val) {
        const selected = _copy(val.selected);

        if (!Map.initialized()) {
          clearTimeout(_timeout);

          _timeout = setTimeout(() => {
            this.alternatives.selected = selected;
          }, 1000);
        } else {
          if (selected != null) {
            _routeId = selected.id;

            this.loadRoute();
          }
        }
      },
    },
    // При изменении показа/скрытия нулевых трасс
    zeroTrackShow(val) {
      ShowRouteMapService.toggleShowZeroTracks(val);
    },
  },
  data: () => ({
    snackbar: {
      show: false,
      text: 'Example Text',
      color: 'primary',
    },
    // Флаг для отображения диалога
    dialog: true,
    // Набор флагов диалогов
    dialogs: {
      // Флаг для отображения окна добавления остановки
      isShowAddStopDialog: false,
      // Флаг при невозможности сохранить
      isCantSave: false,
      // Флаг для показа диалога загрузки по треку
      isByTrack: false,
      // Флаг диалога подтверждения
      sureByTrack: false,
      // Добавление альтернативной трассы
      addAlternateTrack: false,
      // Сохранить всё равно
      forceSave: false,
      // Нельзя сохранить пустой маршрут
      cantEmptySave: false,
    },
    // По треку ТС
    trackBy: {
      // Форма для валидации
      form: false,
      // Дата начала
      start: null,
      // Дата окончания
      end: null,
      // Массив ТС
      items: [],
      // Описание заголовков таблицы
      headers: [
        { text: 'Номер ТС', value: 'gov' },
        { text: 'Перевозчик', value: 'oname' },
      ],
      // Строка для фильтрации данных
      filter: '',
      // Выбранный элемент (всегда будет 1 элемент при выборе)
      selected: [],
      // Загрузка трека
      loading: false,
      // Загрузка списка ТС
      loadingVehicles: false,
    },
    // Признак что загружено
    loaded: false,
    // Текущий маршрут
    item: null,
    // Остановки маршрута
    busStops: [],
    // Выбранные остановки
    selectedWaypoints: [],
    // Необходимо очистить выбранные остановки
    needClearWaypoints: 0,
    // Признак что происходит перетаскивание (сортировка)
    isDrag: false,
    // Изначальный маршрут
    route: [],
    // Признак что происходит перестроение маршрута
    sortChangesInProgress: false,
    // Данные для диалога удаления остановки
    removeStop: {
      // Показ диалога
      showDialog: false,
      // Удаляемая остановка
      item: null,
    },
    // Данные для даилога очистки трека
    clearTrack: {
      // Показ диалога
      showDialog: false,
    },
    // Текущий таб
    currentTab: 0,
    // Название компонента редактирования
    editTabCompName: null,
    // Редактируемая остановка
    editedItem: null,
    // Показать диалог что нельзя редактировать данный маршрут
    showNotForEditDialog: false,
    // Можно ли редактировать данный маршрут
    canEdit: true,
    // Набор остановок над которыми происходят действия
    stopsInProgress: [],
    // Трек сохраняется
    trackSaving: false,
    // Альтернативные трассы
    alternatives: {
      // Данные
      list: [],
      // Выбранный элемент
      selected: null,
      // Флаг что данные загружаются
      loading: false,
      // Имя новой альтернативной трассы
      newName: '',
    },
    // Флаг что происходит копирование маршрута
    copyRouteLoading: false,
    // Флаг что происходит удаление альтернативного маршрута
    removeRouteLoading: false,
    // Флаг показа нулевой трассы
    zeroTrackShow: false,
    // Флаг показа объектов маршрута
    showTrackObjects: false,
    // Настройки Нулевой трассы
    zeroTracks: {
      // Происходи загрузка
      loading: false,
      // Список треков
      list: [],
      // Показ списка
      showList: false,
    },
    // Версии маршрута
    versions: {
      // Происходит загрузка
      loading: false,
      // Список версий
      list: [],
    },
  }),
  created() {
    this.item = this.vcRouteItem ?? this.collection.master.selected;

    // Определяем, можно ли редактировать
    this.canEdit = _allowStatusValues.includes((this.item && this.item.vcroutesVerstatus) || '');
    
    this.loadRouteVersions();

    this.loadAlternates();
  },
  mounted() {
    if (!this.item) {
      this.snackbar = {
        show: true,
        text: 'Необходимо выбрать запись!',
        color: 'warning',
      };

      return;
    }

    // Если нельзя редактировать - покажем оповещение
    if (!this.canEdit) {
      this.showNotForEditDialog = true;
    }

    const mapboxId = 'mapbox_script';
    const mapInit = () => {
      Map.init({
        container: document.getElementById('route_map'),
      }, true)
        .then(() => {
          if (!this.canEdit) {
            Map.getDraw().changeMode('static');
          }
        });
    };

    ShowRouteMapService.listen('by_track_stops', data => {
      this.busStops = data;
    });

    this.trackBy.loadingVehicles = true;

    VehicleService.getList()
      .then(data => {
        this.trackBy.items = data;
      })
      .catch(error => {
        console.error('ShowRoute::getVehicles', error);
      })
      .finally(() => {
        this.trackBy.loadingVehicles = false;
      });

    if (document.getElementById(mapboxId)) {
      mapInit();
    } else {
      let s = document.createElement('link');

      s.setAttribute('rel', 'stylesheet');
      s.setAttribute('href', 'https://api.tiles.mapbox.com/mapbox-gl-js/v1.5.0/mapbox-gl.css');
      document.getElementsByTagName('head')[0].appendChild(s);

      s = document.createElement('script');
      s.setAttribute('id', mapboxId);
      s.setAttribute('src', 'https://api.tiles.mapbox.com/mapbox-gl-js/v1.5.0/mapbox-gl.js');
      document.getElementsByTagName('head')[0].appendChild(s);

      s.onload = () => {
        mapInit();
      };
    }
  },
  destroyed() {
    ShowRouteMapService.destroy();
  },
  methods: {
    // Загрузка маршрута
    loadRoute() {
      MapLayersService.getRoutePoint(_routeId)
        .then(data => {
          const points = data.sort((a, b) => {
            if (a.pointNumber > b.pointNumber) {
              return 1;
            }

            if (a.pointNumber < b.pointNumber) {
              return -1;
            }

            return 0;
          });

          const p = points.filter(it => it.zero === false || !it.zero);

          if (p.length === 0) {
            jet.msg({
              text: 'У данной трассы нет точек маршрута',
            });
          }

          const route = {
            id: _routeId,
            // Маршрут - набор точек без нулевых трасс
            points: p,
          };

          this.busStops = _copy(
            points.filter(it => it.locationName !== null),
          );

          ShowRouteMapService.init(
            Map,
            _copy(this.busStops),
            _copy(data),
            this.canEdit,
          );

          this.route = _copy(points);

          this.loadZeroTracks();

          ShowRouteMapService.drawRoute(_copy(route));
        })
        .catch(err => {
          _err('ShowRoute::loadRoute(MapLayersService::getRoutePoint)', err);
        })
        .finally(() => {
          this.loaded = true;
        });
    },
    // Загрузка списка версий
    loadRouteVersions() {
      this.versions.loading = true;
      
      RouteService.getRouteVersions(this.item.vcroutesVerid)
        .then(result => {
          this.versions.list = [
            {
              vcroutesRoutecode: 'Номер',
              vcroutesRoutename: 'Название',
              vcroutesVersion: 'Версия',
              vcroutesVerstatusName: 'Статус',
              shortName: 'shortName',
              vcroutesId: 'versionID',
              disabled: true,
            }
          ];
          result.map(it => {
            it.vcroutesVerstatusName = it.verstatusName;
            it.shortName = it.vcroutesVersion + ' ' + it.vcroutesVerstatusName;
            this.versions.list.push({...it});
          });
          
          this.versions.selected = this.versions.list.find(it => it.vcroutesId === this.item.vcroutesId) || null;
        })
        .catch(err => {
          _err('ShowRoute::loadRouteVersions', err);
        })
        .finally(() => {
          this.versions.loading = false;
        });
    },
    onVersionSelected(item) {
      this.versions.selected = this.versions.list.find(it => it.vcroutesId === item.vcroutesId) || null;
      this.item = this.versions.selected;
      // Определяем, можно ли редактировать
      this.canEdit = _allowStatusValues.includes((this.item && this.item.vcroutesVerstatus) || '');
      
      this.loadAlternates();
      
      _routeId = this.item.vcroutesId;
      this.loadRoute();
    },
    // Загрузка списка альтернативных трасс
    loadAlternates(setDefault = true) {
      this.alternatives.loading = true;

      RouteService.getAlternates(this.item.vcroutesBaserouteid || this.item.vcroutesId)
        .then(result => {
          this.alternatives.list = result || [];

          if (setDefault === true) {
            this.alternatives.selected = result.find(it => it.alternumb === 1) || null;
          } else if (typeof setDefault === 'string') {
            this.alternatives.selected = result.find(it => it.id === setDefault) || null;
          }
        })
        .catch(err => {
          _err('ShowRoute::getAlternates', err);
        })
        .finally(() => {
          this.alternatives.loading = false;
        });
    },
    // Получение нулевых трасс
    loadZeroTracks() {
      this.zeroTracks.loading = true;

      RouteService.getZeroTracks(this.item.vcroutesId)
        .then(response => {
          console.log('response', response);
          if (response && Array.isArray(response) && response.length) {
            this.zeroTracks.list = response;

            const zeroPoints = _copy(this.route.filter(it => it.zero === true));
            const zeroTracks = _copy(response);

            ShowRouteMapService.drawZeroTracks(zeroPoints, zeroTracks);

            const _z = [];
            let _zc = 0;

            const _zeroTracksHash = [];

            zeroTracks.forEach(zeroTrack => {
              const index = zeroPoints.findIndex(it => it.id === zeroTrack.exitid);

              if (index > -1) {
                _zeroTracksHash.push(index + 1);
              }
            });

            _zeroTracksHash.sort((a, b) => a - b).forEach(end => {
              _z.push({
                points: zeroPoints.slice(_zc, end > zeroPoints.length ? zeroPoints.length : end),
              });

              _zc = end;
            });
          } else {
            this.snackbar = {
              show: true,
              text: 'Нет нулевых треков',
              color: 'primary',
            };
          }
        })
        .catch(err => {
          jet.msg({
            text: 'Не удалось загрузить Нулевые трассы',
            color: 'warning',
          });

          console.error('getZeroTrack err', err);
        })
        .finally(() => {
          this.zeroTracks.loading = false;
        });
    },
    // Закрытие данного диалогового окна
    closeDialog() {
      this.showNotForEditDialog = false;
      this.canEdit = false;
      this.editedItem = null;
      this.editTabCompName = null;
      this.clearTrack.showDialog = false;
      this.removeStop.showDialog = false;
      this.currentTab = _tabs.main;
      this.sortChangesInProgress = false;
      this.needClearWaypoints = 0;

      Object.keys(this.dialogs).forEach(it => {
        this.dialogs[it] = false;
      });

      this.dialog = false;

      this.$emit('close');
    },
    // Подсветка маркера (остановки)
    highlightMarker(item) {
      //   // console.log(item.name, 'over');
    },
    // // Снятие подсветки маркера (остановки)
    unHighlightMarker(item) {
      //   // console.log(item.name, 'out');
    },
    // При изменении сортировки
    onDragChanged(event) {
      const moved = event.moved || null;

      if (moved) {
        this.sortChangesInProgress = true;

        ShowRouteMapService
          .sortStops(
            _copy(this.busStops),
          )
          .finally(() => {
            this.sortChangesInProgress = false;
          });
      }
    },
    // При выборе остановок
    onAddStops() {
      const oldLen = this.busStops.length;
      const start = oldLen === 0 ? 0 : oldLen - 1;

      const ignoredStops = [];

      _copy(this.selectedWaypoints).forEach(it => {
        // Если такой остановки не было в списке, то только тогда добавляем
        if (this.busStops.findIndex(sIt => sIt.id === it.id) === -1) {
          it.isAdded = true;
          it.locationName = it.locname;
          it.coordinates = [
            it.longitude,
            it.latitude,
          ];
          it.location = it.id;
          it.radius = it.radius || 100;
          it.id = $utils.uuidv4();

          this.busStops.push(it);
        } else {
          ignoredStops.push(it.locname);
        }
      });

      if (ignoredStops.length) {
        this.snackbar = {
          show: true,
          text: `Остановки: ${ignoredStops.join(',')} были проигнорированы, т.к. были добавлены ранее`,
          color: 'purple',
        };
      }

      this.sortChangesInProgress = true;

      ShowRouteMapService.autoBuild(
        _copy(this.busStops), 
        start,
      )
      .finally(() => {
        this.sortChangesInProgress = false;
      });

      this.selectedWaypoints = [];
      this.needClearWaypoints = (new Date()).getTime();
      this.dialogs.isShowAddStopDialog = false;
    },
    // Отмена выбора остановок
    onCancelAddStops() {
      this.dialogs.isShowAddStopDialog = false;
      this.selectedWaypoints = [];
      this.needClearWaypoints = (new Date()).getTime();
    },
    // Удаление остановки
    onRemoveStopShow(stop) {
      this.removeStop = {
        item: _copy(stop),
        showDialog: true,
      };
    },
    // При закрытии диалогового окна
    onRemoveStopClose() {
      this.removeStop = {
        item: null,
        showDialog: false,
      };
    },
    // Удаление остановки
    onRemoveStop() {
      const removeId = this.removeStop.item.id;

      this.busStops.splice(
        this.busStops.findIndex(it => it.id === removeId),
        1,
      );

      ShowRouteMapService.sortStops(_copy(this.busStops))
        .then(() => {
          this.onRemoveStopClose();
        });
    },
    // Очистка всего маршрута
    onAllTrackClear() {
      this.busStops = [];
      this.route = [];
      this.selectedWaypoints = [];

      ShowRouteMapService.drawRoute(null);

      this.clearTrack.showDialog = false;
    },
    // При изменении радиуса
    onRadiusChanged() {
      ShowRouteMapService.editStop(
        _copy(this.editedItem),
      );
    },
    // При сохранении измененного радиуса
    onEditRadiusSubmit() {
      this.editTabCompName = null;

      ShowRouteMapService.updateStop(_routeId, _copy(this.editedItem));
    },
    // При отмене любого редактирования
    onEditCancel() {
      this.editTabCompName = null;
      this.editedItem = null;
    },
    // При смене типа компонента в табах
    onChangeTo({ name, item }) {
      this.editTabCompName = name;
      this.editedItem = item;
    },
    // Смена типа остановки
    onBusStopChange(value) {
      this.stopsInProgress.push(value);

      ShowRouteMapService.updateStop(_routeId, value)
        .then(() => {
          this.busStops.splice(
            this.busStops.findIndex(it => it.id === value.id),
            1,
            value,
          );
        })
        .finally(() => {
          this.stopsInProgress = [];
        });
    },
    // Клик по остановке
    onBusClick(stop) {
      // "Летим" к остановке
      Map.getMap().flyTo({
        center: stop.coordinates,
        essential: true,
        zoom: 14,
      });
    },
    // При добавлении ближайшей остановки
    onNearStopAdd(busStop) {
      this.selectedWaypoints = [];
      this.selectedWaypoints.push(busStop);

      this.editedItem = null;
      this.editTabCompName = null;

      this.onAddStops();
    },
    // При отмене создания новой точки маршрута
    onCreateBusStopCancel() {
      this.editTabCompName = null;
      this.editedItem = null;
    },
    // При обновлении остановки
    onBusStopUpdated(data) {
      const value = data.value;

      for (let i = 0, len = this.busStops.length; i < len; i++) {
        const stop = _copy(this.busStops[i]);

        if (stop.location === value.id) {
          stop.locationName = value.locname;

          this.busStops.splice(i, 1, stop);
          ShowRouteMapService.redrawStopName(_copy(stop));
        }
      }

      this.editedItem = null;
      this.editTabCompName = null;
      
    },
    // Сохранение маршрута
    onSave() {
      this.dialogs.cantEmptySave = this.busStops.length == 0;

      if (!this.dialogs.cantEmptySave) {
        const finals = this.busStops.filter(it => it.isFinal);

        this.dialogs.isCantSave = finals.length < 2 || finals.length > 4;

        // Если можем сохранять
        if (!this.dialogs.isCantSave || this.dialogs.forceSave) {
          this.trackSaving = true;

          const track = ShowRouteMapService.getData();

          if (track.length) {
            ShowRouteMapService.updateTrack(_routeId, track)
              .then(data => {
                if (!!data.error){
                  throw data.error;
                }

                this.trackSaving = false;

                this.snackbar = {
                  show: true,
                  text: 'Маршрут успешно сохранен!',
                  color: 'primary',
                };
              })
              .catch(err => {
                console.error('ShowRouteMapService::updateTrack', err);

                this.snackbar = {
                  show: true,
                  text: 'Маршрут не был сохранен!',
                  color: 'error',
                };
              });
          } else {
            this.snackbar = {
              show: true,
              text: 'Что-то пошло не так. Маршрут пустой!',
              color: 'error',
            };
            this.trackSaving = false;
          }
        }
      }
    },
    // По треку ТС
    onByTrackClick() {
      this.dialogs.isByTrack = true;
    },
    // Нажатие по кнопке "Загрузить"
    onLoadTrack() {
      if (
        (this.trackBy.start === null || this.trackBy.start === '')
        &&
        (this.trackBy.end === null || this.trackBy.end === '')
      ) {
        this.snackbar = {
          show: true,
          text: 'Необходимо выбрать даты начала и окончания!',
          color: 'error',
        };

        return;
      }

      if (this.trackBy.selected.length === 0) {
        this.snackbar = {
          show: true,
          text: 'Необходимо выбрать ТС!',
          color: 'error',
        };

        return;
      }

      this.dialogs.sureByTrack = true;
    },
    // Применение маршрута по треку
    applyRouteByTrack() {
      const vehicle = _copy(this.trackBy.selected[0]);

      this.trackBy.loading = true;

      VehicleService.getHistoryTrack(
        vehicle,
        new Date(this.trackBy.start),
        new Date(this.trackBy.end),
      )
        .then(async track => {
          // Скрываем все диалоговые окна
          this.dialogs.isByTrack = false;
          this.dialogs.sureByTrack = false;

          const points = track?.[0]?.points || [];

          if (points.length) {
            this.onAllTrackClear();

            const stops = VehicleService.getTrackMarkers(track, points)
              .filter(it => it.type === 'stop');

            const locations = (await RouteService.getLocations(true))
              .map(it => {
                it.coordinates = [
                  it.longitude,
                  it.latitude,
                ];

                it.location = it.id;

                return it;
              });

            ShowRouteMapService.drawRouteByTrack({
              stops: stops,
              points: points.map(it => {
                it.coordinates = [
                  it.lon,
                  it.lat,
                ];

                return it;
              }),
            }, locations);
          }
        })
        .catch(err => {
          _err('applyRouteByTrack::getHistory', err);
        })
        .finally(() => {
          this.trackBy.loading = false;
        });
    },
    // Добавление новой альтернативной трассы
    onAlternateAddClick() {
      this.dialogs.addAlternateTrack = true;
    },
    // При подтверждении добавления альтернативной трассы
    onApplyAddAlternate() {
      if (this.alternatives.newName !== '') {
        this.copyRouteLoading = true;

        RouteService.copyAlterRoute(
          this.alternatives.selected.baserouteid === null
            ? '0'
            : this.alternatives.selected.baserouteid,
          this.item.vcroutesId,
          this.alternatives.newName,
        )
          .then(data => {
            const newGuid = data?.result?.data?.[0]?.[0] || null;

            this.loadAlternates(newGuid ?? true);
          })
          .catch(err => {
            _err('cant create new alternate', err);

            jet.msg({
              text: 'Не удалось создать альтернативную трассу',
              color: 'warning',
            });
          })
          .finally(() => {
            this.copyRouteLoading = false;
          });

        this.dialogs.addAlternateTrack = false;
        this.alternatives.newName = '';
      }
    },
    // При отмене добавления альтернативной трассы
    onCancelAddAlternate() {
      this.dialogs.addAlternateTrack = false;
      this.alternatives.newName = '';
    },
    // Удаление альтернативной трассы
    onAlterRouteRemoveClick() {
      jet.confirm({
        title: 'Удаление трассы',
        msg: 'Вы действительно хотите УДАЛИТЬ альтернативную трассу по данному маршруту?',
      }).then(answer => {
        if (answer) {
          this.removeRouteLoading = true;

          RouteService.removeAlterRoute(this.alternatives.selected.id)
            .then(() => {
              this.loadAlternates();
            })
            .catch(err => {
              _err('removeAlterRoute', err);

              jet.msg({
                text: 'Не удалось удалить альтернативную трассу',
                color: 'warning',
              });
            })
            .finally(() => {
              this.removeRouteLoading = false;
            });
        }
      });
    },
    // Показ/Скрытие списка нулевых трасс
    showZeroTracksList() {
      this.zeroTracks.showList = !this.zeroTracks.showList;
      this.currentTab = this.zeroTracks.showList ? _tabs.zeroTrack : _tabs.main;

      if (this.zeroTracks.showList) {
        this.zeroTrackShow = true;
      }
    },
  },
  computed: {
    // Получение списка треков либо фильтрованных по имени
    trackByItems() {
      if (this.trackBy.filter !== '') {
        const regex = new RegExp(`${this.trackBy.filter}`, 'i');

        return this.trackBy.items.filter(it => regex.test(it.gov));
      }

      return this.trackBy.items;
    },
    // Дизаблинг кнопки "По треку ТС"
    trackByDisabledApplyButton() {
      return this.trackBy.selected.length === 0
        && this.trackBy.start === null && this.trackBy.start === ''
        && this.trackBy.end === null && this.trackBy.end === '';
    },
    // Проверка на disable для удаления альтернативной трассы
    disableRemoveAlternate() {
      return this.alternatives?.selected?.alternumb === 1;
    },
    // Список конечных остановок
    finalStops() {
      return this.busStops.filter(it => it.type === FINAL_STOP_TYPE_GUID);
    },
  },
};
</script>

<style lang="css" scoped>
.list_flex {
  height: 100px;

  overflow: auto;
}
</style>


<style lang="sass">
#routeVersions
  display: grid
  grid-template-columns: 0.5fr 1.4fr 0.5fr 1fr
  width: 100%
  max-width: 600px

#routeVersions > div
  padding: 5px
  max-height: 50px
  overflow: hidden
  text-overflow: ellipsis
  white-space: nowrap
</style>