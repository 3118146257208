<script>

    import RouteService from '@/services/RouteService';
    const $moment = require('moment');
    
    export default {
        name: 'ScheduleTimeDialog',
        
    props: ['show', 'stop', 'schedule'],
    data(){
        return {
            _stop: null,
            hInput: false,
            recalc: false,
            recalcText: 'Пересчитать время на последующих остановках выезда'
        };
    },
    methods: {
        getAsTime(val, day){
            var d = $moment().utcOffset(3);
            if (!!val){
                d.set({'year': 1, 'month': 0, 'date': day, 'second': 0, 'millisecond': 0});
                if ((val.length > 2) && (val.indexOf(':') < 0)){
                    val = val.substr(0, 2) + ':' + val.substr(2);
                }
                var a = val.split(':');
                switch (a.length){
                    case 1:
                        d.set('hour', a[0]);
                        break;
                    case 2:
                        d.set('hour', a[0]);
                        d.set('minute', a[1]);
                        break;
                }
            }
            return d;
        },
        tm(val, type){
            if (!!this.hInput){
                clearTimeout(this.hInput);
            }
            this.hInput = setTimeout(()=>{
                var day = 1;
                var dd = this._stop[type];
                if (!!dd) {
                    day = Number(dd.format("DD"));
                }
                const newTime = this.getAsTime(val, day);/*
                if (type === 'arr') {
                    var arr1 = this._stop['arr'] || this.stop.arr;
                    var dep1 = this._stop['dep'] || this.stop.dep;
                    if (!!dep1) {
                        const diff = newTime.diff(arr1);
                        this.dep = dep1.add(diff, 'millisecond').format("HH:mm");
                    }
                }*/
                this._stop[type] = newTime;
                this.hInput = false;
            }, 500);
        },
        save(){
            if (!!this._stop.dep && !!this._stop.arr) {
                this._stop.recalc = this.recalc;
                this.$emit('setTime', this._stop);
                this.$emit('close');
            } else {
                var msg = `Не заполнено поле:` + 
                    (!this._stop.arr ? ` Прибытие` : ``) + 
                    (!this._stop.dep ? ` Отправление` : ``);
                jet.msg({type: 'warning', text: msg});
                return;
            }
        }
    },
    watch: {
        show(val){
            this._stop = {};
            if (!!this.stop) {
                this._stop = {
                    arr: this.stop.arr,
                    dep: this.stop.dep,
                    recalc: false,
                }
                
                if (!!this.schedule && !!this.schedule.depId) {
                    this.recalcText = 'Пересчитать время на последующих остановках выезда';
                } else {
                    this.recalcText = 'Пересчитать время на последующих остановках графика';
                }
            }
        },
        stop(val){
            this._stop = {
                arr: val.arr,
                dep: val.dep,
                recalc: false,
            }
        },
    },
    computed: {
        arr: {
            get(){
                var d = null;
                if ((!!this._stop)&&(!!this._stop.arr)){
                    d = this._stop.arr;
                }
                if (!!this.stop){
                    d = this.stop.arr;
                }
                return (!!d) ? $moment(d).format('HH:mm') : '';
            },
            set(val){
                this.tm(val, 'arr');
            }
        },
        dep: {
            get(){
                var d = null;
                if ((!!this._stop)&&(!!this._stop.dep)){
                    d = this._stop.dep;
                }
                if (!!this.stop){
                    d = this.stop.dep;
                }
                return (!!d) ? $moment(d).format('HH:mm') : '';
            },
            set(val){
                this.tm(val, 'dep');
            }
        }
    },
    render(h){
        return h('v-dialog', {props: {
            width: "550px",
            value: this.show
        }}, [
            h('v-card', {class: {"sch-stop-times": true}}, [
                h('v-card-title', 'Время движения'),
                h('v-card-text', [
                    h('h3', (!!this.stop) ? this.stop.pt : ''),
                    h('v-row', [
                        h('v-col', {props: {col: 6}}, [
                            h('v-text-field', {
                                props: {label: 'прибытие', type: 'time', value: this.arr},
                                on: {input: (val)=>{this.arr = val;}}
                            })
                        ]),
                        h('v-col', {props: {col: 6}}, [
                            h('v-text-field', {
                                props: {label: 'отправление', type: 'time', value: this.dep},
                                on: {input: (val)=>{this.dep = val;}}
                            })
                        ])
                    ]),
                    h('v-checkbox', {
                        props: {label: this.recalcText, dense: true},
                        on: {change: (val)=>{this.recalc = val;}}
                    })
                ]),
                h('v-card-actions', [
                    h('v-spacer'),
                    h('v-btn', {props: {outlined:true, tile:true, color: 'gray', small: true},
                                on: {click: ()=>{ this.$emit('close');}
                                }
                            }, 'закрыть'),
                    h('v-btn', {props: {outlined:true, tile:true, color: 'primary', small: true},
                                on: {click: this.save}
                               }, 'сохранить')
                ])
            ])
        ]);
    }
    
    }
    
</script>

<style lang="scss">
    .v-dialog > .v-card > .v-card__text{
        padding-bottom: 0;
    }
    .v-input--selection-controls{
        margin-top: 0;
    }
</style>
