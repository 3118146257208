<template>
  <div class="jet-input jet-input-bool" v-show="vis">
      <v-checkbox 
          v-model="value" 
          :label="label"
          :disabled="dis"
          :rules="rules"
          :error="!valid"
          true-value="true"
          false-value="false"
        >
      </v-checkbox>
  </div>
</template>

<script>
import JetInputBase from '@/components/editors/JetInputBase';

export default {
  name: 'JetInputBoolean',
  extends: JetInputBase,
  data() {
    return {
        valid: true,
        value: this.$attrs.value
    };
  },
  watch: {
    value(newValue, oldValue) {
      this.$attrs.value = newValue;
      this.$emit('input', newValue);
      this.$emit('datachange', this.name);
      this.valid = true;
    }
  }
};
</script>