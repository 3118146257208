<template>
  <div class="jet-input jet-input-num" v-show="vis">
        <v-text-field
          type="text"
          :value="value"
          :disabled="dis"
          :rules="rules"
          :hidden="hidden"
          :label="label"
          :error="!!err"
          :error-messages="(!!err) ? err.message : null"
          @input="oninput($event)">
            <template v-slot:append-outer>
                <jet-input-btn />
            </template>
        </v-text-field>
  </div>
</template>

<script>
import JetInputBase from '@/components/editors/JetInputBase';
import JetInputBtn from '@/components/editors/JetInputBtn';

export default {
  name: 'JetInputFloat',
  extends: JetInputBase,
  data() {
    return {
      prevValue: this.$attrs.value,
      value: !!this.$attrs.value ? parseFloat(this.$attrs.value) : null,
      err: null
    };
  },
  components: {
      JetInputBtn
  },
  mounted(){
      this.$nextTick(()=>{
          $(this.$el).find('input').css({"text-align": "right"})
      });
  },
  methods: {
    oninput(f) {
        this.err = null;
        try {
            this.value = parseFloat(f);
            if ( isNaN(this.value) ){
                throw {message: 'введите числовое значение'};
            }
            this.$emit('input', this.value);
            this.$emit('datachange', this.name);
        } catch(e){
            console.log('ERR (float)', e);
            this.value = this.prevValue;
            this.err = e;
        }
    }
  }
};
</script>
<style lang="scss" scoped>
    .jet-input-num{
        max-width: 10rem;
    }
</style>