<template>
  <div style="height: 100%">
    <v-row style="height: inherit">
      <v-col cols="6" v-if="!isOnce" style="overflow: auto" class="fill-height pl-5">
        <v-row>
             <v-col>
                <JetSearchField
                  label="Введите имя перевозчика"
                  v-model="search"
                ></JetSearchField>
            </v-col>
            <v-col>
                <v-btn
                    color="primary"
                    @click="reloadSending"
                    :loading="loadingRequest"
                >
                    Обновить рассылку
                </v-btn>
            </v-col>
        </v-row>

        <div
          v-if="!!search && search.length > 0 && filteredCarrier.length === 0"
          align="center"
          class="grey--text pt-4"
        >
          Ничего не найдено
        </div>

        <!-- Список организаций -->
        <v-radio-group
          v-model="selectedCarrier"
          column
        >
          <v-list v-if="!loadingProfiles">
            <template v-for="(profile, key) in filteredCarrier">
              <v-divider v-if="key === 0"/>
              <v-radio
                :label="profile.title"
                :value="profile"
                :key="'l-' + profile.carrierId"
                class="mt-2"
              ></v-radio>
              <v-divider :key="'d-' + profile.carrierId" />
            </template>
          </v-list>
          <JetSpinner v-else class="mt-4"/>
        </v-radio-group>
      </v-col>
      <v-col cols="6">
        <div v-if="isOnce" class="title px-4">
          {{ selectedCarrier.title }}
        </div>
        <Settings
          v-if="selectedCarrier != null"
          :carrier="selectedCarrier"
          :key="selectedCarrier.carrierId"
        ></Settings>
        <div v-else class="grey--text" align="center">
          Ничего не выбрано
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import HideGlobalToolbarMixin from '@/components/dev/mixin/HideGlobalToolbarMixin';
import JetSpinner from '@/components/JetSpinner';
import ProfilesService from '@/components/dev/service/ProfilesService';
import Settings from '@/components/dev/modules/auto_building_10_44/Settings';
import JetSearchField from '@/components/JetSearchField';

export default {
  name: 'Autobuilding10_44',
  mixins: [
    HideGlobalToolbarMixin,
  ],
  components: {
    JetSearchField,
    Settings,
    JetSpinner,
  },
  data: () => ({
    dialogSettings: false,
    loadingProfiles: false,
    profiles: [],
    selectedCarrier: null,
    isOnce: false,
    search: '',
    loadingRequest: false,
  }),
  computed: {
    filteredCarrier() {
      return this.profiles.filter(cr => {
        const title = cr?.title?.toLowerCase() || '';
        const sch = this?.search?.toLowerCase() || '';
        return title.indexOf(sch) !== -1;
      });
    },
  },
  created() {
    this.getProfiles();
  },
  methods: {
    async getProfiles() {
      this.loadingProfiles = true;
      this.profiles = await ProfilesService.getProfiles();
      if (this.profiles.length === 1) {
        this.dialogSettings = true;
        this.selectedCarrier = this.profiles[0];
        this.isOnce = true;
      }
      this.loadingProfiles = false;
    },
    reloadSending() {
        this.loadingRequest = true;
        const result = ProfilesService.reloadSending();
        this.loadingRequest = false;
        this.getProfiles();
    },
  },
};
</script>
