import {SPEED_LIMIT} from '@/const/vehicle_consts';

export default {
  // Флаг что анимация остановлена
  paused: false,
  // Полное время пути
  fullTime: null,
  // Текущий индекс
  ind: 0,
  defStart: 0,
  // Текущее время
  curTime: 0,
  points_tile: [],
  defTime: null,
  speedCoeff: 10,
  fisicalTime: 0,
  callback: (lon, lat) => {
  },
  reqAnimation: null,
  increment: 0,
  pastTime: 0,
  svg: null,
  points: [],

  svgFinishedCallback: (svgEl) => {
  },
  tickCallback: (time, endTime) => {
  },

  /**
   * Подготовка точек для внутренней работы
   *
   * @param {Array<Object>} res_points
   * @return {Array<Object>}
   */
  preparePoints(res_points) {
    const startTime = res_points[0].time;

    // Подготовка данных для анимации
    return res_points.map((point, index) => {
      let time = 0;
      let start;
      let nextSpeed = 0;

      if (index !== res_points.length - 1) {
        time = res_points[index + 1].time - point.time;
        start = index === 0 ? 0 : point.time - startTime;
        nextSpeed = res_points[index + 1].speed - point.speed;
      } else {
        start = point.time - startTime;
      }

      return {
        lat: point.lat,
        lon: point.lon,
        start: start,
        index: index,
        time: time,
        nextSpeed: nextSpeed,
        speed: point.speed,
      };
    });
  },

  /**
   * Когда меняется тайм лайн
   * @param {CallableFunction} callback
   */
  onTimeLineCallback(callback) {
    this.tickCallback = callback;
  },

  /**
   * Когда SVG сформирована
   *
   * @param {CallableFunction} callback
   */
  onSVGFinished(callback) {
    this.svgFinishedCallback = callback;
  },

  init(points, callback) {
    this.points = points;
    this.fullTime = points[points.length - 1].start;
    this.ind = 0;
    this.curTime = this.defStart;
    this.points_tile = points;
    this.defTime = (new Date()).getTime();
    this.speedCoeff = 10;
    this.callback = callback;

    let maxSpeed = 0;

    points.forEach(point => {
      if (maxSpeed < point.speed) {
        maxSpeed = point.speed;
      }
    });

    let maxTime = Math.min(600, parseInt((this.fullTime - points[0].start) / 6000, 10));
    maxTime = Math.max(1, maxTime);
    const increment = parseInt((this.fullTime - points[0].start) / maxTime, 10);
    let curIncrement = 0;
    let newIndex;

    let str = `
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 ${maxTime * 3} 50"
        style="width: 100%;">
    `;

    for (let i = 0; i < maxTime; i++) {
      let near = points.filter(p => p.start > curIncrement);

      curIncrement += increment;

      if (near.length > 0) {
        newIndex = near[0].index - 1;

        const point = points[newIndex];
        const height = (point.speed) / (maxSpeed) + 0.01;
        const isMaxSpeed = point.speed >= SPEED_LIMIT;
        const color = isMaxSpeed ? 'red' : 'black';
        const y = 1 - height;

        str += `
          <g transform="translate(${i * 3}, 0)" fill="${color}">
            <rect width="3" y="${y * 50}" height="${height * 50}"></rect>
          </g>
        `;
      }
    }

    str += '</svg>';

    const d = document.createElement('div');
    d.innerHTML = str;

    this.svg = d;

    if (this.svgFinishedCallback) {
      this.svgFinishedCallback(this.svg);
    }
  },

  animate() {
    if (this.paused) {
      return;
    }

    this.increment = (new Date()).getTime() - this.pastTime;
    this.pastTime = (new Date()).getTime();

    this.curTime = this.curTime + this.increment * this.speedCoeff;

    const near = this.points.filter(p => p.start > this.curTime);
    let newIndex;

    if (near.length > 0) {
      newIndex = near[0].index - 1;

      this.ind = newIndex;

      // вычисляем на какую длину относительно начала отрезка нужно сдвинуть точку
      const coeff = (this.curTime - this.points[this.ind].start) / this.points[this.ind].time;

      const it = this.points[this.ind];

      // вычисляем координаты точки со сдвигом
      const x = it.lon + (near[0].lon - it.lon) * coeff;
      const y = it.lat + (near[0].lat - it.lat) * coeff;

      this.callback(x, y, this.curTime, this.fullTime);

      if (this.tickCallback) {
        this.tickCallback(this.curTime, this.fullTime);
      }

      // выходим из анимации, если она завершена
      if (this.curTime >= this.fullTime) {
        return;
      }

      // запускаем новый кадр анимации
      this.reqAnimation = requestAnimationFrame(() => this.animate());
    }
  },

  setCurrentTime(percent) {
    this.pause();

    percent = percent > 1 ? percent / 100 : percent;

    this.points_tile = this.points;
    this.defTime = (new Date()).getTime();
    this.defStart = parseInt(this.fullTime * percent, 10);
    this.curTime = this.defStart;

    this.play();
  },

  setSpeedCoeff(coef) {
    this.speedCoeff = coef;
  },

  stop() {
    this.pause();
    this.defStart = 0;
    this.init(this.points, this.callback);
    this.callback(this.points[0].lon, this.points[0].lat, 0);
  },

  play() {
    this.defTime = (new Date()).getTime();
    this.paused = false;
    this.pastTime = (new Date()).getTime();

    this.animate();
  },

  pause() {
    this.paused = true;

    cancelAnimationFrame(this.reqAnimation);
  },
};
