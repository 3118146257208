const state = {};

const mutations = {
    setSnackbar(state, inf){
        //console.log('depricated');
        jet.msg(inf);
    }
};

export default {
  namespaced: true,
  state,
  mutations
};
