<template>
  <v-tabs-items v-model="tripTab">

    <!-- Маршруты -->
    <v-tab-item>
      <TabRoute
        :key="keyComponentRoute"
        :fullDate="fullDate"
        @selectRoute="goTrips"
      ></TabRoute>
    </v-tab-item>

    <!-- Постановки -->
    <v-tab-item>
      <RouteTrips
        :fullDate="fullDate"
        :selectedRoute="selectedRoute"
        @back="goRoute"
      ></RouteTrips>
    </v-tab-item>
  </v-tabs-items>
</template>

<script>
import TabRoute from './mainTabPlanning/TabRoute';
import RouteTrips from './mainTabPlanning/TabTrips';

export default {
  name: 'MainTabTrips',
  components: {
    TabRoute,
    RouteTrips,
  },
  props: {
    fullDate: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    // Вкладки Маршруты и Постановки маршрутов
    tripTab: 0,
    // Выбранный маршрут
    selectedRoute: {
      routeName: '',
      routePlan: '',
      completed: false,
    },
    // Ключ для компонента, нужен для обновления
    keyComponentRoute: true,
  }),
  watch: {
    tripTab(newValue) {
      this.$emit('hideImport', !!newValue);
    },
  },
  methods: {
    goTrips(route) {
      this.selectedRoute = route;
      this.tripTab = 1;
    },
    goRoute(changed) {
      this.selectedRoute = {
        routeName: '',
        routePlan: '',
        completed: false,
      };
      this.tripTab = 0;
      if (changed) {
        this.keyComponentRoute = !this.keyComponentRoute;
      }
    },
  },
};
</script>
