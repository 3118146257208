<template>
  <div>
    <ShowRoute
      v-if="vcData !== null"
      :vc-route-item="vcData"
      @close="onShowRouteClose"
    ></ShowRoute>
  </div>
</template>

<script>
// Миксины
import BaseAction from '@/components/actions/BaseAction';

// Компоненты - Действия
import ShowRoute from '@/components/actions/RoutesSettings/ShowRoute/ShowRoute';

// Утилиты
import {prepareSinRequest, prepareSinResponse} from '@/utils/http';

export default {
  name: 'OpenRouteEditor',
  mixins: [
    BaseAction,
  ],
  props: {
    // Коллекция
    collection: {
      type: Object,
      required: false,
      default: () => ({
        master: {
          selected: {},
        },
      }),
  },
    selectedId: {
      type: Object,
      required: false,
    },
  },
  watch: {
    selectedId(item)  {
      this.routeID = item.id;
      this.loadData();
    }
  },
  components: {
    ShowRoute,
  },
  data: () => ({
    vcData: null,
    routeID: null
  }),
  methods: {
    // Загрузка данных
    async loadData() {
      let query = 'sin2:/v:acd2f542-598c-4540-b46c-03375098d467';

      query += `?filter=eq(field(".id"), param("${this.routeID}", "id"))`;

      let result = [];

      try {
        result = prepareSinResponse(
          await prepareSinRequest(
            'core-read',
            query
          )
        );
      } catch (e) {
        console.error('OpenRouteEditor::loadData', e);
      }

      if (result !== null && Array.isArray(result) && result.length > 0) {
        const item = result[0];

        // Формируем данные для компонента ShowRoute
        this.vcData = {
          vcroutesVerstatus: item.verstatus,
          vcroutesVerstatusName: item.verstatusName,
          vcroutesId: item.id,
          vcroutesRoutecode: item.routecode,
          vcroutesRoutename: item.routename,
          vcroutesVersion: item.version,
          vcroutesBaserouteid: item.baserouteid,
          vcroutesVerid: item.verid,
        };
      } else {
        this.showMessage('warning', 'Не удалось загрузить информацию по маршруту');
      }
    },
    // При закрытии диалога ShowRoute
    onShowRouteClose() {
      this.vcData = null;
    },
  },
}
</script>
