import animate from '@/utils/animate';

export default {
  /**
   * Инициализация
   *
   * @param {Array<Object>} points
   * @param {Function} lngLatCallback
   * @param {Function} svgDoneCallback
   * @param {Function} timelineCallback
   */
  init(points, lngLatCallback, svgDoneCallback, timelineCallback) {
    animate.onSVGFinished(svgDoneCallback);

    animate.onTimeLineCallback(timelineCallback);

    animate.init(
      animate.preparePoints(points),
      lngLatCallback
    );
  },

  /**
   * Получение объекта анимации
   *
   * @return {Object}
   */
  getAnimate() {
    return animate;
  },

  /**
   * Запуск анимации на проигрывание
   */
  playAnimation() {
    animate.play();
  },

  /**
   * Пауза анимации
   */
  pauseAnimation() {
    animate.pause();
  },

  /**
   * Остановка воспроизведения
   */
  stopAnimation() {
    animate.stop();
  },

  /**
   * Установка времени на шкале
   *
   * @param {Number} percent
   */
  setCurrentTime(percent) {
    animate.setCurrentTime(percent);
  },

  /**
   * Установка режима скорости анимации
   *
   * @param {Number} coef
   */
  setSpeedCoeff(coef) {
    animate.setSpeedCoeff(coef);
  },
};
