export default {
  props: {
    // Объект текущей коллекции
    collection: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    // Текущая выбранная запись
    selected: null,
  }),
  mounted() {
    this.selected = this.collection.master.selected || null;

    if (this.baseActionCheck_() && !this.selected) {
      jet.msg({
        text: 'Необходимо выбрать запись!',
        color: 'warning'
      });
    }
  },
  methods: {
    // Метод для уменьшения кол-ва кода
    showMessage(color, text) {
      jet.msg({
        color,
        text
      });
    },
    // Переопределяя данный метод, можно влиять на поведение компонента
    // Если вернуть false - то компонент не будет выводить warning если не выбрана запись
    baseActionCheck_() {
      return true;
    },
  },
}
