<template>
  <v-list-item
    dense
    :disabled="loading"
    :inactive="loading"
    @mouseenter="mouseEnter"
    @mouseleave="mouseLeave"
    @click="$emit('on-bus-click', item)"
  >
    <v-list-item-title class="pl-2 pr-4" style="position:relative;">
      <v-badge
        left dot
        offset-y="-1"
        class="mr-1"
        :color="item.isFinal ? colors.finalStop : colors.defStop"
      ></v-badge>

      <v-badge
        content="new"
        v-if="(item.isAdded || false)"
        :value="(item.isAdded || false)"
      >
        {{ shortNewLocationName }}
      </v-badge>

      <span v-else>
        {{ item.locationName || item.locname }}
      </span>
    </v-list-item-title>

    <!-- Действие -->
    <v-list-item-action v-if="showMenu" class="my-0">
      <!-- Контекстное меню -->
      <v-menu offset-x transition="slide-x-transition">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            fab x-small depressed
            v-on="on"
            v-bind="attrs"
            :loading="loading"
          >
            <v-icon>
              mdi-dots-vertical
            </v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item @click="changeStopType">
            <v-list-item-content>
              <v-list-item-title>
                Сделать {{ stopType || 'промежуточной' }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <!-- Редактировать -->
          <v-list-item @click="$emit('change-to', {name: 'create-bus-stop', item: item})">
            <v-list-item-content>
              <v-list-item-title>
                Редактировать
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <!-- Изменить радиус -->
          <v-list-item @click="$emit('change-to', {name: 'edit-radius', item: item})">
            <v-list-item-content>
              <v-list-item-title>
                Изменить радиус
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <!-- Удалить -->
          <v-list-item @click="$emit('remove', item)">
            <v-list-item-content>
              <v-list-item-title>
                Удалить
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-list-item-action>
  </v-list-item>
</template>

<script>
import MapLayersService from '@/services/MapLayersService';
import ShowRouteMapService from '@/components/services/ShowRouteMapService';

export default {
  name: 'BusStopItem',
  props: {
    // Остановка
    item: {
      type: Object,
      required: true,
    },
    // Показывать ли меню
    showMenu: {
      type: Boolean,
      required: false,
      default: true,
    },
    // Это первая точка
    isFirst: {
      type: Boolean,
      required: true,
    },
    // Это последняя точка
    isLast: {
      type: Boolean,
      required: true,
    },
    // Признак что происходит бекенд операция
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: () => ({
    // Цвета для остановок
    colors: ShowRouteMapService.colors(),
  }),
  methods: {
    // При наведении мышки
    mouseEnter() {
      this.$emit('onOver', _copy(this.item));
    },
    // При уводе мышки
    mouseLeave() {
      this.$emit('onOut', _copy(this.item));
    },
    // Смена типа остановки
    changeStopType() {
      const result = _copy(this.item);

      result.type = result.isFinal ? MapLayersService.DEF_STOP_GUID : MapLayersService.FINAL_STOP_GUID;
      result.isFinal = !result.isFinal;

      this.$emit('bus-stop-change', result);
    },
  },
  computed: {
    stopType() {
      if (!this.item.isFinal) {
        return 'конечной';
      }

      if (this.item.isFinal) {
        return 'промежуточной';
      }

      return null;
    },
    shortNewLocationName() {
      const locname = this.item.locationName || this.item.locname;
      return locname.length > 28 ? locname.substring(0, 28) + "..." : locname;
    }
  },
};
</script>

<style scoped>

</style>
