<script>
import Vue from 'vue';
import vuetify from '@/plugins/vuetify';
import JetSvg from '@/components/JetSvg';
import JetInputId from '@/components/editors/JetInputId';
import { watch } from '@/utils/dom';
import Model from '@/core/Model';
import NewVersionDialog from './components/sched-ext/NewVersionDialog';
import ApproveVersionDialog from './components/sched-ext/ApproveVersionDialog';
import CloseVersionDialog from './components/sched-ext/CloseVersionDialog';
import CopyVersionDialog from './components/sched-ext/CopyVersionDialog';
import ScheduleTimeDialog from './components/sched-ext/ScheduleTimeDialog';
import SeasonWkmodeDialog from './components/sched-ext/SeasonWkmodeDialog';
import ScheduleTransferDialog from './components/sched-ext/ScheduleTransferDialog';
import AddServiceStopDialog from './components/sched-ext/AddServiceStopDialog';
import DepartureCodeDialog from './components/sched-ext/DepartureCodeDialog';
import AddDepDialog from './components/sched-ext/AddDepDialog';
import ReleaseSchedulesService from '@/services/ReleaseSchedulesService';
import MapLayersService from '@/services/MapLayersService';
import RouteSelVersion from '@/components/dev/components/sched-ext/RouteSelVersion';

import { NULL_ID } from "@/utils";

const SCH_MODE = {
    'none': -1,
    'default': 0,
    'loading': 9,
    'error': 10
};

const $moment = require('moment');
const tz_shift_minutes = $moment(0).hours() * 60;
const now = $moment();


const _to_time = (dt)=>{
    if (!!dt) {
        const _t = $moment(dt).utcOffset(3);   /*    //reset timezone
        _t.set('date', now.get('date'));
        _t.set('month', now.get('month'));
        _t.set('year', now.get('year'));*/
        return _t;
    } else {
        return null;
    }
};

const SchRoutes = {
    name: 'SchRoutes',
    data(){
        return { comp: null };
    },
    methods: {
        async getComp(){
            const self = this;
            return new Promise((resolve, reject)=>{
                var n = 0;
                const _wait = function(){
                    if ( n > 100 ){
                        reject();
                    } else if (!!self.comp){
                        resolve(self.comp);
                    } else {
                        n++;
                        setTimeout(_wait, 100);
                    }
                };
                _wait();
            });
        },  /* getComp */
    },
    mounted(){
        const self = this;
        watch('.v-window-item--active .v-toolbar__title.jet-col-title').then((node)=>{
            $('.v-window-item--active .jet-col-search').css({display: "none"});
            self.comp = new Vue({
                name: 'MtRoutesChooser',
                el: node.get(0),
                vuetify,
                render(h){
                    return h('div', {
                        class: {'sch-routs-place': true}
                    }, [
                            h('div', {class: {'sch-routs-place__legend': true}}, [
                                h('v-icon', {props: {color: 'gray', small: true}}, 'mdi-coffee'),
                                ' - стоянка, ',
                                h('v-icon', {props: {color: 'gray', small: true}}, 'mdi-human-greeting'),
                                ' - остановка по требованию, ',
                                h('v-icon', {props: {color: 'gray', small: true}}, 'mdi-clock-outline'),
                                ' - следующие сутки',
                        ]),
                            h('a', {class: {'sch-routs-place__choose': true}}, 'Выбрать маршрут...'),
                            h('v-spacer'),
                        h('v-btn', {
                            props: {outlined: true, color: 'primary', small: true, elevation: "0"},
                            on: {click: ()=>{
                                    self.$emit('prepareSaveTotal');
                                }}
                        }, 'сохранить')
                    ]);
                }   //render
            });
        });
    },
    render(h){
        return h('i');
    }
};  //SchRoutes


export default {
    name: 'MtSchedules',
    data(){
        return {
            mode: SCH_MODE.none,
            route: null,            //selected route
            stop: null,
            schVerId: null,
            scheduleVersion: null,
            deps: null,
            scheds: null,
            activeDep: null,
            serviceStopsTypes: null,
            showServStop: false,
            showVer: false,
            showTime: false,
            showSeas: false,
            showDepCode: false,
            activeSch: null,
            fixSche: false,
            closeSche: false,
            copySche: false,
            newStopChecked: false,
            forwardStops: null,
            backwardStops: null,
            modeTransferSchedule: false,
            showScheTransfer: false,
            showAddDep: false,
            confirm: {
                yes: true,
                no: true,
                cancel: true,
                show: false,
                message: null,
                method: null,
                title: null
            }
        };
    },
    components: {
        SchRoutes,
        ApproveVersionDialog,
        CloseVersionDialog,
        CopyVersionDialog,
        AddServiceStopDialog,
        DepartureCodeDialog,
        RouteSelVersion,
        JetSvg
    },
    created(){
        const model = new Model({
                                id: '3100ec29-e929-4a9c-83cc-15b87fbe2800',
                                columns: [],
                                projects: [{
                                        projectType: "typeJsClientHtml",
                                        title: "Новая версия расписания...",
                                        name: "verAdd",
                                        call: ()=>{this.showVer = (new Date()).getTime();}

                                }, {
                                        projectType: "typeJsClientHtml",
                                        title: "Утвердить версию расписания",
                                        name: "verFix",
                                        call: ()=>{this.fixSche =  (new Date()).getTime();}

                                }, {
                                        projectType: "typeJsClientHtml",
                                        title: "Прекратить действие версии",
                                        name: "verEnd",
                                        call: ()=>{
                                            if (this.scheduleVersion.vcreleaseschedulesVerstatusName === "Утвержден") {
                                                this.closeSche =  (new Date()).getTime();
                                            } else {
                                                jet.msg({type:'warning', text:`Действие доступно только для статуса «Утвержден».`});
                                            }
                                        }
                                }, {
                                        projectType: "typeJsClientHtml",
                                        title: "Копировать версию",
                                        name: "verCpy",
                                        call: ()=>{this.copySche =  (new Date()).getTime();}

                                }, {
                                        projectType: "typeJsClientHtml",
                                        title: "Удалить версию",
                                        name: "verDel",
                                        call: () => {
                                            this.confirm = {
                                                yes: true,
                                                no: false,
                                                cancel: true,
                                                show: true,
                                                message: 'Вы действительно хотите УДАЛИТЬ версию расписания?',
                                                method: 'deleteVersion',
                                                title: 'Удалить версию'
                                            }
                                        }

                                }, {
                                        projectType: "typeJsClientHtml",
                                        title: "Сформировать новый выезд",
                                        name: "depAdd",
                                        call: ()=>{this.showAddDep =  (new Date()).getTime();}
                                }, {
                                        projectType: "typeJsClientHtml",
                                        title: "Добавить график",
                                        name: "schAdd",
                                        call: this.addSchedulePair
                                }]
                        });
        this.$emit('on-model', model);
    },  //created
    mounted(){
        const self = this;
        watch('.v-window-item--active .v-toolbar__content .sch-routs-place').then( node => {
            const a = $(node).find(".sch-routs-place__choose");
            a.on('click', self.selRoute);
        });
    },  //mounted
    computed: {
        activeScheds(){
            if (!(!!this.scheds)){
                return [];
            }
            if (this.has("deps")){
                if (!!this.activeDep){
                    return this.scheds.filter(sch => sch.depId === this.activeDep.id).sort((s1, s2)=>{
                        return (s1.ordernumber < s2.ordernumber) ? -1 : 1;
                    });
                }
                return [];
            } else {
                return this.scheds.sort((s1, s2)=>{
                    return (s1.ordernumber < s2.ordernumber) ? -1 : 1;
                });
            }
        }   //activeScheds
    },
    methods: {
        has(q){
            switch(q){
                case "deps":
                    return this.deps?.length > 0;
                default:
                    return false;
            }
        },  //has
        set(name, val){
            switch(name){
                case "route":
                    this.setRouteId(val);
            }
        },
        selRoute(e){
            e.preventDefault();
            this.$refs["sel-version"].open(this.route);
            console.log(e);
        },
        setRouteId(id){
            const d = this.$refs["sel-version"];
            d.open({id, need: true});
        },
        async refresh(){
            if (this.mode === SCH_MODE.loading){
                return;
            }
            if (!this.schVerId){
                return;
            }
            
            this.newStopChecked = false;
            this.getRoutePoints();
            
            this.schInf = null;
            this.activeDep = null;
            this.mode = SCH_MODE.loading;
            const m = $moment;

            const deps = [],
                scheds = [];

            const exist = function(id){
                return (deps.filter((dep)=>{return (dep.id === id);}).length > 0);
            };
            
            ReleaseSchedulesService.getServiceStopsTypes().then(result => {
                this.serviceStopsTypes = result;
            });

            try {
                const opts = {
                    type: 'core-read',
                    query: 'sin2:/v:11c7dbd5-d5fb-4cdf-964e-80cacd0c460a?filter=eq(field(".versionId"),param("'
                            + ($utils.isEmpty(this.schVerId) ? NULL_ID : this.schVerId) + '","id"))&sort=ordernumber'
                };
                var _r = await $http.post(opts);
                if (_r.error){
                    throw _r.error;
                }
                const ci = _r.result.columnIndexes;
                _r.result.data.map((item, n)=>{
                    var s = {
                        id: item[ci["vcschedules.id"]],
                        depId: item[ci["vcschedules.depid"]],
                        start: _to_time(item[ci["vcschedules.starttm"]]),
                        end:  _to_time(item[ci["vcschedules.endtm"]]),
                        route: item[ci["vcschedules.route"]],
                        startdt: _to_time(item[ci["vcschedules.startdt"]]),
                        linkcode: item[ci["vcschedules.linkcode"]],
                        routeversionid: item[ci["vcschedules.routeversionid"]],
                        versionid: item[ci["vcschedules.versionid"]],
                        ordernumber: item[ci["vcschedules.ordernumber"]],
                        tripcode: item[ci["vcschedules.tripcode"]],
                        season: {
                            id: item[ci["vcschedules.season"]], 
                            name: item[ci["vcschedules.season.name"]]
                        },
                        wkmode: {
                            name: item[ci["vcschedules.wkmode.modname"]],
                            wkmodeid: item[ci["vcschedules.wkmode"]], 
                        },
                        wkmodeonschedule1: null,
                        wkmodeonschedule2: null,
                        stops: false,
                        tt: 0,
                        //calc when stop's loaded
                        need15: false,
                        need45: false,
                        has15: false,
                        has45: false
                    };
                    
                    ReleaseSchedulesService.getWkmodesOnSchedule(s.id)
                        .then(result => {
                            result.map((wk, n)=>{
                                if (n === 0){
                                    s.wkmodeonschedule1 = {
                                        id: wk.id,
                                        name: `${wk.wkmodeName} (${wk.carrierName})`,
                                        wkmodeid: wk.wkmode, 
                                        carrier: wk.carrier,
                                        tenantid: wk.tenantid
                                    };
                                } else {
                                    s.wkmodeonschedule2 = {
                                        id: wk.id,
                                        name: `${wk.wkmodeName} (${wk.carrierName})`,
                                        wkmodeid: wk.wkmode, 
                                        carrier: wk.carrier,
                                        tenantid: wk.tenantid
                                    };
                                }
                            });
                        });
                    s.tt = m(s.end).diff(s.start, 'minutes');
                    s.icode = Number(s.tripcode.replace(/\D/g, ''));
                    scheds.push(s);
                    
                    //Выезды
                    if ( !!s.depId && !exist(s.depId) ){
                        var wkmode = !!s.wkmode.wkmodeid ? s.wkmode : s.wkmodeonschedule1;
                        var d = {
                            id: s.depId,
                            name: item[ci["vcschedules.depid.name"]],
                            season: !!s.season ? s.season.name : null,
                            wkmode: !!wkmode ? wkmode.name : null,
                            start: s.start,
                            end:  s.end,
                            route: s.route,
                            verId: s.versionid
                        };
                        d.iname = Number(d.name.replace(/\D/g, ''));
                        deps.push(d);
                    }
                    //Выезд для рейсов без выезда
                    if ( !s.depId && !exist(null) ){
                        var d = {
                            id: null,
                            name: 'Рейсы без выезда',
                        };
                        d.iname = Number(d.name.replace(/\D/g, ''));
                        deps.push(d);
                    }
                });
                
                deps.map((dep)=>{
                    dep.n = 0;
                    dep.tt = 0;
                    scheds.filter((sch)=>{return (sch.depId === dep.id);}).map((sch)=>{
                        dep.n++;
                        if (sch.start < dep.start){
                            dep.start = sch.start;
                        }
                        if (sch.end > dep.end){
                            dep.end = sch.end;
                        }
                    });
                    dep.tt = m(dep.end).diff(dep.start, 'minutes');
                });

                this.deps = deps.sort((d1, d2)=>{
                    return (d1.iname < d2.iname) 
                                ? -1
                                : (d1.iname > d2.iname) ? 1 
                                    : 0;
                });
                this.scheds = scheds;
                this.scheds.map((sch)=>{
                    if (!sch.stops){
                        this.stops(sch);
                    }
                });
                this.mode = SCH_MODE.default;
                console.log('SHED`s:', scheds);
            } catch(e){
                console.log('ERR (load)', e);
                jet.msg({type:'warning', text:'Не удается получить список выездов/рейсов по выбранному маршруту'});
                this.mode = SCH_MODE.error;
                //console.log(e);
            }
        },
        
        simpleRefresh(){
            this.scheds = this.scheds;
            this.deps = this.deps;
            this.activeDep = this.scheds;
        },
        async addSchedulePair(){
            try{
                const opts = {
                    type: 'core-read',
                    query: `sin2:/v:dba37e24-6a78-40c1-8615-4ab0d5e70535?filter=and(eq(field(".route"),param("${this.scheduleVersion.sId}", "id")),not(isnull(field(".location"))))&sort=vcroutepoints.pointnumber`
                };
                var _r = await $http.post(opts);
                if (_r.error){
                    throw _r.error;
                }
                const m = new Model(_r.result.model);
                var routePoints = m.sin2obj(_r.result.data, true);
                
                var endPoints = routePoints.filter((rp) => {return rp.vcroutepointsType === "bd78259f-7b66-ad44-e040-007f02002b0e"});
                var isTheeStops = endPoints.length === 3;
                
                var tripcode = this.nextTripCode(this.scheds.find(it => !!it.id));
                var ordernumber = this.nextOrderNumber();
                var linkcode = this.nextLinkCode();
                var s = null;
                
                if (!!isTheeStops) {
                    this.scheds.push(this.addSchedule(routePoints, linkcode, ordernumber, tripcode, endPoints[0].vcroutepointsPointnumber, endPoints[1].vcroutepointsPointnumber));
                    ordernumber++;
                    tripcode = this.tryToIncrementString(tripcode);
                    this.scheds.push(this.addSchedule(routePoints, linkcode, ordernumber, tripcode, endPoints[1].vcroutepointsPointnumber, endPoints[2].vcroutepointsPointnumber));
                } else {
                    this.scheds.push(this.addSchedule(routePoints, linkcode, ordernumber, tripcode, endPoints[0].vcroutepointsPointnumber, endPoints[1].vcroutepointsPointnumber));
                    ordernumber++;
                    tripcode = this.tryToIncrementString(tripcode);
                    this.scheds.push(this.addSchedule(routePoints, linkcode, ordernumber, tripcode, endPoints[2].vcroutepointsPointnumber, endPoints[3].vcroutepointsPointnumber));
                }
                
                this.createBufferDeparture();

                this.deps.filter((_dep) => {
                    return !_dep.id
                })
                .map((_dep) => {
                    _dep.n = _dep.n + 2;
                    this.activeDep = _dep;
                });
            } catch(e) {
                console.log(e);
            }
        },
        addSchedule(routePoints, linkcode, ordernumber, tripcode, pnStart, pnEnd){
            var s = {
                id: $utils.uuidv4(),
                depId: null,
                start: null,
                end: null,
                route: this.scheduleVersion.sId,
                startdt: now,
                linkcode: linkcode,
                routeversionid: this.scheduleVersion.sId,
                versionid: this.schVerId,
                ordernumber: ordernumber,
                tripcode: tripcode,
                season: {
                    id: null, 
                    name: null
                },
                wkmode: {
                    name: null,
                    wkmodeid: null, 
                },
                wkmodeonschedule1: null,
                wkmodeonschedule2: null,
                stops: false,
                tt: 0,
                need15: false,
                need45: false,
                has15: false,
                has45: false
            };
            
            var stops = [];

            routePoints.filter((rp) => {return rp.vcroutepointsPointnumber >= pnStart && rp.vcroutepointsPointnumber <= pnEnd})
            .map((rp, n)=>{
                var stop = {
                    id: $utils.uuidv4(),
                    arr: null,
                    dep: null,
                    pt: rp.vcroutepointsLocationLocname,
                    rq: false,
                    miss: false,
                    stop: 0,
                    schedule: s.id,
                    rpid: rp.vcroutepointsId,
                    pn: rp.vcroutepointsPointnumber,
                    newDay: false,
                    service: [],
                    changed: true
                };

                stops.push(stop);
            });
            s.stops = stops;
            return s;
        },
        checkEmpyDepartures(){
            this.deps = this.deps.filter((dep) => {
                return this.scheds.filter((sch)=>{
                        return (dep.id == sch.depId);
                    }).length !== 0;
            })
        },
        createBufferDeparture(){
            if (!this.deps.find((_dep) => { return !_dep.id })) {
                var d = {
                    id: null,
                    name: 'Рейсы без выезда',
                    n: 0,
                };
                d.iname = Number(d.name.replace(/\D/g, ''));
                this.deps.push(d);
                this.deps.sort((d1, d2)=>{ 
                    return (d1.iname < d2.iname) 
                                ? -1
                                : (d1.iname > d2.iname) ? 1 
                                    : 0;
                });
            }
        },
        async stops(sch){
            if ((!!sch.stops)&&Array.isArray(sch.stops)){
                return;
            }
            if (sch.stops === SCH_MODE.loading){
                return;
            }
            sch.stops = SCH_MODE.loading;
            const m = $utils.moment;
            const prevs = this.scheds.filter((_sch)=>{
                return (sch.depId === _sch.depId) && (sch.start > _sch.end);
            }).sort((s1, s2)=>{
                return (s1.ordernumber < s2.ordernumber) ? -1 : 1;
            });
            const prev = (prevs.length > 0) ? prevs[prevs.length - 1] : null;

            try{
                const opts = {
                    type: 'core-read',
                    query: 'sin2:/v:bc9b51c1-601e-45a9-b485-2ef8392ec440?filter=eq(field(".schedule"),param("'
                            + sch.id + '","id"))&sort=vcschedulestops.routepointnum'
                };
                var _r = await $http.post(opts);
                if (_r.error){
                    throw _r.error;
                }
                const ci = _r.result.columnIndexes;
                var stops = [];
                var prevStop = null;
                _r.result.data.map((item, n)=>{
                    var s = {
                        id: item[ci["vcschedulestops.id"]],
                        arr: _to_time(item[ci["vcschedulestops.arrtime"]]),
                        dep: _to_time(item[ci["vcschedulestops.deptime"]]),
                        pt: item[ci["vcschedulestops.routepoint.location.locname"]],
                        rq: item[ci["vcschedulestops.isrequeststop"]],
                        miss: (!!item[ci["vcschedulestops.ismissedstop"]]),
                        stop: 0,
                        schedule: item[ci["vcschedulestops.schedule"]],
                        rpid: item[ci["vcschedulestops.routepoint"]],
                        pn: item[ci["vcschedulestops.routepointnum"]]
                    };
                    //stop time (minutes)
                    var at = null;
                    if (n===0){ //first stop by prev schedule
                        at = (!!prev) ? prev.end : s.dep;
                    } else {
                        at = s.dep;
                    }
                    s.service = [];
                    s.stop = (!!at) ? Math.abs(m(at).diff(s.arr, 'minutes')) : 0;
                    
                    s.newDay = this.checkNewDay(prevStop, s, prev, n);
                    prevStop = s;
                    stops.push(s);
                });
                sch.stops = stops;
                
                //TODO: moving when trip loading this.getServiceStops(sch.stops);
                if (this.scheduleVersion.vcreleaseschedulesVerstatusName === 'Проект'){
                    this.checkNewStop(sch);
                }
                this.chkRest(sch);
            }catch(e){
                console.log(e);
                sch.stops = false;
            }
        },   //stops
        async getServiceStops(stops) {
            stops.forEach(stop => {
                ReleaseSchedulesService.getServiceStops(stop.id).then(result => {
                    stop.service = result;
                });
            });
        },
        checkNewStop(sch){
            var isForward = sch.stops.filter((stop) => {
                return this.forwardStops.find((f) => f.id === stop.rpid)
            }).length > 1;
            
            
            if (!!isForward) {
                if (this.forwardStops.length > sch.stops.length) {
                    var newStops = this.forwardStops.filter((f) => {
                        return !sch.stops.find((stop) => f.id === stop.rpid)
                    })
                }
            } else {
                if (this.backwardStops.length > sch.stops.length) {
                    var newStops = this.backwardStops.filter((b) => {
                        return !sch.stops.find((stop) => b.id === stop.rpid)
                    })
                }
            }
            if (!!newStops) {
                if (!this.newStopChecked) {
                    jet.msg({type:'warning', text:`В маршруте добавлена остановка(и), которая будет добавлена в расписании! Необходимо проставить время`});
                }
                this.newStopChecked = true;
                
                newStops.map((stop)=>{
                    var s = {
                        id: $utils.uuidv4(),
                        arr: null,
                        dep: null,
                        pt: stop.locationName,
                        rq: false,
                        miss: false,
                        stop: 0,
                        schedule: sch.id,
                        rpid: stop.id,
                        pn: stop.pointNumber,
                        newDay: false,
                        service: [],
                        changed: true
                    };

                    sch.stops.push(s);
                })
                sch.stops.sort((s1, s2)=>{
                    return (s1.pn < s2.pn) ? -1 : 1;
                })
            }
        },
        getRoutePoints(){
            MapLayersService.getRoutePoint(this.scheduleVersion.sId)
            .then(data => {
                var points = data.filter((point) => !!point.location);
                var fin = points.filter((point) => !!point.isFinal);
                var isTheeStops = fin.length === 3;
                if (!!isTheeStops) {
                    this.forwardStops = points.filter((point) => {return (point.pointNumber >= fin[0].pointNumber && point.pointNumber <= fin[1].pointNumber)});
                    this.backwardStops = points.filter((point) => {return (point.pointNumber >= fin[1].pointNumber && point.pointNumber <= fin[2].pointNumber)});
                } else {
                    this.forwardStops = points.filter((point) => {return (point.pointNumber >= fin[0].pointNumber && point.pointNumber <= fin[1].pointNumber)});
                    this.backwardStops = points.filter((point) => {return (point.pointNumber >= fin[2].pointNumber && point.pointNumber <= fin[3].pointNumber)});
                }
            });
        },
        checkNewDay(stop1, stop2, prevSch, n){
            const m = $utils.moment;
            var newDay = false;
            if (!!stop2.arr && !!stop2.dep) {
                newDay = m(stop2.arr).format('DD') !== m(stop2.dep).format('DD');
            }
            if (!newDay) {
                if (n===0) {
                    if (!!prevSch) {
                        newDay = m(prevSch.end).format('DD') !== m(stop2.arr).format('DD');
                    } else {
                        return newDay;
                    }
                } else {
                    if (!!stop1 && !newDay && !!stop1.dep && !!stop2.arr) {
                        newDay = m(stop1.dep).format('DD') !== m(stop2.arr).format('DD');
                    }
                }
            }
            
            return newDay;
        },
        set_time(stop){
            //console.log(stop);
            const state = this.scheduleVersion.vcreleaseschedulesVerstatusName;
            if (state === "Проект") {
                this.stop = stop;
                this.showTime = (new Date()).getTime();
            } else {
                jet.msg({type:'warning', text:`Расписание со статусом "${state}" редактировать запрещено`});
            }
        },
        setRequestStop(item, mode){
            //0 - only current, 1 - all
            const val = !item.rq;
            if (mode == 0) {
                const schIdx = this.activeScheds.findIndex(sch => {
                    if (sch.id === item.schedule) {
                        return true;
                    }
                });
                if (schIdx !== -1) {
                    const stopIdx = this.activeScheds[schIdx].stops.findIndex(stop => {
                        if (stop.id === item.id) {
                            return true;
                        }
                    });
                    if (stopIdx !== -1 && this.activeScheds[schIdx].stops[stopIdx].rq !== val) {
                        this.activeScheds[schIdx].stops[stopIdx].rq = val;
                        this.activeScheds[schIdx].stops[stopIdx].changed = true;
                    }
                }
            } else {
                this.scheds.map((sch, n)=>{
                    if ((!!sch.stops) && (Array.isArray(sch.stops))) {
                        var changed = sch.stops.findIndex(stop => {
                            if (stop.rpid === item.rpid) {
                                return true;
                            }
                        });
                        if (changed !== -1 && sch.stops[changed].rq !== val) {
                            sch.stops[changed].rq = val;
                            sch.stops[changed].changed = true;
                        }
                    }
                });
            }
        },
        setMiss(stop){
            if (!stop.miss) {
                var sch = this.scheds.find(it => it.id === stop.schedule);
                const isLast = sch.stops.slice().reverse().find(it => !!it.arr && !!it.dep) === stop;
                stop.arr = null;
                stop.dep = null;
                if ((!!sch.stops) && (Array.isArray(sch.stops))) {
                    //Проставить переход через сутки
                    if (!!stop.newDay) {
                        var prev = sch.stops.slice().reverse().find(it => !!it.arr && !!it.dep && it.pn < stop.pn);
                        var next = sch.stops.find(it => !!it.dep && it.pn > stop.pn);
                        if (!!next && !!prev && !! prev.dep && !!next.arr) {
                            next.newDay = prev.dep.format("DD") !== next.arr.format("DD");
                        }
                    }
                    //Проставить другое начало/окончание рейса
                    this.recalcStartEndSchedule(sch);
                }
                this.recalcStartEndDeparture(this.activeDep);
                this.recalcRestTimeMissed(sch, stop, isLast);
                
                stop.newDay = false;
                stop.stop = 0;
                stop.changed = false;
            } else {
                stop.changed = true;
            }
            stop.miss = !stop.miss;
        },
        deleteVersion(){
            var params = [
              {id: 'id', type: 'id', value: this.scheduleVersion.vcreleaseschedulesId},
            ];
            ReleaseSchedulesService.deleteReleaseSchedule(params)
                .then(result => {
                    this.setRouteId(this.route.vcroutesId);
                });
        },
        saveTotal(){
            const stopsData = this.prepareScheduleStops();
            const scheduleStops = stopsData.stops;
            const serviceStops = stopsData.services;
            const wkmodesOnSchedule = this.prepareWkmodesOnSchedule();
            const schedules = this.prepareSchedules();
            const departures = this.prepareDepartures();
            ReleaseSchedulesService.updateSchedules(departures, this.scheduleVersion.vcreleaseschedulesId, schedules, scheduleStops, wkmodesOnSchedule, serviceStops)
                .then(result => {
                })
                .finally(() => {
                    this.refreshVers(this.scheduleVersion.vcreleaseschedulesId);
                });
        },
        prepareDepartures(){
            var arr = []
            this.deps.map((dep)=>{
                if (!!dep.id) {
                    var s = {
                        id: dep.id,
                        name: dep.name,
                        route: dep.route,
                        verid: dep.verId,
                    }
                    arr.push(s);
                }
            });
            return arr;
        },
        prepareScheduleStops() {
            var stopArr = [];
            var servArr = [];
            this.scheds.forEach((sch, n)=>{
                if ((!!sch.stops) && (Array.isArray(sch.stops))) {
                    sch.stops.forEach(stop => {
                        var s = {
                            id: stop.id, 
                            routepoint: stop.rpid, 
                            schedule: stop.schedule, 
                            arrtime: !!stop.arr ? $moment(stop.arr).format('YYYY.MM.DD HH:mm:ss BC') : null, 
                            deptime: !!stop.dep ? $moment(stop.dep).format('YYYY.MM.DD HH:mm:ss BC') : null, 
                            ismissedstop: stop.miss, 
                            isrequeststop: stop.rq
                        };
                        
                        stopArr.push(s);
                        
                        stop.service.map(service => {
                            servArr.push({
                                id: service.id,
                                schedulestopid: service.schedulestopid,
                                servicestopid: service.servicestopid
                            });
                        });
                    });
                }
            });
            return {stops: stopArr, services: servArr};
        },
        prepareSchedules() {
            var arr = [];
            this.scheds.map((sch, n)=>{
                var s = {
                    id: sch.id,
                    route: sch.route,
                    tripcode: sch.tripcode,
                    startdt: !!sch.startdt ? $moment(sch.startdt).format('YYYY.MM.DD HH:mm:ss') : null,
                    depid: sch.depId,
                    starttm: !!sch.start ? $moment(sch.start).format('YYYY.MM.DD HH:mm:ss BC') : null,
                    endtm: !!sch.end ? $moment(sch.end).format('YYYY.MM.DD HH:mm:ss BC') : null,
                    season: sch.season.id,
                    wkmode: sch.wkmode.wkmodeid,
                    linkcode: sch.linkcode,
                    routeversionid: sch.routeversionid,
                    versionid: sch.versionid,
                    ordernumber: sch.ordernumber,
                }
                arr.push(s);
            });
            return arr;
        },
        prepareWkmodesOnSchedule() {
            var arr = [];
            this.scheds.map((sch, n)=>{
                if (!!sch.wkmodeonschedule1 && !!sch.wkmodeonschedule1.id) {
                    var s = {
                        id: sch.wkmodeonschedule1.id, 
                        schedule: sch.id, 
                        wkmode: sch.wkmodeonschedule1.wkmodeid, 
                        carrier: sch.wkmodeonschedule1.carrier,
                        tenantid: sch.wkmodeonschedule1.tenantid,
                    }
                    arr.push(s);
                }
                if (!!sch.wkmodeonschedule2 && !!sch.wkmodeonschedule2.id) {
                    var s = {
                        id: sch.wkmodeonschedule2.id, 
                        schedule: sch.id, 
                        wkmode: sch.wkmodeonschedule2.wkmodeid, 
                        carrier: sch.wkmodeonschedule2.carrier,
                        tenantid: sch.wkmodeonschedule2.tenantid,
                    }
                    arr.push(s);
                }
            });
            return arr;
        },
        recalcOrdernumber(ordernumber, scheduleId){
          this.scheds.filter((sch)=>{
                return ((sch.ordernumber >= ordernumber) && (sch.id !== scheduleId));
            })
            .map((sch)=>{
                sch.ordernumber = sch.ordernumber + 1;
            });
            
            this.simpleRefresh();
        },
        recalcRestTime(sch, stop){
            var n = sch.stops.findIndex(it => it.id === stop.id);
            const isFirst = sch.stops.find(it => !!it.arr && !!it.dep) === stop;
            const isLast = sch.stops.slice().reverse().find(it => !!it.arr && !!it.dep) === stop;
            var at = null;
            if (!!isFirst){ //first stop by prev schedule
                const prev = this.scheds.find((_sch) => sch.depId === _sch.depId && sch.ordernumber > _sch.ordernumber);
                at = (!!prev) ? prev.end : stop.dep;
            } else {
                at = stop.dep;
            }
            var to = null;
            if (!!isLast) { //last stop by next schedule
                const next = this.scheds.find((_sch) => sch.depId === _sch.depId && sch.ordernumber < _sch.ordernumber);
                if (!!next) {
                    to = next.start;
                    const first = next.stops.find(it => !!it.arr && !!it.dep);
                    if (!!first) {
                        first.stop = (!!to) ? Math.abs(stop.arr.diff(to, 'minutes')) : 0;
                    }
                }
                to = stop.arr;
            } else {
                to = stop.arr;
            }
            stop.stop = (!!at) ? Math.abs(at.diff(to, 'minutes')) : 0;
        },
        recalcRestTimeMissed(sch, stop, isLast){
            if (!!isLast) {
                const next = this.scheds.find((_sch) => sch.depId === _sch.depId && sch.ordernumber < _sch.ordernumber)
                if (!!next) {
                    const first = next.stops.find(it => !!it.arr && !!it.dep);
                    if (!!first) {
                        first.stop = Math.abs(sch.end.diff(next.start, 'minutes'));
                    }
                }
            } else {
                var next = sch.stops.find(it => !!it.arr && !!it.dep && it.pn > stop.pn);
                if (!!next) {
                    const st1 = !!next.stop ? next.stop : 0;
                    const st2 = !!stop.stop ? stop.stop : 0;
                    next.stop = st1 + st2;
                }
            }
        },
        recalcNewDayDeparture(dep){
            var prevTime = null;
            this.scheds.filter((sch)=>{
                return (dep.id === sch.depId);
            })
            .sort((s1, s2)=>{
                return (s1.ordernumber < s2.ordernumber) ? -1 : 1;
            })
            .map((sch, n)=>{
                if ((!!sch.stops) && (Array.isArray(sch.stops))) {
                    var changed = false;
                    sch.stops.sort((s1, s2)=>{
                         return (s1.pn < s2.pn) ? -1 : 1;
                     })
                    .map((stop)=>{
                        if (!!stop.arr && !!stop.dep) {
                            if (!!prevTime) {
                                var recalc = this.recalcNewDay(prevTime, stop.arr);
                                changed = !changed ? recalc.changed : changed;
                                stop.arr.set('date', recalc.day);

                                recalc = this.recalcNewDay(stop.arr, stop.dep);
                                changed = !changed ? recalc.changed : changed;
                                stop.dep.set('date', recalc.day);

                                stop.newDay = prevTime.format("DD") !== stop.arr.format("DD") || stop.arr.format("DD") !== stop.dep.format("DD");
                            }
                            stop.changed = changed;
                            changed = false;
                            prevTime = stop.dep;
                        }
                    });
                    this.recalcStartEndSchedule(sch);
                }
            })
        },
        recalcNewDay(prevTime, currTime){
            var res = {
                day: 1,
                changed: false
            };
            
            var fTime1 = prevTime.format("HH:mm:ss");
            var time1 = $moment(fTime1, "HH:mm:ss");
            var fTime2 = currTime.format("HH:mm:ss");
            var time2 = $moment(fTime2, "HH:mm:ss");

            var fDate1 = prevTime.format("DD");
            var date1 = Number(fDate1);
            var fDate2 = currTime.format("DD");
            var date2 = Number(fDate2);
            res.day = date2;
            //Нормальное время 
            if (time1.isSameOrBefore(time2)) {
                if (date1 !== date2) {
                    res.day = date1;
                    res.changed = true;
                }
            //Время подразумевает переход через сутки
            } else {
                if (date2 !== date1 + 1) {
                    res.day = date1 + 1;
                    res.changed = true;
                }
            }
            return res;
        },
        recalcStartEndSchedule(sch){
            var first = sch.stops.find(it => !!it.arr && !!it.dep);
            if (!!first) {
                sch.start = first.dep;
            }
            var last = sch.stops.slice().reverse().find(it => !!it.arr && !!it.dep);
            if (!!last) {
                sch.end = last.arr;
            }
        },
        recalcStartEndDeparture(dep){
            var scheds = this.scheds.filter((_sch)=>{
                return (dep.id === _sch.depId);
            }).sort((s1, s2)=>{
                return (s1.ordernumber < s2.ordernumber) ? -1 : 1;
            });
            var first = scheds.find(it => !!it.start);
            if (!!first) {
                dep.start = first.start;
            }
            var last = scheds.slice().reverse().find(it => !!it.end);
            if (!!last) {
                dep.end = last.end;
            }
        },
        removeSchedule(sch){
            const idx = this.scheds.indexOf(sch);
            if (idx > -1) {
                this.scheds.splice(idx, 1);
                this.activeDep.n--;
            }
            this.checkEmpyDepartures();
        },
        copySchedulePair(sch){
            var tripcode = this.nextTripCode(sch);
            var ordernumber = this.nextOrderNumber();
            var linkcode = this.nextLinkCode();
            this.createBufferDeparture();
            
            this.deps.filter((_dep) => {
                return !_dep.id
            })
            .map((_dep) => {
                this.scheds.filter((_sch) => {
                    return (sch.linkcode === _sch.linkcode)
                })
                .sort((s1, s2)=>{
                    return (s1.ordernumber < s2.ordernumber) ? -1 : 1;
                })
                .map((_sch) => {
                    var s = _copy(_sch);
                    s = this.preparedScheduleCopy(s, tripcode, linkcode, ordernumber);
                    s.depId = null;
                    this.scheds.push(s);
                    _dep.n++;
                    ordernumber++;
                    tripcode = this.tryToIncrementString(tripcode);
                });
                this.activeDep = _dep;
            })
        },
        preparedScheduleCopy(s, tripcode, linkcode, ordernumber){
            s.id = $utils.uuidv4();
            s.start = _to_time(s.start);
            s.end = _to_time(s.end);
            s.ordernumber = ordernumber;
            s.linkcode = linkcode;
            s.tripcode = tripcode;

            if ((!!s.stops) && (Array.isArray(s.stops))) {
                var stops = [];

                s.stops.map((stop, n)=>{
                    var ss = stop;
                    ss.arr = _to_time(ss.arr);
                    ss.dep = _to_time(ss.dep);
                    ss.id = $utils.uuidv4();
                    ss.schedule = s.id;
                    stops.push(ss);
                });
                s.stops = stops;
            }
            return s;
        },
        removeDeparture(dep){
            const idx = this.deps.indexOf(dep);
            if (idx > -1) {
                this.scheds = this.scheds.filter((sch)=>{
                    return (dep.id !== sch.depId);
                });
                this.deps.splice(idx, 1);
            }
        },
        breakDeparture(dep){
            var n = 0;
            this.scheds.filter((sch) => {
                return (sch.depId === dep.id)
            })
            .map((sch) => {
                sch.depId = null;
                n++;
            });
            
            this.createBufferDeparture();
            
            this.deps.filter((_dep) => {
                return !_dep.id
            })
            .map((_dep) => {
                _dep.n = _dep.n + n;
            })
            this.removeDeparture(dep);
            this.simpleRefresh();
        },
        copyDeparture(dep){
            var d = _copy(dep);
            d.id = $utils.uuidv4();
            d.name = this.nextDepName(dep);
            d.start = _to_time(d.start);
            d.end = _to_time(d.end);
            this.deps.push(d);
            this.activeDep = d;
            
            var tripcode = this.nextTripCode(this.scheds.find((sch) => sch.depId === dep.id));
            var ordernumber = this.nextOrderNumber();
            var linkcode = this.nextLinkCode();
            var prevLinkcode = 0;
            
            this.scheds.filter((sch) => {
                return (sch.depId === dep.id)
            })
            .sort((s1, s2)=>{
                return (s1.ordernumber < s2.ordernumber) ? -1 : 1;
            })
            .map((sch, n) => {
                if (n > 0 && sch.linkcode !== prevLinkcode) {
                    linkcode++;
                }
                
                var s = _copy(sch);
                s.depId = d.id;
                s = this.preparedScheduleCopy(s, tripcode, linkcode, ordernumber);
                
                this.scheds.push(s);
                
                tripcode = this.tryToIncrementString(tripcode);
                ordernumber++;
                prevLinkcode = sch.linkcode;
            })
            
        },
        nextDepName(dep){
            var str = '01';
            if (!!dep) {
                if (!!dep.name && !!dep.id) {
                    str = new String(dep.name);
                } else if (!!this.deps && this.deps.filter((d)=> !!d.id).length > 0) {
                    str = new String(this.deps.find((d)=> !!d.id).name);
                } else return str;
                var newName = this.tryToIncrementString(str);
                while (this.deps.findIndex((d)=> d.name === newName) > -1) {
                    newName = this.tryToIncrementString(newName);
                }
                return newName;
            } else {
                return str;
            }
        },
        nextLinkCode(){
            var next = this.scheds.length > 0 ? Math.max.apply(Math, this.scheds.map(function(o) { return o.linkcode; })) : 0;
            return !!next ? next + 1 : 1;
        },
        nextTripCode(sch){
            var str = this.scheduleVersion.sRoutecode;
            var newName = null;
            if (!!sch) {
                if (!!sch.tripcode) {
                    str = new String(sch.tripcode);
                } else if (!!this.scheds) {
                    str = new String(this.scheds.find((d)=> !!d.id).name);
                } else return str;
                newName = this.tryToIncrementString(str);
                while (this.scheds.findIndex((d)=> d.tripcode === newName) > -1) {
                    newName = this.tryToIncrementString(newName);
                }
            } else {
                newName = `${str}-01`;
            }
            return newName;
        },
        nextOrderNumber(){
            var next = this.scheds.length > 0 ? Math.max.apply(Math, this.scheds.map(function(o) { return o.ordernumber; })) : 0;
            return !!next ? next + 1 : 1;
        },
        tryToIncrementString(str){
            var numStart = -1;
            var numEnd = -1;
            var strlen = str.length;
            for(var i = strlen-1; i >= 0; --i) {
                var c = str[i];
                if (!isNaN(c)) {
                    if(numEnd < 0) {
                        numEnd = i+1;
                    } else {//конец цифр
                        numStart = i;
                    }
                } else if(numEnd >= 0) {//текущий символ не цифра а цифры мы уже нашли
                      break;
                }
            }
            if(numStart == -1 && numEnd >= 0) {//фикс для одного символа
                numStart = numEnd - 1;
            }
            if(numEnd >= 0 && numStart >= 0) {
                var numStr = str.substring(numStart, numEnd);
                var num = Number(numStr);
                if(num >= 0) {
                    ++num;
                    var constStr = str.substring(0, numStart);
                    var incrNumStr = new String(num);
                    for(var count = numStr.length - incrNumStr.length; count > 0; --count) {
                        constStr = constStr + '0';
                    }
                    constStr = constStr + incrNumStr;
                    return constStr;
                }
            }
            return null;
        },
        async refreshVers(id){
            const verUri = `sin2:/v:17743043-925a-48e4-90b5-8f1e77226a01?filter=
                                    exists(\"17743043-925a-48e4-90b5-8f1e77226a01\",
                                                   \"and(
                                                       eq(field(\\\"s.verid\\\"),super.field(\\\"s.verid\\\")),
                                                       eq(field(\\\"s.id\\\"),param(\\\"${ this.route.vcroutesId }\\\",\\\"id\\\"))
                                                   )\"
                                    )
                                    &sort=vcReleaseSchedules.verStatus.Name,-vcReleaseSchedules.verStart`;
            try{
                const res = await $http.post('/rpc?d=jsonRpc', {
                    type: 'core-read',
                    query: verUri
                });
                if (!!res.error){
                    throw res.error;
                }
                this.deps = null;
                this.scheds = null;
                this.activeDep = null;
                const m = new Model(res.result.model);
                this.schedules = m.sin2obj(res.result.data, true);
                this.scheduleVersion = this.schedules.find((sch) => {return sch.vcreleaseschedulesId === id});
                this.schVerId = id;
                this.refresh();
            } catch(e){
                console.log('error on refreshVers', e);
            }
        },
        chkRest(sch){
            if (!(!!this.scheds)){
                return;
            }
            var scheds = this.scheds.filter((_sch)=>{
                return (sch.depId === _sch.depId) && (sch.start > _sch.end);
            }).sort((s1, s2)=>{
                return (s1.ordernumber < s2.ordernumber) ? -1 : 1;
            });
            if (scheds.length < 1){
                return;
            }
            const m = $utils.moment;
            var reCalc = false,
                start15 = scheds[0].start,
                start45 = scheds[0].start;
            //1. check a need's
            scheds.map((sh, n)=>{
                if ( (reCalc) || !(!!sh.stops) || !Array.isArray(sh.stops) ){
                    reCalc = true;  //wait all stop's loading...
                    return;
                } else {
                    sh.stops.map((st, n)=>{
                        var d = m(st.dep).diff(start15, 'minutes');
                        if (d > 4*60) {
                            start15 = st.dep;
                            sh.need15 = true;
                        }
                        d = m(st.dep).diff(start45, 'minutes');
                        if (d > 5*60) {
                            start45 = st.dep;
                            sh.need45 = true;
                        }
                    });
                }
            });
            if (reCalc){
                setTimeout(()=>{this.chkRest(sch);}, 300);  //TODO: the bag
            }

        }   //chkRest
    },
    render(h){
        const m = $moment;
        var s;
        return h('v-container', {
                    props: {fluid: true},
                    class: {'fill-height': true, 'mt-shedules': true}
                }, [
            h('sch-routes', {
                ref: "setup",
                on: {
                        route: (r)=>{
                            this.route = r;
                        },
                        prepareSaveTotal: (r)=>{
                            if (this.scheduleVersion.vcreleaseschedulesVerstatusName !== 'Проект') {
                                jet.msg({type:'warning', text:`Расписание со статусом "${this.scheduleVersion.vcreleaseschedulesVerstatusName}" редактировать запрещено`})
                                return;
                            }
                            var emptyTime = [];
                            var emptySeason = [];
                            this.scheds.map((sch, n)=>{
                                if ((!!sch.stops) && (Array.isArray(sch.stops))) {
                                    var emptyT = sch.stops.findIndex(stop => {
                                        if ((!stop.arr || !stop.dep) && !stop.miss) {
                                            return true;
                                        }
                                    });
                                    if (emptyT !== -1) {
                                        emptyTime.push(sch.tripcode);
                                    }
                                    
                                    var wkmode = !!sch.wkmode.wkmodeid ? sch.wkmode : sch.wkmodeonschedule1;
                                    if (!sch.season.name || !wkmode || (!!wkmode && !wkmode.name)) {
                                        emptySeason.push(sch.tripcode);
                                    }
                                }
                            });
                            if (emptyTime.length > 0 || emptySeason.length > 0) {
                                this.confirm.show = true;
                                this.confirm.message = (emptyTime.length > 0
                                    ? `Не заполнено время въезда/выезда для рейсов «${emptyTime.join(",")}». `
                                    : ``) + 
                                    (emptySeason.length > 0
                                    ? `Необходимо заполнить режим работы и сезонность для рейсов «${emptySeason.join(",")}». `
                                    : ``) + 
                                    `Вы действительно хотите сохранить расписание?`;
                                this.confirm.title = "Сохранение расписания";
                                this.confirm.method = 'saveTotal';
                                this.confirm.object = null;
                                this.confirm.no = false;
                                this.confirm.yes = true;
                            } else {
                                this.saveTotal();
                            }
                        },
                        schedule: (scheduleVersion)=>{
                            this.scheduleVersion = scheduleVersion;
                            if (!!scheduleVersion) {
                                this.schVerId = scheduleVersion.vcreleaseschedulesId;
                            } else {
                                this.schVerId = null;
                            }
                            console.log('on schedule changed:', this.schVerId);  
                            this.refresh();
                        },
                        schedules: (r)=>{
                            this.schedules = r;
                        },
                }
            }),
            (this.mode === SCH_MODE.default)
                ? h('v-row', [
                    h('v-col', {
                        props: {cols: 3}, 
                        class: {'sch-deps': true, 'd-none': !this.has("deps")}}, [
                        /* ============ Dep's for active route ============ */
                        h('v-timeline', {props: {dense: true}}, [
                           this.deps.map( dep => {
                                s = (dep.tt > 60)
                                    ? Math.trunc(dep.tt/60) + ' ч. ' + (dep.tt - (Math.trunc(dep.tt/60 )*60)) + ' мин.'
                                    : dep.tt + ' мин.';
                                var menuDep = this.scheduleVersion.vcreleaseschedulesVerstatusName === 'Проект'
                                    ? h('v-menu', {
                                        props: {"close-on-click": true,
                                            "close-on-content-click": true,
                                            "offset-x": true,
                                            tile: true},
                                        scopedSlots: {
                                          activator: ({on}) => {
                                            return h('v-btn', {
                                                on: on,
                                                props: {icon: true}
                                              },
                                              [h('jet-svg', {props: {xref: '#ico-kra', width: 25, height: 25}})]
                                            );
                                          }
                                        }
                                      },
                                      [
                                        !!dep.id
                                        ? h('v-list', [
                                            h('v-list-item', {class: {"menu-item": true},
                                              on: {
                                                click: () => { this.copyDeparture(dep);}
                                              }
                                            }, 'Копировать выезд'),
                                        ])
                                        : null,
                                        h('v-list', [
                                            h('v-list-item', {class: {"menu-item": true},
                                              on: {
                                                click: () => { 
                                                    this.showScheTransfer = true;
                                                    this.modeTransferSchedule = false;
                                                }
                                              }
                                            }, 'Добавить к выезду график'),
                                        ]),
                                        h('v-list', [
                                            h('v-list-item', {class: {"menu-item": true},
                                              on: {
                                                click: () => { 
                                                    this.showScheTransfer = true;
                                                    this.modeTransferSchedule = true;
                                                }
                                              }
                                            }, 'Перенести график из выезда в выезд'),
                                        ]),
                                        !!dep.id
                                        ? h('v-list', [
                                            h('v-list-item', {class: {"menu-item": true},
                                              on: {
                                                click: () => { this.removeDeparture(dep);}
                                              }
                                            }, 'Удалить выезд'),
                                        ])
                                        : null,
                                        !!dep.id
                                        ? h('v-list', [
                                            h('v-list-item', {class: {"menu-item": true},
                                              on: {
                                                click: () => { this.breakDeparture(dep);}
                                              }
                                            }, 'Расформировать выезд'),
                                        ])
                                        : null
                                    ])
                                    : null;
                                return h('v-timeline-item', {
                                       key:'dep-' + dep.id,
                                       props: {small: true}
                                   }, [
                                       h('v-card', {
                                           class: {'sch-dep': true, 'sch-dep__active': ((!!this.activeDep)&&(this.activeDep.id === dep.id))},
                                           on: {click: ()=>{this.activeDep = dep;},
                                                contextmenu: ()=>{console.log('contextmenu:', dep);}
                                               }
                                       }, [
                                           h('v-card-text', [
                                               h('div', {class: {'d-flex': true, 'justify-space-between': true}}, [
                                                    h('div', {class:{'sch-dep__num': true},
                                                        on: {click: (e)=>{
                                                                if (this.scheduleVersion.vcreleaseschedulesVerstatusName !== 'Проект'){
                                                                    jet.msg({type:'warning', text:`Расписание со статусом "${this.scheduleVersion.vcreleaseschedulesVerstatusName}" редактировать запрещено`});
                                                                } else {
                                                                    this.activeDep = dep;
                                                                    this.showDepCode = true;
                                                                }
                                                            }}}, dep.name),
                                                    h('div', {class:{'sch-dep__times': true}}, [
                                                        h('v-badge', {props:{content: dep.n}},
                                                            !!dep.id
                                                            ? m(dep.start).format('HH:mm') + ' - ' + m(dep.end).format('HH:mm')
                                                            : null
                                                        ),
                                                        !!dep.id
                                                        ? h('div', {class:{'sch-dep__tt':true}}, s)
                                                        : null
                                                    ])
                                               ]),
                                               h('div',{class:{'sch-dep__modes': true}}, [
                                                   h('div', $utils.isEmpty(dep.season) ? null : dep.season),
                                                   h('div', $utils.isEmpty(dep.wkmode) ? null : dep.wkmode)
                                               ]),
                                               (dep.tt > 5*60)
                                                    ? h('div', {class: {'sch-dep__warn': true}}, [
                                                            h('jet-svg', {props: {xref: '#ico-warn'}}),
                                                            'требуется смена водителей'
                                                        ])
                                                    : null
                                            ]),
                                            h('v-card-actions', [
                                                menuDep
                                           ])
                                       ])
                               ]);
                           })
                       ])
                    ]),     //v-col (4 - deps)
                    h('v-col', {props: {cols: this.has("deps") ? 9 : 12}, class: {'sch-schedules': true}}, [
                        h('v-toolbar', {
                            props: {dense: true, absolute: true, flat: true},
                            class: {"d-none": !(!!this.route)}
                        }, [
                            h('v-toolbar-title', [
                                (!!this.route)
                                    ? `графики движения по маршруту № ${ this.route.vcroutesRoutecode }. ${ this.route.vcroutesRoutename } 
                                       ${!(!!this.schVerId) ? ' (версия не выбрана)' : ', версия расписания №' +  this.scheduleVersion.vcreleaseschedulesVersion }`
                                    : null
                            ])
                        ]),
                        this.activeScheds.map((sch)=>{
                                if (!sch.stops){
                                    this.stops(sch);
                                }
                                var items = [];
                                if ((!!sch.stops) && (Array.isArray(sch.stops))) {
                                    var stopMarks = sch.stops.findIndex(stop => {
                                        if (!!stop.newDay || !!stop.rq || (!!stop.service && stop.service.length > 0)) {
                                            return true;
                                        }
                                    });
                                    sch.stops.map((stop, n)=>{
                                        items.push(
                                            h('v-menu', {
                                                props: {
                                                    "close-on-click": true,
                                                    "close-on-content-click": true,
                                                    "offset-x": true,
                                                    tile: true
                                                },
                                                scopedSlots: {
                                                    activator: ({on}) => {
                                                        return h('v-list-item', {
                                                            on: on,key: stop.id, 
                                                            class: {"sch-stop": true, "sch-stop__miss": stop.miss}
                                                        }, [
                                                            stopMarks !== -1
                                                            ? h('v-flex', {class:{"sch-stop__marks": true}}, [
                                                                h('v-col', [
                                                                    !!stop.newDay
                                                                    ? h('v-row', [
                                                                        h('v-icon', {props: {color: 'red', small: true}}, 'mdi-clock-outline')
                                                                    ])
                                                                    : null,
                                                                    stop.rq
                                                                    ? h('v-row', [
                                                                        h('v-icon', {props: {color: 'gray', small: true}}, 'mdi-human-greeting')
                                                                    ])
                                                                    : null,
                                                                    (!!stop.service && stop.service.length > 0)
                                                                    ? h('v-row', [
                                                                        h('v-icon', {props: {color: 'gray', small: true}}, 'mdi-coffee')
                                                                    ])
                                                                    : null,
                                                                ])
                                                            ])
                                                            : null,
                                                            h('v-list-item-icon', {class: {'sch-stop__order': true}},
                                                                n + 1),
                                                            h('v-list-item-content', {
                                                                style: {'padding-top': stop.stop === 0 ? 0 : "1em", color: stop.changed ? "red" : stop.miss ? "#ccc" : "auto"}
                                                            }, [h('v-hover', {
                                                                    scopedSlots: {
                                                                        default: ({hover})=>{
                                                                            if (stop.miss) {
                                                                                return null;
                                                                            }
                                                                            const s = (!!stop.arr ? m(stop.arr).format('HH:mm') : '__:__')
                                                                                    + ' ' 
                                                                                    + (!!stop.dep ? m(stop.dep).format('HH:mm') : '__:__');
                                                                            return h('div', {
                                                                                    class: {
                                                                                        'sch-stop__time': true,
                                                                                        'unselectable': true,
                                                                                        'elevation-3': (!!hover)
                                                                                    },
                                                                                    on: {
                                                                                        click:(e)=>{
                                                                                            if (e){
                                                                                                e.preventDefault();
                                                                                                e.stopPropagation();
                                                                                            }
                                                                                            this.activeSch = sch;
                                                                                            this.set_time(stop);
                                                                                        }
                                                                                    }
                                                                                }, [
                                                                                ( stop?.stop > 0) 
                                                                                    ? h('v-badge', {
                                                                                        props: {content: stop.stop, color: 'yellow lighten-4'}
                                                                                    }, s)
                                                                                    : s
                                                                                ]
                                                                            );
                                                                        }
                                                                    }
                                                                }),
                                                                h('div',{class: {'sch-stop__name': true}}, stop.pt)
                                                            ])
                                                        ]);
                                                    }
                                                }
                                            }, [
                                                h('v-list', [
                                                    h('v-list-item', { class: {"menu-item": true},
                                                        on: {click: ()=>{
                                                                this.confirm.show = true;
                                                                this.confirm.message = "Установить признак на всех рейсах в выбранном направлении?";
                                                                this.confirm.title = !stop.rq ? "Добавить признак «Остановка по требованию»" : "Снять признак «Остановка по требованию»";
                                                                this.confirm.method = 'setRequestStop';
                                                                this.confirm.object = stop;
                                                                this.confirm.no = true;
                                                                this.confirm.yes = true;
                                                        }}
                                                    }, !stop.rq ? "Добавить признак «Остановка по требованию»" : "Снять признак «Остановка по требованию»"),
                                                    h('v-list-item', { class: {"menu-item": true},
                                                        on: {click: ()=>{
                                                                this.confirm.show = true;
                                                                this.confirm.message = !stop.miss ? `Удалить остановку «${stop.pt}»?` : `Добавить остановку «${stop.pt}»?`;
                                                                this.confirm.title = !stop.miss ? "Удалить остановку расписания" : "Добавить остановку в рейс";
                                                                this.confirm.method = 'setMiss';
                                                                this.confirm.object = stop;
                                                                this.confirm.no = false;
                                                                this.confirm.yes = true;
                                                        }}
                                                    }, !stop.miss ? "Удалить остановку расписания" : "Добавить остановку в рейс"),
                                                    h('v-list-item', { class: {"menu-item": true},
                                                        on: {click: ()=>{ 
                                                            this.showServStop = true;
                                                            this.stop = stop;
                                                        }}
                                                    }, "Добавить сервисную стоянку")
                                                ])
                                            ]),
                                            h('v-divider')
                                        );
                                    });      //sch.stops.map
                                    
                                    items.push(
                                        h('v-list-item', {key: 'sch-modes-' + sch.id, class:{'sch-modes':true},
                                            on: {click: ()=>{
                                                    this.activeSch = sch;
                                                    this.showSeas = true;
                                                }}}, [
                                            h('v-list-item-icon',  [
                                                h('v-icon', 'mdi-calendar-month')
                                            ]),
                                            h('v-list-item-content', [
                                                $utils.isEmpty(sch.season.name) 
                                                    ? null
                                                    : h('div', sch.season.name),
                                                !sch.wkmode.name
                                                    ? null
                                                    : h('div', sch.wkmode.name),
                                                !sch.wkmodeonschedule1
                                                    ? null
                                                    : h('div', sch.wkmodeonschedule1.name),
                                                !sch.wkmodeonschedule2
                                                    ? null
                                                    : h('div', sch.wkmodeonschedule2.name)
                                            ])
                                        ])
                                    );
                                    items.push( h('v-divider') );
                                    
                                    s = (sch.tt > 60)
                                            ? Math.trunc(sch.tt/60) + ' ч. ' + (sch.tt - (Math.trunc(sch.tt/60 )*60)) + ' мин.'
                                            : sch.tt + ' мин.';
                                    items.push(
                                        h('v-list-item', {key: 'sch-toot-' + sch.id, class:{'sch-time':true}}, [
                                            h('v-list-item-icon',  [
                                                h('v-icon', 'mdi-clock-outline')
                                            ]),
                                            h('v-list-item-content', [
                                                'в пути: ' + s
                                            ])
                                        ])
                                    );
                                } else {
                                    items.push(h('v-list-item', {key:'sch-stub-' + sch.id}));
                                }
                            var menuSched = h('v-menu', {
                                        props: {"close-on-click": true,
                                            "close-on-content-click": true,
                                            "offset-x": true,
                                            tile: true},
                                        scopedSlots: {
                                          activator: ({on}) => {
                                            return h('div', {on: on, class:{'sch-schedule__title': true}}, [
                                                h('v-text-field', {
                                                    props: {
                                                        value: sch.tripcode, 
                                                        disabled: this.scheduleVersion.vcreleaseschedulesVerstatusName !== 'Проект',
                                                        "hide-details": true,
                                                        dense: true, 
                                                        solo: true,
                                                        filled: true,
                                                        flat: true,
                                                    },
                                                    on: {input: (e)=>{ sch.tripcode = e; },
                                                        click:(e)=>{
                                                            if (e){
                                                                e.preventDefault();
                                                                e.stopPropagation();
                                                            }
                                                        }
                                                    }
                                                }, sch.tripcode),
                                                h('div', '№ пп:'),
                                                h('v-text-field', {
                                                    props: {
                                                        value: sch.ordernumber, 
                                                        disabled: this.scheduleVersion.vcreleaseschedulesVerstatusName !== 'Проект',
                                                        "hide-details": true,
                                                        dense: true, 
                                                        solo: true,
                                                        filled: true,
                                                        flat: true,
                                                    },
                                                    class: {"ordernumberInput": true},
                                                    on: {change: (e)=>{
                                                            if(isNaN(e)){
                                                                jet.msg({type:'warning', text:'Значение должно быть целочисленным!'});
                                                            } else {
                                                                if (e !== sch.ordernumber) {
                                                                    sch.ordernumber = Number(e);
                                                                    this.recalcOrdernumber(Number(e), sch.id);
                                                                }
                                                            }
                                                        },
                                                        click:(e)=>{
                                                            if (e){
                                                                e.preventDefault();
                                                                e.stopPropagation();
                                                            }
                                                        }
                                                    }
                                                }, sch.ordernumber),
                                                !!sch.start && !!sch.end
                                                ? h('div', m(sch.start).format('HH:mm') + ' - ' + m(sch.end).format('HH:mm'))
                                                : null,
                                                h('div', sch.linkcode),
                                            ]);
                                          }
                                        }
                                      },
                                      this.scheduleVersion.vcreleaseschedulesVerstatusName === 'Проект'
                                      ? [
                                            h('v-list', [
                                                h('v-list-item', {class: {"menu-item": true},
                                                  on: {
                                                    click: () => { this.removeSchedule(sch);}
                                                  }
                                                }, 'Удалить рейс'),
                                            ]),
                                            h('v-list', [
                                                h('v-list-item', {class: {"menu-item": true},
                                                  on: {
                                                    click: () => { this.copySchedulePair(sch);}
                                                  }
                                                }, 'Копировать график'),
                                            ])
                                        ]
                                      : null
                                    );
                                
                            return h('v-card', {
                                key: 'sch-' + sch.id,
                                class: {'sch-schedule': true, 'need-15': sch.need15, 'need-45': sch.need45}
                            }, [
                                h('v-card-text', [
                                    menuSched,
                                    h('v-list', {
                                        key:'sch-stops-' + sch.id, 
                                        props: {
                                            disabled: this.scheduleVersion.vcreleaseschedulesVerstatusName !== 'Проект'
                                    }}, items)
                                ])
                            ]);
                        })
                    ])  //v-col (9)
                ])  //v-row
                : null,
            h(RouteSelVersion, {
                ref: "sel-version",
                on: {
                    input: e => {
                        this.route = e.route;
                        this.scheduleVersion = e.ver;
                        this.schVerId = e.ver.vcreleaseschedulesId;
                        this.schedules = e.vers;
                        this.refresh();
                    }
                }
            }),
            (!!this.route)
                ? h(NewVersionDialog, {
                    props: {show: this.showVer, route: this.route, schedules: this.schedules},
                    on: {close:()=>{this.showVer = false;},
                        saveNewVersion:(id)=>{
                            const d = this.$refs["sel-version"];
                            d.loadVers();
                            this.refreshVers(id);
                        }
                    }
                })
                : null,
            h(ApproveVersionDialog, {
                props: {show: this.fixSche, route: this.route, verId: this.schVerId, schedules: this.schedules},
                on: {close:()=>{this.fixSche = false;},
                    approveVersion:(id)=>{
                        const d = this.$refs["sel-version"];
                        d.loadVers();
                        this.refreshVers(id);
                    }
                }
            }),
            h(CloseVersionDialog, {
                props: {show: this.closeSche, version: this.scheduleVersion},
                on: {close:()=>{this.closeSche = false;},
                    closeVersion:(id)=>{
                        const d = this.$refs["sel-version"];
                        d.loadVers();
                        this.refreshVers(id);
                    }
                }
            }),
            h(CopyVersionDialog, {
                props: {show: this.copySche, route: this.route, verId: this.schVerId, schedules: this.schedules},
                on: {close:()=>{this.copySche = false;},
                    copyVersion:(id)=>{
                        const d = this.$refs["sel-version"];
                        d.loadVers();
                        this.refreshVers(id);
                    }
                }
            }),
            h(DepartureCodeDialog, {
                props: {show: this.showDepCode, departure: this.activeDep, deps: this.deps},
                on: {close:()=>{this.showDepCode = false;},
                    setDepName:(name)=>{
                        this.activeDep.name = name;
                        this.activeDep.iname = Number(name.replace(/\D/g, ''));
                        this.deps.sort((d1, d2)=>{
                            return (d1.iname < d2.iname) 
                                        ? -1
                                        : (d1.iname > d2.iname) ? 1 
                                            : 0;
                        });
                    }
                }
            }),
            h(SeasonWkmodeDialog, {
                props: {show: this.showSeas, schedule: this.activeSch, deps: this.deps, scheds: this.scheds},
                on: {close:()=>{this.showSeas = false;},
                    saveSeasonWkmode:(result)=>{
                        this.scheds.map((sch) => {
                            var wk = result.wkmodes.get(sch.id);
                            if (wk) {
                                sch.season.name = result.season.name;
                                sch.season.id = result.season.id;
                                sch.wkmode = wk.wkmode;
                                sch.wkmodeonschedule1 = wk.wkmodeonschedule1;
                                sch.wkmodeonschedule2 = wk.wkmodeonschedule2;
                            }
                        });
                    }
                }
            }),
            h(AddServiceStopDialog, {
                props: {show: this.showServStop, stop: this.stop, types: this.serviceStopsTypes},
                on: {close:()=>{this.showServStop = false;},
                    save:(result)=>{
                        this.stop.service = result.service;
                    }
                }
            }),
            h(ScheduleTransferDialog, {
                props: {show: this.showScheTransfer, deps: this.deps, scheds: this.scheds, modeTransfer: this.modeTransferSchedule},
                on: {close:()=>{this.showScheTransfer = false;},
                    save:(result)=>{
                        this.scheds.filter((sch) => {
                            return result.sch.find((_sch) => _sch.id === sch.linkcode )
                        })
                        .map((sch)=>{
                            this.deps.filter((dep) => {
                                return dep.id === sch.depId;
                            })
                            .map((dep) => {
                                dep.n--;
                            });
                            
                            this.deps.filter((dep) => {
                                return dep.id === result.dep.id;
                            })
                            .map((dep) => {
                                dep.n++;
                            });
                            sch.depId = result.dep.id;
                        });
                        this.recalcNewDayDeparture(result.dep);
                        this.recalcStartEndDeparture(result.dep);
                        this.checkEmpyDepartures();
                        this.simpleRefresh();
                    }
                }
            }),
            h(AddDepDialog, {
                props: {show: this.showAddDep, scheds: this.scheds},
                on: {close:()=>{this.showAddDep = false;},
                    save:(result)=>{
                        var depId = $utils.uuidv4();
                                                
                        this.scheds.filter((sch) => {
                            return result.sch.find((_sch) => _sch.id === sch.linkcode )
                        })
                        .map((sch)=>{
                            if (!this.deps.find((dep) => dep.id === depId) ){
                                var wkmode = !!sch.wkmode.wkmodeid ? sch.wkmode : sch.wkmodeonschedule1;
                                var d = {
                                    id: depId,
                                    name: this.nextDepName(this.deps.find((dep) => !!dep.id)),
                                    season: !!sch.season ? sch.season.name : null,
                                    wkmode: !!wkmode ? wkmode.name : null,
                                    start: sch.start,
                                    end:  sch.end,
                                    route: sch.route,
                                    verId: sch.versionid,
                                };
                                d.iname = Number(d.name.replace(/\D/g, ''));
                                this.deps.push(d);
                            }
                
                            this.deps.filter((dep) => {
                                return dep.id === sch.depId;
                            })
                            .map((dep) => {
                                dep.n--;
                            });
                            
                            this.deps.filter((dep) => {
                                return dep.id === depId;
                            })
                            .map((dep) => {
                                dep.n++;
                            });
                            sch.depId = depId;
                        });
                        this.deps.filter((dep) => {
                            return dep.id === depId;
                        })
                        .map((dep)=>{
                            dep.n = 0;
                            dep.tt = 0;
                            this.scheds.filter((sch)=>{return (sch.depId === dep.id);})
                            .map((sch)=>{
                                dep.n++;
                                if (sch.start < dep.start){
                                    dep.start = sch.start;
                                }
                                if (sch.end > dep.end){
                                    dep.end = sch.end;
                                }
                            });
                            dep.tt = $moment(dep.end).diff(dep.start, 'minutes');
                            this.recalcNewDayDeparture(dep);
                        });
                        
                        this.checkEmpyDepartures();

                        this.deps.sort((d1, d2)=>{
                            return (d1.iname < d2.iname) 
                                ? -1
                                : (d1.iname > d2.iname) ? 1 
                                    : 0;
                        });
                        this.simpleRefresh();
                    }
                }
            }),
            h(ScheduleTimeDialog, {
                props: {show: this.showTime, stop: this.stop, schedule: this.activeSch},
                on: {close: ()=>{this.showTime = false;},
                    setTime:(newTime)=>{
                        if (!!newTime) {
                            var changed = true;
                            if (!!newTime.arr) {
                                this.stop.arr = newTime.arr;
                            }
                            
                            var timeDiff = 0;
                            if (!!newTime.dep) {
                                if (!!this.stop.dep && !!newTime.recalc) {
                                    timeDiff = newTime.dep.diff(this.stop.dep);
                                }
                                this.stop.dep = newTime.dep;
                            }
                            
                            var prevStop = this.activeSch.stops.slice().reverse()
                                    .find(it => it.pn < this.stop.pn && (!!it.arr || !!it.dep));
                            
                            //Если остановка - первая в рейсе, ищем в предыдущем рейсе последнюю остановку
                            if (!prevStop) {
                                const prevs = this.scheds.filter((_sch)=>{
                                    return (this.activeSch.depId === _sch.depId) && (this.activeSch.ordernumber > _sch.ordernumber);
                                }).sort((s1, s2)=>{
                                    return (s1.ordernumber < s2.ordernumber) ? -1 : 1;
                                });
                                const prev = (prevs.length > 0) ? prevs[prevs.length - 1] : null;
                                prevStop = !!prev ? prev.stops.slice().reverse().find(it => !!it.arr || !!it.dep) : null;
                            }
                            
                            var prevTime = null;
                            if (!!prevStop) {
                                prevTime = prevStop.dep || prevStop.arr;
                            }
                            
                            this.scheds.filter((sch, n)=>{
                               return (
                                    sch.ordernumber >= this.activeSch.ordernumber && 
                                    (
                                        !!sch.depId && sch.depId === this.activeSch.depId   ||
                                        !sch.depId && sch.linkcode === this.activeSch.linkcode
                                    )
                                );
                            })
                            .sort((s1, s2)=>{
                                return (s1.ordernumber < s2.ordernumber) ? -1 : 1;
                            })
                            .map((sch, n)=>{
                                if ((!!sch.stops) && (Array.isArray(sch.stops))) {

                                    sch.stops.filter((stop)=>{
                                        return (
                                            (stop.schedule === this.stop.schedule && stop.pn >= this.stop.pn) ||
                                            (stop.schedule !== this.stop.schedule)
                                        )
                                    })
                                    .sort((s1, s2)=>{
                                         return (s1.pn < s2.pn) ? -1 : 1;
                                     })
                                    .map((stop)=>{
                                        
                                        //Пересчет прибытия/отправления (время по галочке/переход через сутки)
                                        if (!!stop.arr && !!stop.dep) {
                                            if (!!newTime.recalc && ((stop.schedule === this.stop.schedule && stop.pn !== this.stop.pn) || stop.schedule !== this.stop.schedule)) {
                                                stop.arr = stop.arr.add(timeDiff, 'millisecond');
                                                stop.dep = stop.dep.add(timeDiff, 'millisecond');
                                                changed = true;
                                            }
                                            
                                            if (!!prevTime) {
                                                var recalc = this.recalcNewDay(prevTime, stop.arr);
                                                changed = !changed ? recalc.changed : changed;
                                                stop.arr.set('date', recalc.day);
                                                
                                                recalc = this.recalcNewDay(stop.arr, stop.dep);
                                                changed = !changed ? recalc.changed : changed;
                                                stop.dep.set('date', recalc.day);
                                                
                                                stop.newDay = prevTime.format("DD") !== stop.arr.format("DD") || stop.arr.format("DD") !== stop.dep.format("DD");
                                            }
                                            stop.changed = changed;
                                            changed = false;
                                            prevTime = stop.dep;
                                        }
                                    });
                                    this.recalcStartEndSchedule(sch);
                                }
                            });
                            //Проставить время перерыва между прибытием/отправлением на остановке/между рейсами
                            this.recalcRestTime(this.activeSch, this.stop);
                            
                            if (!!this.activeSch.depId) {
                                this.recalcStartEndDeparture(this.activeDep);
                            }
                        }
                    }
                }
            }),
            h('v-dialog', {props: {
                    width: "600px",
                    value: this.confirm.show
                }}, [
                    h('v-card', [
                        h('v-card-title', this.confirm.title),
                        h('v-card-text', this.confirm.message),
                        h('v-card-actions', [
                            h('v-spacer'),
                            this.confirm.cancel
                                ? h('v-btn', {props: {outlined: true, tile: true, color: 'gray', small: true},
                                    on: {click: () => { this.confirm.show = false;}
                                    }
                                }, 'отмена')
                                : null,
                            this.confirm.no
                                ? h('v-btn', {props: {outlined: true, tile: true, color: 'gray', small: true},
                                    on: {click: () => {
                                            switch (this.confirm.method){
                                                case 'setRequestStop':
                                                    this.setRequestStop(this.confirm.object, 0);
                                                    this.confirm.show = false;
                                                    break;
                                                default:
                                                    this.confirm.show = false;
                                                    break;
                                            }
                                        }}
                                }, 'нет')
                                : null,
                            this.confirm.yes
                                ? h('v-btn', {props: {outlined: true, tile: true, color: 'primary', small: true},
                                    on: {click: () => {
                                            switch (this.confirm.method){
                                                case 'setRequestStop':
                                                    this.setRequestStop(this.confirm.object, 1);
                                                    this.confirm.show = false;
                                                    break;
                                                case 'setMiss':
                                                    this.setMiss(this.confirm.object);
                                                    this.confirm.show = false;
                                                    break;
                                                case 'saveTotal':
                                                    this.saveTotal();
                                                    this.confirm.show = false;
                                                    break;
                                                case 'deleteVersion':
                                                    this.deleteVersion();
                                                    this.confirm.show = false;
                                                    break;
                                                default:
                                                    this.confirm.show = false;
                                                    break;
                                            }
                                        }}
                                }, 'да')
                                : null
                        ])
                    ])
                ])
        ]);
    },
    watch: {
        activeScheds(val){
            console.log('activeScheds', val);
            if ( Array.isArray(val) ){
                val.forEach( s => {
                    if ( 
                            Array.isArray(s.stops) 
                         && !s.servLoaded
                       ){
                        this.getServiceStops(s.stops);
                        s.servLoaded = true;
                    }
                });
            }
        }
    }
};
</script>

<style lang="scss">
    @import '~vuetify/src/styles/settings/_colors.scss';

    .sch-routs-place{
        width: 70rem;
        min-width: 50rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        &__legend{
            font-size: 0.7rem;
            margin: 0 1rem;
        }
    }
    .mt-shedules{
        height: calc(100vh - 208px);
        & .row{
            height: 100%;
        }
        & .sch-deps{
            overflow: auto;
            height: 100%;
        }
        & .sch-dep{
            & .v-card__text{
                padding: 0 16px 16px 0;
            }
            & .v-badge__badge{
                border: 1px solid #fff !important;
            }
            &__num{
                border: 1px solid #fff;
                padding: 0.125rem 0.25rem;
                background: #9bafb8;
                border-radius: 4px 0;
                min-width: 4rem;
                box-shadow: 0 2px 4px rgba(0,0,0,0.18);
                color: #fff;
                align-self: flex-start;
                &:hover{
                        background: var(--v-accent-lighten2) !important;
                    }
            }
            &__times{
                padding: 0.125rem 0;
                line-height: 1.115;
            }
            &__modes{
                display: flex;
                justify-content: space-between;
                padding: 16px 0 0 16px;
            }
            &__active{
                background-color: var(--v-primary-base) !important;
                & .v-card__text {
                    color: #fff !important;
                }
            }
            &__warn{
                padding: 16px 0 0 16px;
                font-size: 0.8rem;
                color: var(--v-warning-base) !important;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                & svg{vertical-align: middle;margin-right: 0.5rem;}
            }
            &__tt{
                text-align: right;
                font-size: 0.85rem;
            }
        } /*sch-dep*/
        & .sch-schedules{
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            overflow: auto;
            scrollbar-width: thin;
            align-self: flex-start;
            height: 100%;
            padding: 1rem;
            & .v-toolbar{
                top: 50px;
                width: 100%;
                .v-toolbar__title {
                    font-size: 0.9rem;
                    line-height: 1;
                }
            }
            & .sch-schedule{
                min-width: 20rem;
                margin-right: 1rem;
                margin-top: 54px;
                & .v-card__text {
                    margin-top: 16px;
                    height: calc(100% - 16px);
                    padding: 0 0 0 8px;
                    overflow: auto;
                }
                & .v-list{
                    padding: 0; 
                    
                    & .v-list-item{
                        padding-left: 0.5em;
                        padding-right: 0.5em;
                        
                        &__icon{
                            &:first-child{
                                margin-right: 0;
                            }
                        }
                        &__content{
                            display: flex;
                            & > *{
                                flex: 1 0 auto;
                            }
                            padding-bottom: 0;
                        }
                        & .sch-stop{
                            &__time{
                                width: 2rem;
                                margin-right: 0.5rem;
                                white-space: normal;
                                padding: 0.25rem 0.5rem;
                                cursor: pointer;
                                text-align: center;
                                border-radius: 4px;
                                & .v-badge{
                                    &__badge{color: #000;}
                                }
                            }
                            &__name{
                                width: calc(100% - 5rem);
                                white-space: normal;
                                overflow: hidden;
                            }
                            &__marks{
                                max-width: 1.5rem;
                            }
                            &__order{
                                margin: 0 auto;
                                align-self: center;
                            }
                        }
                        &.sch-modes, &.sch-time{
                            .v-list-item__content{
                                flex-direction: column;
                                align-items: flex-start;
                                padding-left: 0.5rem;
                                padding-top: 0;
                            }
                        }
                    }
                }

                &__title{
                    position: absolute;
                    top: -32px;
                    margin: 0 auto;
                    width: calc(100% - 16px);
                    display: flex;
                    border: 1px solid #ccc;
                    border-radius: 4px;
                    padding: 0.125rem 0.25rem;
                    justify-content: space-between;
                    background: #fff;
                    box-shadow: 0 2px 4px rgba(0,0,0,0.18);
                    max-height: 2.1em;
                    
                    &:hover{
                        background: var(--v-accent-lighten2) !important;
                    }
                
                    & .v-input{
                        max-width: 5em;
                        min-width: 5em;
                        
                        &__control {
                            max-height: 1.2em;
                            min-height: 1.2em;
                            
                            & .v-input__slot{
                                max-height: 1.2em;
                                min-height: 1.2em;
                                padding: 0.1em;
                                background-color: transparent;
                            }
                            input {
                                font-weight: 500;
                            }
                        }
                    }
                    & .ordernumberInput{
                        max-width: 2em;
                        min-width: 2em;
                    }
                }
                &.need-15{
                    & .sch-schedule__title{
                        background-color: var(--v-warning-lighten5);
                    }
                }
                &.need-45{
                    & .sch-schedule__title{
                        background-color: var(--v-warning-lighten1);
                    }
                }

            }
        }
    }
    .menu-item{
        min-height: 2em;
        font-size: 1rem;
    }
</style>
