<script>
export default{
    props: {
        'owner': {
            type: Object,
            required: true
        }
    },
    data: () => ({
        /*params*/
        startDt: null,
        endDt: null,
        carrier: null,
        /*params filters*/
        f_vehicle_carrier_not_null: null,
        f_vehicle_carrier_null: null,
        f_carrier: null
    }),
    created(){
        console.log('10.22 created by', this.owner);
        
        this._setInitialParams();
        this._initingFilters();
        this._setDates();
        this.owner.on_set = this._set;
    },
    methods: {
        _setInitialParams(){
            var comp = this.owner.$refs["startDt"];
            this.startDt = this._setDateFormat(comp.value);
            comp = this.owner.$refs["endDt"];
            this.endDt = this._setDateFormat(comp.value);
        },
        _completingFilters(param){
            param = param.replaceAll(`param_carrier`, this.carrier)
                        .replaceAll(`param_startDt`, this.startDt)
                        .replaceAll(`param_endDt`, this.endDt);
            return param;
        },
        _setDateFormat(param){
            var result = null;
            if (param !== null) {
                result = $utils.formatDate(new Date(param), 'DD-MM-YYYY HH:mm:ss');
            }
            return result;
        },
        _set(param){
            console.log('10.22.set: ' + param.name + ' ', param.value);
            switch (param.name){
                case "carrier":
                    this.carrier = param.value;
                    this._setCarrier();
                    break;
                case "startDt":
                    this.startDt = this._setDateFormat(param.value);
                    this._setDates();
                    break;
                case "endDt":
                    this.endDt = this._setDateFormat(param.value);
                    this._setDates();
                    break;
            }
        },
        _setDates(){
            if (!$utils.isEmpty(this.startDt) && !$utils.isEmpty(this.endDt)) {
                var comp = this.owner.$refs["carrier"];
                var filter = this._completingFilters(this.f_carrier);
                comp.setFilter(filter);
                this._setCarrier();
            }
        },
        _setCarrier(){
            if (!$utils.isEmpty(this.startDt) && !$utils.isEmpty(this.endDt)) {
                var comp = this.owner.$refs["vehicle"];
                var filter = '';
                if (!$utils.isEmpty(this.carrier)){
                    filter = this._completingFilters(this.f_vehicle_carrier_not_null);
                } else {
                    filter = this._completingFilters(this.f_vehicle_carrier_null);
                }
                comp.setFilter(filter);
            }
        },
        _initingFilters(){
            this.f_vehicle_carrier_not_null = 
                    `and(
                        eq(field(\".crrID\"),param(\"param_carrier\", \"id\")),
                        or(
                            isnull(field(\".endDt\")),
                            gte(field(\".endDt\"),param(\"param_startDt\", \"date\"))
                        ),
                        lte(field(\".startDt\"),param(\"param_endDt\", \"date\"))
                    )`;
            this.f_vehicle_carrier_null = 
                    `and(
                        or(
                            isnull(field(\".endDt\")),
                            gte(field(\".endDt\"),param(\"param_startDt\", \"date\"))
                        ),
                        lte(field(\".startDt\"),param(\"param_endDt\", \"date\"))
                    )`;
            this.f_carrier = 
                    `and(
                        or(
                            isnull(field(\".endDt\")),
                            gte(field(\".endDt\"),param(\"param_startDt\", \"date\"))
                        ),
                        lte(field(\".startDt\"),param(\"param_endDt\", \"date\"))
                    )`;
        }
    },
    render(h){
        h('i',{class: "rep-stub"});
    }
}
</script>