<template>
    <v-dialog v-model="show" max-width="500px">
        <v-card v-if="(!!editedFail)">
            <v-card-title>
              <span class="headline">{{ editedFail.t_name }}</span>
            </v-card-title>
            <v-card-text>
                <v-container>
                    <v-row>
                        <v-col cols="12">
                            <v-combobox item-value="vcviolationreasons.id" dense item-text="vcviolationreasons.name" v-model="editedFail.reasId" :items="reasons" label="Причина нарушения"></v-combobox>
                        </v-col>
                        <v-col cols="12">
                            <v-text-field v-model="editedFail.comment" dense label="Примечание"></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <v-checkbox v-model="editedFail.all" dense label="Проставить по всем нарушениям ТС"></v-checkbox>
                        </v-col>
                        <v-col cols="12">
                            <v-text-field v-model="editedFail.result" dense label="Результат"></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <JetInputBlob v-model="editedFail.file" dense label="Прикрепить файл"></JetInputBlob>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" text @click="close">Закрыть</v-btn>
                <v-btn color="primary" text @click="save">Сохранить</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>

import JetInputBlob from '@/components/editors/JetInputBlob';

export default {
    name: 'DspFailComment',
    props: ['fail'],
    components: {
      JetInputBlob
    },
    data: () => ({
      show: false,
      editedFail: {},
      reasons: [],
      file: {},
    }),
    created: function() {
      var self = this;
      //load a Reasons list
      jet.http.post('/rpc?d=jsonRpc', {
        type: 'core-read',
        query: 'sin2:/v:478c258e-78ed-4c8e-b008-001ab813b349/?sort=name',
      }).then(function(data) {
        if (!data.result) {
          return; //TODO: err handle
        }
        var ci = data.result.columnIndexes,
          rows = [];
        for (var i = 0, n = data.result.data.length; i < n; i++) {
          var raw = data.result.data[i],
            row = {};
          for (var columnId in ci) {
            if (ci.hasOwnProperty(columnId)) {
              var index = ci[columnId];
              row[columnId] = raw[index];
            }
          }
          rows.push(row);
        }
        self.reasons = rows;
      }, function(err) {
        console.log('Reasons ERR', err); //TODO: err handle
      });
    },
    watch: {
      fail: function(val) {
        if (!val) {
          this.editedFail = null;
          this.show = false;
          return;
        }
        var self = this;
        jet.http.post('/rpc?d=jsonRpc', {
          type: 'core-read',
          query: 'sin2:/v:f94c06be-0920-4872-907b-414f64de0224/?id=' + val.id,
        }).then(function(data) {
          if ((data.result) && (data.result.data.length > 0)) {
            var ci = data.result.columnIndexes,
              row = data.result.data[0];
            self.editedFail = {
              id: row[ci['vctripscontrol.id']],
              reasId: self.getReasson(row[ci['vctripscontrol.violationreasonid']]),
              all: row[ci['vctripscontrol.settoall']],
              result: row[ci['vctripscontrol.result']],
              comment: row[ci['vctripscontrol.comment']],
              file: row[ci['vctripscontrol.file']],
            };
            self.file = self.editedFail.file;
            self.show = true;
          } else {
            jet.msg({ text: 'Ошибка чтения нарушения - попробуйте вызвать еще раз', color: 'warning', timeout: 6000 });
          }
        });
      }
    },
    methods: {
      getReasson: function(byId) {
        if (this.reasons) {
          for (var i = 0; i < this.reasons.length; i++) {
            if (this.reasons[i]['vcviolationreasons.id'] === byId) {
              return this.reasons[i];
            }
          }
        }
        return null;
      },
      close: function() {
        this.show = false;
        this.fail = null;
      },
      save: function() {
        var self = this,
          params = [
            { id: 'vctripscontrol.id', type: 'id', value: this.editedFail.id },
            {
              id: 'vctripscontrol.violationreasonid',
              type: 'id',
              value: this.editedFail.reasId['vcviolationreasons.id'],
            },
            { id: 'vctripscontrol.settoall', type: 'boolean', value: (this.editedFail.all) ? true : false },
            {
              id: 'vctripscontrol.result',
              type: 'string',
              value: (this.editedFail.result) ? this.editedFail.result : null,
            },
            {
              id: 'vctripscontrol.comment',
              type: 'string',
              value: (this.editedFail.comment) ? this.editedFail.comment : null,
            }
          ];
          
          //Без этого блока файл затирается, если нажать "Сохранить" без изменения
          if (
            (!this.file && this.editedFail.file.value) || // Добавление файла
            (this.file && this.file.id && !this.editedFail.file.id && !this.editedFail.file.name) || // Удаление файла
            (this.file && this.file.id && !this.editedFail.file.id && this.editedFail.file.name) // Замена файлаа
          ) {
            params.push ({
              id: 'file',
              type: 'blob',
              value: this.editedFail.file.value,
            });
            params.push ({
              id: 'filename',
              value: this.editedFail.file.name,
              type: 'string',
            });
          }

        jet.http.post('/rpc?d=jsonRpc', {
          type: 'core-update',
          query: 'sin2:/v:c3c6dc26-089a-4ce2-a8dd-f1d5a6e14051/',
          params: params,
        }).then(function(data) {
          if (data.result) {
            self.editedFail = null;
            self.show = false;
            jet.msg({ text: 'Запись сохранена успешно', color: 'primary', timeout: 6000 });
          } else {
            jet.msg({ text: 'Ошибка сохранения записи - попробуйте сохранить еще раз', color: 'warning', timeout: 6000 });
          }
        }, function(err) {
          console.log('ERR: reasons save', err); //TODO: err handle
          jet.msg({ text: 'Ошибка сохранения записи - попробуйте сохранить еще раз', color: 'warning', timeout: 6000 });
        });
      },
    },
};  

</script>