<template>
  <v-dialog
    v-model="dialog"
    max-width="900"
    content-class="map-layers"
    @click.close="cancel">
    <template #activator="{ on }">
      <v-btn
        fab small dark
        color="primary"
        elevation="2"
        v-on="on"
      >
        <v-badge
          overlap
          color="green"
          offset-y="8"
          offset-x="4"
          v-if="drawCount"
          :content="drawCount"
        >
          <v-icon color="white">
            mdi-layers-outline
          </v-icon>
        </v-badge>

        <v-icon v-else color="white">
          mdi-layers-outline
        </v-icon>
      </v-btn>
    </template>

    <v-card>
      <v-toolbar flat>
        <v-toolbar-title>
          Слои на карте
        </v-toolbar-title>

        <v-spacer/>

        <v-btn icon @click="cancel">
          <v-icon color="grey">
            mdi-close
          </v-icon>
        </v-btn>
      </v-toolbar>

      <v-divider/>

      <v-card-text class="pa-4">
        <v-tabs v-model="tab">
          <!-- Маршруты -->
          <MapLayerTab
            title="Маршруты"
            :checked="newLength('itineraries')"
            :active="checkedByType('itineraries')"
          ></MapLayerTab>

          <!-- Остановочные пункты -->
          <MapLayerTab
            title="Остановочные пункты"
            :checked="newLength('stops')"
            :active="checkedByType('stops')"
          ></MapLayerTab>

          <!-- Геозоны -->
          <MapLayerTab
            title="Геозоны"
            :checked="newLength('geozones')"
            :active="checkedByType('geozones')"
          ></MapLayerTab>

          <!-- Объекты маршрута -->
          <MapLayerTab
            title="Объекты маршрута"
            :checked="newLength('routeObjects')"
            :active="checkedByType('routeObjects')"
          ></MapLayerTab>
          <v-tab>Адреса</v-tab>
          
        </v-tabs>

        <v-divider/>

        <v-tabs-items v-model="tab">
          <!-- Маршруты -->
          <v-tab-item>
            <RoutesMapLayer
              v-model="usedData.itineraries"
              :need-cancel="needCancel"
            ></RoutesMapLayer>
          </v-tab-item>

          <!-- Остановочные пункты -->
          <v-tab-item>
            <StopsMapLayer
              v-model="usedData.stops"
              :need-cancel="needCancel"
            ></StopsMapLayer>
          </v-tab-item>

          <!-- Геозоны -->
          <v-tab-item>
            <GeoZonesMapLayer
              v-model="usedData.geozones"
              :need-cancel="needCancel"
            ></GeoZonesMapLayer>
          </v-tab-item>

          <!-- Объекты маршрута -->
          <v-tab-item>
            <RouteObjectsMapLayer
              v-model="usedData.routeObjects"
            ></RouteObjectsMapLayer>
          </v-tab-item>
          <v-tab-item>
              <MapAdresses v-on:hide="dialog=false" 
                           :active="tab===4"/>
          </v-tab-item>
          
        </v-tabs-items>
      </v-card-text>

      <v-divider/>

      <v-card-actions class="px-3">
        <v-btn
          dark
          depressed
          class="body-2"
          color="red lighten-1"
          @click="reset"
        >
          Очистить слои
        </v-btn>

        <v-spacer/>

        <v-btn
          outlined
          class="body-2"
          color="red lighten-1"
          @click="cancel"
        >
          Отменить
        </v-btn>

        <v-btn
          color="primary"
          class="body-2"
          @click="apply"
        >
          <span v-if="checkedCount">
            Показать
            <span class="ml-2">
              {{ checkedCount }}
            </span>
          </span>

          <span v-else>Применить</span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import MapLayerTab from './MapLayerTab';

// Layers
import RoutesMapLayer from './layers/RoutesMapLayer';
import StopsMapLayer from './layers/StopsMapLayer';
import GeoZonesMapLayer from './layers/GeoZonesMapLayer';
import RouteObjectsMapLayer from './layers/RouteObjectsMapLayer';
import MapAdresses from './layers/MapAdresses';
export default {
  components: {
    MapLayerTab,

    RoutesMapLayer, StopsMapLayer,
    GeoZonesMapLayer, RouteObjectsMapLayer,
    MapAdresses
  },
  props: {
    // Набор дефолтных значений параметров
    defaultData: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    // Свойство, передаваемое через v-model
    value: {
      type: Object,
      required: true,
    },
  },
  watch: {
    // Сброс состояния последних изменений при закрытии попапа
    dialog(newVal) {
      this.usedData = _copy(this.value);
/*      
      if (newVal) {
        this.tab = 0;
      }
*/                        
    },
  },
  name: 'MapLayers',
  created() {
    // Применение дефолтных настроек для компонента
    // Если дефолтных нет - берутся значения модели
    this.defaultObject = _copy(
      _emptyObject(this.defaultData) ? this.value : this.defaultData,
    );

    this.usedData = _copy(this.defaultObject);
  },
  data: () => ({
    // Текущая вкладка
    tab: 0,
    // Индикатор для диалога
    dialog: false,
    // объект для хранения дефолтных значений параметров
    defaultObject: null,
    // выбранные параметры
    usedData: null,
    // клон usedData, используемый для отката изменений
    tempData: null,
    // Флаг что необходимо просто закрыть без изменений
    needCancel: false,
  }),
  methods: {
    /**
     * Вычисление новых выбранных слоев во вкладке
     *
     * @param {String} type
     * @return {Number}
     */
    newLength(type) {
      return this.usedData[type].length - this.checkedByType(type);
    },
    /**
     * Возвращает количество выбранных элементов в определенном состоянии
     *
     * @param {String} type - ключ вкладки
     * @return {Number}
     */
    checkedByType: function (type) {
      return this.value[type].length;
    },
    // Сброс данных до дефолтных
    reset: function () {
      this.closePopup();
      this.$emit('input', this.defaultObject);
      this.needCancel = !this.needCancel;
      // Эмитим событие обнуления данных
      this.$emit('onSubmit', this.defaultObject);
      this.updateModel();
    },
    // Отмена последних изменений
    cancel: function () {
      this.needCancel = !this.needCancel;

      this.closePopup();
      this.updateModel();
    },
    // Подтверждение выбора
    apply: function () {
      console.log('Map-Layers-apply');
      
      this.closePopup();
      console.log('this.usedData', this.usedData);
      this.$emit('input', this.usedData);
      // Эмитим событие изменения данных
      this.$emit('onSubmit', this.usedData);
      this.updateModel();
    },
    // Обновление данных компонента в соответствие с актуальными данными
    updateModel() {
      this.usedData = _copy(this.value);
    },
    closePopup() {
      this.dialog = false;
    },
  },
  computed: {
    // Показывает количество выбранных для показа в диалоге слоев
    checkedCount: function () {
      let count = 0;

      Object.keys(this.usedData).forEach(key => {
        count += this.usedData[key].length;
      });

      return count;
    },
    // Рисуем количество слоев, которые выбраны к отображению
    drawCount() {
      let count = 0;

      Object.keys(this.value).forEach(key => {
        count += this.value[key].length;
      });

      return count;
    },
  },
};
</script>

<style lang="scss">
    .map-layers{
        min-height: 480px;
    }
</style>
