<script>
import JetSvg from '@/components/JetSvg';

export default {
  name: 'JetConfirm',
  components: {
    JetSvg
  },
  data: () => ({
    title: null,
    // info, warning, error
    type: null,
    msg: '',
    show: false,
    help: null,
    // Promise.resolve(): see confirm()
    p: null,
  }),
  methods: {
    /*
     * show confirm-dialog:
     * @param {type} msg Object {?type: info,warning,etc..., ?title: head, ?help:, msg: text }
     */
    confirm(msg) {
      if ((typeof msg === 'string') || (msg instanceof String)) {
        this.title = null;
        this.help = null;
        this.type = null;
        this.msg = msg;
      } else {
        this.title = msg.title;
        this.msg = msg.msg;
        this.type = msg.type;
        this.help = msg.help;
      }
      return new Promise(resolve => {
        this.p = resolve;

        this.show = (new Date()).getTime();
      });
    }
  },
  render(h) {
    const self = this;

    return h('v-dialog', {
        props: {
          value: this.show,
          'max-width': 720
        },
      },
      (!!this.show) ? [
        h('v-card', {
          class: {
            'jet-confirm': true,
          }
        }, [
          $utils.isEmpty(this.title)
            ? null
            : h('v-card-title', this.title),
          h('v-card-text',
            $utils.isEmpty(this.type)
              ? this.msg
              : [h('v-alert', {
                props: {
                  type: this.type,
                  border: 'left',
                  'colored-border': true,
                  elevation: 2,
                }
              }, this.msg)]
          ),
          h('v-card-actions', [
            $utils.isEmpty(this.help)
              ? null
              : h('div', {class: {'text--secondary': true}}, [
                h('jet-svg', {props: {xref: '#ico-question', width: 14, height: 14}}),
                this.help
              ]),
            h('v-spacer'),
            h('v-btn', {
              props: {
                small: true,
                tile: true
              },
              class: {
                'jet-cancel': true,
              },
              on: {
                click: () => {
                  if (!!self.p) {
                    self.p(false);
                  }

                  this.show = false;
                }
              }
            }, 'отмена'),
            h('v-btn', {
              props: {
                color: 'primary',
                small: true,
                tile: true
              },
              class: {
                'jet-ok': true,
              },
              on: {
                click: () => {
                  if (!!self.p) {
                    self.p(true);
                  }

                  this.show = false;
                }
              }
            }, [
              h('jet-svg', {
                props: {
                  xref: '#ico-chk',
                  width: 16,
                  height: 16
                }
              }),
              'ok'
            ])
          ])
        ])
      ] : null
    );
  }
}
</script>

<style lang="scss" scoped>
  .jet-confirm {
    & .jet-cancel, & .jet-ok {
      width: 6rem;
    }

    & svg {
      margin-right: 0.5rem;
    }
  }
</style>
