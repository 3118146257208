<script>
import JetInputBlob from '@/components/editors/JetInputBlob';
import JetInputBoolean from '@/components/editors/JetInputBoolean';
import JetInputDate from '@/components/editors/JetInputDate';
import JetInputFloat from '@/components/editors/JetInputFloat';
import JetInputId from '@/components/editors/JetInputId';
import JetInputInteger from '@/components/editors/JetInputInteger';
import JetInputString from '@/components/editors/JetInputString';
import JetInputPassword from '@/components/editors/JetInputPassword';
    
const KNOWN_COMPS = {
    'blob': JetInputBlob,
    'boolean': JetInputBoolean,
    'date': JetInputDate,
    'dateTime': JetInputDate,
    'datetime': JetInputDate,
    'float': JetInputFloat,
    'id': JetInputId,
    'string':  JetInputString,
    'integer':  JetInputInteger,
    'long':  JetInputInteger,
    'password': JetInputPassword
};

export default {
    name: 'JetInput',
    functional: true,
    props: {
            type: {
              type: String,
              required: true,
              validator(value) {
                return [
                  'blob',
                  'boolean',
                  'date',
                  'dateTime',
                  'datetime',
                  'float',
                  'id',
                  'string',
                  'integer',
                  'password',
                  'long'
                ].indexOf(value) !== -1;
              }
            },
            label: {
              type: String,
              default: ''
            },
            placeholder: {
              type: String,
              default: ''
            },
            maxLength: {
              type: Number,
              default: 0
            },
            disabled: {
              type: Boolean,
              default: false
            },
            required: {
              type: Boolean,
              default: false
            },
            hidden: {
              type: Boolean,
              default: false
            },
            multiline: {
              type: Boolean,
              default: false
            },
            "hide-details": {
              type: Boolean,
              default: false
            },
            uri: {
              type: String,
              default: null
            }
    },
    render: function( h, ctx ){
        const {props, data, children} = ctx;
        Object.assign(data, {props});
        return h(KNOWN_COMPS[props.type], data, children);
    }
};

</script>