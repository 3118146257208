<template>
  <v-layout fill-height>
    <v-flex
      shrink fill-height
      style="width: 400px"
      class="elevation-3"
    >
      <v-tabs-items class="fill-height" v-model="tab">
        <!-- Просмотр геозон -->
        <v-tab-item class="fill-height">
          <v-layout column fill-height>
            <!-- Поиск -->
            <v-flex shrink>
              <div class="pa-4 mb-4">
                <v-text-field
                  label="Поиск по геозонам"
                  placeholder="Введите название геозоны"
                  v-model="searchText"
                  hide-details
                  :disabled="!loaded"
                ></v-text-field>
              </div>
            </v-flex>

            <!-- Список -->
            <v-flex style="height: 100px;overflow: auto;">
              <!-- Список геозон -->
              <v-list v-if="loaded">
                <template v-for="item in filteredList">
                  <GeozoneItem
                    v-model="visibleGeoZones"
                    :item="item"
                    :loading="removing"
                    :key="`${item.id}_${item.name}`"
                    :visible="visibleGeoZones.findIndex(it => it.id === item.id) > -1"
                    @onRemove="removeGeoZone"
                    @onEdit="editGeoZone"
                    @onShow="showGeoZone"
                  ></GeozoneItem>

                  <v-divider :key="`${item.id}_${item.name}_divider`" class="ma-0 mx-4"/>
                </template>
              </v-list>

              <!-- Индикатор загрузки -->
              <div v-else class="pa-4">
                <v-progress-circular
                  indeterminate
                  color="primary"
                ></v-progress-circular>
              </div>

              <!-- Диалог на изменения -->
              <v-dialog v-model="apply_edit_dialog" width="600">
                <v-card>
                  <v-card-title>Изменения не сохранены</v-card-title>

                  <v-divider/>

                  <v-card-text class="pt-4 body-1">
                    Вы не сохранили ранее измененные данные. Подтвердите, если хотите отменить изменения
                  </v-card-text>

                  <v-divider/>

                  <v-card-actions>
                    <v-btn
                      @click="apply_edit_dialog = false"
                      color="red"
                      outlined
                    >
                      Отмена
                    </v-btn>

                    <v-spacer/>

                    <v-btn
                      @click="applyCancel"
                      color="primary"
                      dark
                    >
                      Подтвердить
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>

              <!-- Оповещение -->
              <v-snackbar
                v-model="snackbar"
                bottom
                left
                color="primary"
                v-if="snackbar_text"
              >
                {{ snackbar_text }}

                <v-btn dark text @click="snackbar = false">
                  Ok
                </v-btn>
              </v-snackbar>
            </v-flex>

            <!-- Нижний блок с кнопками -->
            <v-flex v-if="!showEditBlock" shrink class="pa-4 text-right">
              <v-btn
                @click="selectAll"
                color="primary"
                :disabled="!loaded"
              >
                {{ showAll ? 'Выбрать все' : 'Убрать все' }} 
              </v-btn>
              <v-divider vertical/>
              <v-btn
                @click="addGeoZone"
                color="primary"
                :disabled="!loaded"
              >
                <v-icon>mdi-plus</v-icon>
                Добавить
              </v-btn>
            </v-flex>
          </v-layout>
        </v-tab-item>

        <!-- Редактирование геозоны -->
        <v-tab-item class="fill-height">
          <GeozoneEdit
            v-if="geozone_object"
            v-model="geozone_object"
            :loading="saving"
            :disable-types="disableTypes"
            @onCancel="cancelEditor"
            @onSubmit="submitGeoZone"
            @onChangeProperties="onChangeProperties"
          ></GeozoneEdit>
        </v-tab-item>
      </v-tabs-items>
    </v-flex>

    <!-- Карта -->
    <v-flex fill-height style="position:relative; width: 100px">
      <div id="geo_zone_map" class="fill-height" style="position: relative;"/>

      <div id="geoZoneMapControlDelete" class="map_control--delete">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn fab small v-on="on">
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </template>

          <span>Очистка карты</span>
        </v-tooltip>
      </div>
    </v-flex>
  </v-layout>
</template>

<script>
// Компоненты
import GeozoneItem from './components/GeozoneItem';
import GeozoneEdit from './components/GeozoneEdit';

// Сервисы
import MapLayersService from '@/services/MapLayersService';

// Утилиты
import Map from '@/utils/map';

// Mixins
import HideGlobalToolbarMixin from '@/components/dev/mixin/HideGlobalToolbarMixin';

export default {
  mixins: [
    HideGlobalToolbarMixin,
  ],
  name: 'Geozones',
  components: {
    GeozoneEdit,
    GeozoneItem,
  },
  data: () => ({
    tab: 0,
    overlay: true,
    loaded: false,
    saving: false,
    removing: false,
    geoZones: [],
    searchText: '',
    geozone_object: null,
    showEditBlock: false,
    temp_geozone_object: null,
    apply_edit_dialog: false,
    snackbar: false,
    snackbar_text: '',
    visibleGeoZones: [],
    disableTypes: false,
    showAll: true,
    // Карта проинициализорана
    mapInitialized: false,
  }),
  watch: {
    // Изменение списка отображаемых геозон
    visibleGeoZones(val) {
      Map.drawGeoZones(
        val.filter(it => !!(it.polygonText || it.polygontext))
          .map(it => MapLayersService.parseWkx(it))
      );
    },
  },
  mounted() {
    $('.jet-collection-master').height('100%');
  },
  created () {

    // setTimeout(() => {
    //   const elem = document.querySelector('.v-window-item--active .jet-collection-conte');
    //
    //   if (elem) {
    //     elem.style.height = 'calc(100% - 117px)';
    //   }
    // }, 1);

    Map.subscribe('draw.created', () => {
      Map.applyGeoZoneParams(_copy(this.geozone_object));
    });

    Map.subscribe('draw.updated', (data) => {
      this.disableTypes = (data.features || []).length > 0;
      this.geozone_object.__features = _copy(data);
    });

    Map.subscribe('circle_radius_changed', (radius) => {
      this.geozone_object.radius = radius;
    });

    Map.subscribe('circle_center_changed', (center) => {
      this.geozone_object.__center = center;
    });

    this.fetchData();
  },
  destroyed() {
    this.mapInitialized = false;

    $('.jet-collection-master').height('');
  },
  methods: {
    showGeoZone(item) {
      const index = this.visibleGeoZones.findIndex(it => it.id === item.id);
      if (index === -1) {
        this.visibleGeoZones.push(
          _copy(this.geoZones[this.geoZones.findIndex(it => it.id === item.id)])
        );
      } else {
        this.visibleGeoZones.splice(index, 1);
      }
      
      if (this.visibleGeoZones.length === 0) {
        this.showAll = true;
      } else if (this.visibleGeoZones.length === this.geoZones.length) {
        this.showAll = false;
      } else {
        this.showAll = true;
      }
    },
    // Получение данных
    fetchData () {
      return MapLayersService.getGeoZones()
        .then(response => {
          this.geoZones = _copy(response);

          if (!this.mapInitialized) {
            Map.init({
              container: document.getElementById('geo_zone_map'),
            }, true);

            this.mapInitialized = true;
          }
        })
        .catch(err => console.error('GeoZones::created', err))
        .finally(() => {
          this.loaded = true;
        });
    },
    // Изменение параметров геозоны
    onChangeProperties (item) {
      if (item.type === 'LINE' && (!_hasOwnProperty(item, 'width') || item.width === null)) {
        item.width = 1;
      }

      Map.applyGeoZoneParams(_copy(item));
    },
    // Редактирование геозоны
    editGeoZone (item) {
      let color = this.colorFromInt(item.color);

      if (color.r < 255 && color.g < 255 && color.b < 255) {
        color = {
          r: 255,
          g: 0,
          b: 0,
        };
      }

      this.tab = 1;
      this.geozone_object = _copy(item);
      this.geozone_object.color = color;
      this.geozone_object.startDate = (new Date(this.geozone_object.startDate)).toISOString().substring(0, 10);
      this.geozone_object.endDate = this.geozone_object.endDate !== null
        ? (new Date(this.geozone_object.endDate)).toISOString().substring(0, 10)
        : null;

      this.showEditBlock = true;
      this.temp_geozone_object = null;
      this.disableTypes = true;

      // Скрываем первончалаьную гео зону
      Map.visibleEditedGeoZone(_copy(item), false);
    },
    // Отмена редактирования
    applyCancel(item) {
      // Отменяем все изменения геозоны, в редактор отправляем новую геозону
      this.apply_edit_dialog = false;
      this.geozone_object = null;

      // Вернем старую геозону TODO: если была в списке
      Map.visibleEditedGeoZone(_copy(item), true);

      this.editGeoZone(this.temp_geozone_object);
    },
    // Удаление геозоны
    removeGeoZone (item) {
      let index = this.findIndex(item);

      if (index !== -1) {
        this.removing = true;
        this.snackbar_text = `Геозона "${item.name}" успешно удалена`;

        MapLayersService.removeGeoZone(item.id)
          .then(() => {
            // Если открыт редактор для удаляемой геозоны - закрываем его
            if (this.geozone_object && this.geozone_object.id === item.id) {
              this.cancelEditor();
            }

            const vIndex = this.visibleGeoZones.findIndex(removed => {
              return removed.id === item.id;
            });

            // Убираем из списка видимых геозон
            if (vIndex !== -1) {
              this.visibleGeoZones.splice(vIndex, 1);
            }
            // Удаляем геозону из списка
            this.geoZones.splice(index, 1);
            // Убираем лоадер удаления
            this.removing = false;
            // Показываем сообщение об удалении
            this.snackbar = true;
          })
      }
    },
    // Добавление геозоны
    addGeoZone () {
      // Подготавливаем новый объект для создания
      this.geozone_object = {
        title: '',
        description: '',
        date_from: null,
        date_to: null,
        color: '#FF0000',
        center: [],
        paint_settings: {
          type: 1,
          radius: 10,
          width: 10,
          polygon: [],
        },
      };
      this.disableTypes = false;

      this.showEditBlock = true;
      this.tab = 1;
    },
    selectAll () {
      if (this.showAll){
          
        this.geoZones.map(item => {
          const index = this.visibleGeoZones.findIndex(it => it.id === item.id);
          if (index === -1) {
            this.visibleGeoZones.push(
              _copy(this.geoZones[this.geoZones.findIndex(it => it.id === item.id)])
            );
          }
        });
        
      } else {
          
        this.geoZones.map(item => {
          const index = this.visibleGeoZones.findIndex(it => it.id === item.id);
            
          if (index !== -1) {
            this.visibleGeoZones.splice(index, 1);
          }
        });
      }
      this.showAll = !this.showAll;
    },
    // Обнуляем редактируемый объект, закрываем блок редактирования
    cancelEditor (item = null) {
      this.geozone_object = null;
      this.showEditBlock = false;
      this.tab = 0;

      if (item !== null) {
        // Показываем старую гео зону, поскольку изменения были отменены
        Map.visibleEditedGeoZone(_copy(item), true);
      }
    },
    // Сохранение геозоны
    submitGeoZone (item) {
      item = _copy(item);
      this.saving = true;
      this.snackbar_text = `Геозона "${item.name}" успешно сохранена`;

      MapLayersService.updateOrCreateGeoZone(item, item.id || null)
        .then(() => {
          Map.removeAll();
          Map.removeControl();

          const index = this.visibleGeoZones.findIndex(it => it.id === item.id);
          const itemId = item.id || null;

          if (index !== -1) {
            this.visibleGeoZones.splice(index, 1);
          }

          this.fetchData()
            .then(() => {
              this.saving = false;
              this.snackbar = true;
              this.tab = 0;

              // Закрываем блок редактирования
              this.cancelEditor();

              if (index !== -1) {
                this.visibleGeoZones.push(
                  _copy(this.geoZones[this.geoZones.findIndex(it => it.id === itemId)])
                );
              }
            });
        });
    },
    findIndex(item) {
      return this.geoZones.findIndex(it => it.id === item.id);
    },
    // Преобразование числа в цвет
    colorFromInt(color, alpha) {
      const blue = color & 255;
      const green = (color >> 8) & 255;
      const red = (color >> 16) & 255;

      return {
        r: red,
        b: blue,
        g: green,
        a: alpha,
      };
    },
    hexColorFromInt (color) {
      return '#' + (color >>> 0).toString(16).slice(-6);
    }
  },
  computed: {
    // Фильтруем список геозон по введенной строке
    filteredList() {
      return this.geoZones.filter(it => {
        const re = new RegExp(this.searchText, 'i');

        return re.test(it.name);
      });
  },
  },
}
</script>

<style scoped>
  .map_control--delete {
    display: none;

    position: absolute;

    top: 20px;
    right: 20px;
  }
</style>
