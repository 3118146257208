const period = {
    begin: $utils.getMonthBegin(),
    end: $utils.getMonthEnd(),
    current: new Date().setHours(0, 0, 0, 0)
};

const state = {
    visible: false,
    type: null,
    period: $utils.readLocalStorage('period', 'period') || period
};

const mutations = {
    set(state, period) {
        state.period.begin = period.begin;
        state.period.end = period.end;
        state.period.current = period.current;
        state.period.mode = period.mode;
        $utils.saveLocalStorage('period', {period: state.period});
    }
};
const actions = {};
const getters = {
    begin(state) {
        const date = new Date(state.period.begin);
        return new Date(date.setHours(0, 0, 0, 0));
    },
    end(state) {
        const date = new Date(state.period.end);
        return new Date(date.setHours(24, 0, 0, -1));
    },
    current(state) {
        const date = new Date(state.period.current);
        return new Date(date.setHours(24, 0, 0, -1));
    }
};
export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
};
