// Сторонние зависимости
import MapBoxCircle from 'mapbox-gl-circle';

// Утилиты
import Map from '@/utils/map';
import {colorFromInt} from '@/utils/utils';

// Сервисы
import MapLayersService from '@/services/MapLayersService';

const idPrefix = 'stop_objects';

/**
 * Сервис для работы с объектами карты
 */
export default {
  /**
   * Инициализация сервиса
   *
   * @param {Map} map
   */
  init(map) {
    this._map = map.getRawMap();
    this._instance = map;

    if (!_hasOwnProperty(this, '_mapIds')) {
      this._mapIds = [];
    }

    if (!_hasOwnProperty(this, '_circles')) {
      this._circles = [];
    }
  },

  /**
   * Отрисовка
   */
  drawStopObjects(stopObjects) {
    /*
      color: 10289407
      description: ""
      enddt: null
      id: "74788c05-ef4b-4613-887c-6ad1cec867fe"
      name: "bnm"
      objecttypeid: "43dc1401-cae2-4674-9b0e-1cef210bfc82"
      objecttypeidName: "Опасный участок"
      polygon: null
      polygontext: "POLYGON((38.88264621093822 45.234703294683555,38.68489230468782 45.07394168797225,39.173783906250236 44.608492641306555,39.728593476563276 45.2211625976708,38.88264621093822 45.234703294683555))"
      polygontypename: "Полигон"
      startdt: "2020-07-24T14:10:40Z"
      tenantid: "c36a0157-fc27-411c-b0ca-20c3088ac270"
      type: "POLYGON"
      width: 15.124
     */

    // Если уже были данные, то для простоты - все удалим
    // И нарисуем по новой
    this._mapIds.forEach(it => {
      this._instance.createOrDataSource(it, {
        type: 'FeatureCollection',
        features: [],
      });
    });

    this._circles.forEach(it => {
      it.remove();
    });

    this._circles = [];
    this._mapIds = [];

    stopObjects.forEach(it => {
      const id = `${idPrefix}_${it.id}`;
      const geometry = MapLayersService.parseWkx(it);

      const props = {
        name: it.name,
      };

      // Если тип - не круг
      if (it.type !== 'CIRCLE') {
        delete geometry.ref;
        delete geometry.refType;
        delete geometry.refGeometry;

        geometry.properties = props;

        this._mapIds.push(id);

        this._instance.createOrDataSource(id, geometry);

        if (!this._map.getLayer(it)) {
          if (it.type === 'LINE') {
            const radians = (
              78271.517 *
              Math.cos(
                geometry.geometry.coordinates[0][1] * Math.PI / 180
              )
            );

            if (!this._map.getLayer(id)) {
              this._map.addLayer({
                id: id,
                type: 'line',
                source: id,
                layout: {
                  'line-join': 'round',
                  'line-cap': 'round'
                },
                paint: {
                  'line-color': colorFromInt(ref.color || -65536, 1),
                  'line-width': [
                    'interpolate',
                    ['exponential', 2],
                    ['zoom'],
                    1, ['*', it.width / radians, ['^', 2, 1]],
                    24, ['*', it.width / radians, ['^', 2, 24]],
                  ],
                },
              });
            }
          }

          if (it.type === 'POLYGON') {
            if (!this._map.getLayer(id)) {
              this._map.addLayer({
                id: id,
                type: 'fill',
                source: id,
                paint: {
                  'fill-color': colorFromInt(it.color || -65536, 0.7)
                }
              });

              this._map.addLayer({
                id: `${id}_text`,
                type: 'symbol',
                source: id,
                layout: {
                  'text-field': ['get', 'name'],
                  'text-radial-offset': 0.5,
                  'text-justify': 'auto',
                  'text-size': 14,
                  'visibility': 'none',
                },
              });
            }
          }

          this._map.on('mousemove', id, () => {
            this._map.setLayoutProperty(`${id}_text`, 'visibility', 'visible');
          });

          this._map.on('mouseleave', id, () => {
            this._map.setLayoutProperty(`${id}_text`, 'visibility', 'none');
          });
        }
      } else {
        const circle = new MapBoxCircle(
          {
            lat: geometry.geometry.coordinates[1],
            lng: geometry.geometry.coordinates[0],
          },
          (it.width * 1000) || 1000,
          {
            fillColor: colorFromInt(it.color || -65536, 0.8),
            strokeColor: colorFromInt(it.color || -65536, 1)
          }
        );

        this._circles.push(circle);

        circle.addTo(this._map);

        setTimeout(() => {
          const layerId = `circle-fill-${circle.__instanceId}`;
          const layerTextId = `${layerId}_text`;
          const sourceId = `circle-source-${circle.__instanceId}`;

          const circleSource = this._map.getStyle().sources[sourceId];
          circleSource.data.features[0].properties = props;

          this._instance.createOrDataSource(sourceId, circleSource.data);

          if (!this._map.getLayer(layerTextId)) {
            this._map.addLayer({
              id: layerTextId,
              type: 'symbol',
              source: circleSource,
              layout: {
                'text-field': ['get', 'name'],
                'text-radial-offset': 0.5,
                'text-justify': 'auto',
                'text-size': 14,
                'visibility': 'none',
              },
            });
          }

          this._map.on('mousemove', layerId, () => {
            this._map.setLayoutProperty(layerTextId, 'visibility', 'visible');
          });

          this._map.on('mouseleave', layerId, () => {
            this._map.setLayoutProperty(layerTextId, 'visibility', 'none');
          });
        }, 100);
      }
    });
  },
}
