<template>
  <div class="pa-4 fill-height" style="overflow: auto;">
    <!-- Заголовок -->
    <h3 class="mb-4">
      {{ value.id ? 'Редактирование' : 'Создание' }} геозоны
    </h3>

    <!-- Название -->
    <v-text-field
      label="Название геозоны"
      v-model="value.name"
      placeholder="Введите название"
    ></v-text-field>

    <!-- Описание -->
    <v-textarea
      v-model="value.description"
      label="Описание"
      placeholder="Введите описание геозоны"
      auto-grow
      rows="2"
    ></v-textarea>

    <!-- Дата начала -->
    <v-menu
      ref="date_from"
      v-model="date_from_dialog"
      :close-on-content-click="false"
      transition="scale-transition"
      offset-y
      max-width="290px"
      min-width="290px"
    >
      <template v-slot:activator="{ on }">
        <v-text-field
          readonly clearable persistent-hint
          v-on="on"
          v-model="value.startDate"
          label="Начало учета"
          placeholder="Выберите дату"
        ></v-text-field>
      </template>

      <v-date-picker
        no-title
        v-model="value.startDate"
        @input="date_from_dialog = false"
        :first-day-of-week="1"
        :weekday-format="dayOfWeekToFormat"
      ></v-date-picker>
    </v-menu>

    <!-- Дата окончания -->
    <v-menu
      ref="date_from"
      v-model="date_to_dialog"
      :close-on-content-click="false"
      transition="scale-transition"
      offset-y
      max-width="290px"
      min-width="290px"
    >
      <template v-slot:activator="{ on }">
        <v-text-field
          readonly clearable persistent-hint
          v-on="on"
          v-model="value.endDate"
          label="Окончание учета"
          placeholder="Выберите дату"
        ></v-text-field>
      </template>

      <v-date-picker
        no-title
        v-model="value.endDate"
        @input="date_to_dialog = false"
        :first-day-of-week="1"
        :weekday-format="dayOfWeekToFormat"
      ></v-date-picker>
    </v-menu>

    <!-- Тип -->
    <v-radio-group
      label="Тип"
      v-model="value.type"
      :disabled="disableTypes"
      @change="changed"
    >
      <v-radio
        v-for="(tTitle, tName) in types"
        :key="tName"
        :label="tTitle"
        :value="tName"
      ></v-radio>
    </v-radio-group>

    <!-- Цвет -->
    <div>
      <div>Цвет</div>

      <v-color-picker
        v-model="value.color"
        class="my-2"
        hide-canvas
        hide-inputs
        flat
        width="100%"
        @update:color="changed"
      ></v-color-picker>
    </div>

    <!-- Радиус -->
    <template v-if="value.type === 'CIRCLE'">
      <div>Радиус (м)</div>

      <v-text-field
        v-model="value.radius"
        type="number"
        @change="changed"
        hide-details
      ></v-text-field>
    </template>

    <!-- Толщина линии -->
    <template v-else-if="value.type === 'LINE'">
      <div>Толщина линии (м)</div>

      <v-text-field
        v-model="value.width"
        type="number"
        @change="changed"
        hide-details
      ></v-text-field>
    </template>

    <!-- Кнопки -->
    <v-row class="pt-4">
      <v-col>
        <v-btn outlined color="red" class="mb-4" @click="cancelSubmit">
          Отменить
        </v-btn>
      </v-col>

      <v-spacer/>

      <v-col>
        <v-btn
          color="primary"
          :loading="loading"
          :disabled="!value.name"
          @click="submitGeoZone"
        >
          Сохранить
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {dayOfWeekToFormat} from "@/services/JetDate";

export default {
  props: {
    value: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
    // Доступность выбора типа геозоны
    disableTypes: {
      type: Boolean,
      default: false,
    },
  },
  name: 'GeozoneEdit',
  watch: {
    // Слежение за изменением типа геозоны
    disableTypes(val) {
      if (!val) {
        this.value.type = null;
      }
    },
  },
  data: () => ({
    // Типы геозон
    types: {
      LINE: 'Линия',
      CIRCLE: 'Окружность',
      POLYGON: 'Полигон',
    },
    // Дата начала
    date_from_dialog: false,
    // Дата окончания
    date_to_dialog: false,
    dayOfWeekToFormat: () => '',
  }),
  created() {
    this.dayOfWeekToFormat = dayOfWeekToFormat;
  },
  methods: {
    // Проброс события выхода из режима редактирования
    cancelSubmit() {
      this.$emit('onCancel', this.value);
    },
    // Проброс события сохранения геозоны
    submitGeoZone() {
      this.$emit('onSubmit', this.value);
    },
    // Проброс события изменения геозоны
    changed() {
      this.$emit('onChangeProperties', this.value);
    },
  },
}
</script>
