<script>

    import RouteService from '@/services/RouteService';
    const $moment = require('moment');
    
    export default {
        name: 'DepartureCodeDialog',
        
    props: ['show', 'departure', 'deps'],
    data(){
        return {
            newCode: null,
        };
    },
    methods: {
        check(){
            if (!this.newCode) {
                jet.msg({type:'warning', text:`Поле не заполнено!!`});
                return;
            }
            if (this.deps.findIndex((d) => d.name === this.newCode) > -1 && this.departure.name !== this.newCode){
                jet.msg({type:'warning', text:`Значение ${this.newCode} уже назначено на другой выезд!`});
                return;
            } else {
                this.save();
            }
        },
        save(){
            this.$emit('setDepName', this.newCode);
            this.$emit('close');
        }
    },
    watch: {
        show(val){
            this.newCode = null;
            if (!!this.departure) {
                this.newCode = this.departure.name;
            }
        },
    },
    render(h){
        return h('v-dialog', {props: {
            width: "225px",
            value: this.show
        },on: {                 
        keydown: (e)=>{
            if (13 === e.keyCode){
                this.check();
            } else if (27 === e.keyCode){
                this.$emit('close');
            }
        }
        }}, [
            h('v-card', {class: {"sch-stop-times": true}}, [
                h('v-card-title', 'Номер выезда'),
                h('v-card-text', [
                    h('v-row', [
                        h('v-col', {props: {col: 6}}, [
                            h('v-text-field', {
                                props: {type: 'text', value: this.newCode},
                                on: {
                                    input: (val)=>{this.newCode = val;},
                                    keydown: (e)=>{
                                        if (13 === e.keyCode){
                                            this.check();
                                        } else if (27 === e.keyCode){
                                            this.$emit('close');
                                        }
                                    }
                                }
                            })
                        ]),
                    ]),
                ]),
                h('v-card-actions', [
                    h('v-spacer'),
                    h('v-btn', {props: {outlined:true, tile:true, color: 'gray', small: true},
                                on: {click: ()=>{ this.$emit('close');}
                                }
                            }, 'отмена'),
                    h('v-btn', {props: {outlined:true, tile:true, color: 'primary', small: true},
                                on: {click: this.check}
                               }, 'сохранить')
                ])
            ])
        ]);
    }
    
    }
    
</script>

<style lang="scss">
    
    .v-dialog > .v-card > .v-card__text{
        padding-bottom: 0;
    }
    .v-input--selection-controls{
        margin-top: 0;
    }
</style>

