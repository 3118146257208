<template>
  <div class="jet-input jet-input-password" v-show="vis">
    <div hidden> 
      <v-text-field :value="value"></v-text-field>
    </div>
    <v-text-field
      ref="pass"
      v-model="pass" 
      :value="pass" 
      :disabled="dis" 
      :label="label" 
      :placeholder="placeholder"
      type="password"
      :rules="[v => !!v || 'Обязательное поле']"
      @input="validate($event, '1')">
    </v-text-field>
    <v-text-field
      ref="pass2"
      v-model="pass2" 
      :value="pass2" 
      :disabled="dis"
      type="password" 
      label="Подтвердить пароль"
      :rules="[v => !!v || 'Обязательное поле']"
      @input="validate($event, '2')"
      :error = "errPass2"
      :error-messages="errPass2Msg">
    </v-text-field>
  </div>
</template>

<script>
import JetInputBase from '@/components/editors/JetInputBase';

export default {
  name: 'JetInputPassword',
  extends: JetInputBase,
  data() {
    return {
      value: this.$attrs.value,
      pass: null,
      pass2:null,
      errPass2: false,
      errPass2Msg:[],
      valid: true
    }
  },
  created() {
    if ( this.value ) {
      this.pass = '*******';
      this.pass2 = '*******';
    }
  },
  methods:{
    validate(v, src) {
      if ( src === '1' ) {
        if ( !!v && this.pass2 ) {
          this.setError((v != this.pass2), 'Пароли не совпадают');
        }
      }
      if ( src === '2' ) {
        if ( !!v ) {
          this.setError((v != this.pass), 'Пароли не совпадают');
        }
      }
      if ( this.valid && this.pass2) {
        this.value = this.pass;
      } else {
        this.value = null;
      }
    },
    setError(v, msg) {
      this.errPass2Msg= [];
      this.errPass2 = false;
      var valid = true;
      if ( v ) {
        this.errPass2 = true;
        this.errPass2Msg.push(msg);
        valid = false;
      }
      this.valid = valid;
    }
  },
  watch: {
    value(newValue, oldValue) {
      this.$attrs.value = newValue;
      this.$emit('input', newValue);
      this.$emit('datachange', this.name);
    }
  }
};
</script>
