import { isEmpty } from "./utils";

const NULL_ID = "00000000-0000-0000-0000-000000000000";

const MODES = {
    "none":    0,
    "default": 1,
    "loading": 2,
    "editing": 3,
    "saving":  4,
    "success": 5,
    "search":  7,
    "error":   9,
    "freeze": 10
};

export {
    NULL_ID,
    MODES,
    isEmpty
};