<template>
  <div>
    <v-btn
      @click="dialog = true, init()"
      color="primary"
      class="text--white"
    >
      Добавить
    </v-btn>

    <jet-dialog
      v-if="dialog"
      v-model="dialog"
      title="Новое нарушение"
      apply-button-text="Создать"
      persistent
      @on-cancel-click="dialog = false"
      @on-apply-click="newViolation"
      :loading-apply-button="loadingCreate"
    >
      <v-form v-model="isValid" ref="form">
        <v-row>
          <v-col class="pb-0">
            <JetDatePicker v-model="date" label="Дата нарушения"/>
          </v-col>
        </v-row>

        <v-row>
          <v-col class="pt-0">
            <v-autocomplete
              label="Маршрут"
              v-model="route"
              item-value="id"
              :items="routes"
              :loading="loadingRoutes"
              return-object
              clearable
              @click:clear="route = {}"
              :rules="[val => !!val || 'Обязательное поле']"
              required
            >
              <template #item="{item}">
               <span class="textBlack">
                  {{ item.text }}
                 <b>({{ item.shortName }})</b>
                </span>
              </template>

              <template #selection="{item}">
                <span class="textBlack pr-1" style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis">
                 {{ item.text }} ({{ item.shortName }})
               </span>
              </template>
            </v-autocomplete>
          </v-col>
        </v-row>

        <v-row>
          <v-col class="pt-0">
            <v-autocomplete
              label="Рейс"
              v-model="schedule"
              :items="schedules"
              :disabled="routeNull"
              :loading="loadingSchedules"
              return-object
              :rules="[val => !!val || 'Обязательное поле']"
              required
            ></v-autocomplete>
          </v-col>

          <v-col class="pt-0">
            <v-text-field
              label="ТС"
              v-model="vehicle.text"
              disabled
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col class="pt-0">
            <v-select
              label="Причина нарушения"
              v-model="typeViolations"
              :items="typesViolations"
              :loading="loadingTypesViolations"
              return-object
              hide-details
              :rules="[val => !!val || 'Обязательное поле']"
              required
            ></v-select>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-textarea
              v-model="comment"
              label="Примечание"
              outlined
              :rules="[(val) => val.length < 400 || 'Максимальная длинна 400 символов']"
            ></v-textarea>
          </v-col>

          <v-col>
            <v-textarea
              v-model="result"
              label="Результат"
              outlined
              :rules="[(val) => val.length < 255 || 'Максимальная длинна 255 символов']"
            ></v-textarea>
          </v-col>
        </v-row>
      </v-form>
    </jet-dialog>
  </div>
</template>

<script>
import JetDialog from '@/components/JetDialog';
import JetDatePicker from '@/components/JetDatePicker';
import {prepareSinRequest, prepareSinResponse} from '@/utils/http';
import {FORMAT_FOR_SERVER, formatDate} from '@/services/JetDate';
import VehicleService from '@/services/VehicleService';
import PlansService from '@/services/PlansService';
import {getViolationTypes} from '../ViolationsService';

export default {
  name: 'NewViolation',
  components: {JetDatePicker, JetDialog},
  data: () => ({
    dialog: false,
    isValid: false,
    date: new Date().toISOString().substr(0, 10),
    route: null,
    routes: [],
    loadingRoutes: false,
    routeNull: true,
    schedule: null,
    schedules: [],
    loadingSchedules: false,
    vehicle: {},
    vehicles: [],
    typeViolations: null,
    typesViolations: [],
    loadingTypesViolations: false,
    comment: '',
    result: '',
    loadingCreate: false,
  }),
  watch: {
    route(val) {
      this.schedule = null;
      this.vehicle = {};
      this.schedules = [];
      this.vehicles = [];
      // Если маршрут не выбран - запретить ввод графиков и ТС
      this.routeNull = val == null ? true : typeof val !== 'object';
      if (!this.routeNull) {
        this.getSchedules(val.id);
        this.getVehicles(val);
      }
    },
    // При изменении даты сбрасываем рейсы и ТС, заново загружаем список маршрутов
    date() {
      this.schedule = null;
      this.schedules = [];
      this.vehicle = {};
      this.vehicles = [];
      this.getRoutes();
      //После получения списка маршрутов - если маршрут уже был выбран ранее, то проверяем, попадает ли он в новый список.
      //Если попадает - грузим для него рейсы и ТС, иначе - обнуляем выбранный маршрут
      if (!this.routeNull) {
        let findRoute = this.routes.find(rt => rt.id === this.route.id);
        if (findRoute) {
          this.getSchedules(this.route.id);
          this.getVehicles(this.route);
        } else {
          this.routeNull = true;
          this.route = null;
        }
      }
    },
    schedule(val) {
      if (val) {
        const vehicle = this.vehicles.find(veh => veh.id === val.vehicleId);
        if (vehicle) {
          this.vehicle = vehicle;
        } else {
          this.vehicle = {
            id: val.vehicleId,
            text: 'Невозможно отобразить гос номер',
          };
        }
      }
    },
  },
  computed: {
    formattedDate() {
      return formatDate(this.date, FORMAT_FOR_SERVER.ONLY_DATE);
    },
  },
  methods: {
    init() {
      this.route = null;
      this.typeViolations = null;
      this.comment = '';
      this.result = '';
      this.getRoutes();
      this.getTypeViolations();
    },
    async getRoutes() {
      this.loadingRoutes = true;
      const routes = await prepareSinResponse(
        await prepareSinRequest(
          'query',
          'ec7e9f5f-6357-41a7-94c4-d42a17b2e2aa.cabman_planning_date',
          '',
          [],
          {
            tenantId: this.$store.state.auth.user.tenantId,
            date: this.formattedDate,
          },
          {
            cache: false,
          },
        ),
        {
          hideUnderscores: true,
        },
      );
      this.routes = routes.map(rt => {
        return {
          id: rt.routeid,
          text: rt.routecode + ' ' + rt.routename,
          shortName: rt.shortname,
          carrierId: rt.carrierid,
        };
      });
      this.loadingRoutes = false;
    },
    async getTypeViolations() {
      this.loadingTypesViolations = true;
      this.typesViolations = await getViolationTypes();
      const user = this.$store.state.profile.subject;
      Object.keys(user.roles).map((id)=>{
        var role = user.roles[id];
        if (/(пользователь\sперевозчика)+/i.test(role.title)) {
          this.typesViolations = this.typesViolations.filter((p)=>{
            return p.code === 1 || p.code === 5 || p.code === 11 || p.code === 12 || p.code === 19;
          });
        }
      });
      this.loadingTypesViolations = false;
    },
    getSchedules(routeId) {
      this.loadingSchedules = true;
      Promise.all(
        PlansService.getTripsRoute(this.formattedDate, routeId),
      ).then(res => {
        let trips = res[1]?.result?.data ?? [];
        let tci = res[1]?.result?.columnIndexes;
        trips = trips.map(it => ({
          id: it[tci['vctrips.id']],
          scheduleId: it[tci['vctrips.schedule']],
          vehicleId: it[tci['vctrips.vehicle']],
        }));

        let schedules = res[0]?.result?.data ?? [];
        let sci = res[0]?.result?.fields;
        sci = sci.reduce((o, v, i) => {
          o[v] = i;
          return o;
        }, {});
        schedules = schedules.map(sch => ({
          id: sch[sci['id']],
          tripcode: sch[sci['tripcode']],
        }));

        for (const trip of trips) {
          const schedule = schedules.find(sch => sch.id === trip.scheduleId);
          trip.text = schedule.tripcode;
        }

        this.schedules = trips;
      });
    },
    getVehicles(route) {
      VehicleService.getVehiclesCarrier(
        route.carrierId,
        this.formattedDate,
      ).then(res => {
        const data = res?.result?.data ?? [];
        const ci = res?.result?.columnIndexes ?? {};
        this.vehicles = data.map(it => ({
            id: it[ci['vcvehicle.id']],
            text: it[ci['vcvehicle.govnum']],
          }),
        );
        
        this.loadingSchedules = false;

      });
    },
    newViolation() {
      this.$refs.form.validate();
      if (this.isValid) {
        this.loadingCreate = true;
        const request = {
          type: 'core-create',
          query: 'sin2:/v:d727c2f7-b3c2-4a2d-afc5-a5836988a6b3/',
          params: [
            {id: 'tripid', type: 'id', value: this.schedule.id},
            {id: 'vehicleid', type: 'id', value: this.vehicle.id},
            {id: 'eventdt', type: 'date', value: this.formattedDate},
            {id: 'violationreasonid', type: 'id', value: this.typeViolations.id},
            {id: 'comment', type: 'string', value: this.comment},
            {id: 'type', type: 'string', value: 'manual'},
          ],
        };
        if (this.result) {
          request.params.push(
            {id: 'result', type: 'string', value: this.result},
          );
        }
        jet.http.post(request)
          .then(() => {
            this.loadingCreate = false;
            this.$emit('refresh', {date: this.date});
            this.dialog = false;
            this.typesViolations = [];
            this.routes = [];
            this.route = null;
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.textBlack {
  color: black !important;
}
</style>
