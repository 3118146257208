<template>
  <div>

    <!-- toolbar -->
    <v-row>
      <v-col class="title pl-6">Отчётность</v-col>
      <v-col cols="auto" align="right">
        <v-btn
          color="primary"
          @click="dialogCreate = true"
        >
          Загрузить отчёт
        </v-btn>
      </v-col>
      <v-col cols="auto" align="right">
        <v-select
          v-model="mode"
          :items="modes"
          label="Отобразить"
          item-text="title"
          return-object
          style="width: 175px;"
          outlined
          dense
          hide-details
        ></v-select>
      </v-col>
      <v-col cols="auto" align="right">
        <JetSearchField
          v-model="search"
          style="width: 500px"
        ></JetSearchField>
      </v-col>
    </v-row>

    <!-- Таблица -->
    <v-data-table
      :headers="headers"
      :items="filteredReports"
      :search="search"
      :loading="loading"
      no-data-text="Отчётов не найдено"
      sort-by="date"
      @click:row="openReportPage"
    >
      <template v-slot:item.status="{ item }">
        <div v-html="getTextStatus(item.status)"/>
      </template>
    </v-data-table>

    <!-- Диалог создания отчёта -->
    <CreateReport
      v-if="dialogCreate"
      v-model="dialogCreate"
      @refresh="init"
    ></CreateReport>

    <!-- Диалог для чтения\изменения отчёта -->
    <ShowReport
      v-if="dialogReport"
      v-model="dialogReport"
      :report="selectedReport"
      @refresh="init"
    ></ShowReport>
  </div>
</template>

<script>
// Components
import JetSearchField from "@/components/JetSearchField";
import CreateReport from "@/components/dev/modules/carrier_reports/components/CreateReport";
import ShowReport from "@/components/dev/modules/carrier_reports/components/ShowReport";

// Service
import OtherService from "@/services/OtherService";

// Mixins
import HideGlobalToolbarMixin from '@/components/dev/mixin/HideGlobalToolbarMixin';

export default {
  mixins: [
    HideGlobalToolbarMixin,
  ],
  name: "CarrierReports",
  components: {
    ShowReport,
    CreateReport,
    JetSearchField,
  },
  data: () => ({
    loading: true,
    reports: [],
    selectedReport: null,
    dialogReport: false,
    dialogCreate: false,
    search: '',
    headers: [
      {text: 'Дата', value: 'date',},
      {text: 'Наименование', value: 'title'},
      {text: 'Перевозчик', value: 'shortName'},
      {text: 'Статус', value: 'status'},
    ],
    mode: null,
    modes: [
      {title: 'Все', value: -1},
      {title: 'Новые', value: 0},
      {title: 'Отклоненные', value: 1},
      {title: 'Принятые', value: 2},
    ],
  }),
  created() {
    this.mode = this.modes[0];
    this.init();
  },
  computed: {
    filteredReports() {
      return this.reports.filter(
        rep => (
          this.mode.value === -1
          || rep.status === this.mode.value
        )
      );
    },
  },
  methods: {
    async init() {
      this.dialogCreate = false;
      this.dialogReport = false;
      this.loading = true;
      this.reports = await OtherService.getCarrierReports();
      this.loading = false;
    },
    openReportPage(report) {
      this.selectedReport = report;
      this.dialogReport = true;
    },
    getTextStatus(number) {
      switch (number) {
        case 0:
          return 'Новый';
        case 1:
          return 'Отклонен';
        case 2:
          return 'Принят';
        default:
          return '<div class="grey--text">Статус отсутствует</div>';
      }
    },
  },
}
</script>
