<template>
  <v-list-item>
    <!-- Название -->
    <v-list-item-content class="pr-4">
      {{ item.name }}
    </v-list-item-content>

    <!-- Показать на карте -->
    <v-list-item-action>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            fab x-small depressed
            style="margin: -4px 0;"
            v-on="on"
            v-bind="attrs"
            :color="btnColor"
            :loading="loading_points"
            :disabled="loading_data"
            @click="showGeoZone"
          >
            <v-icon>mdi-eye-outline</v-icon>
          </v-btn>
        </template>

        <span>Показать на карте</span>
      </v-tooltip>
    </v-list-item-action>

    <!-- Редактировать -->
    <v-list-item-action class="ml-2">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            fab x-small depressed
            style="margin: -4px 0"
            v-on="on"
            v-bind="attrs"
            :loading="loading_data"
            :disabled="loading_points"
            @click="editGeoZone"
          >
            <v-icon>mdi-pencil-outline</v-icon>
          </v-btn>
        </template>

        <span>Редактировать</span>
      </v-tooltip>
    </v-list-item-action>

    <!-- Удалить -->
    <v-list-item-action class="ml-2">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            fab x-small depressed
            style="margin: -4px 0;"
            v-on="on"
            v-bind="attrs"
            :disabled="loading_points || loading_data"
            @click="deleteBtnClick"
          >
            <v-icon color="red">mdi-close</v-icon>
          </v-btn>
        </template>

        <span>Удалить</span>
      </v-tooltip>
    </v-list-item-action>

    <!-- Диалог удаления гео зоны -->
    <v-dialog v-model="dialog" width="600">
      <v-card>
        <v-card-title>Удалить геозону?</v-card-title>

        <v-divider/>

        <v-card-text class="pt-4 body-1">
          {{toDelete 
            ? `Подтвердите, что хотите удалить геозону "${ this.item.name }"` 
            : `Геозона "${this.item.name}" не может быть удалена, поскольку является объектом маршрута`}}
        </v-card-text>

        <v-divider/>

        <v-card-actions>
          <v-btn
            @click="dialog = false"
            color="red"
            outlined
          >
            Отмена
          </v-btn>

          <v-spacer/>

          <v-btn
            @click="removeGeoZone"
            color="red"
            :disabled="!toDelete"
            dark
            :loading="loading"
          >
            Удалить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-list-item>
</template>

<script>
// Сервисы
import MapLayersService from '@/services/MapLayersService';

export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
    value: {
      type: Array,
      required: true,
    },
    visible: {
      type: Boolean,
      required: true,
    },
  },
  name: 'GeozoneItem',
  data: () => ({
    dialog: false,
    loading_points: false,
    loading_data: false,
    has_data: false,
    toDelete: false,
  }),
  methods: {
    //Проверки при нажатии кнопки удаления
    deleteBtnClick () {
      MapLayersService.checkRouteObjects(this.item.id)
        .then((result) => {
            this.toDelete = result;
            this.dialog = true;
        });
    },
    // Редактирование геозоны
    editGeoZone() {
      // Проверка наличия точек геозоны
      if (!this.has_data) {
        this.has_data = true;
        this.$emit('onEdit', this.item);
        // this.showGeozone();
      } else {
        // Проброс события отправки геозоны в режим редактирования
        this.$emit('onEdit', this.item);
      }
    },
    // Удаление геозоны
    removeGeoZone() {
      this.$emit('onRemove', this.item);
    },
    // Показ/Скрытие геозоны
    showGeoZone() {
      this.$emit('onShow', this.item);
    }
  },
  computed: {
    btnColor() {
      return this.visible ? 'primary' : '#f5f5f5';
    },
  },
}
</script>

<style scoped>

</style>
