<template>
    <v-card>
        <v-toolbar flat class="pt-3">
            <v-form v-on:submit.stop.prevent="search">
                <v-text-field v-model="s" 
                              autofocus
                              label="Адрес"
                              clearable
                              full-width
                              dense
                              :loading="loading"
                              messages="<enter> - поиск">
                    <template v-slot:append>
                        <v-btn text small type="submit"><v-icon small>mdi-magnify</v-icon></v-btn>
                    </template>
                </v-text-field>
            </v-form>
        </v-toolbar>
        <v-card-text>
            <v-list v-if="has('addrs')"
                    dense>
                <v-subheader>
                    <v-badge :content="addrs.length"
                             color="secondary">Результат: "{{s}}"</v-badge>
                </v-subheader>
                <v-list-item v-for="a in addrs"
                            :key="a.osm_id"
                            v-on:click="go(a)">
                    <v-list-item-content>
                        <v-list-item-title>
                            {{ a.display_name }}
                        </v-list-item-title>
                        <v-list-item-subtitle>
                            <div class="coords">
                                {{a.lon}} / {{a.lat}}
                            </div>
                            <div class="type">
                                {{a.osm_type}}
                            </div>
                        </v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </v-card-text>    
    </v-card>
</template>
<script>
import { isEmpty } from '@/utils';

export default {
    name: 'MapAddresses',
    props: {
        'active': {
            type: Boolean,
            required: true,
            default: -1
        }
    },
    data(){
        return {
            s: null,
            loading: false,
            error: null,
            addrs: []
        };
    },
    inject: ['drawFlag'],
    mounted(){
        this.defMe();
    },
    methods: {
        has(q){
            switch(q){
                case 'addrs':
                    return this.addrs?.length > 0;
            }
        },
        defMe(){
            const c = jet.map.center;
            if (!!c){
                const _params = new URLSearchParams();
                _params.append('lon', c[0]);
                _params.append('lat', c[1]);
                _params.append('zoom', 18);
                _params.append('format', 'jsonv2');
                $.getJSON('https://nominatim.openstreetmap.org/reverse?' + _params.toString(), {timeout:20000})
                    .then( data => {
                        console.log(data);
                        if (data.address.city){
                            this.s = data.address.city;
                        }
                    });
            }
        },
        search(){
            if ( isEmpty(this.s) ){
                return false;
            }
            this.error = null;
            const _params = new URLSearchParams();
            _params.append('country', 'Россия');
            _params.append('q', this.s);
            _params.append('format', 'json');
            _params.append('limit', '5');
            
            this.loading = true;
            $.getJSON('https://nominatim.openstreetmap.org/search?' + _params.toString(), {timeout:20000})
                .then( data => {
                    console.log(data);
                    this.addrs = data;
                })
                .catch(e => {
                    console.log(e);
                }).always(()=>{
                    this.loading = false;
                });
        },   //search
        go(a){
            console.log(a);
            a.zoom = 17;
            jet.map.flag = a;
            this.$emit('hide');
        }   //go
    },
    watch: {
        active(val){
            if ( !!val && isEmpty(this.s) ){
                this.defMe();
            }
        }
    }
}    
</script>
<style lang="scss" scoped>
    .v-card{
        &__text{
            min-height: 320px;
        }
    }
    .v-form{
        width: 100%;
    }
    .v-list-item{
        &__subtitle{
            font-size: 0.75rem;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            & .coords{
                margin-right: 1rem;
            }
        }
    }
</style>