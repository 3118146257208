<template>
  <div>
    <v-row class="mx-1">
      <v-col>
        <v-btn color="primary" class="white--text mr-4" @click="closeDialog">Назад</v-btn>
        <span class="title">{{ selectedApplication.title }}</span>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <div class="ml-4 subtitle-1"><b>Изменения:</b></div>
        <v-data-table
          :headers="headers"
          :items="changes"
          hide-default-footer
          :loading="loading"
        ></v-data-table>
      </v-col>
      <v-col>
        <b class="mx-2" style="font-size: 17px">Комментарий</b>
        <v-textarea
          v-model="dialogComment"
          class="px-2 pt-2 pb-4"
          auto-grow
          rows="2"
          row-height="42"
          outlined
          hide-details
          color="grey"
        ></v-textarea>
        <v-row class="px-2">
          <v-col class="pt-0">
            <v-btn
              color="red"
              class="white--text"
              :disabled="!dialogComment.length"
              @click="submit(false)"
              :loading="loadingButton"
            >
              Отклонить
            </v-btn>
          </v-col>
          <v-col class="pt-0" align="right">
            <v-btn color="primary" @click="submit(true)" :loading="loadingButton">Принять</v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
  import ProfilesService from "@/components/dev/service/ProfilesService";

  export default {
    name: "Record",
    props: {
      selectedApplication: {
        type: Object,
        required: true,
      },
    },
    data: () => ({
      loading: true,
      loadingButton: false,
      status: 'Новый',
      dialogComment: '',
      carrier: {},
      change: false,
      headers: [
        {text: 'Поле', value: 'fieldName'},
        {text: 'Было', value: 'before'},
        {text: 'Стало', value: 'after'},
      ],
      changes: [],
    }),
    created() {
      this.init();
    },
    methods: {
      async init() {
        this.loading = true;
        let carriers;
        try {
          carriers = await ProfilesService.getProfiles(this.selectedApplication.carrierId);
        } catch (e) {
          carriers = [];
        }
        this.carrier = carriers[0] || {};
        for(const key in this.selectedApplication.changes) {
          this.changes.push({
            fieldName: this.getNameVariable(key),
            before: this.carrier[key] || '',
            after: this.selectedApplication.changes[key],
          });
        }
        this.loading = false;
      },
      closeDialog() {
        this.dialogComment = '';
        this.$emit('back', this.change);
      },
      async submit(confirm) {
        this.loadingButton = true;
        this.change = true;
        if (confirm) {
          await ProfilesService.confirmProfile(
            this.selectedApplication.orgId,
            this.selectedApplication.hdrId,
            this.selectedApplication.changes
          );
        }
        await ProfilesService.editStatusModificationProfile(
          this.selectedApplication.id,
          confirm,
          this.dialogComment,
        );
        this.loadingButton = false;
        this.closeDialog();
      },
      getNameVariable(nameVariable) {
        switch (nameVariable) {
          case 'nameLeader': return 'ФИО руководителя';
          case 'phoneLeader': return 'Номер руководителя';
          case 'orgPlace': return 'Юр. адрес';
          case 'factAddress': return 'Фактический адрес';
          case 'orgTlf': return 'Телефон';
          case 'email': return 'Email';
        }
        return '';
      }
    },
  }
</script>
