import JetTable from '@/components/JetTable';
globalThis.WebSocket = require("websocket").w3cwebsocket;
import { connect, JSONCodec } from '../../../../../node_modules/nats.ws/lib/src/mod.js';
const natsWs =  { servers: "wss://tr.krasnodar.ru:4222/", user: "teva", pass: "teva25822" },  //TODO: config
      codec = JSONCodec();
const $moment = require('moment');
$moment.locale('ru');
const utcOff = $moment().utcOffset();


var nats = null;
var isNotifiEnabled = false;
var now = new Date();

export const EvaJ  = {
    extends: JetTable,
    created(){
        jet.evanotify = id => {
            if (!nats){
                jet.msg({text:'ВНИМАНИЕ! Сервис сообщений недоступен - уведомление не отправлено', color: 'warning'});
            } else {
                try {
                    nats.publish('PUBLIC.EVA.status', codec.encode( id ));
                } catch(e){
                    console.log('ERR (nats.publish)', e);
                    jet.msg({text:'ВНИМАНИЕ! Уведомление не отправлено', color: 'warning'});
                }
            }
        },
        Notification.requestPermission(res => {
            if (res !== 'granted') {
                jet.msg({text:"Необходимо разрешить уведомления в браузере", type: "warning", timeout: 20000 });
            } else {
                isNotifiEnabled = true;
            }
        });
        (async ()=>{
            try {
                nats = await connect(natsWs);
                const sid = nats.subscribe('PUBLIC.EVA.arrest');
                try {
                    for await (const m of sid) {
                        const data = codec.decode(m.data);
                        this.renotify(data.id);
                    }
                } catch(e){
                    console.log('ERR (ws)', e);
                }
            } catch(e) {
                jet.msg({text:'ВНИМАНИЕ! Сервис сообщений недоступен - информция о поступающих заявках обновляться не будет', color: 'warning'});
            }
        })();
    },
    methods: {
        async renotify(id){
            try {
                const n = this.rows?.findIndex( r => id === r.evacoffensejournalId );
                
                await this.$parent.refresh(id);
                
                setTimeout(()=>{
                    const evaNotifi = new Notification(n < 0 ? "Новая заявка" : "Заявка изменена", {
                            requireInteraction: true,
                            tag: "eva-notify",
                            body: this.selName,
                            icon: require('@/assets/images/eva-notify.png')
                    });
                    
                    evaNotifi.onclick = ()=>{
                        window.focus();
                        this.selId = id;
                    };    
                }, 500);
            } catch(e){
                console.log()
                jet.msg({text: "Ошибка при обновлении списка"});
            }
        },   //renotify
        onStatus(item){
            item._ctl = false;
            var res;
            if ( /(нова)+/i.test(item.value) ){
                res = this.$createElement('v-chip', {
                        props: {
                                    color: "amber lighten-3",
                                    small: true,
                                    label: true,
                                    pill: true
                                },
                        style: {
                                    "min-width": "6rem"
                        }
                }, [
                    this.$createElement('v-icon', {props: {"small": true,  color: 'amber darken-3'}, class: "mr-2"}, 'mdi-plus'),
                    item.value
                ]);
                item._ctl = true;
            } else if ( 
                           /(работ)+/i.test(item.value) 
                        || /(назна)+/i.test(item.value)
                      ){
                res = this.$createElement('v-chip', {
                        props: {
                                    color: "orange lighten-3",
                                    label: true,
                                    small: true,
                                    pill: true
                                },
                        style: {
                                    "min-width": "6rem"
                        }
                }, [
                    this.$createElement('v-icon', {props: {"x-small": true, color: 'orange darken-3'}, class: "mr-2"}, 'mdi-alarm-light'),
                    item.value
                ]);
                item._ctl = true;
            } else if ( /(стоя)+/i.test(item.value) ){
                res = this.$createElement('span', {
                    style: {
                                display: 'inline-block',
                                padding: '0 12px',
                                color: '#BDBDBD'
                    }
                }, item.value);
            } else {
                res = item.value;
            }
            if (item._ctl){
                const tm = $moment(item.item["evacoffensejournalCreatedt"]).add(utcOff + 45,'minutes');
                if ( tm.isBefore(now) ){
                    res = this.$createElement('v-chip', {
                        props: {
                                    color: "error",
                                    small: true,
                                    label: true,
                                    pill: true
                                },
                        style: {
                                    "min-width": "6rem"
                        }
                    }, [
                        this.$createElement('v-icon', {props: {"small": true}, class: "mr-2"}, 'mdi-alert'),
                        item.value
                    ]);
                }
            }
            return res;
        },  //onStatus
        onBeforeLoad(model){
            now = new Date();
            const n = (model?.columns||[]).findIndex( c => c.id === "evacoffensejournalStateidName");
            if (n > -1){
                model.columns[n].formatter = this.onStatus;
            }
        }
    }
};