import wkx from 'wkx';

const _GEO_ZONE_QUERY_ID = 'a7406e47-5a06-4887-9f05-cab7fc6f010d';

/**
 * Сервис для работы со слоями на карте
 */
export default class MapLayersService {
  static FINAL_STOP_GUID = 'bd78259f-7b66-ad44-e040-007f02002b0e';
  static DEF_STOP_GUID = 'bd78259f-7b64-ad44-e040-007f02002b0e';

  /**
   * Получение списка маршрутов
   *
   * @returns {Promise<{
   *  routeCode: *,
   *  vehicleTypeId: *,
   *  endDate: *,
   *  alterDesc: *,
   *  version: *,
   *  routeName: *,
   *  verStart: *,
   *  verStatus: *,
   *  routeTypeId: *,
   *  verEnd: *,
   *  routeTypeName: *,
   *  id: *,
   *  startDate: *,
   *  vehicleTypeName: *
   * }[]|*[]>}
   */
  static async getRoutes() {
    try {
      let query = 'sin2:/v:38947391-3994-48e0-9658-81b78c941e81';

      query += '?filter=';
      query += 'eq(field(".verstatus"),param("3a928e12-eca1-4be3-bff7-df05b5155bde", "id"))'; // Статус "Утвержден"

      const data = await jet.http.post('/rpc?d=jsonRpc', {
        type: 'core-read',
        query: query,
      });

      if (!!data && data.result) {
        const result = data.result || {};
        const items = result.data || [];
        const ci = result.columnIndexes || {};

        return items.map(it => ({
          id: it[ci['vcroutes.id']],
          startDate: it[ci['vcroutes.startdt']],
          endDate: it[ci['vcroutes.enddt']],
          alterDesc: it[ci['vcroutes.alterdesc']],
          routeCode: it[ci['vcroutes.routecode']],
          routeName: it[ci['vcroutes.routename']],
          routeTypeId: it[ci['vcroutes.routetypeid']],
          routeTypeName: it[ci['vcroutes.routetypeid.typename']],
          vehicleTypeId: it[ci['vcroutes.vehicletypeid']],
          vehicleTypeName: it[ci['vcroutes.vehicletypeid.vctypename']],
          verStart: it[ci['vcroutes.verstart']],
          verEnd: it[ci['vcroutes.verend']],
          version: it[ci['vcroutes.version']],
          verStatus: it[ci['vcroutes.verstatus']],
          currentCarriers: it[ci['vcroutes.currentcarriers']]
        }));
      }

      return [];
    } catch (e) {
      console.error('VehicleService::getRoutes', e);

      return [];
    }
  }

  /**
   * Получение остановок
   *
   * @returns {Promise<*[]|{
   *  code: *,
   *  objectTypeId: *,
   *  endDate: *,
   *  codeStr: *,
   *  objectTypeName: *,
   *  description: *,
   *  locName: *,
   *  twnRegionName: *,
   *  twnTownName: *,
   *  twnAreaName: *,
   *  id: *,
   *  twnId: *,
   *  startDate: *
   * }[]>}
   */
  static async getMapStops() {
    try {
      const data = await jet.http.post('/rpc?d=jsonRpc', {
        type: 'core-read',
        query: 'sin2:/v:f775655f-9081-4980-99f2-b0b8a69b6344'
      });

      if (!!data && data.result) {
        const result = data.result || {};
        const items = result.data || [];
        const ci = result.columnIndexes || {};

        return items.map(it => ({
          id: it[ci['vclocations.id']],
          startDate: it[ci['vclocations.startdt']],
          endDate: it[ci['vclocations.enddt']],
          twnId: it[ci['vclocations.twnid']],
          twnAreaName: it[ci['vclocations.twnid.areaname']],
          twnRegionName: it[ci['vclocations.twnid.regionname']],
          twnTownName: it[ci['vclocations.twnid.townname']],
          code: it[ci['vclocations.code']],
          codeStr: it[ci['vclocations.codestr']],
          description: it[ci['vclocations.description']],
          locName: it[ci['vclocations.locname']],
          objectTypeId: it[ci['vclocations.objecttypeid']],
          objectTypeName: it[ci['vclocations.objecttypeid.name']],
          coordinates: [
            it[ci['vclocations.longitude']],
            it[ci['vclocations.latitude']]
          ]
        }));
      }

      return [];
    } catch (e) {
      return [];
    }
  }

  /**
   * Получение актуальной информации по ТС
   *
   * @param {String} routeId
   * @return {Promise<{
   *   id: *,
   *   name: *,
   *   coordinates: *,
   *   closeDate: *,
   *   distance: *,
   *   isSending: *,
   *   limitSpeed: *,
   *   brdStrategy: *,
   *   location: *,
   *   locationCode: *,
   *   locationLatitude: *,
   *   locationLongitude: *,
   *   locationName: *,
   *   objectId: *,
   *   pointNumber: *,
   *   radius: *,
   *   route: *,
   *   stop: *,
   *   type: *,
   *   zero: *,
   *   zeroId: *,
   *   isFinal: *,
   * }[]|[]>}
   */
  static async getRoutePoint(routeId) {
    try {
      const data = await jet.http.post('/rpc?d=jsonRpc', {
        type: 'core-read',
        query: `sin2:/v:dba37e24-6a78-40c1-8615-4ab0d5e70535?filter=eq(field(".route"),param("${routeId}", "id"))`,
      });

      if (!!data && data.result) {
        const result = data.result || {};
        const items = result.data || [];
        const ci = result.columnIndexes || {};

        return items.map(it => ({
          id: it[ci['vcroutepoints.id']],
          name: it[ci['vcroutepoints.name']],
          coordinates: [
            it[ci['vcroutepoints.lon']] || it[ci['vcroutepoints.location.longitude']],
            it[ci['vcroutepoints.lat']] || it[ci['vcroutepoints.location.latitude']],
          ],
          closeDate: it[ci['vcroutepoints.closedate']],
          distance: it[ci['vcroutepoints.distance']],
          iSending: it[ci['vcroutepoints.isending']],
          limitSpeed: it[ci['vcroutepoints.limitspeed']],
          brdStrategy: it[ci['vcroutepoints.brdstrategy']],
          location: it[ci['vcroutepoints.location']],
          locationCode: it[ci['vcroutepoints.location.code']],
          locationLatitude: it[ci['vcroutepoints.location.latitude']],
          locationLongitude: it[ci['vcroutepoints.location.longitude']],
          locationName: it[ci['vcroutepoints.location.locname']],
          objectId: it[ci['vcroutepoints.objectid']],
          pointNumber: it[ci['vcroutepoints.pointnumber']],
          radius: it[ci['vcroutepoints.radius']],
          route: it[ci['vcroutepoints.route']],
          stop: it[ci['vcroutepoints.stop']],
          type: it[ci['vcroutepoints.type']],
          zero: it[ci['vcroutepoints.zero']],
          zeroId: it[ci['vcroutepoints.zeroid']],
          isFinal: it[ci['vcroutepoints.type']] === this.FINAL_STOP_GUID,
        }));
      }

      return [];
    } catch (e) {
      console.error('MapLayersService::getRoutePoint', e);

      return [];
    }
  }

  // TODO: никем не используется
  // static async get() {
  //   try {
  //     const data = jet.http.post('/rpc?d=jsonRpc', {
  //       type: 'core-read',
  //       query: 'sin2:/v:38947391-3994-48e0-9658-81b78c941e81'
  //     });
  //
  //     if (!!data && data.result) {
  //       const response = data.result;
  //       const rData = response.data || [];
  //
  //       const ci = response.columnIndexes || {};
  //
  //       return rData.map(it => ({
  //         id: it[ci['vcroutes.id']],
  //         alterDesc: it[ci['vcroutes.alterdesc']],
  //         startDate: it[ci['vcroutes.startdt']],
  //         endDate: it[ci['vcroutes.enddt']],
  //         routeCode: it[ci['vcroutes.routecode']],
  //         routeName: it[ci['vcroutes.routename']],
  //         routeTypeId: it[ci['vcroutes.routetypeid']],
  //         routeTypeIdTypename: it[ci['vcroutes.routetypeid.typename']],
  //         vehicleTypeId: it[ci['vcroutes.vehicletypeid']]
  //       }));
  //     }
  //     /*
  //     vcroutes.vehicletypeid: 13
  //     vcroutes.vehicletypeid.vctypename: 3
  //     vcroutes.verend: 7
  //     vcroutes.version: 4
  //     vcroutes.verstart: 6
  //     vcroutes.verstatus: 5
  //     _sec_attrs.groupid: 15
  //     _sec_attrs.tenantid: 14
  //     _sec_attrs.userid: 16
  //     _ssc_crud_delete: 19
  //     _ssc_crud_detail: 17
  //     _ssc_crud_update: 18
  //      */
  //   } catch (e) {
  //     console.error('MapLayersService::get', e);
  //
  //     return [];
  //   }
  // }

  /**
   * Получение геозон
   *
   * @returns {Promise<{
   *    objectTypeId: *,
   *    color: *,
   *    endDate: *,
   *    objectTypeName: *,
   *    description: *,
   *    type: *,
   *    polygonText: *,
   *    polygon: *,
   *    polygonName: *,
   *    name: *,
   *    tenantId: *,
   *    width: *,
   *    id: *,
   *    startDate: *
   *  }[]|*[]>}
   */
  static async getGeoZones() {
    try {
      const data = await jet.http.post('/rpc?d=jsonRpc', {
        type: 'core-read',
        query: `sin2:/v:${_GEO_ZONE_QUERY_ID}?filter=and(
            lte(field(".startDt"), var("util.date.truncToDay(dateEnd)")),
            or(
                isnull(field(".endDt")),
                gte(field(".endDt"), var("util.date.truncToDay(dateBegin)"))
            ),
            isnull(field(".objectTypeID"))
        )`,
      });

      if (!!data && data.result) {
        const result = data.result || {};
        const items = result.data || [];
        const ci = result.columnIndexes || {};

        return items.map(it => ({
          id: it[ci['vcpolygons.id']],
          startDate: it[ci['vcpolygons.startdt']],
          endDate: it[ci['vcpolygons.enddt']],
          color: it[ci['vcpolygons.color']],
          name: it[ci['vcpolygons.name']],
          description: it[ci['vcpolygons.description']],
          objectTypeId: it[ci['vcpolygons.objecttypeid']],
          objectTypeName: it[ci['vcpolygons.objecttypeid.name']],
          polygon: it[ci['vcpolygons.polygon']],
          polygonText: it[ci['vcpolygons.polygontext']],
          polygonName: it[ci['vcpolygons.polygontypename']],
          tenantId: it[ci['vcpolygons.tenantid']],
          type: it[ci['vcpolygons.type']],
          width: it[ci['vcpolygons.width']] * 1000,
          radius: it[ci['vcpolygons.width']] * 1000,
        }));
      }

      return [];
    } catch (e) {
      console.error('MapLayersService::getGeoZones', e);

      return [];
    }
  }

  /**
   * Удаление геозоны
   *
   * @param {String} geoZoneId Идентификатор геозоны
   * @return {Promise<boolean>}
   */
  static async removeGeoZone(geoZoneId) {
    try {
      await jet.http.post('/rpc?d=jsonRpc', {
        type: 'core-delete',
        query: `sin2:/v:${_GEO_ZONE_QUERY_ID}`,
        params: [
          { id: 'id', type: 'id', value: geoZoneId }
        ]
      });

      return true;
    } catch (e) {
      console.error('MapLayersService::removeGeoZone', e);

      return false;
    }
  }
  
    /**
   * Проверка, является ли геозона объектом какого-либо маршрута
   *
   * @param {String} geozoneID Идентификатор геозоны
   * @return {Promise<boolean>}
   */
  static async checkRouteObjects(geozoneID) {
    try {
      let query = `sin2:/v:fec8ea3e-dc88-4d95-96d0-bb128609a7f9?filter=eq(field(".object"),param("${geozoneID}", "id"))`;

      const data = await jet.http.post('/rpc?d=jsonRpc', {
        type: 'core-read',
        query: query,
      });

      if (!!data && data.result) {
        const result = data.result || {};
        const items = result.data || [];
        return items.length ? false : true;
      }

      return true;
    } catch (e) {
      console.error('MapLayersServiceService::checkRouteObjects', e);

      return false;
    }
  }

  /**
   * Обновление геозоны
   *
   * @param {String} id Идентификатор геозоны
   * @param {Object} geoZone Геозона
   * @return {Promise<boolean>}
   */
  static async updateOrCreateGeoZone(geoZone, id = null) {
    try {
      const width = (geoZone.type === 'CIRCLE'
        ? geoZone.radius
        : geoZone.width || 10) / 1000;
      const color = parseInt(this._rgbToHex(geoZone.color), 16);
      const startDate = (geoZone.startDate &&
        (new Date(geoZone.startDate)).getTime()) || null;
      const endDate = (geoZone.endDate &&
        (new Date(geoZone.endDate)).getTime()) || null;
      const type = geoZone.type;

      let points;

      if (type !== 'CIRCLE') {
        points = wkx.Geometry.parseGeoJSON(
          geoZone.__features.features[0].geometry).toWkt();
      } else {
        points = wkx.Geometry.parseGeoJSON({
          type: 'Point',
          coordinates: [
            geoZone.__center.lng,
            geoZone.__center.lat,
          ],
        }).toWkt();
      }

      const params = [
        { id: 'name', type: 'string', value: geoZone.name },
        { id: 'type', type: 'string', value: type },
        { id: 'width', type: 'double', value: +width },
        { id: 'color', type: 'integer', value: color },
        { id: 'description', type: 'string', value: geoZone.description },
        { id: 'startdt', type: 'date', value: startDate },
        { id: 'enddt', type: 'date', value: endDate },
        { id: 'polygonText', type: 'string', value: points }
      ];

      if (id !== null) {
        params.push(
          { id: 'id', type: 'id', value: id }
        );
      }

      return await jet.http.post('/rpc?d=jsonRpc', {
        type: id !== null ? 'core-update' : 'core-create',
        query: `sin2:/v:${_GEO_ZONE_QUERY_ID}`,
        params: params,
      });

      // return id !== null ? data.result[_GEO_ZONE_QUERY_ID] || [] : data.result;
    } catch (e) {
      console.error('MapLayersService::updateGeoZone', e);

      return false;
    }
  }

  /**
   * Парсинг
   *
   * @param item
   * @returns {null}
   */
  static parseWkx(item) {
    let type = item.type || null;
    const geometry = wkx.Geometry.parse(item.polygonText || item.polygontext);
    const ref = _copy(item);

    if (type === null) {
      if (_hasOwnProperty(geometry, 'exteriorRing')) {
        type = 'POLYGON';
      } else if (_hasOwnProperty(geometry, 'x') ||
        _hasOwnProperty(geometry, 'y')) {
        type = 'CIRCLE';
      } else {
        type = 'LINE';
      }
    }

    let result = null;

    switch (type) {
      case 'POLYGON':
        const points = geometry.exteriorRing.map(it => ([it.x, it.y]));

        result = {
          ref: ref,
          refGeometry: geometry,
          refType: type,
          type: 'Feature',
          properties: {},
          geometry: {
            type: 'Polygon',
            properties: {},
            coordinates: [points],
          },
        };

        break;

      case 'LINE':
        result = {
          ref: ref,
          refGeometry: geometry,
          refType: type,
          type: 'Feature',
          properties: {},
          geometry: {
            type: 'LineString',
            properties: {},
            coordinates: (geometry.points || []).map(it => ([it.x, it.y])),
          },
        };

        break;

      case 'CIRCLE':
        result = {
          ref: ref,
          refGeometry: geometry,
          refType: type,
          type: 'Feature',
          properties: {},
          geometry: {
            type: 'Point',
            properties: {},
            coordinates: [
              geometry.x,
              geometry.y,
            ],
          },
        };

        break;
    }

    return result;
  }

  /**
   * @param rgb
   * @return {string}
   * @private
   */
  static _rgbToHex(rgb) {
    if (typeof rgb === 'string') {
      if (rgb.substring(0, 1) === '#') {
        return rgb.substring(1);
      }

      return rgb;
    }

    return ((1 << 24) + (rgb.r << 16) + (rgb.g << 8) + rgb.b).toString(16).slice(1);
  }
}
