
<template>
    <div>
        <!-- Заголовок -->
        <v-row>
            <v-col cols="3" class="title pl-6">
                Поиск проезда
            </v-col>
        </v-row>
        <!-- Выбор остановок и поиск расписания -->
        <v-row>
            <v-col cols="3" class="title pl-6">
                <v-autocomplete
                    label="Отправление"
                    item-value="id"
                    v-model="value.stop1"
                    :items="locations"
                    :item-text="location => `${location.code} ${location.name}`">
                </v-autocomplete>
            </v-col>
            <v-col cols="3" class="title pl-6">
                <v-autocomplete
                    label="Прибытие"
                    item-value="id"
                    v-model="value.stop2"
                    :items="locations"
                    :item-text="location => `${location.code} ${location.name}`">
                </v-autocomplete>
            </v-col>
            <v-col cols="3" class="title pl-6">
                <v-btn color="primary"
                       @click="searchRoutes"
                       :loading="loading">
                    Показать расписание
                </v-btn>
            </v-col>
        </v-row>
        <v-data-table
            :headers="headers"
            :items="schedules"
            :loading="loading"
            loading-text="Получение расписания"
            height="500"
            fixed-header
            disable-pagination
            hide-default-footer>
            <template v-slot:item.btnSchedule="{ item }">
                <v-icon @click="showDialog(item)">
                    mdi-book-open-outline
                </v-icon>
            </template>
        </v-data-table>
        <!-- Окно с полным расписанием рейса -->
        <v-dialog
            v-model="dialog"
            max-width="900px">
            <v-card>
                <v-card-title>
                    <span class="headline">Расписание движения маршрута</span>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-data-table
                            :headers="headersSchedule"
                            :items="schedule"
                            height="400"
                            fixed-header
                            disable-pagination
                            hide-default-footer>
                        </v-data-table>
                        <v-row>
                            <v-col>{{ getCarrier }}
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>{{ getFirst }}
                            </v-col>
                            <v-col>{{ getSecond }}
                            </v-col>
                            <v-col>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>{{ getThird }}
                            </v-col>
                            <v-col>{{ getFourth }}
                            </v-col>
                            <v-col class="text-right">
                                <v-btn align-right color="primary"
                                       @click="dialog = false">
                                    Закрыть
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    import OtherService from '@/services/OtherService';

// Mixins
    import HideGlobalToolbarMixin from '@/components/dev/mixin/HideGlobalToolbarMixin';

    export default {
        mixins: [
            HideGlobalToolbarMixin,
        ],
        name: 'LocationSchedules',
        data: () => ({
                schedules: [],
                locations: [],
                schedule: [],
                dialogFooter: [],
                dialog: false,
                value: {
                    stop1: null,
                    stop2: null,
                },
                loading: true,
                carrier: '',
                // Таблица с рейсами маршрутов
                headers: [
                    {text: '№', value: 'n'},
                    {text: 'Контрольный ОП', value: 'routepointLocationLocname'},
                    {text: 'Прибытие на КП', value: 'arrtimeformat'},
                    {text: 'Отправление из КП', value: 'deptimeformat'},
                    {text: 'Номер маршрута', value: 'routepointRouteRoutecode'},
                    {text: 'Наименование маршрута', value: 'routepointRouteRoutename'},
                    {text: 'Отправление из НП маршрута', value: 'firstdeptime'},
                    {text: 'Прибытие на КП маршрута', value: 'lastarrtime'},
                    {text: 'КП маршрута', value: 'endstop'},
                    {text: 'Перевозчик', value: 'carriers'},
                    {text: 'Сезонность', value: 'workmode'},
                    {text: 'Расписание', value: 'btnSchedule'},
                ],
                // Таблица с расписанием рейса
                headersSchedule: [
                    {text: 'Код', value: 'routepointLocationCode', sortable: false},
                    {text: 'Наименование', value: 'routepointLocationLocname', sortable: false},
                    {text: 'Расстояние (км)', value: 'distance', sortable: false},
                    {text: 'Время прибытия', value: 'arrtimeformat', sortable: false},
                    {text: 'Время отправления', value: 'deptimeformat', sortable: false},
                    {text: ' ', value: 'stopsign', sortable: false},
                ],
            }),
        created() {
            this.init();
        },
        computed: {
            getFirst() {
                if (this.dialogFooter.length > 0) {
                    return this.dialogFooter[0];
                } else {
                    return null;
                }
            },
            getSecond() {
                if (this.dialogFooter.length > 1) {
                    return this.dialogFooter[1];
                } else {
                    return null;
                }
            },
            getThird() {
                if (this.dialogFooter.length > 2) {
                    return this.dialogFooter[2];
                } else {
                    return null;
                }
            },
            getFourth() {
                if (this.dialogFooter.length > 3) {
                    return this.dialogFooter[3];
                } else {
                    return null;
                }
            },
            getCarrier() {
                return this.carrier;
            }
        },
        methods: {
            async init() {
                this.loading = true;
                this.locations = await OtherService.getAllLocations();
                this.loading = false;
            },
            async searchRoutes() {
                this.loading = true;
                this.schedules = await OtherService.getLocationsSchedules(this.value.stop1, this.value.stop2);
                this.loading = false;
            },
            async showDialog(item) {
                this.loading = true;
                this.schedule = await OtherService.getScheduleStops(item.scheduleid);
                this.carrier = '';
                if (item.carriers) {
                    this.carrier = "Перевозчик: " + item.carriers;
                }
                this.dialogFooter = [];
                if (item.scheduleidSeasonName) {
                    this.dialogFooter.push("Сезонность: " + item.scheduleidSeasonName);
                }
                if (item.modname) {
                    this.dialogFooter.push("Режим: " + item.modname);
                }
                if (this.schedule.length > 0) {
                    this.dialogFooter.push("1-й рейс: " + this.schedule[0].firsttrip);
                    this.dialogFooter.push("Последний рейс: " + this.schedule[0].lasttrip);
                }
                this.dialog = true;
                this.loading = false;
            },
        },
    };
</script>
