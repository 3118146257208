<template>
  <v-dialog
    v-model="show"
    :width="width"
    :persistent="persistent"
  >
    <v-card>
      <!-- Заголовок -->
      <v-toolbar dark :color="toolbarColor">
        <slot name="title">
          <v-toolbar-title>
            {{ title }}
          </v-toolbar-title>
        </slot>

        <v-spacer/>

        <v-toolbar-items>
          <v-btn dark text @click="closeDialog">
            Закрыть
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <!-- Основное содержимое -->
      <v-card-text class="pa-2">
        <!-- Табы -->
        <v-tabs-items :value="currentTab">
          <!-- Основной таб -->
          <v-tab-item>
            <slot name="tab-1"></slot>
          </v-tab-item>

          <!-- Вторичный таб -->
          <v-tab-item>
            <slot name="tab-2"></slot>
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>

      <!-- Действия -->
      <v-card-actions class="pa-0">
        <slot name="actions"></slot>
      </v-card-actions>
    </v-card>

    <slot name="default"></slot>
  </v-dialog>
</template>

<script>
export default {
  props: {
    // Значение для отображения диалога
    value: {
      type: Boolean,
      required: true,
    },
    // Указатель текущей вкладки
    currentTab: {
      type: Number,
      required: true,
    },
    // Заголовок
    title: {
      type: String,
      default: '',
    },
    // Ширина диалогового окна
    width: {
      type: String,
      default: '600',
    },
    // Закрытие диалога при нажатии вне его
    persistent: {
      type: Boolean,
      default: false,
    },
    // Цвет toolbar
    toolbarColor: {
      type: String,
      required: false,
      default: 'primary',
    },
  },
  name: 'JetDialogData',
  methods: {
    // Закрытие диалога
    closeDialog() {
      this.show = false;

      this.$emit('close');
    },
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
}
</script>
