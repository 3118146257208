var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"grey lighten-3"},[_c('v-data-table',{staticClass:"grey lighten-3 mb-2",attrs:{"headers":_vm.headersInner,"items":_vm.items,"show-select":"","item-key":"index","disable-pagination":"","hide-default-footer":""},on:{"current-items":function($event){_vm.current = $event},"item-selected":_vm.bulkSelect},scopedSlots:_vm._u([{key:("item." + _vm.fieldName),fn:function(ref){
var item = ref.item;
return [_c('v-badge',{attrs:{"dot":"","inline":"","left":"","color":_vm.getColorByViolation(item[_vm.fieldName])}},[_vm._v(" "+_vm._s(item[_vm.fieldName])+" ")])]}},{key:("item." + _vm.fieldDate),fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item[_vm.fieldDate]))+" ")]}},{key:"item.btnComment",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.showAddComment(item)}}},[_vm._v(" mdi-pencil ")])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"title":"На карте"},on:{"click":function($event){return _vm.$emit('onMap', item)}}},[_vm._v(" mdi-map-marker-alert-outline ")])]}}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}),_c('v-layout',{staticClass:"pr-2 pb-2"},[_c('v-spacer'),_c('AddComment',{ref:"addComm",attrs:{"selected":_vm.selected},on:{"onApply":_vm.apply}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }