<template>
  <v-tabs-items v-model="vehicleTab">

    <!-- ТС -->
    <v-tab-item>
      <TabVehicles
        :fullDate="fullDate"
        @selectVehicle="goTrips"
      ></TabVehicles>
    </v-tab-item>

    <!-- Постановки -->
    <v-tab-item>
      <TabTrips
        :fullDate="fullDate"
        :selectedVehicle="selectedVehicle"
        @back="goVehicles"
      ></TabTrips>
    </v-tab-item>
  </v-tabs-items>
</template>

<script>
  import TabVehicles from "./mainTabVehicles/TabVehicles";
  import TabTrips from "./mainTabVehicles/TabTrips";

  export default {
    name: "MainTabVehicles",
    components: {
      TabTrips,
      TabVehicles,
    },
    props: {
      // Выбранная дата
      fullDate: {
        type: Object,
        required: true,
      },
    },
    data: () => ({
      // Вкладки ТС и Постановки ТС
      vehicleTab: 0,
      // Выбранное ТС
      selectedVehicle: {
        gov: '',
        oname: '',
      },
    }),
    methods: {
      goTrips(vehicle) {
        this.selectedVehicle = vehicle;
        this.vehicleTab = 1;
      },
      goVehicles() {
        this.selectedVehicle = {
          gov: '',
          oname: '',
        };
        this.vehicleTab = 0;
      },
    },
  };
</script>
