<template>
  <div>
    <v-data-table
      v-show="!dialog"
      :headers="headers"
      :items="filteredDisruptions"
      :search="search"
      :loading="loading"
    >
      <template v-slot:item="row">
        <tr @click="openDialog(row.item)">
          <td>{{ row.item.routeName }}</td>
          <td>{{ row.item.tripCode }}</td>
          <td>{{ row.item.govnum }}</td>
          <td>{{ row.item.time }}</td>
          <td>{{ row.item.typeName }}</td>
          <td>
            <span v-html="getDialogStatus(row.item.status)"></span>
            <v-icon v-if="row.item.status === 2" color="grey">mdi-paperclip</v-icon>
          </td>
        </tr>
      </template>
    </v-data-table>

    <DisruptionPage
      v-if="dialog"
      :message="selectedViolation"
      :isModerator="isModerator"
      @refresh="closeDialog"
    >
    </DisruptionPage>
  </div>
</template>

<script>
import DisruptionPage from "@/components/dev/modules/notifications/components/components/DisruptionPage";
import OtherService from "@/services/OtherService";

var WS_URI = {
    servers: process.env.VUE_APP_BACKEND_NATS_SERVER,
    user: process.env.VUE_APP_BACKEND_NATS_USERNAME,
    pass: process.env.VUE_APP_BACKEND_NATS_PASSWORD
};

export default {
  name: 'DisruptionTab',
  components: {
    DisruptionPage
  },
  props: {
    search: {
      type: String,
      default: '',
    },
    isModerator: {
      type: Boolean,
      default: false,
    },
    newOnly: {
      type: Boolean,
      required: true,
    },
  },
  data: () => ({
    disruptions: [],
    loading: true,
    dialog: false,
    selectedViolation: {},
    headers: [
      {text: 'Маршрут', value: 'routeName'},
      {text: 'Рейс', value: 'tripCode'},
      {text: 'ТС', value: 'govnum'},
      {text: 'Время', value: 'time'},
      {text: 'Причина', value: 'typeName'},
      {text: 'Статус', value: 'status'},
    ],
    nats: null,
    sids: [],
  }),
  computed: {
    filteredDisruptions() {
      if (this.newOnly) {
        return this.disruptions.filter(dis => dis.status === 1 || dis.status === 2 );
      } else {
        return this.disruptions;
      }
    },
  },
  created() {
    this.init();
  },
  destroyed() {
    this.disconnectNats();
  },
  methods: {
    async init() {
      this.loading = true;
      this.disruptions = await OtherService.getDisruptions();
      this.loading = false;
      this.connectNats();
    },
    async openDialog(violation, regid) {
      if (typeof violation === 'object') {
        this.selectedViolation = violation;
        this.dialog = true;
        await this.editStatus(violation);
      } else {
        if (!this.loading) {
          const tmpViolation = this.disruptions.find(item => {
            return item.id === violation;
          });
          if (tmpViolation) {
            this.selectedViolation = tmpViolation;
          } else {
            const loadedViol = await OtherService.getDisruption(violation, regid);
            if (loadedViol) {
              this.selectedViolation = loadedViol;
            } else {
              jet.msg({
                text: 'Нарушение не найдено!',
                color: 'Error'
              });
            }
            return;
          }
          await this.editStatus(this.selectedViolation);
          this.dialog = true;
        } else {
          setTimeout(() => {
            this.openDialog(violation, regid);
          }, 1000);
        }
      }
    },
    async editStatus(violation) {
      if (!violation.readdt) {
        if (await OtherService.editStatus(violation.regid, OtherService.STATUS_NOTIFICATION.CONFIRM_READ)) {
          violation.readdt = true;
        }
      }
    },
    closeDialog() {
      this.dialog = false;
      this.selectedViolation = {};
      this.init();
    },
    async connectNats() {
      if (!this.nats) {
        this.codec = await jet.http.getJSONCodec();
        this.nats = await jet.http.getNats(WS_URI);

        if (this.nats) {
          const userId = this.$store.state.auth.subject.id;
          const s = this.nats.subscribe('PUBLIC.kigat.eventMessaging.type.tc.' + userId);
          (async ()=>{
            for await (const m of s) {
              try{
                var msg = codec.decode(m.data);
                this.onNatsMessageReceive(msg);
              } catch(e){
                console.log('ERR (on_m_recieve):', e);
              }
            }
          })();
          this.sids.push(s);
        }
      }
    },
    disconnectNats() {
      if (this.nats) {
        this.sids.forEach(s => s.unsubscribe());
        this.sids = [];
        this.nats.disconnect();
        this.nats = null;
      }
    },
    async onNatsMessageReceive(message) {
      const data = JSON.parse(message);
      const event = {
        regid: data.id,
        eventid: data.eventId,
      };
      const item = await OtherService.getDisruption(event);
      this.disruptions.push(item);
      jet.msg({
        text: 'Добавлен новый срыв',
      });
    },
    getDialogStatus(number) {
      switch (number) {
        case 0: return 'Новый';
        // Без файла
        case 1:
        // С файлом
        case 2: return this.isModerator ? 'Есть комментарий' : 'Комментарий отправлен';
        case 3: return 'Отклонено';
        case 4: return 'Принято';
        default: return '<div class="grey--text">Статус отсутствует</div>';
      }
    },
  },
};
</script>

<style scoped lang="sass">
.red-border
  border: 1px solid red
  border-collapse: collapse

.row > .col
  padding: 0 0 4px

.container > .row
  padding: 4px 20px 0
</style>
