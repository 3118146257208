<template>
  <div>

    <!-- toolbar -->
    <v-row>
      <v-col class="title pl-6">1.6.4 Уведомления о тарифах</v-col>
      <v-col cols="auto" align="right">
        <v-btn
          color="primary"
          @click="dialogCreate = true"
        >
          Добавить тариф
        </v-btn>
      </v-col>
      <v-col cols="auto" align="right">
        <v-select
          v-model="mode"
          :items="modes"
          label="Отобразить"
          item-text="title"
          return-object
          style="width: 175px;"
          outlined
          dense
          hide-details
        ></v-select>
      </v-col>
      <v-col cols="auto" align="right">
        <JetSearchField
          v-model="search"
          style="width: 500px"
        ></JetSearchField>
      </v-col>
    </v-row>

    <!-- Таблица -->
    <v-data-table
      :headers="headers"
      :items="filteredTariffs"
      :search="search"
      :loading="loading"
      no-data-text="Отчётов не найдено"
      sort-by="date"
      @click:row="openTariffPage"
    >
      <template v-slot:item.isRead="{ item }">
        {{ item.isRead ? 'Прочитано' : 'Новый' }}
      </template>
    </v-data-table>

    <!-- Диалог создания -->
    <CreateTariff
      v-if="dialogCreate"
      v-model="dialogCreate"
      @refresh="init"
    ></CreateTariff>

    <ShowTariff
      v-if="dialogShow"
      v-model="dialogShow"
      :tariff="selectedTariff"
      @refresh="init"
    ></ShowTariff>
  </div>
</template>

<script>
// Components
import JetSearchField from '@/components/JetSearchField';
import CreateTariff from '@/components/dev/modules/tariffing/components/CreateTariff';
import ShowTariff from '@/components/dev/modules/tariffing/components/ShowTariff';

// Service
import OtherService from '@/services/OtherService';

// Mixins
import HideGlobalToolbarMixin from '@/components/dev/mixin/HideGlobalToolbarMixin';

export default {
  mixins: [
    HideGlobalToolbarMixin,
  ],
  name: 'Tariffing',
  components: {
    JetSearchField,
    CreateTariff,
    ShowTariff,
  },
  data: () => ({
    loading: true,
    tariffs: [],
    selectedTariff: null,
    dialogShow: false,
    dialogCreate: false,
    search: '',
    headers: [
      { text: 'Дата', value: 'uploadDt' },
      { text: 'Перевозчик', value: 'shortName' },
      { text: 'Статус', value: 'isRead' },
    ],
    mode: null,
    modes: [
      { title: 'Все', value: -1 },
      { title: 'Новые', value: false },
      { title: 'Прочитанные', value: true },
    ],
  }),
  created() {
    this.mode = this.modes[0];
    this.init();
  },
  computed: {
    filteredTariffs() {
      return this.tariffs.filter(
        rep => (
          this.mode.value === -1
          || rep.isRead === this.mode.value
        ),
      );
    },
  },
  methods: {
    async init() {
      this.dialogCreate = false;
      this.dialogShow = false;
      this.loading = true;
      this.tariffs = await OtherService.getTariffs();
      this.loading = false;
    },
    openTariffPage(tariff) {
      this.selectedTariff = tariff;
      this.dialogShow = true;
    },
  },
};
</script>
