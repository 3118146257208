// Префикс для ключей с настройками карты
const prefix = '_mapSettings';

// Время жизни ключа (10 минут)
const deltaTime = 10 * 60 * 1000;

/**
 * Сервис для работы с настройками отображения трека на карте
 *
 * TIP: $utils.readLocalStorage не используется для ускорения работы
 */
export default class MapSettingsService {
  // Типы остановок
  static stopsEnum = {
    // Все
    all: 'all',
    // Только конечные
    stops: 'stops',
  };

  /**
   * Получение данных по-умолчанию
   *
   * @return {{
   *    color: {hexa: String,},
   *    width: number,
   *    showStops: string,
   *    stopTypes: {parking: boolean, stop: boolean, speedUp: boolean, noData: boolean, moving: boolean},
   * }}
   */
  static getDefaultData() {
    return {
      // Цвет трека
      color: '#0000FFFF',
      // Ширина линии
      width: 3,
      // Показ остановок
      showStops: this.stopsEnum.all,
      // Типы остановок
      stopTypes: {
        // Движется
        moving: true,
        // Остановка
        stop: true,
        // Парковка
        parking: true,
        // Нет данных
        noData: true,
        // Превышение скорости
        speedUp: true,
      },
    };
  }

  /**
   * Сохранение настроек
   *
   * @param {Object} vehicle ТС
   * @param {Object} settings Примененные настройки
   */
  static saveSettings(vehicle, settings) {
    const key = this._key(vehicle.id);
    const currentTime = (new Date()).getTime();
    const expiredAt = new Date(currentTime + deltaTime);

    const data = Object.assign(settings, {
      expired: expiredAt,
    });

    localStorage.setItem(key, JSON.stringify(data));
  }

  /**
   * Получение настроек
   *
   * Если нет в кеше, то вернутся значения по-умолчанию
   *
   * @param {Object} vehicle ТС
   */
  static getSettings(vehicle) {
    const key = this._key(vehicle.id);
    const result = localStorage.getItem(key);

    // Если данных не было
    if (result === null) {
      // То вернем по-умолчанию и сразу сохраним их
      this.saveSettings(vehicle, this.getDefaultData());

      return this.getDefaultData();
    }

    return JSON.parse(result);
  }

  /**
   * Удаление всех настроек, которые более не актуальны
   */
  static removeExpired() {
    Object.keys(localStorage).forEach(key => {
      // Если в имени ключа есть наш префикс
      if (key.indexOf(prefix) > -1) {
        const item = JSON.parse(localStorage.getItem(key));
        const expired = item.expired;

        const currentTime = (new Date()).getTime();
        const expiredTime = (new Date(expired)).getTime();

        // Если элемент необходимо удалить
        if (currentTime > expiredTime) {
          localStorage.removeItem(key);
        } else {
          // Иначе продляем срок жизни
          item.expired = new Date((new Date()).getTime() + deltaTime);

          localStorage.setItem(key, JSON.stringify(item));
        }
      }
    });
  }

  /**
   * Получение ключа для localStorage
   *
   * @param {String} vehicleId Идентификатор ТС
   * @return {string}
   * @private
   */
  static _key(vehicleId) {
    return `${vehicleId}_${prefix}`;
  }
}
