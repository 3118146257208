<template>
    <v-dialog v-model="view" max-width="1600" scrollable>
        <v-card class="jet-conte" flat width="100%">
            <v-card-title>
                Данные аудита: {{name}}
            </v-card-title>
            <v-card-text>
                <jet-table 
                    mode="single" 
                    :model="model"
                    :rows="items"
                    :focused="true"
                    :loading="!loaded" />
            </v-card-text>
            <v-card-actions class="justify-end">
                <v-btn outlined tile small color="default" @click="view = !view">
                    закрыть
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
import Model from '@/core/Model';
import JetTable from '@/components/JetTable.vue';

export default {
    name: 'JetAuditForm',
    components: {
        JetTable
    },
    data() {
        return {
            model: {},
            items: [],
            id: null,
            name: null,
            view: false
        };
    },
    computed: {
        loaded() {
            return this.refresh();
        },
        uri() {
            return `sin2:/v:536934b1-a672-4f39-bf5a-3144623f0e6f/?filter=eq(field(".objectID"), param("${this.id}", "id"))`;
        }
    },
    methods: {
        audit(id, name) {
            this.id = id;
            this.name = name;
            this.view = true;
        },
        async refresh(){
            if (!this.$store.getters['profile/isAuthenticated']){
                return false;
            }
            var res = false;
            try {
                var data = await $http.post('/rpc?d=jsonRpc', {
                    type: 'core-read',
                    query: this.uri
                });
                if (!!data.error){
                    throw data.error;
                }
                
                const m = new Model(data.result.model);
                const ci = m.columnIndexes, 
                      r = [];
                data.result.data.map((_raw, i)=>{
                    var row = {};
                    for (const columnId in ci) {
                        if ( ci.hasOwnProperty(columnId) ) {
                            row[columnId] = _raw[ci[columnId]];
                        }
                    }
                    row[m.columnId] = _raw[m.columnIndexes[m.columnId]] + '-' + i;
                    r.push(row);
                });
                this.model = m;
                this.items = r;
                res = true;
            } catch(err) {
                console.log(err);
                jet.msg({text:'ВНИМАНИЕ! Ошибка получения данных<br /><small>Информация для технической поддержки: ' + err.message + '</small>', color:'warning'});
            }
            return res;
        }
    }
}
</script>
