<template>
  <div class="px-4">
    <template v-if="!dialog">
      <template v-if="!loading">
        <v-row>
          <v-col>
            <v-btn color="primary" @click="getModificationTable">Обновить</v-btn>
          </v-col>
          <v-col>
            <JetSearchField
              v-model="search"
            ></JetSearchField>
          </v-col>
        </v-row>
        <v-data-table
          :headers="headers"
          :items="applications"
          :search="search"
        >
          <template v-slot:item="row">
            <tr @click="openDialog(row.item)">
              <td>{{ row.item.title }}</td>
              <td>{{
                  !row.item.moderationdt ? 'Новый'
                    : row.item.status ? 'Принято' : 'Отклонено'
                }}
              </td>
            </tr>
          </template>
        </v-data-table>
      </template>
      <div v-else class="b-spinner align-center mt-6" align="center">
        <v-progress-circular indeterminate color="primary"/>
      </div>
    </template>
    <Record
      v-else
      :selected-application="selectedApplication"
      @back="closeDialog"
    ></Record>
  </div>
</template>

<script>
import Record from './modules/Record';
import ProfilesService from '@/components/dev/service/ProfilesService';
import JetSearchField from '@/components/JetSearchField';
import HideGlobalToolbarMixin from '@/components/dev/mixin/HideGlobalToolbarMixin';

export default {
  mixins: [
    HideGlobalToolbarMixin,
  ],
  name: 'Moderation',
  components: {
    JetSearchField,
    Record,
  },
  data: () => ({
    search: '',
    dialog: false,
    loading: true,
    selectedApplication: {},
    headers: [
      { text: 'Автоперевозчик', value: 'title' },
      { text: 'Статус', value: 'status' },
    ],
    applications: [],
  }),
  created() {
    this.getModificationTable();
  },
  methods: {
    async getModificationTable() {
      this.loading = true;
      this.applications = await ProfilesService.getModificationProfilesTable();
      this.loading = false;
    },
    openDialog(application) {
      this.selectedApplication = application;
      this.dialog = true;
    },
    closeDialog(change) {
      this.dialog = false;
      this.selectedApplication = {};
      if (change || true) {
        this.getModificationTable();
      }
    },
  },
};
</script>
