<template>
  <div>
    <v-flex>
      <v-btn
        outlined
        small
        @click="dialog = true, init(null)"
        :disabled="selected.length === 0"
      >
        Примечание
      </v-btn>
    </v-flex>

    <v-dialog
      v-if="dialog"
      v-model="dialog"
      width="600"
    >
      <v-card>
        <v-toolbar
          dark
          color="primary"
        >
          <v-toolbar-title>
            Добавить примечание
          </v-toolbar-title>

          <v-spacer></v-spacer>

          <v-toolbar-items>
            <v-btn
              dark
              text
              @click="dialog = false"
            >
              Закрыть
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>

        <v-divider/>

        <v-card-text>
          <v-form v-model="isValid" ref="form">
            <v-select
              label="Причина нарушения"
              v-model="typeViolations"
              :items="typesViolations"
              return-object
              :rules="[val => !!val || 'Обязательное поле']"
              required
            ></v-select>

            <v-textarea
              v-model="comment"
              label="Примечание"
              outlined
              :rules="[(val) => val.length < 255 || 'Максимальная длинна 255 символов', requiredComment]"
              required
            ></v-textarea>

            <v-textarea
              v-model="result"
              label="Результат"
              outlined
              :rules="[(val) => val.length < 255 || 'Максимальная длинна 255 символов']"
            ></v-textarea>

            <v-checkbox
              v-model="setToAll"
              label="Проставить по всем нарушениям ТС за день"
              class="pt-0 mt-0"
            ></v-checkbox>

          </v-form>
        </v-card-text>

        <v-divider/>

        <v-card-actions>
          <v-spacer/>

          <v-btn
            @click="apply"
            :loading="loadingApply"
            color="primary"
          >
            Отправить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { getViolationTypes } from '@/components/dev/modules/violations/ViolationsService';

export default {
  props: {
    selected: {
      type: Array,
    },
  },
  name: 'AddComment',
  data: () => ({
    dialog: false,
    typeViolations: null,
    typesViolations: [],
    loadingTypesViolations: false,
    comment: '',
    result: '',
    setToAll: false,
    loadingApply: false,
    isValid: false,
    idFromRow: null,
  }),
  watch: {
    result(val) {
      if ((!!val && val.length > 0) && (!this.comment || this.comment === '')) {
        this.$refs.form.resetValidation();
      }
    },
  },
  methods: {
    // Функция, прокидывающая событие комментирования нарушений
    apply() {
      this.$refs.form.validate();
      if (this.isValid) {
        const ids = this.idFromRow ? this.idFromRow : this.selected
          .map(i => '"' + i['vctripscontrol.id'] + '"')
          .join(',');
        this.loadingApply = true;
        jet.http.post({
          type: 'query',
          query: '03f13af2-6c3a-4277-b68f-b2bdccf82add.storeComms',
          params: {
            in_violationReasonID: this.typeViolations.id,
            in_comment: this.comment,
            in_result: this.result,
            in_setToAll: this.setToAll,
            in_IDs: '{' + ids + '}',
          },
        }).then(() => {
          this.dialog = false;
          this.loadingApply = false;
          this.$emit('onApply', this.selected);
        });
      }
    },

    async init(item) {
      this.comment = '';
      this.result = '';
      this.setToAll = false;
      this.typeViolations = null;
      this.idFromRow = null;

      await this.getTypesViolations();
      if (item) {//если вызывается из кнопки в строке
        this.dialog = true;
        this.idFromRow = item["vctripscontrol.id"];
        this.comment = item["comment"] ? item["comment"] : '';
        const type = this.typesViolations.find(type => type.id === item["reasonId"]);
        if (type) {
          this.typeViolations = type;
        }
      } else if (this.selected?.length === 1) {
        this.comment = this.selected?.[0]?.comment || '';
        const type = this.typesViolations.find(type => type.id === this.selected?.[0]?.reasonId);
        if (type) {
          this.typeViolations = type;
        }
      } else {
        this.comment = '';
        this.typeViolations = null;
      }
    },

    async getTypesViolations() {
      this.loadingTypesViolations = true;
      this.typesViolations = await getViolationTypes();
      this.loadingTypesViolations = false;
    },

    requiredComment(val) {
      if (val || this.result) {
        return true;
      }
      return 'Обязательное поле';
    },
  },
};
</script>
