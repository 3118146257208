<template>
    <div class="jet-main-conte">
        <jet-app-bar v-on:navshow='toggleNav' 
                     v-on:tenants='showTenants'/>
        <jet-nav-bar ref="jetNavBar" />
        <jet-tab-bar ref="tabs" />
        <jet-report ref="rep" :report="report" :values="values" v-on:hide="report = false"/>
        <jet-footer-bar v-on:onperiod="onPeriod" />
        <the-dialog-period :visible="showPeriod" />
        <jet-tenants-dlg ref="tenants-dlg" />
    </div>
</template>

<script>
import JetAppBar from '@/components/JetAppBar';
import JetNavBar from '@/components/JetNavBar';
import JetFooterBar from '@/components/JetFooterBar';
import JetTabBar from '@/components/JetTabBar';
import Report from '@/core/Report';
import JetReport from '@/components/JetReport';
import TheDialogPeriod from '@/components/TheDialogPeriod';
import JetTenantsDlg from '@/components/JetTenantsDlg';

class ApiCollections {
    constructor(o){
        this._own = o;
    }
    open(ci){
          this._own.$store.commit('colls/active', ci);
        }
    refresh(){
        const a = this.active;
        if ( !!a?.refresh ){
            a.refresh();
        }
    }
    get active(){
        return this._own.$refs.tabs.getActiveCollection();
    }
    
}  //api

class ApiReports {
    constructor(o){
        this._own = o;
    }
    open(ci, newTab = true, values){
        (async ()=>{
            try {
                const rid = ci.uri.substr(8, 36);
                const res = await $http.post({
                                type: 'core-report-info',
                                name: rid
                      });
                if (!!res.error){
                    throw res.error;
                }
                const r = new Report(res.result);
                console.log('Report:', r, r.params);
                
                //Открываем форму заполнения параметров отчета в отдельной вкладке
                if (newTab) {
                    r.uri = ci.uri;
                    this._own.$store.commit('colls/active', r);
                    
                //Открываем форму заполнения параметров отчета как popup
                } else {
                    this._own.doReport(r, values);
                }
                
            } catch(e){
                console.log(e);
            }
        })();
    }
}  //api


export default {
    name: 'Main',
    components: {
        JetAppBar,
        JetNavBar,
        JetFooterBar,
        JetReport,
        JetTabBar,
        TheDialogPeriod,
        JetTenantsDlg
    },
    data: function() {
        return {
            report: null,
            values: null,
            showPeriod: false
        };
    },
    methods: {
        toggleNav(){
            this.$refs.jetNavBar.toggleNav();
        },
        doReport(model, values){
            this.values = values;
            this.report = model;
        },
        onPeriod(){
            if (!!this.showPeriod){
                this.showPeriod = false;
            } else {
                this.showPeriod = (new Date()).getTime();
            }
        },
        showTenants(){
            this.$refs["tenants-dlg"].open();
        }
    },
    created(){
        jet.collections = new ApiCollections(this);
        jet.reports = new ApiReports(this);
    },
    mounted(){
        var ci = $utils.readLocalStorage('last-coll');
        if (!!ci){
            jet.collections.open(ci);
        }
    }
};
</script>
<style lang="scss" scoped>
    .jet-main-conte{
        height: 100%;
    }
</style>
