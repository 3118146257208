/**
 * Содержит методы подписки на жизненные циклы компонента
 * для показа и скрытия Toolbar
 */
export default {
  // Скрываем Toolbar
  mounted() {
    setTimeout(() => {
      $('.v-window-item--active .split > .v-toolbar').css({
        display: 'none',
      });
    }, 300);
  },
  // Показываем Toolbar
  destroyed() {
    $('.v-window-item--active .split > .v-toolbar').css({
      display: '',
    });
  },
};
