var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-list-item',[_c('v-list-item-content',{staticClass:"pr-4"},[_vm._v(" "+_vm._s(_vm.item.name)+" ")]),_c('v-list-item-action',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticStyle:{"margin":"-4px 0"},attrs:{"fab":"","x-small":"","depressed":"","color":_vm.btnColor,"loading":_vm.loading_points,"disabled":_vm.loading_data},on:{"click":_vm.showGeoZone}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-eye-outline")])],1)]}}])},[_c('span',[_vm._v("Показать на карте")])])],1),_c('v-list-item-action',{staticClass:"ml-2"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticStyle:{"margin":"-4px 0"},attrs:{"fab":"","x-small":"","depressed":"","loading":_vm.loading_data,"disabled":_vm.loading_points},on:{"click":_vm.editGeoZone}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-pencil-outline")])],1)]}}])},[_c('span',[_vm._v("Редактировать")])])],1),_c('v-list-item-action',{staticClass:"ml-2"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticStyle:{"margin":"-4px 0"},attrs:{"fab":"","x-small":"","depressed":"","disabled":_vm.loading_points || _vm.loading_data},on:{"click":_vm.deleteBtnClick}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-close")])],1)]}}])},[_c('span',[_vm._v("Удалить")])])],1),_c('v-dialog',{attrs:{"width":"600"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_vm._v("Удалить геозону?")]),_c('v-divider'),_c('v-card-text',{staticClass:"pt-4 body-1"},[_vm._v(" "+_vm._s(_vm.toDelete ? ("Подтвердите, что хотите удалить геозону \"" + (this.item.name) + "\"") : ("Геозона \"" + (this.item.name) + "\" не может быть удалена, поскольку является объектом маршрута"))+" ")]),_c('v-divider'),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"red","outlined":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" Отмена ")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"red","disabled":!_vm.toDelete,"dark":"","loading":_vm.loading},on:{"click":_vm.removeGeoZone}},[_vm._v(" Удалить ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }