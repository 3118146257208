var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-toolbar',[_c('v-row',{staticClass:"pt-4 align-center"},[_c('v-col',[_c('v-select',{attrs:{"label":"Группа","placeholder":"Выберите группу маршрутов","items":_vm.groups,"item-text":"title","item-value":"id","disabled":_vm.disableGroups,"hide-details":""},model:{value:(_vm.group),callback:function ($$v) {_vm.group=$$v},expression:"group"}})],1),_c('v-col',[_c('v-menu',{ref:"menu1",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"readonly":"","persistent-hint":"","hide-details":"","label":"Дата"},model:{value:(_vm.formattedDate),callback:function ($$v) {_vm.formattedDate=$$v},expression:"formattedDate"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu1),callback:function ($$v) {_vm.menu1=$$v},expression:"menu1"}},[_c('v-date-picker',{attrs:{"no-title":"","first-day-of-week":1,"weekday-format":_vm.dayOfWeekToFormat},on:{"input":function($event){_vm.menu1 = false}},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})],1)],1),_c('v-col',[_c('v-text-field',{attrs:{"hide-details":"","label":"Маршрут","placeholder":"Номер или название"},model:{value:(_vm.route_name),callback:function ($$v) {_vm.route_name=$$v},expression:"route_name"}})],1),_c('v-col',[_c('v-text-field',{attrs:{"hide-details":"","label":"Перевозчик/ТС","placeholder":"Рег.номер ТС или название"},model:{value:(_vm.vehicle_num),callback:function ($$v) {_vm.vehicle_num=$$v},expression:"vehicle_num"}})],1),_c('v-col',[_c('v-select',{attrs:{"hide-details":"","multiple":"","label":"Тип нарушения","item-text":"title","item-value":"id","placeholder":"Выберите тип","items":_vm.types},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(index === 0)?_c('v-chip',{attrs:{"small":""}},[_c('span',[_vm._v(_vm._s(item))])]):_vm._e(),(index === 1)?_c('span',{staticClass:"grey--text caption"},[_vm._v(" (+"+_vm._s(_vm.type.length - 1)+" тип"+_vm._s(_vm.type.length >= 6 ? 'ов' : _vm.type.length >= 3 ? 'a' : '')+") ")]):_vm._e()]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-badge',{attrs:{"dot":"","inline":"","color":_vm.getColorByViolation(item)}}),_vm._v(" "+_vm._s(item)+" ")]}}]),model:{value:(_vm.type),callback:function ($$v) {_vm.type=$$v},expression:"type"}})],1)],1)],1),_c('v-toolbar',[_c('v-row',{staticClass:"align-center"},[_c('v-col',{staticClass:"col-auto"},[_vm._v(" Отображено маршрутов: "+_vm._s(_vm.countViolations)+" ")]),_c('v-col',{staticClass:"col-auto"},[_c('v-col',{staticClass:"px-0",attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"color":"primary","icon":"","title":"Обновить список"},on:{"click":_vm.init}},[_c('jet-svg',{attrs:{"xref":"#ico-refresh"}})],1)],1)],1),_c('v-spacer'),_c('v-col',{staticClass:"col-auto",attrs:{"jus":""}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('NewViolation',{on:{"refresh":_vm.refresh}})],1)],1)],1)],1),_c('v-data-table',{attrs:{"show-expand":"","item-key":"id","headers":_vm.headers,"items":_vm.filtered_list,"single-expand":_vm.singleExpand,"expanded":_vm.expanded,"loading":_vm.loading,"disable-pagination":"","hide-default-footer":""},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"item.count",fn:function(ref){
var item = ref.item;
return [_vm._l((_vm.getViolationTypes(item)),function(type,index){return [_c('v-badge',{key:((item.id) + "_" + index),attrs:{"dot":"","inline":"","color":_vm.getColorByViolation(type)}}),_vm._v(" "+_vm._s(_vm.getViolationTypeShort(type))+" ")]})]}},{key:"item.countViolationOnExpand",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(" "+_vm._s(Array.isArray(item.items) ? item.items.length : '')+" ")])]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('div',{staticClass:"py-0"},[_c('VehicleViolations',{attrs:{"headersInner":_vm.headersInner,"items":item.items},on:{"onApply":_vm.apply,"onMap":_vm.goMap}})],1)])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }