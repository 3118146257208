<template>
  <div>

    <!-- Заголовок -->
    <v-row>
      <v-col cols="3" class="title pl-6">
        Инциденты
      </v-col>
      <v-col cols="3" align="right">
        <v-btn color="primary" @click="dialogCreate = true">
          Создать инцидент
        </v-btn>
      </v-col>
      <v-col cols="6">
        <JetSearchField
          v-model="search"
        ></JetSearchField>
      </v-col>
    </v-row>

    <!-- Таблица -->
    <v-data-table
      v-show="!incidentPage"
      :headers="headers"
      :items="incidents"
      :loading="loading"
      :search="search"
      @click:row="openIncidentPage"
    >

      <!-- Слот: Текст -->
      <template #item.text="{ item }">
        <div>
          {{ item.text.length > 25 ? item.text.substring(0, 22) + '...' : item.text }}
        </div>
      </template>

      <!-- Слот: Файл -->
      <template #item.file="{ item }">
        <v-btn
          v-if="item.file"
          class="px-0"
          small
          text
          style="text-transform: initial"
          @click="openFile(item)"
          @click.native="check"
        >
          <u>{{ item.file.name || 'Файл' }}</u>
        </v-btn>
      </template>
    </v-data-table>

    <!-- Страница с инцидентом -->
    <IncidentPage
      v-if="incidentPage"
      :message="selectedIncident"
      :isModerator="isModerator"
      @back="closeIncidentPage"
    ></IncidentPage>

    <!-- Диалог добавлениия инцидента -->
    <CreateIncident
      v-if="dialogCreate"
      v-model="dialogCreate"
      @refresh="init"
    ></CreateIncident>

  </div>
</template>

<script>
import IncidentPage from './components/IncidentPage';
import OtherService from '@/services/OtherService';
import JetSearchField from '@/components/JetSearchField';
import CreateIncident from './components/CreateIncident';

// Mixins
import HideGlobalToolbarMixin from '@/components/dev/mixin/HideGlobalToolbarMixin';

export default {
  mixins: [
    HideGlobalToolbarMixin,
  ],
  name: 'Incidents',
  components: {
    CreateIncident,
    JetSearchField,
    IncidentPage,
  },
  props: {
    isModerator: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    incidents: [],
    loading: true,
    // Поиск по таблице
    search: '',
    // Таблица
    headers: [
      { text: 'Тип', value: 'typeName' },
      { text: 'Дата события', value: 'eventdate', sortable: false },
      { text: 'Заголовок', value: 'title' },
      { text: 'Текст', value: 'text' },
      { text: 'Файл', value: 'file' },
    ],
    // Отображение страницы инцидента
    incidentPage: false,
    // Выбранный инцидент
    selectedIncident: {
      date: '',
    },
    // Диалог создания инцидента
    dialogCreate: false,
  }),
  created() {
    this.init();
  },
  methods: {
    async init() {
      this.loading = true;
      this.incidents = await OtherService.getAllIncidents();
      this.loading = false;
    },
    openIncidentPage(message) {
      this.selectedIncident = message;
      this.incidentPage = true;
    },
    closeIncidentPage() {
      this.incidentPage = false;
      this.selectedIncident = {
        date: '',
      };
    },
    openFile(item) {
      location.href = '/rpc/?d=file&uri=' + item.file.ref;
    },
    check(e) {
      e.cancelBubble = true;
    },
  },
};
</script>
