import { setPadding } from '@/utils/utils';

/**
 * Сервис для работы с датой
 * TODO: Вынести все утилиты и все константы сюда
 */

// Дни недели
const daysOfWeek = ['ВС', 'ПН', 'ВТ', 'СР', 'ЧТ', 'ПТ', 'СБ'];

function dayOfWeekToFormat(date) {
  return daysOfWeek[new Date(date).getDay()];
}

const FORMAT_FOR_SERVER = {
  ONLY_DATE: 'yyyy-MM-dd',
  DATE_TIME: 'yyyy-MM-dd HH:mm:ss',
  START_DAY_DATE: 'yyyy-MM-dd 00:00:00',
  END_DAY_DATE: 'yyyy-MM-dd 23:59:59',
};

const FORMAT_FOR_USER = {
  ONLY_DATE: 'dd/MM/yyyy',
  ONLY_TIME: 'HH:mm',
  DATE_TIME: 'dd/MM/yyyy HH:mm:ss',
};

/**
 * Приведение даты к определенному формату
 * @param date - дата, может быть как ISO, так и Date
 * @param format - формат к которому будет приведена дата
 * @param isReturnNull - вернуть null если date пустая, иначе будет выбрана текущая дата
 * @returns {String | Null} formattedDate
 */
function formatDate(date, format, isReturnNull = false) {
  if (isReturnNull && !date) {
    return null;
  }

  const d = date != null ? new Date(date) : new Date();

  const day = d.getDate();
  const month = d.getMonth() + 1;
  const year = d.getFullYear();
  const hours = d.getHours();
  const minutes = d.getMinutes();
  const seconds = d.getSeconds();

  let result = format;
  result = result.replace(/dd/, setPadding(day));
  result = result.replace(/MM/, setPadding(month));
  result = result.replace(/yyyy/, year);
  result = result.replace(/HH/, setPadding(hours));
  result = result.replace(/mm/, setPadding(minutes));
  result = result.replace(/ss/, setPadding(seconds));

  return result;
}

export {
  FORMAT_FOR_SERVER,
  FORMAT_FOR_USER,
  dayOfWeekToFormat,
  formatDate,
};
