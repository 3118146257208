/**
 * Для чего предназначено
 *
 * В [PtMonMap] используется [VehicleItem]
 * В меню есть выбор "Трек за период"
 * Данный период должен быть одиннаков для всех - изменили в одном месте - такой же показывать и в другом
 */

import { formatDate, FORMAT_FOR_SERVER, FORMAT_FOR_USER } from '@/services/JetDate';

const state = {
  startDate: formatDate(new Date(), FORMAT_FOR_SERVER.ONLY_DATE),
  startTime: '00:00',

  endDate: formatDate(new Date(), FORMAT_FOR_SERVER.ONLY_DATE),
  endTime: formatDate(new Date(), FORMAT_FOR_USER.ONLY_TIME),
};

const actions = {};

const getters = {
  startDate: state => state.startDate,
  startTime: state => state.startTime,

  endDate: state => state.endDate,
  endTime: state => state.endTime,
};

const mutations = {
  startDate: (state, value) => state.startDate = value,
  startTime: (state, value) => state.startTime = value,

  endDate: (state, value) => state.endDate = value,
  endTime: (state, value) => state.endTime = value,
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
