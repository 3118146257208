<template>
  <div class="pa-4 fill-height" style="overflow: auto;">
    <!-- Заголовок -->
    <h3 class="mb-4">
      Редактирование радиуса
    </h3>

    <div>Радиус (м)</div>

    <v-text-field
      v-model="value.radius"
      type="number"
      @change="onRadiusChange"
      hide-details
    ></v-text-field>

    <!-- Кнопки -->
    <v-row class="pt-4">
      <v-col>
        <v-btn
          outlined
          class="mb-4"
          color="red"
          @click="$emit('onCancel', busStop)"
        >
          Отменить
        </v-btn>
      </v-col>

      <v-spacer/>

      <v-col>
        <v-btn
          color="primary"
          @click="$emit('onSubmit', busStop)"
        >
          Сохранить
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      required: false,
    },
  },
  name: 'EditRadius',
  data: () => ({
    // Копия value
    busStop: null,
  }),
  created() {
    this.busStop = _copy(this.value);
  },
  methods: {
    // При изменении радиуса
    onRadiusChange() {
      this.$emit('radiusChanged', _copy(this.busStop));
    },
  },
}
</script>

<style scoped>

</style>
