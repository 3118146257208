<template>
  <v-navigation-drawer
    v-model="showNavigationDrawer"
    absolute
    temporary
    right
    width="500px"
  >
    <div class="grid" style="height: 100%">

      <!-- Табы -->
      <v-tabs grow v-model="tab" class="text-center" id="header">
        <v-tab align="center">Новые</v-tab>
        <v-tab>Все</v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab" id="article">

        <!-- Вкладка Новые -->
        <v-tab-item>
          <div v-if="!loading" class="mt-4">
            <div v-if="newNotifications.length > 0">
              <v-card v-for="notification in filteredNewNotifications" :key="notification.id" class="ma-2">
                <v-card-title>{{ notification.title }}</v-card-title>
                <v-card-subtitle>{{ notification.text }}</v-card-subtitle>
                <v-card-actions>
                  <template v-if="notification.isMessage">
                    <v-btn
                      text
                      small
                      @click="setRead(notification)"
                      color="primary"
                    >
                      Отметить прочитанным
                    </v-btn>
                    <v-spacer/>
                    <v-divider vertical/>
                  </template>
                  <v-spacer/>
                  <v-btn
                    text
                    small
                    color="primary"
                    @click="goToNotification(notification)"
                  >
                    Перейти к уведомлению
                  </v-btn>
                </v-card-actions>
              </v-card>
            </div>
            <div v-else align="center">
              Ничего не найдено
            </div>
          </div>
          <div v-else class="b-spinner align-center mt-6" align="center">
            <v-progress-circular indeterminate color="primary"/>
          </div>
        </v-tab-item>

        <!-- Все -->
        <v-tab-item>
          <div v-if="!loading" class="mt-4">
            <div v-if="allNotifications.length > 0">
              <v-card v-for="notification in filteredAllNotifications" :key="notification.id" class="ma-2">
                <v-card-title>{{ notification.title }}</v-card-title>
                <v-card-subtitle>{{ notification.text }}</v-card-subtitle>
                <v-card-actions>
                  <v-spacer/>
                  <v-btn
                    text
                    small
                    color="primary"
                    @click="goToNotification(notification)"
                  >
                    Перейти к уведомлению
                  </v-btn>
                </v-card-actions>
              </v-card>
            </div>
            <div v-else align="center">
              Ничего не найдено
            </div>
          </div>
          <div v-else class="b-spinner align-center mt-6" align="center">
            <v-progress-circular indeterminate color="primary"/>
          </div>
        </v-tab-item>
      </v-tabs-items>

      <v-pagination v-model="page" :length="countPages" id="footer"></v-pagination>
    </div>
  </v-navigation-drawer>
</template>

<script>
import JetCollection from '@/components/JetCollection';
import OtherService from '@/services/OtherService';
import { getComponent } from '@/utils/utils';

export default {
  name: 'NotificationShort',
  props: {
    show: {
      type: Boolean,
      required: true,
    },
  },
  data: () => ({
    tab: 0,
    page: 1,
    perPage: 20,
    allNotifications: [],
    newNotifications: [],
    loading: true,
    newExist: false,
  }),
  created() {
    this.refresh();
  },
  watch: {
    show(value) {
      if (value) {
        if (this.newExist) {
          this.refresh();
          this.newExist = false;
        }
      }
    },
    newExist(value) {
      if (this.show && value) {
        this.refresh();
        this.newExist = false;
      }
    },
    tab() {
      this.page = 1;
    },
  },
  computed: {
    showNavigationDrawer: {
      get() {
        return this.show;
      },
      set(newValue) {
        if (!newValue) {
          this.$emit('close');
        }
      },
    },
    countPages() {
      return Math.ceil((this.tab === 0 ? this.newNotifications.length : this.allNotifications.length) / this.perPage);
    },
    filteredNewNotifications() {
      return this.newNotifications.slice((this.page - 1) * this.perPage, this.page * this.perPage);
    },
    filteredAllNotifications() {
      return this.allNotifications.slice((this.page - 1) * this.perPage, this.page * this.perPage);
    },
  },
  methods: {
    refresh() {
      this.getAllNotifications();
    },
    async getAllNotifications() {
      this.loading = true;
      this.allNotifications = await OtherService.getAllNotifications();
      this.newNotifications = this.allNotifications.filter(ntf => !ntf.isOld);

      this.loading = false;
    },
    async setRead(message) {
      if (await OtherService.editStatus(message.regid, OtherService.STATUS_NOTIFICATION.CONFIRM_READ)) {
        const index = this.newNotifications.findIndex(item => {
          return item.id === message.id;
        });
        if (index !== -1) {
          this.newNotifications.splice(index, 1);
        }
        OtherService.setReadIncident(message.id);
      } else {
        jet.msg({ text: 'Возникла проблема. Повторите запрос позже', color: 'error' });
      }
    },
    async goToNotification(notification) {
      await jet.collections.open({
        id: JetCollection.constants.NOTIFICATIONS_GUID,
        uri: 'siv2:/v:eb0360b1-14f0-4f0b-9ea4-f7c30a370eb9',
        name: 'Уведомления',
      });
      const own = jet.collections.active._own;
      const notifications = getComponent(own, 'Notifications');
      if (notification.isMessage) {
        notifications.tab = 1;
        setTimeout(() => {
          const tab = getComponent(notifications, 'MessageTab');
          tab.openDialog(notification.regid);
          this.showNavigationDrawer = false;
        }, 1000);
      } else {
        notifications.tab = 0;
        setTimeout(() => {
          const tab = getComponent(notifications, 'DisruptionTab');
          tab.openDialog(notification.eventId, notification.regid);
          this.showNavigationDrawer = false;
        }, 1000);
      }

      const index = this.newNotifications.findIndex(item => {
        return item.id === notification.id;
      });
      if (index !== -1 && notification.isMessage) {
        this.newNotifications.splice(index, 1);
      }
    },
  },
};
</script>

<style scoped lang="sass">
.grid
  display: grid
  grid-template-areas: "header" "article" "footer"
  grid-template-rows: 32px 1fr 50px

#header
  grid-area: header

#article
  grid-area: article
  overflow: scroll

#footer
  grid-area: footer
</style>
