<template>
  <div> 
    <v-dialog v-model="showThis" max-width="600">
      <v-card class="sel-sched-card">
        <v-card-title>Выбор рейсов</v-card-title>
        <v-card-text class="sel-sched">
          <v-row>
            <v-col>
              Выезды
              <v-banner
                outlined
                single-line
                rounded>
                <template v-for="dep in departures">
                  <v-checkbox
                    v-model="dep.checked"
                    class="pt-o mt-0"
                    hide-details
                    color="primary"
                    @change="selectDep(dep)"
                    :key="dep.id"
                    :label="dep.name"
                  ></v-checkbox>
                </template>
              </v-banner>
            </v-col>
            <v-col>
              Рейсы
              <v-banner
                outlined
                single-line
                rounded>
                <template v-for="item in schedules">
                  <v-checkbox
                    v-model="item.checked"
                    class="pt-o mt-0"
                    hide-details
                    color="primary"
                    :key="item.id"
                    :label="item.tripcode"
                  ></v-checkbox>
                </template>
              </v-banner>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn @click="prepareSave">ок</v-btn>
          <v-btn @click="close">отмена</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div> 
</template>

<script>

    export default {
        name: 'ScheduleTransferDialog',
        props: ['show', 'deps', 'scheds', 'modeTransfer'],
        data() {
            return {
                schedules: [],
                departures: null,
                showThis: false,
            };
        },
        created () {
        },
        watch: {
            modeTransfer: function (val) {
                this.prepareSchedules();
            },
            show: function (val) {
                this.prepareDepartures();
                this.prepareSchedules();
                this.showThis = val;
            },
            showThis: function (val) {
                if (!val) {
                    this.$emit('close');
                }
            },
        },
        methods: {
            prepareDepartures(){
                this.departures = [];
                this.deps.filter(it => !!it.id)
                .map((dep) => {
                    var s = _copy(dep);
                    s.checked = false;
                    this.departures.push(s);
                });
            },
            prepareSchedules(){
                if (!!this.scheds) {
                    const codes = this.scheds.filter(it => !this.modeTransfer ? !it.depId : !!it.depId)
                    .map(it => it.linkcode)
                    .filter((it, index, self) => self.indexOf(it) === index)
                    .sort((s1, s2)=>{
                        return (s1 < s2) ? -1 : 1;
                    });

                    this.schedules = [];
                    (codes || []).forEach(code => {
                        var codeSche = [];
                        var arr = [];
                        this.scheds.filter((sch)=>{
                            return sch.linkcode === code;
                        })
                        .map((sch)=>{
                            codeSche.push(sch.tripcode);
                            arr.push(sch);
                        });
                        var item = {
                            id: code,
                            tripcode: `${code} (${codeSche.join(', ')})`
                        }
                        this.schedules.push(item);
                    });
                }
            },
            close() {
                this.$emit('close');
            },
            selectDep(item) {
                this.departures.filter((dep) => dep !== item)
                .map((dep) => {
                    dep.checked = false;
                });
            },
            prepareSave(){
                if (!this.departures.find((dep) => !!dep.checked) || !this.schedules.find((sch) => !!sch.checked)) {
                    return;
                }
                var res = {
                    dep: this.departures.find((dep) => !!dep.checked),
                    sch: this.schedules.filter((sch) => !!sch.checked)
                }
                this.$emit('save', res);
                this.$emit('close');
            }
        },
    };

</script>

<style lang="scss">
    .sel-sched-card{
       height: 41em;
    }
    .sel-sched{
        height: 38em;
    }
    .v-banner{
        overflow-y: auto;
        height: 35em;
    }
    .select-all{
        width: 14em;
    }
</style>