import { prepareSinRequest, prepareSinResponse } from '@/utils/http';
import { FORMAT_FOR_SERVER, formatDate } from '@/services/JetDate';
import {uuidv4} from '@/utils/utils';

export default class ReportSettingService {
  static async setSettingsAutoReport(time, email, carrierId, reportId) {
    try {
      const date = formatDate(null, FORMAT_FOR_SERVER.ONLY_DATE) + ' ' + time + ':00';
      await prepareSinRequest(
        reportId ? 'core-update' : 'core-create',
        'sin2:/v:4902f349-b324-4fdc-acae-8a41f77bfc24/',
        '',
        [],
        [
          { id: 'id', type: 'id', value: reportId || uuidv4() },
          { id: 'carrierId', type: 'id', value: carrierId },
          { id: 'dailytime', type: 'date', value: date },
          { id: 'email', type: 'string', value: email },
        ],
      );
    } catch (e) {
      console.log('ReportSettingService::setSettingsAutoReport()', e);
    }
  };

  static async getSettingsAutoReport(carrierId) {
    try {
      const res = await prepareSinResponse(
        await prepareSinRequest(
          'core-read',
          `sin2:/v:4902f349-b324-4fdc-acae-8a41f77bfc24?filter=eq(field(".carrierId"),param("${carrierId}", "id"))`,
        ),
        {
          hideUnderscores: true,
        },
      );
      return res && res[0];
    } catch (e) {
      console.log('ReportSettingService::getSettingsAutoReport()', e);
      return false;
    }
  };
}
