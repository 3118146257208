/**
 *
 * For backward's: only subject
 */
const state = {
  subject: null,
  // Для обраной совместимости с integration
  user: null,
};

const mutations = {
  setSubject(state, payload) {
    state.subject = payload;
    state.user = state.subject;
  }
};

export default {
  namespaced: true,
  state,
  mutations
};
