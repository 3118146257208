<template>
    <div v-if="has('map')" id="map-conte" class="map"></div>
    <v-alert v-else
             class="my-5 mx-auto" 
             icon="mdi-alert"
             elevation="2"
             max-width="720"
             colored-border
             border="left"
             color="warning"
             style="margin-top: 10rem !important;">
        ВНИМАНИЕ! Предлагаем использовать обновленный картографический сервис мониторинга транспорта<br />
        <div class="d-flex justify-end align-center mt-3">
            <v-btn text 
                   small
                   class="mr-2"
                   v-on:click="initOld">
                остаться на старом
            </v-btn>
            <v-btn color="primary"
                   v-on:click="gonew">перейти к новому
                &nbsp;<v-icon>mdi-chevron-right</v-icon>
            </v-btn>
        </div>
    </v-alert>
</template>

<script>
import 'ol/ol.css';

import {Map, View, proj} from 'ol';
import {Tile as TileLayer, Vector as VectorLayer} from 'ol/layer';
import {OSM, Vector as VectorSource} from 'ol/source';
import Feature from 'ol/Feature';
import Point from 'ol/geom/Point';
import Polygon from 'ol/geom/Polygon';
import MultiPoint from 'ol/geom/MultiPoint';
import GeoJSON from 'ol/format/GeoJSON';
import {Circle as CircleStyle, Fill, Stroke, Style, Icon, Text} from 'ol/style';
import * as olExtent from 'ol/extent';
import Select from 'ol/interaction/Select';
import { singleClick } from 'ol/events/condition';
import { isEmpty } from '@/utils/';
import { rgbaFromHexa, colorFromInt } from '@/utils/utils';

const $moment = require('moment');

const TRACK_ICONS = {
    'STOP': require('@/assets/images/map/map-track-stop.png'),
    'PARKING': require('@/assets/images/map/map-track-pause.png'),
    'STARTING': require('@/assets/images/map/map-track-a.png'),
    'ENDING': require('@/assets/images/map/map-track-b.png'),
    'MOVING': require('@/assets/images/map/arrow-direction.png'),
    'OVERSPEEDING': require('@/assets/images/map/map-overspeed.png')
};

const VEHICLE_ICONS = {
    'BUS-MOVING': require('@/assets/images/map/bus-moving.png'),

    //'EVA': require('@/assets/images/map/map-eva-pointer.png'),
    //'EVA-GRAY': require('@/assets/images/map/map-eva-pointer-gray.png'),
    //'EVA-RED': require('@/assets/images/map/map-eva-pointer-red.png'),
 
    'EVA-FLASHER': require('@/assets/images/map/map-eva-flasher.png'),
    'EVA-POINTER': require('@/assets/images/map/map-eva-pointer2.png'),
    'EVA-POINTER-GRAY': require('@/assets/images/map/map-eva-pointer-gray2.png'),
    'EVA-POINTER-GREEN': require('@/assets/images/map/map-eva-pointer-green.png'),
    'EVA-POINTER-RED': require('@/assets/images/map/map-eva-pointer-red2.png'),
}

class MapApi {
    constructor(map) {
        this.$map = map;
        jet.map = this;
    }
    get center(){
        return this.$map.getCenter();
    }
    
    /**
     * @param {Array | Object} {lon, lat, zoom?}
     */
    set center(coords){
        const view = this.$map.getView();
        if (Array.isArray(coords)){
            view.setCenter(coords);
        } else {
            view.setCenter([coords.lon, coords.lat]);
            if (!!coords.zoom){
                view.setZoom(coords.zoom);
            }
        }
    }
    
    get zoom(){
        return this.$map.getView().getZoom();
    }
    
    set zoom(zoom){
        const view = this.$map.getView();
        view.setZoom(zoom);
    }
    
    set flag(coords){
        const view = this.$map.getView();
        this.$map.drawFlag(coords);
        view.setCenter([coords.lon, coords.lat]);
        if (!!coords.zoom){
            view.setZoom(coords.zoom);
        }
    }
}

export default {
    name: 'JetOlMap',
    props: {
        name: {
            type: String,
            required: true
        },
    },
    data() {
        return {
            _map: null,
            _on: null,
            _api: null
            
        }
    },
    computed: {
        map(){
            return this._map;
        }
    },
    mounted() {
        //this.$nextTick( this.init );
    },
    destroyed(){
        if (!!this._map){
            this._map = null;
        }
    },
    methods: {
        has(q){
            switch(q){
                case "map": 
                    return !!this._map;
            }
            return false;
        },
        initOld(){
            this._map = true;
            this.$forceUpdate();
            setTimeout(()=>{this.init();}, 300);
        },
        async init(){
            const self = this;
            return new Promise( (resolve, reject) => {
                if ( typeof self._map?.getLayers === "undefined" ){
                    const src = new OSM();
                    const opts = {
                        target: self.$el,
                        layers: [
                            new TileLayer({source: src})
                        ],
                        view: new View({
                            projection: 'EPSG:4326',
                            center: [38.97603, 45.04484],
                            zoom: 8,
                            maxZoom: 24,
                            enableRotation: false,
                            constrainResolution: true,
                        })
                    };
                    const map = new Map( opts );
                    map.on('error', function(e){
                        console.log('OL MAP ERROR INIT');
                        reject(e);
                    });

                    
                    var select = new Select({
                        multi: false,
                        condition: singleClick,
                        layers: l => ( /^(static\-vehicles)+/.test( l.get("name")) ),
                        style: self._vehiStyle
                    });
                    select.on('select',  e => {
                        console.log('e', e);
                        if(e.selected?.length > 0) {
                            const vehicle = e.selected[0]?.getProperties()?.vehicle,
                                  point = {X: e.mapBrowserEvent.pixel_[0], Y: e.mapBrowserEvent.pixel_[1]};
                            
                            
                            if(!!vehicle) {
                                this.$emit('vehicleClick', { vehicle, point });
                            } 
                        }
                    
                        select.getFeatures().clear();
                    });
                    
                    map.addInteraction(select);

                    var select2 = new Select({
                        multi: false,
                        condition: singleClick,
                        layers: l => ( /^(track\-vehicle)+.*(points)+$/.test(l.get("name")) ),
                        style: self._trackStyle
                    });
                    
                    select2.on('select',  e => {
                        console.log('select', e);
                        const point = e.selected[0]?.getProperties()?.point,
                             vehicle= e.selected[0]?.getProperties()?.vehicle;
                        if (!!point){
                            this.$emit('trackClick', {vehicle, point});
                        }
                        select2.getFeatures().clear();
                    });                    
                    map.addInteraction(select2);
                    
                    //extends
                    map.resize = map.updateSize;
                    map.update = map.updateSize;
                    map.remove = () => { self._map = null; };
                    map.getCenter = () => {
                        return map.getView().getCenter();
                    };
                    map.getLayer = this._getLayer;
                    map.drawFlag = this.drawFlag;

                    window["mbx"] = map;  //TODO: del (debug only)
                    self._map = map;
                    this._api = new MapApi(map);

                    //self.$emit('onload', self._map);
                    map.on('loadend', e => {
                        console.log('loaded', e);
                        self.$emit('onload', self._map);
                    })
                    resolve(self._map);
                }                     
            });
        },
        createOrDataLayer(layerId, layerData) {
            //console.log('layerId', layerId);
            //console.log('layerData', layerData);
            let layer = this._getLayer(layerId);
            if (!!layerData){
                layer.getSource().addFeatures(new GeoJSON().readFeatures(layerData));
            }
            
        },  //createOrDataLayer
        drawTrack({track, stops}) {
            console.log('DRAW TRACK');
            var source = new VectorSource({
                features: new GeoJSON().readFeatures(track)
            }),
            layer = this._getLayer(track.id);
            layer.setSource(source);
            
             //centering & fit map
            const bounds = source.getExtent();
            if ( !olExtent.isEmpty(bounds) && (track.geometry.coordinates.length > 20) ){
                this._map.getView().fit(bounds, {padding: [30, 30, 30, 30]});
            }
            
            layer = this._getLayer(stops.id);
            source = new VectorSource({
                features: new GeoJSON().readFeatures(stops)
            });
            layer.setSource(source);
        },
        drawLayerRoutes({route, stops}) {
            console.log('route', route);
            console.log('stops', stops);
            if(Object.keys(route).length) {
                let source = new VectorSource({
                    features: new GeoJSON().readFeatures(route)
                }),
                
                layer = this._getLayer(route.id);
                layer.setSource(source);
            
            

                //centering & fit map
                const bounds = source.getExtent();
                if ( !olExtent.isEmpty(bounds) && (route.geometry.coordinates.length > 20) ){
                    this._map.getView().fit(bounds, {padding: [30, 30, 30, 30]});
                }
            }

            if(Object.keys(stops).length) {
                
                let layer = this._getLayer(stops.id);
                let source = new VectorSource({
                    features: new GeoJSON().readFeatures(stops)
                });
                layer.setSource(source);

                // Если нет route - фиксируемся на остановки
                if(!Object.keys(route).length) {
                    //centering & fit map
                    const bounds = source.getExtent();
                    if ( !olExtent.isEmpty(bounds) && (stops.features[0].geometry.coordinates.length) ){
                        this._map.getView().fit(bounds, {padding: [20, 20, 20, 20]});
                        this._map.getView().setZoom(8);
                    }
                }
            }

        },
        getCenter(){
            return this._map.getCenter();
        },
        drawGeoZone({geoZone}) {
            console.log('geoZones', geoZone);
            let source = new VectorSource({
                features: new GeoJSON().readFeatures(geoZone)
            }),

            layer = this._getLayer(geoZone.id);
            layer.setSource(source);
            
            //centering & fit map
            const bounds = source.getExtent();
            if ( !olExtent.isEmpty(bounds) && (geoZone.geometry.coordinates.length) ){
                this._map.getView().fit(bounds, {padding: [20, 20, 20, 20]});
                //this._map.getView().setZoom(8);
            }
        },
        getSourceFeatures(sourceId) {
            console.log('getSourceFeatures:' + sourceId);
            if (!!this._getLayer(sourceId).getSource()) {
                return this._getLayer(sourceId).getSource().getFeatures() || [];
            }
            return null;
        },
        getRawMap(){
            if (typeof this._map?.getLayers === "undefined"){
                (async ()=>{
                    await this.init();
                })();            
            }
            return this._map;
        },
        _getLayer(name){
            var layer = null;
            this._map?.getLayers().forEach( l => {
                if ( name === l.get('name') ){
                    layer = l;
                }
            });

            if(!layer) {
                layer = new VectorLayer({
                   source: new VectorSource()
                });
                layer.set('name', name);
                
                if(name === 'static-vehicles') {
                    layer.setZIndex(999);
                    layer.setStyle(this._vehiStyle)
                } else if( /^(track\-vehicle)+/.test(name) ) {
                    layer.setStyle(this._trackStyle);
                } else if(name === "flag-point") {
                    layer.setStyle(this._flagStyle);
                } else if( /^(route\_)+/.test(name) ) {
                    layer.setStyle(this._routeStyle);
                } else if( /^(geo\_zone_)+/.test(name) ) {
                    layer.setStyle(this._geoZoneStyle);
                } 
                
                this._map.addLayer(layer);
            }
            return layer;
        },  //_getLayer
        
        _vehiStyle(feature) {
            const props = feature.getProperties();
            const zoom  = this._map.getView().getZoom();
            
            const isEvac = (/^[Ээ]вакуатор/).test(props.vehicle.vctypename.trim());
            const isConnectTo15M = (!!props.vehicle.telemetry) ? (!!$moment(props.vehicle?.telemetry.time).isAfter($moment().subtract(15, 'minutes'))) : false;
            const isConnectTo2H = (!!props.vehicle.telemetry) ? (!!$moment(props.vehicle?.telemetry.time).isAfter($moment().subtract(2, 'hours'))) : false;
            const isNearestVehicle = props.vehicle?.isNearestVehicle || false;

            let style = null;
            if(isEvac) {
                let src = VEHICLE_ICONS['EVA-POINTER-GRAY'];

                if(isNearestVehicle) {
                    src = VEHICLE_ICONS['EVA-POINTER-RED'];
                } else {
                    if(isConnectTo15M && isConnectTo2H) {
                        // До 15 минут
                        src = VEHICLE_ICONS['EVA-POINTER-GREEN'];
                    } else if(!isConnectTo15M && isConnectTo2H) {
                        // До 2 часов
                        src = VEHICLE_ICONS['EVA-POINTER'];
                    } 
                }

                style = [
                    // Указатель эвакуатора
                    new Style({
                        image: 
                            new Icon({
                                scale: isNearestVehicle ? [0.3,0.3] : [0.2,0.2], 
                                //src: (isConnect) ? (isNearestVehicle ? VEHICLE_ICONS['EVA-POINTER-RED'] : VEHICLE_ICONS['EVA-POINTER']) : VEHICLE_ICONS['EVA-POINTER-GRAY'],   
                                src,
                                rotation: ((!!props.vehicle.heading ? props.vehicle.heading : props.vehicle.telemetry?.heading)||0) * (Math.PI/180) || undefined
                            }),
                        text: (zoom > 13) 
                            ?   new Text({
                                    text: props.vehicle.govnum,
                                    scale: 1,
                                    fill: new Fill({
                                        color: "#202"
                                    }),
                                    offsetY: 24,
                                    backgroundFill: new Fill({
                                        color: "#FFF"
                                    })
                                })
                            : undefined
                    }),
                    // Иконка мигалки эвакуатора
                    new Style({
                        image: 
                            new Icon({
                                scale: [0.25, 0.25],
                                src: VEHICLE_ICONS['EVA-FLASHER'],
                                rotation: undefined
                            }),
                    })
                ];
                
                
            } else {
                
                style = new Style({
                    image: 
                        new Icon({
                            scale: [0.3, 0.3], 
                            //src: require('@/assets/images/map/bus-moving.png'),  
                            src: VEHICLE_ICONS['BUS-MOVING'],                                                                                                                                                                      
                            rotation: props.vehicle?.heading * (Math.PI/180) || undefined
                        }),
                    text: (zoom > 13) 
                        ?   new Text({
                                text: props.vehicle.govnum,
                                scale: 1,
                                fill: new Fill({
                                    color: "#202"
                                }),
                                offsetY: 24,
                                backgroundFill: new Fill({
                                    color: "#FFF"
                                })
                            })
                        : undefined
                })
            }

            return style;
        },
        _trackStyle(feature){
            const point  = feature.getProperties()?.point || false;
            const zoom  = this._map.getView().getZoom();
            
            const lineSettings = feature.getProperties()?.lineSettings || false;
            const pointSettings = point?.pointSettings || false;

            //console.log('ZOOM: ', zoom);
            
            var style = null;
            if (!point){

                style = new Style({
                                    stroke: new Stroke({
                                    color: lineSettings.trackColor || '#2951DB',
                                    width: lineSettings.lineWidth || 4
                                })
                });
                
            } else {
                
                //const src = TRACK_ICONS[point.status || 'xxx'];
                const src   = (point.firstOverspeed) ? TRACK_ICONS['OVERSPEEDING'] : TRACK_ICONS[(point.status != 'OVERSPEEDING') ? point.status : 'xxx'];
                let img = point.status != 'MOVING' || point.status == 'MOVING' && zoom >= point.zoom;

                if(
                    (!!pointSettings) 
                    &&
                    ((point.status == 'OVERSPEEDING' && pointSettings.showSpeedUps == 'none')
                    || (point.status == 'STOP' && pointSettings.showStops == 'none')
                    || (point.status == 'PARKING' && pointSettings.showParking == 'none')) 
                ) {
                    img = false;
                }


                if (!!src) {
                    style = new Style({
                                        geometry: new Point([point.lon, point.lat]),
                                        image: (!!img)
                                        ?   new Icon({
                                                            src,
                                                            scale: ('MOVING' === point.status )? [0.7, 0.7] : [0.3, 0.3],
                                                            anchorOrigin: 'bottom-left',
                                                            anchorXUnits: 'pixels',
                                                            anchorYUnits: 'pixels',
                                                            anchor: ('MOVING' === point.status ) ? [12, 12] : [20, 12],
                                                            rotation: ('MOVING' === point.status ) ? (point.heading * (Math.PI/180)) + 3 : 0,
                                                            rotateWithView: true
                                            })
                                        :   undefined,
                                        text: ( point.firstOverspeed && (!!img) )
                                                ?   new Text({
                                                        text: point.overspeed.toString(),
                                                        scale: 1.2,
                                                        fill: new Fill({
                                                            color: '#FFF',
                                                        }),
                                                        offsetX: 10,
                                                        offsetY: -18,
                                                    })
                                                :   undefined,

                    })
                    
                }
            }

            return style;
        },  //_trackStyle
        _routeStyle(feature) {
            //console.log('_routeStyle', feature);
            const props = feature.getProperties();
            const point  = feature.getProperties()?.point || false;
            const zoom  = this._map.getView().getZoom();

            let style = null;
            if(!point) {
                style = new Style({
                        stroke: new Stroke({
                        color: '#2951DB',
                        width: 4
                    })
                });
            } else {
                let color = null;
                let strokeColor = null;

                if(point.isFinal || props.stop) {
                    color = '#ff00de';
                    strokeColor = '#0032e7';
                } else if(!point.isFinal && point.location !== null) {
                    color = '#FFA812';
                    strokeColor = '#FFA812';
                }

                style = new Style({
                    geometry: new Point(point.coordinates),
                    image:  new CircleStyle({
                                fill:  (color)
                                    ?   new Fill({
                                            color
                                        })

                                    :   undefined,
                                stroke: (strokeColor)
                                    ?   new Stroke({
                                            color: strokeColor,
                                            width: 2
                                        })
                                    :   undefined,
                                radius: 8,

                        }),
                    text: (zoom > 15)
                        ?    new Text({
                                text: point.name || point.locationName || point.locName || '',
                                offsetX: 0.5,
                                offsetY: 0.5,
                                scale: 1.2
                            })

                        : (zoom <= 15)
                        ?   new Text({
                                text: (point.first) ? (point.name || point.locationName || point.locName || '') : '',
                                offsetX: 0.5,
                                offsetY: 0.5,
                                scale: 1.5
                            })
                        : undefined,
                    
                })
            }

            return style;
        }, // _routeStyle
        _geoZoneStyle(feature) {
            const props = feature.getProperties();
            const geoZone  = feature.getProperties()?.geoZone || false;
            const zoom  = this._map.getView().getZoom();
            let style = null;
            console.log('geoZone', geoZone);
            
            if(geoZone.type == 'LINE') {
                const radians = (78271.517 * Math.cos(geoZone.coordinates[0][1] * Math.PI / 180));

                //console.log('color', colorFromInt(geoZone.color || -65536, 1));
                //console.log('width', geoZone.width);
                //console.log('radians', radians);
                //console.log('((geoZone.width / radians) * Math.pow(2, 14))', ((geoZone.width / radians) * Math.pow(2, 14)));

                style = new Style({
                    stroke: new Stroke({
                            color: colorFromInt(geoZone.color || -65536, 1),
                            width: ((geoZone.width / radians) * Math.pow(2, 14)),
                    }),
                })
            } else if(geoZone.type == 'POLYGON') {
                
                style = [
                    // Полгон
                    new Style({
                        stroke: new Stroke({
                            color: colorFromInt(geoZone.color|| -65536, 0.7),
                            width: 3,
                        }),
                        fill: new Fill({
                            color: colorFromInt(geoZone.color || -65536, 0.7),
                        }),
                    }),
                    // Вершины полигона
                    new Style({
                        image: new CircleStyle({
                            radius: 5,
                            fill: new Fill({
                                color: '#FFA812',
                            }),
                        }),
                        geometry: function (feature) {
                            // return the coordinates of the first ring of the polygon
                            const coordinates = feature.getGeometry().getCoordinates()[0];
                            return new MultiPoint(coordinates);
                        },
                    }),
                ];
            } else if(geoZone.type == 'CIRCLE') {
                style = new Style({
                    image: new CircleStyle({
                        fill: new Fill({
                            color: colorFromInt(geoZone.color || -65536, 0.8)
                        }),
                        stroke: new Stroke({
                            color: colorFromInt(geoZone.color || -65536, 1)
                        }),
                        radius: geoZone.radius,
                        
                    })
                })
            }

            return style;
        }, // _geoZoneStyle
        _stopStyle(feature) {
            const props = feature.getProperties();
            const zoom  = this._map.getView().getZoom();
            const direction = props.direction;

            const style = new Style({
                text: (zoom > 13)
                    ? new Text({
                            font: '12px Arial,sans-serif',
                            fill: new Fill({
                                color: "#263238"
                            }),
                            stroke: new Stroke({
                                color: '#fff',
                                width: 3
                            }),
                            offsetX: 18,
                            offsetY: -18,
                            text: props.stop.name || ''
                        })
                    : undefined,
                image:  new CircleStyle({
                            fill: new Fill({
                                color: '#ff6200'
                            }),
                            stroke: new Stroke({
                                color: `rgba(33, 33, 33, 0.2)`,
                                width: 6
                            }),
                            radius: 12
                        })
            })
            return style;
        },
        _flagStyle(feature) {
            const props = feature.getProperties();
            const style = new Style({
                image: new Icon({
                        scale: [0.2, 0.2], 
                        anchorOrigin: 'bottom-left',
                        anchorXUnits: 'pixels',
                        anchorYUnits: 'pixels',
                        anchor: [20, 12],
                        src: require('@/assets/images/map/flag-red.png')
                }),
            })

            return style;
        },      
        fitBounds(points){
            //console.log('fitBounds at:', points);
            if (
                    (points.length > 1)
                &&  (!!this._map)
            ) {
                //console.log('fit');
                const view = this._map.getView(),
                    feas = {
                            type: 'Feature',
                            geometry: {
                                type: 'LineString',
                                coordinates: []
                            }
                        };
                points.map( p => {
                    feas.geometry.coordinates.push([p.lng, p.lat]);
                });
                const source = new VectorSource({
                    features: new GeoJSON().readFeatures(feas)
                });
                const bounds = source.getExtent();
                if (!olExtent.isEmpty(bounds) ){
                    view.fit(bounds, {padding: [20, 20, 20, 20]});
                } else {
                    console.log('empty extent at source', source);
                }
            }
        },   //fitBounds
        clear(name){
            //console.log('name:', name);
            const removes = [];
            this._map?.getLayers().forEach(layer => {
                if(layer.get('name') === name) {
                    removes.push(layer);
                }
            });
            //console.log('removes', removes);
        
            removes.map( r => {
                this._map.removeLayer(r);
            });
        },   //clear
        drawFlag(point){
            const l = this._getLayer("flag-point");
            const source = l.getSource();
            if ( source.getFeatures().length < 1 ){
                source.addFeatures(new GeoJSON().readFeatures({
                    type: 'FeatureCollection',
                    features: [{
                                    type: 'Feature',
                                    geometry: {
                                        type: 'Point',
                                        coordinates: [point.lon, point.lat],
                                    }
                    }]
                
                }));
            } else {
                const flag = source.getFeatures()[0];
                flag.getGeometry().setCoordinates([point.lon, point.lat]);
            }
        },
        gonew(){
            window.open(`${window.location.protocol}//${window.location.host}/tracked`, '_blank').focus();
        }
    }       //methods
}
</script>
<style lang="scss" scoped>
    .map {
        position: relative; 
        width: 100%; 
        height: 100%;
    }
</style>