<template>
    <v-container fluid align-start class="jet-map-conte">
    <v-layout fill-height style="position: relative">
    <v-flex shrink style="width: 400px; max-height: 100%;">
        <!-- Шапка управления списком -->
        <CarrierListHeader :loading="isLoading" :totals="totals" :useFor="'EVAC_MAP'" />
        
        <!-- Сам список ТС -->
        <v-list class="mt-vehicles-tree"
            style="overflow-y: scroll; height: calc(100% - 80px);"
            v-if="!isLoading && !_emptyObject(groupData)"
        >
            <template>
                <v-list-group   append-icon="mdi-chevron-down"
                                v-for="(subItems, fTitle) in groupData" :key="fTitle"
                                v-model="expand[fTitle]">
                <template #activator>
                    <v-list-item-title class="group-header">
                        <v-icon @click.stop="selectVehicles(fTitle)">
                            mdi-checkbox-{{ !!groupSelectedTwo[fTitle] ? 'marked' : 'blank' }}-outline
                        </v-icon>
                        <div class="text-truncate">{{ fTitle }} ({{subItems.length}})</div>
                    </v-list-item-title>
                </template>
                <VehicleItem v-for="vehicle of subItems" 
                            :key="vehicle.id"
                            :vehicle="vehicle"
                            v-on:checked="vehiCheck(vehicle, $event)" />
                </v-list-group>
            </template>
        </v-list>

        <!-- Отображение загрузки списка данных -->
        <v-layout
          fill-height justify-center align-center
          v-if="isLoading"
        >
          <v-progress-circular indeterminate/>
        </v-layout>

        <!-- Если нет данных -->
        <v-layout
          fill-height justify-center align-center
          v-if="!isLoading && _emptyObject(groupData)">
          Нет данных для отображения
        </v-layout>
    </v-flex>
    <div style="position:relative; width:100%; height:100%;">
        <OlMapProvider ref="nMap" />
        <eva-map-popup ref="nPopup" 
            :isFromJournal="!!nearestVehicleId" 
            :isNearestVehicle="selectedVehicle ? (selectedVehicle.id == nearestVehicleId) : false"
            :selectedVehicle="selectedVehicle" 
        />
    </div>
    </v-layout>
    </v-container>
</template>

<script>
import OlMapProvider from "@/components/OlMapProvider";
import VehicleService from '@/services/VehicleService';
import MapSettingsService from '@/components/dev/service/MapSettingsService';
import EvaMapPopup from '@/components/dev/components/eva-ext/EvaMapPopup';
import CarrierUtils from '@/components/dev/service/CarrierUtils';
import CarrierListHeader from '../CarrierListHeader';
import VehicleItem from '../VechileItem';
import { Split } from 'vue-split-panel';
import {ebus} from '../../../../main';

const $moment = require('moment');

// Типы последних событий
const _lastEvents = CarrierUtils.lastEvents();

// Набор эвакуаторов
let _vehicles = []; 

// Текущая группировка
let _currentGroup = 'GRP_BY_CARRIERS';

var WS_URI = {
    servers: process.env.VUE_APP_BACKEND_NATS_SERVER,
    user: process.env.VUE_APP_BACKEND_NATS_USERNAME,
    pass: process.env.VUE_APP_BACKEND_NATS_PASSWORD
};

let _codec = null;
let _nats = null;
let _sids = {};



export default {
    name: 'evacMap',
    data() {
        return {
            isLoading: false,
            // Сгруппированные данные
            groupData: [],
            // Информация о раскрытых данных 2го уровня списка
            expand: {},
            // Выбор всех ТС группы 2го уровня
            groupSelectedTwo: {},
            // Выбранное ТС
            selectedVehicle: null,
            lastDevice: null,
            totals: null,  //selected vehicles info
            nearestVehicleId: null,
            isMapLoad: false
        }
    },
    components: {
        OlMapProvider,
        EvaMapPopup,
        CarrierListHeader,
        VehicleItem
    },
    provide() {
        const self = this;
        return {
            // Установки точки на карту
            placePoint(vehicle) {
                self.placePoint(vehicle);
            },
            // Удаление точки на карте
            removePoint(vehicle) {
                self.removePoint(vehicle);
            },
            // Клик по статичной машинке
            vehiclePointClick(vehicle, point, deselected) {
                self.vehiclePointClick(vehicle, point, deselected);
            },
            // Клик по точке трека на карте
            trackPointClick() {
                console.log('trackPointClick');
            },
            // Отрисовка трека машинки
            async drawTrack(vehicle) {
                const start = new Date($moment().startOf('days'));
                const end = new Date();
                
                await self.drawTrack(vehicle, start, end);
            },
            // Начало сллежения
            startTracking(vehicle) {
                self.startTracking(vehicle);
            },
            // Стоп слежения
            stopTracking(vehicle) {
                self.stopTracking(vehicle);
            },
            // Отмена слежения за всеми ТС
            stopTrackingTotal() {
                self.stopTrackingTotal();
            },
            // Удаление трека
            removeTrack(vehicle) {
                self.removeTrack(vehicle);
            },
            // Удаление всех треков
            removeTrackTotal() {
                self.removeTrackTotal();
            },
            // Смена настроек отображения
            changeTrackStyle(vehicle, settings) {
                self.changeTrackStyle(vehicle, settings);
            },
            // Переход на компоненту EvaJForm
            goToForm() {
                self.goToForm();
            },
            // Переход на компоненту EvaJForm и установка выбранного эвакуатора
            setEvaForm() {
                self.setEvaForm();
            },
            // ----- CarrierListHeader -----
            // Фильтрация
            changeFilter(filter) {
                self.changeFilter(filter);
            },
            changeGroup() {
                console.log('changeGroup');
            },
            // Выбор всех(2)/запланированных(1)/сброс(0)
            selectAll(show = 0) {
                self.selectAll(show);
            },
            clearAll() {
                self.clearAll();
            },
            mapLoad(loaded) {
                self.mapLoad(loaded);
            }
           
        }
        
    },
    methods: {
        async _get_last_positions(ids) {
            try {
                const vehiclesLastInfo = await VehicleService.getLastInfo(ids);

                vehiclesLastInfo?.map((tm) => {
                    const n = _vehicles.findIndex((vc)=>{
                        return ((vc.id === tm.deviceId) || (vc.id === tm.id));
                    });
                    if (n > -1){    
                        tm.heading += 180;
                        _vehicles[n].telemetry = tm;
                    }
                })
            } catch (error) {
                console.error('ERR: _get_last_positions');
            }
            
            
        }, // _get_last_positions
        async getData() {
               try {

                   this.isLoading = true;
                   
                    // Получаем список ТС

                    let prevehicles = await VehicleService.getVehiclesForMap();
                    let vehicles = JSON.parse(prevehicles[0].jsonobject.value);
                    _vehicles = vehicles.filter(it => /^эвакуатор/i.test(it.vctypename));
                    
                    // Запрашиваем по списку Last Position
                    if(_vehicles.length > 0) {
                        let subarray = [];
                        for (let i = 0; i <Math.ceil(_vehicles.length/10); i++){
                            subarray[i] = _vehicles.slice((i*10), (i*10) + 10);
                        }

                        await Promise.all(
                            subarray.map(async vehicle => {
                                let ids = vehicle.map(v => v.id);
                                await this._get_last_positions(ids);
                            })
                        );
                        
                        
                        const statuses = await VehicleService.getStatuses(_vehicles.map(it => it.id));
                        
                        if (statuses != null && statuses !== {} && Object.keys(statuses).length) {
                            _vehicles = _vehicles.map(it => {
                                it.telemetry = { ...it.telemetry, ...statuses[it.id] };
                                    
                                return it;
                            });
                        }
                                               
                        
                        
                        
                        _vehicles = _vehicles.map(it => {
                            if(!!it.telemetry?.lat && !!it.telemetry?.lon) {
                                it.blockStatus = false;
                            } else {
                                it.blockStatus = true;
                            }

                            return it;
                        });

                        _vehicles.map( v => {
                                v.dispAttrs = {
                                    draw: false,
                                    track: false,
                                    tracking: false,
                                    at: false //time for changes
                                };  //display modes: check, tracking & etc...
                        });

                        const preData = await CarrierUtils.onGroupData('GRP_BY_CARRIERS', _vehicles);
                        this.groupData = preData.data;
                        
                    }
               } catch(e){
                   console.error('ERR: evacMap', e);
               } finally{
                    this.isLoading = false;
               }
        },
        getTitle(items, title, level){
            return `${title} (${this.getCountByLevel(items, title, level)})`;
        },
        getCountByLevel(items, title, level) {
            let count = 0;
            switch (level) {
                case 1:
                Object.keys(items).forEach(key => {
                    const subItem = items[key];
                    Object.keys(subItem).forEach(subKey => {
                    count += subItem[subKey].length;
                    });
                });
                break;
                case 2:
                Object.keys(items).forEach(key => {
                    count += items[key].length;
                });
                break;
                case 3:
                count = items.length;
                break;
            }
            return count;
        },
        placePoint(vehicle) {
            return this.$refs['nMap'].drawVehicle(vehicle);
        },
        // Удалить точку
        removePoint(vehicle) {
            this.$refs['nPopup'].hide();
            return this.$refs['nMap'].removeVehicle(vehicle);
        },
        // Установка фокуса на координатах
        flyToCoordinates(lat, lon, zoom){
            if (
                    (!!lat)&&(!!lon)
            ){
                
                const map = this.$refs['nMap'];
                
                if (typeof zoom !== "undefined"){
                    map.setZoom(zoom);
                }
                map.flyToCoordinates(lat, lon);
            }
        },
        // Обработчик клика по эвакуатору
        vehiclePointClick(vehicle, point) {
            this.selectedVehicle = {...vehicle};
            this.popupShow(vehicle, point);
        },
        //Инфоблок по точке трека Эвакуатору
        popupShow(vehicle, point) {
            if( !(!!vehicle.telemetry) ) {
                jet.msg({text: "Ошибка получения данных о ТС", color: "warning"});
                return;
            }
            switch (vehicle.telemetry.status?.toLowerCase() || 'n/d') {
                case _lastEvents.moving:
                    status = 'Движется';
                break;
                case _lastEvents.blocked:
                    status = 'Заблокирован';
                break;
                case _lastEvents.alarm:
                    status = 'Тревожная кнопка';
                break;
                case _lastEvents.noData:
                    status = 'Нет данных';
                break;
                case _lastEvents.parking:
                    status = 'Парковка';
                break;
                case _lastEvents.speedUp:
                    status = 'Превышение скорости';
                break;
                case _lastEvents.stop:
                    status = 'Стоянка';
                break;
                case _lastEvents.noGps:
                    status = 'Не привязан GPS';
                break;
                case _lastEvents.starting:
                    status = 'Начало движения';
                break;
                case _lastEvents.ending:
                    status = 'Окончание движения';
                break;
                case _lastEvents.online:
                    status = 'Установка связи с датчиком';
                break;
                case _lastEvents.offline:
                    status = 'Потеря связи с датчиком от 2 до 24 часов';
                break;
                case _lastEvents.offlineUp72:
                    status = 'Потеря связи с датчиком от 24 до 72 часов';
                break;
                case _lastEvents.offlineOver72:
                    status = 'Потеря связи с датчиком свыше 72 часов';
                break;
                case _lastEvents.free:
                    status = 'Свободен';
                break;
                case _lastEvents.busy:
                    status = 'Занят';
                break;
                case _lastEvents.locked:
                    status = 'Заблокирован';
                break;
            }

            status += (vehicle.telemetry.speed > 0.5) ? ' (' + vehicle.telemetry.speed + ' км/ч)' : '';
            
            let position = Number(vehicle.telemetry.lat).toFixed(5) + ' / ' + Number(vehicle.telemetry.lon).toFixed(5);

            const info = [
                `Перевозчик: ${vehicle.orgname || ''}`,
                `Событие: ${ status }`,
                `Положение: ${ position }`,
            ];

            const popa = this.$refs['nPopup'];

            if (!!popa) {
                popa.set({
                    title: `${vehicle.vctypename} ${vehicle.vckindname} ${(vehicle.govnum || '').toUpperCase()}`,
                    text: info.join('<br />'),
                    //time: (vehicle.time ? vehicle.time : ''),
                    time: (vehicle.telemetry.time ? vehicle.telemetry.time : ''),
                    point: point
                });
            }
        },
        // Нарисовать трек
        async drawTrack(vehicle, dateStart, dateEnd){
            try {
                const track = await VehicleService.getHistoryTrack(vehicle, dateStart, dateEnd);
                const points = track[0]?.points || [];  //fix
                
                const settings = MapSettingsService.getSettings(vehicle);
                
                vehicle.track = track;

                if (points.length > 0) {
                // Рисуем трек на карте
                this.$refs['nMap'].drawTrack(vehicle, points, settings);
                
                vehicle.trips = null;
                try {
                    vehicle.trips = await VehicleService.getVcTrips(vehicle, {start: dateStart, end: dateEnd});
                } catch(e){
                    console.log('ERR on (trips)', e);
                }
                const i = _vehicles.findIndex(v => {return (v.id === vehicle.id);});
                if ( i > -1 ){
                        _vehicles[i].track = vehicle.track;
                        _vehicles[i].trips = vehicle.trips;
                };
                } else {
                    jet.msg({
                                text: `${vehicle.govnum?.toUpperCase() || 'б/н'} за период ${ $moment(dateStart).format('DD.MM.YYYY HH:mm')} - 
                                ${ $moment(dateEnd).format('DD.MM.YYYY HH:mm')} данные трека не получены`, 
                                color: "warning",
                                timeout: 30000
                            });
                }
            } catch(e){
                console.log('ERR (on track)', e);
                jet.msg({text:"Ошибка при загрузке трека, попробуйте обновить страницу", color: "warning"});
            }
        },
        // Нарисовать трек по слежению
        async drawTrackingLine(vehicle, dateStart, dateEnd){
            try {
                // Рисуем трек на карте
                const settings = MapSettingsService.getSettings(vehicle);

                this.$refs['nMap'].drawTrackingLine(vehicle, vehicle.track, settings);
                vehicle.trips = null;

                try {
                    vehicle.trips = await VehicleService.getVcTrips(vehicle, {start: dateStart, end: dateEnd});
                } catch(e){
                    console.log('ERR on (trips)', e);
                }
                const i = _vehicles.findIndex(v => {return (v.id === vehicle.id);});
                if ( i > -1 ){
                    _vehicles[i].track = vehicle.track;
                    _vehicles[i].trips = vehicle.trips;
                };
            } catch(e){
                console.log('ERR (on track)', e);
                jet.msg({text:"Ошибка при загрузке трека, попробуйте обновить страницу", color: "warning"});
            }
        },
        // Начало слежения
        async startTracking(vehicle) {
            // Сразу покажем машинку, пока ждем очередного пакета
            this.removePoint({
                ...vehicle,
                tracking: true,
            });
            
            if(vehicle.telemetry.lat != 0) {
                this.flyToCoordinates(vehicle.telemetry.lat, vehicle.telemetry.lon, 16);
            }
            
            const ls = await VehicleService.getLastInfo([vehicle.id]);

            if (ls === false) {
                jet.msg(`Для ${vehicle.govnum} нет информации`);
                return null;
            }
            /*
            vehicle.lat = ls[0].lat;
            vehicle.lon = ls[0].lon;
            vehicle.time = ls[0].time;
            vehicle.status = ls[0].status;
            */
           vehicle.telemetry = ls[0];
            var track = [];
            track.push({...ls[0]});
            vehicle.track = track;
            
            this.$refs['nMap'].clearTracks();
            this.lastDevice = vehicle.origid;

            /*this.placePoint({
                ...vehicle,
                ll: `${vehicle.telemetry.lat}:${vehicle.telemetry.lon}`,
                heading: vehicle.telemetry.heading + 180,
                tracking: true,
            });*/

            vehicle.telemetry.heading += 180;
            this.placePoint({
                ...vehicle,
                ll: `${vehicle.telemetry.lat}:${vehicle.telemetry.lon}`,
                tracking: true,
            });


            this.flyToCoordinates(vehicle?.telemetry.lat, vehicle?.telemetry.lon, 17);

            try {
                //if (!(!!_nats)) {
                    
                    _sids[vehicle.id] = _nats.subscribe(`PUBLIC.kigat.telemetry.${vehicle.id}`);
                    
                    (async ()=>{
                    for await (const m of _sids[vehicle.id]) {
                        try{
                        
                        const payload = _codec.decode(m.data);
                        
                        if (payload.time > vehicle.track[vehicle.track.length - 1].time && this.lastDevice === payload.originalId) {
                            vehicle.track.push(payload);
                            this.drawTrackingLine(vehicle, new Date (vehicle.telemetry.time), new Date (payload.time));
                        }

                        
                        
                        // TODO: Возможно не самое лучшее решение
                        
                        this.removePoint({
                            ...vehicle,
                            tracking: true,
                        });

                        vehicle.lasttime = payload.time;
                        /*
                        vehicle.lat    = payload.lat;
                        vehicle.lon    = payload.lon;
                        vehicle.time   = payload.time;
                        vehicle.speed  = payload.speed;
                        vehicle.status = (payload.speed > 0.5) ? 'moving' : 'stop';
                        */
                        vehicle.telemetry.lat    = payload.lat;
                        vehicle.telemetry.lon    = payload.lon;
                        vehicle.telemetry.time   = payload.time;
                        vehicle.telemetry.speed  = payload.speed;
                        vehicle.telemetry.status = (payload.speed > 0.5) ? 'moving' : 'stop';
                        
                        /*this.placePoint({
                            ...vehicle,
                            ll: `${payload['lat']}:${payload['lon']}`,
                            heading: payload.heading + 180,
                            tracking: true,
                        });*/
                        vehicle.telemetry.heading = payload.heading + 180;
                        this.placePoint({
                            ...vehicle,
                            ll: `${payload['lat']}:${payload['lon']}`,
                            tracking: true,
                        });
                        

                        
                        if (Object.keys(_sids).length < 3){
                            this.flyToCoordinates(payload['lat'], payload['lon']);
                        }
                        } catch (e) {
                        console.error('Nats parse error:', e);
                        }
                    }
                    })();
                //}
            } catch (e) {
                console.error('Tracking error', e);

                jet.msg({
                text: 'Произошла ошибка во время запроса слежения за ТС',
                });
            }

            return vehicle;
        },
        // Остановка слежения
        stopTracking(vehicle) {
            console.log('STOP TRACKING ', vehicle.id);
            console.log('_sids', _sids);
            
            if (!!_sids[vehicle.id] && _sids[vehicle.id] != null) {
                _sids[vehicle.id].unsubscribe();
                
                delete _sids[vehicle.id];
            }
    
            vehicle = this.removePoint(vehicle);
            //this.removePoint(vehicle);
            this.removeTrack(vehicle);

            return vehicle;
        },
        // Остановка слежения за всеми ТС
        stopTrackingTotal() {
            Object.keys(_sids).forEach(vId => {
                if (!!_sids[vId] && _sids[vId] != null) {
                _sids[vId].unsubscribe();
                }

                delete _sids[vId];
            });
            this.$refs['nMap'].clearVehicles();
        },
        // Удалить трек
        removeTrack(vehicle) {
            this.$refs['nMap'].removeTrack(vehicle);
            this.$refs['nMap'].removeFlag();
            this.$refs['nPopup'].hide();
        },
        // Удаление всех треков
        removeTrackTotal() {
            this.$refs['nMap'].clearTracks();
            this.$refs['nMap'].removeFlag();
            this.$refs['nPopup'].hide();
        },
        // Применение настроек
        changeTrackStyle(vehicle, settings) {
            const points = vehicle.track[0]?.points || [];
            this.$refs['nMap'].drawTrack(vehicle, points, settings);
        },
        startTrack(vehicles){
            vehicles.forEach(vehicle => {
                if (vehicle.blockStatus === false) {
                this.startTracking(vehicle).then(e => {
                    vehicle.dispAttrs = {
                    ...vehicle.dispAttrs || {},
                    draw: true,
                    tracking: (!!e),
                    at: (new Date()).getTime()
                    };
                });
                }
            });
        },
        stopTrack(vehicles){
            vehicles.forEach(vehicle => {
            this.stopTracking(vehicle);

            vehicle.dispAttrs = {
                ...vehicle.dispAttrs || {},
                draw: false,
                tracking: false,
                at: (new Date()).getTime()
            };
                
            });
        },
        // Выбор всех ТС группировки
        selectVehicles(title, params = { level: 1, parent: null, grandparent: null, items: null }) {
            // Если это 2й уровень
                // Если ранее не выбирали
                if ( !this.groupSelectedTwo.hasOwnProperty(title) ) {
                // Помечаем как выбранное
                this.groupSelectedTwo[title] = true;
                this.expand[title] = true;
                this.$forceUpdate();
                const vehicles = this.groupData[title] || [];
                this.startTrack(vehicles);
                } else {
                delete this.groupSelectedTwo[title];
                const vehicles = this.groupData[title] || [];
                this.$forceUpdate();
                this.stopTrack(vehicles);

                // this.$set(this.groupData, title, vehicles);
                }
                //this.$set(this, 'groupSelectedTwo', gst);
        },
        // Для 2-х уровневого списка
        vehiCheck({orgname}, checked){
            var n = 0;
            if (!!checked){
                n = 1;
            } else {
                n = _vehicles.filter( v=>{
                    return ( 
                                (v.orgname == orgname) 
                            && (!!v.dispAttrs?.draw)
                            );
                }).length;
            }
            if ( n > 0 ){
                this.groupSelectedTwo[orgname] = true;
            } else if(!!this.groupSelectedTwo[orgname]){
                delete this.groupSelectedTwo[orgname];
            }
            
            this.$forceUpdate();
        },
        // Фильтр
        async changeFilter(filter) {
            this.isLoading = true;

            let vehicles = [];

            // Если есть поисковая строка
            if (filter && (filter.text !== '' || filter.text != null)) {
                const re = new RegExp(filter.text, 'i');

                vehicles = _vehicles.filter(it => {
                return re.test(it.orgname) || re.test(it.govnum);
                });
            }

            if (filter.text == null || filter.text === '') {
                // Если не было поисковой строки То берем все машинки
                vehicles = _copy(_vehicles);
            }
            
            
            // Обработка фильтров
            if (filter) {
                const statuses = filter.statuses;
                const moving = filter.moving;

                vehicles = vehicles.filter(it => {
                // Обработка фильтра "Статуса ТС"
                if (statuses) {
                    // На Связи
                    if (!statuses.inState && it.state === 0) {
                    return null;
                    }

                    // 2 - 24 часа
                    if (!statuses.fail2to24 && it.state === 24) {
                    return null;
                    }

                    // 24 - 72 часов
                    if (!statuses.fail24to72 && it.state === 72) {
                    return null;
                    }

                    // 72 - 100 - часов
                    if (!statuses.failUp72 && it.state === 100) {
                    return null;
                    }

                    // Нет данных
                    if (!statuses.noData && it.state === 9999) {
                    return null;
                    }
                }

                const lastStatus = (it.last?.status || _lastEvents.moving).toLowerCase();

                // Обработка фильтра "Статус движения"
                if (moving) {// && it.state === 0) {
                    // Движется
                    if (!moving.move && lastStatus === _lastEvents.moving) {
                    return null;
                    }

                    // Остановка
                    if (!moving.stop && lastStatus === _lastEvents.stop) {
                    return null;
                    }

                    // Парковка
                    if (!moving.parking && 
                            (lastStatus === _lastEvents.parking 
                            || lastStatus === _lastEvents.starting 
                            || lastStatus === _lastEvents.offline)) {
                    return null;
                    }

                    // Заблокирован
                    if (!moving.blocked && lastStatus === _lastEvents.blocked) {
                    return null;
                    }

                    // Тревожная кнопка 
                    if (!moving.alertButton && lastStatus === _lastEvents.alarm) {
                    return null;
                    }
                }

                return it;
                }).filter(it => it != null);
            }

            const preData = await CarrierUtils.onGroupData(_currentGroup, vehicles);
            this.groupData = preData.data;
            this.isTwoLevel = preData.level === 2;

            this.isLoading = false;
        },
        selectAll(show = 0) {
            this.clearAll();
            if (0 === show){
                return ;
            }
            const re = /^(?:не)+/i;
            
            var n = 0, 
                _show;
            _vehicles.filter((p) => !p.blockStatus).map(v=>{
                _show = (
                                (show === 2) 
                            ||(
                                    (show < 2) 
                                &&(!!v.routecode) && !re.test(v.routecode)
                                )
                        );
                if (_show){
                    n++;
                    this.groupSelectedTwo[v.orgname] = true;
                    this.startTracking(v).then(e => {
                    v.dispAttrs = {
                        ...v.dispAttrs || {},
                        draw: true,
                        tracking: (!!e),
                        at: (new Date()).getTime()
                    };
                    });
                }
            });
            
            this.totals = {
                all: (show === 2) ? n : 0,
                planned: (show === 1) ? n : 0
            };
        
        },  // selectAll
        // Очистка всего
        clearAll() {
            this.expand = {};
            this.subExpand = {};

            this.groupSelectedTwo = {};
            this.groupSelectedThree = {};

            this.$refs['nMap'].clearVehicles();
            this.$refs['nMap'].clearTracks();
            this.$refs['nMap'].removeFlag();
            this.$refs['nPopup'].hide();
            this.totals = null;
            
            
            for (const key in this.groupData) {
                for (const v of this.groupData[key]) {
                    if (!!v.dispAttrs?.tracking) {
                        this.stopTracking(v);
                    }
        
                    v.dispAttrs = {
                        ...v.dispAttrs,
                        draw: false,
                        track: false,
                        tracking: false,
                        at: (new Date()).getTime()
                    };
                }
            }
        },
        goToForm() {
            const col = {
                id: "fc4d98cc-4e89-4d73-8b20-ae56e1df6646",
                name: "Журнал регистрации правонарушений",
                uri: "sin2:/v:5fea1003-e578-444b-8836-ea0074ce38b8/"
            }

            jet.collections.open(col);
        },
        setEvaForm() {
            this.goToForm();
            ebus.$emit('changeSelectVehi', this.selectedVehicle);
        },
        mapLoad(loaded) {
            this.isMapLoad = loaded;
        },
        selectNearestVehi(vehicleId) {
            this.nearestVehicleId = vehicleId;
            
            const currentNearestVc = _vehicles.find(vehicle => vehicle.isNearestVehicle);
            const nearestVc = _vehicles.find(vehicle => vehicle.id == vehicleId);

            if(!!currentNearestVc) {
                this.removePoint(currentNearestVc);
                currentNearestVc.isNearestVehicle = false;
                this.placePoint(currentNearestVc);
            }
            
            this.removePoint(nearestVc);
            nearestVc.isNearestVehicle = true;
            this.placePoint(nearestVc);

            setTimeout(() => {
                this.flyToCoordinates(nearestVc.telemetry.lat, nearestVc.telemetry.lon, 17);
                this.vehiclePointClick(nearestVc, null);
            },10);  

        },
        async onAfterLoad() {
            return (new Promise((resolve, reject)=>{ 
                let n = 0;
                const _wait = () => {
                    console.log('n',n);
                    if ( n > 100){
                        reject(false);
                    }
                    if (!!_vehicles.length && this.isMapLoad){
                        resolve(this.isMapLoad);
                    } else {
                        n++;
                        setTimeout(_wait, 1000);
                    }
                }   //_wait
                _wait();
            }))
        }
        
    },
    async mounted() {
        await this.getData();
        this.clearAll();
        
        _vehicles.map(vehicle => {
            this.placePoint(vehicle);
        })
        
    },
    async created() {
        _codec = await jet.http.getJSONCodec();
        _nats = await jet.http.getNats(WS_URI);
    },
    destroyed() {
        console.log('DESTROYED');
        if (_sids != null) {
            Object.keys(_sids).forEach(vId => {
                _sids[vId].unsubscribe();
            });
        }
        _codec = null;
        _nats = null;
        
    },
    
}
</script>

<style lang="scss">
.jet-map-conte{
    padding: 0 !important;
    max-width: none;
    height: calc(100vh - 148px);
    & .v-list.mt-vehicles-tree{
        overflow-y: scroll; 
        height: calc(100% - 80px);
        & .v-list-item.v-list-group__header{
            min-height: 32px !important;
            & .v-list-item__title{
                display: flex;
                align-items: center;
                & .v-icon{
                    margin-right: 0.5rem;
                }
            }
        }
        & .item__title {
          text-overflow: ellipsis;
          overflow: hidden;
        }
        
        & .v-list-group__header__append-icon {
          min-width: 1rem;
          & .v-icon {
            font-size: 1.25rem !important;
          }
        }
    }
}
</style>