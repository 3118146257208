import Project from '@/core/Project';

/** @class */
class Model {
  /**
   * @constructor
   * @param {Object} model
   */
  constructor(model) {
    this._model = model;
    this._availableColumns = [];
    this._columnIndexes = {};
    this._columnTypes = {};
    this._columns = [];
    this._projects = [];
    this._params = [];
    this._meta = null; /* meta info from typeHtmlView */
    try {
        this._init();
    }catch(e){
        console.log('on model:', e);
    }
  }

  /**
   * Инициализация модели
   */
  _init() {
    /**
     * Проверяем id колонки
     * @param {String} columnId
     * @return {Boolean}
     */
    function isSecurityColumn(columnId) {
      return columnId.startsWith('_sec_') || columnId.startsWith('_ssc_');
    }

    const {columns: columnsRaw, idColumnId} = this._model;
    const columnIndexes = {};
    const columnTypes = {};
    const params = [];
    const availableColumns = [];
    const columns = [];

    for (let i = 0, size = columnsRaw.length; i < size; i++) {
      const column = columnsRaw[i];
      const {attributes} = column;
      const columnId = Model.formatColumnId(column.id);

      columnIndexes[columnId] = i;
      columnTypes[columnId] = column.typeName;

      if (column.id !== idColumnId && !isSecurityColumn(column.id)) {
        params.push({
          _id: column.id,                               //raw id: Employees.empName
          id:  Model.formatColumnId(column.id, true),   // id witout class: empName (for saving)
          type: column.typeName,
          name: columnId                                //for form data-binding
        });
      }

      if (!attributes.hiddenInTable) {
        availableColumns.push(columnId);
        var col = {
          _id:   column.id,     //raw id
          id:    columnId,
          title: column.title,
          asName: attributes.asName,
          //специально для vuetify таблиц
          value: columnId,
          text:  column.title,
          type:  column.typeName,
          align: 'start',           //TODO:
          sortable: true,           //TODO:
          index: -1
        };
        col.filterable = col.asName;
        if ("boolean" === col.type){
            col.align = 'center';
            col.width = '4rem';
        } else if ("integer" === col.type){
            col.align = 'end';
        } else if ("string" === col.type){
            col;
        }

        columns.push(col);
      }
    }

    this._columnIndexes = columnIndexes;
    this._columnTypes = columnTypes;
    this._params = params;
    this._availableColumns = availableColumns;
    this._columns = columns;

    /**
     * Оборачиваем проект в js-класс
     * @param {Object} project
     * @return {Project}
     */
    function mapProject(project) {
      return new Project(project);
    }

    this._projects = this._model.projects.map(mapProject);
  }

  /**
   * Форматируем id колонки, примеры:
   * 1) sysNews.newsHeader -> sysnewsNewsheader
   * 2) sysNews.users.regDt -> sysnewsUsersRegdt
   * 3) sysNews.users.regDt -> regDt (only = true)
   * @param {String} id
   * @param {Boolean?} only
   * @return {String}
   * @static
   */
  static formatColumnId(id, only) {
    const partials = id.split('.');
    let fieldName = '';
    if (!!only){
        fieldName = partials.pop();
    } else {
        for (let i = 0, size = partials.length; i < size; i++) {
          const s = partials[i];

          if (!i) {
            fieldName += s.toLowerCase();
          } else {
            fieldName += s.charAt(0).toUpperCase() + s.slice(1).toLowerCase();
          }
        }
    }

    return fieldName;
  }

  get name(){
      return this._model.name;
  }
  /**
   * Получаем массив доступных (видимых) колонок
   * @return {Array<String>}
   */
  get availableColumns() {
    return this._availableColumns;
  }

  /**
   * Получаем словарь название колонки: индекс колонки
   * @return {Object}
   */
  get columnIndexes() {
    return this._columnIndexes;
  }

  /**
   * Получаем словарь название колонки: тип данных колонки
   * @return {Object}
   */
  get columnTypes() {
    return this._columnTypes;
  }

  /**
   * Получаем id колонки, содержащей id записей
   * @return {String}
   */
  get columnId() {
    return Model.formatColumnId(this._model.idColumnId);
  }

  /**
   * Получаем колонки
   * @return {Array}
   */
  get columns() {
    return this._columns;
  }

  /**
   * Получаем проекты
   * @return {Array}
   */
  get projects() {
    return this._projects;
  }

  /**
   * Получаем проекты с типом "typeJsClientHtml"
   * @return {Array<Project>}
   */
  get scripts() {
    return this.projects.filter((p)=>{
        return p.type === 'typeJsClientHtml';
    });
  }

  /**
   * Получаем проект с типом "typeHtmlView"
   * @return {Project}
   */
  get htmlView() {
    return this.projects.find( (p)=>{return p.isHtml;} );
  }

  /**
   *    Meta info from htmlView: childs & others
   */
  async get_meta(){
        var self = this;
        return new Promise(function(resolve, reject) {
            if (!!self._meta) {
              return resolve(self._meta);
            } else {
                const p = self.htmlView;
                if ( (!!p)&&(p.resource) ){
                    $http.get('/static/model/view/' + p.resource.id )
                        .then(
                            (meta)=>{
                                self._meta = meta;
                                return resolve(self._meta);
                            }
                        ).catch((err)=>{
                            return reject(err);
                        });
                } else {
                    return reject('No html view');
                }
            }
        });
  } // get_meta

  get editor(){
      return ( (!!this._meta) ? this._meta.attributes?.editorViewUri : null ) || null;
  } //editor

  /**
   * Получаем отчёты
   * @return {Array|Array<Promise>}
   */
  get reports() {
    const result = [];
    const ids = [];
    const {tags} = this._model;

    if (tags.hasOwnProperty('reports')) {
      const {reports} = tags;
      const matches = reports.match(/([a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12})/g);

      for (let i = 0, size = matches.length; i < size; i++) {
        ids.push(matches[i]);
      }
    }

    for (let i = 0, size = ids.length; i < size; i++) {
      const fn = function() {
        return $http.post('/rpc?d=jsonRpc', {
          type: 'report-description',
          id: ids[i]
        });
      };

      result.push(fn);
    }

    return result;
  }

  /**
   * Получаем проекты с типом "guiActionHtml"
   * @return {Array<Project>}
   */
  get guiActionHtml() {
    return this.projects.filter((p)=>{
        return p.isAction && ('typeJsClientHtml' === p.type);
    });
  }

  /**
   * Получаем действия
   * @return {Array|Array<Object>}
   */
  get actions() {
    const result = [];

    for (let i = 0, size = this.guiActionHtml.length; i < size; i++) {
      const project = this.guiActionHtml[i];
      result.push({
        name: project.name,
        mimeType: project.resource.attrs.mimeType
      });
    }

    return result;
  }

  /**
   * Получаем id представления
   */
  get id() {
    return this._model.viewId;
  }

  /**
   * Получаем параметры
   */
  get params() {
    return this._params;
  }

  get form(){
      return (this._meta) ? this._meta.form : null;
  }

  sin2obj(raw, all){
    const cols = this._columnIndexes;
    const keys = Object.keys(cols);
    var s, n, o = {};
    if (!!raw){
        if (!!all) {
            var res = [];
            raw.map((r)=>{
                res.push(this.sin2obj(r));
            });
            return res;
        } else {
            keys.map((key)=>{
                    n = key.lastIndexOf('.');
                    s = (n < 0) ? key : key.substr(n + 1);
                    o[s] = raw[cols[key]];
            });
            return o;
        }
    } else {
        return null;
    }
  } //sin2obj
  
  getObjName(item){
      if (!!item){
        var a = [];
        this.columns.map((h)=>{
            if (h.asName){
                a.push(item[h.id]);
            }
        });
        return a.length > 0 ? a.join(' ') : null;
      } else {
          return null;
      }
      
  } //getObjName
}

export default Model;
