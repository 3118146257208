<template>
  <div> 
    <v-dialog v-model="showThis" max-width="800">
      <v-card>
        <v-card-title>Редактирование режима работы и сезонности</v-card-title>
        <v-card-text>
          <v-row>
            <v-autocomplete
              label="Сезонность"
              v-model="selectedSeason"
              item-text="name"
              return-object
              :items="seasons"
            >
            </v-autocomplete>
          </v-row>
          <v-row>
            <v-autocomplete
              label="Режим работы"
              v-model="selectedWkmode1"
              item-text="modname"
              return-object
              :items="wkmodes"
            >
            </v-autocomplete>  
            <v-autocomplete
              label="Перевозчик"
              v-model="selectedCarrier1"
              :item-text="carrierName"
              return-object
              clearable
              :items="carriers"
              :disabled="carrier1disabled"
            >
            </v-autocomplete>    
          </v-row>
          <v-row>
            <v-autocomplete
              label="Режим работы"
              v-model="selectedWkmode2"
              item-text="modname"
              return-object
              clearable
              :items="wkmodes"
              :disabled="wkmode2disabled"
            >
            </v-autocomplete>  
            <v-autocomplete
              label="Перевозчик"
              v-model="selectedCarrier2"
              :item-text="carrierName"
              return-object
              clearable
              :items="carriers"
              :disabled="carrier2disabled"
            >
            </v-autocomplete>    
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn @click="selectSchedules">выбрать рейсы
          </v-btn>
          <v-btn @click="prepareSave">сохранить
          </v-btn>
          <v-btn @click="close">отмена
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog  v-model="showSelect" max-width="600">
      <v-card class="sel-sched-card">
        <v-card-title>Выбор рейсов</v-card-title>
        <v-card-text class="sel-sched">
          <v-row>
            <v-col>
              Выезды
              <v-banner
                outlined
                single-line
                rounded>
                <template v-for="dep in departures">
                  <v-checkbox
                    v-model="dep.checked"
                    class="pt-o mt-0"
                    hide-details
                    color="primary"
                    @change="selectDep(dep)"
                    :key="dep.id"
                    :label="dep.name"
                  ></v-checkbox>
                </template>
              </v-banner>
            </v-col>
            <v-col>
              Рейсы
              <v-banner
                outlined
                single-line
                rounded>
                <template v-for="item in currentScheds">
                  <v-checkbox
                    v-model="item.checked"
                    class="pt-o mt-0"
                    hide-details
                    color="primary"
                    :key="item.id"
                    :label="item.tripcode"
                  ></v-checkbox>
                </template>
              </v-banner>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn class="select-all" dense @click="selectAllDep">
            {{!selectedAllDeps ? "Выбрать все выезды" : "Снять все выезды"}}
          </v-btn>
          <v-btn class="select-all" dense @click="selectAllSche">
            {{!selectedAllScheds ? "Выбрать все рейсы" : "Снять все рейсы"}}
          </v-btn>
          <v-btn @click="applySelect">ок</v-btn>
          <v-btn @click="closeSelect">отмена</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div> 
</template>

<script>

    import ReleaseSchedulesService from '@/services/ReleaseSchedulesService';
    
    export default {
        name: 'SeasonWkmodeDialog',
        props: ['show', 'schedule', 'deps', 'scheds'],
        data() {
            return {
                wkmodes: [],
                seasons: [],
                carriers: [],
                schedules: null,
                departures: null,
                selectedWkmode1: null,
                selectedWkmode2: null,
                selectedCarrier1: null,
                selectedCarrier2: null,
                selectedSeason: null,
                selectedSchedules: [],
                showThis: false,
                showSelect: false,
                selectedAllScheds: false,
                selectedAllDeps: false,
            };
        },
        created () {
            this.loadWkmodes();
            this.loadSeasons();
        },
        watch: {
            deps: function (val) {
                this.departures = [];
                if (!!val) {
                    val.map((dep) => {
                        var s = _copy(dep);
                        s.checked = false;
                        this.departures.push(s);
                    });
                }
            },
            scheds: function (val) {
                this.schedules = [];
                this.selectedSchedules = [];
                if (!!val) {
                    val.map((sch) => {
                        var s = _copy(sch);
                        s.checked = false;
                        this.schedules.push(s);
                    });
                }
            },
            schedule: function (val) {
                this.selectedWkmode1 = null;
                this.selectedWkmode2 = null;
                this.selectedCarrier1 = null;
                this.selectedCarrier2 = null;
                this.selectedSeason = null;
                this.selectedSchedules = [];
                
                this.loadCarriers(val);
            },
            show: function (val) {
                this.selectedAllScheds = false;
                this.selectedAllDeps = false;
                
                this.schedules.map((sch) => {
                    sch.checked = false;
                });
                this.departures.map((dep) => {
                    dep.checked = false;
                });
                this.showThis = val;
            },
            showThis: function (val) {
                if (!val) {
                    this.$emit('close');
                }
            },
            selectedWkmode1: function(val) {
                if (typeof val === 'undefined') {
                    this.selectedCarrier1 = null;
                    this.selectedWkmode2 = null;
                    this.selectedCarrier2 = null;
                }
            },
            selectedCarrier1: function(val) {
                if (typeof val === 'undefined') {
                    this.selectedWkmode2 = null;
                    this.selectedCarrier2 = null;
                }
            },
            selectedWkmode2: function(val) {
                if (typeof val === 'undefined') {
                    this.selectedCarrier2 = null;
                }
            },
        },
        computed: {
            carrier1disabled(){
                return this.selectedWkmode1 === null || (typeof this.selectedWkmode1 === 'undefined');
            },
            wkmode2disabled(){
                return this.selectedCarrier1 === null || (typeof this.selectedCarrier1 === 'undefined');
            },
            carrier2disabled(){
                return this.selectedWkmode2 === null || (typeof this.selectedWkmode2 === 'undefined');
            },
            currentScheds(){
                if (!(!!this.schedules) || this.schedules === []){
                    return [];
                }
                var selectedDeps = this.departures.filter((dep)=>{
                    return dep.checked === true;
                });
                
                return this.schedules
                        .filter((sch) => {
                            sch.checked = false;
                            return selectedDeps.find(dep => sch.depId === dep.id )
                        })
                        .sort((s1, s2)=>{
                                return (s1.ordernumber < s2.ordernumber) ? -1 : 1;
                        });
            }
        },
        methods: {
            async loadWkmodes(){
                ReleaseSchedulesService.getWkmodes()
                    .then(result => {
                        this.wkmodes = result;
                    });
            },
            async loadSeasons(){
                ReleaseSchedulesService.getSeasons()
                    .then(result => {
                        this.seasons = result;
                    });
            },
            async loadCarriers(val){
                ReleaseSchedulesService.getCarriers(val.route, val.id)
                    .then(result => {
                        this.carriers = result;
                    })
                    .finally(() => {
                        if (!!val.season.id) {
                            this.selectedSeason = this.seasons.find(seas => {
                                if (seas.id === val.season.id) {
                                    return true;
                                }
                            });
                        }
                        if (!!val.wkmode.wkmodeid) {
                            this.selectedWkmode1 = this.wkmodes.find(wk => {
                                if (wk.id === val.wkmode.wkmodeid) {
                                    return true;
                                }
                            });
                            
                        } else if (!!val.wkmodeonschedule1) {
                            this.selectedWkmode1 = this.wkmodes.find(wk => {
                                if (wk.id === val.wkmodeonschedule1.wkmodeid) {
                                    return true;
                                }
                            });
                            this.selectedCarrier1 = this.carriers.find(crr => {
                                if (crr.id === val.wkmodeonschedule1.carrier) {
                                    return true;
                                }
                            });
                            

                            if (!!val.wkmodeonschedule2) {
                                this.selectedWkmode2 = this.wkmodes.find(wk => {
                                    if (wk.id === val.wkmodeonschedule2.wkmodeid) {
                                        return true;
                                    }
                                });
                                this.selectedCarrier2 = this.carriers.find(crr => {
                                    if (crr.id === val.wkmodeonschedule2.carrier) {
                                        return true;
                                    }
                                });
                            } 
                        }  
                    });
            },
            carrierName(item) {
                return item.orgCode + ' ' + item.shortName;
            },
            close() {
                this.$emit('close');
            },
            closeSelect() {
                this.showSelect = false;
            },
            selectSchedules() {
                this.showSelect = true;
            },
            applySelect() {
                this.selectedSchedules = [];
                this.currentScheds.map((sch) => {
                    if (sch.checked === true) {
                        this.selectedSchedules.push(sch);
                    }
                });
                this.showSelect = false;
            },
            selectAllSche() {
                this.selectedAllScheds = !this.selectedAllScheds;
                this.currentScheds.map((sch) => {
                    sch.checked = this.selectedAllScheds;
                });
            },
            selectAllDep() {
                this.selectedAllScheds = false;
                this.selectedAllDeps = !this.selectedAllDeps;
                this.departures.map((dep) => {
                    dep.checked = this.selectedAllDeps;
                });
            },
            selectDep(item) {
                this.selectedAllScheds = false;
                this.selectedAllDeps = false;
            },
            prepareSave(){
                var wkmodeObj = {
                    wkmode: {
                        name: null,
                        wkmodeid: null
                    },
                    wkmodeonschedule1: {
                        id: null,
                        name: null,
                        wkmodeid: null, 
                        carrier: null,
                        tenantid: null
                    },
                    wkmodeonschedule2: {
                        id: null,
                        name: null,
                        wkmodeid: null, 
                        carrier: null,
                        tenantid: null
                    }
                }
                if (this.selectedSchedules.length === 0) {
                    this.selectedSchedules.push(_copy(this.schedule));
                }
                
                if (!this.selectedSeason) {
                    jet.msg({type:'warning', text:`Не указана сезонность`});
                    return;
                }
                
                if (!!this.selectedWkmode1) {
                    if (!!this.selectedCarrier1) {
                        wkmodeObj.wkmodeonschedule1.wkmodeid = this.selectedWkmode1.id;
                        wkmodeObj.wkmodeonschedule1.name = `${this.selectedWkmode1.modname} (${this.selectedCarrier1.shortName})`;
                        wkmodeObj.wkmodeonschedule1.carrier = this.selectedCarrier1.id;
                        wkmodeObj.wkmodeonschedule1.tenantid = this.$store.state.auth.subject.tenantId;
                    } else {
                        wkmodeObj.wkmode.wkmodeid = this.selectedWkmode1.id;
                        wkmodeObj.wkmode.name = this.selectedWkmode1.modname;
                    }
                } else {
                    jet.msg({type:'warning', text:`Не указан режим работы`});
                    return;
                }
                if (!!this.selectedWkmode2) {
                    if (this.selectedWkmode1 === this.selectedWkmode2) {
                        jet.msg({type:'warning', text:`Режим работы «${this.selectedWkmode2.modname}» указан несколько раз`});
                        return;
                    } else {
                        if (!!this.selectedCarrier2) {
                            if (this.selectedCarrier1 === this.selectedCarrier2) {
                                jet.msg({type:'warning', text:`Перевозчик «${this.selectedCarrier2.shortName}» указан несколько раз`});
                                return;
                            } else {
                                wkmodeObj.wkmodeonschedule2.wkmodeid = this.selectedWkmode2.id;
                                wkmodeObj.wkmodeonschedule2.name = `${this.selectedWkmode2.modname} (${this.selectedCarrier2.shortName})`;
                                wkmodeObj.wkmodeonschedule2.carrier = this.selectedCarrier2.id;
                                wkmodeObj.wkmodeonschedule2.tenantid = this.$store.state.auth.subject.tenantId;
                            }
                        } else {
                            jet.msg({type:'warning', text:`Для режима работы «${this.selectedWkmode2.modname}» не указан перевозчик`});
                            return;
                        }
                    }
                }
                let scheduleWkmodes = new Map();
                this.selectedSchedules.map((sch) => {
                    var s = _copy(wkmodeObj);
                    
                    if (!!s.wkmodeonschedule1.wkmodeid) {
                        var wk = sch.wkmodeonschedule1;
                        s.wkmodeonschedule1.id = (!!wk && !!wk.id) ? wk.id : $utils.uuidv4();
                    }
                    if (!!s.wkmodeonschedule2.wkmodeid) {
                        var wk = sch.wkmodeonschedule2;
                        s.wkmodeonschedule2.id = (!!wk && !!wk.id) ? wk.id : $utils.uuidv4();
                    }
                    
                    scheduleWkmodes.set(sch.id, s);
                });
                
                this.$emit('saveSeasonWkmode', {wkmodes: scheduleWkmodes, season: this.selectedSeason});
                this.$emit('close');
            }
        },
    };

</script>

<style lang="scss">
    .sel-sched-card{
       height: 41em;
    }
    .sel-sched{
        height: 38em;
    }
    .v-banner{
        overflow-y: auto;
        height: 35em;
    }
    .select-all{
        width: 14em;
    }
</style>