<template>
  <v-tab>
    <v-badge
      v-if="checked !== 0"
      bottom
      left
      dot
      offset-x="-5"
      offset-y="4"
      :color="checked > 0 ? 'green' : 'orange'"
    ></v-badge>

    <v-badge
      v-if="active"
      :content="active"
    >
      {{ title }}
    </v-badge>

    <span v-else>{{ title }}</span>
  </v-tab>
</template>

<script>
export default {
  name: 'MapLayerTab',
  props: {
    title: {
      type: String,
      required: true,
    },
    checked: {
      type: Number,
      required: true,
    },
    active: {
      type: Number,
      required: true,
    },
  },
}
</script>

<style scoped>

</style>
