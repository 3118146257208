<template>
  <v-footer app inset light class="jet-footer">
    <p class="footer_copyright" v-html="get('copy')"></p>
    <v-spacer/>
    <v-btn text @click="showPeriod">
      {{ period.current }} / {{ period.begin }} - {{ period.end }}
    </v-btn>
    <v-btn class="ma-1" text icon small title="Справка">
      <jet-svg width="18" height="18" xref="#ico-question"/>
    </v-btn>
  </v-footer>
</template>

<script>
import JetSvg from '@/components/JetSvg';
import { isEmpty } from '@/utils';

export default {
  name: 'JetFooterBar',
  components: {
    JetSvg,
  },
  computed: {
    period(){
      const _m = this.$moment,
        _f = 'DD.MM.YYYY';
      return {
        begin: _m(this.$store.getters['period/begin']).format(_f),
        current: _m(this.$store.getters['period/current']).format(_f),
        end: _m(this.$store.getters['period/end']).format(_f),
      };
    }
  },
  methods: {
    showPeriod() {
      this.$emit('onperiod');
    },
    get(q){
        switch(q){
            case "copy":
                
                return isEmpty(this.$store.getters['branding/get']('brand.copy')) 
                            ? this.$store.getters['branding/get']('brand.web.system.name')
                            : this.$store.getters['branding/get']('brand.copy');
            default:
                return '';
        }
    }
  }
};
</script>

<style lang="scss" scoped>
.jet-footer {
  font-size: 0.75rem;
  padding: 0 16px;
}

.footer_copyright {
  margin: 0 auto;
  color: #676767;
  font-size: 12px;
}
</style>
