const state = {
    branding: {},
    loaded: false
};

const mutations = {
    setBranding(state, branding) {
        state.branding = branding;
        state.loaded = true;
    }
};

const actions = {
    fillBranding(store) {
        const { commit, state } = store;
        
        const url = '/branding?type=json';
        
        return new Promise((resolve)=>{
            if (!!state.loaded){
                resolve();
                return;
            }
            
            (async ()=>{
                $http.get(url)
                    .then(function(res){
                        commit('setBranding', res);
                        resolve();
                    })
                    .catch(function(e){
                        console.log('ERR (branding read):', e);
                    });
            })();
        });
    }
};

const getters = {
    get: state => name => {
        return (!!state.loaded) ? state.branding[name] || '' : '';
    }
};

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
};
