<template>
  <div align="center">
    <v-progress-circular indeterminate color="#003c87"/>
  </div>
</template>

<script>
export default {
  name: 'JetSpinner',
};
</script>
