<template>
  <div></div>
</template>

<script>
// Service
import PlansService from "@/services/PlansService";
import store from "@/store";

export default {
  data: () => ({
    DATE_FORMAT: 'yyyy-MM-DD',
    DATE_TIME_FORMAT: 'yyyy-MM-DD HH:mm:ss',
    changed: false,
    loadingSchedules: true,
    loadingPlanButton: false,
    schedules: [
      {
        schedules: [],
      },
    ],
    selected: [],
    departureMode: false,
    onlyUnplanned: false,
    tripCode: '',
    rightDialog: false,
    // ТС
    vehiclesTrip: [],
    vehicles: [],
    filteredVehicles: [],
    searchVehicle: '',
    appointedSelectedVehicle: false,
    // Добавление второго ТС
    isTwoVehicle: false,
    stopPoint: null,
    endStopPoint: null,
    stopPoints: [],
    loadingStopPoints: false,
    stopPointDialog: false,
    // Удаление постановок
    dialogDelete: false,
    loadingDelete: false,
    // Массовое выделение
    firstItem: null,
    // Выбрать все
    selectAll: false,
    // Диалог с ошибкой
    dialogError: false,
    errorText: '',
    // Планирование на период
    showPeriodPicker: false,
    datePeriodEnd: (new Date()).toISOString().substr(0, 10),
  }),
  watch: {
    selectAll(newValue) {
      for (const departure of this.schedules) {
        departure.selected = newValue;
        for (const schedule of departure.schedules) {
          schedule.selected = newValue;
          if (newValue) {
            const index = this.selected.findIndex(item => {
              return item.scheduleId === schedule.scheduleId;
            });
            if (index === -1) {
              this.selected.push(schedule);
            }
          }
        }
      }
      if (!newValue) {
        this.clearSelected();
      }
    },
  },
  computed: {
    filteredSchedules() {
      let filteredSchedules = [];
      const regexp = new RegExp(this.selectedVehicle?.gov || '', 'i');
      for (const departure of this.schedules) {
        for (const schedule of departure.schedules) {
          if ((this.appointedSelectedVehicle && schedule.trips.filter(trip => regexp.test(trip.govnum)).length) || !this.appointedSelectedVehicle) {
            if ((this.onlyUnplanned && !schedule.planned) || !this.onlyUnplanned) {
              filteredSchedules.push(departure);
              break;
            }
          }
        }
      }
      return filteredSchedules;
    },
    tripsName() {
      const departures = [];
      const trips = [];
      this.selected.forEach(departure => {
        if (departure.departureName) {
          const index = departures.findIndex(departureName => {
            return departureName === departure.departureName;
          });
          if (index === -1) {
            departures.push(departure.departureName);
          }
        } else {
          const index = trips.findIndex(tripName => {
            return tripName === departure.tripCode;
          });
          if (index === -1) {
            trips.push(departure.tripCode);
          }
        }
      });
      let resString = '';
      if (departures.length > 0) {
        resString += 'выезд(-ы): ' + departures.join(', ');
        if (trips.length > 0) {
          resString += ' и ';
        }
      }
      if (trips.length > 0) {
        resString += 'рейс(-ы): ' + trips.join(', ');
      }
      return resString;
    }
  },
  methods: {
    // Очистка "выделенных" checkbox
    clearSelected() {
      this.selected = [];
      for (const group of this.schedules) {
        group.selected = false;
        for (const schedule of group.schedules) {
          schedule.selected = false;
        }
      }
    },

    // Метод добавляющий постановки
    planTrips() {
      this.loadingPlanButton = true;
      let warningReplanning = false;
      const added = [];
      const startDate = $utils.formatDate(
        new Date(this.fullDate.iso), 'yyyy-MM-DD 00:00:00',
      );
      const endDate = $utils.formatDate(
        new Date(this.fullDate.iso), 'yyyy-MM-DD 23:59:59',
      );
      const baseParams = [
        {
          id: 'StartDt',
          type: 'date',
          value: startDate,
        },
        {
          id: 'EndDt',
          type: 'date',
          value: endDate,
        },
        {
          id: 'carrier',
          type: 'id',
          value: this.selectedRoute.carrierId,
        },
        {
          id: 'route',
          type: 'id',
          value: this.selectedRoute.routeId,
        },
        {
          id: 'vehicle',
          type: 'id',
          value: this.selectedVehicle.id || this.selectedVehicle,
        },
      ];
      for (const schedule of this.selected) {
        if (!schedule.planned || this.isTwoVehicle) {
          const params = [...baseParams];
          params.push(
            {
              id: 'id',
              type: 'id',
              value: $utils.uuidv4(),
            },
            {
              id: 'schedule',
              type: 'id',
              value: schedule.scheduleId,
            },
            {
              id: 'firststop',
              type: 'id',
              value: schedule.firststop,
            },
            {
              id: 'laststop',
              type: 'id',
              value: schedule.laststop,
            },
            {
              id: 'starttrip',
              type: 'date',
              value: this.getDateTrip(schedule.startTime),
            },
            {
              id: 'endtrip',
              type: 'date',
              value: this.getDateTrip(schedule.endTime),
            },
          );
          if (this.isTwoVehicle) {
            params.push(
              {
                id: 'firstStop',
                type: 'id',
                value: this.stopPoint.stopId,
              },
              {
                id: 'lastStop',
                type: 'id',
                value: this.endStopPoint.stopId,
              },
            );
          }
          added.push(PlansService.addTrips(params));
        } else if (schedule.planned) {
          warningReplanning = true;
        }
      }

      if (warningReplanning) {
        this.dialogError = true;
        this.errorText = 'Невозможно перепланировать рейсы (один из рейсов). Для того, что бы перепланировать рейс - удалите с него постановки.';
      }

      Promise.all(added).then(() => {
        this.stopPointDialog = false;
        this.loadingPlanButton = false;
        this.searchVehicle = '';
        this.isTwoVehicle = false;
        this.dialogPlanning = false;
        this.loadTrips(this.selectedRoute);
        this.changed = true;
      });
    },

    // Метод добавляющий постановки
    planTripsOnPeriod() {
      this.loadingPlanButton = true;
      let warningReplanning = false;
      const added = [];
      const startDate = $utils.formatDate(
          new Date(this.fullDate.iso), 'yyyy-MM-DD 00:00:00',
      );
      const endDate = $utils.formatDate(
          new Date(this.datePeriodEnd), 'yyyy-MM-DD 23:59:59',
      );
      const baseParams = [
        {
          id: 'StartDate',
          type: 'date',
          value: startDate,
        },
        {
          id: 'EndDate',
          type: 'date',
          value: endDate,
        },
        {
          id: 'tenantId',
          type: 'id',
          value: this.$store.state.auth.subject.tenantId,
        },
        {
          id: 'vehicle',
          type: 'id',
          value: this.selectedVehicle.id || this.selectedVehicle,
        },
      ];
      for (const schedule of this.selected) {
        if (!schedule.planned || this.isTwoVehicle) {
          const params = [...baseParams];
          params.push(
              {
                id: 'id',
                type: 'id',
                value: $utils.uuidv4(),
              },
              {
                id: 'schedule',
                type: 'id',
                value: schedule.scheduleId,
              },
          );
          if (this.isTwoVehicle) {
            params.push(
                {
                  id: 'firstStop',
                  type: 'id',
                  value: this.stopPoint.stopId,
                },
                {
                  id: 'lastStop',
                  type: 'id',
                  value: this.endStopPoint.stopId,
                },
            );
          }
          added.push(PlansService.addTripsOnPeriod(params));
        } else if (schedule.planned) {
          warningReplanning = true;
        }
      }

      if (warningReplanning) {
        this.dialogError = true;
        this.errorText = 'Невозможно перепланировать рейсы (один из рейсов). Для того, что бы перепланировать рейс - удалите с него постановки.';
      }

      Promise.all(added).then(() => {
        this.stopPointDialog = false;
        this.loadingPlanButton = false;
        this.searchVehicle = '';
        this.isTwoVehicle = false;
        this.dialogPlanning = false;
        this.loadTrips(this.selectedRoute);
        this.changed = true;
      });
    },

    // Подготовка правильной пары дата-время
    getDateTrip(time) {
      const dateTrip = new Date(this.fullDate.iso);
      dateTrip.setHours(time.getHours());
      dateTrip.setMinutes(time.getMinutes());
      return $utils.formatDate(dateTrip, this.formatDateTime);
    },
    deleteTrips(selected) {
      this.loadingDelete = true;
      const deleted = [];
      const currentDate = $utils.formatDate(
        new Date(this.fullDate.iso), 'yyyy-MM-DD 00:00:00',
      );
      for (const schedule of selected || this.selected) {
        if (schedule.planned) {
          for (const trip of schedule.trips) {
            deleted.push(
              PlansService.deleteTrips(
                trip.tripId,
                currentDate,
              ),
            );
          }
        }
      }
      Promise.all(deleted).then(() => {
        this.loadingDelete = false;
        this.dialogDelete = false;
        this.deletedTrips();
        if (selected) {
          selected = [];
        }
      });
    },
    // Метод вызываемый после удаления постановок, нужен для обновления страницы
    deletedTrips() {
      this.changed = true;
      this.loadTrips();
    },
    // Добавление 2 ТС на рейс, получает остановки
    async addVehicle(schedule) {
      this.loadingStopPoints = false;
      if (schedule.length > 1) {
        jet.msg({
          text: 'Установка дополнительного ТС возможна только на рейс',
          color: 'error'
        });
        return;
      }

      const dateWork = $utils.formatDate(
        new Date(this.fullDate.iso), 'yyyy-MM-DD 03:00:00',
      );
      Promise.all([
        PlansService.getStops(
          schedule[0].scheduleId,
          dateWork,
        ),
      ]).then(res => {
        this.stopPoints = res[0];
        this.endStopPoint = this.stopPoints[this.stopPoints.length - 1];
        this.loadingStopPoints = true;
        // Удаляем первую остановку (конечная)
        this.stopPoints = this.stopPoints.slice(1, this.stopPoints.length - 1);
        // Если на рейсе уже два ТС, удаляем все остановки до второго ТС
        if (schedule[0].trips.length >= 2) {
          let maxNumber = -1;
          for (const trip of schedule[0].trips) {
            const num = trip.stopNumber;
            maxNumber = (num && num > maxNumber) ? num : maxNumber;
          }
          this.stopPoints = this.stopPoints.filter(stop => {
            return stop.stopNumber > maxNumber;
          });
        }
      });
      this.isTwoVehicle = true;
      this.vehiclesTrip = this.vehicles.slice();
      if (schedule[0].planned) {
        for (const trip of schedule[0].trips) {
          const index = this.vehiclesTrip.findIndex(item => {
            return item.govnum === trip.govnum;
          });
          if (index !== -1) {
            this.vehiclesTrip.splice(index, 1);
          }
        }
      }
      this.filteredVehicles = this.vehiclesTrip.slice();
      this.tripCode = schedule.tripCode;
      this.planButton(schedule, false);
    },
    clickCheckbox(event) {
      if (event.isShiftPress) {
        if (this.firstItem) {
          let first = -1;
          let last = -1;
          first = this.schedules.findIndex(schedule => {
            if (this.firstItem.id) {
              return schedule.id === this.firstItem.id;
            } else {
             return schedule.schedules[0].scheduleId === this.firstItem.scheduleId;
            }
          });
          last = this.schedules.findIndex(schedule => {
            if (event.item.id) {
              return schedule.id === event.item.id;
            } else {
              return schedule.schedules[0].scheduleId === event.item.scheduleId;
            }
          });

          if (first !== -1 && last !== -1) {
            // Что бы работало и в обратном порядке
            // Когда мы выбираем сначала элемент внизу, а потом вверху
            if (last < first) {
              const tmp = first;
              first = last;
              last = tmp;
            }
            for (let i = first; i <= last; i++) {
              this.schedules[i].selected = true;
              for (const schedule of this.schedules[i].schedules) {
                schedule.selected = true;
                const index = this.selected.findIndex(item => {
                  return item.scheduleId === schedule.scheduleId;
                });
                if (index === -1) {
                  this.selected.push(schedule);
                }
              }
            }
          }
        }
      } else {
        this.firstItem = event.item;
      }
    },
  },
};
</script>

