<template>
  <v-text-field
    v-model="data"
    :label="label"
    :flat="isAppBar"
    :solo-inverted="isAppBar"
    type="search"
    hide-details
    clearable
    rounded
    dense
    clear-icon="far fa-times-circle"
    :outlined="!isAppBar"
    :solo="!isAppBar"
  >
    <template v-slot:prepend-inner>
      <jet-svg width="16" height="16" xref="#ico-search"/>
    </template>
  </v-text-field>
</template>

<script>
import JetSvg from '@/components/JetSvg';

export default {
  name: "JetSearchField",
  components: {
    JetSvg,
  },
  props: {
    label: {
      type: String,
      default: "Поиск",
    },
    value: {
      required: true,
    },
    isAppBar: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    data: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    }
  },
}
</script>
