<script>

    import ReleaseSchedulesService from '@/services/ReleaseSchedulesService';
    import {dayOfWeekToFormat} from "@/services/JetDate";
    import JetInputId from '@/components/editors/JetInputId';
    import ScheduleVersionSelection from '@/components/dev/components/sched-ext/ScheduleVersionSelection';

    export default {
        name: 'CopyVersionDialog',
        components: {
            JetInputId,
            ScheduleVersionSelection,
        },
        props: ['show', 'route', 'schedules',  'verId'],
        data() {
            return {
                version: 1,
                selected: null,
                schDt: new Date().setHours(0, 0, 0, 0),
                dateMenu: false,
                filter: null,
                loading: false,
                dayOfWeekToFormat: () => '',
            };
        },    
        provide: {
            owner: {
                reg(comp){},
                unreg(comp){},
                val(name, val){}
            }
        },

        created () {
            this.dayOfWeekToFormat = dayOfWeekToFormat;
        },
        watch: {
            show: function (val) {
                this.loading = false;
                if (!!this.route) {
                    this.filter = `exists(\"17743043-925a-48e4-90b5-8f1e77226a01\",
                                                   \"and(
                                                       eq(field(\\\"s.verid\\\"),super.field(\\\"s.verid\\\")),
                                                       eq(field(\\\"s.id\\\"),param(\\\"${ this.route.vcroutesId }\\\",\\\"id\\\"))
                                                   )\"
                                    )
                                    &sort=vcReleaseSchedules.verStatus.Name,-vcReleaseSchedules.verStart`;
                }
                if (!!this.schedules) {
                    this.version = Math.max.apply(Math, this.schedules.map(function (o) {
                        return o.vcreleaseschedulesVersion;
                    })) + 1;
                }
            },
        },
        methods: {
            copyVersion() {
                if (!this.selected) {
                    jet.msg({type:'warning', text:'Версия расписания не выбрана'});
                    return;
                }
                this.loading = true;
                ReleaseSchedulesService.copyVersion(this.route.vcroutesId, this.selected.id)
                    .then(result => {
                        if (!!result && result.result) {
                            const response = result.result.data[0];
                            this.$emit('copyVersion', response[0]);
                        }
                    })
                    .finally(() => {
                        this.loading = false;
                        this.$emit('close');
                    });
            }
        },
        render(h) {
            return h('v-dialog', {props: {
                    width: "600px",
                    value: this.show
                }}, [
                h('v-card', [
                    h('v-card-title', 'Копировать версию расписания'),
                    h('v-card-text', [
                        h('ScheduleVersionSelection', {
                            props: {filter: this.filter, selected: this.verId},
                            on: {
                                selectVersion: (r) => {
                                    this.selected = r;
                                }
                            }
                        }),
                        
                        h('v-form', [
                            h('v-text-field', {
                                props: {label: 'Номер версии расписания', value: this.version, disabled: true}}),
                            h('v-menu', {
                                props: {"max-width": "290px", "min-width": "290px", value: this.dateMenu},
                                scopedSlots: {
                                    activator: ({on, attrs}) => {
                                        on["blur"] = (e) => {
                                            console.log("blur:", e);
                                        };
                                        return h('v-text-field', {
                                            props: {
                                                label: "Дата начала действия версии расписания",
                                                "append-icon": "mdi-calendar",
                                                value: $utils.formatDate(this.schDt,'DD.MM.yyyy'),
                                                "full-width": false
                                            },
                                            style: {"max-width": "12rem"},
                                            on: on
                                        });
                                    }
                                }
                            }, [
                                h('v-date-picker', {props: {
                                        value: $utils.formatDate(this.schDt,'YYYY-MM-DD'),
                                        "no-title": true, locale: "ru-RU",
                                        "weekday-format": this.dayOfWeekToFormat,
                                        "first-day-of-week": "1",
                                        scrollable: true
                                    }, on: {
                                        input: (schDt) => {
                                            if (!schDt) {
                                                return;
                                            }
                                            const [y, m, d] = schDt.split('-');
                                            this.schDt = new Date(y, m - 1, d);
                                            this.dateMenu = false;
                                        }
                                    }})
                            ]),
                        ])
                    ]),
                    h('v-card-actions', [
                        h('v-spacer'),
                        h('v-btn', {
                            props: {outlined: true, tile: true, color: 'gray', small: true, disabled: this.loading},
                            on: {click: () => {
                                    this.$emit('close');
                                }}
                        }, 'отмена'),
                        (!!this.route)
                                ? h('v-btn', {
                                    props: {outlined: true, tile: true, color: 'primary', small: true, loading: this.loading},
                                    on: {click: this.copyVersion}
                                }, 'сохранить')
                                : null,
                    ])
                ])
            ]);
        }
    };

</script>
