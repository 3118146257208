<script>
    const $moment = require('moment');
    const SCH_MODE = {
        'none': -1,
        'default': 0,
        'loading': 9,
        'error': 10
    };

    import ReleaseSchedulesService from '@/services/ReleaseSchedulesService';
    import PlansService from '@/services/PlansService';
    import VersionSelection from '@/components/actions/RoutesSettings/VersionSelection';

    export default {

        name: 'ApproveVersionDialog',
        props: ['show', 'route', 'verId', 'schedules'],
        data() {
            return {
                mode: SCH_MODE.none,
                verInfo: null,
                schDt: new Date().setHours(0, 0, 0, 0),
                dateMenu: false,
                approved: null,
                msg: null,
                canFixTotal: true,
                showConfirm: false,
                confirmMessage: null,
                showInfo: false,
                infoMessage: null,
            };
        },
        computed: {
            canFix() {
                this.msg = null;
                if (!!this.verInfo) {
                    if (!!this.verInfo.error) {
                        this.msg = 'Ошибка загрузки данных расписания';
                        return false;
                    } else {
                        if (!/^проект/i.test(this.verInfo.stateName)) {
                            this.msg = 'Действие доступно только для статуса «Проект»';
                            return false;
                        }
                        if (!/^утвержден/i.test(this.verInfo.routeStateName)) {
                            this.msg = `Необходимо сначала утвердить версию маршрута «${this.verInfo.routeVersion}». Расписание не может быть утверждено`;
                            return false;
                        }
                        if (typeof this.verInfo.warnings !== 'undefined') {
                            if (!!this.verInfo.warnings.emptytime) {
                                this.msg = `Не заполнено время въезда/выезда для рейсов «${this.verInfo.warnings.emptytime}». Расписание не может быть утверждено`;
                                return false;
                            }
                            if (!!this.verInfo.warnings.season) {
                                this.msg = `Необходимо заполнить режим работы и сезонность для рейсов «${this.verInfo.warnings.season}». Расписание не может быть утверждено`;
                                return false;
                            }
                        }
                    }
                } else {
                    return false;
                }
                return true;
            },
            hasApproved() {
                if (!!this.approved) {
                    return this.verId !== this.approved.vcreleaseschedulesId;
                } else {
                    return false;
                }
            },
        },
        watch: {
            show(val) {
                if (!!val) {
                    this.approved = this.schedules.find(it => it.vcreleaseschedulesVerstatusName === 'Утвержден');
                    this.read();
                }
            }
        },
        methods: {
            async read() {
                this.mode = SCH_MODE.loading;
                const opts = {
                    type: 'core-read',
                    query: 'sin2:/v:17743043-925a-48e4-90b5-8f1e77226a01?filter=eq(field(".id"),param("' + this.verId + '","id"))'
                };
                try {
                    var r = await $http.post(opts);
                    if (r.error) {
                        throw r.error;
                    }
                    if (r.result.data.length > 0) {
                        const data = r.result.data || [];
                        const ci = r.result.columnIndexes || {};
                        const newData = data.map(it => ({
                                routeID: it[ci['s.id']],
                                routecode: it[ci['s.routecode']],
                                routename: it[ci['s.routename']],
                                routeVersion: it[ci['s.version']],
                                routeverstart: it[ci['s.verstart']],
                                routeStateName: it[ci['s.verstatus.name']],
                                version: it[ci['vcreleaseschedules.version']],
                                verstart: it[ci['vcreleaseschedules.verstart']],
                                stateName: it[ci['vcreleaseschedules.verstatus.name']],
                                
                        }));
                        let preData = await ReleaseSchedulesService.checkBeforeApprove(this.verId, $moment(this.schDt).format('DD.MM.YYYY'), 0);
                        let warnings = JSON.parse(preData[0].jsonobject.value);
                        this.verInfo = newData[0];
                        this.verInfo.warnings = warnings[0];
                        this.mode = SCH_MODE.default;
                    } else {
                        this.verInfo = {error: 'Данные не получены'};
                        this.mode = SCH_MODE.error;
                    }
                } catch (e) {
                    this.verInfo = {error: e};
                    this.mode = SCH_MODE.error;
                }
            }, //read
            
            async checkBeforeFix() {
                this.canFixTotal = true;
                this.infoMessage = null;
                this.confirmMessage = null;
                this.showConfirm = false;
                
                if (!!this.approved && $moment(this.schDt).isSameOrBefore($moment(this.approved.vcreleaseschedulesVerstart))) {
                    this.confirmMessage = 'Нельзя утвердить указанной датой, т.к. уже существует версия расписания на указанную дату';
                    this.showConfirm = true;
                    this.canFixTotal = false;
                    return;
                }
                
                console.log('check sch/route', this.schDt, this.verInfo.routeverstart);
                
                if ( !!this.verInfo.routeverstart && $moment(this.schDt).isBefore($moment(this.verInfo.routeverstart)) ) {
                    this.confirmMessage = 'Нельзя утвердить указанной датой, т.к. версия маршрута утверждена более поздней датой';
                    this.showConfirm = true;
                    this.canFixTotal = false;
                    return;
                }
                if (!!this.approved) {
                    let preData = await ReleaseSchedulesService.checkBeforeApprove(this.approved.vcreleaseschedulesId, $moment(this.schDt-1).format('DD.MM.YYYY'), 1);
                    let trips = JSON.parse(preData[0].jsonobject.value);
                    if (!!trips[0].trips || !!trips[0].futuretrips) {
                        this.confirmMessage = 'По данному расписанию были выполнены постановки в будущем периоде, которые будут УДАЛЕНЫ';
                        this.infoMessage = 'Нельзя утвердить расписание, т.к. уже существуют постановки на рейс по предыдущей версии';
                        this.showConfirm = true;
                        this.verInfo.trips = trips[0];
                        return;
                    }
                }
                
                this.fix();
            }, //checkBeforeFix
            
            fix() {
                const currentDate = $utils.formatDate(
                        new Date($moment(this.schDt-1)), 'yyyy-MM-DD 00:00:00',
                    );
                    
                if (!!this.verInfo.trips) {
                    if (!!this.verInfo.trips.trips) {
                        var trips = this.verInfo.trips.trips.split(",");
                        trips.forEach(x => {
                            PlansService.deleteTrips(x, currentDate);
                        });
                    }
                    if (!!this.verInfo.trips.futuretrips) {
                        var futuretrips = this.verInfo.trips.futuretrips.split(",");
                        futuretrips.forEach(x => {
                            PlansService.deleteFutureTrips(x, new Date(0).getTime());
                        });
                    }
                }
                
                if (!!this.approved) {
                    var params = [
                        {
                            id: 'id',
                            type: 'id',
                            value: this.approved.vcreleaseschedulesId,
                        }, {
                            id: 'verEnd',
                            type: 'integer',
                            value: (new Date(currentDate)).getTime(),
                        }, {
                            id: 'verstatus',
                            type: 'id',
                            value: 'dfbc6fca-4a65-4a55-9a36-fe69b2703cf3',
                        }
                    ];
                    ReleaseSchedulesService.updateReleaseSchedule(params);
                }
                
                var params = [
                    {
                        id: 'id',
                        type: 'id',
                        value: this.verId,
                    }, {
                        id: 'verStart',
                        type: 'integer',
                        value: new Date(this.schDt).getTime(),
                    }, {
                        id: 'verstatus',
                        type: 'id',
                        value: '3a928e12-eca1-4be3-bff7-df05b5155bde',
                    }
                ];
                
                ReleaseSchedulesService.updateReleaseSchedule(params)
                    .then(result => {
                        this.$emit('approveVersion', this.verId);
                    })
                    .finally(() => {
                        this.showConfirm = false;
                        this.$emit('close');
                    });

            }   //fix
        },
        
        render(h) {
            return h('div', [
                h('v-dialog', {props: {
                        width: "640px",
                        value: this.show
                    }}, [
                    h('v-card', {class: {"sch-fix": true}}, [
                        h('v-card-title', 'Утвердить версию расписания'),
                        h('v-card-text', [
                            (this.mode === SCH_MODE.loading)
                            ? h('v-skeleton-loader', {props: {type: 'card-heading, image, actions'}})
                            : (this.mode === SCH_MODE.error)
                            ? h('v-alert', {props: {border: "top", "colored-border": true, type: "warning"}}, 'Ошибка получения информации о версии маршрута')
                            : (this.mode === SCH_MODE.none)
                            ? null
                            : h('div', [
                                h('h3', this.verInfo.routecode + '. ' + this.verInfo.routename),
                                h('div', {class: {'mt-3': true, 'mb-3': true}},
                                        'версия № ' + this.verInfo.version +
                                        (!!this.verInfo.verstart ? ' от ' + $moment(this.verInfo.verstart).format('DD.MM.YYYY') : '') +
                                        ': ' + this.verInfo.stateName),
                                h('v-menu', {
                                    props: {"max-width": "290px", "min-width": "290px", value: this.dateMenu, disabled: !this.canFix},
                                    scopedSlots: {
                                        activator: ({on, attrs}) => {
                                            on["blur"] = (e) => {
                                                console.log("blur:", e);
                                            };
                                            return h('v-text-field', {
                                                props: {
                                                    label: "Дата утверждения",
                                                    "append-icon": "mdi-calendar",
                                                    value: $utils.formatDate(this.schDt,'DD.MM.yyyy'),
                                                    "full-width": false
                                                },
                                                style: {"max-width": "12rem"},
                                                on: on
                                            });
                                        }
                                    }
                                }, [
                                    h('v-date-picker', {props: {
                                            value: $utils.formatDate(this.schDt,'YYYY-MM-DD'),
                                            "no-title": true, locale: "ru-RU",
                                            "weekday-format": this.dayOfWeekToFormat,
                                            "first-day-of-week": "1",
                                            scrollable: true
                                        }, on: {
                                            input: (schDt) => {
                                                if (!schDt) {
                                                    return;
                                                }
                                                const [y, m, d] = schDt.split('-');
                                                this.schDt = new Date(y, m - 1, d);
                                                this.dateMenu = false;
                                            }
                                        }})
                                ]),
                                this.canFix
                                    ? null
                                    : h('v-alert', {props: {outlined: true, type: "error"}}, this.msg),
                                this.canFix && this.hasApproved
                                    ? h('v-alert', {props: {outlined: true, type: "warning"}}, 
                                    `Версия расписания ${this.approved.vcreleaseschedulesVersion} прекратит действие ${$moment(this.schDt-1).format('DD.MM.YYYY')}`)
                                    : null
                            ])
                        ]),
                        h('v-card-actions', [
                            h('v-spacer'),
                            h('v-btn', {props: {outlined: true, tile: true, color: 'gray', small: true},
                                on: {click: () => {
                                        this.$emit('close');
                                    }
                                }
                            }, 'отмена'),
                            this.canFix
                                ? h('v-btn', {props: {outlined: true, tile: true, color: 'primary', small: true},
                                    on: {click: this.checkBeforeFix}
                                }, 'утвердить')
                                : null
                        ])
                    ])
                ]),
                h('v-dialog', {props: {
                        width: "500px",
                        value: this.showConfirm
                    }}, [
                        h('v-card', {class: {"sch-fix": true}}, [
                            h('v-card-title', 'Утвердить версию расписания'),
                            h('v-card-text', this.confirmMessage),
                            h('v-card-actions', [
                                h('v-spacer'),
                                h('v-btn', {props: {outlined: true, tile: true, color: 'gray', small: true},
                                    on: {click: () => {
                                            this.showConfirm = false;
                                            if (!!this.infoMessage) {
                                                this.showInfo = true;
                                            }
                                        }
                                    }
                                }, 'отмена'),
                                this.canFixTotal
                                    ? h('v-btn', {props: {outlined: true, tile: true, color: 'primary', small: true},
                                        on: {click: this.fix}
                                    }, 'да')
                                    : null
                            ])
                        ])
                    ]),
                    h('v-dialog', {props: {
                        width: "500px",
                        value: this.showInfo
                    }}, [
                        h('v-card', {class: {"sch-fix": true}}, [
                            h('v-card-title', 'Утвердить версию расписания'),
                            h('v-card-text', this.infoMessage),
                            h('v-card-actions', [
                                h('v-spacer'),
                                h('v-btn', {props: {outlined: true, tile: true, color: 'gray', small: true},
                                    on: {click: () => {
                                            this.showInfo = false;
                                        }
                                    }
                                }, 'отмена')
                            ])
                        ])
                    ]),
            ]);
        }
    }

</script>