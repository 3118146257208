<template>
    <v-dialog v-model="show"
              scrollable
              max-width="720"
              content-class="jet-tenants-dlg">
        <v-card tile
                :loading="loading">
            <v-toolbar dark
                       fixed
                       color="primary">
                <v-text-field v-if="(mode===MODES.search)"
                              clearable
                              dark
                              dense
                              full-width
                              hide-details
                              v-on:input="onsearch"
                              v-on:click:clear="search(false)">
                </v-text-field>
                <v-toolbar-title v-else>
                        {{ selected.title }}
                        <div class="small">текущая организация</div>
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn text
                       v-on:click="search(true)"><v-icon>mdi-magnify</v-icon></v-btn>
                <v-btn text
                       v-on:click="show = false">
                    закрыть&nbsp;<v-icon small>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card-text>
                <v-list class="jet-tenants"
                        dense>
                    <v-list-item-group v-model="selected" v-if="tenants.length > 0">
                        <template v-for="(t, n) in tenants" >
                            <v-list-item :key="t.id"
                                         :value="t">
                                {{ t.title }}
                            </v-list-item>
                            <v-divider :key="'dv-' + n" />
                        </template>
                    </v-list-item-group>
                    <v-list-item v-else
                                 v-on:click="search(false)">
                        <v-icon>mdi-magnify-close</v-icon>&nbsp;ничего не найдено | сбросить
                    </v-list-item>
                </v-list>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>
<script>
import { isEmpty, MODES } from "@/utils";

var _hs = false;

export default {
    name: 'JetTenantsDlg',
    data(){
        return {
            MODES,
            mode: MODES.default,
            s: '',
            show: false,
            loading: false
        };
    },
    computed: {
        tenants(){
            const s = this.$store.getters['profile/subject'];
            if (!(!!s)){
                return null;
            }
            const a = [];
            Object.keys(s.availableTenants).map( k=>{
                if ( !(!!k.blocked) ){
                    a.push(s.availableTenants[k]);
                }
            });
            if ( isEmpty(this.s) ){
                return a;
            } else {
                const re = new RegExp( this.s, 'gi' );
                return a.filter( t => { return re.test(t.title); });
            }
        },
        selected: {
            get(){
                const tenantId = this.$store.state.profile.subject?.tenantId;
                return isEmpty(tenantId) ? null : this.$store.state.profile.subject.availableTenants[tenantId];
            },
            set(val){
                if (!!val){
                    this.$store.dispatch("profile/tenant", val)
                            .then(()=>{
                                this.loading = false;
                                this.show = false;
                            })
                            .catch(e => {
                                this.loading = false;
                            });
                }
            }
        }
    },
    methods: {
        search(e){
            if ( !(!!e) ){
                this.mode = MODES.default;
                this.s = '';
                return;
            }
            this.mode = (this.mode!==MODES.search)
                                ? MODES.search
                                : MODES.default;
            if (this.mode===MODES.search){
                this.$nextTick( ()=>{
                    const inp = $(".jet-tenants-dlg").find("input");
                    inp.val(this.s);
                    inp.focus();
                });
            }
        },
        onsearch(e){
            if (_hs){
                clearTimeout(_hs);
            }
            _hs = setTimeout(()=>{
                _hs = false;
                this.s = e;
            }, 666);
        },
        open(){
            this.loading = false;
            this.mode = MODES.default;
            this.show = (new Date()).getTime();
            setTimeout( () => {
                const conte = $(".jet-tenants-dlg .v-card .v-card__text");
                this.$vuetify.goTo(conte.find(".v-list .v-item--active").get(0), {
                    container: conte.get(0)
                });
            }, 500);
        }
    }
};
</script>
<style lang="scss" scoped>
    .jet-tenants-dlg{
        & .v-toolbar{
            &__title{
                line-height: 1.125;
                & .small{
                    font-size: 0.75rem;
                    opacity: 0.8;
                }
            }
        }
        & .v-card{
            &__text{
                min-height: 640px;
            }
        }
    }
</style>
