<script>

    import ReleaseSchedulesService from '@/services/ReleaseSchedulesService';
    import VersionSelection from '@/components/actions/RoutesSettings/VersionSelection';
    import {dayOfWeekToFormat} from "@/services/JetDate";
    
    export default {
        name: 'NewVersionDialog',
        components: {
            VersionSelection,
        },
        props: ['show', 'route', 'schedules'],
        data() {
            return {
                version: 1,
                selected: null,
                schDt: new Date().setHours(0, 0, 0, 0),
                dateMenu: false,
                filter: null,
                dayOfWeekToFormat: () => '',
            };
        },
        created () {
            this.dayOfWeekToFormat = dayOfWeekToFormat;
        },
        watch: {
            show: function (val) {
                if (!!this.route) {
                    this.filter = `and(eq(field(".verID"),param("${this.route.vcroutesVerid}","id")),not(eq(field(".verStatus"),param("dfbc6fca-4a65-4a55-9a36-fe69b2703cf3","id")))`;
                }
                if (!!this.schedules) {
                    this.version = Math.max.apply(Math, this.schedules.map(function (o) {
                        return o.vcreleaseschedulesVersion;
                    })) + 1;
                }
            },
        },
        methods: {
            saveNewVersion() {
                const id = $utils.uuidv4();
                var params = [
                    {
                        id: 'id',
                        type: 'id',
                        value: id,
                    }, {
                        id: 'route',
                        type: 'id',
                        value: this.selected.vcroutesId,
                    }, {
                        id: 'version',
                        type: 'integer',
                        value: this.version
                    }, {
                        id: 'verstatus',
                        type: 'id',
                        value: 'ed4762ee-ca91-4b52-89e8-1176fb0a296e',
                    }, {
                        id: 'verStart',
                        type: 'integer',
                        value: new Date(this.schDt).getTime(),
                    }, 
                ];
                ReleaseSchedulesService.createNewReleaseSchedule(params)
                    .then(result => {
                        this.$emit('saveNewVersion', id);
                    })
                    .finally(() => {
                        this.$emit('close');
                    });
            }
        },
        render(h) {
            return h('v-dialog', {props: {
                    width: "600px",
                    value: this.show
                }}, [
                h('v-card', [
                    h('v-card-title', 'Новая версия расписания'),
                    h('v-card-text', [
                        h('VersionSelection', {
                            props: {filter: this.filter, selected: this.route},
                            on: {
                                selectVersion: (r) => {
                                    this.selected = r;
                                }
                            }
                        }),
                        h('v-form', [
                            h('v-text-field', {
                                props: {label: 'Номер версии расписания', value: this.version, disabled: true}}),
                            h('v-menu', {
                                props: {"max-width": "290px", "min-width": "290px", value: this.dateMenu},
                                scopedSlots: {
                                    activator: ({on, attrs}) => {
                                        on["blur"] = (e) => {
                                            console.log("blur:", e);
                                        };
                                        return h('v-text-field', {
                                            props: {
                                                label: "Дата начала действия версии расписания",
                                                "append-icon": "mdi-calendar",
                                                value: $utils.formatDate(this.schDt,'DD.MM.yyyy'),
                                                "full-width": false
                                            },
                                            style: {"max-width": "12rem"},
                                            on: on
                                        });
                                    }
                                }
                            }, [
                                h('v-date-picker', {props: {
                                        value: $utils.formatDate(this.schDt,'YYYY-MM-DD'),
                                        "no-title": true, locale: "ru-RU",
                                        "weekday-format": this.dayOfWeekToFormat,
                                        "first-day-of-week": "1",
                                        scrollable: true
                                    }, on: {
                                        input: (schDt) => {
                                            if (!schDt) {
                                                return;
                                            }
                                            const [y, m, d] = schDt.split('-');
                                            this.schDt = new Date(y, m - 1, d);
                                            this.dateMenu = false;
                                        }
                                    }})
                            ]),
                        ])
                    ]),
                    h('v-card-actions', [
                        h('v-spacer'),
                        h('v-btn', {
                            props: {outlined: true, tile: true, color: 'gray', small: true},
                            on: {click: () => {
                                    this.$emit('close');
                                }}
                        }, 'закрыть'),
                        (!!this.route)
                                ? h('v-btn', {
                                    props: {outlined: true, tile: true, color: 'primary', small: true},
                                    on: {click: this.saveNewVersion}
                                }, 'сохранить')
                                : null,
                    ])
                ])
            ]);
        }
    };

</script>