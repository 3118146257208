<template>
  <v-autocomplete
    label="Версия маршрута"
    v-model="versions.selected"
    item-value="vcroutesId"
    item-text="shortName"
    return-object
    :items="versions.list"
    :loading="versions.loading"
    @change="onSelectVersion($event)"
  >
    <template #item="{item: version}">
      <div id="routeVersions">
        <div>
          {{ version.vcroutesRoutecode }}
        </div>
        <div>
          {{ version.vcroutesRoutename }}
        </div>
        <div>
          {{ version.vcroutesVersion }}
        </div>
        <div>
          {{ version.verstatusName }}
        </div>
      </div>
    </template>
  </v-autocomplete>
</template>

<script>
    
import RouteService from '@/services/RouteService';

export default {
  name: "VersionSelection",
  props: {
    filter: {
      type: String,
      required: true,
    },
    selected: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    versions: {
      loading: false,
      list: [],
    },
  }),
  created() {
    this.loadRouteVersions();
  },
  watch: {
    filter: function (val) {
      if (!!val){
          this.loadRouteVersions();
      }
    }
  },
  methods: {
    onSelectVersion(item) {
      this.$emit('selectVersion', item);
    },
    loadRouteVersions() {
      this.versions.loading = true;
      
      RouteService.getRouteVersionsFiltered(this.filter)
        .then(result => {
          this.$emit('versionsCount', result.length);
          this.versions.list = [
            {
              vcroutesRoutecode: 'Номер',
              vcroutesRoutename: 'Название',
              vcroutesVersion: 'Версия',
              verstatusName: 'Статус',
              shortName: '',
              disabled: true,
            }
          ];
          result.map(it => {
            it.shortName = it.vcroutesRoutecode + '   ' + it.vcroutesRoutename + '   ' + it.vcroutesVersion + '   ' + it.verstatusName;
            it.disabled = false;
            this.versions.list.push({...it});
          });
          
          this.versions.selected = this.versions.list.find(it => it.vcroutesId === this.selected.vcroutesId) || this.versions.list[this.versions.list.length - 1];
          this.$emit('selectVersion', this.versions.selected);
        })
        .catch(err => {
          _err('ShowRoute::loadRouteVersions', err);
        })
        .finally(() => {
          this.versions.loading = false;
        });
    },
  }
}
</script>



<style lang="sass">
#routeVersions
  display: grid
  grid-template-columns: 0.5fr 1.4fr 0.5fr 1fr
  width: 100%
  max-width: 600px

#routeVersions > div
  padding: 5px
  max-height: 50px
  overflow: hidden
  text-overflow: ellipsis
  white-space: nowrap
</style>