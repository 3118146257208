<template>
  <div>
    <!-- Заголовок -->
    <v-row class="align-center">
      <v-col>
        <v-row class="align-center">
          <!-- Всего записей -->
          <v-col style="white-space: nowrap">
            Всего записей: {{ data.length }}

            <v-tooltip
              right
              color="primary"
              v-if="hasData"
            >
              <template v-slot:activator="{ on }">
                <v-btn icon class="ml-2" v-on="on" @click="showAllData">
                  <v-icon>mdi-refresh</v-icon>
                </v-btn>
              </template>

              <span>Обновить список</span>
            </v-tooltip>
          </v-col>

          <!-- Активные -->
          <v-col>
            <v-switch
              label="Активные"
              v-model="active"
              :disabled="!hasData || loadingData"
            ></v-switch>
          </v-col>
        </v-row>
      </v-col>

      <!-- Поиск -->
      <v-col cols="6">
        <v-layout align-center>
          <v-text-field
            hide-details
            class="mr-4"
            label="Поиск"
            v-model="searchText"
            :disabled="loadingData"
            :placeholder="placeholder"
          ></v-text-field>
        </v-layout>
      </v-col>
    </v-row>

    <div :style="{height: innerHeight + 'px'}">
      <template v-if="!loadingData">
        <template v-if="hasData && filteredList.length">
          <v-data-table
            show-select fixed-header dense
            height="260"
            v-model="selected"
            :headers="headers"
            :items="filteredList"
          ></v-data-table>
        </template>

        <template v-else-if="hasData && filteredList.length === 0">
          <v-sheet
            class="fill-height py-8 text-center"
            style="position: relative; border: 1px dashed #cecece;"
          >
            Ничего не найдено
          </v-sheet>
        </template>

        <template v-else>
          <v-sheet
            class="fill-height py-8"
            style="position:relative; border: 1px dashed #cecece;"
          >
            <v-btn
              outlined
              absolute
              color="grey"
              @click="showAllData"
              style="left: 50%; top: 50%; transform: translate(-50%, -50%);"
            >
              Показать все записи
            </v-btn>
          </v-sheet>
        </template>
      </template>

      <!-- Прогресс -->
      <div v-else class="pa-4 text-center">
        <v-progress-circular
          indeterminate
          color="primary"
        ></v-progress-circular>
      </div>
    </div>
  </div>
</template>

<script>
import RouteService from '@/services/RouteService';

export default {
  name: 'SelectStops',
  props: {
    // Плейсхолдер
    placeholder: {
      type: String,
      required: false,
      default: 'Введите название',
    },
    // свойство, передаваемое через v-model
    value: {
      type: Array,
      required: true,
    },
    // Необходимо отменить действие
    needCancel: {
      type: Boolean,
      required: false,
      default: false,
    },
    // Необходимо очистить выбранные элементы
    needClear: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  watch: {
    searchText(val) {
      if (val) {
        // Если данные еще не загружены с сервера - получаем их
        if (!this.hasData && !this.loadingData) {
          this.showAllData();
        }
      }
    },
    selected(val) {
      this.$emit('input', _copy(val));
    },
    needCancel() {
      this.selected = _copy(this.value);
    },
    needClear() {
      this.selected = [];
    },
  },
  data: () => ({
    // Вычисляемая высота
    innerHeight: 300,
    // Признак активности
    active: false,
    // Флаг, что данные загружены но ничего нет
    hasData: false,
    // Флаг, что происходит загрузка
    loadingData: false,
    // Загруженные данные
    data: [],
    // Строка поиска
    searchText: '',
    // Выбранные значения
    selected: [],
    // Описание заголовкой таблицы
    headers: [
      {text: 'Наименование', value: 'locname'},
      {text: 'Код', value: 'code'},
      {text: 'Тип остановки', value: 'objecttypeidName'},
      {text: 'Населенный пункт', value: 'twnidTownname'},
    ],
  }),
  computed: {
    // Список, фильтированный по статусу активности и введенной строке поиска
    filteredList() {
      return this.data.filter(item => {
        // Проверка на активность
        if (this.active) {
          if (this.itemIsChecked(item) === -1) {
            return false;
          }
        }

        // Проверка на введенную строку
        if (this.searchText) {
          return this.checkByStr(item);
        }

        // На всякий случай - установка текущего статуса активности
        // item.checked = this.itemIsChecked(item) !== -1;

        return true;
      });
    },
  },
  methods: {
    // Проверка элемента на соответствие введенной строке
    checkByStr(item) {
      const strSearch = new RegExp(this.searchText, 'i');

      return strSearch.test(item.locname);
    },
    // Проверка зачекан элемент или нет
    itemIsChecked(item) {
      return this.value.findIndex(it => it.id === item.id);
    },
    // Получение данных с сервера
    showAllData() {
      this.loadingData = true;
      this.data = [];
      this.hasData = false;

      RouteService.getLocations(true)
        .then(data => {
          this.data = _copy(data).map(it => {
            it.coordinates = [
              it.longitude,
              it.latitude,
            ];

            it.location = it.id;

            return it;
          });

          this.hasData = true;
        })
        .catch(err => console.error('RouteService::getLocations', err))
        .finally(() => {
          this.loadingData = false;
        });
    },
  },
};
</script>

<style scoped>

</style>
