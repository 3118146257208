<template>
 <div>
   <div v-if="!loading" max-width="600" class="pa-4">

     <!-- Выбор времени -->
     <v-dialog
       ref="dialog"
       v-model="showTimePicker"
       :return-value.sync="time"
       persistent
       width="290px"
     >
       <template v-slot:activator="{ on, attrs }">
         <v-text-field
           v-model="time"
           label="Время формирования отчета"
           prepend-icon="mdi-clock-outline"
           color="#003c87"
           readonly
           v-bind="attrs"
           v-on="on"
         ></v-text-field>
       </template>
       <v-time-picker
         v-if="showTimePicker"
         v-model="time"
         full-width
         format="24hr"
         color="#003c87"
       >
         <v-spacer></v-spacer>
         <v-btn
           text
           color="#003c87"
           @click="showTimePicker = false"
         >
           Отмена
         </v-btn>
         <v-btn
           text
           color="#003c87"
           @click="$refs.dialog.save(time)"
         >
           ОК
         </v-btn>
       </v-time-picker>
     </v-dialog>

     <!-- Описание времени -->
     <div class="grey--text">
       Укажите время, в которое предпочтительнее будет получать отчёт. Время формирования отчёта может составить
       до 2х часов. Время с 20:00 до 23:59 - отчёт за текущие сутки; с 00:00 до 19:59 - отчёт за прошедшие сутки
     </div>

     <!-- Поле email  -->
     <v-text-field
       v-model.trim="email"
       label="Email"
       :rules="emailValidator"
       color="#003c87"
       prepend-icon="mdi-email-outline"
     ></v-text-field>

     <!-- Описание email -->
     <div class="grey--text">
       Только на указанную почту будет приходить отчёт.
     </div>
     <v-btn
       block
       color="#003c87"
       class="white--text mt-4"
       :disabled="disableBtnSave"
       @click="save"
     >
       Сохранить
     </v-btn>
   </div>
   <JetSpinner v-else class="mt-4"/>
 </div>
</template>

<script>
import HideGlobalToolbarMixin from '@/components/dev/mixin/HideGlobalToolbarMixin';
import ReportSettingService from '@/components/dev/modules/auto_building_10_44/ReportSettingService';
import {FORMAT_FOR_USER, formatDate} from '@/services/JetDate';
import JetSpinner from '@/components/JetSpinner';

export default {
  name: 'Settings',
  components: {JetSpinner},
  mixins: [
    HideGlobalToolbarMixin,
  ],
  props: {
    carrier: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    // Отображение компонента для выбора времени
    showTimePicker: false,
    // Выбранное время. Поумолчанию 05:00
    time: '05:00',
    email: '',
    /* Email поумолчанию, который берется из телефона (если есть)
      или из организации поле email */
    defaultEmail: '',
    // Валидация введеного email
    emailValidator: [val => new RegExp(/.+@.+\..+/).test(val) || 'Некорректный email'],
    loading: true,
    report: null,
  }),
  computed: {
    disableBtnSave() {
      return !this.testEmail();
    },
  },
  created() {
    this.defaultEmail = this.carrier.email;
    this.init();
  },
  methods: {
    async init() {
      this.loading = true;
      const report = await ReportSettingService.getSettingsAutoReport(this.carrier.carrierId);
      if (report) {
        this.email = report.email;
        this.time = formatDate(report.dailytime, FORMAT_FOR_USER.ONLY_TIME, true) || '5:00';
        this.report = report;
      } else {
        this.email = this.defaultEmail;
      }
      this.loading = false;
    },
    async save() {
      const email = this.email || this.defaultEmail;
      const reportId = this.report && this.report.id;
      await ReportSettingService.setSettingsAutoReport(this.time, email, this.carrier.carrierId, reportId);
      this.close();
    },
    close() {
      this.$emit('close');
    },
    testEmail(email) {
      if (!email) {
        email = this.email;
      }
      return new RegExp(/.+@.+\..+/).test(email);
    },
  },
};
</script>
