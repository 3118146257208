/** Класс Project */
class Project {
  /**
   * Создаём экземпляр Project
   * @param {Object} project
   */
  constructor(project) {
    this._project = project;
  }

  /**
   * Получаем тип проекта
   * @return {String}
   */
  get type() {
    return this._project.projectType;
  }

  get isHtml() {
      return ('typeViewHtml' === this._project.projectType)
             || ('typeHtmlView' === this._project.projectType);
  }
  
  /** Actions for user-call */
  get isAction(){
      return ( 
                ('typeJsClientHtml' === this._project.projectType) 
                || ('guiAction' === this._project.projectType) 
             )
            && this._project.name.toLowerCase().indexOf(".vue") < 0;
  }
  
  /* Auto-load script in view */
  get isCtrl(){
      return 'typeJsClientHtml' === this._project.projectType 
            && this._project.name.toLowerCase().indexOf(".vue") > -1;
  }

  /**
   * Получаем ресурс (источник данных) проекта
   */
  get resource() {
    const {data: resource} = this._project.lobRef.target;
    const {attrs} = resource;
    const newAttrs = {};

    for (const prop in attrs) {
      if (attrs.hasOwnProperty(prop)) {
        const matches = prop.match(/Key{name=(\w+),\s?type=java.(lang|util).(\w+)}/);

        /**
         * @todo
         * Нужно обработать событие, когда regexp не нашёл нужного совпаления
         */
        const newProp = matches[1];
        const value = attrs[prop];

        newAttrs[newProp] = value;
      }
    }

    const empty = Object.create(null);

    return Object.assign(empty, resource, {attrs: newAttrs});
  }

  /**
   * Получаем имя проекта
   */
  get name() {
    return this._project.title;
  }
  
  /**
   * Вызов для компонентов-расширений
   * @returns {Project._project.call}
   */
  get call(){
      return this._project.call;
  }
  
}

export default Project;
