<template>
  <div>
    <v-row class="align-center">
      <v-col>
        <v-row class="align-center">
          <v-col>
            Всего записей: {{ data.length }}

            <v-tooltip
              right
              color="primary"
              v-if="hasData"
            >
              <template #activator="{ on, attrs }">
                <v-btn
                  icon
                  class="ml-2"
                  v-on="on"
                  v-bind="attrs"
                  @click="showAllData"
                >
                  <v-icon>
                    mdi-refresh
                  </v-icon>
                </v-btn>
              </template>

              <span>Обновить список</span>
            </v-tooltip>
          </v-col>

          <v-col>
            <v-switch
              label="Активные"
              v-model="active"
              :disabled="!hasData"
            ></v-switch>
          </v-col>
        </v-row>

      </v-col>

      <v-col cols="6">
        <v-layout align-center>
          <v-text-field
            hide-details
            class="mr-4"
            label="Поиск"
            v-model="searchText"
            :placeholder="placeholder"
          ></v-text-field>
        </v-layout>
      </v-col>
    </v-row>

    <div :style="{height: innerHeight + 'px'}">
      <template v-if="!loadingData">
        <template v-if="hasData && filteredList.length">
          <v-data-table
            dense show-select fixed-header
            height="260"
            v-model="selected"
            :single-select="false"
            :headers="headers"
            :items="filteredList"
          ></v-data-table>
        </template>

        <template v-else-if="hasData && filteredList.length === 0">
          <v-sheet
            class="fill-height py-8 text-center"
            style="position: relative; border: 1px dashed #cecece"
          >
            Ничего не найдено
          </v-sheet>
        </template>

        <template v-else>
          <v-sheet
            class="fill-height py-8"
            style="position:relative; border: 1px dashed #cecece"
          >
            <v-btn
              outlined absolute
              color="grey"
              style="left: 50%; top: 50%; transform: translate(-50%, -50%)"
              @click="showAllData"
            >
              Показать все записи
            </v-btn>
          </v-sheet>
        </template>
      </template>

      <div v-else class="pa-4 text-center">
        <v-progress-circular
          indeterminate
          color="primary"
        ></v-progress-circular>
      </div>
    </div>
  </div>
</template>

<script>
import MapLayersService from '@/services/MapLayersService';

export default {
  props: {
    // Свойство, передаваемое через v-model
    value: {
      type: Array,
      required: true,
    },
    needCancel: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  watch: {
    searchText(val) {
      if (val) {
        // Если данные еще не загружены с сервера - получаем их
        if (!this.hasData && !this.loadingData) {
          this.showAllData();
        }
      }
    },
    selected(val) {
      this.$emit('input', _copy(val));
    },
    needCancel() {
      this.selected = _copy(this.value);
    },
  },
  name: 'RoutesMapLayer',
  data: () => ({
    innerHeight: 300,
    active: false,
    hasData: false,
    loadingData: false,
    data: [],
    searchText: '',
    selected: [],
    placeholder: 'Введите номер или название маршрута',
    headers: [
      {
        text: '№ маршрута',
        value: 'routeCode',
      },
      {
        text: 'Наименованиее',
        value: 'routeName',
      },
      {
        text: 'Тип маршрута',
        value: 'routeTypeName',
      },
      {
        text: 'Подвижной состав',
        value: 'alterDesc',
      },
      {
        text: 'Версия',
        value: 'version',
      },
      {
        text: 'Перевозчик',
        value: 'currentCarriers',
      },
    ],
  }),
  methods: {
    // Проверка элемента на соответствие введенной строке
    checkByStr(item) {
      const strSearch = new RegExp(this.searchText, 'i');

      return strSearch.test(item.routeCode) || strSearch.test(item.routeName);
    },
    // Проверка зачекан элемент или нет
    itemIsChecked(item) {
      return this.value.findIndex(it => it.id === item.id);
    },
    // Получение данных с сервера
    async showAllData() {
      this.loadingData = true;

      const data = await MapLayersService.getRoutes();

      this.data = _copy(data);
      this.hasData = true;

      this.loadingData = false;
    },
  },
  computed: {
    // Список, фильтированный по статусу активности и введенной строке поиска
    filteredList() {
      return this.data.filter(item => {
        // Проверка на активность
        if (this.active) {
          if (this.itemIsChecked(item) === -1) {
            return false;
          }
        }

        // Проверка на введенную строку
        if (this.searchText) {
          return this.checkByStr(item);
        }

        return true;
      });
    }
  },
};
</script>

<style scoped>

</style>
