import VehicleService from '@/services/VehicleService';

/**
 * Класс со вспомогательными функциями
 */
export default class CarrierUtils {
  // События
  static events = {
    // Скрытие всех машинок
    hideAllTracks: 'hardHideTracks',
    // Скрытие ранее отслеживаемой машинки
    hideAllTracking: 'hideTrackingCars',
    // Произошла фильтрация
    filtered: 'onVehiclesFiltered',
    // Данные для журнала переданы
    journalData: 'onJournalData',
    // Данные для группировки загружены
    groupDataChanged: 'group_data_changed',
    // Скролл в журнале
    journalScroll: 'onJournalScroll',
  };

  /**
   * Связка типа группировки и метода для получения данных
   *
   * @private
   */
  static _groupTypeMethods = {
    'GRP_BY_CARRIERS': CarrierUtils._byCarrier,
    'GRP_BY_CARRIERS_ROUTES': CarrierUtils._byCarrierRoutes,
    'GRP_BY_ROUTES_CARRIERS': CarrierUtils._byRouteCarrier,
    'GRP_BY_ROUTES_DEPS': '',
    'GRP_BY_ARS_CARRIERS': '',
    'GRP_BY_GRPS_CARRIERS': CarrierUtils._byGroupCarrier,
  };

  /**
   * Типы последних событий
   *
   * @returns {{
   *    parking: String,
   *    stop: String,
   *    blocked: String,
   *    alarm: String,
   *    speedUp: String,
   *    noData: String,
   *    moving: String,
   * }}
   */
  static lastEvents() {
    return {
      // Движется
      moving: 'moving',
      // Остановка
      stop: 'stop',
      // Парковка
      parking: 'parking',
      // Заблокирован
      blocked: 'blocked',//Нет такого флага в бэке
      // Тревожная кнопка
      alarm: 'alert',
      // Превышение скорости
      speedUp: 'overspeeding',
      // Нет данных
      noData: 'no_data',//Нет такого флага в бэке

      // Есть в бэке:
      // Не привязан GPS
      noGps: 'no_gps_tracker',
      // Начало движения
      starting: 'starting',
      // Окончание движения
      ending: 'ending',
      // Установка связи с датчиком
      online: 'online',
      // Потеря связи с датчиком от 2 до 24 часов
      offline: 'offline',
      // Потеря связи с датчиком от 24 до 72 часов
      offlineUp72: 'offline_from_24_to_72',
      // Потеря связи с датчиком свыше 72 часов
      offlineOver72: 'offline_overtop',

      // Эвакуаторы
      // Свободен
      free: 'free',
      // Занят
      busy: 'busy',
      // Заблокирован
      locked: 'locked',
    };
  }

  /**
   * Получение данных для формирования группироки
   *
   * @param {String} groupType Тип группировки
   * @param {Array<Object>} vehicles Массив ТС
   * @returns {Promise<Object>}
   */
  static async onGroupData(groupType, vehicles) {
    return await this._groupTypeMethods[groupType](vehicles);
  }

  // --------------------- Группировки ---------------------

  /**
   * По перевозчикам
   *
   * @param {Array<Object>} vehicles Массив ТС
   * @returns {Promise<Object>}
   * @private
   */
  static async _byCarrier(vehicles) {
    const orgGroup = {};

    const orgNames = vehicles
      .map(it => it.orgname)
      .filter((it, index, self) => self.indexOf(it) === index);

    (orgNames || []).forEach(orgName => {
      orgGroup[orgName] = vehicles.filter(it => it.orgname === orgName);
    });
    const res = {
      level: 2,
      data: orgGroup,
    }

    return res;
  }

  /**
   * По перевозчикам-маршрутам
   *
   * @param {Array<Object>} vehicles Массив ТС
   * @returns {Promise<Object>}
   * @private
   */
  static async _byCarrierRoutes(vehicles) {
    const result = {};

    const orgNames = vehicles
      .map(it => it.orgname)
      .filter((it, index, self) => self.indexOf(it) === index);

    (orgNames || []).forEach(orgName => {
      const routes = {};
      
      const routeNames = vehicles
        .filter(it => it.orgname === orgName)
        .map(it => it.fullroutename)
        .filter(it => it != null)
        .filter((it, index, self) => self.indexOf(it) === index);
      
      var customSort = function (a, b) {
        return (Number(a.match(/(\d+)/g)[0]) - Number((b.match(/(\d+)/g)[0])));
      }
      routeNames.sort(customSort);
      
      (routeNames || []).forEach(routeName => {
        const tripGroup = {};
        
        const tripNames = vehicles
          .filter(it => it.fullroutename === routeName && it.orgname === orgName)
          .map(it => it.fulltripcode);
  
        (tripNames || []).forEach(tripName => {
          tripGroup[tripName] = vehicles
            .filter(it => it.fullroutename === routeName && it.orgname === orgName && it.fulltripcode === tripName);
        });
        
        routes[routeName] = tripGroup;
      });
      
      if (!_emptyObject(routes)) {
        result[orgName] = routes;
      }
    });
    
    const res = {
      level: 4,
      data: result,
    }
    
    return res;
  }

  /**
   * По маршрутам-перевозчикам
   *
   * @param {Array<Object>} vehicles Массив ТС
   * @returns {Promise<Object>}
   * @private
   */
  static async _byRouteCarrier(vehicles) {
    const result = {};

    const routeNames = vehicles
      .map(it => it.fullroutename)
      .filter(it => it != null)
      .filter((it, index, self) => self.indexOf(it) === index);
    
    var customSort = function (a, b) {
      return (Number(a.match(/(\d+)/g)[0]) - Number((b.match(/(\d+)/g)[0])));
    }
    routeNames.sort(customSort);

    (routeNames || []).forEach(routeName => {
      const orgGroup = {};

      const orgNames = vehicles
        .filter(it => it.fullroutename === routeName)
        .map(it => it.orgname)
        .filter((it, index, self) => self.indexOf(it) === index);

      (orgNames || []).forEach(orgName => {
        const tripGroup = {};
        
        const tripNames = vehicles
          .filter(it => it.fullroutename === routeName && it.orgname === orgName)
          .map(it => it.fulltripcode);
  
        (tripNames || []).forEach(tripName => {
          tripGroup[tripName] = vehicles
            .filter(it => it.fullroutename === routeName && it.orgname === orgName && it.fulltripcode === tripName);
        });

        orgGroup[orgName] = tripGroup;
      });

      result[routeName] = orgGroup;
    });
    
    const res = {
      level: 4,
      data: result,
    }

    return res;
  }

  /**
   * По группам-перевозчикам
   *
   * @param {Array<Object>} vehicles Массив ТС
   * @returns {Promise<Object>}
   * @private
   */
  static async _byGroupCarrier(vehicles) {
    const result = {};

    let carriers = {};

    try {
      carriers = await VehicleService.getCarriers();
    } catch (e) {
      console.error('CarrierUtils::_byGroupCarrier', e);

      return {};
    }

    carriers.forEach(it => {
      const orgId = it.orgId;
      let parentCarrier = it.parentCarrier;

      const carrierHash = CarrierUtils._getCarrierVehicleHash(
        vehicles
          .filter(iit => iit.oid === orgId)
          .filter(iit => iit !== null),
      );

      if (parentCarrier === null) {
        parentCarrier = Object.keys(carrierHash)[0];
      }

      if (parentCarrier !== undefined && parentCarrier !== null) {
        result[parentCarrier] = carrierHash;
      }
    });

    return _copy(result);
  }

  /**
   * Формирование хеша
   *
   * Ключ - имя перевозчика
   * Значение - массив его ТС
   *
   * @param {Array<Object>} vehicles Массив ТС
   * @returns {Object}
   * @private
   */
  static _getCarrierVehicleHash(vehicles) {
    const result = {};

    vehicles.forEach(it => {
      const orgName = it.oname;

      if (!_hasOwnProperty(result, orgName)) {
        result[orgName] = [];
      }

      result[orgName].push(it);
    });

    return result;
  }
}