// Vue зависимости
import Vue from 'vue';
import Vuex from 'vuex';

// Модули
import app from './modules/app';
import auth from './modules/auth';
import profile from './modules/profile';
import period from './modules/period';
import branding from './modules/branding';
import colls from './modules/colls';
import cache from './modules/cache';
import ptmap_date_track from './modules/ptmap_date_track';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    app,
    auth,
    profile,
    period,
    branding,
    colls,
    cache,
    ptmap_date_track,
  },
});
