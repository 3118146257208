<template>
  <div class="pa-4 fill-height" style="overflow: auto;">
    <!-- Заголовок -->
    <h3 class="mb-4">
      {{ isEdit ? 'Редактирование' : 'Создание' }} остановки
    </h3>

    <!-- Форма -->
    <v-form ref="cbForm" v-model="form">

      <!-- Дата начала -->
      <v-menu
        offset-y
        ref="date_from"
        max-width="290px"
        min-width="290px"
        transition="scale-transition"
        v-model="dateFromDialog"
        :close-on-content-click="false"
      >
        <template v-slot:activator="{ on }">
          <v-text-field
            readonly clearable persistent-hint
            label="Дата начала учета"
            placeholder="Выберите дату"
            v-on="on"
            v-model="startdtFormat"
            :rules="[
              v => !!v || 'Необходимо выбрать дату начала',
            ]"
          ></v-text-field>
        </template>

        <v-date-picker
          no-title
          v-model="startdt"
          @input="dateFromDialog = false"
          :first-day-of-week="1"
          :weekday-format="dayOfWeekToFormat"
        ></v-date-picker>
      </v-menu>

      <!-- Дата окончания -->
      <v-menu
        offset-y
        ref="date_from"
        max-width="290px"
        min-width="290px"
        transition="scale-transition"
        v-model="dateToDialog"
        :close-on-content-click="false"
      >
        <template v-slot:activator="{ on }">
          <v-text-field
            readonly clearable persistent-hint
            label="Окончание учета"
            placeholder="Выберите дату"
            v-on="on"
            v-model="enddtFormat"
          ></v-text-field>
        </template>

        <v-date-picker
          no-title
          v-model="enddt"
          @input="dateToDialog = false"
          :first-day-of-week="1"
          :weekday-format="dayOfWeekToFormat"
        ></v-date-picker>
      </v-menu>

      <!-- Код остановки -->
      <v-text-field
        label="Код остановки"
        v-model="value.code"
        :rules="[
          v => !!v || 'Код остановки не может быть пустым',
        ]"
      ></v-text-field>

      <!-- Наименование остановки -->
      <v-text-field
        label="Наименование остановки"
        v-model="value.locname"
        :rules="[
          v => !!v || 'Наименование остановки не может быть пустым',
        ]"
      ></v-text-field>

      <!-- Тип остановки -->
      <v-select
        label="Тип остановки"
        item-text="name"
        item-value="id"
        v-model="value.objecttypeid"
        :items="stopTypes"
        :loading="preloads.stopTypes"
      ></v-select>

      <!-- Широта -->
      <v-text-field
        readonly disabled
        label="Широта"
        v-model="value.latitude"
        :rules="[
          v => !!v || 'Необходимо выбрать точку на карте',
        ]"
      ></v-text-field>

      <!-- Долгота -->
      <v-text-field
        readonly disabled
        label="Долгота"
        v-model="value.longitude"
        :rules="[
          v => !!v || 'Необходимо выбрать точку на карте',
        ]"
      ></v-text-field>

      <!-- Часовой пояс -->
      <v-select
        label="Часовой пояс"
        item-text="name"
        item-value="id"
        v-model="value.timezoneid"
        :items="timeZones"
        :loading="preloads.timeZones"
        :rules="[
          v => !!v || 'Необходимо выбрать часовой пояс',
        ]"
      ></v-select>

      <!-- Город/НП -->
      <v-autocomplete
        label="Город/НП"
        placeholder="Начните вводить"
        item-value="id"
        v-model="value.twnid"
        :items="cities"
        :item-text="city => `${city.fullName}`"
        :loading="preloads.cities"
        :disabled="preloads.cities"
        :rules="[
          v => !!v || 'Необходимо выбрать город',
        ]"
      ></v-autocomplete>

      <!-- Описание -->
      <v-textarea
        dense hide-details
        label="Описание"
        rows="3"
        v-model="value.description"
      ></v-textarea>

    </v-form>

    <!-- Кнопки -->
    <v-row class="pt-4">
      <v-col>
        <v-btn
          outlined
          class="mb-4"
          color="red"
          :disabled="isNewStopLoading"
          @click="$emit('on-cancel')"
        >
          Отменить
        </v-btn>
      </v-col>

      <v-spacer/>

      <v-col>
        <v-btn
          color="primary"
          :loading="isNewStopLoading"
          :disabled="preloads.timeZones || preloads.cities || preloads.stopTypes || isNewStopLoading"
          @click="onSubmit"
        >
          Сохранить
        </v-btn>
      </v-col>
    </v-row>

    <!-- Диалог предупреждающий что есть поблизости остановки -->
    <JetDialog
      title="Предупреждение"
      apply-button-text="Показать"
      cancel-button-text="Отмена"
      v-model="showAdditionalStopsDialog"
      @on-apply-click="onAdditionalApply"
      @on-cancel-click="closeAdditionalDialog"
    >
      <template v-slot:default>
        В радиусе 200 м. от указанной точки находится
        <b>
          {{ nearStops.length }}
          {{ _pluralize(nearStops.length, ['остановка', 'остановки', 'остановок']) }}
        </b>
      </template>
    </JetDialog>

    <!-- Диалог подтверждения выбора остановки -->
    <JetDialog
      title="Добавить остановочный пункт"
      width="600"
      apply-button-text="Добавить"
      cancel-button-text="Отмена"
      v-model="showAddNearStop"
      @on-apply-click="onNearAdd"
      @on-cancel-click="onNearCancel"
    >
      <template v-slot:default>
        Вы хотите добавить остановочный пункт
        <b>{{ selectedNearStop && selectedNearStop.locname || '' }}</b>
        вместо создания новой?
      </template>
    </JetDialog>

    <!-- Диалог оповещение о редактировании -->
    <JetDialog
      title="Внимание"
      v-if="isEdit"
      v-model="alertDialog"
    >
      <template v-slot:default>
        При изменении данной остановки
        изменения затронут и остальные связанные с ней маршруты
      </template>

      <template v-slot:actions>
        <v-spacer/>

        <v-btn outlined color="primary" @click="alertDialog = false">
          OK
        </v-btn>
      </template>
    </JetDialog>
  </div>
</template>

<script>
// Сторонние зависимости
import MapBoxGL from 'mapbox-gl';

// Компоненты
import JetDialog from '@/components/JetDialog';

// Сервисы
import CreateBusStopService from '@/components/services/CreateBusStopService';
import RouteService from '@/services/RouteService';

// Утилиты
import Map from '@/utils/map';
import {deg2rad} from '@/utils/utils';
import {dayOfWeekToFormat} from "@/services/JetDate";

// Имя источника и слоя для клика по карте
const slId = 'new_bus_stop';

const nearId = 'near_stops';
const nearTextId = 'near_stops_text';

export default {
  components: {
    JetDialog,
  },
  props: {
    item: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },
  name: 'CreateBusStop',
  data: () => ({
    // Флаг что происходит редактирование остановки
    isEdit: false,
    // Показ диалога-оповещения
    alertDialog: true,
    // Флаг для диалога показа доп точек
    showAdditionalStopsDialog: false,
    // Флаг для диалога выбора близжайшей остановки
    showAddNearStop: false,
    // Валидна ли форма
    form: false,
    // Диалог выбора даты начала
    dateFromDialog: false,
    // Диалог выбора даты окончания
    dateToDialog: false,
    // Блок с флагами "загрузок"
    preloads: {
      // Загружается список городов
      cities: false,
      // Загружается список типов остановок
      stopTypes: false,
      // Загружается срисок временных зон
      timeZones: false,
      // Загружается список локаций
      locations: false,
    },
    // Список городов
    cities: [],
    // Список типов остановок
    stopTypes: [],
    // Список временных зон
    timeZones: [],
    // Список локаций
    locations: [],
    // Близжайшие остановки от клика
    nearStops: [],
    // Выбранная ближайшая остановка
    selectedNearStop: null,
    // Происходит создание новой остановки
    isNewStopLoading: false,
    // Информация о новой остановке
    value: {
      id: null,
      startdt: null,
      enddt: null,
      code: null,
      locname: null,
      objecttypeid: null,
      description: null,
      timezoneid: null,
      twnid: null,
      latitude: null,
      longitude: null,
    },
    dayOfWeekToFormat: () => '',
  }),
  created() {
    this.dayOfWeekToFormat = dayOfWeekToFormat;
    this.preloads.cities = true;
    this.preloads.stopTypes = true;
    this.preloads.timeZones = true;
    this.preloads.locations = true;

    this._initMap();

    const _catchError = (msg, err) => {
      console.error(msg, err);

      jet.msg({
        text: msg,
        color: 'warning',
      });
    };

    CreateBusStopService.getCities()
      .then(cities => {
        this.cities = cities.map(it => ({
          id: it.id,
          fullName: it.areaName ? it.name + ', ' + it.areaName : it.name,
          name: it.name,
          areaName: it.areaName
        }));
      })
      .catch(err => _catchError('Список городов не был получен', err))
      .finally(() => this.preloads.cities = false);

    CreateBusStopService.getStopTypes()
      .then(types => {
        this.stopTypes = types;

        if (this.value.objecttypeid === null) {
          // По-умолчанию - тарифная остановка (да, не очень красиво)
          this.value.objecttypeid = '65dc8094-c69f-46e6-ac67-d0da33c10879';
        }
      })
      .catch(err => _catchError('Типы остановки не были загружены', err))
      .finally(() => this.preloads.stopTypes = false);

    CreateBusStopService.getTimeZones()
      .then(timeZones => this.timeZones = timeZones)
      .catch(err => _catchError('Временные зоны не были получены', err))
      .finally(() => this.preloads.timeZones = false);

    RouteService.getLocations()
      .then(data => this.locations = data)
      .catch(err => _catchError('Локации не были загружены', err))
      .finally(() => this.preloads.locations = false);
      
    if (this.item === null) {
      this.value.startdt = $utils.formatDate(new Date(),'YYYY-MM-DD');
      
      CreateBusStopService.getNextLocationCode()
        .then(data => this.value.code = data)
        .catch(err => _catchError('Код остановки не вычислен', err));  
    }
  },
  mounted() {
    Map.getDraw().changeMode('static');

    Map.getMap().on('click', this.onMapClick);

    if (this.item !== null && Object.keys(this.item).length) {
      this.isEdit = true;

      const _item = _copy(this.item);

      RouteService.getLocation(_item.location, true)
        .then(data => {
          this.value = data?.[0] || {};

          Map.getMap().fitBounds(new MapBoxGL.LngLatBounds(
            [this.value.longitude, this.value.latitude],
            [this.value.longitude, this.value.latitude],
          ), {
            padding: 20,
            maxZoom: 15,
          });
        });
    }
  },
  destroyed() {
    const map = Map.getMap();

    Map.getDraw().changeMode('simple_select');

    map.off('click', this.onMapClick);
    map.off('click', nearId, this._onNearStopsClick);

    map.removeLayer(nearId);
    map.removeLayer(nearTextId);
    map.removeSource(nearId);
    map.removeLayer(slId);
    map.removeSource(slId);

    Object.keys(this.value).forEach(key => {
      this.value[key] = null;
    });

    this.dateFromDialog = false;
    this.dateToDialog = false;
    this.showAdditionalStopsDialog = false;
    this.showAddNearStop = false;

    this.selectedNearStop = false;
    this.nearStops = [];
  },
  computed: {
    startdtFormat: {
      get() {
        return this.value.startdt ? $utils.formatDate(new Date(this.value.startdt),'DD.MM.yyyy') : null;
      },
      set(value) {
        this.value.startdt = value;
      },
    },
    startdt: {
      get() {
        return this.value.startdt ? $utils.formatDate(new Date(this.value.startdt),'YYYY-MM-DD') : $utils.formatDate(new Date(),'YYYY-MM-DD');
      },
      set(value) {
        this.value.startdt = value;
      },
    },
    enddtFormat: {
      get() {
        return this.value.enddt ? $utils.formatDate(new Date(this.value.enddt),'DD.MM.yyyy') : null;
      },
      set(value) {
        this.value.enddt = value;
      },
    },
    enddt: {
      get() {
        return this.value.enddt ? $utils.formatDate(new Date(this.value.enddt),'YYYY-MM-DD') : $utils.formatDate(new Date(),'YYYY-MM-DD');
      },
      set(value) {
        this.value.enddt = value;
      },
    },
  },
  methods: {
    // Обработка клика по карте
    onMapClick(event) {
      if (!this.isEdit) {
        const map = Map.getMap();
        const lngLat = event.lngLat;

        const oFeatures = map.queryRenderedFeatures(event.point, {
          layers: [
            nearId,
          ],
        });

        if (!oFeatures.length) {
          map.setLayoutProperty(nearId, 'visibility', 'none');
          map.setLayoutProperty(nearTextId, 'visibility', 'none');

          this.value.longitude = lngLat.lng;
          this.value.latitude = lngLat.lat;

          const feature = {
            type: 'Feature',
            geometry: {
              type: 'Point',
              coordinates: [
                lngLat.lng,
                lngLat.lat,
              ],
            },
          };

          Map.createOrDataSource(slId, {
            type: 'FeatureCollection',
            features: [
              feature,
            ],
          });

          const ratio = 0.01;

          this.nearStops = this.locations
            .filter(it => (Math.abs(it.latitude - lngLat.lat) < ratio))
            .filter(it => (Math.abs(it.longitude - lngLat.lng) < ratio))
            .filter(it => {
              // pow(sin(deg2rad( ($lat1-$lat2) / 2)), 2)
              const l1 = Math.pow(Math.sin(deg2rad((lngLat.lat - it.latitude) / 2)), 2);
              // cos(deg2rad($lat1))
              const l2 = Math.cos(deg2rad(lngLat.lat));
              // cos(deg2rad($lat2))
              const l3 = Math.cos(deg2rad(it.latitude));
              // pow(sin(deg2rad(($lng1- $lng2) / 2))
              const l4 = Math.pow(Math.sin(deg2rad((lngLat.lng - it.longitude) / 2)), 2);

              const r = Math.asin(Math.sqrt(l1 + l2 * l3 * l4)) * 6378245 * 2;

              return r < 200;
            });

          if (this.nearStops.length) {
            const features = this.nearStops.map(it => ({
              type: 'Feature',
              geometry: {
                type: 'Point',
                coordinates: [
                  it.longitude,
                  it.latitude,
                ],
              },
              properties: {
                name: it.locname,
                ref: _copy(it),
              },
            }));

            Map.createOrDataSource(nearId, {
              type: 'FeatureCollection',
              features: features,
            });

            this.showAdditionalStopsDialog = true;
          } else {
            if (map.getSource(nearId)) {
              map.getSource(nearId).setData({
                type: 'FeatureCollection',
                features: [],
              });
            }
          }
        }
      }
    },
    // При нажатии на кнопку сохранить
    onSubmit() {
      this.$refs.cbForm.validate()

      if (this.form) {
        this.isNewStopLoading = true;

        CreateBusStopService
          .createOrUpdateBusStop(_copy(this.value))
          .then(data => {
            if (this.isEdit) {
              this.$emit('on-update', {
                value: _copy(this.value),
                item: _copy(this.item),
              });
            } else {
              this.$emit('on-new-add', data);
            }
          })
          .catch(err => console.error('CreateBusStop::onSubmit', err))
          .finally(() => this.isNewStopLoading = false);
      }
    },
    // Закрытие диалога
    closeAdditionalDialog() {
      const map = Map.getMap();

      this.showAdditionalStopsDialog = false;

      map.setLayoutProperty(nearId, 'visibility', 'none');
      map.setLayoutProperty(nearTextId, 'visibility', 'none');
    },
    // Показ близжайших точек
    onAdditionalApply() {
      const map = Map.getMap();

      this.showAdditionalStopsDialog = false;

      map.setLayoutProperty(nearId, 'visibility', 'visible');
      map.setLayoutProperty(nearTextId, 'visibility', 'visible');

      const bounds = new MapBoxGL.LngLatBounds(
        [this.nearStops[0].longitude, this.nearStops[0].latitude,],
        [this.nearStops[0].longitude, this.nearStops[0].latitude,],
      );

      this.nearStops.forEach((it, index) => {
        if (index) {
          bounds.extend([
            it.longitude,
            it.latitude,
          ]);
        }
      });

      map.fitBounds(bounds, {
        padding: 20,
        maxZoom: 16,
      });
    },
    // Добавление близжайшей
    onNearAdd() {
      this.$emit('on-near-add', _copy(this.selectedNearStop));

      this.showAddNearStop = false;
    },
    // При отмене выбора близжайшей остановк
    onNearCancel() {
      this.selectedNearStop = null;
      this.showAddNearStop = false;
    },
    // Обработка клика по близжайшей остановке
    _onNearStopsClick(e) {
      this.selectedNearStop = JSON.parse(e.features[0].properties.ref);
      this.showAddNearStop = true;
    },
    // Инициалиация карты
    _initMap() {
      const map = Map.getMap();

      Map.createOrDataSource(nearId, {
        type: 'FeatureCollection',
        features: [],
      });

      Map.createOrDataSource(slId, {
        type: 'FeatureCollection',
        features: [],
      });

      // Слой для ближайших остановок
      if (!map.getLayer(nearId)) {
        // Найденные остановки
        map.addLayer({
          id: nearId,
          source: nearId,
          type: 'circle',
          paint: {
            'circle-radius': 8,
            'circle-color': '#8000ff',
          },
          layout: {
            visibility: 'none',
          },
        });

        // Название остановки
        map.addLayer({
          id: nearTextId,
          type: 'symbol',
          source: nearId,
          layout: {
            visibility: 'none',
            'text-field': ['get', 'name'],
            'text-variable-anchor': ['top', 'bottom', 'left', 'right'],
            'text-radial-offset': 0.5,
            'text-justify': 'auto',
            'text-size': 14,
          },
        });

        // Обработка клика по найденным
        map.on('click', nearId, this._onNearStopsClick);
      }

      // Слой для клика по карте
      if (!map.getLayer(slId)) {
        map.addLayer({
          id: slId,
          source: slId,
          type: 'circle',
          paint: {
            'circle-radius': 8,
            'circle-color': '#ff8c00',
          },
        });
      }
    },
  },
}
</script>

<style scoped>

</style>
