<script>
export default{
    props: {
        'owner': {
            type: Object,
            required: true
        }
    },
    data: () => ({
        /*params*/
        currentDate: null,
        carrierID: null,
        /*params filters*/
        f_vehicleID_carrierID_not_null: null,
        f_vehicleID_carrierID_null: null,
        f_carrierID: null
    }),
    created(){
        console.log('10.10 created by', this.owner);
        
        this._setInitialParams();
        this._initingFilters();
        this._setDates();
        this.owner.on_set = this._set;
    },
    methods: {
        _setInitialParams(){
            this.currentDate = this._setDateFormat(Date.now());
        },
        _completingFilters(param){
            param = param.replaceAll(`param_carrierID`, this.carrierID)
                        .replaceAll(`param_currentDate`, this.currentDate);
            return param;
        },
        _setDateFormat(param){
            var result = null;
            if (param !== null) {
                result = $utils.formatDate(new Date(param), 'DD-MM-YYYY');
            }
            return result;
        },
        _set(param){
            console.log('10.10.set: ' + param.name + ' ', param.value);
            switch (param.name){
                case "carrierID":
                    this.carrierID = param.value;
                    this._setCarrier();
                    break;
            }
        },
        _setDates(){
            if (!$utils.isEmpty(this.currentDate)) {
                var comp = this.owner.$refs["carrierID"];
                var filter = this._completingFilters(this.f_carrierID);
                comp.setFilter(filter);
            }
        },
        _setCarrier(){
            if (!$utils.isEmpty(this.currentDate)) {
                var comp = this.owner.$refs["vehicleID"];
                var filter = '';
                if (!$utils.isEmpty(this.carrierID)){
                    filter = this._completingFilters(this.f_vehicleID_carrierID_not_null);
                } else {
                    filter = this._completingFilters(this.f_vehicleID_carrierID_null);
                }
                comp.setFilter(filter);
            }
        },
        _initingFilters(){
            this.f_vehicleID_carrierID_not_null = 
                    `and(
                        eq(field(\".crrID\"),param(\"param_carrierID\", \"id\")),
                        or(
                            isnull(field(\".endDt\")),
                            gte(field(\".endDt\"),param(\"param_currentDate\", \"date\"))
                        ),
                        lte(field(\".startDt\"),param(\"param_currentDate\", \"date\"))
                    )`;
            this.f_vehicleID_carrierID_null = 
                    `and(
                        or(
                            isnull(field(\".endDt\")),
                            gte(field(\".endDt\"),param(\"param_currentDate\", \"date\"))
                        ),
                        lte(field(\".startDt\"),param(\"param_currentDate\", \"date\"))
                    )`;
            this.f_carrierID = 
                    `and(
                        or(
                            isnull(field(\".endDt\")),
                            gte(field(\".endDt\"),param(\"param_currentDate\", \"date\"))
                        ),
                        lte(field(\".startDt\"),param(\"param_currentDate\", \"date\"))
                    )`;
        }
    },
    render(h){
        h('i',{class: "rep-stub"});
    }
}
</script>