<script>
import JetSvg  from '@/components/JetSvg';
import JetInput from '@/components/editors/JetInput';
import Rep1044 from '@/components/rep-ext/rep-10-44';
import Rep106 from '@/components/rep-ext/rep-10-6';
import Rep105 from '@/components/rep-ext/rep-10-5';
import Rep107 from '@/components/rep-ext/rep-10-7';
import Rep1010 from '@/components/rep-ext/rep-10-10';
import Rep1022 from '@/components/rep-ext/rep-10-22';
import Rep102 from '@/components/rep-ext/rep-10-2';
import Rep1033 from '@/components/rep-ext/rep-10-33';
import Rep1041 from '@/components/rep-ext/rep-10-41';
import Rep1071 from '@/components/rep-ext/rep-10-71';

const REP_MODE = {
    none: -1,
    default: 0,
    loading: 1,
    waiting: 2,
    error: 999
};  //REP_MODE

const REP_EXT = {
    "rep1071": "6489e7cc-3d53-4e01-a7af-3fcfcc7fc1c7"
};

const Chooser = {
    functional: true,
    props: ['repId'],
    components: {
        Rep1044,
        Rep106,
        Rep105,
        Rep107,
        Rep1010,
        Rep1022,
        Rep102,
        Rep1033,
        Rep1041,
        Rep1071
    },
    render(h, context){
        switch(context.props.repId){
            case "5b71ce2e-3f48-409d-a259-a9e5720f8b35":
                return h(Rep1044, {props: {owner: context.parent}});
            case "3bc83c0b-4ccd-4f18-8305-7f3beb0a4ad3":
                return h(Rep106, {props: {owner: context.parent}});
            case "a921b6c3-e22d-4766-b4a6-bcf1eba8df91":
            case "fad4ca1e-9c22-4847-9842-b461b430e80e":
                return h(Rep105, {props: {owner: context.parent}});
            case "54b61ec4-585a-46ea-8907-0f615c301185":
                return h(Rep107, {props: {owner: context.parent}});
            case "840623e6-0261-4fdb-ba32-80926cdfb93a":
                return h(Rep1010, {props: {owner: context.parent}});
            case "5253a9c5-b4d9-4ea4-8eec-03b45dad6f01":
                return h(Rep1022, {props: {owner: context.parent}});
            case "88d5c390-9ca8-442b-9edc-20562d12c213":
                return h(Rep102, {props: {owner: context.parent}});
            case "64ea0b26-3db8-4f93-9f31-22ab4b100200":
                return h(Rep1033, {props: {owner: context.parent}});
            case "332cf8e3-01a4-417e-98ce-588ed4aeeb29":
                return h(Rep1041, {props: {owner: context.parent}});
            case "6489e7cc-3d53-4e01-a7af-3fcfcc7fc1c7":
                return h(Rep1071, {props: {owner: context.parent}});
            default:
                return h('div', {class:"jet-rep-stub"});
        }
    }
};  // Chooser

export default {
    name: 'JetReportTab',
    props: {
        report: {
            type: Object,
            default: null
        },
    },
    data(){
        return {
            mode: REP_MODE.none,
            reportName: this.report.description.title, 
            err: null,
            tableHeaders: [],
            tableItems: [],
        };
    },
    components: {
        JetSvg,
        JetInput,
        Chooser
    },
    provide() {
        const self = this;
        return {
            owner: {
                reg(comp){},
                unreg(comp){},
                val(name, val){
                    self.set(name, val);
                }
            }
        };
    },
    computed: {
        show: {
            get(){
                return (!!this.report) ? (new Date()).getTime() : false;
            },
            set(val){
                if (!!val){
                    //TODO: reset values
                } else {
                    this.$emit('hide');
                }
            }
        },
        hasReport(){
            return ((!!this.report) && !$utils.isEmpty(this.report.id));
        }
    },
    methods: {
        has(name){
            switch(name){
                case 'report':
                    return (!!this.report);
                    break;
                case 'model':
                    return (!!this.report)&&(!!this.report.description);
                    break;
            }
        },
        val(name){
            var v = null;
            if ((this.report)&&(!!this.report.params)){
                this.report.params.map((p)=>{
                    if (p.name === name){
                        v = p.value;
                    }
                });

            }
            console.log('Val:', name, v);
            return v;
        },
        set(name, val){
            if ((!!this.report)&&(!!this.report.params)){
                this.report.params.map((p)=>{
                    if (p.name === name){
                        p.value = val;
                        var inp = this.$refs[p.name];
                        if (!!inp){
                            inp.value = p.value;
                        }
                        this.on_set(p);
                    }
                });
            }
        },
        on_set(param){},    //stub for extension
        async doReport(){ //TODO: check def values when no setting
            this.mode = REP_MODE.waiting;
            this.$nextTick(()=>{
                var url = '/rpc?d=report';
                var _vals = "{";
                this.report.params.map((p, i)=>{
                    console.log(i, p);
                    var fAdd = true,
                        v = p.value;
                    switch ( p.type.toLowerCase() ){
                            case "date":
                            case "datetime":
                                fAdd = (!!v);
                                v = fAdd ? `java.util.Date{time:${ v.getTime() }l}` : '';
                                break;
                            case "string":
                            case "id":
                                fAdd = !$utils.isEmpty(v);
                                v = fAdd ? '"' + v + '"' : '';
                                break;
                            case "integer":
                            case "long":
                                v = v + 'l';
                                break;
                    }
                    if (fAdd){
                        if (i > 0){
                            _vals += ',';
                        }
                        _vals += '"' + p.name + '":' + v;
                    }
                });
                _vals += '}';
                console.log('sending rep values:', this.report.id, _vals);

                var d = this.$store.getters['period/current'];
                var ctx = 'param{name:"dateWork",type:"date",value:java.util.Date{time:' + d.getTime() + 'l}},';
                d = this.$store.getters['period/begin'];
                ctx += 'param{name:"dateBegin",type:"date",value:java.util.Date{time:' + d.getTime() + 'l}},';
                d = this.$store.getters['period/end'];
                ctx += 'param{name:"dateEnd",type:"date",value:java.util.Date{time:' + d.getTime() + 'l}}';
                const formId = 'jet-form-' + this.report.id;
                const mime = this.report.mime;

                if(typeof this.$refs.rep?.methods?.doReportExt === "undefined") {
                    if (/vnd.kih.jdl/i.test(mime)) {
                        $.ajax({
                            url: '/rpc?d=report',
                            contentType: "application/x-www-form-urlencoded; charset=UTF-8",
                            data: '&type=json&report=' + this.report.id + '&args=' + '@' + _vals + '&ctx=' + '@[' + ctx + ']',
                            processData: false,
                            type: 'POST'
                        }).then((data, status, request)=>{
                            const parsedData = JSON.parse(data);
                            const columns = this.report.description.columns;
                            var headers = [];
                            columns.map((c) => {
                                headers.push({text: c.title, title: c.title, value: c.name, id: c.name, type: c.metaType});
                            });
                            this.tableHeaders = headers;
                            var items = [];
                            Object.keys(parsedData.rows).map((i) => {
                                var r = parsedData.rows[i];
                                var item = {id: $utils.uuidv4()};
                                headers.map((h) => {
                                    item[h.value] = r[headers.indexOf(h)];
                                })
                                items.push(item);
                            });
                            this.tableItems = items;
                        }).catch((err)=>{
                            this.err = err;
                            console.log('report error', this.report.id, err);
                        }).always(()=>{
                            this.mode = REP_MODE.default;
                            this.show = false;
                        });
                    } else {
                        $.ajax({
                            url: '/rpc?d=report',
                            contentType: "application/x-www-form-urlencoded; charset=UTF-8",
                            data: '&report=' + this.report.id + '&args=' + '@' + _vals + '&ctx=' + '@[' + ctx + ']',
                            processData: false,
                            type: 'POST',
                            xhrFields: {responseType: 'blob'}
                        }).then((data, status, request)=>{
                            var ext = request.getResponseHeader('content-disposition') || '.xls';
                            ext = ext.substr(ext.lastIndexOf('.'));
                            var url = window.URL.createObjectURL(new Blob([data],  {type : this.report.mime}));
                            var ref = document.createElement('a');
                            ref.href = url;
                            ref.download = this.report.name + ext;
                            document.body.appendChild(ref);
                            ref.click();
                            document.body.removeChild(ref);
                            window.URL.revokeObjectURL(url);
                        }).catch((err)=>{
                            this.err = err;
                            console.log('report error', this.report.id, err);
                        }).always(()=>{
                            this.mode = REP_MODE.default;
                        });
                    }
                } else {
                    this.$refs.rep?.methods?.doReportExt(this.report.params);
                    this.mode = REP_MODE.default;
                }
            });
        },   //doReport
    },
    created() {
        if (!!this.report.params){
            this.report.params.map((p)=>{
                if (!!p.value){
                    return; //empty only
                }
                const dfe = p.defaultExpression;
                const hasExpr = !$utils.isEmpty(dfe);
                switch (p.type){
                    case "boolean":
                        p.value = "false";
                        if (hasExpr && "true" === dfe){
                            p.value = "true";
                        }
                        break;
                    case "date":
                    case "dateTime":
                    case "datetime":
                        if (hasExpr){
                            if (/now\(\)/i.test(dfe)){
                                p.value = new Date();
                            }
                            if (/addDays\(/i.test(dfe)){
                                var d = p.value || new Date();
                                var n = Number(dfe.replace(/[^0-9]+/g, ''));
                                d = this.$moment(d).add(Number.isNaN(n) ? 0 : n, 'days').toDate();
                                p.value = d;
                            }
                            if (/truncToDay/i.test(dfe)){
                                var d = p.value || new Date();
                                p.value = new Date(d.getFullYear(), d.getMonth(), d.getDate());
                            }
                            if (/todayBegin/i.test(dfe)){
                                var d = this.$store.getters['period/current'];
                                p.value = new Date(d.getFullYear(), d.getMonth(), d.getDate());
                            }
                            if (/todayEnd/i.test(dfe)){
                                p.value = this.$store.getters['period/current'];
                            }
                        }
                        break;
                    case "id":
                        if (hasExpr){
                            if (/getCurrentTenantId\(\)/i.test(dfe)){
                                p.value = this.$store.state.profile.tenant;
                            } else if (!/\(/i.test(dfe)){
                                p.value = dfe.replaceAll('"', '');
                            }
                        }
                        break;
                    case "int":
                    case "integer":
                    case "float":
                    case "long":
                        if (hasExpr){
                            var n = Number(dfe.replace(/[^0-9]+/g, ''));
                            p.value = Number.isNaN(n) ? 0 : n;
                        }
                }
            });
        }

        if(this.report?._report?.id == REP_EXT.rep1071)
            this.$refs.rep = Rep1071;

    },
    render(h){
        var conte = [];
        if (this.has('model')){
            if (this.report.params.length > 0){
                this.report.params.map( (p)=>{
                    conte.push(h('v-row', [
                        h('v-col', {props: {cols: 12}}, [
                            h('jet-input', {
                                props: {
                                    type: p.type,
                                    label: p.label,
                                    required: p.required,
                                    uri: p.uri,
                                    value: p.value,
                                    "hide-details": true
                                },
                                attrs: {
                                    name: p.name,
                                    value: p.value
                                },
                                ref: p.name
                            })
                        ])
                    ]));
                } );
            } else {
                this.report.params.push(h('v-alert', {
                    props: {
                        border: 'left',
                        'colored-border': true,
                        type: 'primary'
                    }}, 'Отчет не содержит параметров, нажмите кнопку "Построить" для его формирования.'
                ));
            }
        } else {
            conte.push(h('v-alert', {props: {
                        border: 'left',
                        'colored-border': true,
                        type: 'warning'
                    }}, 'Отчет не содержит параметров, нажмите кнопку "Построить" для его формирования.'
            ));
        }
        
        if (this.hasReport){
            conte.push(h(Chooser, {props: {repId: this.report.id}}));
        }

        return h('v-flex', {
                    key: 'rep-' + this.report.id,
                    class: {"jet-report-tab": true}
                }, [
                    h('v-flex', {
                        class: {"params": true}
                    }, [
                        h('div', {style: {'padding-top': '2em'}}, conte),
                        h('v-spacer'),
                        h('div', {style: {'text-align': 'right', 'margin': '2rem 0'}
                        }, [
                            h('v-btn', {
                                props: {
                                    outlined: true, color: 'primary', 
                                    loading: (this.mode === REP_MODE.waiting),
                                    disabled:(this.mode === REP_MODE.waiting)
                                },
                                on: {click: this.doReport}
                            }, [
                                h('jet-svg', {
                                    props: {xref:'#ico-file-down', width: 15, height: 15},
                                    style: {'margin-right': '0.5rem'}
                                }),
                                'построить'
                            ])
                        ])
                    ]),
                    (this.tableHeaders.length > 0)
                    ? h('v-flex', [
                        h('p'),
                        h('v-data-table', {
                            ref: "table",
                            props: {
                                headers: this.tableHeaders, items: this.tableItems, 
                                'disable-pagination':true, 'hide-default-footer':true, 'single-select': true,
                                'items-per-page': 1000, 'fixed-header': true
                            },
                            class: {"fill-height jet-table": true}
                        })
                    ])
                    : null
                ])
                ;
    }
}
</script>
<style lang="scss">
    .jet-report-tab{
        padding-bottom: 2rem;
        & .params{
            margin: 0 auto 2rem auto;
            max-width: 60%;
        }
        
        & .col{
            padding-top: 0 !important;
            padding-bottom: 0 !important;
        }
        
        & .v-input{
            & .v-text-field__details{
                display: none !important;
            }
        }
    }
</style>
