<template>
    <v-dialog persistent width="640" v-model="show">
      <v-card>
        <v-card-title primary-title>
          Выбор периода для показа трека
        </v-card-title>
        <v-card-text>
            <v-row>
              <v-col cols="6">
                <label class="font-weight-bold" for="startdt">
                  Начало периода
                </label>
                <v-menu offset-y
                        transition="scale-transition"
                        max-width="290px"
                        max-height="290px"
                        v-model="start.showPicker"
                        :close-on-content-click="false">
                    <template #activator="{ on }">
                      <v-text-field persistent-hint
                                    readonly
                                    label="Дата начала"
                                    id="startdt"
                                    append-icon="mdi-calendar-outline"
                                    v-on="on"
                                    :value="format('start')">
                      </v-text-field>
                    </template>
                    <v-date-picker
                                no-title
                                v-model="start.dt"
                                v-on:input="start.showPicker = false"
                                :first-day-of-week="1"
                                :weekday-format="dayOfWeekToFormat">
                    </v-date-picker>
                </v-menu>
                <div>
                    <v-text-field persistent-hint
                                  label="Время начала"
                                  v-model="start.tm"
                                  type="time"
                    ></v-text-field>
                </div>
              </v-col>
              <v-col cols="6">
                <label class="font-weight-bold"
                       for="enddt">
                  Конец периода
                </label>

                <v-menu offset-y
                        transition="scale-transition"
                        max-width="290px"
                        max-height="290px"
                        v-model="end.showPicker"
                        :close-on-content-click="false">
                  <template #activator="{ on }">
                    <v-text-field id="enddt"
                                  readonly
                                  :error="end.e"
                                  persistent-hint
                                  label="Дата окончания"
                                  append-icon="mdi-calendar-outline"
                                  v-on="on"
                                  :value="format('end')">
                    </v-text-field>
                  </template>
                  <v-date-picker no-title
                                 v-model="end.dt"
                                 v-on:input="end.showPicker = false"
                                 :first-day-of-week="1"
                                 :weekday-format="dayOfWeekToFormat">
                  </v-date-picker>
                </v-menu>
                <div>
                    <v-text-field persistent-hint
                                  label="Время окончания"
                                  v-model="end.tm"
                                  type="time">
                    </v-text-field>
                </div>
              </v-col>
            </v-row>
        </v-card-text>

        <v-card-actions>
          <v-btn color="gray" 
                 small 
                 outlined
                 @click="show = false">
            Отмена
          </v-btn>
          <v-btn color="primary" 
                 small
                 outlined
                 @click="apply">
            Применить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>
<script>
import Inputmask from "inputmask";
const $moment = require('moment');
$moment.locale('ru');

const _dt = new Date();
const _now = $moment([_dt.getFullYear(), _dt.getMonth(), _dt.getDate()]);

const _DEF_PER = {
    start: _now,
    end: _now.clone().add(1, 'days').add(-1, 'seconds')
};

import { dayOfWeekToFormat } from '@/services/JetDate';
    
export default {
    name: 'VcChoosePeriod',
    data(){
        return {
            show: false,
            start: {
                showPicker: false,
                dt: null,
                tm: '00:00',
                e: false
            },
            end: {
                showPicker: false,
                dt: null,
                tm: '00:00',
                e: false
            }
        };
    },
    mounted(){
        this.$nextTick(()=>{
            $("input[type='time']").each(function(){
                Inputmask({mask: "99:99"}).mask(this);
            });
        });
    },
    methods: {
        dayOfWeekToFormat,
        open(){
            if (!this.end.dt){
                this.end.dt = _DEF_PER.end.format("YYYY-MM-DD");
                this.end.tm = _DEF_PER.end.format('HH:mm');
            }
            this.end.e = false;
            
            if (!this.start.dt){
                this.start.dt = _DEF_PER.start.format("YYYY-MM-DD");
                this.start.tm = _DEF_PER.start.format('HH:mm');
            }
            this.start.e = false;
            this.show = (new Date()).getTime();
            
        },
        format(q){
            switch(q){
                case "start":
                    return (!!this.start.dt) ? $moment(this.start.dt, 'YYYY-MM-DD').format("DD.MM.YYYY") : null;
                case "end":
                    return (!!this.end.dt) ? $moment(this.end.dt, 'YYYY-MM-DD').format("DD.MM.YYYY") : null;
            }
        },
        apply(){
            this.start.e = false;
            this.end.e = false;
            const dt1 = $moment(`${this.start.dt}T${this.start.tm}:00.000`),
                  dt2 = $moment(`${this.end.dt}T${this.end.tm}:00.000`);
            if ( dt2.isBefore(dt1) ){
                this.end.e = true;
                return false;
            }
            _DEF_PER.start = dt1;
            _DEF_PER.end = dt2;
            this.$emit('period', {start:dt1.toDate(), end: dt2.toDate()});
            this.show = false;
        }
    }
}
</script>
<style lang="scss" scoped>
    .v-card{
        &__actions{
            justify-content: flex-end;
        }
    }
</style>    