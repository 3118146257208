<template>
  <div>
    <v-data-table
      v-show="!dialog"
      :headers="headers"
      :items="messages"
      :loading="loading"
    >
      <template v-slot:item="row">
        <tr @click="openDialog(row.item)">
          <td>{{ row.item.title }}</td>
          <td>{{ row.item.text.substr(0, 30) + '...' }}</td>
          <td>{{ row.item.date }}</td>
          <td v-if="!isModerator">
            {{ row.item.readdt ? 'Прочитано' : 'Новое' }}
          </td>
        </tr>
      </template>
    </v-data-table>

    <template v-if="dialog">
      <MessagePage
        :incident="selectedIncident"
        @back="closeDialog"
      ></MessagePage>
    </template>
  </div>
</template>

<script>
import MessagePage from "@/components/dev/modules/notifications/components/components/MessagePage";
import OtherService from "@/services/OtherService";

var WS_URI = {
    servers: process.env.VUE_APP_BACKEND_NATS_SERVER,
    user: process.env.VUE_APP_BACKEND_NATS_USERNAME,
    pass: process.env.VUE_APP_BACKEND_NATS_PASSWORD
};

export default {
  name: 'MessageTab',
  components: {
    MessagePage
  },
  props: {
    isModerator: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    messages: [],
    loading: true,
    headers: [
      {text: 'Заголовок', value: 'title'},
      {text: 'Сообщение', value: 'text'},
      {text: 'Дата', value: 'date'},
    ],
    dialog: false,
    dialogLoading: true,
    selectedIncident: {
      date: '',
    },
    nats: null,
    sids: [],
  }),
  created() {
    if (!this.isModerator) {
      this.headers.push(
        {text: 'Статус', value: 'readdt'},
      );
    }
    this.init();
  },
  methods: {
    async init() {
      this.loading = true;
      this.messages = await OtherService.getIncidents({onlyEntity: false, addOld: true});
      this.loading = false;
    },
    openDialog(message) {
      this.dialogLoading = true;
      if (typeof message === 'object') {
        this.selectedIncident = message;
        this.editStatus(message);
        this.dialog = true;
        this.dialogLoading = false;
      } else {
        if (!this.loading) {
          this.selectedIncident = this.messages.find(item => {
            return item.regid === message;
          });
          this.dialog = true;
          this.dialogLoading = false;
          this.editStatus(this.selectedIncident);
        } else {
          setTimeout(() => {
            this.openDialog(message);
          }, 1000);
        }
      }
    },
    async editStatus(message) {
      if (!message.readdt) {
        if (await OtherService.editStatus(message.regid, OtherService.STATUS_NOTIFICATION.CONFIRM_READ)) {
          message.readdt = true;
        }
        OtherService.setReadIncident(message.id);
      }
    },
    closeDialog() {
      this.dialog = false;
      this.selectedIncident = {
        date: '',
      };
    },
    async connectNats() {
      if (!this.nats) {
        this.codec = await jet.http.getJSONCodec();
        this.nats = await jet.http.getNats(WS_URI);

        if (this.nats) {
          const userId = this.$store.state.auth.subject.id;
          const s = this.nats.subscribe('PUBLIC.kigat.eventMessaging.type.inc.' + userId);
          (async ()=>{
            for await (const m of s) {
              try{
                var msg = codec.decode(m.data);
                this.onNatsMessageReceive(msg);
              } catch(e){
                console.log('ERR (on_m_recieve):', e);
              }
            }
          })();
          this.sids.push(s);
        }
      }
    },
    disconnectNats() {
      if (this.nats) {
        this.sids.forEach(s => s.unsubscribe());
        this.sids = [];
        this.nats.disconnect();
        this.nats = null;
      }
    },
    async onNatsMessageReceive(message) {
      const data = JSON.parse(message);
      const event = {
        regid: data.id,
        eventid: data.eventId,
      };
      const item = await OtherService.getIncident(event);
      this.messages.push(item);
      jet.msg({
        text: 'Добавлен новый инцидент',
      });
    },
  },
};
</script>
