<template>
  <v-card>
    <v-card-title>
      <v-row dense>
        <v-col class="pa-0">
          Настройки отображения на карте
        </v-col>

        <v-col cols="1" class="pa-0">
          <v-btn @click="close" icon style="margin-bottom: -4px">
            <v-icon color="grey">mdi-close</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-card-title>

    <v-divider/>

    <v-card-text>
      <!-- Цвет трека -->
      <div class="mb-4">
        <label class="body-1 font-weight-bold">
          Цвет трека
        </label>

        <v-color-picker
          v-model="usedData.color"
          class="my-2"
          hide-canvas
          hide-inputs
          hide-mode-switch
          flat
          width="100%"
          @update:color="updateModel"
        ></v-color-picker>

        <v-divider/>
      </div>

      <!-- Толщина линии -->
      <div class="mb-4">
        <label class="body-1 font-weight-bold">
          Толщина линии
        </label>

        <v-row>
          <v-col>
            <v-slider
              v-model="usedData.width"
              min="1"
              max="10"
              step="1"
              ticks="always"
              hide-details
              @change="updateModel"
            ></v-slider>
          </v-col>

          <v-col cols="2">
            <v-text-field
              v-model="usedData.width"
              class="pt-0 mt-0 text-right"
              readonly
              hide-details
            ></v-text-field>
          </v-col>
        </v-row>

        <v-divider/>
      </div>

      <!-- Отображение событий -->
      <div class="mb-4">
        <label class="body-1 font-weight-bold">
          Настройка отображения событий
        </label>

        <v-row>
          <v-col class="pt-0">
            <v-checkbox
              v-if="false"
              v-model="usedData.stopTypes.moving"
              label="В движении"
              hide-details
              class="py-0"
              @change="updateModel"
            ></v-checkbox>
              
            <v-checkbox
              v-model="usedData.stopTypes.stop"
              label="Остановки"
              hide-details
              class="py-0"
              @change="updateModel"
            ></v-checkbox>

            <v-checkbox
              v-model="usedData.stopTypes.parking"
              label="Стоянки"
              hide-details
              class="py-0"
              @change="updateModel"
            ></v-checkbox>
          </v-col>

          <v-col class="pt-0">
            <v-checkbox
              v-if="false"
              v-model="usedData.stopTypes.noData"
              label="Потеря связи"
              hide-details
              class="py-0"
              @change="updateModel"
            ></v-checkbox>

            <v-checkbox
              v-model="usedData.stopTypes.speedUp"
              label="Превышение скорости"
              hide-details
              class="py-0"
              @change="updateModel"
            ></v-checkbox>
          </v-col>
        </v-row>

        <v-divider/>
      </div>

      <!-- Показывать остановки -->
      <div v-if="false">
        <label class="body-1 font-weight-bold">
          Показывать остановки
        </label>

        <v-radio-group
          v-model="usedData.showStops"
          @change="updateModel"
          :disabled="!usedData.stopTypes.stop"
        >
          <v-radio label="Все остановки" value="all"/>
          <v-radio label="Только конечные" value="stops"/>
        </v-radio-group>

        <v-divider/>
      </div>
    </v-card-text>

    <v-card-actions class="px-3">
      <v-btn
        dark
        depressed
        class="body-2"
        color="red lighten-1"
        @click="reset"
      >
        Сбросить настройки
      </v-btn>

      <v-spacer/>

      <v-btn
        outlined
        class="body-2 ml-4"
        color="red lighten-1"
        @click="cancel"
      >
        Отменить изменения
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import MapSettingsService from '../service/MapSettingsService';

export default {
  name: 'MapSettings',
  props: {
    // Набор параметров по-умолчанию
    defaultData: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    // Свойство, передаваемое через v-model
    value: {
      type: Object,
      required: true,
    },
    // Открыт ли
    dialog: {
      type: Boolean,
      required: true,
    },
  },
  data: () => ({
    // Объект для хранения параметров по-умолчанию значений параметров
    defaultObject: MapSettingsService.getDefaultData(),
    // Выбранные параметры
    usedData: {},
    // Клон usedData, используемый для отката изменений
    tempData: {},
  }),
  watch: {
    dialog(val) {
      this.tempData = val ? _copy(this.usedData) : {};
    },
  },
  created() {
    // Применение настроек по-умолчанию для компонента
    // Если нет - берутся значения модели
    this.defaultObject = _copy(
      this.value ?? this.defaultData,
    );

    this.usedData = _copy(this.defaultObject);
    this.tempData = _copy(this.defaultObject);
  },
  methods: {
    // Сброс настроек по-умолчанию
    reset() {
      this.usedData = _copy(this.defaultObject);
      this.tempData = _copy(this.defaultObject);

      this.updateModel();
    },
    // Отмена последних изменений
    cancel() {
      this.usedData = _copy(this.tempData);

      this.updateModel();
    },
    // Закрытие окна настроек
    close() {
      this.tempData = {};

      this.$emit('close');
    },
    // Метод для преобразования модели для родительского компонента
    updateModel() {
      this.$emit('input', this.usedData);
    },
  },
};
</script>

<style scoped>

</style>
